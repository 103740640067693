<template>
  <div>
    <ConfirmActionDialog
      :width="'500px'"
      :isOpen="isOpen"
      :disableAction="isRequestProcessing"
      @close="$emit('close')"
      @ok="handleYesAction"
    >
      <template v-slot:header>Update Email Request</template>
      <template v-if="currentState === STATES.CONFIRM">
        <p>
          This client has registered on Investor Portal with their current email.
          They will be required to verify new email within 14 days to access Investor Portal with the new email.
        </p>
        <p>Please confirm you wish to change the email to <br>{{newEmail}}</p>
      </template>
      <template v-if="currentState === STATES.PENDING">
        <p>The request to change <br>
        {{emailRequestStatus.old_email}} to <br>
        {{emailRequestStatus.new_email}} is pending for verification.
        </p>
        <p>Do you want to cancel this request?</p>
      </template>
      <template v-slot:btn>Yes</template>
    </ConfirmActionDialog>
  </div>
</template>

<script>
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';
import { requestIPEmailChange, cancelIPEmailChange } from 'Services/api/InvestorPortal';

const STATES = {
  CONFIRM: 'CONFIRM',
  PENDING: 'PENDING',
};
export default {
  name: 'EmailChangeRequest',
  data() {
    return {
      STATES,
      isRequestProcessing: false,
    };
  },
  components: {
    ConfirmActionDialog,
  },
  props: {
    isOpen: { type: Boolean },
    newEmail: {
      type: String,
      default: '',
    },
    client: {
      type: Object,
      default: () => { },
    },
    emailRequestStatus: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    currentState() {
      if (this.emailRequestStatus && this.emailRequestStatus.status === 1) {
        return STATES.PENDING;
      }
      return STATES.CONFIRM;
    },
  },
  methods: {
    async handleYesAction() {
      let resp;
      let action;
      this.isRequestProcessing = true;

      if (this.currentState === STATES.CONFIRM) {
        action = 'create';
        resp = await this.createEmailRequest();
      } else if (this.currentState === STATES.PENDING) {
        action = 'cancel';
        resp = await this.cancelEmailRequest();
      }
      if (resp.code !== 200) {
        this.$store.dispatch('setSnackbar', { text: `Failed to ${action} email change request`, type: 'error' });
        this.$store.dispatch('flipSnackbarSwitch');
      }
      this.isRequestProcessing = false;
      await this.$store.dispatch('setEmailChangeRequestStatus', this.client.id);
      this.$emit('close');
    },
    createEmailRequest() {
      return requestIPEmailChange({
        new_email: this.newEmail,
        old_email: this.client.email,
        client_id: this.client.id,
      });
    },
    cancelEmailRequest() {
      return cancelIPEmailChange({
        new_email: this.emailRequestStatus.new_email,
        old_email: this.emailRequestStatus.old_email,
        client_id: this.client.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
