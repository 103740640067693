<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div v-if="hasFullWorkflowEditPermission">
      <div>Review documents then approve to continue workflow.</div>
      <div>
        <span @click.prevent="advisorPreviewDoc" class="action-text">Email copy of documents</span>
      </div>
      <div>
        <span
            @click.prevent="idModal = true"
            class="action-text"
          >
            Upload documents
          </span>
      </div>

      <div>
        <span @click.prevent="advisorApproved" class="action-text">Approve</span>
        <span>or</span>
        <span @click.prevent="advisorRejected" class="action-text">Reject</span>
      </div>
    </div>
    <AdditionalDocumentUpload
      :workflowId="workflow.id"
      :isOpen="idModal"
      @close="idModal = false"
      @done="idModal = false"
    />
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  sendPMApprovalFailure,
  sendPMApprovalSuccess,
  sendApprovalDocuments,
} from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';
import AdditionalDocumentUpload from './AdvisorApprovalStep/AdditionalDocumentUpload';

export default {
  name: 'PMApprovalStep',
  components: { WorkflowStep, AdditionalDocumentUpload },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      idModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
    ]),
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
  },
  methods: {
    async advisorPreviewDoc() {
      try {
        const res = await sendApprovalDocuments(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Generating Documents...',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Error in Document generation',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async advisorApproved() {
      try {
        const res = await sendPMApprovalSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async advisorRejected() {
      try {
        const res = await sendPMApprovalFailure(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
