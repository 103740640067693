<template>
  <v-card class="pa-3 accounts-card">
    <v-layout justify-center fill-height align-end>
       <v-tabs fixed-tabs fluid class="pas-tabs pas-tabs-centered">
         <v-tab
           v-if="canViewInvestmentAccounts"
          :key="`accounts-tab-1`"
         >
          <h2 class="section-title pas-tabs-title">Investment accounts</h2>
         </v-tab>
         <v-tab
           v-if="canViewBankAccounts"
           :key="`accounts-tab-2`"
         >
          <h2 class="section-title pas-tabs-title">Bank accounts</h2>
         </v-tab>
         <v-tab-item
           :key="`accounts-item-1`"
          v-if="canViewInvestmentAccounts"
         >
           <investment-accounts-tab
            :investmentAccounts="investmentAccounts"
            :rrifPayments="rrifPayments"
            :person="person"
          >
          </investment-accounts-tab>
         </v-tab-item>
         <v-tab-item
           :key="`accounts-item-2`"
           v-if="canViewBankAccounts"
         >
          <bank-accounts-tab
            :bankAccounts="bankAccounts"
            :person="person"
          ></bank-accounts-tab>
         </v-tab-item>
       </v-tabs>
    </v-layout>
    <v-layout>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import InvestmentAccountsTab from './Accounts/InvestmentAccountsTab';
import BankAccountsTab from './Accounts/BankAccountsTab';


export default {
  name: 'accounts',
  components: {
    InvestmentAccountsTab,
    BankAccountsTab,
  },
  props: {
    investmentAccounts: Array,
    bankAccounts: Array,
    person: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters([
      'canViewInvestmentAccounts',
      'canViewBankAccounts',
    ]),
    rrifPayments() {
      return this.$store.state.clients.activeClient.rrifPayments;
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

  .pas-tabs-title {
    font-size: 19px !important;
    text-transform: capitalize !important;
    border-bottom: none !important;
  }
  .accounts-tabs.v-tabs {
    width: 100% !important;
  }
  .accounts-tabs.v-tabs .v-tabs__container {
    justify-content: center;
  }
  .accounts-tabs .v-tabs__wrapper {
    margin-bottom: 40px;
  }
  .accounts-tabs .v-tabs__item {
    text-transform: none !important;
  }
  .accounts-tabs .v-tabs__slider.accent {
    background-color: transparent !important;
  }
  .accounts-tabs .v-tabs__item:not(.v-tabs__item--active):not(:hover) {
    color: rgba(0,0,0,0.2) !important;
  }
  .accounts-tabs .v-tabs__item--active {
    color: var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .accounts-card {
    min-height: 541px !important;
  }
</style>
