<template>
  <v-btn-toggle
    :value="!multiple ? value : valueArray"
    :mandatory="mandatory"
    @change="toggle($event)"
    :class="['pas-toggle',{ disabled: disabled }, classToggle]"
    :multiple="multiple"
  >
    <slot></slot>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'pas-button-toggle',
  data() {
    return { };
  },
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    valueArray: {
      type: Array,
      default() {
        return [];
      },
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    classToggle: {
      type: String,
      default: 'small-toggle',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle(value) {
      if (!this.disabled) return this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

.pas-toggle {
  box-shadow: none !important;
  background-color: transparent !important;
  .v-btn {
    margin-right: 15px;
    color: var(--primary-color) !important;
    background-color: transparent !important;
    .v-btn {
      opacity: 1 !important;
      margin-right: 15px;
      color: var(--primary-color) !important;
      font-size: 12px;
      height: 24px !important;
      border-radius: 12px !important;
      background-color: transparent !important;
      border-radius: 12px !important;
    }
    text-transform: none !important;
    border: none !important;
  }
  .v-btn--active {
    background-color: var(--primary-translucent) !important;
  }
}

.large-toggle {
  .v-btn {
    font-size: 14px;
    width: 100px !important;
    height: 39px !important;
    border-radius: 30px !important;
  }
}

.small-toggle {
  .v-btn {
    height: 24px !important;
    font-size: 12px;
    border-radius: 12px !important;
  }
}


</style>
