<template>
  <auth-page>
    <v-layout column>
      <v-flex>
        <pas-page-title class="mb-0">Check your email</pas-page-title>
      </v-flex>
      <v-flex>
        <div class="light-text">We've sent a six-digit confirmation code to </div>
      </v-flex>
      <v-flex mt-4>
        <v-layout column>
          <div class="strong-text form-label">Confirmation Code</div>
          <v-layout row child-flex nowrap>
          <template v-for="(value, index) in [0, 1, 2, 3, 4, 5]">
            <v-text-field
              :id="`charcode-field-${index}`"
              :ref="`charcode-field-${index}`"
              :value="codeChars[index]"
              @input="handleCharcodeInput($event, index)"
              class="box-field mr-2"
              :key="`code-char-${index}`"
              solo
              single-line
              outline
            ></v-text-field>
          </template>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-flex mt-5>
          <v-layout>
            <v-flex shrink text-xs-left>
              <div class="light-text">Already have an account?</div>
              <div class="action-text">Sign-in.</div>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <pas-button class="px-5" @click="">
                Next
              </pas-button>
            </v-flex>
          </v-layout>
        </v-flex>
    </v-layout>
  </auth-page>
</template>

<script>
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage'
import v from 'Services/validation'

export default {
  name: 'confirm',
  components: {
    AuthPage
  },
  data() {
    return {
      v,
      email: 'email@example.com',
      codeChars: [...Array(6)],
    }
  },
  mounted() {
    //grab email from query string
  },
  methods: {
    handleCharcodeInput(val, index) {
      let newValue = val.toString();
      if (newValue.length > 1) newValue = newValue.split('').reverse()[0]

      //set field
      this.$set(this.codeChars, index, '')  //so that it will update when same value      
      this.$nextTick().then(function() {
        this.$set(this.codeChars, index, newValue)  //so that it will update when same value
      }.bind(this))

      //adjust focus
      if (newValue && index < this.codeChars.length-1) {
          this.setFocus(`charcode-field-${index+1}`)      
      } else if (!newValue && index > 0){
          this.setFocus(`charcode-field-${index-1}`)
      }
    },
    setFocus(refName) {
      document.getElementById(refName).focus()  //TODO: for some reason this.$refs[refName] was not working here - figure out
    }
  }
}
</script>

<style lang="scss">
  .box-field {
    width: 50px !important;
    height: 40px !important;
    .v-input__slot {
      border-width: 1px !important;
      border-color: rgba(0, 0, 0, 0.2) !important;
      box-shadow: none !important;
      min-height: 64px !important;
      &:focus, &:active, &:focus-within {
        border-color: var(--primary-color) !important;
      }
    }
    input {
      margin: auto !important;
      text-align: center !important;
      font-size: 20x !important;
    }
    // border: 1px solid !important;
  }
</style>