<template>
  <v-pagination
    :length="length"
    circle
    prev-icon="mdi-chevron-left"
    next-icon="mdi-chevron-right"
    :value="value"
    @input="$emit('input', $event)"
    color="rgba(0,0,0,0.01)"
    total-visible="5"
  >
  </v-pagination>
</template>

<script>
export default {
  name: 'pas-pagination',
  props: {
    length: {
      type: Number,
      default: 1,
    },
    value: Number,
  },
  data() {
    return {};
  },
};
</script>

<style>

</style>
