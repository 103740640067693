<template>
  <pas-fullscreen-modal @close="closeModal" :isOpen="isOpen">
		<v-container class="modal-container">
      <v-layout column>
        <v-flex xs12 text-xs-center mb-3>
          <PasPageTitle>Create Systematic Investments</PasPageTitle>
        </v-flex>
        <v-flex xs12 text-xs-center mb-5>
          <span>
						Simply choose what type of systematic investment you would like to set up and how often you would like to contribute. <br/>
						Please allow 5 business days to process SWP requests.
					</span>
        </v-flex>
      </v-layout>

			<v-form
				@submit.prevent
				ref="form"
				lazy-validation
				:class="{ 'no-pointer': launchWorkflowProcessing }"
			>
				<PasSections
					:selected="PACorSWP === 'PAC'"
					@select="PACorSWP = $event ? 'PAC' : 'SWP'"
				>
					<template v-slot:1>&nbsp; PAC Pre-Authorized Contribution</template>
					<template v-slot:2>&nbsp; SWP Systematic Withdrawal Plan</template>
				</PasSections>


					<v-layout row nowrap>
						<v-flex xs6 mr-2><div class="strong-text my-3">From</div></v-flex>
						<v-flex xs6 ml-2><div class="strong-text my-3">To</div></v-flex>
					</v-layout>
					<v-layout v-if="PACorSWP === 'PAC'" key="pac-accounts">
						<v-flex xs6 mr-2>
							<PasAutocomplete
								:items="bankingAccounts"
								placeholder="Select a Client's Bank Account"
								v-model="bankingAccount"
								:rules="[v.isRequired()]"
								required
								:returnObject="true"
								:key="`pac-1`"
							/>
						</v-flex>
						<v-flex xs6 ml-2>
							<PasAutocomplete
								:items="investmentAccounts"
								placeholder="Select a Client's Investment Account"
								v-model="selectedInvAccID"
								:rules="[v.isRequired(), hasDataphileNumber, hasPositions]"
								required
								:returnObject="true"
								:key="`pac-2`"
							/>
						</v-flex>
					</v-layout>
					<v-layout v-else key="swp-accounts">
						<v-flex xs6 mr-2>
							<PasAutocomplete
								:items="investmentAccounts"
								placeholder="Select a Client's Investment Account"
								v-model="selectedInvAccID"
								:rules="[v.isRequired(), hasDataphileNumber, hasPositions]"
								required
								:returnObject="true"
								:key="`swp-1`"
							/>
						</v-flex>
						<v-flex xs6 ml-2>
							<div>
								<PasAutocomplete
									:items="bankingAccounts"
									placeholder="Select a Client's Bank Account"
									v-model="bankingAccount"
									:rules="[isNomineeDeposit || v.isRequired()]"
									:disabled="isNomineeDeposit"
									required
									:returnObject="true"
									:key="`swp-2`"
							/>
								<v-layout :style="{ position: 'absolute', marginTop: '34px'}" row>
									<PasCheckbox
                    :value="isNomineeDeposit"
                    @change="isNomineeDeposit = $event"
                    class="mt-0 mr-3"
                  />
									<span :style="{lineHeight: '34px', position: 'relative', left: '-10px'}">Deposit in Nominee Account</span>
								</v-layout>
							</div>
						</v-flex>
					</v-layout>
					<v-layout column align-content-start>
						<v-progress-linear indeterminate :color="primaryColor" v-if="loadPositions"/>
						<div  v-if="positions && positions.length && !loadPositions" class="width-49">
							<div class="strong-text my-3">Fund Code</div>
							<PasAutocomplete
								:items="positions"
								:placeholder="`Fund Code`"
								v-model="position"
								:rules="[v.isRequired()]"
							/>
						</div>
						<div v-if="position" class="width-49">
							<div class="strong-text my-3">Fund Name</div>
							<PasInputField :value="position.security_name" disabled/>
							<div class="strong-text my-3">Fund Account Number</div>
							<PasInputField :value="position.fund_account_no" readonly disabled/>
						</div>
						<div class="positions-message mt-5" v-if="positions && !positions.length && !loadPositions">
							No positions available on this account, please select another
						</div>
					</v-layout>
					<v-layout mt-4>
						<div class="strong-text form-label">Contribution Type</div>
					</v-layout>
					<v-layout row nowrap :class="{ 'no-pointer': PACorSWP === 'SWP' || (investmentAccount && investmentAccount.type === '14') }">
						<PasSelectableChip
							@click="contributionType = 'Regular'"
							:class="{ 	'selected-chip': contributionType === 'Regular',
										'mr-4': true }"
							:style="{ width: '130px' }"
						>Regular</PasSelectableChip>
						<PasSelectableChip
							@click="contributionType = 'Spousal'"
							:class="{ 'selected-chip': contributionType === 'Spousal' }"
							:style="{ width: '130px' }"
						>Spousal</PasSelectableChip>
					</v-layout>
					<v-layout>
						<v-flex md2 mr-4>
							<div class="strong-text my-3">Amount</div>
							<PasInputCurrency
								v-if="!refresh"
								v-model="amount"
								ref="inputCurrency"
								:rules="[v.isRequired(), v.isCurrency, v.isPositive]"
							/>
						</v-flex>
						<v-flex md2 mr-4>
							<div class="strong-text my-3">Start payment date</div>
							<PasDatepicker
								:value="date"
								@input="date = $event"
								iconLocation="before"
								:key="`date-field`"
						/>
						</v-flex>
					</v-layout>

					<v-layout>
						<div class="strong-text my-3">Frequency</div>
					</v-layout>
					<v-layout row nowrap>
						<v-flex
              v-for="(item, i) in frequencyItems"
              :key="i"
              shrink
            >
              <PasSelectableChip
                @click="frequency = item.value"
                :class="{ 'selected-chip': frequency === item.value }"
                :style="{ width: '130px' }"
              >
                {{item.text}}
              </PasSelectableChip>
            </v-flex>
					</v-layout>

					<v-spacer></v-spacer>
					<v-layout justify-center :style="{height: '40px !important', maxHeight: '40px !important'}" mt-2>
						<v-flex text-xs-center>
							<PasErrorDiv :show="errorText !== ''">{{errorText}}</PasErrorDiv>
						</v-flex>
					</v-layout>

					<v-layout justify-space-between="" mt-5>
						<div>
							<v-chip v-for="(systematic, i) in queuedSystematics" :key="i" color="#eeeeee">
								{{systematic.type === "1" ? 'PAC' : 'SWP'}}: {{systematic.fund_name}}
								<v-icon
									@click="systematicForDeletion = systematic; confirmDialog = true"
									:style="{ fontSize: '16px' }"
									class="ml-3"
								>close</v-icon>
							</v-chip>
						</div>
					</v-layout>
					<v-layout mt-2>
						<v-flex text-xs-right>
							<PasButton
								@click="create"
								:disabled="queuedSystematics.length === 0 && !launchWorkflowProcessing"
								class="wide-btn"
								theme="inverted"
								:processing="launchWorkflowProcessing"
							>Launch Workflow</PasButton>
							<PasButton
								@click="save"
								class="primary-btn wide-btn "
								:processing="saveProcessing"
							>Save</PasButton>
						</v-flex>
					</v-layout>
				</v-layout>
			</v-form>
		</v-container>
		<ConfirmActionDialog
      @close="confirmDialog = false"
      @ok="deleteSystematic"
      :isOpen="confirmDialog"
      :width="'400px'"
    >
      <template v-slot:header>Delete Systematic</template>
			Are you sure you want to delete this systematic?<br/><br/>
			{{`Systematic details - ${systematicForDeletion.type === "1" ? "PAC into" : "SWP out of"} ` +
			`${systematicForDeletion.fund_name}, ${FREQUENCIES[systematicForDeletion.frequency]}`}}
      <template v-slot:btn>Delete</template>
    </ConfirmActionDialog>
	</pas-fullscreen-modal>
</template>

<script>
import babelPolyfill from '@babel/polyfill';
import api from 'Services/api';
import v from 'Services/validation';
import moment from 'moment';
import { modal, cursorToEnd } from 'Services/mixins';
import { isValidCurrencyString } from 'Services/helpers/currency';
import { ACCOUNTTYPE, SYSTEMATIC_TYPES, FREQUENCIES } from 'Services/constants';
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';

export default {
  name: 'add-systematic-payment',
  mixins: [modal, cursorToEnd],
  components: {
    ConfirmActionDialog,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v,
      PACorSWP: 'PAC',
      selectedInvAccID: null,
      bankingAccount: null,
      isNomineeDeposit: false,
      positions: null,
      position: null,
      loadPositions: false,
      contributionType: 'Regular',
      FREQUENCIES,
      frequency: 'W',
      date: null,
      nextDate: null,
      amount: null,
      refresh: false,
      errorText: '',
      saveProcessing: false,
      confirmDialog: false,
      systematicForDeletion: '',
      launchWorkflowProcessing: false,
    };
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    queuedSystematics() {
      return this.activeClient.systematics.reduce((acc, item) => acc.concat(item.systematics
        .filter((s) => s.status === '0' && ['Deposit (PAC)', 'Withdrawal (SWP)'].includes(SYSTEMATIC_TYPES[s.type]))), []);
    },
    investmentAccounts() {
      const investmentAccounts = this.activeClient.investment_accounts.map((a) => ({ text: `${a.nickname}, (${a.account_no_dataphile || 'Missing Dataphile Number'})`, value: a.id, type: a.account_type_short }));
      if (this.PACorSWP === 'PAC') {
        const accountTypes = ['CASH', 'RRSP', 'RESP', 'TFSA']; // types of accounts that can do that PACs
        return investmentAccounts.filter((a) => accountTypes.some((type) => ACCOUNTTYPE[Number(a.type)].toUpperCase().includes(type)));
      }
      const accountTypes = ['CASH', 'TFSA', 'LIF', 'LRIF', 'PRIF', 'RRIF']; // types of accounts that can do that SWPs
      return investmentAccounts.filter((a) => accountTypes.some((type) => ACCOUNTTYPE[Number(a.type)].toUpperCase().includes(type)));
    },
    investmentAccount() {
      return this.activeClient.investment_accounts.find((a) => a.id === (this.selectedInvAccID ? this.selectedInvAccID.value : ''));
    },
    bankingAccounts() {
      return this.activeClient.bankAccounts.map((account) => ({
        text: `${account.description}, (${account.account_no})`,
        value: account,
      }));
    },
    hasDataphileNumber() {
      return (this.investmentAccount && !!this.investmentAccount.account_no_dataphile) || 'Please select an account with a Dataphile number';
    },
    hasPositions() {
      return (this.positions && !!this.positions.length) || 'Please select an account with associated positions';
    },
    rifSelected() {
      if (!this.investmentAccount) return false;
      return 	(Number(this.investmentAccount.account_type_long) >= 19
					&& Number(this.investmentAccount.account_type_long) <= 23);
    },
    frequencyItems() {
      const frequencyEnum = _.invert(FREQUENCIES);
      return Object.keys(FREQUENCIES)
        .map((key) => ({ text: FREQUENCIES[key], value: key }))
        .filter((x) => x.value !== frequencyEnum['One-Time'] && x.value !== frequencyEnum.Unknown);
    },
  },
  watch: {
    selectedInvAccID: {
      deep: true,
      handler(val) {
        if (this.investmentAccount) {
          this.position = '';
          this.contributionType = this.investmentAccount.spousal_indicator ? 'Spousal' : 'Regular';
          this.getPositions();
        }
      },
    },
    frequency(val) {
      if (val === 'N' && !this.nextDate) {
        let date = new Date();
        date = date.setDate(date.getDate() + 16);
        this.nextDate = (new Date(date)).toISOString().split('T')[0];
      }
    },
    PACorSWP(val) {
      val === 'SWP' ? this.contributionType = 'Regular' : this.isNomineeDeposit = false;
    },
    rifSelected(val) {
      if (val) this.isNomineeDeposit = true;
    },
    isOpen(val) {
      if (val) this.getRecommendedDate();
    },
  },
  methods: {
    getRecommendedDate() {
      let date = new Date();
      date = date.setDate(date.getDate() + (date.getDay() === 0 ? 5 : 7));
      this.date = (new Date(date)).toISOString().split('T')[0];
    },
    getPositions() {
      this.loadPositions = true;
      this.$store.dispatch('fetchPositions', {
        context: 'account',
        value: this.investmentAccount.id,
        security_classes: '90,91,92,95,96,97,98,100',
      })
        .then((res) => {
          if (!res.accounts) return [];
          const account = res.accounts.find((a) => a.id === this.investmentAccount.id);
          if (!account) return [];
          this.positions = account.holdings[0].positions.map((p) => ({ text: p.cusip, value: p }));
          this.loadPositions = false;
        })
        .catch((err) => loadPositions = false);
    },
    isValidSystematicPayment() {
      if (!this.$refs.form.validate()) return false;
      if (!this.isValidPaymentDate()) {
        this.errorText = 'Date must be in the future';
        return false;
      }
      if (this.amount == 0) {
        this.errorText = 'Please select an amount greater than 0';
        return false;
      }
      this.errorText = '';
      return true;
    },
    isValidPaymentDate() {
      return (new Date(this.date)).getTime() > (new Date()).getTime();
    },
    save() {
      if (!this.isValidSystematicPayment()) return;
      this.saveProcessing = true;
      const systematic = {
        security_id: this.position.security_number_id,
        bank_account_id: !this.isNomineeDeposit ? this.bankingAccount.value.id : null,
        client: this.activeClient.id,
        account_no_dataphile: this.investmentAccount.account_no_dataphile,
        amount: this.amount,
        account: this.investmentAccount.id,
        frequency: this.frequency,
        status: 0,
        type: this.PACorSWP === 'PAC' ? '1' : '2',
        payment_method: '1',
        cusip: this.position.cusip,
        spousal_contribution: this.contributionType === 'Spousal',
        fund_name: this.position.security_name,
        start_date: this.date,
        second_date: this.nextDate,
        fund_account_no: this.position.fund_account_no,
      };
      this.$store.dispatch('createSystematic', systematic)
        .then((res) => {
          if (!res.id) throw Error;
          this.$store.dispatch('setSnackbar', {
            text: 'Systematic successfully generated',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          this.saveProcessing = false;
          this.formReset();
        })
        .catch((err) => {
          this.saveProcessing = false;
          this.$store.dispatch('setSnackbar', {
            text: 'Systematic failed to save',
            type: 'fail',
          });
          this.$store.dispatch('flipSnackbarSwitch');
        });
    },
    formReset() {
      this.PACorSWP = 'PAC';
      this.$refs.form.reset();
      this.positions = null;
      this.contributionType = 'Regular';
      this.frequency = _.invert(FREQUENCIES).Weekly;
      this.refresh = true;
      this.$nextTick().then(() => { this.refresh = false; });
      this.getRecommendedDate();
      this.nextDate = null;
    },
    deleteSystematic() {
      this.$store.dispatch('deleteSystematic', this.systematicForDeletion.id);
      this.confirmDialog = false;
    },
    create() {
      this.launchWorkflowProcessing = true;
      api.post('/advisor_portal/workflow/new/', {
        pas_client_ids: [this.activeClient.client.id],
        workflow_type: 'systematic_setup',
      }).then((res) => {
        if (!res.workflow_id) throw new Error();
        this.$store.dispatch('fetchSystematics', this.activeClient.client.id);
        this.$store.dispatch('getWorkflows', this.activeClient.client.id);
        this.$store.dispatch('setSnackbar', {
          text: 'Systematic workflow has been successfully created',
          type: 'success',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.closeModal();
      }).catch((err) => {
        this.$store.dispatch('setSnackbar', {
          text: 'Systematic workflow creation failed',
          type: 'fail',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      });
      this.launchWorkflowProcessing = false;
    },
    closeModal() {
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.close();
    },
  },

};
</script>

<style lang="scss">
@import '~Styles/variables';

.spacer {
	margin-top: 1rem;
}
.positions-message {
	margin: .5rem 0 1rem;
}

</style>
