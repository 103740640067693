import {
  get, defaultTo, isArray,
} from 'lodash';
// Helper functions for accessing state easily
// Use these in your getters

export const hasModule = (state, moduleName) => {
// For now, we need to check the feature flag enabled.
// If its not enabled, just allow the user edit access
// TODO: Remove this feature flag when we are done
  if (!state.featureEnabled && state.featureFlagsFetched) {
    return true;
  }
  return state.modules.has(moduleName);
};

export const canViewSubmodule = (state, submodule) => {
  // For now, we need to check the feature flag enabled.
  // If its not enabled, just allow the user view access
  // TODO: Remove this feature flag when we are done
  if (!state.featureEnabled && state.featureFlagsFetched) {
    return true;
  }
  return defaultTo(get(state.submodules, `${submodule}.read`), false);
};

export const canEditSubmodule = (state, submodule) => {
  // For now, we need to check the feature flag enabled.
  // If its not enabled, just allow the user edit access
  // TODO: Remove this feature flag when we are done
  if (!state.featureEnabled && state.featureFlagsFetched) {
    return true;
  }
  return defaultTo(get(state.submodules, `${submodule}.write`), false);
};

export const canDeleteSubmodule = (state, submodule) => {
  // For now, we need to check the feature flag enabled.
  // If its not enabled, just allow the user delete access
  // TODO: Remove this feature flag when we are done
  if (!state.featureEnabled && state.featureFlagsFetched) {
    return true;
  }
  return defaultTo(get(state.submodules, `${submodule}.delete`), false);
};

export const canAccessUserLevelModule = (state, moduleName) => {
  if (isArray(state.userLevelModules)) {
    return !!state.userLevelModules.find((module) => module.module_name === moduleName);
  }
  return false;
};
