<template>
    <v-layout row :style="{height: '42px'}" justify-start>
        <v-flex :style="{maxWidth: '100px !important'}" mr-1>
            <v-text-field
                single-line
                outline
                readonly
                solo
                flat
                :value="countryCode"
                class="phone-input country-code"
            ></v-text-field>
        	<v-img
                :src="flagURL"
                class="flag-new"
			></v-img>
        </v-flex>
        <v-flex>
            <v-text-field
                :mask="`phone`"
                single-line
                outline
                solo
                flat
                :value="value"
                @input="$emit('input', $event)"
                class="phone-input phone-number"
                :placeholder="placeholder"
                :rules="rules"
                :readonly="readonly"
            ></v-text-field>
        </v-flex>
    </v-layout>
</template>

<script>

import { findFlagUrlByNationality } from 'country-flags-svg';

export default {
  name: 'pas-phone-input',
  props: {
    value: [String],
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryCode: '+1',
      flagURL: findFlagUrlByNationality('Canadian'),
    };
  },
};

</script>

<style lang="scss">
.country-code {
    div.v-input__slot {
        //max-width: 110px !important;
        padding: 0 25px !important;
    }
}
.phone-input {
  .v-input__slot {
    border-radius: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 20px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 42px !important;
    margin: 0px !important;
    &:active, &:focus, &:hover {
        border-color: var(--primary-translucent-5) !important;
    }
    input {
        padding: 0px !important;
        margin: 0px !important;
    }
  }
.v-text-field {
    display: none
}
    min-height: 40px !important;
    height: 42px !important;
}
.v-image.flag-new{
    position: relative !important;
    width: 24px !important;
    bottom: 29px !important;
    left: 50px !important;
    border-radius: 2px !important;
}

</style>
