<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
  <span>
    This step is in progress -
    <span
      @click.prevent="completeAccountOpening"
      class="action-text"
    >
      mark as complete.
    </span>
  </span>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendAccountOpeningSuccess } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'AccountOpeningStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
  },
  methods: {
    async completeAccountOpening() {
      try {
        const res = await sendAccountOpeningSuccess(this.workflow, this.activeClient.client.id);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Complete Account Opening Survey',
            type: 'success',
          });
          setTimeout(() => {
            this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
          }, 250);
          this.$store.dispatch('flipSnackbarSwitch');
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating survey status',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
