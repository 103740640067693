<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span v-if="step.name === 'in_progress'">
        <span
          @click.prevent="completeFccProcessing"
            class="action-text"
        >
          Mark as complete
        </span>
    </span>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendFccProcessingSuccess } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'FCCProcessingStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
  },
  methods: {
    async completeFccProcessing() {
      try {
        const res = await sendFccProcessingSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Completing Workflow...',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to complete workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
