<template>
  <div>
    <PasCircularLoader v-if="loading"></PasCircularLoader>
    <div v-else>
      <v-card class="pa-4">
        <v-layout mb-4>
          <v-flex xs12 text-xs-center>
            <PasPageTitle>Account Details Confirmation</PasPageTitle>
          </v-flex>
        </v-layout>
        <v-layout mb-4>
          <v-flex xs12 text-xs-left>
            <p class="body-text">
              {{clientDetailsTable[0].value}},
              <br />
              <br />Your advisor has provided us with the below information regarding your investment accounts at {{ firmNameShort }}.
              <br />If the information is accurate, simply click “Looks good” and proceed to the next onboarding step. If the information is not accurate, select “Something’s incorrect” and a member from the {{ firmNameShort }} team or your advisor will contact you to update the information.
              <br />By proceeding, you are confirming that you have discussed the arrangement between {{ firmNameShort }} and your advisor.
              <br />
            </p>
          </v-flex>
        </v-layout>
        <div>
          <PasPageSubtitle class="section-header">Client Details</PasPageSubtitle>
          <div
            v-for="(info, index) in clientDetailsTable"
            :key="index+info.label.replace(/\s/g, '')"
          >
            <v-layout mb-2 row nowrap align-center class="table-row">
              <v-flex xs3 class="strong-text">{{info.label}}</v-flex>
              <v-flex xs9>{{info.value}}</v-flex>
            </v-layout>
          </div>
        </div>
        <div>
          <PasPageSubtitle class="section-header">Bank Accounts</PasPageSubtitle>
          <v-data-table :headers="bankAccHeaders" :items="bankAccountsTable" hide-actions>
            <template v-slot:items="props">
              <td class="text-xs-left">{{ props.item.institutionName }}</td>
              <td class="text-xs-rleft">{{ props.item.transitNo }}</td>
              <td class="text-xs-rleft">{{ props.item.accountNo }}</td>
              <td class="text-xs-rleft">{{ props.item.currency }}</td>
              <td class="text-xs-rleft">{{ props.item.accountName }}</td>
            </template>
          </v-data-table>
        </div>
        <div>
          <PasPageSubtitle class="section-header">Investment Accounts</PasPageSubtitle>
          <v-data-table :headers="investAccHeaders" :items="investmentAccountsTable" hide-actions>
            <template v-slot:items="props">
              <td class="text-xs-left">{{ props.item.accountType }}</td>
              <td class="text-xs-rleft">{{ props.item.accountName }}</td>
              <td class="text-xs-rleft">{{ props.item.beneiName }}</td>
              <td class="text-xs-rleft">{{ props.item.beneiType }}</td>
              <td class="text-xs-rleft">{{ props.item.percentage }}</td>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-layout mt-5 justify-end>
        <PasButton theme="warning" class="px-4" @click="$emit('submit',false)">Something's Incorrect</PasButton>
        <PasButton theme="primary" class="confirm-btn px-4" @click="$emit('submit',true)">Looks Good</PasButton>
      </v-layout>
    </div>
  </div>
</template>

<script>
import {
  getClientDetails,
  getClientBankAccounts,
  getClientInvestmentAccounts,
} from 'Services/api/ClientConfirmationTool';

import { REGISTERED_ACCOUNT_TYPES } from 'Services/constants';

import getBaseType from 'Services/helpers/getAccountBaseType';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { getMetadata } from 'Services/api/metadata';

export default {
  data() {
    return {
      firmNameShort: '',
      clientData: {},
      clientDetailsLoading: true,
      bankAccountsLoading: true,
      investmentAccountsLoading: true,
      investAccHeaders: [
        {
          text: 'Account Type',
          align: 'left',
          sortable: false,
          value: 'accountType',
        },
        {
          text: 'Account Name',
          sortable: false,
          value: 'accountName',
        },
        {
          text: 'Beneficiary Name',
          sortable: false,
          value: 'beneiName',
        },
        {
          text: 'Beneficiary Type',
          sortable: false,
          value: 'beneiType',
        },
        {
          text: 'Percentage',
          sortable: false,
          value: 'percentage',
        },
      ],
      bankAccHeaders: [
        {
          text: 'Institution',
          align: 'left',
          sortable: false,
          value: 'institutionName',
        },
        {
          text: 'Transit Number',
          sortable: false,
          value: 'transitNo',
        },
        {
          text: 'Account Number',
          sortable: false,
          value: 'accountNo',
        },
        {
          text: 'Currency',
          sortable: false,
          value: 'currency',
        },
        {
          text: 'Account Name',
          sortable: false,
          value: 'accountName',
        },
      ],
    };
  },
  async created() {
    this.firmNameShort = await getMetadata('firm_name_short');
  },
  async mounted() {
    this.fetchCCTData();
  },
  computed: {
    loading() {
      return (
        this.clientDetailsLoading
        || this.bankAccountsLoading
        || this.investmentAccountsLoading
      );
    },
    sessionToken() {
      return this.$route.query.sessionToken;
    },
    firmId() {
      return this.$route.query.firm;
    },
    clientDetails() {
      return this.clientData.clientDetails;
    },
    bankAccounts() {
      return this.clientData.bankAccounts;
    },
    investmentAccounts() {
      return this.clientData.investmentAccounts;
    },
    ...mapGetters(['bankInstitutions']),
    clientDetailsTable() {
      const clientInfo = [
        {
          label: 'First Name',
          value: `${
            this.clientDetails.person.first_name
              ? this.clientDetails.person.first_name
              : ''
          }`,
        },
        {
          label: 'Middle Name',
          value: `${
            this.clientDetails.person.middle_name
              ? this.clientDetails.person.middle_name
              : ''
          }`,
        },
        {
          label: 'Last Name',
          value: `${
            this.clientDetails.person.last_name
              ? this.clientDetails.person.last_name
              : ''
          }`,
        },
        {
          label: 'Date Of Birth',
          value: `${
            this.clientDetails.person.date_of_birth
              ? this.clientDetails.person.date_of_birth
              : ''
          }`,
        },
        {
          label: 'Email',
          value: `${this.clientDetails.email ? this.clientDetails.email : ''}`,
        },
        {
          label: 'Mobile Number',
          value: `${
            this.clientDetails.mobile_number
              ? this.clientDetails.mobile_number
              : ''
          }`,
        },
        {
          label: 'Office Number',
          value: `${
            this.clientDetails.office_number
              ? this.clientDetails.office_number
              : ''
          }`,
        },
        {
          label: 'Marital Status',
          value: `${
            this.clientDetails.person.marital_status
              ? this.clientDetails.person.marital_status
              : ''
          }`,
        },
      ];
      return clientInfo;
    },
    bankAccountsTable() {
      const res = this.bankAccounts.map((account) => ({
        institutionName: account.bank_name,
        transitNo: account.transit_no || 'N/A',
        accountNo: account.account_no || 'N/A',
        currency: account.currency || 'N/A',
        accountName: account.description || 'N/A',
      }));
      return res;
    },
    investmentAccountsTable() {
      const res = [];
      this.investmentAccounts.forEach((account) => {
        const accountType = getBaseType(account);
        const isRegistered = REGISTERED_ACCOUNT_TYPES.includes(accountType);
        const ia = [
          {
            accountType: accountType || 'N/A',
            accountName: account.nickname || 'Not Specified',
            beneiType: isRegistered ? 'Not Selected' : 'N/A',
            beneiName: isRegistered ? 'Not Selected' : 'N/A',
            percentage: isRegistered ? 'Not Selected' : 'N/A',
          },
        ];
        const accBeneficiaries = account.beneficiaries;
        if (accBeneficiaries) {
          accBeneficiaries.forEach((beneficiary, i) => {
            const beneficiaryType = beneficiary.priority === 'P' ? 'Primary' : 'Contingent';
            if (i === 0) {
              ia[i].beneiType = beneficiaryType;
              ia[i].beneiName = beneficiary.display_name;
              ia[i].percentage = beneficiary.percentage ? `${Number(beneficiary.percentage) * 100} %` : 'N/A';
            } else {
              ia.push({
                accountType: '--',
                accountName: '--',
                beneiType: beneficiaryType,
                beneiName: beneficiary.display_name,
                percentage: beneficiary.percentage ? `${Number(beneficiary.percentage) * 100} %` : 'N/A',
              });
            }
          });
        }
        res.push(...ia);
      });
      return res;
    },
    institutions() {
      return this.bankInstitutions.reduce((map, bank) => {
        const institutionNo = `000${bank.institution_no.toString()}`.substr(-3);
        const bankName = bank.bank_name
          .toLowerCase()
          .replace(/\w+/g, _.capitalize);
        map[institutionNo] = bankName;
        return map;
      });
    },
  },
  methods: {
    fetchCCTData() {
      getClientDetails(this.sessionToken)
        .then((data) => {
          this.clientData.clientDetails = data[0];
          this.clientDetailsLoading = false;
        })
        .catch(() => {
          this.error = true;
          this.clientDetailsLoading = false;
        });
      getClientBankAccounts(this.sessionToken)
        .then((data) => {
          this.clientData.bankAccounts = data;
          this.bankAccountsLoading = false;
        })
        .catch(() => {
          this.error = true;
          this.bankAccountsLoading = false;
        });
      getClientInvestmentAccounts(this.sessionToken)
        .then((data) => {
          this.clientData.investmentAccounts = data;
          this.investmentAccountsLoading = false;
        })
        .catch(() => {
          this.error = true;
          this.investmentAccountsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-text {
  font-size: 14px;
}
.section-header {
  margin: 24px 0;
}
.confirm-btn {
  margin-left: 16px;
}
</style>
