<template>
  <div>
    <PModal
      @hidden="closeModal"
      :visible="isOpen"
      :hide-backdrop="false"
      centered
    >
      <template>
        <div class="subtitle bold mb-2">
          Are you sure you want to unlock your client's account?
        </div>
        <div>
          Once unlocked, your client will be able to log in with their email and password.
        </div>
        <PRow v-if="error" align-h="center" class="mt-2">
          <div class="alert alert-secondary">
            Sorry, something went wrong. Please try again in a few minutes.
          </div>
        </PRow>
        <PRow
          align-h="end"
          class="mt-3 px-2"
        >
          <PButton
            variant="light"
            class="mr-3"
            size="lg"
            @click="closeModal"
          >
            Nevermind
          </PButton>
          <PButton
            variant="dark"
            size="lg"
            @click="unlock"
          >
            Unlock
          </PButton>
        </PRow>
      </template>
    </PModal>
  </div>
</template>

<script>
import { unlockInvestorAccount } from 'Services/api/InvestorPortal';
import { mapGetters } from 'vuex';

const SCREENS = {
  confirm: 'confirm',
  done: 'done',
  error: 'error',
};

export default {
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      screen: SCREENS.confirm,
      SCREENS,
      error: false,
    };
  },
  computed: {
    ...mapGetters(['activeClient', 'clientId']),
  },
  methods: {
    async unlock() {
      // unlock the account
      try {
        const res = await unlockInvestorAccount(this.clientId);
        if (!res.code) {
          this.error = false;
          await this.$store.dispatch('setClientIPAccountStatus', this.clientId);
          this.closeModal(null);
        } else throw new Error();
      } catch (e) {
        this.error = true;
      }
    },
    closeModal(event) {
      if (event) { event.preventDefault(); }
      this.error = false;
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.$router.push(`/clients/${this.$route.params.clientID}`);
    },
  },
};
</script>

<style>

</style>
