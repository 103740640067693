<template>
  <v-dialog width="600px" px-5 :value="isOpen" persistent>
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon
          @click="$emit('close')"
          class="close-icon"
        >close</v-icon>
      </PasFab>
      <PasPageTitle class="mb-3">Upload</PasPageTitle>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent
      >
        <div class="strong-text my-4">Title</div>
        <PasInputField
          v-model="documentName"
          :rules="[v.isRequired()]"
          class="my-3"
          :placeholder="'Enter'"
        />
        <div class="strong-text my-4">Type</div>
        <PasInputFieldList
          v-model="documentType"
          :items="docTypesSelectItems"
          :rules="[v.isRequired()]"
        />
        <template v-for="(opt, i) in metaDataOpts">
          <template v-for="(field, j) in opt.UI">
            <div :key="`UI-${i}-${j}`">
            <div class="strong-text my-4">{{field.fieldLabel}}</div>
            <PasInputFieldList
              v-if="field.fieldType === 'select'"
              v-model="metaDataKeys[field.fieldName]"
              :items="field.items.type === 'entities' ? entities[field.items.entity] : field.items.values"
            />
            </div>
          </template>
        </template>
        <div v-if="documentType" class="mb-4 mt-1">
          <span class="strong-text my-4">Category:</span>
          <span>{{documentCategory[documentType] || ''}}</span>
        </div>
        <!-- archive selection. -->
        <div v-if="documentExists" class="strong-text my-3">
          You already have {{documentExists}} document(s).
          Would you like to archive the old one?
        </div>
        <div v-if="documentExists">
          <div class='upload-doc-checkbox' v-for="doc in existedDocs" :key="doc.id">
              <v-checkbox
              :label="`${doc.document_name} Uploaded at: ${moment(doc.created_at).format('YYYY/MM/DD')}`"
              :color="primaryColor"
              :value="doc"
              v-model="docsToArchive"
              class="mb-4"
              ></v-checkbox>
          </div>
        </div>

      </v-form>
      <span>{{maxFileSizeWarning}}</span>
      <PasDropzone
        ref="pasDropzone"
        :id="'documentUpload'"
        :class="'mb-3'"
        v-if="displayDropzone"
        :options="dropzoneOptions"
      />
      <v-layout justify-end>
        <PasButton
          @click="upload"
          :disabled="!isUploaded"
          :processing="isProcessing"
        >Upload</PasButton>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

// Libraries and services
import {
  DOCUMENT_TYPES, FILE_EXTENSIONS, DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE,
  CLIENT_DOCUMENT_MAX_FILESIZE, DOCUMENT_SOURCE, CLIENT_DOCUMENT_TIMEOUT,
} from 'Services/constants';
import _ from 'lodash';
import moment from 'moment';
import v from 'Services/validation';
import { modal } from 'Services/mixins';
import { mapGetters } from 'vuex';
import { getMetaDataOptsByDocType } from 'Services/api/Documents';

export default {
  name: 'DocumentUpload',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    documents: Array,
  },
  mounted() {
    this.setAccounts();
  },
  data() {
    const that = this;
    const acceptedFiles = FILE_EXTENSIONS.map((ext) => `.${ext}`).join(',');
    return {
      dropzoneOptions: {
        url: '/advisor_portal/upload_document',
        init() {
          this.on('addedfile', (file) => {
            that.isUploaded = true;
          });
          this.on('removedfile', (file) => {
            that.isUploaded = false;
          });
          this.on('sending', (file, xhr, formData) => {
            that.isProcessing = true;
            formData.append('client_id', that.activeClient.client.id);
            formData.append('document_type', that.documentType);
            formData.append('document_name', that.documentName);
            formData.append('document_source', that.documentSource.Manual);
            Object.keys(that.metaDataKeys).forEach((k) => {
              formData.append(k, that.metaDataKeys[k]);
            });
            xhr.ontimeout = () => this.emit('canceled', file);
          });
          this.on('success', (file, res) => {
            that.handleResponse(res, file);
          });
          this.on('error', (file, err) => {
            that.handleResponse(err, file);
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: CLIENT_DOCUMENT_MAX_FILESIZE,
        maxFiles: 1,
        chunking: false,
        chunkSize: 3000000, // Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        timeout: CLIENT_DOCUMENT_TIMEOUT,
        addRemoveLinks: true,
        autoProcessQueue: false,
        acceptedFiles,
      },
      v,
      existedDocs: [],
      docsToArchive: [],
      documentType: undefined,
      documentName: '',
      isUploaded: false,
      isProcessing: false,
      replaceDocument: false,
      documentCategory: DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE,
      metaDataOpts: [],
      metaDataSelections: {},
      uiSelections: {},
      entities: {},
      metaDataKeys: {}, // string list of keys for selected doc type, generated after get metadata types by doc type is called
    };
  },
  computed: {
    ...mapGetters([
      'docTypes',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts;
    },
    documentTypes() {
      return DOCUMENT_TYPES.map((e, i) => ({ value: i, text: e }));
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
    displayDropzone() {
      return this.documentName && (this.documentType || this.documentType === 0);
    },
    documentExists() {
      if (!this.documentType) return null;
      this.existedDocs = this.documents.filter((d) => d.document_type == this.documentType);
      if (!this.existedDocs.length) return null;
      return DOCUMENT_TYPES[this.existedDocs[0].document_type];
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    maxFileSizeWarning() {
      return `Note: File size must not exceed ${CLIENT_DOCUMENT_MAX_FILESIZE} MB`;
    },
    docManagementV2Enabled() {
      return this.$store.getters.hasFeatureFlag('doc_management_v2');
    },
    docTypesSelectItems() {
      return this.docTypes.map((d, i) => ({ value: i, text: d }));
    },
  },
  methods: {
    upload() {
      this.$refs.pasDropzone.$refs.dropzone.processQueue();
    },
    async handleResponse(res, file) {
      this.isUploaded = false;
      this.isProcessing = false;
      if (res.code !== 200) {
        this.snackBar('fail', this.getErrorMsg(res));
        this.$refs.pasDropzone.$refs.dropzone.removeFile(file);
        return;
      }
      let text = 'Successfully uploaded the document';
      if (this.docsToArchive.length) {
        const archivePromises = this.docsToArchive.map((d) => {
          d.is_archived = true;
          d.archived_at = moment().toISOString();
          return this.$store.dispatch('updateDocument', d);
        });
        const responses = await Promise.all(archivePromises);
        const replacedSuccessfully = responses.filter((res) => res).length;
        text = 'Successfully uploaded the document BUT failed to replace';
        if (replacedSuccessfully === responses.length) text = 'Successfully replaced the document';
        this.snackBar('success', text);
      }
      this.snackBar('success', 'Successfully uploaded the document');
      this.$refs.form.reset();
      this.docsToArchive = [];
      this.$store.dispatch('setDocuments', this.activeClient.client.id);
      this.$emit('close');
    },
    async getMetaDataOpts() {
      const opts = await getMetaDataOptsByDocType(this.documentType);
      this.metaDataOpts = opts.metadata_types;
      this.metaDataKeys = _.flatten(this.metaDataOpts.map((opt) => opt.UI.map((ui) => ui.fieldName)))
        .reduce((acc, curr) => {
          acc[curr] = '';
          return acc;
        }, {});
      this.metaDataSelections = {};
      this.metaDataOpts.forEach((opt) => {
        this.metaDataSelections[opt.MetaDataType] = {};
        opt.UI.forEach((ui) => {
          this.metaDataSelections[opt.MetaDataType][ui.fieldName] = null;
        });
      });
    },
    setAccounts() {
      this.entities.accounts = this.investmentAccounts.map(
        (ia) => (
          {
            text: `${ia.nickname} / ${ia.account_no_dataphile || ' - '}`,
            value: ia.id,
          }
        ),
      );
    },
    getErrorMsg(err) {
      if (err === 'Upload canceled.') return 'Upload took too long and was canceled. Please try uploading again.';
      if (typeof err === 'string' && err.includes('File is too big')) {
        return `File must be smaller than ${CLIENT_DOCUMENT_MAX_FILESIZE} MB`;
      }
      return 'Failed to upload document. If problem persists, please contact PAS Operations.';
    },
  },
  watch: {
    investmentAccounts: {
      deep: true,
      handler(val) {
        this.setAccounts();
      },
    },
    documentType: {
      handler(val) {
        this.getMetaDataOpts();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .upload-doc-checkbox {
    margin-bottom: -30px;
  }
</style>
