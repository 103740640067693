<template>
  <div class="my-4">

    <div v-if="theme === 'harness-fcc' || theme === 'harness'">
      Fee Groups allow clients to be grouped together for fee purposes.<br>
      <br>
      Only <span :style="{ 'font-weight': 600, 'text-decoration': 'underline' }"> immediate family members </span>should be grouped together in the same Fee Group.
      Immediate family members include: the client and spouse/common-law partner, their parents, their children, and any associated corporate and trust accounts.
      <br><br>
      To add a client to an existing Fee Group, the client must have an established relationship with a member of the Fee Group (including Corporations) in Advisor Center.
    </div>

    <template v-if="!feeGroup.name">
      <div class="mt-3">
        <span>
          {{clientDisplayName}} is not part of a fee group.
        </span>
        <span v-if="canDeleteFeeGroups">
          You can add {{clientDisplayName}} to a fee group
          <span class="action-link" @click="createGroup">here</span>.
        </span>
      </div>
    </template>

    <FeeGroupDetails
      v-if="feeGroup.name"
      :feeGroup="feeGroup"
      :feeGroups="feeGroups"
      :relationships="relationships"
      @update="update($event)"
      :disabled="!canDeleteFeeGroups"
    />

    <template v-if="feeGroup.name && feeGroupsIteams.length && canDeleteFeeGroups">
      <div class="mt-4 mb-3">
        {{clientDisplayName}} can join any of the available Fee Groups below.</br>
      </div>
      <v-layout>
        <v-flex shrink mr-3>
          <PasInputFieldList
            :items="feeGroupsIteams"
            v-model="selectedFeeGroup"
            :style="{ width: '300px' }"
          />
        </v-flex>
        <v-flex>
          <PasButton @click="join">{{selectedFeeGroup === 0 ? 'Create' : 'Join' }}</PasButton>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  fetchFeeGroup,
  joinFeeGroup,
  leaveFeeGroup,
  createFeeGroup,
  updateFeeGroup,
  deleteFeeGroup,
} from 'Services/api/FeeManagement';
import { RELATIONS } from 'Services/constants';
import { modal } from 'Services/mixins';
import _ from 'lodash';
import FeeGroupDetails from './ClientFeeGroup/FeeGroupDetails';

export default {
  name: 'ClientFeeGroup',
  components: {
    FeeGroupDetails,
  },
  mixins: [modal],
  data() {
    return {
      feeGroups: [],
      selectedFeeGroup: undefined,
    };
  },
  mounted() {
    this.loadFeeGroups();
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'clientId',
      'feeGroup',
      'canDeleteFeeGroups',
    ]),
    theme() {
      return this.$store.state.app.theme;
    },
    relationships() {
      const rel = {};
      this.activeClient.relationships.forEach((r) => {
        rel[r.client_details[0].id] = {
          display_name: r.client_details[0].display_name,
          type: RELATIONS[r.relationship_type],
          status: r.client_details[0].client_status,
        };
      });
      this.activeClient.corporate_roles.forEach((c) => {
        rel[c.client] = {
          display_name: c.client_details.display_name,
          status: c.client_details.client_status,
          type: 'Corporation', // placeholder
        }; // placeholder
        rel[c.corporate_client] = {
          display_name: c.corporate_client_details.display_name,
          status: c.corporate_client_details.client_status,
          type: 'Corporation', // placeholder
        };
      });
      rel[this.clientId] = {
        display_name: this.activeClient.client.display_name,
        type: '---',
        status: this.activeClient.client.client_status,
      };
      return rel;
    },
    feeGroupsIteams() {
      if (!this.feeGroups.length) return [];
      const feeGroups = this.feeGroups
        .filter((fg) => fg.id !== this.feeGroup.id)
        .map((fg) => ({ text: fg.name, value: fg.id }));
      if (this.feeGroup.clients.length > 1) { feeGroups.push({ text: '+ New Fee Group', value: 0 }); }
      return feeGroups;
    },
    clientDisplayName() {
      return this.activeClient?.client?.display_name;
    },
  },
  methods: {
    async join() {
      if (this.feeGroup.name) await this.leaveGroup();
      if (!this.selectedFeeGroup) await this.createGroup();
      else await this.joinExistingFeeGroup(this.selectedFeeGroup);
      this.selectedFeeGroup = undefined;
      this.loadFeeGroups();
    },
    async update(data) {
      await updateFeeGroup(data);
      this.$store.dispatch('setFeeGroup', this.clientId);
    },
    async leaveGroup() {
      const clientFeeGroupId = this.feeGroup.clients.filter((c) => c.client === this.clientId)[0].id;
      await leaveFeeGroup(clientFeeGroupId);
      if (this.feeGroup.clients.length === 1) deleteFeeGroup(this.feeGroup.id);
    },
    async createGroup() {
      try {
        const feeGroup = await createFeeGroup({
          name: `${this.clientDisplayName}'s Fee Group`,
        });
        if (!feeGroup.id) throw Error;
        const clientFeeGroupRes = await joinFeeGroup({
          client: this.clientId,
          fee_group: feeGroup.id,
        });
        if (!clientFeeGroupRes.id) throw Error;
        this.snackBar('success', 'Fee Group successfully created');
        this.$store.dispatch('setFeeGroup', this.clientId);
      } catch (err) {
        this.snackBar('fail', 'Failed to create a Fee Group');
      }
    },
    async joinExistingFeeGroup(feeGroupId) {
      try {
        const clientFeeGroupRes = await joinFeeGroup({
          client: this.clientId,
          fee_group: feeGroupId,
        });
        if (!clientFeeGroupRes.id) throw Error;
        this.snackBar('success', 'Successfully joined the fee group');
      } catch (err) {
        this.snackBar('fail', 'Failed to join the fee group');
      }
      this.$store.dispatch('setFeeGroup', this.clientId);
    },
    async loadFeeGroups() {
      try {
        const relationships = Object.keys(this.relationships).filter((r) => r !== this.clientId);
        const resRel = await Promise.all(relationships.map((r) => fetchFeeGroup(r)));
        this.feeGroups = _.uniqBy(resRel.filter((r) => r), 'id');
      } catch (err) {
        const text = 'Failed to fetch available fee group. Please refresh the page.';
        this.snackBar('fail', text);
      }
    },
  },
};
</script>
