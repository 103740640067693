
<template>
  <pas-fullscreen-modal @close="closeWindow" :isOpen="isOpen">
    <div v-if="screen === 2" class="strong-text back-doodle" @click="goBack">&larr; Back</div>

    <v-container class="modal-container">
      <v-layout column>
        <v-flex xs12 text-xs-center mb-3>
          <pas-page-title>Add Bank Account</pas-page-title>
        </v-flex>
        <v-flex xs12 text-xs-center mb-5>
          <span
            v-if="screen === 1"
          >To add a bank account, select from the list below and add the respective banking information.</span>
          <span v-if="screen === 2">To add a bank account, please enter the information below.</span>
        </v-flex>
      </v-layout>

      <template v-if="screen === 1">
        <v-form ref="one" :lazy-validation="true" @submit.prevent>
          <v-layout wrap :style="{  maxWidth: '950px', margin: '0 auto' }">
            <v-flex xs6 md3 text-xs-center v-for="(item, i) in institutions" :key="i">
              <v-btn @click="institutionNumber = item.value; screen = 2;" class="bank-institution">
                <v-layout align-center>
                  <v-flex sm4>
                    <div class="bank-logo">
                      <v-img :src="item.img"></v-img>
                    </div>
                  </v-flex>
                  <v-flex text-xs-left>
                    <span :style="{ whiteSpace: 'normal' }">{{item.text1}}</span>
                  </v-flex>
                </v-layout>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout column>
            <v-flex xs12 my-4 text-xs-center>
              <span>Can't find the bank above? We support over 2000 different financial institutions. Search for more here.</span>
            </v-flex>
            <v-flex>
              <pas-autocomplete
                :placeholder="'Type the name or website of the bank or financial institution'"
                :prependInnerIcon="'search'"
                :items="institutionNumbers"
                v-model="institutionNumber"
                :rules="[v => !!v || 'Institution number is required']"
                :style="{  maxWidth: '500px', margin: '0 auto' }"
              ></pas-autocomplete>
            </v-flex>
            <v-flex xs12 mt-4 text-xs-center>
              <pas-button @click="nextScreen" class="wide-btn">Next</pas-button>
            </v-flex>
          </v-layout>
        </v-form>
      </template>

      <template v-if="screen === 2">
        <v-form ref="two" :lazy-validation="true" @submit.prevent>
          <v-layout mt-2 wrap>
            <v-flex xs6 md3 px-4 mb-4 v-for="(item, i) in bankAccount" :key="i">
              <div>
                <label class="strong-text form-label my-2" :for="item.id">
                  {{item.fieldName}}
                  <sup>*</sup>
                </label>
                <pas-input-field
                  :id="item.id"
                  v-model="item.value"
                  :rules="item.rules"
                  v-if="item.fieldName !== 'Currency'"
                  :data-testid="`bank-account-${item.id}`"
                />
                <pas-button-toggle
                  v-else
                  :value="item.value === 'CAD' ? 0 : 1"
                  @input="bankAccount.currency.value = $event === 0 ? 'CAD' : 'USD'"
                  :mandatory="true"
                  :classToggle="'large-toggle'"
                >
                  <v-btn flat>CAD</v-btn>
                  <v-btn flat>USD</v-btn>
                </pas-button-toggle>
              </div>
            </v-flex>
            <v-flex xs6 md3 px-4 mb-4 v-if="!isFcc">
              <div v-if="activeClient.client.client_type === 'person'">
                <pas-add-item
                  v-if="!jointAccountOwner"
                  :text="'Add Account Holder'"
                  @click="jointAccountOwner = true"
                ></pas-add-item>
                <div v-else>
                  <div class="strong-text form-label my-2">Joint Bank Account</div>
                  <pas-autocomplete
                    v-model="secondaryClient"
                    :items="relationships"
                    :placeholder="'Specify with who'"
                    :rules="[v => !!v || 'Account number is required']"
                  ></pas-autocomplete>
                </div>
              </div>
            </v-flex>
            <v-flex px-4 mb-2 xs12>
              <div class="strong-text form-label my-2">Upload a void cheque PDF:</div>
            </v-flex>
            <v-flex px-4 xs12>
              <upload-void-cheque
                v-if="cleanDoc"
                @addVoidCheque="voidCheque = $event"
                @voidChequeExt="voidChequeExt = $event"
                @removeVoidCheque="voidCheque = null"
                @missingAccountNickname="validationText.accountNickname = true"
                @ocr="ocr($event)"
                @disableSave="disableSave"
              ></upload-void-cheque>
            </v-flex>
            <v-flex px-4 mb-3 xs12 mt-4>
              <span class="error--text">{{errorText}}</span>
            </v-flex>
            <v-flex px-4 mb-3 xs12>
              <span>
                Questions? Send us a note for
                <a
                  :href="`mailto:PASOperations@purposeadvisorsolutions.com`"
                  target="_blank"
                  :style="{ display: 'inline' }"
                  class="action-link"
                >help.</a>
              </span>
              <br />
              <span @click="goBack" class="action-link">Choose a different bank.</span>
            </v-flex>
          </v-layout>

          <v-layout align-center justify-start row fill-height px-4>
            <div v-for="(account, index) in tempAccounts" :key="index">
              <v-chip color="#eeeeee" class="little-pill">
                {{account.description}}
                <v-icon
                  @click="confirmDeletion(account)"
                  :style="{fontSize: '16px', opacity: '0.6'}"
                  class="ml-3"
                >close</v-icon>
              </v-chip>
            </div>
          </v-layout>
          <v-layout mt-5 px-4>
            <v-spacer></v-spacer>
            <pas-button
              class="inverted-btn wide-btn"
              @click="launchWorkflow"
              :disabled="!tempAccounts.length || saveProcessing || !openAccounts.length"
              :processing="launchWorkflowProcessing"
            >Launch Workflow</pas-button>
            <pas-button
              class="primary-btn wide-btn"
              @click="save"
              :disabled="launchWorkflowProcessing || disableSaveButton"
              :processing="saveProcessing"
            >Save</pas-button>
          </v-layout>
        </v-form>

        <v-dialog :value="ocrDialog" width="600px" height="300px">
          <v-card :style="{ padding: '20px'}">
            <v-card-title
              class="strong-text form-label"
            >Would you like to use banking information from the void cheque provided?</v-card-title>
            <v-card-text>
              Transit Number: {{ocrResults && ocrResults.transit ? ocrResults.transit : ''}}
              <br />
              Account Number: {{ocrResults && ocrResults.account ? ocrResults.account : ''}}
              <br />
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-layout align-center justify-end row fill-height>
                <v-flex shrink>
                  <pas-button class="inverted-btn wide-btn" @click="useOCR">Yes, Use It</pas-button>
                </v-flex>
                <v-flex shrink>
                  <pas-button
                    class="primary-btn wide-btn"
                    @click="ocrDialog = false"
                  >No, Enter Manually</pas-button>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-container>

    <ConfirmActionDialog
      @close="confirmDialog = false"
      @ok="deleteBankAccount"
      :isOpen="confirmDialog"
      :width="'400px'"
    >
      <template v-slot:header>Delete Bank Account</template>
      Are you sure you want to delete this account?
      <br />
      <br />
      {{`Account Info - Name: ${accountForDeletion ? accountForDeletion.description : ''}`}}
      <template
        v-slot:btn
      >Yes</template>
    </ConfirmActionDialog>
  </pas-fullscreen-modal>
</template>

<script>
import _ from 'lodash';

import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';
import TDlogo from 'Assets/icons/TD.png';
import BMOlogo from 'Assets/icons/BMO.jpg';
import CIBClogo from 'Assets/icons/CIBC.jpg';
import CClogo from 'Assets/icons/CC.jpg';
import NBlogo from 'Assets/icons/NB.png';
import RBClogo from 'Assets/icons/RBC.png';
import SBlogo from 'Assets/icons/SB.png';
import VClogo from 'Assets/icons/VC.jpg';
import api from 'Services/api';
import { mapGetters } from 'vuex';
import { createBankAccountOpeningWorkflow } from 'Services/api/Workflows';
import UploadVoidCheque from './AddBankAccount/UploadVoidCheque';

export default {
  name: 'add-bank-account',
  components: {
    UploadVoidCheque,
    ConfirmActionDialog,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      screen: 1,
      institutions: [
        {
          text1: 'TD Canada Trust',
          text: 'The Toronto Dominion Bank 004',
          value: '004',
          img: TDlogo,
        },
        {
          text1: 'RBC (Royal Bank of Canada)',
          text: 'Royal Bank Of Canada 003',
          value: '003',
          img: RBClogo,
        },
        {
          text1: 'BMO (Bank of Montreal)',
          text: 'Bank Of Montreal 001',
          value: '001',
          img: BMOlogo,
        },
        {
          text1: 'CIBC (Bank of Commerce)',
          text: 'Canadian Imperial Bank Of Commerce 010',
          value: '010',
          img: CIBClogo,
        },
        {
          text1: 'Scotiabank',
          text: 'The Bank Of Nova Scotia 002',
          value: '002',
          img: SBlogo,
        },
        {
          text1: 'National Bank',
          text: 'Banque Nationale Du Canada 006',
          value: '006',
          img: NBlogo,
        },
        {
          text1: 'Vancity',
          text: 'Vancouver City Savings Credit Union 809',
          value: '809',
          img: VClogo,
        },
        {
          text1: 'Coast Capital',
          text: 'Coast Capital 809',
          value: '809',
          img: CClogo,
        },
      ],
      institutionNumber: null,
      institutionNumbers: [],
      bankAccount: {
        transitNumber: {
          fieldName: 'Transit Number',
          id: 'transit-number',
          value: null,
          rules: [
            (v) => !!v || 'Transit number is required',
            (v) => /^\d{5}$/.test(v) || 'Must be 5 numbers',
          ],
        },
        accountNumber: {
          fieldName: 'Account Number',
          id: 'account-number',
          value: null,
          rules: [
            (v) => !!v || 'Account number is required',
            (v) => /^\d+$/.test(v) || 'Input should be a number',
            (v) => (v || '').length < 99 || 'The account number is too long',
          ],
        },
        currency: {
          fieldName: 'Currency',
          id: 'currency',
          value: 'CAD',
          rules: [(v) => !!v || 'Currency is required'],
        },
        accountNickname: {
          fieldName: 'Account Nickname',
          id: 'account-nickname',
          value: null,
          rules: [
            (v) => (v || '').length > 0 || 'Nickname is required',
            (v) => /^(?!\s*$).+/.test(v) || 'Nickname is required',
            (v) => (v || '').length <= 24 || 'Nickname is too long',
          ],
        },
      },
      voidCheque: null,
      voidChequeExt: null,
      errorText: '',
      ocrResults: {},
      ocrDialog: false,
      secondaryClient: null,
      jointAccountOwner: false,
      cleanDoc: true,
      launchWorkflowProcessing: false,
      saveProcessing: false,
      accountForDeletion: null,
      confirmDialog: false,
      disableSaveButton: false,
    };
  },
  computed: {
    ...mapGetters(['bankInstitutions', 'hasFeatureFlag', 'custodian', 'currencyTypes']),
    isFcc() {
      return this.custodian === 'fcc';
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    relationships() {
      return this.activeClient.relationships.map((r) => {
        const display_name = r.client_details[0]
          ? r.client_details[0].display_name
          : '';
        const client_2_id = r.client_details[0] ? r.client_details[0].id : '';
        return { text: display_name || '', value: client_2_id };
      });
    },
    tempAccounts() {
      return this.activeClient.bankAccounts.filter((a) => a.status === 'temporary');
    },
    openAccounts() {
      return this.activeClient.investment_accounts.filter((a) => a.account_status === 'O');
    },
    filteredInstitutionNumber() {
      // remove everything but the institution number to use for api call
      return this.institutionNumber.split(' ')[0];
    },
    firm() {
      return this.$store.getters.firm;
    },
  },
  methods: {
    fetchBanksInfo() {
      return this.$store.dispatch('fetchBankInstitutions');
    },
    clearInput() {
      if (this.$refs.two) this.$refs.two.reset();
      this.bankAccount.currency.value = 'CAD';
      this.jointAccountOwner = false;
      this.secondaryClient = null;
      this.voidCheque = null;
      this.cleanDoc = false;
      this.$nextTick().then(() => {
        this.cleanDoc = true;
      });
      this.errorText = '';
    },
    goBack() {
      if (this.voidCheque) {
        this.$store.dispatch('deleteDocument', {
          docID: this.voidCheque,
          clientID: this.activeClient.client.id,
        });
      }
      this.clearInput();
      this.screen = 1;
    },
    closeWindow() {
      this.goBack();
      this.institutionNumber = null;
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
    nextScreen() {
      if (!this.$refs.one.validate()) return null;
      this.screen = 2;
    },
    ocr(results) {
      this.ocrResults = results;
      results && results.institution && results.transit && results.account
        ? (this.ocrDialog = true)
        : null;
    },
    disableSave(event) {
      this.disableSaveButton = event;
    },
    getDeletedBankAccount() {
      const deleted = this.activeClient.bankAccounts.filter((b) => b.institution_no === this.filteredInstitutionNumber && b.transit_no === this.bankAccount.transitNumber.value && b.account_no === this.bankAccount.accountNumber.value && b.status === 'D');
      return deleted;
    },
    isDuplicateBankAccount() {
      if (
        this.activeClient.bankAccounts.some(
          (b) => b.institution_no === this.filteredInstitutionNumber && b.secondary_client !== this.activeClient.client.id,
        )
      ) {
        if (
          this.activeClient.bankAccounts.some(
            (b) => b.transit_no === this.bankAccount.transitNumber.value,
          )
        ) {
          if (
            this.activeClient.bankAccounts.some(
              (b) => b.account_no === this.bankAccount.accountNumber.value,
            )
          ) {
            return true;
          }
        }
      }
      return false;
    },
    useOCR() {
      this.bankAccount.transitNumber.value = this.ocrResults.transit;
      this.bankAccount.accountNumber.value = this.ocrResults.account;
      this.ocrDialog = false;
    },
    snackBar(type, text) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async save() {
      this.errorText = '';
      if (!this.currencyTypes.length) {
        await this.$store.dispatch('setCurrencyTypes');
      }
      if (!this.validate()) return;
      if (!this.$refs.two.validate()) return null;
      // if a workflow is being launched, prevent save
      if (this.launchWorkflowProcessing) return null;
      this.saveProcessing = true;
      const deletedAccount = await this.getDeletedBankAccount();
      try {
        // check if the transit and institution numbers are valid
        const bankInfo = `${this.bankAccount.transitNumber.value}-${this.filteredInstitutionNumber}`;
        const validBankAccount = await this.$store.dispatch(
          'validateBankAccount',
          bankInfo,
        );
        if (!validBankAccount) {
          this.errorText = 'Invalid institution or transit number';
          throw Error;
        }
        let bankAccountRes;
        if (deletedAccount.length === 0) {
          const data = {
            institution_no: this.filteredInstitutionNumber,
            transit_no: this.bankAccount.transitNumber.value,
            account_no: this.bankAccount.accountNumber.value,
            currency: this.bankAccount.currency.value,
            description: this.bankAccount.accountNickname.value,
            client: this.activeClient.client.id,
            secondary_client: this.secondaryClient,
            document: this.voidCheque,
            status: 'T',
          };
          bankAccountRes = await this.$store.dispatch(
            'addBankAccount',
            data,
          );
        } else {
          bankAccountRes = await this.$store.dispatch(
            'updateBankAccounts',
            {
              accountID: deletedAccount[0].id,
              data: {
                currency: this.bankAccount.currency.value,
                description: this.bankAccount.accountNickname.value,
                client: this.activeClient.client.id,
                secondary_client: this.secondaryClient,
                document: this.voidCheque,
                status: 'T',
              },
            },
          );
        }
        if (!bankAccountRes.id) throw Error;
        // update document name
        const documentData = {
          id: bankAccountRes.document,
          document_name: `Void Cheque or Bank Statement for ${this.bankAccount.accountNickname.value} account${this.voidChequeExt}`,
          associated_clients: this.secondaryClient
            ? [this.activeClient.client.id, this.secondaryClient]
            : [this.activeClient.client.id],
        };
        if (this.isFcc) {
          documentData.document_name = `Void Cheque for 0${this.filteredInstitutionNumber}-${this.bankAccount.transitNumber.value}-${this.bankAccount.accountNumber.value}`;
        }
        const documentRes = await this.$store.dispatch(
          'updateDocument',
          documentData,
        );
        this.snackBar('success', 'Account successfully added to the profile');
        this.clearInput();
      } catch (err) {
        this.snackBar('fail', 'Error in adding bank account');
      }
      this.saveProcessing = false;
    },
    confirmDeletion(account) {
      if (account.status === 'processing' || account.status === 'verified') {
        this.snackBar('fail', 'Cannot delete active bank account');
        throw Error;
      }
      this.accountForDeletion = account;
      this.confirmDialog = true;
    },
    async deleteBankAccount() {
      try {
        const data = {
          document: this.accountForDeletion.document,
          client: this.activeClient.client.id,
          id: this.accountForDeletion.id,
        };
        const deleteAccountRes = await this.$store.dispatch(
          'deleteBankAccounts',
          data,
        );
        if (!deleteAccountRes) throw Error;
        this.snackBar('success', 'Account successfully deleted');
      } catch (err) {
        this.snackBar('fail', 'Error in deleting account');
      }
      this.confirmDialog = false;
      this.accountForDeletion = null;
    },
    async launchOldWorkflow() {
      // create new workflow
      try {
        await api
          .post('/advisor_portal/workflow/new/', {
            pas_client_ids: [this.activeClient.client.id],
            workflow_type: 'bank_account_setup',
          })
          .catch((err) => {
            throw Error;
          });
        this.snackBar(
          'success',
          'Bank account setup workflow has been successfully created',
        );
        this.$store.dispatch('getWorkflows', this.activeClient.client.id);
        this.$store.dispatch('getBankAccounts', this.activeClient.client.id);
        this.launchWorkflowProcessing = false;
        this.closeWindow();
      } catch (err) {
        this.snackBar('fail', 'Error in launching workflow');
        this.launchWorkflowProcessing = false;
      }
    },
    async launchWorkflow() {
      // if a bank account is being saved, can't launch a workflow
      if (this.saveProcessing) return;
      this.launchWorkflowProcessing = true;

      try {
        if (this.hasFeatureFlag('new_workflows')) {
          const bankAccountIds = this.tempAccounts.map((x) => x.id);
          await createBankAccountOpeningWorkflow([this.activeClient.client.id], bankAccountIds);
        } else {
          await this.launchOldWorkflow();
        }
        this.$store.dispatch('getWorkflows', this.activeClient.client.id);
        this.$store.dispatch('getBankAccounts', this.activeClient.client.id);
        this.launchWorkflowProcessing = false;
        this.closeWindow();
      } catch (err) {
        this.snackBar('fail', 'Error in launching workflow');
        this.launchWorkflowProcessing = false;
      }
    },

    validate() {
      if (this.getDeletedBankAccount()?.length > 0) {
        return true;
      }
      if (this.isDuplicateBankAccount()) {
        this.errorText = 'This account has already been added. Please try a different account.';
        return false;
      }
      if (this.isFcc) {
        if (!this.voidCheque) {
          this.errorText = 'A bank statement or void cheque is required.';
          return false;
        }
        if (this.tempAccounts.length >= 3) {
          this.errorText = 'Please launch workflow before adding more bank accounts.';
          return false;
        }
        // need to get the iso code of the currency for comparison with the temp bank accounts' currency
        const existCurrencyIds = this.tempAccounts.map((acc) => acc.currency.toString());
        const existCurrencyObjects = this.currencyTypes.filter((currency) => existCurrencyIds.includes(currency.id));
        const existCurrency = existCurrencyObjects.map((currency) => currency.iso_code);
        if (this.tempAccounts.length && !existCurrency.includes(this.bankAccount.currency.value)) {
          this.errorText = 'The selected bank accounts use multiple currencies. All accounts must use the same currency (CAD or USD).';
          return false;
        }
      }
      return true;
    },
  },
  async mounted() {
    await this.fetchBanksInfo();
  },
  watch: {
    isOpen(val) {
      if (val && !this.institutionNumbers.length) {
        this.institutionNumbers = this.bankInstitutions.map((bank) => {
          const institutionNo = `000${bank.institution_no.toString()}`.substr(
            -3,
          );
          const bankName = bank.bank_name
            .toLowerCase()
            .replace(/\w+/g, _.capitalize);
          return {
            // converting the number into a three digit string
            text: `${bankName} (${institutionNo})`,
            value: `${`000${bank.institution_no.toString()}`.substr(
              -3,
            )} ${bankName}`, // value needs to be unique
          };
        });
      }
    },
    confirmDialog(val) {
      if (!val) this.accountForDeletion = '';
    },
  },
};
</script>


<style lang="scss">
@import "~Styles/variables";

.bank-institution {
  width: 215px !important;
  height: 70px !important;
  box-shadow: none !important;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  text-transform: none !important;
  &:hover {
    box-shadow: $soft-box-shadow !important;
  }
  &:before {
    background-color: #f5f5f5 !important;
  }
}
.bank-logo {
  width: 50px !important;
  margin-left: 10px;
  margin-right: 10px;
  .v-image__image {
    border-radius: 2px;
  }
}

.little-pill {
  background-color: #f5f5f5 !important;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5f5f5),
    to(#f1f1f1)
  ) !important;
  border: 1px solid rgba(0, 0, 0, 0.01) !important;
}
</style>
