<template>
  <div>
    <v-layout row wrap justify-end pt-3>
      <template v-for="(r, i) in slicedRelationships.slice(0,3)">
        <v-flex mb-2 ml-3 :key="`rel-${i}`" shrink text-xs-right>
          <v-btn depressed icon @click="viewProfile(r.client_2_id)">
            <v-tooltip top>
              <pas-avatar
                size='50'
                slot="activator"
                class="client-avatar"
                :isCorporate="r.corporate_role && r.client_type !== 'person'"
                :avatar="getAvatar('clients',r.client_2_id)"
              />
              <span>
                {{`${r.display_name}`}}
              </span>
              <br>
              <span>{{`(${r.relationship_type})`}}</span>
            </v-tooltip>
          </v-btn>
        </v-flex>
      </template>
    </v-layout>
    <v-layout row wrap justify-end pt-3>
      <v-flex
        v-for="(r, i) in slicedRelationships.slice(3, 6)"
        :key="`rel-${i}`"
        mb-2
        ml-3
        shrink
        text-xs-righ
      >
        <v-btn depressed icon @click="viewProfile(r.client_2_id)">
          <v-tooltip top>
            <pas-avatar
              size='50'
              slot="activator"
              class="client-avatar"
              :isCorporate="r.corporate_role && r.client_type !== 'person'"
              :avatar="getAvatar('clients',r.client_2_id)"
            />
            <span>
              {{`${r.display_name} (${r.relationship_type})`}}
            </span>
          </v-tooltip>
        </v-btn>
      </v-flex>
      <v-flex shrink :key="`add-rel`" ml-3>
        <v-tooltip top>
          <v-btn
            slot="activator"
            color="rgba(0,0,0,0.1)"
            dark small fab size="50"
            class="add-rel-fab"
            @click="isPersonClient ? addRelationship() : addRoles()"
            v-if="!disableAdd"
          >
            <v-icon color="rgba(0,0,0,0.2)">add</v-icon>
          </v-btn>
          <span>{{ this.clientType === 'person' ? 'Add relationship' : 'Add role' }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <define-roles
      :isOpen="defineRolesModal"
      @close="defineRolesModal = false"
      @save="saveRoles"
    />
    <add-edit-relationship
      v-if="!disableAdd"
      :isOpen="addEditRelationship"
      :action="action"
      :relationship="relationship"
      @close="addEditRelationship = false"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { getAvatar } from 'Services/helpers/files';
import DefineRoles from 'Components/Shared/DefineRoles';
import AddEditRelationship from 'Components/Shared/AddEditRelationship';
import { getRoleSummary } from 'Services/helpers/corporateRoles'

export default {
  name: 'relationships',
  components: {
    DefineRoles,
    AddEditRelationship,
  },
  props: {
    relationships: Array,
    clientType: String,
  },
  data() {
    return {
      getAvatar,
      defineRolesModal: false,
      addEditRelationship: false,
      action: null,
      relationship: undefined,
      getRoleSummary,
    };
  },
  mounted() {
    this.$store.dispatch('fetchRelationships', this.activeClient.client.id);
  },
  computed: {
    ...mapGetters([
      'canEditClientRelationships',
      'canEditCorporateClientRelationships',
      'isPersonClient',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    disableAdd() {
      return (this.clientType === 'corporation' && !this.canEditCorporateClientRelationships) || !this.canEditClientRelationships;
    },
    corporateRoles() {
      return this.activeClient.corporate_roles
        .filter((r) => {
          const client = this.clientType === 'person' ? r.client : r.corporate_client;
          return client === this.activeClient.client.id;
        })
        .map((r) => {
          const rel = { corporate_role: true };
          const id = this.clientType === 'person' ? r.corporate_client : r.client;
          const details = this.clientType === 'person' ? r.corporate_client_details : r.client_details;

          rel.client_2_id = id;
          rel.display_name = details.display_name;
          rel.relationship_type = this.getRoleSummary(r, {
            onlyRoles: false,
            truncateOnNthRole: 2,
          });
          rel.percentage_ownership = r.percentage_ownership;
          rel.client_type = details.client_type;
          return rel;
        });
    },
    slicedRelationships() {
      return this.relationships.concat(this.corporateRoles).slice(0, 5);
    },
  },
  methods: {
    viewProfile(clientID) {
      this.$router.push(`/clients/${clientID}`);
    },
    addRelationship() {
      this.$store.dispatch('fetchClientsWithPermissions');
      this.addEditRelationship = true;
      this.action = 'add';
    },
    addRoles() {
      this.$store.dispatch('fetchClientsWithPermissions');
      this.defineRolesModal = true;
    },
    edit(r) {
      this.addEditRelationship = true;
      this.action = 'edit';
      this.relationship = r;
    },
    saveRoles() {
      this.defineRolesModal = false;
      this.$store.dispatch('fetchCorporateRoles', this.activeClient.client.id);
    },
  },
};
</script>

<style scoped lang="scss">

@import '~Styles/variables';

.add-rel-fab {
  box-shadow: $soft-box-shadow !important;
  height: 50px !important;
  width: 50px !important;
  position: top-left;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-top: 6px;
}
.relationship-flex {
  width: 50px !important;
  margin-bottom: 12px !important;
  cursor: pointer;
}

</style>
