<template>
    <div>
        <v-container class="roles-and-permissions-manager">
            <template v-if="this.isLoading">
                <v-layout justify-center align-center>
                    <PasCircularLoader
                        :loadingMessage="`Loading roles and permissions manager...`">
                    >
                    </PasCircularLoader>
                </v-layout>
            </template>
            <template v-else-if="this.rolesAndPermissionsFeatureEnabled">
                <v-layout>
                    <v-flex xs12 text-xs-center>
                        <PasPageTitle class="mb-2">Roles and Permissions Manager</PasPageTitle>
                        <p class="mb-5">Use the manager to modify view, edit, and delete permissions for the selected user role.</p>
                    </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
                <v-layout align-end class="mb-3">
                    <v-flex md3>
                        <PasDropDown
                            :items="this.permissions.roles"
                            :value="selectedRole.text"
                            :hideSelected="false"
                            :prependInnerIcon="'account_box'"
                            class="mr-3 elevation-1"
                            solo
                            hideDetails
                            returnObject
                            @change="selectRole"
                        >
                        </PasDropDown>
                    </v-flex>
                    <v-flex md9>
                        <v-layout justify-end>
                            <PasButton
                                @click="savePermissions"
                                class="primary-btn wide-btn mr-2"
                                :disabled="!isEdited"
                            >Save Permissions</PasButton>
                            <PasButton
                                @click="toggleModule"
                                class="wide-btn darken-1 white--text"
                                :class="[ this.selectedModule.is_available ? 'red' : 'green' ]"
                            >{{ this.selectedModule.is_available ? 'Disable Module' : 'Enable Module' }}</PasButton>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex md3>
                        <v-card
                        class="elevation-1 mr-3"
                        >
                            <v-list>
                                <v-list-tile
                                    v-for="module in selectedRole.modules"
                                    :key="module.module_id"
                                    @click="selectModule(module);"
                                    :color="activeModuleId === module.module_id ? primaryColor : 'black'"
                                    :class="{'is-active' : activeModuleId === module.module_id}"
                                >
                                <v-list-tile-action>
                                    <v-icon>view_module</v-icon>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{ module.module_name }}</v-list-tile-title>
                                </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-card>
                    </v-flex>
                    <v-flex xs9>
                        <v-data-table
                            :headers="headers"
                            :items="selectedModule.submodules"
                            :key="selectedModule.submodules.submodule_name"
                            class="elevation-1"
                            hide-actions
                        >
                            <template v-slot:items="selectedSubmodule">
                                <td class="text-xs-center module-name">{{ selectedSubmodule.item.submodule_name }}</td>
                                <td v-for="(permission) in ['read', 'write', 'delete']" :key="selectedSubmodule.item.submodule_id + permission">
                                    <v-checkbox
                                        @change="selectPermission(selectedSubmodule.item, selectedSubmodule.item.submodule_access[permission])"
                                        class="justify-center"
                                        v-model="selectedSubmodule.item.submodule_access[permission]"
                                        hide-details
                                        :color="primaryColor"
                                        :disabled="!selectedModule.is_available"
                                    >
                                    </v-checkbox>
                                </td>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
            </template>
        </v-container>
    </div>
</template>

<script>

import _ from 'lodash';
import { fetchRolesAndPermissions, updateRolesAndPermissions, toggleRolesAndPermissionsModule } from 'Services/api/RolesAndPermissions';

export default {
  name: 'RolesAndPermissions',
  data() {
    return {
      headers: [
        {
          text: 'Submodule',
          value: 'module_name',
          align: 'center',
          sortable: false,
          width: '40%',
        },
        {
          text: 'View',
          value: 'view',
          align: 'center',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Edit',
          value: 'edit',
          align: 'center',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Delete',
          value: 'delete',
          align: 'center',
          sortable: false,
          width: '20%',
        },
      ],
      permissions: {
        roles: [],
      },
      selectedModule: [],
      isLoading: true,
      activeModuleId: '',
      selectedRole: '',
      isEdited: false,
    };
  },
  async mounted() {
    try {
      const rolesAndPermissions = await fetchRolesAndPermissions(this.$store.dispatch);
      rolesAndPermissions.permissions.roles.forEach((role) => {
        if (role.modules.length) {
          // map the role to a new role object
          const newRole = role;
          // add extra properties for display purposes
          newRole.text = _.startCase(role.role_name);
          newRole.icon = 'account_box';
          newRole.id = role.role_id;
          newRole.isEdited = false;
          // add edited flags to track changes
          newRole.modules.map((module) => {
            module.isEdited = false;
            module.module_name = _.startCase(module.module_name);
            module.submodules.map((submodule) => {
              submodule.submodule_name = _.startCase(submodule.submodule_name);
              submodule.isEdited = false;
            });
          });
          // add the new role object to component roles  data
          this.permissions.roles.push(newRole);
        }
      });
      this.selectRole(this.permissions.roles[0]);
      this.selectModule(this.selectedRole.modules[0]);
      this.isLoading = false;
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    selectRole(selectedRole) {
      this.selectedRole = selectedRole;
      this.selectModule(this.selectedRole.modules[0]);
    },
    selectModule(selectedModule) {
      this.selectedModule = selectedModule;
      this.activeModuleId = this.selectedModule.module_id;
    },
    selectPermission(selectedSubmodule, selectedPermission) {
      const submoduleAccess = selectedSubmodule.submodule_access;
      // check or uncheck depending on level of access
      if (selectedPermission) {
        if (submoduleAccess.delete) {
          submoduleAccess.write = true;
          submoduleAccess.read = true;
        } else if (submoduleAccess.write) {
          submoduleAccess.read = true;
        }
      } else if (!selectedPermission) {
        if (!submoduleAccess.read) {
          submoduleAccess.write = false;
          submoduleAccess.delete = false;
        } else if (!submoduleAccess.write) {
          submoduleAccess.delete = false;
        }
      }
      // flag as edited
      this.selectedRole.isEdited = true;
      this.selectedModule.isEdited = true;
      selectedSubmodule.isEdited = true;
      this.isEdited = true;
    },
    async toggleModule() {
      // format data for toggle module endpoint
      const module = {
        role: this.selectedRole.role_id,
        module: this.selectedModule.module_id,
        is_available: !this.selectedModule.is_available,
      };
      const roleGroupId = this.selectedModule.module_role_group_id;
      try {
        await toggleRolesAndPermissionsModule(roleGroupId, module, this.$store.dispatch);
        this.selectedModule.is_available = !this.selectedModule.is_available;
      } catch (err) {
        console.log(err);
      }
    },
    async savePermissions() {
      const modifiedPermissions = { permissions: { roles: [] } };
      // loop through each role
      this.permissions.roles.forEach((role) => {
        if (role.isEdited) {
          // only use the properties needed for the api call
          const modifiedRole = _.pick(role, ['role_name', 'is_available_to_user', 'role_id']);
          const modifiedModules = [];
          role.modules.forEach((module) => {
            if (module.isEdited) {
              // only use the properties needed for the api call
              const modifiedModule = _.pick(module, ['module_id', 'is_available', 'is_available_to_user', 'module_name', 'module_role_group_id']);
              const modifiedSubmodules = [];
              module.submodules.forEach((submodule) => {
                if (submodule.isEdited) {
                  // only use properties needed for the api call
                  modifiedSubmodules.push(_.pick(submodule, ['submodule_name', 'submodule_id', 'submodule_access']));
                }
              });
              // add submodules array of objects with modified submodules to the module object
              modifiedModule.submodules = modifiedSubmodules;
              modifiedModules.push(modifiedModule);
            }
          });
          // add modules array of objects with modified modules to the role object
          modifiedRole.modules = modifiedModules;
          modifiedPermissions.permissions.roles.push(modifiedRole);
        }
      });
      try {
        await updateRolesAndPermissions(modifiedPermissions, this.$store.dispatch);
        this.isEdited = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    rolesAndPermissionsFeatureEnabled() {
      return this.$store.state.app.features.roles_and_permissions;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style lang="scss">
.roles-and-permissions-manager {
  .v-list__tile__action {
    min-width: 40px !important;
  }
  .drop-down {
      .v-input__slot {
          padding: 0px 16px !important;
          border-color: rgba(0,0,0,0.2) !important;
          border-width: 1px !important;
          max-width: 100%;
          .v-select__slot {
            margin-left: 11px !important;
            width: 100% !important;
              .v-select__selections {
                max-width: 88%
              }
              .v-select__selection {
                font-size: 16px !important;
                white-space: nowrap;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 88%;
              }
          }
          i {
              font-size: 25px !important;
          }
      }
  }
  .v-card {
      margin-bottom: 30px !important;
      .v-list {
          padding: 0;
          transition: none;
          .v-list__tile__title {
              font-size: 16px !important;
              align-self: center !important;
              text-overflow: ellipsis !important;
              overflow: hidden !important;
          }
          .is-active {
              font-weight: 600 !important;
              background-color:rgb(254, 254, 254);
          }
      }
  }
  table.v-datatable {
      tbody {
          td  {
              font-size: 14px;
          }
          .v-input--selection-controls__input {
              margin: 0;
          }
      }
      thead {
          tr {
              background-color: rgb(254, 254, 254) !important;
          }
          th {
              font-size: 14px !important;
              color: black !important;
              font-weight: 600;
          }
      }
  }
}

</style>
