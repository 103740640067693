<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    :prepend-inner-icon="prependInnerIcon"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blur')"
    @keyup.enter="$emit('enter', $event)"
    outline
    single-line
    class="base-autocomplete"
    :autofocus="true"
    :no-data-text="noDataText"
    :small-chips="smallChips"
    :label="label"
    :return-object="returnObject"
    :deletable-chips="deletableChips"
    :attach="attach"
  >
    <slot></slot>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'pas-autocomplete',
  data() {
    return {
    };
  },
  props: {
    value: [String, Object, Number, Boolean],
    items: Array,
    noDataText: {
      type: String,
      default: 'No data available',
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    label: String,
    deletableChips: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.base-autocomplete {
  .v-input__slot {
    padding: 0px 20px !important;
    border-color: rgba(0,0,0,0.2) !important;
    border-width: 1px !important;
    border-radius: 40px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 40px !important;
  }
  min-height: 40px !important;
  height: 40px !important;
  .v-input__prepend-inner {
    margin-top: 7px !important;
  }
  .v-input__append-inner {
    margin-top: 8px !important;
  }
  .v-chip {
    background-color: #F3EDE3 !important;
    color: var(--primary-color) !important;
  }
  .v-menu__content {
		max-width: 225% !important;
  }
  .v-select-list .v-list__tile__content {
     padding: 0px 0px !important;
}
}
</style>
