<template>
  <PModal :visible="visible" centered @click="$emit('click')" @hide="$emit('closeModal')">
    <div class="subtitle bold mb-1">{{ this.amount }}</div>
    <div v-if="this.underReviewMessage !== ''" class="mb-2">{{ this.underReviewMessage }}</div>
    <div v-else-if="this.transaction.transaction_card_date" class="subtitle mb-3">{{ `${status_descriptions[transaction.transaction_status]} ${this.date}` }}</div>
    <div class="subtitle bold mb-3">Details</div>
    <!-- TODO: LOOP THROUGH THESE INSTEAD, CREATE A COMPONENT FOR TRANSACTION PROPERTIES -->
    <p v-if="this.transaction.client_name" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'user']" class="mr-2"/>{{ this.clientRole }}</span> {{ this.transaction.client_name }}</p>
    <p v-if="this.transaction.joint" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'users']" class="mr-2"/>{{ this.jointClientRole }}</span> {{ this.transaction.joint }}</p>
    <p v-if="this.isTransfer" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'sack-dollar']" class="mr-2"/>{{ this.inKindTransfer ? "Partial" : "Full" }}</span> {{ this.netAmount }} {{ this.transaction.currency  }}</p>
    <p v-if="this.transaction.from_account" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'arrow-left']" class="mr-2"/>From</span> {{ this.transaction.from_account }}</p>
    <p v-if="this.transaction.to_account" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'arrow-right']" class="mr-2"/>To</span> {{ this.transaction.to_account }}</p>
    <p v-if="this.transaction.model_portfolio" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'chart-pie']" class="mr-2"/>Portfolio</span> {{ this.transaction.model_portfolio }}</p>
    <p v-if="this.beneficiaryExists" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'hand-heart']" class="mr-2"/>Beneficiary</span> {{ this.beneficiary }}</p>
    <p v-if="this.transaction.reason" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'folder']" class="mr-2"/>Type</span> {{ this.transaction.reason }}</p>
    <p v-if="this.transaction.gross_amount && !this.isGrossAndNetEqual" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'sack-dollar']" class="mr-2"/>Gross amount</span> {{ this.grossAmount }} {{ this.transaction.currency  }}</p>
    <p v-if="this.transaction.net_amount && !isTransfer" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'wallet']" class="mr-2"/>Net Amount</span> {{ this.netAmount }} {{ this.transaction.currency }}</p>
    <p v-if="this.transaction.withholding_tax" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'box-usd']" class="mr-2"/>Tax</span> {{ this.taxAmount }}</p>
    <p v-if="this.transaction.frequency" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'clock']" class="mr-2"/>Recurring</span> {{ this.frequency }}</p>
    <p v-if="this.inKindTransfer" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'sack-dollar']" class="mr-2"/>In Kind Assets</span> {{ this.transaction.relinquishing_institution }}</p>
    <p v-if="this.transaction.event_creation_date" class="mb-2"><span class="font-weight-bold"><font-awesome-icon :icon="['fal', 'calendar']" class="mr-2"/>Created</span> {{ this.createdDate }}</p>
    <div class="d-flex flex-row justify-content-end mt-4">
      <div>
        <PButton variant="light ml-2" size="lg" @click="openClientProfile">View profile</PButton>
        <PButton variant="light ml-2" size="lg" @click="$emit('closeModal')">Close</PButton>
      </div>
    </div>
  </PModal>
</template>

<script>
import { FREQUENCIES } from 'Services/constants';
import moment from 'moment';

export default {
  name: 'TransactionModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    transaction: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      frequencies: FREQUENCIES,
      isLoading: true,
      ICON_SIZE: 20,
      // TODO: USE BETTER MAPPING FOR RELEASE 2
      status_descriptions: {
        'CLIENT-SIGNATURE': 'Sent to Client',
        'UNDER REVIEW': 'Now Expected',
        PENDING: 'Expected',
        PROCESSED: 'Completed',
        CANCELLED: 'Cancelled',
      },
    };
  },
  methods: {
    openClientProfile() {
      window.open(`/advisor_portal/clients/${this.clientId}`, '_blank');
    },
  },
  computed: {
    clientId() {
      return this.transaction.client_id;
    },
    amount() {
      return `$${new Intl.NumberFormat().format(this.transaction.net_amount)} ${this.transaction.event_type}` || null;
    },
    date() {
      return this.transaction.transaction_status === 'UNDER REVIEW' || this.transaction.transaction_status === 'PENDING'
        ? `${moment(this.transaction.transaction_card_date).format('MMM DD')} - ${moment(this.transaction.transaction_card_date).add(1, 'days').format('MMM DD')}`
        : `${moment(this.transaction.transaction_card_date).format('MMMM Do, YYYY')}`;
    },
    frequency() {
      return FREQUENCIES[this.transaction.frequency] || null;
    },
    createdDate() {
      return moment(this.transaction.event_creation_date).format('MMM DD, YYYY');
    },
    grossAmount() {
      return `$${new Intl.NumberFormat().format(this.transaction.gross_amount)}`;
    },
    netAmount() {
      return `$${new Intl.NumberFormat().format(this.transaction.net_amount)}`;
    },
    taxAmount() {
      return `$${new Intl.NumberFormat().format(this.transaction.withholding_tax)}`;
    },
    isGrossAndNetEqual() {
      return this.transaction.gross_amount === this.transaction.net_amount;
    },
    beneficiary() {
      return this.transaction.beneficiary.length > 1 ? this.transaction.beneficiary.join(', ') : this.transaction.beneficiary[0];
    },
    beneficiaryExists() {
      if (this.transaction.hasOwnProperty('beneficiary')) {
        if (this.transaction.beneficiary.length > 0) {
          return true;
        }
      }
      return false;
    },
    underReviewMessage() {
      if (['Deposit', 'Withdrawal'].includes(this.transaction.event_type)) {
        if (['15', '16'].includes(this.transaction.account_type)) {
          return 'We are sending your forms to unlock the LIRA. If you have not received your forms by 2 business days, please contact us.';
        }
        if (['17', '18'].includes(this.transaction.account_type)) {
          return 'Please submit the RESP withdrawal form and proof of enrollment to our team. The RESP withdrawal form can be found here. If you have any questions please contact us.';
        }

        if (this.transaction.transaction_status === 'UNDER REVIEW') {
          return `We are fixing an issue. If you have not received your funds by ${moment(this.transaction.transaction_card_date).format('MMMM Do, YYYY')}, please contact us.`;
        }
      }
      return '';
    },
    clientRole() {
      if (['Deposit'].includes(this.transaction.event_type) && (['5', '14', '17'].includes(this.transaction.account_type))) {
        return 'Contributor';
      }
      return 'For';
    },
    jointClientRole() {
      if (['Deposit'].includes(this.transaction.event_type) && (['14'].includes(this.transaction.account_type))) {
        return 'Annuitant';
      }
      if (this.transaction.event_type.includes('Transfer') && (['14'].includes(this.transaction.account_type))) {
        return 'Annuitant';
      }
      return 'Joint with';
    },
    isTransfer() {
      return this.transaction.event_type.includes('Transfer');
    },
    inKindTransfer() {
      return this.transaction.event_type.includes('In-Kind');
    },
  },
};
</script>

<style scoped>

</style>
