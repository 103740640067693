<template>
  <v-autocomplete
    single-line
    outline
    solo
    flat
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    class="input-field-list"
    :rules="rules"
    :placeholder="placeholder"
    append-icon="mdi-chevron-down"
    :suffix="suffix"
    :readonly="readonly"
    :multiple="multiple"
    :no-data-text="noDataText"
    :clearable="clearable"
  />
</template>

<script>
export default {
  name: 'PasInputField',
  props: {
    items: Array,
    value: [String, Number, Object, Array],
    placeholder: {
      type: String,
      default: 'Select',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    noDataText: {
      type: String,
      default: 'No data available',
    },
    suffix: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">

.input-field-list {
  min-width: 70px !important;
  .v-input__slot {
    border-radius: 40px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 30px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 40px !important;
    margin: 0px !important;
    &:active, &:focus, &:hover, &:focus-within {
        border-color: var(--primary-translucent-5) !important;
    }
    input {
        padding: 0px !important;
        margin: 0px !important;
    }
  }
}

</style>
