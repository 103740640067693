
import _ from 'lodash';
import api from 'Services/api';
import apiV2 from 'Services/apiV2';
import {
  fetchWorkflowClient,
  fetchWorkflowDetails,
  fetchWorkflowSteps,
  fetchWorkflowIpsGroup,
} from 'Services/api/Workflows';
import { WORKFLOW } from 'Services/constants';

const defaultState = {
  selectedWorkflow: {
    workflowLoading: true,
    workflowSteps: [],
    assignedUser: {},
    ipsGroupInfo: {},
    clientInfo: {},
    type: null,
    status: null,
  },
};

const mutations = {
  SET_WORKFLOWV2_LOADING(state) {
    state.selectedWorkflow = defaultState.selectedWorkflow;
  },
  SET_WORKFLOW(state, workflow) {
    state.selectedWorkflow = workflow;
  },
  SET_WORKFLOWV2_CLIENT(state, clientInfo) {
    state.selectedWorkflow.clientInfo = clientInfo;
  },
  SET_WORKFLOWV2_DETAILS(state, workflowDetails) {
    if (!state.selectedWorkflow) {
      state.selectedWorkflow = {};
    }
    state.selectedWorkflow = _.assign(state.selectedWorkflow, workflowDetails);
    state.selectedWorkflow.workflowLoading = false;
  },
  SET_WORKFLOWV2_STEPS(state, workflowSteps) {
    state.selectedWorkflow.workflowSteps = workflowSteps || [];
  },
  SET_WORKFLOWV2_ASSIGNED_USER(state, assignedUser) {
    state.selectedWorkflow.assignedUser = assignedUser;
  },
  SET_WORKFLOWV2_IPS_GROUP(state, ipsGroupInfo) {
    state.selectedWorkflow.ipsGroupInfo = ipsGroupInfo;
  }
};

const actions = {
  async fetchWorkflowV2AssignedUser({ commit }, assignedUserId) {
    try {
      const response = await apiV2.get(`/platform_users/${assignedUserId}/`);
      commit('SET_WORKFLOWV2_ASSIGNED_USER', response);
    } catch (err) {
      console.log(err);
    }
  },
  fetchWorkflowV2Details({ commit, dispatch }, workflowID) {
    commit('SET_WORKFLOWV2_LOADING');
    return fetchWorkflowDetails(workflowID)
      .then((res) => {
        commit('SET_WORKFLOWV2_DETAILS', res);
        if (res.assigned_user) {
          dispatch('fetchWorkflowV2AssignedUser', res.assigned_user);
        }
      })
      .catch((err) => console.log('err', err));
  },
  fetchWorkflowV2Steps({ commit }, workflowID) {
    return fetchWorkflowSteps(workflowID)
      .then((res) => {
        commit('SET_WORKFLOWV2_STEPS', res);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  fetchWorkflowV2IpsGroup({ commit }, workflowID) {
    return fetchWorkflowIpsGroup(workflowID)
      .then(({ ipsGroup }) => {
        commit('SET_WORKFLOWV2_IPS_GROUP', ipsGroup);
        return ipsGroup;
      })
      .catch((err) => console.log('err', err));
  },
  fetchWorkflowV2Client({ commit }, workflowID) {
    return fetchWorkflowClient(workflowID)
      .then(({ client }) => {
        commit('SET_WORKFLOWV2_CLIENT', client);
        return client;
      })
      .catch((err) => console.log('err', err));
  },
  fetchWorkflow({ commit }, workflowId) {
    return api.get('/advisor_portal/workflow/get', `id=${workflowId}`)
      .then((res) => {
        if (res && res.docs && res.docs.length !== 0) {
          return res.docs[0];
        }
        throw Error('Could not find the workflow');
      })
      .then((res) => {
        // filter out deleted documents
        res.docs = res.docs.filter((d) => !d.is_deleted);
        commit('SET_WORKFLOW', res);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateWorkflow({ }, data) {
    return 	api.post('/advisor_portal/workflow/put', data)
      .then((res) => {
        if (res.status.code !== 200) throw Error;
        return res;
      })
      .catch((error) => ({ error }));
  },
  deleteWorkflow({ }, workflow) {
    return api.deleteCall('/advisor_portal/workflow/delete', { workflow })
      .then((res) => res)
      .catch((error) => ({ error }));
  },
  addWorkflowComment({ dispatch }, { workflowId, body, replyTo = '' }) {
    const updateWorkflowData = {
      action: 'add_comment',
      workflow: workflowId,
      comment: {
        body,
        reply_to: replyTo,
      },
    };
    return dispatch('updateWorkflow', updateWorkflowData);
  },
  editWorkflowComment({ dispatch }, { workflowId, commentId, body }) {
    const updateWorkflowData = {
      action: 'edit_comment',
      workflow: workflowId,
      comment: {
        _id: commentId,
        body,
      },
    };
    return dispatch('updateWorkflow', updateWorkflowData);
  },
  deleteWorkflowComment({ dispatch }, { workflowId, commentId }) {
    const updateWorkflowData = {
      action: 'delete_comment',
      workflow: workflowId,
      comment: {
        _id: commentId,
      },
    };
    return dispatch('updateWorkflow', updateWorkflowData);
  },
  deleteWorkflowDocument({}, data) {
    console.log('checking deleteWorkflowDocument', data);
    return api.deleteCall('/advisor_portal/workflow/delete_workflow_document/', data);
  },
};

export const getters = {
  householdId: (state) => state.selectedWorkflow?.ipsGroupInfo?.household,
  ipsGroupGuid: (state) => state.selectedWorkflow?.ipsGroupInfo?.guid,
  isClientOnboarding: (state) => state.selectedWorkflow?.type.includes('client_onboarding'),
  isFundingWorkflow: (state) => state.selectedWorkflow?.type === WORKFLOW.TYPES.funding,
  isIpsGroupWorkflow: (state) => state.selectedWorkflow?.type === WORKFLOW.TYPES.ips_group,
  isManualWorkflow: (state) => (
    [
      WORKFLOW.TYPES.money_movement_fcc_manual, WORKFLOW.TYPES.client_maintenance,
    ].some((wf) => state.selectedWorkflow?.type.includes(wf))
  ),
  isClientMaintenanceOrAnnualKyc: (state) => (
    state.selectedWorkflow?.type === WORKFLOW.TYPES.client_maintenance
    || state.selectedWorkflow?.type === WORKFLOW.TYPES.annual_kyc
  ),
  isFatcaRenewalWorkflow: (state) => state.selectedWorkflow?.type === WORKFLOW.TYPES.fatca_repapering,
  workflowCanBeCancelled: (state) => {
    const status = state.selectedWorkflow?.status.toUpperCase();
    return status !== 'CANCELLED' && status !== 'COMPLETED';
  },
  workflowType: (state) => state.selectedWorkflow?.type,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
