<template>
  <PasList
    :columns="columns"
    :minHeight="minHeight"
    :itemName="'transfers'"
    :items="transfers"
    :loading="loading"
    @toggleSort="toggleSort($event)"
  >
    <template slot-scope="{ item }">
        <v-layout fill-height align-center>
          <v-flex xs4>
            <v-layout align-center>
              <router-link :to="`/clients/${item.client_id}`">
                <PasAvatar class="mr-4 ml-2" :avatar="getAvatar('clients',item.client_id)"/>
              </router-link>
              <div class="strong-text">
                <router-link :to="`/clients/${item.client_id}`" :style="{ textTransform: 'capitalize' }">
                  {{item.account_holder ? item.account_holder.toLowerCase() : ''}}
                </router-link>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs2 light-text>
            {{moment(item.date).format('MMMM D, YYYY')}}
          </v-flex>
          <v-flex xs3>
            <v-layout column>
              <v-flex xs12>{{item.relinquishing_institution || ''}}</v-flex>
              <v-flex xs12 small-text light-text>#{{item.relinquishing_account || ''}}</v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column>
              <v-flex xs12 small-text light-text>#{{item.destination_account || ''}}</v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs2 strong-text>
            ${{commaDecimalFormatted(item.amount_estimated)}}
          </v-flex>
          <v-flex pl-3 xs1>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on" >
                  <PasStatusBullet
                    v-if ="item.transfer_status && statusMap[item.transfer_status]"
                    :color="statusMap[item.transfer_status].color"
                    :value="statusMap[item.transfer_status].value"
                  />
                </div>
              </template>
              <span>{{ATON_TRANSFER.STATUS[item.transfer_status.toUpperCase()]}}</span>
            </v-tooltip>
          </v-flex>
            <!-- <v-flex xs1>
              HIDE UNTIL TRANSFERS PAGE COMPLETE
              <PasMoreMenu/>
          </v-flex>  -->
        </v-layout>
    </template>
  </PasList>
</template>

<script>
import { commaDecimalFormatted, commaFormatted } from 'Services/helpers/currency';
import { getAvatar } from 'Services/helpers/files';
import { ATON_TRANSFER } from 'Services/constants';

export default {
  name: 'TransferList',
  props: {
    transfers: Array,
    sortBy: String,
    sortDir: Number,
    loading: Boolean,
    minHeight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      getAvatar,
      commaDecimalFormatted,
      ATON_TRANSFER,
      columns: [
        {
          header: 'Customer Name', size: 'xs4', isSortable: true, sortValue: 1,
        },
        {
          header: 'Date', size: 'xs2', isSortable: true, sortValue: 1,
        },
        {
          header: 'From Account', size: 'xs3', isSortable: false,
        },
        {
          header: 'To Account', size: 'xs3', isSortable: false,
        },
        {
          header: 'Amount', size: 'xs2', isSortable: true, sortValue: 1,
        },
        { header: 'Status', size: 'xs1', isSortable: false },
      ],
      statusMap: {
        ACC: {
          color: 'green',
          value: 25,
        },
        COMP: {
          color: 'green',
          value: 100,
        },
        CONF: {
          color: 'green',
          value: 50,
        },
        CONP: {
          color: 'green',
          value: 75,
        },
        DEL: {
          color: 'red',
          value: 100,
        },
        INIT: {
          color: '#d9ad4a', // yellow
          value: 100,
        },
        NEW: {
          color: 'green',
          value: 0,
        },
        REJ: {
          color: 'red',
          value: 50,
        },
        RESC: {
          color: 'red',
          value: 100,
        },
      },
    };
  },
  methods: {
    toggleSort(event) {
      this.columns = this.columns.map((c) => {
        if (c.header === event.header) {
          c.sortValue = event.sortValue ? event.sortValue * -1 : 0;
        }
        return c;
      });
      const newSortColumn = this.columns.find((x) => x.header === event.header);
      if (!newSortColumn) return;
      this.$emit('toggleSort', {
        sortBy: newSortColumn.header,
        sortDir: newSortColumn.sortValue,
      });
    },
  },
};
</script>
