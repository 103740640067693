const state = {
  snackbar: false,
  snackbarText: '',
  messageType: '',
  snackbarTopic: '',
};

const mutations = {
  SET_SNACKBAR(state, { text, topic, type }) {
    state.snackbarText = text;
    state.messageType = type;
    state.snackbarTopic = topic;
  },
  FLIP_SNACKBAR_SWITCH(state, on) {
    state.snackbar = on;
    if (!on) {
      state.snackbarText = '';
      state.snackbarTopic = '';
    }
  },
};

const actions = {
  setSnackbar({ commit }, { text, topic = '', type = 'success' }) {
    commit('SET_SNACKBAR', { text, topic, type });
  },
  flipSnackbarSwitch({ commit }, on = true) {
    commit('FLIP_SNACKBAR_SWITCH', on);
  },
};


export default {
  state,
  mutations,
  actions,
};
