<template>
  <v-form
    ref="employment"
    lazy-validation
    @submit.prevent
  >
    <pas-page-subtitle v-if="!noTitle" class="mb-3 px-2 mb-3 client-edit-section">Employment details</pas-page-subtitle>
    <div class="strong-text mb-3">What's the employment status?</div>
    <v-radio-group
      v-model="employmentStatus"
      class="radio-btn"
      row
    >
      <v-layout child-flex row fill-hight wrap>
        <v-radio
          v-for="(item, i) in employmentStatuses"
          :key="i"
          :color="primaryColor"
          :label="item.label"
          :value="item.value"
          :readonly="readonly"
          :rules="[v.isRequired()]"
        >
        </v-radio>
      </v-layout>
    </v-radio-group>
    <v-layout wrap>
      <v-flex
        v-for="(item, i) in employment"
        :key="i"
        sm6 md4 mb-2 px-2>
        <div class="strong-text mb-3">{{item.fieldName}}</div>
        <pas-input-field-list
          :items="employmentIndustries"
          v-model="item.value"
          v-if="item.field === 'industry'"
          :readonly="readonly"
          :rules="item.rules"
        >
        </pas-input-field-list>
        <pas-input-field
          v-model="item.value"
          v-else
          :rules="item.rules"
          :readonly="readonly"
        >
        </pas-input-field>
      </v-flex>
    </v-layout>

    <span class="error--text">{{validationText}}</span>
    <pas-button v-if="!readonly" class="primary-btn wide-btn mb-4" @click="saveEmployment" :processing="processingEmployment">Save</pas-button>
  </v-form>
</template>

<script>

import { EMPLOYMENTSTATUS, EMPLOYMENTINDUSTRIES } from 'Services/constants';
import _ from 'lodash';
import v from 'Services/validation';

export default {
  name: 'employment',
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employmentStatus: null,
      employed: false,
      employment: {
        occupation: {
          field: 'occupation',
          fieldName: 'Occupation',
          value: null,
          rules: [(v) => (!this.employed || !!v) || 'Field is required']
        },
        industry: {
          field: 'industry',
          fieldName: 'Industry',
          value: null,
          rules: [(v) => (!this.employed && !this.retired || !!v) || 'Field is required']
        },
        employer: {
          field: 'employer',
          fieldName: 'Employer',
          value: null,
          rules: [(v) => (!this.employed || !!v) || 'Field is required']
        },
      },
      retired: false,
      v,
      validationText: '',
      processingEmployment: false,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    employmentIndustries() {
      return EMPLOYMENTINDUSTRIES.concat(['Other']).concat(['']).sort();
    },
    employmentStatuses() {
      return Object.keys(EMPLOYMENTSTATUS).map((x) => ({ value: x, label: EMPLOYMENTSTATUS[x] }));
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    kycFeatureFlag() {
      return this.$store.getters.hasFeatureFlag('client_maintenance') || this.$store.getters.hasFeatureFlag('annual_kyc');
    },
  },
  methods: {
    handleResponses(type) {
      const text = `${type === 'fail' ? 'Failed to update' : 'Successfully updated'} Employment Details`;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async saveEmployment() {
      if (this.kycFeatureFlag && this.$store.getters.hasSubmittedKycChangeRequest) {
        this.$store.commit('OPEN_MODAL', 'cannot-edit-profile');
        return;
      }
      if (!this.$refs.employment.validate()) return null;
      this.validationText = this.employmentStatus ? '' : 'Please indicate employment status';
      if (this.validationText) return null;
      this.processingEmployment = true;
      const data = {
        employment_status: this.employmentStatus,
        employer: this.employment.employer.value || null,
        occupation: this.employment.occupation.value || null,
        industry: this.employment.industry.value || null,
        client: this.activeClient.client.id,
        id: this.activeClient.employment.id || null,
      };
      const callType = this.activeClient.employment.id ? 'updateEmployment' : 'createEmployment';
      await this.$store.dispatch(callType, data)
        .then((res) => this.handleResponses(res.id ? 'success' : 'fail'))
        .catch((err) => this.handleResponses('fail'));
      this.processingEmployment = false;
    },
  },
  mounted() {
    const client = _.cloneDeep(this.activeClient);
    if (client.employment && (client.employment.employment_status || client.employment.employment_status === 0)) {
      this.employmentStatus = client.employment.employment_status;
      if (client.employment.employment_status == 1) {
        this.employmentStatus = '0'; // set part-time as employed, make sure new changes are compatible with old data.
      }
    }
    if (client.employment) {
      this.employment.industry.value = client.employment.industry || null;
      this.employment.occupation.value = client.employment.occupation || null;
      this.employment.employer.value = client.employment.employer || null;
    }
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        const client = _.cloneDeep(this.activeClient);
        if (client.employment && (client.employment.employment_status || client.employment.employment_status === 0)) {
          this.employmentStatus = client.employment.employment_status;
          if (client.employment.employment_status == 1) {
            this.employmentStatus = '0'; // set part-time as employed, make sure new changes are compatible with old data.
          }
        }
        if (client.employment) {
          this.employment.industry.value = client.employment.industry || null;
          this.employment.occupation.value = client.employment.occupation || null;
          this.employment.employer.value = client.employment.employer || null;
        }
      },
      deep: true,
    },
    employmentStatus: {
      handler(val, oldVal) {
        this.employed = (this.employmentStatus == 0 || this.employmentStatus == 1 || this.employmentStatus == 2);
        this.retired = this.employmentStatus == 4;

        this.employment.employer.fieldName = this.employmentStatus == 2 ? 'Company Name' : 'Employer';
        this.employment.industry.fieldName = this.retired ? 'Previous Industry' : 'Industry';
      },
      deep: true,
    },
  },
};

</script>

<style lang="scss">
.radio-btn {
  .v-input__control {
    width: 100% !important;
  }
}
</style>
