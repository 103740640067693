<template>
  <div>
    <PasAccordion>
      <template v-slot:panelTitle>General Information</template>
      <template v-slot:panelContent>
        <client-avatar :readonly="!areClientDetailsEditable" class="pt-4" :clientID="activeClient.client.id"/>
        <EditDetailsModal :editable="areClientDetailsEditable" />
      </template>
    </PasAccordion>
    <PasAccordion>
      <template v-slot:panelTitle>Addresses</template>
      <template v-slot:panelContent>
        <addresses
          class="pt-4"
          :client="activeClient.client"
          :_addresses="activeClient.addresses"
          noTitle
          :readonly="!areClientDetailsEditable"
        />
      </template>
    </PasAccordion>
  </div>
</template>

<script>
import Addresses from 'Components/Shared/Addresses';
import EditDetailsModal from 'Components/Shared/EditDetailsModal';
import ClientAvatar from '../ClientEdit/ClientDetailsTab/ClientAvatar';

export default {
  name: 'ClientGeneralInfoTab',
  components: {
    Addresses,
    ClientAvatar,
    EditDetailsModal,
  },
  props: {
    activeClient: {
      type: Object,
      default: () => {},
    },
    areClientDetailsEditable:{
      type: Boolean,
      default: false,
    },
  },
};
</script>