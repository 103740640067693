<template>
  <v-dialog width="600px" v-model="isOpen" persistent>
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="$emit('close'); reset()" class="close-icon">close</v-icon>
      </PasFab>
      <PasPageTitle>Complete AML Check</PasPageTitle>
      <v-form ref="form" lazy-validation @submit.prevent>
        <div
          class="strong-text mt-4 mb-3"
        >Would you like to approve or reject the client onboarding?</div>
        <pas-button-toggle :value="isApproved ? 0 : 1" @input="isApproved = !$event" mandatory>
          <v-btn class="px-4" flat>Approve</v-btn>
          <v-btn class="px-4" flat>Reject</v-btn>
        </pas-button-toggle>
        <!-- leaving the disabled logic for now -->
        <div
          :class="['strong-text', 'mt-4', 'mb-3', { 'disabled-section': false }]"
        >Assign a risk score</div>
        <pas-button-toggle :value="riskScore" @input="riskScore = $event" :mandatory="true">
          <v-btn class="px-4" flat>Low</v-btn>
          <v-btn class="px-4" flat>Medium</v-btn>
          <v-btn class="px-4" flat>High</v-btn>
        </pas-button-toggle>
        <div class="strong-text mt-4 mb-3">Add comments</div>
        <PasTextarea v-model="comments" :rules="[v.isRequired()]" />
        <v-layout justify-end>
          <PasButton class="primary-btn mt-4" @click="submit">Submit</PasButton>
        </v-layout>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import v from 'Services/validation';

export default {
  name: 'CompleteAMLCheck',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isApproved: false,
      riskScore: 0,
      comments: '',
      v,
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;
      const { isApproved, riskScore, comments } = this;
      this.$emit('submit', { isApproved, riskScore: riskScore + 1, comments });
      this.reset();
    },
    reset() {
      this.isApproved = false;
      this.riskScore = 0;
      this.$refs.form.reset();
    },
  },
  // commenting out the disabled logic for now
  // watch: {
  // isApproved(val) {
  //   if (val) this.riskScore = 0;
  //   else this.riskScore = undefined;
  // },
  // },
};
</script>
