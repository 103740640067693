<template>
    <v-layout justify-center row wrap>
        <v-flex xs12>
            <v-btn
                color="secondary"
                @click="update"
                block
                :disabled="updating"
            >
                <v-progress-circular
                    v-if="updating"
                    indeterminate
                >
                </v-progress-circular>
                <template
                    v-if="!updating">
                    {{updateButtonText}}
                </template>
            </v-btn>
        </v-flex>
        <v-flex>
            <p :class="{'statusText': true,
                        'success--text': updateSuccess,
                        'error--text': !updateSuccess}">
            {{updateStatusText}}
            </p>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
  name: 'update-button',
  props: {
    updateButtonText: String,
    updateStatusSuccessText: String,
    updateStatusFailText: String,
    updateSuccess: Boolean,
    updating: Boolean,
    updated: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    updateStatusText() {
      return this.updated && !this.updating ? (this.updateSuccess ? this.updateStatusSuccessText : this.updateStatusFailText)
        : '';
    },
  },
  methods: {
    update() {
      this.$emit('update');
    },
  },
};
</script>

<style scoped>
    .statusText {
        text-align: center;
    }
</style>
