<template>
  <PFormAutocomplete
    v-model="selectedClient"
    class="mb-2"
    :options="filteredClients"
    @search="query => search = query"
    @input="$emit('update:clientId', selectedClient.id)"
    autocomplete
    labelKey="display_name"
    placeholder="Search clients"
  >
    <template #option="{ display_name, email }">
      <span>{{ display_name }}</span><span class="ml-2 text-muted">{{ email }}</span>
    </template>
  </PFormAutocomplete>
</template>

<script>

export default {
  name: 'FundingSearch',
  props: {
    clientList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      selectedClient: null,
    };
  },
  computed: {
    filteredClients() {
      return this.clientList.filter((client) => client.display_name.toLowerCase()
        .includes(this.search.toLowerCase()))
        .splice(0, 5);
    },
  },
  watch: {
    $route(to) {
      if (to.query.page === 'landing-page') {
        this.selectedClient = 0;
      }
    },
  },
};
</script>
