<template>
	<v-layout align-center>
		<template v-if="!isReadOnly">
			<v-select
				:items="teamMembersParsed"
				label="Re-Assign Workflow To"
				v-model="userToReAssign"
			></v-select>
			<v-btn flat round :color="primaryColor" :disabled="!userToReAssign" @click="reassign">Re-Assign Workflow</v-btn>
			<v-btn flat round :color="primaryColor" @click="markComplete">Mark as Complete</v-btn>
			<v-btn flat round :color="primaryColor" @click="regenerateDocs">Regenerate Documents</v-btn>
		</template>
	</v-layout>
</template>

<script>
import api from '../../../services/api.js'

import generateDocuments from './GenerateDocuments'
export default {
	name: 'generate-documents',
	props: {
		teamMembers: Array,
		workflow: Object,
		stage: Number,
		step: Number,
		readOnly: Boolean,
	},
	data () {
		return {
			userToReAssign: null,
		}
	},
	computed:{
		primaryColor() {
			return this.$store.state.app.themeVars.primaryColor
		},
		currentUser(){
			return this.$store.state.users.currentUser 
		},
		teamMembersParsed(){
			return this.teamMembers.map(member => ({text: member.name, value: member}))
		},
		isReadOnly() {
			return this.readOnly
		}
	},
	methods: {
		reassign(){
			api.post('/advisor_portal/workflow/put', {
				workflow: this.workflow._id,
				action: 'assign_user',
				user: this.userToReAssign._id
			})
			this.$router.push('/')
		},
		markComplete(){
			api.post('/advisor_portal/workflow/put', {
				workflow: this.workflow._id,
				action: 'complete_step',
				stage: this.stage,
				step: this.step
			})
			this.$emit('complete-step')
		},
		regenerateDocs(){
			api.post('/advisor_portal/workflow/put', {
				workflow: this.workflow._id,
				action: 'redo_step',
				stage: this.stage - 1,
				step: this.step - 1,
			})

			this.$store.dispatch('setSnackbar', 'Regenerating documents...')
			this.$store.dispatch('flipSnackbarSwitch')
		}
	}
}
</script>

<style lang="scss">
</style>
