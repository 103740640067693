<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div>
      <span>Accounts numbers have been received, you can</span>
      <br>
      <div>
        <span v-if="step.status === 'in_progress'">
          <span @click.prevent="idModal = true" class="action-text">Confirm Accounts Numbers</span>
        </span>
        <ConfirmAccountNumber
          :workflowId="workflow.id"
          :isOpen="idModal"
          @close="idModal = false"
        />
        <span> or </span>
        <span @click.prevent="completeReceiveAccountNumber" class="action-text">
          Mark as complete
        </span>
      </div>
    </div>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendReceiveAccountNumberSuccess } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';
import ConfirmAccountNumber from './ReceiveAccountNumberStep/ConfirmAccountNumber';

export default {
  name: 'ReceiveAccountNumberStep',
  components: { WorkflowStep, ConfirmAccountNumber },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      complianceDialog: false,
      idModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
  },
  methods: {
    async completeReceiveAccountNumber() {
      try {
        const res = await sendReceiveAccountNumberSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
