import moment from 'moment';
import Isemail from 'isemail';
import { isValidPassword, getPasswordErrorMessage } from './helpers/auth';
import { isValidCurrencyString } from './helpers/currency';
import { luhnValidate } from './helpers/luhn';

const validationRules = new function () {
  this.isRequired = (err = null) => (v) => (!!v || v === 0 || v === false) || (err || 'Field is required'),
  this.isValidEmail = (v) => (v && Isemail.validate(v, { errorLevel: 8, allowUnicode: true, minDomainAtoms: 2 }) < 8) || 'Please enter a valid email',
  this.isValidPhone = (v) => RegExp(/^\d{10}$/).test(v) || 'Please enter a valid phone number',
  this.isValidPassword = (v) => isValidPassword(v) || getPasswordErrorMessage(v),
  this.isCurrency = (v) => isValidCurrencyString(v) || 'Please enter a valid currency format - ex 300 or 59.99',
  this.isValidCurrencyFormat = (v) => RegExp(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/).test(v) || 'Please enter a valid amount',
  this.maxChars = (num) => (v) => (v && v.length <= num) || `Maximum of ${num} characters`,
  this.maxCharsOrEmpty = (num) => (v) => (!v || v.length <= num) || `Maximum of ${num} characters`,
  this.minChars = (num) => (v) => (v && v.length >= num) || `Minimum of ${num} characters`,
  this.specifiedChars = (num) => (v) => (v && v.length === num) || `Must be ${num} characters`,
  this.emptyOrSpecifiedChars = (num) => (v) => (!v || v.length === num) || `Must be ${num} characters or blank`,
  this.inArray = (array, err = null) => (v) => array.indexOf(v) !== -1 || (err || 'Please select a value from the values provided'),
  this.notUndefined = (v) => v !== undefined || 'Field is required',
  this.isNumeric = (err = null) => (v) => (!v || RegExp(/^\d*\.?\d*$/).test(v)) || (err || 'Numeric values only'),
  this.isAlphabetical = (err = null) => (v) => (!v || RegExp(/^[a-z\-' ]+$/i).test(v)) || (err || 'Alphabetical characters only'),
  this.isObjectEmpty = (err = null) => (v) => (!_.isEmpty(v) || (err || 'Field is required')),
  this.isPositive = (v) => parseFloat(v) >= 0 || 'Please enter a positive value',
  this.isGreaterThan = (n, err = null) => (v) => parseFloat(v) > n || `Please enter a value greater than ${n}`;
  this.isLessThanOrEqual = (n, err = null) => (v) => parseFloat(v) <= n || `Please enter a value less than or equal ${n}`;
  this.emptyOrPhone = (v) => (!v || this.isValidPhone(v)) || 'Field must be blank or a valid phone number';
  this.emptyOrEmail = (v) => (!v || this.isValidEmail(v)) || 'Field must be blank or a valid email';
  this.isValidDate = (v) => moment(v).isValid() || 'Please enter a valid date';
  this.isOverAge = (num) => (v) => moment(v).isBefore(moment().subtract(num, 'years')) || 'Invalid Age';
  this.isAlphanumeric = (err = null) => (v) => (!v || RegExp(/^[a-zA-Z0-9]+$/).test(v)) || (err || 'Alphanumeric values only');
  this.isValidName = (err = null) => (v) => (!v || RegExp(/^[a-z\u00C0-\u02AB .'´`-]+$/i).test(v)) || (err || 'Name is not valid');
  this.isSINValid = (err = null) => (v) => (!v || (v !== '000000000' && luhnValidate(v)) || 'Invalid SIN');
  this.isValidDataphileNum = (v) => (!v || RegExp(/^[a-zA-Z0-9]{8}$/).test(v)) || 'Invalid dataphile number';
}();

export default validationRules;
