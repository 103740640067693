
<template>
  <pas-fullscreen-modal @close="closeModal" :isOpen="isOpen">
    <v-container class="modal-container">
      <v-layout column>
        <v-flex xs12 text-xs-center mb-3>
          <pas-page-title>Create New Fund Transfer</pas-page-title>
        </v-flex>
        <v-flex xs12 text-xs-center mb-4>
          <span>Please fill out information</span>
        </v-flex>
      </v-layout>
      <v-form @submit.prevent ref="form" lazy-validation>
        <div class="strong-text my-3">One-Time or Recurring?</div>
        <pas-autocomplete
          :items="recurringItems"
          v-model="isRecurring"
          :style="{ width: '200px' }"
          :rules="[v.isRequired('Please make your selection')]"
          class="mb-4"
        ></pas-autocomplete>
        <template v-if="isRecurring">
          <div class="strong-text my-3">Frequency</div>
        	<v-layout row justify-space-between>
            <v-flex
              v-for="(item, i) in frequencyItems"
              :key="i"
              shrink
            >
              <pas-selectable-chip
                @click="frequency = item.value"
                :class="{ 'selected-chip': frequency === item.value }"
                :style="{ width: '130px' }"
              >
                {{item.text}}
              </pas-selectable-chip>
            </v-flex>
					</v-layout>
        </template>
        <template v-else>
          <span>The EFT request will be processed the next business day</span>
        </template>
        <v-layout row>
          <v-flex xs5 md3 mr-3>
            <div class="strong-text my-4">Enter Amount</div>
            <pas-input-currency
              v-if="currencyUpdate"
              v-model="amount"
              :rules="[v.isRequired('Please enter an amount'), v.isCurrency, v.isPositive]"
            ></pas-input-currency>
          </v-flex>
          <v-flex xs5 md3 mr-3>
            <div class="strong-text my-4">Select Date</div>
            <pas-datepicker
              :value="date"
              @input="date = $event"
            ></pas-datepicker>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs5 md3 mr-3>
            <div class="strong-text my-4">Investment Account</div>
            <pas-autocomplete
              :items="investmentAccounts"
              v-model="investmentAccount"
              :rules="investmentAccRules"
            ></pas-autocomplete>
          </v-flex>
          <v-flex xs5 md3>
            <div class="strong-text my-4">Banking Account</div>
            <pas-autocomplete
              :items="bankAccounts"
              v-model="bankAccount"
              :rules="[v.isRequired('Please select a bank account')]"
            ></pas-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout justify-start align-center mt-4>
          <v-flex shrink mr-3>
            <v-switch
              label="Direction of Transfer"
              v-model="fromInvestToBank"
              :disabled="!isRecurring"
              :color="primaryColor"
            ></v-switch>
          </v-flex>
          <v-flex>
            <span :style="{ paddingBottom: '5px'}">
              {{  fromInvestToBank
                  ? `from ${investmentAccount ? selectedInvestmentAccount.nickname : 'Investment Account'} to ${bankAccount ? selectedBankAccount.description : 'Bank Account'}`
                  : `from ${bankAccount ? selectedBankAccount.description : 'Bank Account'} to ${investmentAccount ? selectedInvestmentAccount.nickname : 'Investment Account'}`}}
            </span>
          </v-flex>
        </v-layout>
        <pas-error-div :show="!!errorText">{{errorText}}</pas-error-div>
        <v-layout>
          <v-spacer></v-spacer>
          <v-flex shrink>
            <pas-button
              @click="save"
              class="primary-btn wide-btn"
              :processing="processing"
            >Create Fund Transfer</pas-button>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
  </pas-fullscreen-modal>
</template>

<script>

import { getMetadata } from 'Services/api/metadata';
import api from 'Services/api';
import { FREQUENCIES, SYSTEMATIC_TYPES, ACCOUNT_STATUS } from 'Services/constants';
import babelPolyfill from '@babel/polyfill';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import moment from 'moment';

export default {
  name: 'add-fund-transfer',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRecurring: false,
      recurringItems: [
        { value: false, text: 'One-Time' },
        { value: true, text: 'Recurring' },
      ],
      frequency: 'W',
      amount: null,
      date: null,
      hasDataphileNo: false,
      isTempInvAcc: true,
      investmentAccRules: [
        v.isRequired('Please select an account'),
        (v) => this.hasDataphileNo || 'Please select an account with a Dataphile number',
        (v) => !this.isTempInvAcc || 'Please select an active account',
      ],
      investmentAccount: null,
      bankAccount: null,
      fromInvestToBank: true,
      errorText: '',
      processing: false,
      currencyUpdate: false,
      v,
    };
  },
  async mounted() {
    this.operationsEmail = await getMetadata('operations_email');
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    frequencyItems() {
      return Object.keys(FREQUENCIES)
        .map((key) => ({ text: FREQUENCIES[key], value: key }))
        .filter((x) => x.value !== this.frequencyEnum['One-Time'] && x.value !== this.frequencyEnum.Unknown);
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts.map((a) => ({
        text: `${a.nickname}${a.account_no_dataphile ? ` - ${a.account_no_dataphile}` : ''}${a.account_status !== this.accountStatusEnum.Temporary ? '' : ' (Not Active)'}`,
        value: a.id,
      }));
    },
    bankAccounts() {
      return this.activeClient.bankAccounts.map((a) => ({ text: a.description, value: a.id }));
    },
    selectedInvestmentAccount() {
      if (!this.investmentAccount) return {};
      return this.activeClient.investment_accounts.filter((a) => a.id === this.investmentAccount)[0];
    },
    selectedBankAccount() {
      if (!this.bankAccount) return {};
      return this.activeClient.bankAccounts.filter((a) => a.id === this.bankAccount)[0];
    },
    frequencyEnum() {
      return _.invert(FREQUENCIES);
    },
    accountStatusEnum() {
      return _.invert(ACCOUNT_STATUS);
    },
    isCorporate() {
      return this.activeClient.client.client_type === 'corporation';
    },
    primarySigner() {
      if (!this.isCorporate) return;
      return this.$store.state.clients.list.find((client) => client.id === this.activeClient.corporate_roles.find((r) => r.authorized_signatory && r.primary).client);
    },
  },
  methods: {
	  setDefaultDate() {
      const dayOfWeek = moment().format('dddd');
      let daysToAdd;
      // if it's a one-time transfer, default date should be the next business day
      if (!this.isRecurring) daysToAdd = dayOfWeek === 'Friday' ? 3 : dayOfWeek === 'Saturday' ? 2 : 1;
      // if it's a recurring transfer, default date should be in the next 5 business days
      else daysToAdd = dayOfWeek === 'Sunday' ? 5 : dayOfWeek === 'Saturday' ? 6 : 7;
      this.date = moment().add(daysToAdd, 'days').format('YYYY-MM-DD');
    },
    snackBar(type, message) {
      let text;
      if (type === 'fail') {
        text = message || 'Error in EFT generation';
      } else {
        text = 'EFT successfully generated';
      }
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    isValidFundTransfer() {
      this.errorText = '';
      if (!this.$refs.form.validate()) return false;
      if (moment(this.date).isBefore()) {
        this.errorText = 'Please select a future date';
        return false;
      }
      const amountValidation = Number(this.amount);
      if (amountValidation <= 0) {
        this.errorText = 'Please select an amount greater than 0';
        return false;
      }
      return true;
    },
    async save() {
      if (!this.isValidFundTransfer()) return;
      this.processing = true;
      try {
        const data = {
          account: this.selectedInvestmentAccount.id,
          bank_account_id: this.selectedBankAccount.id,
          client: this.activeClient.client.id,
          account_no_dataphile: this.selectedInvestmentAccount.account_no_dataphile,
          amount: this.amount,
          frequency: this.isRecurring ? this.frequency : this.frequencyEnum['One-Time'],
          status: 0,
          type: this.fromInvestToBank ? SYSTEMATIC_TYPES.indexOf('Withdrawal (EFT)') : SYSTEMATIC_TYPES.indexOf('Deposit (EFT)'),
          payment_method: this.fromInvestToBank ? '2' : '1',
          spousal_contribution: this.selectedInvestmentAccount.spousal_indicator,
          start_date: this.date,
          currency: this.selectedBankAccount.currency,
        };
        const systematic = await this.$store.dispatch('createSystematic', data);
        if (!systematic.id) throw Error('Failed to create EFT record');
        if (this.isRecurring) {
          const workflow = await this.$store.dispatch('createNewWorkflow', {
            pas_client_ids: [this.activeClient.client.id],
            workflow_type: 'eft_setup',
          });
          if (!workflow.workflow_id) {
            this.$store.dispatch('deleteSystematic', systematic.id);
            throw Error('Failed to create EFT workflow');
          }
        } else {
          const emailResponse = await this.sendEmailToOperations(data);
          if (emailResponse.code !== 200) {
            this.$store.dispatch('deleteSystematic', systematic.id);
            throw Error('Failed to send One-Time EFT request');
          }
        }
        this.snackBar('success');
        this.$store.dispatch('getWorkflows', this.activeClient.client.id);
        this.$store.dispatch('fetchSystematics', this.activeClient.client.id);
        this.closeModal();
      } catch (err) {
        this.snackBar('fail', err);
      }
      this.processing = false;
    },
    sendEmailToOperations(data) {
      const messageData = {
        subject: 'New One-Time EFT transfer',
        email: this.operationsEmail,
      };

      messageData.message = 	'Hi, \n\n'
        + 'Here are the details of the EFT: \n\n '
        + `	Client : ${this.activeClient.client.display_name} \n`
        + `${this.isCorporate ? `	Primary Signer: ${this.primarySigner.display_name} \n` : ''}`
        + `	Start date : ${data.start_date} \n`
        + `	Amount : ${data.amount} \n`
        + `	Investment Account : ${data.account_no_dataphile} \n`
        + '	Bank Account : \n'
        + `		Institution Number: ${this.selectedBankAccount.institution_no} \n`
        + `		Transit Number: ${this.selectedBankAccount.transit_no} \n`
        + `		Account Number: ${this.selectedBankAccount.account_no} \n`
        + `	Type: ${data.type} \n\n`
        + 'Thanks';

      return api.post('/advisor_portal/send_email', messageData);
    },
    closeModal() {
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.close();
    },
  },
  watch: {
    isOpen(val) {
      if (val) this.setDefaultDate();
      this.isRecurring = false;
      this.currencyUpdate = false;
      this.amount = null;
      this.$nextTick().then(() => {
        this.currencyUpdate = true;
      });
    },
    isRecurring() {
      this.setDefaultDate();
      this.fromInvestToBank = !this.isRecurring;
    },
    investmentAccount: {
      deep: true,
      handler(val) {
        if (this.selectedInvestmentAccount) {
          this.hasDataphileNo = !!this.selectedInvestmentAccount.account_no_dataphile;
          this.isTempInvAcc = this.selectedInvestmentAccount.account_status === this.accountStatusEnum.Temporary;
        }
      },
    },
  },
};
</script>

<style lang="scss">
</style>
