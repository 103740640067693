import {
  get, set, defaultTo, first,
} from 'lodash';


export const getModules = (roles) => {
  // return a list of all module names a user has
  const moduleNames = new Set();
  if (!roles) {
    return moduleNames;
  }
  roles.forEach((role) => {
    role.modules.forEach((module) => {
      moduleNames.add(module.module_name);
    });
  });
  return moduleNames;
};


export const getSubmodulePermissions = (roles) => {
  const modulePermissionMap = {};
  if (!roles) {
    return modulePermissionMap;
  }
  roles.forEach((role) => {
    role.modules.forEach((module) => {
      module.submodules.forEach((submodule) => {
        ['read', 'write', 'delete'].forEach((verb) => {
          const path = `${submodule.submodule_name}.${verb}`;
          const currentVal = defaultTo(get(modulePermissionMap, path), false);
          const newVal = defaultTo(get(submodule.submodule_access, verb), false);
          set(
            modulePermissionMap, path, currentVal || newVal,
          );
        });
      });
    });
  });
  return modulePermissionMap;
};

export const hasRole = (roles, roleName) => roles.some((role) => role.role_name === roleName);
