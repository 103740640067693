<template>
  <v-layout row nowrap mb-3>
    <v-flex>
      <PasPageHeader :path="path" v-if="path">
        {{ workflowTitle }}
      </PasPageHeader>
      <div @click="goBack()" v-else>
        <PasPageHeader>{{ workflowTitle }}</PasPageHeader>
      </div>
    </v-flex>
    <v-spacer />
    <v-flex text-xs-left>
      <v-layout row nowrap v-if="workflow.assigned_user">
        <v-flex xs2 text-xs-center mt-2>
          <pas-avatar :avatar="getAvatar('platform_users', workflow.assigned_user)" />
        </v-flex>
        <v-flex xs1 />
        <v-flex xs9 text-xs-left>
          <div class="p-text" style="padding-top:24px">{{assignedUserName}}</div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { WORKFLOW } from 'Services/constants';
import { getAvatar } from 'Services/helpers/files';

export default {
  name: 'WorkflowTitle',
  props: {
    workflow: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      WORKFLOW,
      getAvatar,
      assignedUser: {},
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
    path() {
      if (this.ipsGroupGuid) {
        return '';
      }

      return `/clients/${this.clientId}`;
    },
    workflowTitle() {
      let title = '';
      if (this.workflow?.clientInfo) {
        title = `${WORKFLOW.DISPLAY_NAMES[this.workflow.type]} for: ${this.workflow?.clientInfo?.display_name}`;
      } else if (this.workflow?.type === WORKFLOW.TYPES.ips_group) {
        title = `${WORKFLOW.DISPLAY_NAMES[this.workflow.type]} for: ${this.workflow?.ipsGroupInfo?.name}`;
      }

      return title;
    },
    assignedUserName() {
      if (!this.workflow.assignedUser) {
        return '';
      }
      return `${this.workflow.assignedUser.first_name} ${this.workflow.assignedUser.last_name}`;
    },
    ipsGroupGuid() {
      return this.workflow?.ipsGroupInfo?.guid;
    },
    householdId() {
      return this.workflow?.ipsGroupInfo?.household;
    },
    clientId() {
      return this.workflow?.clientInfo?.id;
    },
  },
};
</script>

<style scoped>

</style>
