<template>
  <PContainer class="m-0">
    <PRow class="h-100">
      <IpsGroupDetails
        :step-id="stepId"
        :ips-group-guid="ipsGroupGuid"
        :household-id="householdId"
        :routes="routes"
        :key="ipsGroupGuid"
        @create-ips-group="createNewIpsGroup"
        data-testid="ips-group-details"
      />
    </PRow>
  </PContainer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { createAndGoToIpsGroup } from '../../../../../services/helpers/ipsGroup';

const ipsGroupsVuex = createNamespacedHelpers('ipsGroups');

export default {
  name: 'IpsGroupPage',
  computed: {
    stepId() {
      const DEFAULT_START_STEP = 'group_info';
      return this.$route.params.stepId || DEFAULT_START_STEP;
    },
    householdId() {
      return this.$route.params.householdId;
    },
    ipsGroupGuid() {
      return this.$route.params.ipsGroupGuid;
    },
    routes() {
      return {
        back: {
          name: 'HouseholdProfile',
          params: {
            householdId: this.householdId,
          },
          query: {
            tab: 1,
          },
        },
        client_details: {
          name: 'ClientMaintenancePage',
        },
      };
    },
  },
  methods: {
    ...ipsGroupsVuex.mapActions(['createIpsGroup']),
    async createNewIpsGroup() {
      createAndGoToIpsGroup(this.$store, this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
