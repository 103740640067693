<template>
  <PContainer v-if="clientId">
    <PRow class="m-1">
      <DataCaptureLegalEntity
        :clientId="clientId"
        :entityType="entityType"
        viewMode="advisor"
        :navigation="navigation"
        :requiresChangeRequest="requiresChangeRequest"
      />
    </PRow>
  </PContainer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LegalEntity',
  computed: {
    ...mapGetters(['activeClient']),
    clientId() {
      return this.activeClient.client && this.activeClient.client.id;
    },
    entityType() {
      return this.activeClient.client && this.activeClient.client.client_type;
    },
    requiresChangeRequest() {
      return this.activeClient.client && this.activeClient.client.client_status === 'active' && !this.$store.getters.hasFeatureFlag('bypass_client_maintenance_workflow');
    },
    navigation() {
      return {
        clientChanges: {
          routeName: 'ClientChangesPage',
          text: this.$t('cm_common:action.navigate.client_changes'),
        },
        back: {
          routeName: 'ClientHomePage',
          text: this.activeClient?.client?.display_name || `${this.activeClient.client?.first_name} ${this.activeClient.client?.last_name}`,
        },
      };
    },
  },
};
</script>
