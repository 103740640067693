const defaultState = {
  openModals: [],
};

const mutations = {
  OPEN_MODAL(state, modalName) {
    state.openModals.push(modalName);
  },
  CLOSE_CURRENT_MODAL(state) {
    state.openModals.pop();
  },
};

export const getters = {
  isBankAccountOpen: (state) => state.openModals.includes('add-bank-account'),
  isGenerateAccountTransferDocsOpen: (state) => state.openModals.includes('generate-account-transfer-docs'),
  isGenerateIpsDocsOpen: (state) => state.openModals.includes('generate-ips-docs'),
  isSetUpSystematicOpen: (state) => state.openModals.includes('set-up-systematic'),
  isAddFundTransferOpen: (state) => state.openModals.includes('add-fund-transfer'),
  isAddInvestmentAccountsOpen: (state) => state.openModals.includes('add-investment-accounts'),
  isAddCorporateInvestmentAccountsOpen: (state) => state.openModals.includes('add-corporate-investment-accounts'),
  isAddAccountTransferOpen: (state) => state.openModals.includes('add-account-transfer'),
  isAddInternalTransferOpen: (state) => state.openModals.includes('add-internal-transfer'),
  isAddSystematicFccOpen: (state) => state.openModals.includes('add-systematics-fcc'),
  isSendInvestorInvite: (state) => state.openModals.includes('send-investor-invite'),
  isUnlockInvestor: (state) => state.openModals.includes('unlock-investor-account'),
  isCannotEditProfile: (state) => state.openModals.includes('cannot-edit-profile'),
  isContactOpsCancelWorkflow: (state) => state.openModals.includes('contact-ops-cancel-workflow'),
};

export default {
  state: defaultState,
  mutations,
  getters,
};
