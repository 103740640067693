<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div v-if="hasFullWorkflowEditPermission">
      <span>FOR COMPLIANCE ONLY:</span>
      <br>
      <div>
        <span @click.prevent="compliancePreviewDoc" class="action-text">Email documents</span>
      </div>
      <div>
        <span @click.prevent="complianceApproved" class="action-text">Approve</span>
        <span> or </span>
        <span @click.prevent="complianceRejected" class="action-text">Reject</span>
      </div>
    </div>
    <v-dialog max-width="450px" v-model="complianceDialog" persistent>
      <v-card class="pa-5">
        <v-layout column>
          <pas-page-subtitle>Compliance Approval</pas-page-subtitle>
          <div class="my-3">Only members of the compliance team can approve</div>
          <v-layout>
            <v-spacer></v-spacer>
            <pas-button
              class="primary-btn mt-3"
              @click="complianceDialog = false"
            >OK
            </pas-button>
          </v-layout>
        </v-layout>
      </v-card>
    </v-dialog>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import api from 'Services/api';
import {
  sendComplianceApprovalFailure,
  sendComplianceApprovalSuccess,
  sendComplianceDocuments,
} from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'ComplianceApprovalStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      complianceDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
    ]),
  },
  methods: {
    async checkCompliance() {
      const { compliance_check } = await api.get('/advisor_portal/compliance_check/');
      if (!compliance_check) {
        this.complianceDialog = true;
        return false;
      }
      return true;
    },
    async compliancePreviewDoc() {
      const complianceCheck = await this.checkCompliance();
      if (!complianceCheck) return;
      try {
        const res = await sendComplianceDocuments(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Generating Documents...',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id)
            .then(() => this.$forceUpdate());
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error in Document generation',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async complianceApproved() {
      const complianceCheck = await this.checkCompliance();
      if (!complianceCheck) return;
      try {
        const res = await sendComplianceApprovalSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id)
            .then(() => this.$forceUpdate());
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async complianceRejected() {
      const complianceCheck = await this.checkCompliance();
      if (!complianceCheck) return;
      try {
        const res = await sendComplianceApprovalFailure(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
