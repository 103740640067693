import Vue from 'vue';
import Vuetify from 'vuetify';
import moment from 'moment-business-days';
import babelPolyfill from '@babel/polyfill';
import VueDragDrop from 'vue-drag-drop';
import JsonExcel from 'vue-json-excel';
import { initAuth } from 'Services/helpers/auth';
import infiniteScroll from 'vue-infinite-scroll';
import Purposeful from '@purposeadvisorsolutions/purposeful';
import Features from '@purposeadvisorsolutions/features';
import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import Locize from 'i18next-locize-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import VueI18Next from '@panter/vue-i18next';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import api from 'Services/api';

import BaseComponents from './components/Base';
import Dashboard from './components/Vue-Routes/Dashboard';
import HeaderNav from './components/HeaderNav';
import HeaderNavV2 from './components/HeaderNavV2';
import router from './router/index';
import App from './App';
import store from './store/store';
import { FRONTEND_LOCIZE_NAMESPACES } from './constants/locize';

initAuth(store, router);

Vue.use(Purposeful);
Vue.use(Features);

//  Globally Registered Components
Vue.component('HeaderNav', HeaderNav);
Vue.component('HeaderNavV2', HeaderNavV2);
Vue.component('dashboard', Dashboard);
Vue.component('downloadExcel', JsonExcel);

library.add(fal);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.moment = moment;

Vue.use(VueDragDrop);
Vue.use(babelPolyfill);
Vue.use(infiniteScroll);

Vue.use(BaseComponents);

Vue.use(Vuetify, {
  theme: {
    primary: '#323741',
    secondary: '#c48f3e',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
  iconfont: 'mdi',
  options: {
    customProperties: true,
  },
});

if (['dev', 'staging', 'prod'].includes(process.env.VUE_APP_ENVIRONMENT)
    && process.env.VUE_APP_ELASTIC_APM_SERVER_URL) {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: 'AdvisorHubVue',
      serverUrl: process.env.VUE_APP_ELASTIC_APM_SERVER_URL,
      environment: process.env.VUE_APP_ENVIRONMENT,
    },
  });
}

Vue.use(VueI18Next);

api.get('/config')
  .then(async (res) => {
    if (res) {
      Object.entries(res).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });

      // Fe-Shared service/component/plugin registration
      // Needs to be dynamically imported here so that the required env variables
      // are available in localStorage before the related code is run.
      const { importAndRegisterModules } = await import('./services/boot');
      await importAndRegisterModules();

      // i18n setup
      i18next.use(ChainedBackend).init({
        ns: FRONTEND_LOCIZE_NAMESPACES,
        supportedLngs: ['en-CA', 'fr-CA'],
        fallbackLng: 'en-CA',
        load: 'currentOnly',
        debug: false,
        saveMissing: false,
        backend: {
          backends: [
            LocalStorageBackend,
            Locize,
            HttpApi,
          ],
          backendOptions: [{
            expirationTime: 0.5 * 24 * 60 * 60 * 1000, // 12 hours
          },
          {
            projectId: localStorage.getItem('vueAppLocizeProjectId'),
            version: localStorage.getItem('locizeBranchName'),
            referenceLng: 'en-CA',
          },
          {
            loadPath: `https://s3.ca-central-1.amazonaws.com/locize-translations/advisor-hub/${localStorage.getItem('locizeBranchName')}/{{lng}}/{{ns}}.json`, // http api load path for my own fallback
          }],
        },
      });

      const i18n = new VueI18Next(i18next);

      new Vue({
        el: '#app',
        i18n,
        store,
        router,
        render: (h) => h(App),
      });
    } else {
      throw Error(res);
    }
  })
  .catch((err) => {
    console.log('error retrieving API URLs', err);
  });
