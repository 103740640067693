<template>
  <div>
    <v-form
      ref="client"
      lazy-validation
      @submit.prevent
    >
      <v-layout wrap mt-5>
        <v-flex
          v-for="(item, i) in clientPerson"
          :key="i"
          sm6 md4
          px-2 mb-5
          v-if="!item.isHidden"
        >
          <template v-if="!item.type === 0 || item.fieldName !== 'Gender Identity' || clientPerson.sex.value === 'other'">
          <div class="strong-text mb-3">
            {{item.fieldName}}
            <sup>{{item.required}}</sup>
            <v-tooltip top color="white" max-width="20%" v-if="isFcc && item.fieldName === 'Gender'">
              <v-icon
                slot="activator"
                size="15px"
                :style="{ 'margin-right': '10px' }"
                color="grey"
              >mdi-alert-circle</v-icon>
              <span :style="{'color': 'black'}">
                {{fccGenderMessage}}
              </span>
            </v-tooltip>
            <v-icon
              size="15px"
              :style="{ 'margin-right': '10px' }"
              color="grey"
              @click="emailChangeModal = true"
              v-if="item.fieldName === 'Email' && hasEmailChangeRequestPending">
              mdi-information
            </v-icon>
          </div>
          <v-btn
            class="favorite-number"
            :class="{ selected: item.preferredNumber }"
            fab
            depressed
            round
            @click="selectPreferredNumber(item.fieldName)"
            :disabled="!editable"
            v-if="item.type === 2 && item.fieldName !== 'Fax Number' && item.fieldName !== 'Phone Number'"
          >
            <v-icon small>favorite</v-icon>
          </v-btn>
          <pas-datepicker
            :value="item.value"
            v-if="item.type === 1"
            :rules="isClientOtherContact?[]:undefined"
            @input="clientPerson.dateOfBirth.value = $event"
            :readonly="!editable"
          ></pas-datepicker>
          <pas-phone-input
            v-model="item.value"
            v-if="item.type === 2"
            :rules="item.rules"
            :readonly="!editable"
          ></pas-phone-input>
          <pas-input-field
            v-model="item.value"
            v-if="item.type === 0"
            :rules="item.rules"
            :maxLength="item.maxLength"
            :readonly="!editable || item.readOnly"
          ></pas-input-field>
          <pas-autocomplete
            v-if="item.type === 3"
            :items="item.items"
            v-model="item.value"
            :rules="item.rules"
            :readonly="!editable"
          ></pas-autocomplete>
          </template>
        </v-flex>
      </v-layout>
    </v-form>
    <EmailChangeRequest
      :isOpen="emailChangeModal"
      :newEmail="clientPerson.email.value"
      :client="activeClient.client"
      :emailRequestStatus="activeClient.emailChangeRequestStatus"
      @close="closeEmailChangeModal"
    />
    <div class="px-2">
        <div v-if="!modalMode">
          <div class="strong-text mb-3">Notes</div>
          <pas-textarea
            v-model="notes"
            :placeholder="`Write user notes here`"
            :readonly="!editable"
          ></pas-textarea>
        </div>
        <span class="error--text">{{ validationText }}</span>
        <pas-button
          v-if="!modalMode && editable"
          class="primary-btn wide-btn"
          @click="save"
          :processing="processingPerson"
          :disabled="!editable"
        >Save</pas-button>
      </div>
  </div>
</template>

<script>

import EmailChangeRequest from 'Components/Modals/EmailChangeRequest';
import _ from 'lodash';
import v from 'Services/validation';
import {
  FCC_GENDERS,
  GENDERS,
  TRUSTED_CONTACT_PERSON as HAS_TCP,
  MARITALSTATUS,
  WARNING_MESSAGES,
} from 'Services/constants';

export default {
  name: 'Client',
  components: {
    EmailChangeRequest,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const maritalStatusItems = Object.keys(MARITALSTATUS).map((x) => ({ text: MARITALSTATUS[x], value: x }));
    const isFcc = this.$store.getters.custodian === 'fcc';
    const genderOptions = isFcc ? FCC_GENDERS : GENDERS;
    const genderItems = Object.keys(genderOptions).map((x) => ({ text: genderOptions[x], value: x }));
    const hasTrustedContactPersonItems = Object.keys(HAS_TCP).map((x) => ({ text: x, value: HAS_TCP[x] }));

    return {
      clientPerson: {
        firstName: {
          fieldName: 'First Name', value: null, rules: [v.isRequired(), v.isValidName()], type: 0, required: '*',
        },
        middleName: {
          fieldName: 'Middle Name', value: null, rules: [v.isValidName()], type: 0,
        },
        lastName: {
          fieldName: 'Last Name', value: null, rules: [v.isRequired(), v.isValidName()], type: 0, required: '*',
        },
        displayName: {
          fieldName: 'Display Name', value: null, rules: [v.isRequired()], type: 0, required: '*',
        },
        dateOfBirth: {
          fieldName: 'Date of Birth', value: null, type: 1,
        },
        email: {
          fieldName: 'Email', value: null, type: 0,
        },
        homePhone: {
          fieldName: 'Home Phone Number', value: null, preferredNumber: false, rules: [v.emptyOrPhone], type: 2,
        },
        workPhone: {
          fieldName: 'Work Phone Number', value: null, preferredNumber: false, rules: [v.emptyOrPhone], type: 2,
        },
        mobilePhone: {
          fieldName: 'Mobile Phone Number', value: null, preferredNumber: false, rules: [v.emptyOrPhone], type: 2,
        },
        faxNumber: {
          fieldName: 'Fax Number', value: null, rules: [v.emptyOrPhone], type: 2,
        },
        maritalStatus: {
          fieldName: 'Marital Status', value: null, items: maritalStatusItems, rules: [v.isRequired()], type: 3, required: '*',
        },
        SIN: {
          fieldName: 'Social Insurance Number', value: null, type: 0, rules: [v.isNumeric(), v.emptyOrSpecifiedChars(9), v.isSINValid()], maxLength: 9,
        },
        sex: {
          fieldName: 'Gender', value: null, type: 3, items: genderItems,
        },
        trustedContactPerson: {
          fieldName: 'Has Trusted Contact', value: HAS_TCP.Unknown, type: 3, items: hasTrustedContactPersonItems, isHidden: !this.$store.getters.hasFeatureFlag('trusted_contact_person'),
        },
        genderIdentity: {
          fieldName: 'Gender Identity', value: null, type: 0, items: genderItems, rules: [v.isAlphabetical(), v.maxChars(35)],
        },
      },
      notes: '',
      v,
      validationText: '',
      processingPerson: false,
      isEdited: false,
      isInit: true,
      isFcc,
      fccGenderMessage: WARNING_MESSAGES.fcc_genders,
      emailChangeModal: false,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    isClientOtherContact() {
      if (this.activeClient.client && this.activeClient.client.client_status && this.activeClient.client.client_status.toLowerCase() === 'other contact') {
        return true;
      }
      return false;
    },
    registeredOnIP() {
      return this.$store.getters.hasFeatureFlag('investor_portal') && this.activeClient.IPAccountStatus;
    },
    hasEmailChangeRequestPending() {
      const PENDING_STATUS = 1;
      return (
        this.registeredOnIP
        && this.activeClient.emailChangeRequestStatus
        && this.activeClient.emailChangeRequestStatus.status === PENDING_STATUS
      );
    },
    kycFeatureFlag() {
      return this.$store.getters.hasFeatureFlag('client_maintenance') || this.$store.getters.hasFeatureFlag('annual_kyc');
    },
  },
  methods: {
    selectPreferredNumber(fieldName) {
      this.clientPerson.homePhone.preferredNumber = (fieldName === 'Home Phone Number');
      this.clientPerson.workPhone.preferredNumber = (fieldName === 'Work Phone Number');
      this.clientPerson.mobilePhone.preferredNumber = (fieldName === 'Mobile Phone Number');
    },
    handleResponses(type, msg) {
      const text = msg !== null ? msg : `${type === 'fail' ? 'Failed to update' : 'Updated'} Client Details`;
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type === 'fail' ? 'fail' : 'success');
    },
    async save() {
      this.validationText = '';
      if (this.kycFeatureFlag && this.$store.getters.hasSubmittedKycChangeRequest) {
        this.$store.commit('OPEN_MODAL', 'cannot-edit-profile');
        return;
      }
      if (!this.$refs.client.validate()) return null;
      if (!this.isClientOtherContact) {
        if (!(this.clientPerson.mobilePhone.value || this.clientPerson.workPhone.value || this.clientPerson.homePhone.value)) {
          this.validationText = 'Please enter at least one phone number. ';
        }
        if (!(this.clientPerson.homePhone.preferredNumber || this.clientPerson.workPhone.preferredNumber || this.clientPerson.mobilePhone.preferredNumber)) {
          this.validationText += 'Please select preferred number. ';
        }
        if (this.clientPerson.homePhone.preferredNumber && !this.clientPerson.homePhone.value) {
          this.validationText += 'You must indicate home number if selected as preferred.';
        }
        if (this.clientPerson.workPhone.preferredNumber && !this.clientPerson.workPhone.value) {
          this.validationText += 'You must indicate work number if selected as preferred.';
        }
        if (this.clientPerson.mobilePhone.preferredNumber && !this.clientPerson.mobilePhone.value) {
          this.validationText += 'You must indicate mobile number if selected as preferred.';
        }
      }

      if (this.validationText) return null;
      this.processingPerson = true;
      const data = {
        id: this.activeClient.client.id,
        display_name: this.clientPerson.displayName.value,
        fax_number: this.clientPerson.faxNumber.value || null,
        quick_notes: this.notes,
        client_type: 'person',
      };
      data.first_name = this.clientPerson.firstName.value;
      data.middle_name = this.clientPerson.middleName.value || null;
      data.last_name = this.clientPerson.lastName.value;
      data.date_of_birth = this.clientPerson.dateOfBirth.value || null;
      data.marital_status = this.clientPerson.maritalStatus.value;
      data.sex = this.clientPerson.sex.value;
      data.trusted_contact_person = this.clientPerson.trustedContactPerson.value;
      data.gender_identity = this.clientPerson.genderIdentity.value || null;
      data.SIN = this.clientPerson.SIN.value || null;
      data.home_number = this.clientPerson.homePhone.value || null;
      data.mobile_number = this.clientPerson.mobilePhone.value || null;
      data.office_number = this.clientPerson.workPhone.value || null;
      if (this.registeredOnIP && this.clientPerson.email.value !== this.activeClient.client.email) {
        this.emailChangeModal = true;
      } else {
        data.email = this.clientPerson.email.value || null;
      }
      this.clientPerson.homePhone.preferredNumber ? data.preferred_number = 'H' : null;
      this.clientPerson.workPhone.preferredNumber ? data.preferred_number = 'O' : null;
      this.clientPerson.mobilePhone.preferredNumber ? data.preferred_number = 'M' : null;

      await this.$store.dispatch('updateClient', data)
        .then((res) => this.handleResponses(res.id ? 'success' : 'fail', res.id ? 'Client details successfully updated.' : 'Error in updating client profile.'))
        .catch((err) => this.handleResponses('fail', err));
      this.processingPerson = false;

      this.updateDropDownItems();
    },
    closeEmailChangeModal() {
      // email won't be changed until client verifies new email
      this.clientPerson.email.value = this.activeClient.client.email;
      this.emailChangeModal = false;
    },
    updateDropDownItems() {
      const tcpField = this.clientPerson.trustedContactPerson;

      if (tcpField.value !== HAS_TCP.Unknown) {
        tcpField.items = tcpField.items
          .filter((i) => i.value !== HAS_TCP.Unknown);
      }
    },
  },
  mounted() {
    const client = _.cloneDeep(this.activeClient);
    if (client.person) {
      this.clientPerson.firstName.value = client.person.first_name || null;
      this.clientPerson.middleName.value = client.person.middle_name || null;
      this.clientPerson.lastName.value = client.person.last_name || null;
      this.clientPerson.displayName.value = client.person.display_name || null;
      this.clientPerson.dateOfBirth.value = client.person.date_of_birth || null;
      this.clientPerson.maritalStatus.value = client.person.marital_status || null;
      this.clientPerson.SIN.value = client.person.SIN || null;
      this.clientPerson.sex.value = client.person.sex || null;
      this.clientPerson.trustedContactPerson.value = client.person.trusted_contact_person || HAS_TCP.Unknown;
    }
    if (client.client) {
      this.clientPerson.email.value = client.client.email || null;
      this.clientPerson.homePhone.value = client.client.home_number || null;
      this.clientPerson.workPhone.value = client.client.office_number || null;
      this.clientPerson.mobilePhone.value = client.client.mobile_number || null;
      this.clientPerson.faxNumber.value = client.client.fax_number || null;
      this.notes = client.client.quick_notes || '';
      // Initial email validation here
      if (!this.isClientOtherContact) {
        this.clientPerson.email.rules = [v.isValidEmail];
      }
    }
    if (client.client && client.client.preferred_number) {
      client.client.preferred_number === 'H' ? this.clientPerson.homePhone.preferredNumber = true : null;
      client.client.preferred_number === 'M' ? this.clientPerson.mobilePhone.preferredNumber = true : null;
      client.client.preferred_number === 'O' ? this.clientPerson.workPhone.preferredNumber = true : null;
    }

    this.updateDropDownItems();

    this.isEdited = false;
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        if (!this.isEdited) {
          const client = _.cloneDeep(this.activeClient);
          if (client.person) {
            this.clientPerson.firstName.value = client.person.first_name || null;
            this.clientPerson.middleName.value = client.person.middle_name || null;
            this.clientPerson.lastName.value = client.person.last_name || null;
            this.clientPerson.dateOfBirth.value = client.person.date_of_birth || null;
            this.clientPerson.maritalStatus.value = client.person.marital_status || null;
            this.clientPerson.SIN.value = client.person.SIN || null;
            this.clientPerson.sex.value = client.person.sex;
            this.clientPerson.genderIdentity.value = client.person.gender_identity;
            this.clientPerson.displayName.value = client.person.display_name || null;
          }
          if (client.client) {
            this.clientPerson.displayName.value = client.client.display_name || null;
            this.clientPerson.email.value = client.client.email || null;
            this.clientPerson.homePhone.value = client.client.home_number || null;
            this.clientPerson.workPhone.value = client.client.office_number || null;
            this.clientPerson.mobilePhone.value = client.client.mobile_number || null;
            this.clientPerson.faxNumber.value = client.client.fax_number || null;
            this.notes = client.client.quick_notes || '';
          }
          if (client.client && client.client.preferred_number) {
            client.client.preferred_number === 'H' ? this.clientPerson.homePhone.preferredNumber = true : null;
            client.client.preferred_number === 'M' ? this.clientPerson.mobilePhone.preferredNumber = true : null;
            client.client.preferred_number === 'O' ? this.clientPerson.workPhone.preferredNumber = true : null;
          }
        }
      },
      deep: true,
    },
    clientPerson: {
      handler(val, oldVal) {
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
      deep: true,
    },
    hasEmailChangeRequestPending: {
      handler() {
        this.clientPerson.email.readOnly = !!this.hasEmailChangeRequestPending;
      },
      immediate: true,
    },
  },
};

</script>

<style lang="scss">
.favorite-number {
  &:hover {
    border-style: none !important;
  }
  position: absolute !important;
  right: 3px;
  bottom: 42px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white !important;
  .v-icon {
    color: rgba(0,0,0,0.2) !important;
  }
}
.favorite-number.selected {
  background-color: #EAEAEA !important;
  border: none !important;
}
</style>
