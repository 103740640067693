<!-- This is a dropdown for reassigning a workflow
  Args: store -> users: current user, team members
        props -> workflow
  Actions: Reassign workflow
  Events: Reassign workflow success
  Conditions: If user's workflow, display dropdown
              Else display who it's assigned to
 -->

<template>
  <pas-drop-down
    :disabled="disabled"
    :items="formattedTeamMembers"
    :value="workflow.assigned_user"
    @change="reAssignWorkflow($event)"
    @click.stop
  ></pas-drop-down>
</template>

<script>

export default {
  name: 'reassign-workflow',
  data() {
    return {};
  },
  props: {
    workflow: Object,
  },
  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    teamMembers() {
      return this.$store.getters.teamMembers;
    },
    disabled() {
      return this.workflow.assigned_user !== this.currentUser._id;
    },
    formattedTeamMembers() {
      const ftm = this.teamMembers.map((m) => ({ text: `${m.first_name} ${m.last_name}`, value: m.ac_mongo_id }));
      return ftm;
    },
  },
  methods: {
    reAssignWorkflow(userID) {
      const { workflow } = this;
      this.$store
        .dispatch('reassignWorkflow', { workflow, userID })
        .then((res) => {
          if (!res.error) {
            this.$store.dispatch('setSnackbar', {
              text: `You have successfully reassigned a workflow to ${
                workflow.assigned_user_name
              }`,
              type: 'success',
            });
            this.$store.dispatch('flipSnackbarSwitch');
          } else {
            this.$store.dispatch('setSnackbar', {
              text: `Error in reassigning workflow to ${
                workflow.assigned_user_name
              }`,
              type: 'error',
            });
            this.$store.dispatch('flipSnackbarSwitch');
          }
        })
        .catch((err) => {
          this.$store.dispatch('setSnackbar', {
            text: `Error in reassigning workflow to ${
              workflow.assigned_user_name
            }`,
            type: 'error',
          });
          this.$store.dispatch('flipSnackbarSwitch');
        });
    },
  },
};
</script>

<style>

</style>
