<template>
    <div>
      <v-layout>
        <v-flex>
          <span class="form-label workflow-step strong-text mb-3 align-center justify-center">
            <template v-if="(step.status === 'process_error' || step.status === 'needs_attention') && numOfErrors" >
              <v-chip @click="$emit('open-error-dialog')" disabled color="red darken-1" text-color="white" >
                <v-avatar class="red darken-4">{{ numOfErrors }}</v-avatar>
                View Step Errors
              </v-chip>
            </template>
            <template v-else>
              <pas-status-bullet
                v-if="WORKFLOW_STATUS_MAP[step.status]"
                :color="WORKFLOW_STATUS_MAP[step.status].color"
                :value="100"
              />
              <span class="form-label strong-text mb-3">{{getStepStatusLabel(step)}}</span>
              <template v-if="(step.status === 'process_error' || step.status === 'needs_attention' || step.name ==='client_signing')">
                <v-flex>
                  <PasButton
                    v-if="canAccessPushStep && step.can_be_triggered"
                    class="primary-btn wide-btn mt-3"
                    :processing="isPushStepLoading"
                    @click="retriggerStep();"
                  >Push Step</PasButton>
                </v-flex>
              </template>
              <!-- Complete step date and info -->
              <span v-if="step.status === 'completed'" class="p-text">
                  <span class="vertical-align">
                    &bull;&nbsp;{{moment(step.updated_at).format('D MMMM YYYY, hA').toLocaleString()}}
                </span>
                  <p class="workflow-step-info">
                    <!-- TODO: show detailed info based on advisor approval and compliance approval status -->
                  </p>
              </span>
              <span v-if="step.status === 'pre_update_approval' && workflow.type === 'remediation_repapering'" class="p-text">
                  <span class="vertical-align">
                    &bull;&nbsp;{{moment(step.updated_at).format('D MMMM YYYY, hA').toLocaleString()}}
                </span>
                  <p class="workflow-step-info">
                    Pre-Update Approval is Required
                  </p>
              </span>
            </template>
          </span>

          <!-- Complete step date and info -->
          <span v-if="step.status === 'completed'" class="p-text">
              <span class="vertical-align">
                &bull;&nbsp;{{moment(step.updated_at).format('D MMMM YYYY, hA').toLocaleString()}}
            </span>
              <p class="workflow-step-info">
                <!-- TODO: show detailed info based on advisor approval and compliance approval status -->
              </p>
          </span>
        </v-flex>
      </v-layout>
      <!-- {{step}} -->

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WORKFLOW, WORKFLOW_STATUS_MAP } from 'Services/constants';
import acApi from 'Services/api';

export default {
  name: 'WorkflowStepStatus',
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    step: {
      type: Object,
    },
  },
  data() {
    return {
      WORKFLOW,
      WORKFLOW_STATUS_MAP,
      isPushStepLoading: false,
    };
  },
  computed: {
    ...mapGetters(['hasFullWorkflowEditPermission']),
      numOfErrors() {
        if (this.step.error_details) {
        return this.step.error_details.length;
      }
      return 0;
    },
    canAccessPushStep() {
      return this.$store.state.permissions.submodules.hasOwnProperty(this.workflow.type)
      && this.$store.state.permissions.submodules[this.workflow.type].write
      && this.hasFullWorkflowEditPermission;
    },
  },
  methods: {
    getStepStatusLabel(step) {
      return WORKFLOW.STATUS_NAMES[step.status];
    },
    async refreshWorkflow() {
      this.workflowID = this.$route.params.workflowID;
      return this.$store.dispatch('fetchWorkflowV2Steps', this.workflowID);
    },
    async retriggerStep() {
      this.isPushStepLoading = true;

      const payload = {
        message: `workflow.${this.workflow.type}.${this.step.name}`,
        data: {
          workflow_id: this.workflow.id,
        },
      };

      await acApi.post('/advisor_portal/send_message', payload);

      setTimeout(async () => {
        await this.refreshWorkflow();
        this.isPushStepLoading = false;
      }, 2000)
    },
  },
};
</script>

<style lang="scss">
.workflow-step {
  .v-icon {
    font-size: 18px !important;
  }
  .v-btn--floating.v-btn--small {
    height: 30px !important;
    width: 30px !important;
  }
  .v-chip .v-chip__content {
    cursor: pointer !important;
  }
}
</style>
