<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div v-if="hasFullWorkflowEditPermission">
      <div>
        <span @click.prevent="completeDocumentProcessing" class="action-text">Mark as complete</span>
      </div>
    </div>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import api from 'Services/api';
import { sendDocumentProcessingSuccess } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'DocumentProcessingManualStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      complianceDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
    ]),
  },
  methods: {
    async completeDocumentProcessing() {
      try {
        const res = await sendDocumentProcessingSuccess(this.workflow, this.step);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>
</style>
