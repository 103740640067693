<!-- eslint-disable max-len -->
<template>
  <Withdrawal
    :editMode="editMode"
    :frequencyOptions="frequencyOptions"
    :withdrawalTypeOptions="withdrawalTypes"
    :paymentMethodsOptions="paymentMethodsOptions"
    :withholdingTaxTypeOptions="withholdingTaxTypes"
    :currencyOptions="currencyOptions"
    :recipientOptions="recipientTypes"
    :recurringEventOptions="recurringEventOptions"
    :investmentAccts="setInvestmentAccts"
    :filteredToInvestmentAccts="filteredToInvestmentAccts"
    :bankAccts="bankAccts"
    :withdrawalRequest="withdrawalRequest"
    :requestSubtype="selectedRequestSubtype"
    :docList="selectedRequestSubtype.docList"
    :optionalDocList="selectedRequestSubtype.optionalDocList"
    :errorAlertVisible="errorAlertVisible"
    :errorMessage="errorMessage"
    :warningMessage="warningMessage"
    v-on:reviewWithdrawal="reviewWithdrawal"
    v-on:back="back"
    class="mt-5"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { postComplexFundingRequest, postRequest } from '../../../services/api/FundingVersion2';
import {
  DEFAULT_WITHDRAWAL_SUBTYPE,
  WITHDRAWAL_CURRENCY_TYPES,
  REQUEST_SUBTYPE_IDS,
  dataFromValidatedRequest,
  formatAmountToDecimal,
  parseBankDetails,
  parseWithdrawalTypeAmounts,
  removeEmptyVals,
  isCustomWithdrawalType,
  CUSTOM_AMOUNT_IDS,
} from '../../../services/helpers/funding/index';

export default {
  name: 'MakeWithdrawal',
  data() {
    return {
      withdrawalRequest: {
        selectedFromAcct: null, // pass account id only if needed
        amount: null,
        selectedToAcct: null, // pass account id only if needed
        reason: null,
        frequency: null,
        startDate: null,
        endDate: null,
        note: null,
      },
      errorAlertVisible: false,
      errorMessage: 'Sorry, something went wrong. Try again in a few minutes.',
    };
  },
  computed: {
    ...mapGetters([
      'fundingFrequencies',
      'withdrawalTypes',
      'paymentMethods',
      'withholdingTaxTypes',
      'currencyTypes',
      'recipientTypes',
      'recurringEvents',
      'investmentAccts',
      'bankAccts',
      'validatedRequest',
      'activeRequest',
      'editMode',
      'selectedRequestSubtype',
      'complexFundingFeatureFlag',
      'fundingDocuments',
      'filteredToInvestmentAccts',
      'warningMessage',
    ]),
    setInvestmentAccts () {
      if (DEFAULT_WITHDRAWAL_SUBTYPE.id == 5){
        return this.investmentAccts.filter((acc) => acc.primary_client_id === this.$route.params.clientID);
      }
      else {
        return this.investmentAccts;
      }
    },
    frequencyOptions() {
      if (this.fundingFrequencies.length) {
        return this.fundingFrequencies.filter((e) => e.id !== '1'); // remove unknown
      }
      return [];
    },
    paymentMethodsOptions() {
      if (this.paymentMethods.length) {
        return this.paymentMethods.filter((e) => e.id !== '1'); // remove unknown
      }
      return [];
    },
    currencyOptions() {
      if (this.currencyTypes.length) {
        return this.currencyTypes.filter((e) => WITHDRAWAL_CURRENCY_TYPES.includes(e.iso_code));
      }
      return [];
    },
    recurringEventOptions() {
      if (this.recurringEvents.length) {
        return this.recurringEvents.map((event) => ({ ...event, label: `$${event.amount} - ${event.frequency} - ${event.from_account?.account_name}` }));
      }
      return [];
    },
    activeRequestBankAcctId() {
      if (this.activeRequest.from_account && this.activeRequest.to_account) {
        if (this.activeRequest.to_account.type === 'bank_account') {
          return this.activeRequest.to_account.account_id;
        }
        if (this.activeRequest.from_account.type === 'bank_account') {
          return this.activeRequest.from_account.account_id;
        }
      }
      return null;
    },
    activeRequestInvestmentAcctId() {
      if (this.activeRequest.from_account && this.activeRequest.to_account) {
        if (this.activeRequest.to_account.type === 'investment_account') {
          return this.activeRequest.to_account.account_id;
        }
        if (this.activeRequest.from_account.type === 'investment_account') {
          return this.activeRequest.from_account.account_id;
        }
      }
      return null;
    },
  },
  methods: {
    async reviewWithdrawal(reviewPayload) {
      try {
        let validatedPayload;
        if (this.selectedRequestSubtype?.id === DEFAULT_WITHDRAWAL_SUBTYPE.id) {
          validatedPayload = await postRequest({ requestedScope: 'validate', body: reviewPayload });
        } else {
          if (this.selectedRequestSubtype?.id === REQUEST_SUBTYPE_IDS.WITHDRAWAL_RECURRING_EVENT_AUTO_SELL) {
            if (!['RRIF', 'RRIF-S', 'PRIF', 'LIF', 'LRIF', 'RLIF'].includes(reviewPayload.recurring_event.account_type) && reviewPayload.share_certificates.some((s) => s.minimum_amount)) {
              throw new Error('Minimum amount is only allowed for RRIF, RRIF-S, PRIF, LIF, LRIF, and RLIF accounts');
            }
            if (!['LIF', 'LRIF', 'RLIF'].includes(reviewPayload.recurring_event.account_type) && reviewPayload.share_certificates.some((s) => s.maximum_amount)) {
              throw new Error('Maximum amount is only allowed for LIF, LRIF, and RLIF accounts');
            }
          }
          validatedPayload = await postComplexFundingRequest({ requestedScope: 'validate', body: reviewPayload });
        }
        this.$store.dispatch('setValidatedRequest', validatedPayload);
        this.$store.dispatch('setFundingDocuments', reviewPayload.documents);
        this.goToReviewCard();
      } catch (err) {
        this.errorAlertVisible = true;
        this.errorMessage = err.message;
      }
    },
    populateRequestData() {
      if (Object.keys(this.activeRequest).length !== 0 && Object.keys(this.validatedRequest).length === 0) {
        this.withdrawalRequest.selectedToAcct = this.activeRequestBankAcctId;
        this.withdrawalRequest.selectedFromAcct = this.activeRequestInvestmentAcctId;
        this.withdrawalRequest.amount = formatAmountToDecimal(this.activeRequest.amount);
        this.withdrawalRequest.reason = this.activeRequest.reason;
        this.withdrawalRequest.frequency = this.fundingFrequencies.find((e) => e.frequency === this.activeRequest.frequency).id;
        this.withdrawalRequest.startDate = this.activeRequest.start_date;
        this.withdrawalRequest.endDate = this.activeRequest.end_date;
        this.withdrawalRequest.note = this.activeRequest.notes || null;
      } else {
        this.withdrawalRequest = dataFromValidatedRequest(this.withdrawalRequest, this.validatedRequest, this.fundingDocuments);
        this.withdrawalRequest.selectedToAcct = this.validatedRequest.bank_account
                                              || this.validatedRequest.to_bank_account
                                              || this.validatedRequest.to_investment_account;
        this.withdrawalRequest.selectedFromAcct = this.validatedRequest.investment_account
                                                || this.validatedRequest.from_investment_account;
        this.withdrawalRequest.reason = this.validatedRequest.reason || null;
        this.withdrawalRequest.withdrawalType = this.validatedRequest.withdrawal_type || null;
        this.withdrawalRequest.paymentMethod = this.validatedRequest.payment_method || null;
        this.withdrawalRequest.withholdingTaxType = this.validatedRequest.withholding_tax_type || null;
        this.withdrawalRequest.withholdingTax = this.validatedRequest.withholding_tax || null;
        const bankAccountRequestData = this.validatedRequest.to_wire_transfer_bank_account || null;
        this.withdrawalRequest.bankAccountDetails = parseBankDetails(bankAccountRequestData);
        this.withdrawalRequest.recipientType = this.validatedRequest.resp_recipient || null;
        this.withdrawalRequest.withdrawalTypeAmounts = parseWithdrawalTypeAmounts(this.validatedRequest);
        if (isCustomWithdrawalType(this.validatedRequest.withdrawal_type)) {
          this.withdrawalRequest.customAmount = this.validatedRequest.net_amount
            ? CUSTOM_AMOUNT_IDS.NET
            : CUSTOM_AMOUNT_IDS.GROSS;
        }
        this.withdrawalRequest = removeEmptyVals(this.withdrawalRequest);
      }
    },
    goToReviewCard() {
      this.$router.push({
        path: `${this.complexFundingFeatureFlag && !this.editMode ? 'withdrawal/' : ''}review/`,
      });
    },
    back() {
      this.$router.go(-1);
      this.$store.dispatch('setValidatedRequest', {});
      this.$store.dispatch('setActiveRequest', {});
      this.$store.dispatch('setWarningMessage', {});
    },
  },
  mounted() {
    if (Object.keys(this.validatedRequest).length || Object.keys(this.activeRequest).length) {
      this.populateRequestData();
    }
  },
  created() {
    if (Object.keys(this.validatedRequest).length || Object.keys(this.activeRequest).length) {
      this.populateRequestData();
    }
  },
};
</script>

<style>

</style>
