import { FIRMS } from '../constants';

let Store = null;
let Router = null;

export const initAuth = (store, router) => {
  Store = store;
  Router = router;
};

export const logUserOut = () => {
  Store.dispatch('logUserOut');
  Router.push({ name: 'login' });
};

export const isFirm = (firm) => {
  const isValid = Object.keys(FIRMS).includes(firm.toLowerCase());
  return isValid ? firm.toLowerCase() : false;
};

export const isValidPassword = (password) => {
  return password
    && password.length >= 8
    && /[a-z]/.test(password)
    && /[A-Z]/.test(password)
    && /[0-9]/.test(password)
    && /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(password);
}

export const getPasswordErrorMessage = (password) => {
  if (!password) return 'Please enter password';
  if (password.length < 8) {
    return 'Your password must be at least 8 characters.';
  }
  if (!/[a-z]/.test(password)) {
    return 'Your password must contain at least one lower case letter.';
  }
  if (!/[A-Z]/.test(password)) {
    return 'Your password must contain at least one upper case letter.';
  }
  if (!/[0-9]/.test(password)) {
    return 'Your password must contain at least one digit.';
  }
  if (!/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(password)) {
    return 'Your password must contain at least one special character.';
  }

  return '';
}
