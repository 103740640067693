<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span @click.prevent="completeSigning" class="action-text">
      Mark as complete
    </span>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendDocumentSigningSuccess } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'AdvisorSigningStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
  },
  methods: {
    async completeSigning() {
      try {
        const res = await sendDocumentSigningSuccess(this.workflow, 'advisor');
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
