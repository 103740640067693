<template>
  <v-flex xs12 :class="{'comment-div': true, 'indented': isReply}" py-2>
    <template v-if="_deleteProcessing || _editProcessing">
                    <pas-circular-loader></pas-circular-loader>
                  </template>
          <v-layout row wrap v-else>
            <v-flex xs12>
               
              <v-layout
                row
                nowrap
              >
                <v-flex xs12>
                 
                  <v-layout fluid align-center mb-2>
                     <v-flex :style="{'maxWidth': '60px'}">
                        <pas-avatar>
                        </pas-avatar>
                      </v-flex>
                      <v-flex>
                        <v-layout column>
                          <v-flex>
                            <div class="strong-text">
                              {{comment.author.name}}
                            </div>
                          </v-flex>
                          <v-flex>
                            <span class="small-text">{{
                              (getTimeDiffString(new Date(comment.posted_at)).indexOf('seconds') !== -1 ? 'Just now' :  getTimeDiffString(new Date(comment.posted_at)))
                            }}</span>
                            <template v-if="!(comment.is_deleted)">
                              <span :style="{'font-size': '8px'}">&nbsp;&bull;&nbsp;</span>
                              <span class="action-text-small"
                                @click="$emit('reply', comment)"
                              >Reply</span>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-spacer>
                      </v-spacer>
                      <v-flex text-xs-right>
                        <template v-if="shouldShowCommentMenu(comment)">
                          <pas-more-menu
                            @click="$event(comment._id)"
                            :editOptions="editOptions"
                          >
                          </pas-more-menu>
                        </template>
                      </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer>
                </v-spacer>
                <v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
            <template v-if="!inEditMode">
              <v-flex xs12>
                <span v-if="isReply && !isTopLevelReply" class="action-text">@{{comment.replyTo.author.name}}</span>
                <span class="p-text">{{!(comment.is_deleted) ? comment.body : `[This comment was deleted]`}}</span>
              </v-flex>
            </template>
            <template v-else>
              <v-flex xs12>
                <v-form
                  ref="editCommentForm"
                  @submit.prevent="() => {}"
                  lazy-validation
                  novalidate="true"
                >
                <v-textarea
                  v-model="editedCommentBody"
                  autofocus
                  flat
                  row-height="1"
                  auto-grow
                  :rules="[v.isRequired()]"
                  class="edit-textarea"
                >
                </v-textarea>
                </v-form>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn :color="primaryColor" flat @click="saveEdit" class="square-grey-button"
                >Save</v-btn>
                <v-btn :color="primaryColor" flat @click="cancelEdit" class="square-grey-button"
                >Cancel</v-btn>
              </v-flex>
            </template>
            <v-flex xs12 text-xs-right v-if="comment.replies.length && !isReply" pr-3>
              <v-btn small class="square-grey-button no-hover-modification"
                @click="$emit('showReplies', comment._id)"
              >
                {{repliesExpanded ? 'Hide ' : 'Show '}}{{`${comment.replies.length} ${comment.replies.length === 1 ? `reply` : `replies`}`}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
</template>

<script>
import { getTimeDiffString } from 'Services/helpers/time'
import v from 'Services/validation'
import _ from 'lodash'

export default {
  name: 'workflow-view-discussion-comment',
  mounted() {
    this.editedCommentBody = _.cloneDeep(this.comment.body)
  },
  data() {
    return {
      v:v,
      getTimeDiffString: getTimeDiffString,
      editedCommentBody: _.cloneDeep(this.comment.body),
      editOptions: [
        {
          title: "Edit",
          onClick: commentId => 
            {this.$emit('edit', commentId)}
        },
        {
          title: "Delete",
          onClick: commentId => 
            { this.$emit('delete', commentId)}
        }
      ]    
    }
  },
  props: {
    comment: Object,
    isReply: {
      type: Boolean,
      default: false
    },
    isTopLevelReply: {
      type: Boolean,
      default: false
    },
    repliesExpanded: {
      type: Boolean,
      default: false
    },
    inEditMode: {
      type: Boolean,
      default: false
    },
    _deleteProcessing: {
      type: Boolean,
      default: false
    },
    _editProcessing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    primaryColor() {
			return this.$store.state.app.themeVars.primaryColor
		},
    currentUser() {
      return this.$store.state.users.currentUser
    }
  },
  methods: {
    shouldShowCommentMenu(comment) {
      if (comment.is_deleted) return false
      if (!(this.isCurrentUser())) return false
      if (this.inEditMode) return false
      return true
    },
    isCurrentUser() {
      if (this.comment.author._id.toString() === this.currentUser._id.toString()) return true
    },
    cancelEdit() {
      this.editedCommentBody = this.comment.body
      this.$emit('cancelEdit')
    },
    saveEdit() {
      if (!this.editedCommentBody) {
        this.cancelEdit()
        return
      }
      else if (!(this.$refs.editCommentForm.validate())) return false
      this.$emit('saveEdit', {...this.comment, body: this.editedCommentBody})
    }
  },
  watch: {
    comment: {
      deep: true,
      handler: function() {
        this.editedCommentBody = _.cloneDeep(this.comment.body)
      }
    }
  }
}
</script>

<style lang="scss">
  .comment-div {
      border-top: 1px solid #dddddd;
  }
  .comment-div.indented {
    margin-left: 50px;
  }
  .comment-div:first-child {
    border-top: none !important;
  }
  .comment-div:last-child {
    border-top: none !important;
  }
  
  .square-grey-button {
    border-radius: 4px !important;
    box-shadow: 0px 0px 0px 1px #ddd inset !important;
    border-color:#f4f4f4;
    padding: 0 13px !important;
    font-weight: bold !important;
    &:before {
      background-color: #f5f5f5 !important;
    }
    &:hover {
      background-color: #f5f5f5 !important;
    }
    &:not(.no-hover-modification):before {
      background-color: transparent !important;
    }
    &:not(.no-hover-modification):hover {
      background-color: transparent !important;
    }
    .v-btn__content {
      font-size: 10px !important;
      color: #959595;
      text-transform: capitalize !important;
      letter-spacing: 0.5px !important;
      
    }
  }
  .edit-textarea .v-input__slot:before {
    display: none !important;
  }
  .edit-textarea .v-input__slot:after {
    display: none !important;
  }
</style>
