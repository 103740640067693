<template>
  <auth-page>
    <template>
      <v-layout column>
        <v-flex>
          <pas-page-title>Create an account</pas-page-title>
        </v-flex>
        <v-flex>
          <div class="light-text">To create an account, please confirm your email</div>
        </v-flex>
        <v-flex mt-4>
          <v-layout column>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent
              @keyup.enter.native="submitEmail"
            >
            <div class="strong-text form-label">Email Address</div>
            <pas-input-field
              :placeholder="`Email`"
              v-model="email"
              :rules="[v.isRequired(), v.isValidEmail]"
            ></pas-input-field>
            </v-form>
          </v-layout>
        </v-flex>
        <v-flex mt-5>
          <v-layout>
            <v-flex shrink text-xs-left>
              <div class="light-text">Already have an account?</div>
              <router-link :to="{name: 'login'}" class="action-text">Sign-in.</router-link>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <pas-button class="px-5" @click="submitEmail">
                Next
              </pas-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </auth-page>
</template>

<script>
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage'
import v from 'Services/validation'

export default {
  name: 'register',
  components: {
    AuthPage
  },
  data() {
    return {
      v,
      email: ''
    }
  },
  methods: {
    submitEmail() {
      if (!(this.$refs.form.validate())) return
      this.$router.push({ name: 'details', query: { email: this.email }})
    }
  },
  watch: {
    email() {
      this.$refs.form.resetValidation()
    }
  },
  watch: {
    email() {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style>

</style>