<template>
  <div class="px-5">
    <v-tabs  fluid class="pas-tabs justify-left">
      <v-tab
      :key="`tab-1`"
      >
      <h2 class="section-title pas-tabs-title">Related Documents</h2>
      </v-tab>
      <v-tab
      :key="`tab-2`"
      >
      <h2 class="section-title pas-tabs-title">Upload Documents</h2>
      </v-tab>
      <v-tab-item
      :key="`tab-item-1`"
      >
        <workflow-view-related-documents-tab
          :documents="documents"
          :workflowId="workflow._id"
        >
        </workflow-view-related-documents-tab>
      </v-tab-item>
      <v-tab-item
      :key="`tab-item-2`"
      >
        <workflow-view-upload-documents-tab
          :workflowId="workflow._id"
        >
        </workflow-view-upload-documents-tab>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>

import WorkflowViewRelatedDocumentsTab from './WorkflowViewDocuments/WorkflowViewRelatedDocumentsTab'
import WorkflowViewUploadDocumentsTab from './WorkflowViewDocuments/WorkflowViewUploadDocumentsTab'

export default {
  name: 'workflow-view-documents',
  components: {
    WorkflowViewRelatedDocumentsTab,
    WorkflowViewUploadDocumentsTab
  },
  props: {
    workflow: Object
  },
  data() {
    return {

    }
  },
  computed: {
    documents() {
      return (this.workflow ? this.workflow.docs.map(d => {
        return {
          name: d.display_name,
          _id: d._id,
          key: d.aws_info.key,
          file_type: d.file_type ? d.file_type : this.getFileType(d.aws_info.key)
        }
      }) : [])
    }
  },
  methods: {
    getFileType(fileName){
      let extension = fileName.substr(fileName.lastIndexOf('.')+1).toLowerCase();
      const fileTypeCode = { pdf: 0, doc: 1, docx: 1, jpeg: 2, jpg: 2, png: 3, xls: 4, xlsm: 4, xlsx: 4 };
      return fileTypeCode[extension] || fileTypeCode[extension] == 0 ? fileTypeCode[extension] : 5;
    }
  }
}
</script>

<style>

</style>
