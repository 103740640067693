<template>
	<div>
		<pas-page-title class="page-header"> Account Settings </pas-page-title>
		<pas-tabs :numberOfTabs="1" class="tabs-page-view">
			<template v-slot:tabTitle1>Your Details</template>
			<template v-slot:tabItem1>
				<div class="px-2 mb-4">
					<pas-avatar
						size='100'
						:avatar="getAvatar('platform_users',currentUser.platform_user_id)"
						v-if="avatarRefresh"
					></pas-avatar>
					<v-menu
						offset-y
						class="edit-user-avatar"
						open-on-hover
						open-on-click
					>
						<v-btn fab small class="pas-fab" slot="activator" icon>
							<v-icon>edit</v-icon>
						</v-btn>
						<v-list>
							<v-list-tile>
								<v-list-tile-title class="cursor-pointer">
									<upload-avatar
										:id="'userAvatarEdit'"
										:type="false"
										@updateDropzone="updateDropzone"
										v-if="dropzoneRefresh"
									>
										<div class="px-4 more-menu-item ">Upload</div>
									</upload-avatar>
								</v-list-tile-title>
							</v-list-tile>
							<v-list-tile>
								<v-list-tile-title class="cursor-pointer" @click="deleteAvatar">
									<div class="px-4 more-menu-item">Remove</div>
								</v-list-tile-title>
							</v-list-tile>
						</v-list>
					</v-menu>
				</div>
				<v-form
					ref="form"
					lazy-validation
					@submit.prevent
				>
					<v-layout wrap mt-5>
						<v-flex
							v-for="(item, i) in topSection"
							:key="i"
							px-2 mb-4
							:class="item.class"
							:maxLength="item.maxLength"
						>
							<div :class="`strong-text form-label ${item.class}`">{{item.fieldName}}</div>
							<pas-input-field
								v-model="item.value"
								:rules="item.rules"
								:placeholder="item.placeholder"
								v-if="item.fieldName !== `Phone Number`"
								:disabled="item.disabled"
							></pas-input-field>
							<pas-phone-input
								v-model="item.value"
								v-if="item.fieldName === `Phone Number`"
								:rules="item.rules"
								:placeholder="item.placeholder"
							></pas-phone-input>
						</v-flex>
					</v-layout>
					<div class="px-2 mt-2">
						<pas-button
							class="primary-btn wide-btn"
							@click="updateUser"
							:processing="userUpdateLoading"
							:disabled="!userFormValid"
						>Save Changes</pas-button>
					</div>
				</v-form>
				<v-form
					ref="password"
					lazy-validation
					@submit.prevent
				>
					<v-layout v-if="!isFirmSSOActivated()" wrap mt-5>
						<v-flex
							v-for="(item, i) in bottomSection"
							:key="i"
							px-2 mb-4
							sm6 md4
						>
							<div class="strong-text form-label">{{item.fieldName}}</div>
							<pas-input-field
								v-model="item.value"
								:rules="item.rules"
								:placeholder="item.placeholder"
								:type="`password`"
							></pas-input-field>
						</v-flex>
						<v-flex pl-2 mb-2>
							<pas-error-div :show="errorText!==''">{{errorText}}</pas-error-div>
						</v-flex>
					</v-layout>
					<div v-if="!isFirmSSOActivated()" class="px-2 mt-2">
						<pas-button
							class="primary-btn wide-btn"
							@click="savePassword"
							:processing="savePasswordProcessing"
							:disabled="!passwordFormValid"
						>Save Changes</pas-button>
					</div>

					<div v-if="isFirmSSOActivated()" class="px-2 mt-2">
						<pas-button
							class="primary-btn wide-btn"
							@click="sendResetPasswordEmail"
							:processing="sendingResetPasswordEmail"
						>Change Password</pas-button>
						<pas-button
							class="primary-btn wide-btn"
							@click="resetMfa"
							:processing="resettingAuth0Mfa"
						>Reset MFA</pas-button>
					</div>

				</v-form>
				<div v-bind:style="{ height: '30px' }"></div>
			</template>
		</pas-tabs>
	</div>
</template>

<script>
import { getAvatar } from 'Services/helpers/files'
import v from 'Services/validation'
import api from 'Services/api'
import dhApi from 'Services/apiV2'
import UploadAvatar from 'Components/Shared/UploadAvatar'
export default {
	name: 'my-settings',
	components: {
		UploadAvatar
	},
	data() {
		return {
			topSection: {
				firstName: { fieldName: "First Name", class:"sm6 md4", value: null, rules: [v.isRequired()] },
				lastName: { fieldName: "Surname", class:"sm6 md4", value: null, rules: [v.isRequired()] },
				email: { fieldName: "Your Email", class:"sm6 md4", value: null, rules: [v.isRequired(), v.isValidEmail], disabled: true },
				officeAddressLine1: { fieldName: "Office Address Line 1", class:"sm6 md4", value: null, disabled: true },
				officeAddressLine2: { fieldName: "Office Address Line 2", class:"sm6 md4", value: null,  disabled: true },
				city: { fieldName: "City", class:"sm6 md4", value: null,  disabled: true },
				province: { fieldName: "Province", class:"sm6 md2", value: null, rules: [v.emptyOrSpecifiedChars(2)], disabled: true, maxLength: 2 },
				postalCode: { fieldName: "Postal Code", class:"sm6 md2", value: null, rules: [v.maxCharsOrEmpty(6)],  disabled: true, maxLength: 6 },
				country: { fieldName: "Country", class:"sm6 md4", value: null,  disabled: true },
				phoneNumber: { fieldName: "Phone Number", class:"sm6 md4", value: null, rules: [v.emptyOrPhone] }
			},
			bottomSection: {
				oldPassword:{fieldName: "Old Password", value: null, rules: [v.isRequired()]},
				newPassword: {fieldName: "New Password", value: null, rules: [v.isRequired()]},
				confirmNewPassword: {fieldName: "Confirm New Password", value: null, rules:
					[ v => (v && this.bottomSection.newPassword.value === v) || 'Passwords must match']
				},
			},
			v: v,
			userFormValid: true,
			passwordFormValid: true,
			errorText: '',
			responses: [],
			avatarRefresh: true,
			dropzoneRefresh: true,
			getAvatar,
			userUpdateLoading: false,
			savePasswordProcessing: false,
			sendingResetPasswordEmail: false,
			resettingAuth0Mfa: false
		}
	},
	computed: {
		currentUser(){
			return this.$store.state.users.currentUser;
		},
		currentUserDatahub(){
			return this.$store.state.users.currentUserDatahub;
		},
		firm() {
			return this.$store.getters.firm
		}
	},
	methods: {
		isFirmSSOActivated() {
			return this.$auth.isFirmSSOActivated(this.firm)
		},
		updateAvatar() {
			this.avatarRefresh = false;
			this.$nextTick().then(() => {
					this.avatarRefresh = true;
			});
		},
		updateDropzone() {
			this.updateAvatar()
			this.dropzoneRefresh = false;
			this.$nextTick().then(() => {
					this.dropzoneRefresh = true;
			});
		},
		handleResponse(res, text, type, topic = '') {
			console.log("res", res);
			this.$store.dispatch('setSnackbar', {text, type, topic});
			this.$store.dispatch('flipSnackbarSwitch');
		},
		async savePassword() {
			if (!this.$refs.password.validate()) return false
			this.savePasswordProcessing = true
			await api.post('/advisor_portal/user_update_password', {
				user_id: this.currentUser._id,
				password: this.bottomSection.oldPassword.value,
				new_password: this.bottomSection.newPassword.value,
				username: this.currentUser.username,
				firstname: this.topSection.firstName.value,
				firm: this.firm
			})
			.then(res => {
				this.$refs.password.reset();
				if (res.error) return this.errorText = res.error;
				this.handleResponse(res, `Your password has been successfully updated`, 'success');
			}).catch(err => this.handleResponse(err, `Failed to update your password`, 'fail'));
			this.savePasswordProcessing = false
		},
		async sendResetPasswordEmail() {
			this.sendingResetPasswordEmail = true
			await api.post('/advisor_portal/api/reset_password', {
				username: this.currentUser.username,
				firm: this.firm
			})
			.then(res => {
				if (res.error) return this.errorText = res.error;
				this.handleResponse(res, `A password reset email was sent`, 'success');
			}).catch(err => this.handleResponse(err, `Failed to send the password reset email`, 'fail'));
			this.sendingResetPasswordEmail = false
		},
		async resetMfa() {
			this.resettingAuth0Mfa = true
			await dhApi.post('/auth0/reset_mfa/', {
				user_account_id: this.currentUserDatahub.account.id
			})
			.then(res => {
				if (res.error) return this.errorText = res.error;
				this.handleResponse(res, `MFA has been reset`, 'success');
			}).catch(err => this.handleResponse(err, `Failed to reset MFA`, 'fail'));
			this.resettingAuth0Mfa = false
		},
		async updateUser () {
			if (!this.$refs.form.validate()) {
				return null;
			}
			const data = {
				primary_phone : this.topSection.phoneNumber.value || null,
				address_line_1 : this.topSection.officeAddressLine1.value || null,
				address_line_2 : this.topSection.officeAddressLine2.value || null,
				city : this.topSection.city.value || null,
				province : this.topSection.province.value || null,
				postal_code : this.topSection.postalCode.value || null,
				country : this.topSection.country.value || null
			}

			this.userUpdateLoading = true
			await this.$store.dispatch('updateCurrentUserDatahub', {userID: this.currentUser.platform_user_id, data})
			.then(res => {
				this.responses.push("UserInfo");
				console.log("UserInfo res", res);
			}).catch(err => console.log("UserInfo err", err));

			let username = null;
			if (this.currentUser.username !== this.topSection.email.value) {
				username = this.currentUser.username;
			}

			api.post('/advisor_portal/user_update_name/', {
				'user_id': this.currentUser._id,
				'platform_user_id': this.currentUser.platform_user_id,
				'user_account_id': this.currentUserDatahub.account.id,
				'username': this.topSection.email.value,
				'new_firstname': this.topSection.firstName.value,
				'new_lastname': this.topSection.lastName.value,
			}).then(res => {
				this.responses.push("UserName");
				console.log("UserName res",res);
			}).catch(err => console.log("UserName err", err));
			this.userUpdateLoading = false
		},
		deleteAvatar(){
			this.$store.dispatch('deleteUserAvatar', this.currentUser.platform_user_id)
			.then(res => this.updateAvatar());
		},
		updateUserFormValidity() {
			this.userFormValid = this.$refs.form && this.$refs.form.validate()
		},
		updatePasswordFormValidity() {
			this.passwordFormValid = this.$refs.form && this.$refs.password.validate()
		},
		clearErrorText() {
			if(this.errorText !== '' && this.bottomSection.oldPassword.value) this.errorText = '';
		}
	},
	mounted(){
		if (this.currentUser) {
			const user = _.cloneDeep(this.currentUser);
			this.topSection.firstName.value = user.first_name;
			this.topSection.lastName.value = user.last_name;
			this.topSection.email.value = user.username;
		}
		if (this.currentUserDatahub) {
			const userDH = _.cloneDeep(this.currentUserDatahub);
			this.topSection.officeAddressLine1.value = userDH.address_line_1;
			this.topSection.officeAddressLine2.value = userDH.address_line_2;
			this.topSection.city.value = userDH.city;
			this.topSection.province.value = userDH.province;
			this.topSection.postalCode.value = userDH.postal_code;
			this.topSection.country.value = userDH.country;
			this.topSection.phoneNumber.value = userDH.primary_phone;
		}
		this.updateUserFormValidity()
		this.updatePasswordFormValidity()
	},
	watch: {
		currentUser: {
			handler(){
				const user = _.cloneDeep(this.currentUser);
				this.topSection.firstName.value = user.first_name;
				this.topSection.lastName.value = user.last_name;
				this.topSection.email.value = user.username;
			},
			deep: true
		},
		currentUserDatahub: {
			handler(){
				const user = _.cloneDeep(this.currentUserDatahub);
				this.topSection.officeAddressLine1.value = user.address_line_1;
				this.topSection.officeAddressLine2.value = user.address_line_2;
				this.topSection.city.value = user.city;
				this.topSection.province.value = user.province;
				this.topSection.postalCode.value = user.postal_code;
				this.topSection.country.value = user.country;
				this.topSection.phoneNumber.value = user.primary_phone;
			},
			deep: true
		},
		responses: {
			handler(){
				if (this.responses.length === 2) {
					this.handleResponse("Success", `Your account settings have been successfully updated`, 'success');
					this.responses = [];
					this.$store.dispatch('fetchUserInfoOnLogin')
					.catch(err => console.log("err",err));
				}
			},
			deep: true
		},
		topSection: {
			deep: true,
			handler(){
				this.updateUserFormValidity()
			}
		},
		bottomSection: {
			deep: true,
			handler() {
				this.updatePasswordFormValidity()
				this.clearErrorText()
			}
		}
	}
}
</script>

<style lang="scss">
	.edit-user-avatar {
		position: absolute !important;
		left: 65px;
		top: 65px;
	}
	#userAvatarEdit {
		width: fit-content !important;
	}
</style>
