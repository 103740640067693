export const modal = {
  methods: {
    close() {
      if (this.$refs.form) this.$refs.form.reset();
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit('close');
    },
    closeModal() {
      if (this.$refs.form) this.$refs.form.reset();
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
    snackBar(type, text) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
};

export const cursorToEnd = {
  methods: {
    cursorToEnd(e) {
      const el = e.srcElement;
      try {
        el.selectionStart = el.selectionEnd = el.value.length;
      } catch {
        console.log('cursor to end catch');
      }
    },
  },
};

export const formatPhoneNumber = {
  methods: {
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return '';
      const number = phoneNumber.replace(/-/g, '');
      return `(${number.slice(0, 3)}) ${number.slice(3, 6)} - ${number.slice(6, 10)}`;
    },
  },
};

export const editClientRoute = {
  methods: {
    editClientRoute(client) {
      const isPersonClient = client.client_type === 'person';
      if (this.$store.getters.hasFeatureFlag('client_maintenance') && isPersonClient) {
        return ({ name: 'ClientMaintenancePage', params: { clientID: client.id } });
      }
      // if (this.$store.getters.hasFeatureFlag('corporation_details_page') && !isPersonClient) {
      //   return ({ name: 'LegalEntityDetails', params: { clientID: client.id } });
      // }
      // TODO: revisit client type, remove feature after each release
      if (this.$store.getters.hasFeatureFlag('corporation_details_page') && client.client_type === 'corporation') {
        return ({ name: 'LegalEntityDetails', params: { clientID: client.id } });
      }
      if (this.$store.getters.hasFeatureFlag('foundation_details_page') && client.client_type === 'foundation') {
        return ({ name: 'LegalEntityDetails', params: { clientID: client.id } });
      }
      if (this.$store.getters.hasFeatureFlag('trust_details_page') && client.client_type === 'trust') {
        return ({ name: 'LegalEntityDetails', params: { clientID: client.id } });
      }
      if (this.$store.getters.hasFeatureFlag('non_profit_details_page') && client.client_type === 'nonprofit') {
        return ({ name: 'LegalEntityDetails', params: { clientID: client.id } });
      }
      if (this.$store.getters.hasFeatureFlag('ipp_details_page') && client.client_type === 'ipp') {
        return ({ name: 'LegalEntityDetails', params: { clientID: client.id } });
      }
      return ({ name: 'ClientEditPage', params: { id: client.id } });
    },
  },
};
