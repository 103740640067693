<template>
  <v-dialog
    :value="isOpen"
    persistent
    fullscreen
  >
    <v-card class="pa-5" id="addnewplatformusercard">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <v-container :class="'add-platform-user modal-container'">
        <v-layout justify-center child-flex mb-3>
          <v-flex text-xs-center>
            <h1>Add a New Platform User</h1>
          </v-flex>
        </v-layout>
        <v-layout mb-5 justify-center>
          <v-flex xs12 row text-xs-center>
            <span class="p-text">Fill in the form below to generate a new platform user.</span>
          </v-flex>
        </v-layout>
        <v-form
          lazy-validation
          ref="addPlatformUserForm"
          @submit.prevent=""
        >
          <v-layout row mt-4 class="form-row">
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">Activate</div>
                <v-radio-group
                  v-model="sendEmailNow"
                  :rules="[v.isRequired()]"
                  row
                >
                  <v-radio :label="`Immediately`" :value="true"></v-radio>
                  <v-radio :label="`Later`" :value="false"></v-radio>
                </v-radio-group>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row mb-5 mt-4 class="form-row">
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">First Name<sup>*</sup></div>
                <PasInputField
                  v-model="firstName"
                  :placeholder="`First Name`"
                  :rules="[v.isRequired(), v.isValidName()]"
                  id="first-name-input"
                ></PasInputField>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">Last Name<sup>*</sup></div>
                <PasInputField
                  v-model="lastName"
                  :placeholder="`Last Name`"
                  :rules="[v.isRequired(), v.isValidName()]"
                  id="last-name-input"
                ></PasInputField>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">Email<sup>*</sup></div>
                <PasInputField
                  v-model="email"
                  :placeholder="`Email`"
                  :rules="[v.isRequired(), v.isValidEmail]"
                  id="email-input"
                ></PasInputField>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row mb-5 mt-4 class="form-row">
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">Assigned Roles</div>
                <PasInputFieldList
                  v-model="assignedRoles"
                  :items="allRoles"
                  :rules="[v.isRequired()]"
                  multiple
                  chips
                />
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout mt-1>
            <v-flex text-xs-left>
              <PasButton
                @click="close"
                class=" inverted-btn px-5"
                id="cancel-add-platform-user-btn"
              >Cancel</PasButton>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex text-xs-right>
              <PasButton
                @click="addPlatformUser"
                class="primary-btn px-5"
                :id="`add-platform-user-btn`"
                :processing="processing"
              >Add</PasButton>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>

import { modal } from 'Services/mixins';
import v from 'Services/validation';
import {
  updatePlatformUserRoles, registerPlatformUser, updatePlatformUserApproval, resetPassword,
} from 'Services/api/RolesAndPermissions';
import moment from 'moment';

export default {
  name: 'AddPlatformUser',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    allRoles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      v,
      firstName: '',
      lastName: '',
      email: '',
      assignedRoles: null,
      sendEmailNow: true,
      processing: false,
    };
  },
  methods: {
    addPlatformUser() {
      if (!this.$refs.addPlatformUserForm.validate()) return;
      this.processing = true;
      const newPlatformUser = {
        username: this.email,
        password: `${Math.random().toString(36).substring(3)}A!`, // generate temporary password
        firstname: this.firstName,
        lastname: this.lastName,
        firm: this.firm,
      };
      registerPlatformUser(newPlatformUser, this.$store.dispatch)
        .then(async (res) => {
          await updatePlatformUserRoles([{ user_account_id: res.user_id, roles: this.assignedRoles }], this.$store.dispatch);
          await updatePlatformUserApproval(res.user_id, { username: this.email, is_approved: true }, this.$store.dispatch);
          if (res.user_id && this.sendEmailNow) {
            updatePlatformUserApproval(res.user_id, { username: this.email, is_approved: false }, this.$store.dispatch)
              .then(() => {
                resetPassword({ username: this.email, firm: this.firm }, this.$store.dispatch)
                  .then(() => updatePlatformUserApproval(res.user_id, {
                    username: this.email,
                    is_approved: true,
                    email_link_sent_at: moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
                  }, this.$store.dispatch))
                  .then(() => {
                    this.saveAndClose();
                  });
              });
          } else if (res.user_id) {
            this.saveAndClose();
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    saveAndClose() {
      this.close();
      this.$emit('getPlatformUsers');
    },
  },
  computed: {
    firm() {
      return this.$store.getters.firm;
    },
  },
};
</script>
<style lang="scss">
.add-platform-user {
  .v-input {
    padding: 0 !important;
    margin: 0 !important;
  }
}

</style>
