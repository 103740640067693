<template>
  <v-dialog v-model="isOpen" persistent max-width="800px">
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <pas-page-title class="mb-4">Additional Document Upload</pas-page-title>
      <PasDropzone
        ref="pasDropzone"
        :id="'docDrapzone'"
        :options="dropzoneOptions"
      />
      <div class="error--text mt-2 ml-3" v-if="errorText">
        <span class="v-messages__message">{{errorText}}</span>
      </div>
      <v-layout v-for="file in files" mt-2 ml-2 align-center justify-end row fill-height>
        <v-flex shrink>
            <div class="strong-text">{{file.file.name}}</div>
        </v-flex>
        <v-flex v-if="file.doc_id" shrink pl-2>
            <pas-icon-circle
                @click="removeFile(file)"
                :style="{marginTop: '8px'}"
                :theme="`pas`"
            >
                <v-icon>mdi-close</v-icon>
            </pas-icon-circle>
        </v-flex>

      </v-layout>
      <v-layout mt-4 align-center justify-end row >
        <v-flex shrink>
          <pas-button @click="close">Cancel</pas-button>
        </v-flex>
        <v-flex shrink>
          <PasButton
            class="wide-btn ml-2"
            @click="complete"
          >Done</PasButton>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

import { modal } from 'Services/mixins';
import { DOCUMENT_SOURCE, CLIENT_DOCUMENT_MAX_FILESIZE } from 'Services/constants';

const ADDITIONAL_DOCUMENT_TYPE = 53;
export default {
  name: 'AdditionalDocumentUpload',
  mixins: [modal],
  props: {
    isOpen: Boolean,
    workflowId: Number,
  },
  mounted() {
    this.filterWofklowDocuments();
  },
  data() {
    const that = this;
    return {
      dropzoneOptions: {
        url: '/advisor_portal/upload_document',
        init() {
          this.on('sending', (file, _xhr, formData) => {
            const documentName = that.activeClient.client.display_name || that.activeClient.client.id;
            formData.append('client_id', that.activeClient.client.id);
            formData.append('document_type', ADDITIONAL_DOCUMENT_TYPE); // Additional workflow document
            formData.append('workflow', that.workflowId);
            formData.append('document_source', that.documentSource.Manual);
            formData.append('document_name', `${file.name}`);
            that.files.push({ file, newUpload: true });
          });
          this.on('success', (file, res) => {
            if (res.message === 'Success') {
              const fileIndex = that.files.findIndex((f) => f.file.name === file.name);
              const modifiedFile = that.files[fileIndex];
              modifiedFile.doc_id = res.doc_id;
              that.files.splice(fileIndex, 1, modifiedFile);
              that.snackBar('success');
              that.$store.dispatch('setDocuments', that.activeClient.client.id);
            } else {
              that.snackBar('fail');
              this.removeFile(file);
            }
          });
          this.on('error', (file, res) => {
            that.snackBar('fail');
            this.removeFile(file);
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: CLIENT_DOCUMENT_MAX_FILESIZE,
        maxFiles: 5,
        chunking: false,
        chunkSize: 3000000, // Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        acceptedFiles: '.pdf',
        createImageThumbnails: false,
        disablePreviews: true,
      },
      errorText: '',
      files: [],
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  methods: {
    complete() {
      this.errorText = '';
      this.$emit('done');
    },
    removeFile(file) {
      // Remove the file from the files array
      this.files = this.files.filter((f) => f.file.name !== file.file.name);

      // Remove the file from the dropzone
      if (file.newUpload) {
        this.$refs.pasDropzone.$refs.dropzone.removeFile(file.file);
      }

      // Remove the document from the database
      if (file.doc_id) {
        this.$store.dispatch('deleteDocument', {
          docID: file.doc_id,
          clientID: this.activeClient.client.id,
        });
      }
    },
    filterWofklowDocuments() {
      const thisClientDocs = this.documents;
      // Must convert workflow to string and compare using == operator.
      // Everything in the Documents table is a string...
      const thisWorkflowDocs = thisClientDocs.filter((doc) => doc.associated_workflows.includes(`${this.workflowId}`)
                                                              && doc.document_type == ADDITIONAL_DOCUMENT_TYPE);

      // Loop through our array and remove any documents that are not in the workflow
      const filesToRemove = [];
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        const docExists = thisWorkflowDocs.find((f) => f.id === this.files[i].doc_id);
        if (!docExists) {
          if (file.newUpload) {
            this.$refs.pasDropzone.$refs.dropzone.removeFile(file.file);
          }
          filesToRemove.push(file);
        }
      }
      for (const file of filesToRemove) {
        this.files = this.files.filter((f) => f.file.name !== file.file.name);
      }

      // Loop through the documents and add them to the array if they don't already exist
      for (const doc of thisWorkflowDocs) {
        // See if document already exist in the array
        const docExists = this.files.find((f) => f.doc_id === doc.id);
        if (!docExists) {
          this.files.push({
            doc_id: doc.id,
            file: {
              name: doc.document_name,
            },
            newUpload: false
          });
        }
      }
    },
    snackBar(type) {
      let text = 'Oops... Something went wrong, please try upoading the file again';
      if (type === 'success') text = 'The doument was successfully uploaded';
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
  watch: {
    documents() {
      this.filterWofklowDocuments();
    },
  },
};
</script>

<style>
</style>
