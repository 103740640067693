<template>
  <PContainer>
    <PRow align-h="center" v-if="!isModalOpen">
      <PSpinner />
    </PRow>
    <OnboardJointClientModal
      v-else
      :isOpen="isModalOpen"
      :jointClient="jointClient"
      :activeClient="activeClient"
      :needRiskSurvey="needRiskSurvey"
      :riskSurveyLink="riskSurveyLink"
      @close="onModalClose"
      @submit="onModalSubmit"
    />
  </PContainer>
</template>

<script setup>
import api from 'Services/api';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'Store/store'
import { useRouter } from 'vue-router/composables';
import { getJointClient } from 'Services/helpers/onboarding';
import OnboardJointClientModal from './OnboardJointClientModal';

const store = useStore();
const router = useRouter();
const isModalOpen = ref(false);

const activeClient = computed(() => store.getters.activeClient.client);
const jointClient = ref({});
const needRiskSurvey = computed(() => store.getters.hasFeatureFlag('risk_survey'));
const riskSurveyLink = ref('');

onMounted(async () => {
  riskSurveyLink.value = (await api.get('/datahub/firm_details/')).results[0].risk_survey_link;
});

const onModalClose = () => {
  isModalOpen.value = false;
};

const onModalSubmit = ({ clientId }) => {
  router.push({ name: 'ClientHomePage', params: { clientID: clientId } });
};

watch([activeClient], async () => {
  if (activeClient.value) {
    jointClient.value = await getJointClient(activeClient.value.id);
    if (needRiskSurvey.value || jointClient.value) {
      isModalOpen.value = true;
    } else {
      router.replace({ name: 'ClientHomePage' });
    }
  }
}, {
  immediate: true,
});

</script>
