<template>
  <div>
    <PCard
      @click="$emit('click')"
      clickable
      class="mb-1"
    >
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-row">
          <div class="mr-2">
            <font-awesome-icon :icon="['fal', 'sack-dollar']" />
          </div>
          <div>
            <div class="subtitle bold mb-1">{{ this.amountAndType }}</div>
            <div class="pre-title text-muted mb-2">{{ `${status_descriptions[transaction.transaction_status]} ${this.date}` }}</div>
          <p class="bold mb-1">{{ this.name }}</p>
            <p>{{ `${accountType} ${reason} ${frequency}` }}</p>
          </div>
        </div>
        <div class="align-self-center mr-2">
        </div>
      </div>
    </PCard>
  </div>
</template>

<script>
import { FREQUENCIES, ACCOUNTTYPE } from 'Services/constants';

import moment from 'moment';

export default {
  name: 'TransactionCard',
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: true,
      selectedClient: null,
      search: '',
      FREQUENCIES,
      ACCOUNTTYPE,
      statuses: {
        1: 'Expected',
        2: 'Completed',
        5: 'Cancelled',
        6: 'Now expected',
        7: 'Sent to client',
      },
      status_descriptions: {
        'CLIENT-SIGNATURE': 'Sent to Client',
        'UNDER REVIEW': 'Now Expected',
        PENDING: 'Expected',
        PROCESSED: 'Completed',
        CANCELLED: 'Cancelled',
      },
    };
  },
  computed: {
    amountAndType() {
      return `$${new Intl.NumberFormat().format(this.transaction.net_amount)} ${this.transaction.event_type}`;
    },
    date() {
      return this.transaction.transaction_status === 'UNDER REVIEW' || this.transaction.transaction_status === 'PENDING'
        ? `${moment(this.transaction.transaction_card_date).format('MMM DD')} - ${moment(this.transaction.transaction_card_date).add(1, 'days').format('MMM DD')}`
        : `${moment(this.transaction.transaction_card_date).format('MMM DD')}`;
    },
    name() {
      return this.transaction.client_name;
    },
    accountType() {
      return this.transaction.account_type ? `${ACCOUNTTYPE[this.transaction.account_type]}` : '';
    },
    reason() {
      return this.transaction.reason ? `• ${this.transaction.reason}` : '';
    },
    frequency() {
      return this.FREQUENCIES[this.transaction.frequency] ? `• ${this.FREQUENCIES[this.transaction.frequency]}` : '';
    },
  },
};
</script>
