<template>
  <Consent>
    <ConsentForm
      @after-submit="afterSubmit"
    />
  </Consent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('consent');

export default {
  name: 'ConsentView',
  computed: {
    ...mapGetters([
      'userConsentRequired',
    ]),
  },
  methods: {
    ...mapActions([
      'initUserConsent',
      'setClientId',
    ]),
    afterSubmit() {
      this.$emit('consentCompleted');
    },
  },
  async mounted() {
    const clientId = this.$router.currentRoute?.params?.clientID;
    if (clientId) {
      await this.setClientId({ id: clientId });
    }

    await this.initUserConsent();
    if (!this.userConsentRequired) {
      this.$emit('consentCompleted');
    }
  },
};
</script>
