<template>
  <v-layout>
    <pas-input-field
      :label="label"
      :disabled="disabled"
      type="number"
      @input="$emit('input', $event)"
      :value="value"
      :step="step"
      :min="min"
      class="number-field"
      :suffix="suffix"
    >
    </pas-input-field>
    <div v-if="arrows === true" class="input-arrows">
      <div class="input-up-arrow input-arrow"
        @click="!disabled && $emit('upClick')"
      >
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </div>
      <div class="input-down-arrow input-arrow"
        @click="!disabled && $emit('downClick')"
      >
        <v-icon>
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {

  name: 'pas-number-input',
  data() {
  },
  props: {
    arrows: {
      type: Boolean,
      default: true,
    },
    min: {
      type: String,
      default: '',
    },
    step: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '1',
    },
    suffix: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">

  // Position custom arrows
  .input-arrows {
    left: -40px;
  }
  .input-arrow {
    cursor: pointer;
    position: absolute !important;
  }
  .input-down-arrow {
    bottom: -3px;
  }

  .number-field {
    // Hide default arrows
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
    {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number]
    {
      -moz-appearance: textfield;
      max-width: 43px !important;
    }
    .v-text-field__slot {
      display: flex;
      justify-content: center !important;
    }
  }

</style>
