<template>
  <PContainer fluid>
    <PToast
      key="funding_landing_toast"
      :visible="notification.show"
      auto-hide-delay="8000"
      @hidden="notification.show = false"
      toaster="b-toaster-bottom-left"
    >
      {{notification.message}}
    </PToast>
    <PRow no-gutters>
      <PColumn cols="2">
        <PSideNav
          :sections="fundingMenu"
          @item-click="menuClick($event)"
          class="funding-side-bar"
        />
      </PColumn>
      <PColumn cols="10">
        <FundingLandingPage
          :selectedAccountTypeFilter="selectedAccountTypeFilter"
          :selectedTimeRangeFilter="selectedTimeRangeFilter"
          :selectedTransactionTypeFilter="selectedTransactionTypeFilter"
          :selectedTransaction="selectedTransaction"
          :isTransactionModalVisible="isTransactionModalVisible"
          :isTransactionLoading="isTransactionLoading"
          :isCancelTransactionModalVisible="isCancelTransactionModalVisible"
          :warningMessage="warningMessage"
          :upcomingTitle="upcomingTitle"
          :upcomingFields="upcomingFields"
          :upcomingTransactions="upcomingTransactions"
          :pastTitle="pastTitle"
          :pastFields="pastFields"
          :pastTransactions="pastTransactions"
          :accountTypes="accountTypes"
          :timeRanges="timeRanges"
          :transactionStatusTypes="transactionStatusTypes"
          :upcomingRecurringTransactions="upcomingRecurringTransactions"
          :isUpcomingTableLoading="isUpcomingTableLoading"
          :isPastTableLoading="isPastTableLoading"
          @transactionSelected="transactionSelected"
          @hideTransactionModal="hideTransactionModal"
          @accountTypeFilterSelected="accountTypeFilterSelected"
          @timeRangeFilterSelected="timeRangeFilterSelected"
          @transactionTypeSelected="transactionTypeSelected"
          @confirmCancel="confirmCancel"
          @hideConfirmCancelTransaction="hideConfirmCancelTransaction"
          @initiateCancel="initiateCancel"
          @editTransaction="editTransaction"
        />
      </PColumn>
    </PRow>
  </PContainer>
</template>

<script>
import api from 'Services/api';
import { ACCOUNTTYPE } from 'Services/constants';
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
cancelFundingWorkflow,
fetchFundingRequests,
fetchFundingTransactions,
fetchWorkflowBySystematicId,
updateRequest,
} from '../../../services/api/FundingVersion2';
import { fetchAdvisor } from '../../../services/api/PlatformUser';
import { fetchWorkflowDetails } from '../../../services/api/Workflows';
import { getMetadata } from '../../../services/api/metadata';
import { DEFAULTSUBTYPES, REQUEST_TYPES, FUNDING_TYPE } from '../../../services/helpers/funding/index';
import { getNthBusinessDay } from '../../../services/helpers/time';

export default {
  name: 'FundingV2',
  data() {
    return {
      clientInvestmentAccts: [],
      clientBankAccts: [],
      selectedAccountTypeFilter: {
        id: '',
        text: 'Account',
      },
      selectedTimeRangeFilter: {
        id: '30',
        text: 'Last 30 days',
      },
      selectedTransactionTypeFilter: {
        id: '',
        text: 'Frequency',
      },
      selectedTransaction: {},
      isTransactionModalVisible: false,
      isTransactionLoading: false,
      isCancelTransactionModalVisible: false,
      // TODO: Use translation keys
      upcomingTitle: 'Upcoming',
      upcomingFields: [
        { key: 'status', label: '', tdClass: 'align-middle pl-5' },
        { key: 'type', label: '', tdClass: 'align-middle' },
        { key: 'from_to', label: 'From → To', tdClass: 'align-middle' },
        { key: 'date', label: 'Date', tdClass: 'align-middle' },
        { key: 'amount', label: 'Amount', tdClass: 'align-middle' },
        { key: 'select_arrow', label: '', tdClass: 'align-middle' },
      ],
      upcomingRecurringTransactions: [],
      upcomingTransactions: [],
      pastTransactions: [],
      // TODO: Use translation keys
      pastTitle: 'Past',
      pastFields: [
        {
          key: 'type', label: 'Type', tdClass: 'align-middle pl-5', thClass: 'pl-5',
        },
        { key: 'from_to', label: 'From → To', tdClass: 'align-middle' },
        { key: 'date', label: 'Date', tdClass: 'align-middle' },
        { key: 'amount', label: 'Amount', tdClass: 'align-middle' },
        { key: 'select_arrow', label: '', tdClass: 'align-middle' },
      ],
      timeRanges: [
        {
          id: '30',
          text: 'Last 30 days',
        },
        {
          id: '60',
          text: 'Last 60 days',
        },
        {
          id: '90',
          text: 'Last 90 days',
        },
      ],
      isPastTableLoading: false,
      isUpcomingTableLoading: false,
      notification: {
        show: false,
        message: '',
      },
      // associated workflows of selected request/ trsanction
      associatedWfs: [],
    };
  },
  computed: {
    ...mapGetters([
      'fundingFrequencies',
      'fundingTypes',
      'investmentAccts',
      'bankAccts',
      'withdrawalTypes',
      'paymentMethods',
      'currencyTypes',
      'recipientTypes',
      'withholdingTaxTypes',
      'activeRequest',
      'editMode',
      'activeClient',
      'hasFeatureFlag',
      'complexFundingFeatureFlag',
      'warningMessage',
    ]),
    clientId() {
      return this.$route.params.clientID;
    },
    client() {
      return this.activeClient.client;
    },
    fundingMenu() {
      const options = [
        {
          id: 0,
          title: 'Back',
          icon: 'chevron-left',
          route: `/clients/${this.$route.params.clientID}`,
          enabled: true,
        },
        {
          id: 1,
          title: 'Make a deposit',
          icon: 'dollar-sign',
          route: this.establishRouteFromFeatureFlag(`/clients/${this.$route.params.clientID}/funding-v2/deposit/`),
          enabled: true,
          requestType: { id: REQUEST_TYPES.DEPOSIT, type: FUNDING_TYPE.PAC, label: 'Deposit type' },
          defaultRequestSubtype: DEFAULTSUBTYPES.PAC,
        },
        {
          id: 2,
          title: 'Make a withdrawal',
          icon: 'upload',
          route: this.establishRouteFromFeatureFlag(`/clients/${this.$route.params.clientID}/funding-v2/withdrawal/`),
          enabled: true,
          requestType: { id: REQUEST_TYPES.WITHDRAWAL, type: FUNDING_TYPE.SWP, label: 'Withdrawal type' },
          defaultRequestSubtype: DEFAULTSUBTYPES.SWP,
        },
        {
          id: 3,
          title: 'Start internal transfer',
          icon: 'arrows-retweet',
          route: this.establishRouteFromFeatureFlag(`/clients/${this.$route.params.clientID}/funding-v2/transfer/`),
          enabled: !!this.complexFundingFeatureFlag,
          requestType: { id: REQUEST_TYPES.INTERNAL_TRANSFER, type: FUNDING_TYPE.TRANSFER, label: 'Internal transfer type' },
        },
        {
          id: 4,
          title: 'Start external transfer',
          icon: 'arrows-repeat',
          route: this.establishRouteFromFeatureFlag(`/clients/${this.$route.params.clientID}/funding-v2/transfer/`),
          enabled: !!this.complexFundingFeatureFlag,
          requestType: { id: REQUEST_TYPES.EXTERNAL_TRANSFER, type: FUNDING_TYPE.TRANSFER, label: 'External transfer type' },
        },
      ];
      return options.filter(((o) => !!o.enabled));
    },
    transactionStatusTypes() {
      if (this.fundingFrequencies.length) {
        return this.fundingFrequencies
          .filter((e) => e.id !== '1')
          .map((e) => ({
            id: e.frequency,
            text: e.frequency,
          }));
      }
      return [];
    },
    accountTypes() {
      return ACCOUNTTYPE
        .filter((e) => e !== 'Unknown')
        .map((e) => ({
          id: e,
          text: e,
        }));
    },
    internalTransfersFeatureFlag() {
      return this.hasFeatureFlag('internal_transfers');
    },
    investorDisplayName() {
      return this.activeClient.client.display_name;
    },
    env() {
      return this.$store.state.app.env;
    },
  },
  methods: {
    establishRouteFromFeatureFlag(routePath) {
      return this.complexFundingFeatureFlag ? `/clients/${this.$route.params.clientID}/funding-v2/request-types/` : routePath;
    },
    async menuClick(event) {
      if (event.id !== 0) {
        this.$store.dispatch('setAccts', { clientId: this.clientId });
        if (this.complexFundingFeatureFlag) {
          await this.$store.dispatch('setFundingSubtypes', {clientId: this.clientId, typeId: event.requestType.id});
        } else await this.$store.dispatch('setSelectedRequestSubtype', event.defaultRequestSubtype); // Since the subtype selection screen is not loaded if feature flag is off it is set to the default subtype request value
      }
      this.$store.dispatch('setFundingDocuments', []);
      this.$store.dispatch('setValidatedRequest', {});
      this.$store.dispatch('setActiveRequest', {});
      this.$store.dispatch('setEditMode', false);
      this.$store.dispatch('setSelectedRequestType', event.requestType);
      this.$router.push({ path: event.route });
    },
    async editTransaction() {
      const activeRequest = this.selectedTransaction;
      this.$store.dispatch('setSelectedRequestSubtype', DEFAULTSUBTYPES[activeRequest.type]);
      try {
        if (!(this.investmentAccts.length && this.bankAccts.length)) {
          this.$store.dispatch('setAccts', { clientId: this.clientId });
        }
        this.$store.dispatch('setFundingDocuments', []);
        this.$store.dispatch('setValidatedRequest', {});
        this.$store.dispatch('setActiveRequest', activeRequest);
        this.$store.dispatch('setEditMode', true);
        this.$router.push({ path: `/clients/${this.$route.params.clientID}/funding-v2/${FUNDING_TYPE[this.activeRequest.type]}/` });
      } catch (err) {
        // TODO: Front end display err
      }
    },
    transactionSelected(transaction) {
      this.isTransactionLoading = true;
      this.isTransactionModalVisible = true;
      // modify cancel and edit flag on active recurring event
      if (transaction.id === transaction.funding_id) { // recurring event
        this.selectedTransaction = this.addWarningToActiveRecurringEvent(transaction);
      } else { // transaction
        this.selectedTransaction = transaction;
      }
      this.isTransactionLoading = false;
    },
    addWarningToActiveRecurringEvent(transaction) {
      // Allowing edit and cancel on Active recurring event within 5 business days, but add warning msg
      if (transaction.status === 'Active' && !['Unknown', 'One Time'].includes(transaction.frequency) && transaction.next_date) {
        if (getNthBusinessDay(moment(), 5) > moment(transaction.next_date) && moment() <= moment(transaction.next_date)) {
          // next transaction will be happen in 5 business days
          transaction.is_editable = true;
          transaction.can_be_cancelled = true;
          this.$store.dispatch('setWarningMessage', {
            show: true,
            message: 'If the next event occurs within 5 business days, the changes will take place the following event.',
          });
          return transaction;
        }
      }
      return transaction;
    },
    accountTypeFilterSelected(accountType) {
      this.selectedAccountTypeFilter = accountType;
    },
    timeRangeFilterSelected(timeRange) {
      this.selectedTimeRangeFilter = timeRange;
    },
    transactionTypeSelected(transactionType) {
      this.selectedTransactionTypeFilter = transactionType;
    },
    hideTransactionModal() {
      this.isTransactionModalVisible = false;
    },
    async hideConfirmCancelTransaction() {
      await this.$store.dispatch('setWarningMessage', {});
      this.isCancelTransactionModalVisible = false;
    },
    async confirmCancel() {
      try {
        this.isCancelTransactionModalVisible = false;
        this.isTransactionModalVisible = false;
        const body = this.selectedTransaction;
        body.request_id = this.selectedTransaction.funding_id;
        body.status = 'Inactive';
        await updateRequest({ body });
        await this.cancelWorkflow();
        this.notification.message = 'The cancellation request has been submitted successfully.';
        this.notification.show = true;
      } catch (err) {
        this.notification.message = 'An error has occurred. Please try again.';
        this.notification.show = true;
      }
    },
    async cancelWorkflow() {
      // Cancel related workflows
      try {
        this.associatedWfs = [];
        const resp = await fetchWorkflowBySystematicId(this.selectedTransaction.funding_id);
        this.associatedWfs = await Promise.all(resp.map((e) => fetchWorkflowDetails(e.workflow)));
        // Should only have one active workflow associated
        const inProgressWorkflows = this.associatedWfs.find((wf) => ['in_progress', 'needs_attention', 'process_error'].includes(wf.status));
        const cancelledWfId = inProgressWorkflows?.id;
        if (cancelledWfId) {
          const resp = await cancelFundingWorkflow(cancelledWfId);
        }
        this.sendFundingCancelNotification();
      } catch (err) {
        throw new Error('Fail to cancel funding request', err);
      }
    },
    async sendFundingCancelNotification() {
      const client = this.activeClient.client;
      const advisorId = client.advisor_id;
      const advisor = await fetchAdvisor(advisorId);
      const email = advisor.email;
      const link = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
      const clientUrl = `${link}/advisor_portal/clients/${client.id}`;
      const workflowUrl = `${link}/advisor_portal/workflowV2/${this.cancelledWfId}`;
      // Semi-Monthly $100.0 deposit from account Bank1 to account Funding-Cash-P-CAD"
      const requestDetail = `${this.selectedTransaction.frequency} $${this.selectedTransaction.amount} ${this.selectedTransaction.type_verbose} from account ${this.selectedTransaction.from_account.account_name} to account ${this.selectedTransaction.to_account.account_name}`;
      const messageData = {
        subject: `${client.display_name}'s Funding Workflow has been cancelled`,
        email,
      };
      const investmentAcc = this.selectedTransaction.from_account.type === 'investment_account'? this.selectedTransaction.from_account : this.selectedTransaction.to_account
      messageData.message = `Hello, \n\nPlease be advised that ${requestDetail} for ${client.display_name} and associated workflow have been cancelled, if you would like to review the cancelled Money Movement or reinitiate, please log into Advisor Center and proceed accordingly. \nPlease note it takes up to 5 business days to cancel a recurring event. \n\nClient's Profile: ${clientUrl} ${this.cancelledWfId ? `\n\nClient's Workflow: ${workflowUrl}` : ''} \n\nThank you, \nPurpose Advisor Solutions`;
      // Send notification to client's advisor
      api.post('/advisor_portal/send_email', messageData);
      // Send notification to Ops
      const msgToOps = `Please cancel the following ${this.selectedTransaction.type} effective immediately:\n\nAccount Number (FCC#): ${investmentAcc.account_no}\nFrequency: ${this.selectedTransaction.frequency}\nAmount: $${this.selectedTransaction.amount}\nNext Date: ${this.selectedTransaction.next_date || 'Not available yet'}`
      messageData.message = msgToOps;
      messageData.email = await getMetadata('pas_funding_email');
      api.post('/advisor_portal/send_email', messageData);
      // Send notification to Cusdotian - remove for now
      // const completedWf = this.associatedWfs.find((wf) => wf.status === 'completed');
      // if (this.env === 'production' && completedWf) {
      //   const custodianEmail = await getMetadata('custodian_email');
      //   if (custodianEmail) {
      //     messageData.email = await getMetadata('custodian_email');
      //     api.post('/advisor_portal/send_email', messageData);
      //   }
      // }
    },
    async fetchPastTransactions(investorDisplayName) {
      const clientId = this.$route.params.clientID;
      const timeRange = this.selectedTimeRangeFilter.id;
      const transactionType = this.selectedTransactionTypeFilter.id;
      const accountType = this.selectedAccountTypeFilter.id;

      this.isPastTableLoading = true;

      this.pastTransactions = await fetchFundingTransactions({
        clientId,
        transactionStatus: 'Processed',
        accountType,
        timeRange,
        transactionType,
      });

      this.pastTransactions = this.pastTransactions.map((item) => ({
        ...item,
        transaction_status: item.status,
        investor_name: investorDisplayName,
      }));

      this.isPastTableLoading = false;
    },
    initiateCancel() {
      this.isCancelTransactionModalVisible = true;
      this.isTransactionModalVisible = false;
    },
  },
  async mounted() {
    this.isPastTableLoading = true;
    this.isUpcomingTableLoading = true;
    const clientId = this.$route.params.clientID;
    const client = await this.$store.dispatch('fetchClientByID', clientId);
    const investorDisplayName = client.display_name;

    if (!this.fundingFrequencies.length) {
      await this.$store.dispatch('setFundingFrequencies');
    } if (!this.fundingTypes.length) {
      await this.$store.dispatch('setFundingTypes');
    }
    if (!this.withdrawalTypes.length) {
      await this.$store.dispatch('setWithdrawalTypes');
    }
    if (!this.currencyTypes.length) {
      await this.$store.dispatch('setCurrencyTypes');
    }
    if (!this.recipientTypes.length) {
      await this.$store.dispatch('setRecipientTypes');
    }
    if (!this.withholdingTaxTypes.length) {
      await this.$store.dispatch('setWithholdingTaxTypes');
    }
    if (!(this.investmentAccts.length && this.bankAccts.length)) {
      this.$store.dispatch('setAccts', { clientId: this.clientId });
    }

    this.upcomingTransactions = await fetchFundingTransactions({ clientId, transactionStatus: 'Pending' });
    this.upcomingRecurringTransactions = await fetchFundingRequests({ clientId, frequency: 'recurring', status: 'Active,Processing' });
    this.$store.dispatch('setRecurringEvents', this.upcomingRecurringTransactions);

    this.upcomingRecurringTransactions = this.upcomingRecurringTransactions.map((item) => _.assign(item, {
      transaction_status: item.status,
      investor_name: investorDisplayName,
    }));

    this.upcomingTransactions = this.upcomingTransactions.map((item) => _.assign(item, {
      investor_name: investorDisplayName,
    }));

    await this.fetchPastTransactions(investorDisplayName);
    this.isPastTableLoading = false;
    this.isUpcomingTableLoading = false;
  },
  watch: {
    selectedAccountTypeFilter() {
      this.fetchPastTransactions(this.investorDisplayName);
    },
    selectedTimeRangeFilter() {
      this.fetchPastTransactions(this.investorDisplayName);
    },
    selectedTransactionTypeFilter() {
      this.fetchPastTransactions(this.investorDisplayName);
    },
  },
};
</script>

<style scoped>
  .funding-v2{
    /* remove gap between head nav and funding index*/
    margin-top: -32px;
  }
  .funding-side-bar {
    width: 100%;
  }
</style>
