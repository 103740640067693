<template>
  <div>
    <v-form
      ref="edit-details"
      lazy-validation
      @submit.prevent
    >
      <Client v-if="activeClient.client.client_type === 'person'" :editable="editable" />
      <Trust v-else-if="activeClient.client.client_type === 'trust'" :editable="editable" />
      <Foundation v-else-if="activeClient.client.client_type === 'foundation'" :editable="editable" />
      <Corporation v-else-if="activeClient.client.client_type === 'corporation'" :editable="editable" />
      <IPP v-else-if="activeClient.client.client_type === 'ipp'" :editable="editable" />
      <NonProfit v-else-if="activeClient.client.client_type === 'nonprofit'" :editable="editable" />
    </v-form>
  </div>
</template>

<script>

import _ from 'lodash';
import Client from './Types/Client';
import Trust from './Types/Trust';
import IPP from './Types/IPP';
import Foundation from './Types/Foundation';
import NonProfit from './Types/NonProfit';
import Corporation from './Types/Corporation';

export default {
  name: 'edit-details-modal',
  components: {
    Client,
    Trust,
    Foundation,
    Corporation,
    IPP,
    NonProfit,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notes: '',
      clientData: '',
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientType() {
      return this.activeClient.client ? this.activeClient.client.client_type : '';
    },
  },
  mounted() {
    const client = _.cloneDeep(this.activeClient);
    if (client.client) {
      this.notes = client.client.quick_notes || '';
    }
  },
};

</script>
