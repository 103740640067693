<template>
  <v-app id="app">
    <main v-if="!loading">
      <HeaderNav v-if="onAuthRoute && !isHeaderNavV2On" />
      <HeaderNavV2 v-if="onAuthRoute && isHeaderNavV2On" />

      <v-dialog :value="idleDialog && usingVuetify" persistent width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Sorry, you've been logged out!</v-card-title>
          <v-card-text>To make sure client information is safe, you get logged out after being inactive for 30 minutes.</v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn :color="primaryColor" flat href="/advisor_portal/login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <PModal :visible="idleDialog && usingPurposeful" centered no-close-on-backdrop :hide-backdrop="false">
        <h3>Sorry, you've been logged out!</h3>
        <p class="mb-2">To make sure client information is safe, you get logged out after being inactive for 30 minutes.</p>
        <PButton href="/advisor_portal/login">Login</PButton>
      </PModal>

      <RouterView />

      <ModalManager />

      <Snackbar />
    </main>
  </v-app>
</template>

<script>
// Libraries
import jwtDecode from 'jwt-decode';

// Components

// Services
import { getTheme } from 'Services/ThemeHelpers/ThemeHelpers';
import api from 'Services/api';
import { mapGetters } from 'vuex';
import Snackbar from './Snackbar';
import ModalManager from './ModalManager';

export default {
  name: 'app',
  components: {
    ModalManager,
    Snackbar,
  },
  async created() {
    this.loadTheme();

    const jwt = localStorage.getItem('jwt');

    this.$store.dispatch('getEnv');

    await api
      .get('/sockets_enabled/')
      .then((res) => {
        if (res.websockets_enabled) {
          const socket = io(`wss://${window.location.host}`, {
            transports: ['websocket'],
          });
          this.prototype.$socket = socket;
        }
      })
      .catch((err) => {
        console.log('error getting sockets status', err);
      });

    // checking jwt
    const exitToLogin = () => {
      this.$store.dispatch('logUserOut');
    };

    if (this.onAuthRoute && !this.isClientConfirmationTool) {
      if (!jwt) {
        exitToLogin();
        this.loading = false;
        return;
      }

      const decoded = jwtDecode(jwt);
      if (
        !decoded
        || decoded.exp < new Date().getTime() / 1000
        || !decoded.firm
      ) {
        exitToLogin();
        return;
      }
    }
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      hasBeenIdleTooLong: false,
      hadATokenBeforeIdle: false,
    };
  },
  computed: {
    ...mapGetters(['hasFeatureFlag']),
    isClientConfirmationTool() {
      return this.$route.path.includes('/client-confirmation');
    },
    onAuthRoute() {
      return this.$route.meta.requiresAuth !== false;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    idleDialog() {
      if (this.loading) return false;
      if (!this.isAppIdle && !this.hasBeenIdleTooLong) return false;

      const jwt = localStorage.getItem('jwt');
      if (jwt) {
        this.$store.dispatch('logUserOut', {
              reason: 'idle'
            });
        this.hadATokenBeforeIdle = true;
      };
      this.hasBeenIdleTooLong = true;

      // Do not display the old 'idle' pop-up in SSO mode as the IdP
      // will redirect us to a different logout page
      const firm = this.$store.getters.firm
      if (this.$auth.isFirmSSOActivated(firm)) return false

      // Avoid showing the idle logout pop-up dialog if the user was
      // not signed-in in the first place
      if (!this.hadATokenBeforeIdle) return false;

      return true;
    },
    usingPurposeful() {
      return this.$route.meta.stylesheet === 'beta';
    },
    usingVuetify() {
      return this.$route.meta.stylesheet === 'old';
    },
    isHeaderNavV2On() {
      return this.hasFeatureFlag('top_nav_bar_v2');
    },
  },
  methods: {
    loadTheme() {
      this.$store.dispatch('setTheme', getTheme());
    },
  },
};
</script>

<style lang="scss">
@import "~Styles/fonts.scss";
//global styling
#app {
  height: 100%;
}

.application--wrap {
  background-color: #FAFAFA
}
</style>
