<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span
      v-if="canAccessMarkAsCompleteBtn && isManualWorkflow"
      @click.prevent="completeStep"
      class="action-text"
    >
      Mark as Complete
    </span>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendMessage } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'FccProcessRequestStep',
  components: { WorkflowStep },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      default: () => {},
    },
    isManualWorkflow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['hasFullWorkflowEditPermission']),
    canAccessMarkAsCompleteBtn() {
      const submoduleName = `${this.workflow.type}_completion_approval`;
      const accessPermission = this.$store.state.permissions.submodules[submoduleName];
      return !!accessPermission?.write && this.hasFullWorkflowEditPermission;
    },
  },
  methods: {
    async completeStep() {
      if (this.loading) return;
      this.loading = true;
      const res = await sendMessage(
        this.workflow,
        { messageType: `workflow.${this.workflow.type}.fcc_process_request.success` },
      );
      try {
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow...',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>

</style>
