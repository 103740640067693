<template>
	<v-dialog :value="isOpen" persistent fullscreen>
        <v-card :style="{ overflow: 'hidden !important' }">
            <PasFab class="close-btn">
                <v-icon @click="closeModal" class="close-icon">close</v-icon>
            </PasFab>
            <v-container class="modal-container">
                <v-layout mb-3>
                    <v-flex xs12 text-xs-center>
                        <pas-page-subtitle>Generate Transfer Documents</pas-page-subtitle>
                    </v-flex>
                </v-layout>
                <v-layout mb-5>
                    <v-flex xs12 text-xs-center>
                        <span>Please select transfers below</span>
                    </v-flex>
                </v-layout>
                <v-form @submit.prevent ref="form">
                    <pas-list
                        :columns="columns"
                        :itemName="'transfers'"
                        :items="filteredTransfers"
                        :loading="false"
                    >
                        <template slot-scope="{ item }">
                            <v-layout mx-3 fill-height row>
                                <v-flex xs1>
                                  <v-tooltip
                                      top
                                      :disabled="item.pas_account_info.account_status === 'O'"
                                  >
                                    <PasCheckbox
                                        @change="selectTransfer(item.id)"
                                        :value="selected.includes(item.id)"
                                        :key="`${item.id}`"
                                        class="ml-3"
                                        v-on="on"
                                        slot="activator"
                                        :disabled="item.pas_account_info.account_status !== 'O'"
                                    />
                                    <span>Account must be active to generate transfer documents</span>
                                  </v-tooltip>
                                </v-flex>
                                <v-flex xs2>
                                    <v-layout fill-height row align-center justify-start>
                                        <div class="p-text">{{accountNicknames[item.pas_account]}}</div>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs1>
                                    <v-layout fill-height row align-center justify-start>
                                        <div class="p-text">{{item.pas_account_info.currency}}</div>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs2>
                                    <v-layout fill-height row align-center justify-start>
                                        <div class="p-text">{{item.account_number_external}}</div>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs2>
                                    <v-layout fill-height row align-center justify-start>
                                        <div class="p-text">{{item.relinquishing_institution}}</div>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs1>
                                    <v-layout fill-height row align-center justify-start>
                                        <div class="p-text">${{commaDecimalFormatted(item.amount_estimated)}}</div>
                                    </v-layout>
                                </v-flex>
                                  <v-flex xs2>
                                    <v-layout fill-height row align-center justify-start>
                                        <div class="p-text">{{transferMethods[item.transfer_method]}}</div>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs1>
                                    <v-layout align-center fill-height>
                                        <pas-more-menu
                                            @click="$event(item.id)"
                                            :editOptions="editOptions"
                                        ></pas-more-menu>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </template>
                    </pas-list>
                    <v-layout text-xs-center mt-4>
			            <v-flex>
                            <pas-pagination
                                :length="paginationLength"
                                :value="page"
                                @input="page = $event"
                            ></pas-pagination>
                        </v-flex>
                    </v-layout>
                    <v-layout mt-3 text-xs-center>
                        <v-flex>
                            <pas-error-div :show="errorText ? true : false">{{errorText}}</pas-error-div>
                        </v-flex>
                    </v-layout>
                    <v-layout mt-2 justify-end>
                        <pas-button class="px-5" :disabled="saving" @click="generate">Generate</pas-button>
                    </v-layout>
                </v-form>
            </v-container>
		</v-card>
	</v-dialog>
</template>

<script>

import { mapGetters } from 'vuex';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import api from 'Services/api';
import { createExternalAccountTransferWorkflow } from 'Services/api/Workflows';
import { commaDecimalFormatted, commaFormatted } from 'Services/helpers/currency';
import { ACCOUNT_TRANSFER_METHODS } from 'Services/constants';
import babelPolyfill from '@babel/polyfill';


export default {
  name: 'generate-account-transfer-docs',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commaDecimalFormatted,
      columns: [
        { header: '', size: 'xs1' },
        { header: 'Account Name', size: 'xs2' },
        { header: 'Currency', size: 'xs1' },
        { header: 'Relinquishing Acc No.', size: 'xs2' },
        { header: 'Relinquishing Institution', size: 'xs' },
        { header: 'Amount', size: 'xs1' },
        { header: 'Transfer Method', size: 'xs2' },
        { header: '', size: 'xs1' },
      ],
      filteredTransfers: [],
      paginationLength: 1,
      page: 1,
      perPage: 5,
      selected: [],
      editOptions: [{
        title: 'Delete',
        onClick: (id) => this.delete(id),
      }],
      saving: false,
      errorText: '',
      v,
    };
  },
  computed: {
    ...mapGetters(['hasFeatureFlag']),
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    transfers() {
      return this.$store.state.clients.activeClient.transfers
        .filter((transfer) => transfer.transfer_status === '0');
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts.length ? this.activeClient.investment_accounts : [];
    },
    accountNicknames() {
      const accountNicknames = {};
      this.investmentAccounts
        .forEach((account) => {
          const { nickname, id } = account;
          accountNicknames[id] = nickname;
        });
      return accountNicknames;
    },
    transferMethods() {
      return ACCOUNT_TRANSFER_METHODS;
    },
  },
  methods: {
  	    filterAndSort() {
      this.filteredTransfers = _.cloneDeep(this.transfers);
      this.paginationLength = this.filteredTransfers.length ? Math.ceil(this.filteredTransfers.length / this.perPage) : 1;
      const iStart = (this.page - 1) * this.perPage;
      const iEnd = this.page * this.perPage;
      this.filteredTransfers = this.filteredTransfers.slice(iStart, iEnd);
    },
    selectTransfer(id) {
      const index = this.selected.findIndex((item) => item === id);
      if (index === -1) return this.selected.push(id);
      this.selected.splice(index, 1);
    },
    delete(id) {
      api.deleteCall(`/datahub/external_account_transfers/${id}/`)
        .then((res) => {
          if (res) this.$store.dispatch('fetchTransfersByClientID', this.activeClient.client.id);
        })
        .catch((err) => err);
    },
    async generate() {
      this.errorText = '';
      if (!this.selected.length) return this.errorText = 'No transfers are selected';
      this.saving = true;
      if (this.hasFeatureFlag('new_workflows')) {
        try {
          await createExternalAccountTransferWorkflow([this.activeClient.client.id], this.selected);
          this.handleResponse('success');
        } catch (err) {
          this.handleResponse('fail');
        }
      } else {
        try {
          const workflow = await api.post('/advisor_portal/workflow/new/', {
            pas_client_ids: [this.activeClient.client.id],
            workflow_type: 'external_account_transfer',
          });
          if (!workflow.workflow_id) throw Error;
          const transfersPostResponse = await api.post('/advisor_portal/gen_transfer_forms/', {
            transfers: this.selected,
            workflow_id: workflow.workflow_id,
          });
          if (transfersPostResponse.code === 422) { // data validation error
            this.errorText = transfersPostResponse.details.error_list[0];
            await this.$store.dispatch('deleteWorkflow', workflow.workflow_id);
            throw Error;
          }
          if (transfersPostResponse.code !== 200) {
            await this.$store.dispatch('deleteWorkflow', workflow.workflow_id);
            throw Error;
          }
          this.handleResponse('success');
        } catch (err) {
          this.handleResponse('fail');
        }
      }
    },
    handleResponse(type) {
      this.saving = false;
      let text = 'Account transfer workflow creation failed';
      if (type === 'success') {
        text = 'Account transfer workflow has been successfully created';
        this.$store.dispatch('fetchTransfersByClientID', this.activeClient.client.id);
        this.closeModal();
      }
      this.$store.dispatch('getWorkflows', this.activeClient.client.id);
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    closeModal() {
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.close();
    },
  },
  watch: {
    transfers: {
      handler() {
        this.filterAndSort();
      },
      deep: true,
    },
    isOpen() {
      this.filterAndSort();
    },
    page() {
      this.filterAndSort();
    },

  },
};
</script>

<style lang="scss" >

</style>
