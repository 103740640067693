<template>
	<v-dialog :value="isOpen" max-width="60rem">
		<v-card class="pa-5" max-width="60rem">
			<PasFab class="close-btn">
				<v-icon @click="close" class="close-icon">close</v-icon>
			</PasFab>
			<v-card-title class="headline pl-0" :style="{ justifyContent: 'center' }">
				Select Relationship
			</v-card-title>
			<v-form @submit.prevent ref="form" lazy-validation>
				<v-card-text v-if="relationshipOptions.length" class="mb-3 pl-0" :style="{ textAlign: 'center' }">
					Below are {{clientList[client]}}'s relationships. Select a relationship, or click 
					<span @click="addEditRelationshipModal = true" class="action-link underline">here</span>
                    to add a relationship if it's not yet in AC.
				</v-card-text>
				<v-card-text v-else class="mb-3 pl-0" :style="{ textAlign: 'center' }">
					{{clientList[client]}} has no relationships. Click 
					<span @click="addEditRelationshipModal = true" class="action-link underline">here</span>
                    to add a relationship.
				</v-card-text>                
                <v-layout column>
                    <template v-for="(rel, i) in relationshipOptions">
                        <v-flex>
                            <v-checkbox 
                                v-model="selectedRelationship" 
                                :label="clientList[rel.client_details[0].id]" 
                                :value="rel.client_details[0].id"
                                :color="primaryColor"
                                class="mt-0 ml-3"
                            ></v-checkbox>
                        </v-flex>
                    </template>
                </v-layout>
                <pas-error-div class="ml-3" :show="errorText ? true : false">{{errorText}}</pas-error-div>                   
				<v-card-actions class="pr-0 mt-4">
					<v-spacer></v-spacer>
					<pas-button 
						class="primary-btn wide-btn" 
						@click="select" 
						:processing="processing"
					>Select</pas-button>
				</v-card-actions>
			</v-form>
		</v-card>
    <add-edit-relationship
        :isOpen="addEditRelationshipModal"
        :action="'add'"
        :client="selectedClient"
        @close="addEditRelationshipModal = false"
        @done="fetchRelationships"
    ></add-edit-relationship>	
	</v-dialog>
</template>

<script>

import { RELATIONSMAP, RELATIONS, CORPORATERELATIONS } from 'Services/constants'
import AddEditRelationship from 'Components/Shared/AddEditRelationship'
import { modal } from 'Services/mixins'

export default {
    name: 'Select-relationship',
    mixins: [modal],
	components: {
        AddEditRelationship
	},
	props: {
		isOpen: {
            type: Boolean,
            default: false
        },
        client: Number
	},
	data () {
		return {
			errorText: '',
            processing: false,
            relationshipOptions: [],
            selectedRelationship: [],
            addEditRelationshipModal: false
		}
    },
	computed:{
        clientList(){
            const clientList = {};
            this.$store.state.clients.list.forEach(client => { 
                const { id, display_name } = client;
                clientList[id] = display_name;
            })
            return clientList;
        },
        selectedClient(){
            return { id: this.client, displayName: this.clientList[this.client] };
        },
        primaryColor() {
            return this.$store.state.app.themeVars.primaryColor;
        }
	},
	watch: {
		isOpen(val) {
			if (val && this.client) this.fetchRelationships();
        },
        addEditRelationshipModal(){
            if (!this.addEditRelationshipModal && this.isOpen) this.fetchRelationships();
        }
    },
	methods: {
        fetchRelationships(){
            this.$store.dispatch('fetchRelationships', this.client)
            .then(res => {
                this.relationshipOptions = res[0].relationship_id ? res : [];
            })
            .catch(err => console.log("err fetching rel",err));
        },
        select(){
            if (!this.selectedRelationship.length) {
                return this.errorText = "Please select a client with a financial interest";
            }
            const relationshipDetails = this.relationshipOptions
            .filter(rel => this.selectedRelationship.includes(rel.client_details[0].id))
            .map(rel => ({
                clientId: rel.client_details[0].id,
                clientDisplayName: rel.client_details[0].display_name,
                relationship: RELATIONS[rel.relationship_type]
            }))
            this.relationshipOptions = [];
            this.selectedRelationship = [];
            this.$emit('done',relationshipDetails, this.client);
        }
	}
}
</script>

<style lang="scss">

</style>
