<template>
  <div v-if="canAccessOperationalCapabilities">
    <!--Register any modals here -->
    <AddBankAccount
      v-if="canEditBankAccountSetup"
      :isOpen="isBankAccountOpen"
    />
    <GenerateAccountTransferDocs
      v-if="canEditGenerateTransferDocuments"
      :isOpen="isGenerateAccountTransferDocsOpen"
    />
    <GenerateIpsDocs
      v-if="canEditGenerateIps && activeClient.client"
      :isOpen="isGenerateIpsDocsOpen"
    />
    <AddSystematicPayment
      v-if="canEditSetUpSystematic && custodian !== 'fcc'"
      :isOpen="isSetUpSystematicOpen"
    />
    <AddSystematicFcc
      v-if="canEditSetUpSystematic && custodian === 'fcc' && this.isMoneyMovementFeature"
      :isOpen="isAddSystematicFccOpen"
    />
    <AddFundTransfer
      v-if="canEditTransferFunds"
      :isOpen="isAddFundTransferOpen"
    />
    <AddInternalTransfer
      v-if="canEditTransferFunds && custodian === 'fcc' & this.isMoneyMovementFeature"
      :isOpen="isAddInternalTransferOpen"
    />
    <AddInvestmentAccounts
      v-if="canEditOpenAccount && activeClient.client"
      :isOpen="isAddInvestmentAccountsOpen"
    />
    <AddCorporateInvestmentAccounts
      v-if="canEditOpenAccount && activeClient.client"
      :isOpen="isAddCorporateInvestmentAccountsOpen"
    />
    <AddAccountTransfer
      v-if="canEditTransferAccount"
      :isOpen="isAddAccountTransferOpen"
    />
    <SendInvestorInvite
      v-if="canInviteClient && activeClient.client"
      :isOpen="isSendInvestorInvite"
    />
    <UnlockInvestor
      v-if="canInviteClient && activeClient.client"
      :isOpen="isUnlockInvestor"
    />
    <CannotEditProfile
      v-if="(hasFeatureFlag('client_maintenance') || hasFeatureFlag('annual_kyc')) && activeClient.client"
      :isOpen="isCannotEditProfile"
    />
    <ContactOpsCancelWorkflow
      v-if="(hasFeatureFlag('client_maintenance') || hasFeatureFlag('annual_kyc')) && activeClient.client"
      :isOpen="isContactOpsCancelWorkflow"
    />
  </div>
</template>

<script>
import AddBankAccount from 'Components/Modals/AddBankAccount';
import GenerateAccountTransferDocs from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/GenerateAccountTransferDocs';
import GenerateIpsDocs from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/GenerateIpsDocs';
import AddSystematicPayment from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddSystematicPayment';
import AddFundTransfer from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddFundTransfer';
import AddInvestmentAccounts from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddInvestmentAccounts';
import AddCorporateInvestmentAccounts from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddCorporateInvestmentAccounts';
import AddAccountTransfer from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddAccountTransfer';
import AddInternalTransfer from 'Components/Modals/AddInternalTransfer';
import AddSystematicFcc from 'Components/Modals/AddSystematicsFcc/AddSystematicFcc';
import CannotEditProfile from 'Components/Modals/CannotEditProfile';
import ContactOpsCancelWorkflow from 'Components/Modals/ContactOpsCancelWorkflow';
import SendInvestorInvite from 'Components/Modals/SendInvestorInvite';
import UnlockInvestor from 'Components/Modals/UnlockInvestor';
import { mapGetters } from 'vuex';

export default {
  name: 'modal-manager',
  components: {
    AddBankAccount,
    GenerateAccountTransferDocs,
    GenerateIpsDocs,
    AddSystematicPayment,
    AddFundTransfer,
    AddInvestmentAccounts,
    AddCorporateInvestmentAccounts,
    AddAccountTransfer,
    AddInternalTransfer,
    AddSystematicFcc,
    SendInvestorInvite,
    UnlockInvestor,
    CannotEditProfile,
    ContactOpsCancelWorkflow,
  },
  computed: {
    ...mapGetters([
      'canAccessOperationalCapabilities',
      'canEditBankAccountSetup',
      'canEditGenerateTransferDocuments',
      'canEditGenerateIps',
      'canEditSetUpSystematic',
      'canEditTransferFunds',
      'canEditOpenAccount',
      'canEditTransferAccount',
      'isBankAccountOpen',
      'isGenerateAccountTransferDocsOpen',
      'isGenerateIpsDocsOpen',
      'isSetUpSystematicOpen',
      'isAddFundTransferOpen',
      'isAddInvestmentAccountsOpen',
      'isAddCorporateInvestmentAccountsOpen',
      'isAddAccountTransferOpen',
      'isAddInternalTransferOpen',
      'isAddSystematicFccOpen',
      'isSendInvestorInvite',
      'isUnlockInvestor',
      'isCannotEditProfile',
      'isContactOpsCancelWorkflow',
      'hasFeatureFlag',
      'custodian',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    isMoneyMovementFeature() {
      return this.hasFeatureFlag('money_movement');
    },
    canInviteClient() {
      return this.hasFeatureFlag('investor_portal');
    },
  },
};
</script>
