/* eslint-disable brace-style */
import jwtDecode from 'jwt-decode';
import _, { find } from 'lodash';
import { FIRMS } from '../constants';

export const getThemeFromUrl = (hostname, params) => {
  // split location by period, analyze that
  // use url parameter for localhost

  if (hostname !== 'localhost') {
    const currentFirm = _.intersection(hostname.split('.'), Object.values(FIRMS));
    return currentFirm[0] || 'fwp';
  }

  if (params.firm) {
    return params.firm || FIRMS[params.firm];
  }
  return 'fwp';
};

export const buildStylesheetHref = (theme) => `${window.location.origin}/advisor_portal/src/styles/themes/${theme}/${theme}_theme.css`;

export const getParamsFromUrl = (url) => _.chain(url)
  .replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
  .split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
  .map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
  .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
  .value();

export const getThemeFromJwt = () => {
  const jwt = localStorage.getItem('jwt');
  if (jwt && jwt != '' && jwt != 'undefined') {
    const jwtInfo = jwtDecode(jwt);
    if (!jwtInfo.firm) return null;
    return FIRMS[jwtInfo.firm];
  } else {
    return null;
  }
};

export const getTheme = () => {
  const jwtTheme = getThemeFromJwt();
  const params = getParamsFromUrl(window.location.search);
  return jwtTheme || getThemeFromUrl(window.location.hostname, params);
};

export const getTokenFormatFirmFromTheme = () => {
  const themeFirm = getTheme()
  const tokenFormatFirm = Object.keys(FIRMS).find(key => FIRMS[key] === themeFirm);
  return tokenFormatFirm;
};
