<template>
  <HouseholdMembersList
    @view-profile="goToClientsProfile($event)"
  />
</template>

<script>
export default {
  name: 'HouseholdMembersTab',
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    goToClientsProfile(clientId) {
      this.$router.push({ path: `/clients/${clientId}` });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
