<template>
  <PContainer v-if="clientID && clientLoaded">
    <PRow v-if="currentStep !== ViewEnums.SURVEY">
      <PColumn class="text-right mx-4">
        <LanguageSwitcher class="mr-2 ml-auto" />
      </PColumn>
    </PRow>
    <InPersonMfa
      v-if="currentStep === ViewEnums.MFA"
      :clientId="clientID"
      :context="mfaContext"
      @success="onSuccessMfa"
      @error="onError"
    />
    <ConsentView
      v-if="currentStep === ViewEnums.CONSENT"
      @consentCompleted="onConsentCompleted"
    />
    <SubAdvisorConsentView
      v-if="currentStep === ViewEnums.SUBADVISOR_CONSENT"
      :activeClient="activeClient"
      @subAdvisorConsentCompleted="onSubAdvisorConsentCompleted"
    />
    <DataCaptureSurvey
      v-if="currentStep === ViewEnums.SURVEY"
      :clientId="clientID"
      :investor="investor"
      :featureFlags="features"
      :navigation="navigation"
      viewMode="advisor"
    />
    <!-- Survey component has its own Error Wrapper -->
    <PRow v-if="currentStep !== ViewEnums.SURVEY" class="justify-content-center">
      <PColumn md="5">
        <ErrorWrapper type="alert" />
      </PColumn>
    </PRow>
  </PContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import ConsentView from './ConsentView';
import SubAdvisorConsentView from './SubAdvisorConsentView';

const ViewEnums = {
  MFA: 1,
  CONSENT: 2,
  SUBADVISOR_CONSENT: 3,
  SURVEY: 4,
};

export default {
  name: 'KycSurvey',
  components: {
    ConsentView,
    SubAdvisorConsentView
  },
  data() {
    return {
      ViewEnums,
      currentStep: ViewEnums.CONSENT,
      mfaContext: this.$route.query.context ?? 'In Person Survey',
    };
  },
  props: {
    clientID: { type: String, default: null },
    clientLoaded: Boolean,
  },
  computed: {
    ...mapGetters(['activeClient', 'hasFeatureFlag']),
    features() {
      return this.$store.state.app.features;
    },
    client() {
      return this.activeClient?.client;
    },
    investor() {
      return {
        display_name: this.client?.display_name,
        email: this.client?.email,
      };
    },
    navigation() {
      return {
        onEndDataCapture: {
          routeName: 'onboardingCompleted',
        },
        onLeaveDataCapture: {
          routeName: 'ClientHomePage',
          params: { clientID: this.client?.id },
        },
      };
    },
    prefillMfaData() {
      const mobileNumber = this.activeClient?.person?.mobile_number;
      const email = this.activeClient?.person?.email;
      return (mobileNumber || email) ? {
        mobileNumber,
        email,
      } : {};
    },
  },
  created() {
    this.currentStep = this.hasFeatureFlag('in_person_survey_mfa') ? ViewEnums.MFA : ViewEnums.CONSENT;
    if (this.$route.query.started) {
      // avoid NavigationDuplicated error
      this.$router.replace({ query: null });
    }
  },
  methods: {
    async onConsentCompleted() {
      this.currentStep = ViewEnums.SUBADVISOR_CONSENT;
      if(!this.activeClient?.suitabilityScore) {
        await this.$store.dispatch('setClientSuitabilityScore', this.activeClient?.client.id)
      }
      // skip survey landing page
      this.$router.push({ query: { started: 'true' } });
    },
    onSubAdvisorConsentCompleted() {
      this.currentStep = ViewEnums.SURVEY;
      // skip survey landing page
      this.$router.push();
    },
    onError(event) {
      this.$store.dispatch('errorParser/parseError', event?.error);
    },
    onSuccessMfa() {
      this.$store.dispatch('errorParser/clearError');
      this.currentStep = ViewEnums.CONSENT;
    },
  },
};
</script>
