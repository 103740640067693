import api from 'Services/apiV2';

export const fetchReportList = (user) => api.get(`/get_report_url/?ac_user_email=${encodeURIComponent(user)}`)
  .then((res) => res.reports || [])
  .catch((err) => err);

export const fetchCategoryReportList = (user, categoryId = 1) => api.get(`/get_report_url/?category_id=${categoryId}&ac_user_email=${encodeURIComponent(user)}`)
  .then((res) => res.reports || [])
  .catch((err) => err);

export const fetchCategories = (advisor_id) => api.get(`/get_category/?advisor_id=${advisor_id}`)
  .then((res) => res.results || [])
  .catch((err) => err);

export const fetchFullReportUrl = ({ user, reportId }) => api.get(`/get_report_url/?ac_user_email=${encodeURIComponent(user)}&insight_ids=${reportId}&insight_context=full_report`)
  .then((res) => res.reports || [])
  .catch((err) => err);
