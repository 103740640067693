<template>
  <div>
    <v-container class='ra-preferences-manager'>
      <template v-if="this.isLoading">
        <v-layout justify-center align-center>
            <PasCircularLoader :loadingMessage="`Loading RA preferences manager...`"  />
        </v-layout>
      </template>
      <template v-else-if="this.rolesAndPermissionsFeatureEnabled && isRAPreferencesOn">
        <v-layout align-baseline mt-4 justify-space-between>
          <PasPageTitle class="mb-4">Referral Advisors ({{ this.numOfRAs }})</PasPageTitle>
        </v-layout>
        <v-layout justify-space-between>
          <v-flex xs6>
            <PasSearchBox
              placeholder="Search for a referral advisor..."
              v-model="searchText"
              :clearable="false"
              @input="filterOptions.page = 1;"
            />
          </v-flex>
          <v-flex xs6>
            <v-layout justify-end>
              <PasButton
                @click="saveChanges"
                class="primary-btn px-4 mr-3"
                :disabled="!isEdited"
              >Save Changes</PasButton>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-spacer></v-spacer>
        <pas-list
          :columns="getColumns"
          :itemName="'ra preferences...'"
          :items="filteredAllRAPreferences"
        >
          <template slot-scope="{ item }" >
            <v-layout fill-height row>
              <v-flex xs3 strong-text>
                <v-layout fill-height align-center ml-3>
                  {{ item.name }}
                </v-layout>
              </v-flex>
              <v-flex xs3 pr-5>
                <v-layout fill-height>
                  <v-select
                    @change="isEdited = true; item.isEdited = true;"
                    v-model="item.planning_schedule_id"
                    :items="allFeeSchedules"
                    :placeholder="'N/A'"
                  >
                  </v-select>
                </v-layout>
              </v-flex>
              <v-flex v-for="(sa, i) in item.preferred_subadvisors"
                xs2
                :class="isSubAdvisorConsentFF ? 'pr-2' : 'pr-5'"
                :key="item.name + item.preferred_subadvisors[i].value + item.preferred_subadvisors[i].program_id + i"
              >
                <v-layout fill-height>
                  <v-select
                   @change="isEdited = true; item.isEdited = true;"
                    v-model="item.preferred_subadvisors[i].value"
                    :items="modifiedAllSubadvisors"
                    :placeholder="'N/A'"
                  >
                  </v-select>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </pas-list>
        <v-spacer fill-height></v-spacer>
        <v-layout text-xs-center row fill-height>
          <v-flex xs12>
            <pas-pagination :length="paginationLength" v-model="filterOptions.page"></pas-pagination>
          </v-flex>
        </v-layout>
      </template>
    </v-container>
  </div>
</template>

<script>

import { fetchRAPreferences, updateRAPreferences } from 'Services/api/RAPreferences';
import { fetchPlanningSchedules } from 'Services/api/FeeManagement';
import { mapGetters } from 'vuex';

export default {
  name: 'RAPreferencesManager',
  data() {
    return {
      filterOptions: {
        sortDir: -1, // -1 for down (most recent first), 1 for up
        page: 1,
        perPage: 10, // not modifiable
      },
      paginationLength: 1,
      columns: [
        {
          header: 'Name', size: 'xs3',
        },
        {
          header: 'Planning Schedule', size: 'xs3',
        },
        {
          header: 'Core Pools', size: 'xs2',
        },
        {
          header: 'Core Models', size: 'xs2',
        },
        {
          header: 'Custom Models', size: 'xs2',
        },
      ],
      isLoading: true,
      searchText: '',
      allRAPreferences: [],
      allFeeSchedules: [],
      isEdited: false,
    };
  },
  async mounted() {
    await this.getRAPreferences();
    await this.$store.dispatch('fetchAllSubAdvisors');
    await this.getFeeSchedule();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      'allSubadvisors',
      'hasFeatureFlag',
    ]),
    getColumns(){
      if(this.isSubAdvisorConsentFF){
        let newColumns = this.columns.slice(0, 2);
        newColumns.push( { header: 'Sub-Advisor Preference', size: 'xs3' } );
        return newColumns;
      }
      else return this.columns
    },
    rolesAndPermissionsFeatureEnabled() {
      return this.$store.state.app.features.roles_and_permissions;
    },
    numOfRAs() {
      return this.allRAPreferences.length;
    },
    firm() {
      return this.$store.getters.firm;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    modifiedAllSubadvisors() {
      return this.allSubadvisors.map((fs) => ({
        text: fs.name,
        value: fs.id,
      }));
    },
    filteredAllRAPreferences() {
      return this.pagination(this.allRAPreferences
        .filter((ra) => ra.name.toLowerCase()
          .includes(this.searchText.toLowerCase())));
    },
    isRAPreferencesOn() {
      return this.hasFeatureFlag('ra_preferences');
    },
    isSubAdvisorConsentFF() {
      return this.hasFeatureFlag('sub_advisor_consent');
    },
  },
  methods: {
    async saveChanges() {
      let modifiedRAs = [];
      modifiedRAs = this.filteredAllRAPreferences
        .filter((ra) => ra.isEdited)
        .map((ra) => ({
          ra_id: ra.ra_account_id,
          planning_schedule_id: ra.planning_schedule_id,
          preferred_subadvisors: ra.preferred_subadvisors
            .filter((ps) => ps.value)
            .map((ps) => ({
              program_id: ps.program_id,
              subadvisor_id: ps.value,
            })),
        }));

      await updateRAPreferences(modifiedRAs)
        .then((res) => {
          if (res.status === 'success') {
            this.snackBar('Successfully updated RA Preferences', 'success');
            return;
          }
          this.snackBar('Failed to update RA Preferences', 'fail');
        })
        .catch((err) => this.snackBar(err.message, 'fail'));

      await this.getRAPreferences();
      this.isEdited = false;
    },
    async getRAPreferences() {
      await fetchRAPreferences()
        .then((res) => {
          if (res.status === 'success') {
            this.allRAPreferences = res.ra_preferences.map((ra) => ({
              ra_account_id: ra.id,
              name: ra.display_name,
              isEdited: false,
              planning_schedule_id: ra.default_planning_schedule_id,
              preferred_subadvisors: this.formatPreferredSubadvisors(ra),
            }));
          } else {
            this.snackBar('Failed to retrieve RA Preferences', 'fail');
          }
        });
    },
    async getFeeSchedule() {
      await fetchPlanningSchedules()
        .then((res) => {
          if (res) {
            this.allFeeSchedules = res.map((fs) => ({
              text: fs.name,
              value: fs.id,
            }));
          }
        });
    },
    formatPreferredSubadvisors(ra) {
      const formattedPreferredSubadvisorsList =  [
        {
          program_id: 1, subadvisor_id: null, value: null,
        },
        {
          program_id: 2, subadvisor_id: null, value: null,
        },
        {
          program_id: 3, subadvisor_id: null, value: null,
        },
      ];
      const formattedPreferredSubadvisors = this.isSubAdvisorConsentFF ? formattedPreferredSubadvisorsList.filter((sub) => sub.program_id === 1): formattedPreferredSubadvisorsList;
      const preferred_subadvisors = this.isSubAdvisorConsentFF ? ra.preferred_subadvisors.filter((sub) => sub.program_id === 1) : ra.preferred_subadvisors;

      preferred_subadvisors.forEach((sub) => {
        const { subadvisor_id, program_id } = sub;
        formattedPreferredSubadvisors[program_id - 1].subadvisor_id = subadvisor_id;
        formattedPreferredSubadvisors[program_id - 1].value = subadvisor_id;
      });
      return formattedPreferredSubadvisors;
    },
    pagination(filteredAllRAPreferences) {
      const iStart = (this.filterOptions.page - 1) * this.filterOptions.perPage;
      const iEnd = this.filterOptions.page * this.filterOptions.perPage;

      this.paginationLength = filteredAllRAPreferences ? Math.ceil(filteredAllRAPreferences.length / this.filterOptions.perPage) : 1;
      return filteredAllRAPreferences.slice(iStart, iEnd);
    },
    snackBar(text, type) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
};
</script>

<style lang="scss">
.ra-preferences-manager {
  .v-input__slot {
      .v-select__slot {
          .v-select__selection {
            width: 100% !important;
            white-space: nowrap;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
      }
  }
}
</style>
