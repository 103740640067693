<template>
  <div>
  <template v-if="onboardingSetupLoading">
    <v-layout justify-center class="investment-accounts-loader">
      <pas-circular-loader
        :loadingMessage="`Don't worry, we are processing your request. It will take just a moment. Please do not close this window or click the Back button on your browser`">
      </pas-circular-loader>
    </v-layout>
  </template>
  <template v-else>
    <v-layout>
      <v-flex xs12 text-xs-center mb-4>
        <pas-page-title>
          What would you like to do next?
        </pas-page-title>
      </v-flex>
    </v-layout>
    <v-layout mb-4 justify-center>
      <v-flex xs12 text-xs-center>
        <span>
          Looking good! We're almost done. As the final step in the process, you'll need to access your client's tolerance
          for investment risk. You can complete this step in person, or by email. 
        </span>
      </v-flex>
    </v-layout>
    <transition name="slide-fade">
      <v-layout row nowrap v-if="surveyOptionsLoaded" justify-center>
        <v-flex xs4 text-xs-center mr-2>
          <action-card
            :action="'In Person'"
            :imagePath="InPersonSvg"
            @click="$emit('triggerInPerson')"
          >Walk your client through the easy process of opening an account in person.
          </action-card>

        </v-flex>
        <!-- <v-flex xs4 text-xs-center>
          <action-card
            :action="'Screenshare'"
            :imagePath="ScreenshareSvg"
            @click=""
            :disabled="true"
          >Walk your client through the process of opening an account while sharing your screen.
          </action-card>
        </v-flex> -->
        <v-flex xs4 text-xs-center>
          <action-card
            :action="'By Email'"
            :imagePath="EmailSvg"
            @click="$emit('triggerByEmail')"
          >Send a link to your client and let them go through the risk assessment on their own.
          </action-card>
        </v-flex>
      </v-layout>
    </transition>
  </template>
  </div>
</template>

<script>
import ActionCard from 'Components/Shared/ActionCard'
import EmailSvg from 'Assets/icons/email-01.svg'
import InPersonSvg from 'Assets/icons/meeting-in-person.svg'
import ScreenshareSvg from 'Assets/icons/screenshare-01.svg'

export default {
  name: 'trigger-onboarding',
  components: {
    ActionCard
  },
  data() {
    return {
      EmailSvg,
      InPersonSvg,
      ScreenshareSvg
    }
  },
  props: {
    onboardingSetupLoading: {
      type: Boolean,
      default: false
    },
    surveyOptionsLoaded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>