<template>
    <pas-fullscreen-modal @close="$emit('close'); nominee = null" :isOpen="isOpen">
        <v-container class="modal-container">
            <v-layout>
                <v-flex xs12 text-xs-center>
                    <pas-page-title>Specify Nominee</pas-page-title>
                </v-flex>
            </v-layout>
            <v-layout column>
                <v-flex xs12 text-xs-center>
                    <div>Specify the nominee for the Cash ITF Account</div>
                </v-flex>
                <v-flex xs12 text-xs-center mt-3>
                    <div>
                        If the nominee is not yet in advisor center, you can <br/>
                        <span @click="$emit('addNewClient')" class="action-text underline">add a new client</span>
                    </div>
                </v-flex>
                <v-flex text-xs-center mt-5>
                    <v-form ref="form" lazy-validation @submit.prevent="">
                        <pas-autocomplete
                            :rules="[v.isRequired('Please select a nominee')]"
                            :placeholder="`Type here to search...`"
                            :prependInnerIcon="`search`"
                            :key="`nominee-autocomplete`"
                            :items="relationships"
                            :value="nominee"
                            @input="nominee = $event"
                            :style="{ width: '255px'}"
                        ></pas-autocomplete>
                    </v-form>
                </v-flex>
            </v-layout>
            <v-layout mt-5 justify-end>
                <pas-button class="px-5" @click="save">Confirm</pas-button>
            </v-layout>
        </v-container>
    </pas-fullscreen-modal>
</template>

<script>

import v from 'Services/validation'

export default {
    name: 'specify-nominee',
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        account: Object
    },
    data() {
        return {
            v,
            errorText: '',
            addClientInfoOpen: false,
            nominee: null
        }
    },
    computed: {
        relationships() {
        return this.$store.state.clients.activeClient.relationships
            .map(r => {
                return { text: r.client_details[0].display_name, value: r }
            })
        },
        activeClient() {
            return this.$store.state.clients.activeClient;
        }
    },
    methods: {
        save() {
            if (!this.$refs.form.validate()) return false;
            this.$emit('save',this.nominee.client_details[0].id);
            this.$emit('close'); 
            this.nominee = null;
        }
    },
    watch: {
         account: { 
            handler () {
                this.nominee = this.account.nominee_id;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">

</style>
