<template>
  <PRow class="d-flex flex-nowrap justify-content-between">
    <PInputField
      class="w-100 household-list-search-bar ml-6"
      v-model="searchBarValue"
      placeholder="Search Households"
      prependIcon="search"
      type="text"
      :clearable="true"
      @input="$emit('input', searchForHouseholds(searchBarValue))"
    />
    <PDropdown :text="'Advisor team: ' + this.selectedTeam.name" class="px-4" size="lg">
      <PDropdownItem
        v-for="item in this.teamList"
        :key="item.id"
        :value="item.id"
        @click="refineHouseholdList(item)"
      >
        {{ item.name }}
      </PDropdownItem>
    </PDropdown>
  </PRow>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';

const householdsVueX = createNamespacedHelpers('households');
const clientsListVuex = createNamespacedHelpers('clients-list');

export default {
  name: 'HouseholdListSearch',
  data() {
    return {
      placeholder: [],
      searchBarValue: '',
    };
  },
  computed: {
    ...householdsVueX.mapState(['householdList', 'loading', 'selectedTeam']),
    ...clientsListVuex.mapState(['teamList']),
  },
  methods: {
    ...householdsVueX.mapActions(['fetchHouseholdList', 'removeClient']),
    ...householdsVueX.mapMutations(['SET_SELECTED_TEAM', 'SET_SEARCH_VAL']),
    dropdownShouldOpen(VueSelect) {
      return false;
    },
    refineHouseholdList(team) {
      this.SET_SELECTED_TEAM(team);
      this.fetchHouseholdList({ resetHouseholdList: true });
    },
    searchForHouseholds: _.debounce(async function (searchValue) {
      this.SET_SEARCH_VAL(searchValue);
      this.fetchHouseholdList({ resetHouseholdList: true });
    }, 300),
  },
};

</script>
