<template>
  <div>
    <PasPageTitle class="text-xs-center xs12 mb-3">Assign Fee Group</PasPageTitle>
    <v-layout column>
      <v-flex xs12 text-xs-center>
        <span>
          The client has been assigned to a Fee Group. You can change or update the fee group now or at a later time.
        </span>
      </v-flex>
    </v-layout>
    <v-layout>
      <div :style="{ width:'fit-content', margin: '0 auto', 'min-width': '800px'}">
        <ClientFeeGroup ref="clientFeeGroup" class="my-4" />

        <template v-if="relationshipsFiltered.length">
          <div class="mt-4 mb-3">You can assign any of the client's relationships to the fee group.</div>
          <v-layout>
            <v-flex shrink mr-3>
              <PasAutocomplete
                :items="relationshipsFiltered"
                v-model="selectedRelationship"
                :prependInnerIcon="`search`"
                :autofocus="true"
                :noDataText="`No client relationships`"
                :smallChips="true"
                :style="{ width: '300px' }"
              />
            </v-flex>
            <v-flex>
              <PasButton @click="add">Add</PasButton>
            </v-flex>
          </v-layout>
        </template>

        <v-layout justify-end>
          <PasButton @click="$emit('continueClick')" class="px-4" data-testid="add-fee-group-continue-btn">Continue</PasButton>
        </v-layout>
      </div>
    </v-layout>
  </div>
</template>

<script>
import ClientFeeGroup from 'Components/Shared/FeeManagement/ClientFeeGroup';
import { joinFeeGroup, fetchFeeGroup } from 'Services/api/FeeManagement';
import _ from 'lodash';
import { modal } from 'Services/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientFeeGroupTab',
  components: {
    ClientFeeGroup,
  },
  mixins: [modal],
  data() {
    return {
      selectedRelationship: undefined,
      relationshipsFiltered: [],
    };
  },
  mounted() {
    this.fetchRelationshipsFeeGroups();
  },
  computed: {
    ...mapGetters(['activeClient', 'clientId']),
    clientType() {
      return this.activeClient.client.client_type;
    },
    relationships() {
      const rel = [];

      this.activeClient.relationships
        .forEach((r) => {
          rel.push({
            value: r.client_details[0].id,
            text: r.client_details[0].display_name,
          });
        });
      this.activeClient.corporate_roles.forEach((c) => {
        const clientId = this.clientType !== 'person' ? c.client : c.corporate_client;
        rel.push({
          value: clientId,
          text: this.clientType !== 'person' ? c.client_details.display_name : c.corporate_client_details.display_name,
        });
      });
      return rel;
    },
  },
  methods: {
    async add() {
      if (!this.selectedRelationship) return;
      await joinFeeGroup({
        client: this.selectedRelationship,
        fee_group: this.activeClient.feeGroup.id,
        is_primary: false,
      });
      this.$store.dispatch('setFeeGroup', this.clientId);
      this.selectedRelationship = undefined;
      this.fetchRelationshipsFeeGroups();
    },
    async fetchRelationshipsFeeGroups() {
      try {
        const resRel = await Promise.all(this.relationships.map((r) => fetchFeeGroup(r.value)));
        let feeGroups = resRel.filter((r) => r);
        if (!feeGroups.length) {
          this.relationshipsFiltered = this.relationships;
          return;
        }
        feeGroups = feeGroups.map((r) => r.clients).flat().map((c) => c.client);
        const clientsWithFeeGroups = _.uniq(feeGroups);
        this.relationshipsFiltered = this.relationships.filter((r) => !clientsWithFeeGroups.includes(r.value));
      } catch {
        const text = 'Oops... Something went wrong. Please refresh the page.';
        this.snackBar('fail', text);
      }
    },
  },
};
</script>
