<template>
  <v-layout row nowrap class="new-workflow-step-info"
            :class="{'blurred': isDisabledStep}">
    <WorkflowStepName :workflow="workflow" :step="step" />
    <!-- Workflow Step Status and actions -->
    <v-flex xs2/>
    <v-flex xs4 class="justify-end">
      <WorkflowStepStatus class="pb-3" @open-error-dialog="isOpen = !isOpen;" :step="step" :workflow="workflow" />
      <v-layout v-if="!isDisabledStep">
        <slot></slot>
      </v-layout>
      <v-dialog v-if="step.status === 'process_error' || step.status === 'needs_attention'" v-model="isOpen" max-height="60rem" max-width="60rem">
        <PasFab class="close-btn">
          <v-icon @click="isOpen = !isOpen">close</v-icon>
        </PasFab>
        <v-card class="pa-5" max-height="60rem" max-width="60rem">
          <v-card-title class="headline pl-0" :style="{ justifyContent: 'center' }">
            {{ stepName }}
          </v-card-title>
          <v-flex  class="text-xs-center" mb-4>
           <span>Please fix the following errors and retrigger the step to continue the workflow.</span>
          </v-flex>
          <v-card-text>
            <ol class="step-error-dialog">
              <li v-for="error in step.error_details" :key="error.key">
                {{ error.value }}<v-btn v-if="error.action" fab small :color="primaryColor" @click="actionable(error.action)"><v-icon>launch</v-icon></v-btn>
              </li>
            </ol>
            <PasButton
              v-if="canPushStep()"
              class="primary-btn wide-btn mt-5"
              @click="retriggerStep(); isOpen = false"
            >Push Step</PasButton>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex';
import WorkflowStepName from './WorkflowStep/WorkflowStepName';
import WorkflowStepStatus from './WorkflowStep/WorkflowStepStatus';
import { WORKFLOW, WORKFLOW_STATUS_MAP } from '../../../../services/constants';
import acApi from 'Services/api';

export default {
  name: 'WorkflowStep',
  components: { WorkflowStepName, WorkflowStepStatus },
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    isCanceled: Boolean,
  },
  data() {
    return {
      WORKFLOW,
      WORKFLOW_STATUS_MAP,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters(['hasFullWorkflowEditPermission']),
    isDisabledStep() {
      if (this.isCanceled) return true;
      return this.step.status !== 'in_progress' && this.step.status !== 'needs_attention' && this.step.status !== 'process_error';
    },
    stepName() {
      return _.startCase(_.toLower(WORKFLOW.STEP_DISPLAY_NAMES[this.step.name]));
    },
    workflow() {
      return this.$store.state.workflow.selectedWorkflow;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
  methods: {
    async refreshWorkflow() {
      this.workflowID = this.$route.params.workflowID;
      this.$store.dispatch('fetchWorkflowV2Steps', this.workflowID);
    },
    getStepStatusLabel(step) {
      if (step.step_name === 'equifax_check' && step.status === 'needs_attention') return 'Failed';
      return WORKFLOW.STATUS_NAMES[step.status];
    },
    actionable(link) {
      console.log(link);
      window.open(link, '_blank');
    },
    async retriggerStep() {
      const payload = {
        message: 'workflow.' + this.workflow.type + '.' + this.step.name,
        data: {
          workflow_id: this.workflow.id,
        },
      };
      await acApi.post('/advisor_portal/send_message', payload);
      await this.$store.dispatch('updateWorkflow', {
        workflow: this.workflow.id,
        paused: false,
      });
      await this.refreshWorkflow();
    },
    canPushStep() {
      return this.step.can_be_triggered && this.hasFullWorkflowEditPermission;
    },
  },
};
</script>

<style scoped lang="scss">
  .new-workflow-step-info {
    margin: 26px 52px;
    padding: 16px 0px;
  }
  .blurred {
    opacity: 0.4;
  }
  .step-error-dialog {
    li {
      font-size: 16px !important;
      line-height: 38px !important;
    }
    .v-icon {
      font-size: 14px !important;
    }
    .v-btn--floating.v-btn--small {
      height: 24px !important;
      width: 24px !important;
      color: white !important;
      margin-bottom: 10px;
      box-shadow: none !important;
    }
  }
</style>
