// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("Styles/Avenir/386C11_0_0.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("Styles/Avenir/386C11_0_0.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("Styles/Avenir/386C11_0_0.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("Styles/Avenir/386C11_0_0.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("Styles/Avenir/386C11_1_0.eot");
var ___CSS_LOADER_URL_IMPORT_5___ = require("Styles/Avenir/386C11_1_0.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("Styles/Avenir/386C11_1_0.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("Styles/Avenir/386C11_1_0.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face{font-family:\"AvenirLTPro-Medium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")}@font-face{font-family:\"AvenirLTPro-Black\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\")}#app{height:100%}.application--wrap{background-color:#fafafa}", ""]);
// Exports
module.exports = exports;
