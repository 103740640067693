import api from 'Services/apiV2';

export const fetchPortfolioDiscussion = (clientId) => api.get('/get_portfolio_assignment_discussion', { client_id: clientId });

export const fetchClientSuitability = (clientId) => api.get('/get_portfolio_suitability', { client_id: clientId });

export const fetchClientPortfolio = (clientId) => api.get('/suitability_score/', { client_id: clientId });

export const fetchAccountPortfolio = (accountId) => api.get('/get_account_portfolio_info', { account_id: accountId });

export const updateClientPortfolio = (id, body) => api.put(`/suitability_score/${id}/`, body);

export const createClientPortfolio = (body) => api.post('/suitability_score/', body);

export const updateAccountPortfolio = (body) => api.put('/put_account_portfolio_info/', body);

export const fetchPortfolioStatusForSurvey = (clientId) => api.get('/get_or_update_portfolio_status_for_survey/', { client_id: clientId });

export const fetchFieldsToDisplay = (category) => {
  return api.get(`/firm_display_config/?category=${category}`)
    .then((res) => {
      return res || []
    })
    .catch((err) => []);
}