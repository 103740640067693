import Login from 'Components/Vue-Routes/Auth/Login';
import Grant from 'Components/Vue-Routes/Auth/Grant';
import Register from 'Components/Vue-Routes/Auth/Register';
import Forgot from 'Components/Vue-Routes/Auth/Forgot';
import Reset from 'Components/Vue-Routes/Auth/Reset';
import Confirm from 'Components/Vue-Routes/Auth/Confirm';
import Details from 'Components/Vue-Routes/Auth/Details';
import SSOLogout from 'Components/Vue-Routes/Auth/SSOLogout';

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Login,
  },
  {
    path: '/grant',
    name: 'grant',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Grant,
  },
  {
    path: '/register',
    name: 'register',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Register,
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Forgot,
  },
  {
    path: '/new_password',
    name: 'reset',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Reset,
  },
  {
    path: '/confirm',
    name: 'confirm',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Confirm,
  },
  {
    path: '/details',
    name: 'details',
    meta: { requiresAuth: false, stylesheet: 'old' },
    component: Details,
  }, {
    path: '/sso-logout',
    name: 'sso-logout',
    meta: { requiresAuth: false, stylesheet: 'beta' },
    component: SSOLogout,
  },
];
