<template>
  <div v-if="hasFullWorkflowViewPermission">
    <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span v-if="(step.status === 'needs_attention' || step.status === 'process_error') && !isCanceled">
      <span>FOR COMPLIANCE ONLY:</span>
      <br>
      <!-- TODO: finish mannully complete AML check route -->
      <span @click.prevent="completeAMLCheck" class="action-text">Complete Manually</span>
    </span>
    <span v-else-if="isCanceled"></span>
    <span v-else>We are currently performing World-Check Customer Risk Screening</span>
    <!-- Compliance dialog: AML Check-->
    <CompleteAMLCheck
      :isOpen="completeAMLCheckDialog"
      @close="completeAMLCheckDialog = false"
      @submit="manualAMLCheck($event); completeAMLCheckDialog = false"
    />
    <v-dialog max-width="450px" v-model="complianceDialog" persistent>
      <v-card class="pa-5">
        <v-layout column>
          <PasPageSubtitle>Compliance Approval</PasPageSubtitle>
          <div class="my-3">Only members of the compliance team can approve</div>
          <v-layout>
            <v-spacer/>
            <PasButton
              class="primary-btn mt-3"
              @click="complianceDialog = false"
            >OK</PasButton>
          </v-layout>
        </v-layout>
      </v-card>
    </v-dialog>
    </WorkflowStep>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from 'Services/api';
import { sendAMLManualApproval } from 'Services/api/Workflows';
import CompleteAMLCheck from './AmlCheckStep/CompleteAMLCheck';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'AmlCheckStep',
  components: { WorkflowStep, CompleteAMLCheck },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      completeAMLCheckDialog: false,
      complianceDialog: false,
    };
  },
  computed: {
    ...mapGetters(['activeClient', 'hasFullWorkflowViewPermission']),
    isCanceled() {
      return this.workflow.status === 'canceled';
    },
  },
  methods: {
    async completeAMLCheck() {
      const { compliance_check } = await api.get('/advisor_portal/compliance_check/');
      if (!compliance_check) {
        this.complianceDialog = true;
      } else {
        this.completeAMLCheckDialog = true;
      }
    },
    async manualAMLCheck(approvalDetails) {
      try {
        const res = await sendAMLManualApproval(
          this.workflow,
          this.activeClient.client.id,
          approvalDetails,
        );
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Manual AML Check',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          // TODO: Manually update the front-end, eventually we can use a notification service to know when new data is available
          this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating aml status',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
