<template>
  <v-card :width="width" :class="['pas-list-new', {'pas-list-min-hight': minHeight }]">
    <!-- HEADERS  -->
    <v-layout v-if="columns.length" mb-4 style="width:100%">
      <template v-for="(column, i) in columns">
        <v-flex
          :class="[
              { 'cursor-pointer': column.isSortable && minHeight },
              `strong-text`,
              `${column.size}`,
              { 'pl-3' : (i === 0) },  { 'pr-3' : (i === columns.length-1) }
            ]"
          :key="i"
          @click="column.isSortable ? $emit(`toggleSort`,column) : ''"
        >
          {{column.header}}
          <div :style="{ display: 'inline'}" v-if="column.isSortable && minHeight">
            <v-icon small v-if="column.sortValue === -1">mdi-arrow-up</v-icon>
            <v-icon small v-if="column.sortValue === 1">mdi-arrow-down</v-icon>
          </div>
        </v-flex>
      </template>
    </v-layout>


    <!-- ITEMS -->
    <template v-if="!loading && !items.length">
      <div class="pl-5">No matching {{itemName}}</div>
    </template>
    <template v-else v-for="(item, i) in items">
      <v-card class="my-2 pas-list-item" :key="i">
        <slot :item="item" :list_index="i"></slot>
      </v-card>
    </template>
    <PasCircularLoader v-if="loading"></PasCircularLoader>
  </v-card>
</template>

<script>
export default {
  name: 'pasList',
  props: {
    items: Array,
    loading: Boolean,
    columns: Array,
    itemName: String,
    width: String,
    minHeight: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "~Styles/variables";

.pas-list-min-hight {
  min-height: 541px !important;
}

.pas-list-new {
  background-color: transparent !important;
  box-shadow: none !important;
}
.pas-list-item-text {
  color: $light-text !important;
}
.pas-list-item {
  height: 64px !important;
}
.pas-list-item:last-of-type {
  margin-bottom: 0px !important;
}
.pas-list-item:first-child {
  margin-top: 0px !important;
}
.pas-list-item:hover {
  cursor: pointer !important;
  background-color: #fafafa !important;
}
</style>
