<template>
  <v-checkbox
    :color="primaryColor"
    :label="label"
    :input-value="value"
    :disabled="disabled"
    :readonly="readonly"
    @change="$emit('change', $event)"
    class="pas-checkbox"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PasCheckbox',
  data() {
    return {};
  },
  props: {
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.app.themeVars.primaryColor,
    }),
  },

};
</script>

<style lang="scss">
  .pas-checkbox {
    width: 20px !important;
    i {
      font-size: 20px !important;
    }
  }
</style>
