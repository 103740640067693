<template>
  <auth-page>
    <v-layout column>
      <v-flex>
        <pas-page-title>{{ this.headingText }}</pas-page-title>
      </v-flex>
      <v-flex>
        <div class="light-text">Enter a new password{{ this.subText }} </div>
      </v-flex>
       <v-form
        ref="form"
        lazy-validation
        @submit.prevent
        @keyup.enter.native="resetPassword"
      >
      <v-flex mt-4>
        <v-layout column>

          <div class="strong-text form-label">New Password</div>
          <pas-input-field
            :placeholder="`New Password`"
            v-model="password"
            :rules="[v.isRequired(), v.isValidPassword]"
            type="password"
          ></pas-input-field>
        </v-layout>
      </v-flex>
      <v-flex mt-4>
        <v-layout column>
          <div class="strong-text form-label">Confirm New Password</div>
          <pas-input-field
            :placeholder="`Confirm Password`"
            v-model="confirmPassword"
            :rules="[v.isRequired(), v => (v && password === confirmPassword) || 'Passwords must match']"
            type="password"
          ></pas-input-field>
        </v-layout>
      </v-flex>
      </v-form>
      <v-flex mt-4 mb-2>
        <pas-error-div :show="errorText!==''">{{errorText}}</pas-error-div>
      </v-flex>
      <v-flex mt-1>
        <v-layout>
          <v-flex v-if="isApproved" shrink text-xs-left>
              <div class="light-text">Changed your mind?</div>
              <router-link to="/login" class="action-text">Sign in.</router-link>
          </v-flex>
          <v-spacer v-if="isApproved"></v-spacer>
          <v-flex shrink>
            <pas-button class="px-5" @click="resetPassword" :processing="requestProcessing">
              {{ this.buttonText }}
            </pas-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </auth-page>
</template>

<script>
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage';
import v from 'Services/validation';
import api from 'Services/api';

export default {
  name: 'forgot',
  components: {
    AuthPage,
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.isApproved = urlParams.get('is_active') === 'true';
  },
  data() {
    return {
      v,
      password: '',
      confirmPassword: '',
      errorText: '',
      requestProcessing: false,
      isApproved: false,
      urlParam: '',
    };
  },
  methods: {
    async resetPassword() {
      this.errorText = '';
      if (!(this.$refs.form.validate())) return;
      const urlParams = new URLSearchParams(window.location.search);
      const user_id = urlParams.get('user_id');
      const timestamp = urlParams.get('timestamp');
      const signature = urlParams.get('signature');
      const username = urlParams.get('username');
      const { firm } = this;
      const data = {
        user_id, timestamp, signature, username, password: this.password, firm,
      };

      this.requestProcessing = true;

      await api.post('/advisor_portal/api/confirm_new_password', data)
        .then((res) => {
          if (!res.token) {
            this.errorText = res.err ? res.err : 'An error occurred while updating the password';
            return null;
          }
          this.$store.dispatch('setSnackbar', { text: this.snackbarText, type: 'success' });
          this.$store.dispatch('flipSnackbarSwitch');
          this.$router.push({ name: 'login' });
        })
        .catch((err) => {
          this.errorText = 'Error creating new password.';
          console.log('error in reset password', err);
        });

      this.requestProcessing = false;
    },
  },
  computed: {
    passwords() { return [this.password, this.confirmPassword]; },
    firm() { return this.$store.getters.firm; },
    headingText() { return this.isApproved ? 'Password Reset' : 'Account Activation'; },
    subText() { return this.isApproved ? '.' : ' to activate your account.'; },
    buttonText() { return this.isApproved ? 'Confirm Password' : 'Activate Account'; },
    snackbarText() { return this.Approved ? 'Success! Your password has been updated.' : 'Success! Your account has been activated.'; },
  },
  watch: {
    passwords() { this.$refs.form.resetValidation(); },
  },
};
</script>

<style>

</style>
