/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable no-restricted-syntax */
import api from 'Services/api';
import { fetchInProgressWorkflowsForClient } from 'Services/api/Workflows';

export const createAnnualKycWorkflow = (clientId, dataphileIds) => {
  const payload = {
    message: 'workflow.annual_kyc.create',
    data: {
      client_id: clientId,
      dataphile_client_ids: dataphileIds,
    },
  };
  return api.post('/advisor_portal/send_message', payload);
};

export const sendAnnualKycReminder = (workflowId) => {
  const payload = {
    message: 'workflow.annual_kyc.change_request',
    data: {
      workflow_id: workflowId,
      send_reminder: true,
    },
  };
  return api.post('/advisor_portal/send_message', payload);
};

export const fetchAnnualKycList = (params) => {
  const notifiedValues = {
    all: '',
    not_notified: 'not_notified',
    one_week_ago: '-7,0',
    two_weeks_ago: '-14,-7',
    over_four_weeks_ago: ',-30',
  };

  const queryParams = new URLSearchParams({
    limit: params.limit,
    search_val: params.searchVal,
    notified: notifiedValues[params.notified],
    offset: params.offset,
    sort_by: `due_date,${params.sortDirection}`,
    status: params.status,
    team_ids: params.teamIds,
    client_id: params.clientId,
  });

  const falsyValues = [];
  queryParams.forEach((value, key) => {
    if (['null', 'undefined', ''].includes(value)) {
      falsyValues.push(key);
    }
  });

  falsyValues.forEach((key) => {
    queryParams.delete(key);
  });

  const apiUrl = '/annual_kyc/clients_list/';
  return api.get(`${api.datacaptureUrl()}${apiUrl}?${queryParams}`);
};

export const notifyClients = async (clients) => {
  // the invitation list contains only client with no akyc or akyc in progress step change_request
  const invitationList = clients.map((c) => c.client_id);
  const reminderList = [];

  const dataAkyc = await fetchInProgressWorkflowsForClient(
    invitationList.length,
    invitationList,
    'annual_kyc',
  );

  for (const workflowClient of dataAkyc.results) {
    // client has AKYC workflow started, push change_request to send reminder. Otherwise, start workflow to send invitation
    const remindClientIdx = invitationList.indexOf(workflowClient.client.id);
    reminderList.push({
      client_id: workflowClient.client.id,
      workflow_id: workflowClient.workflow.id,
    });
    invitationList.splice(remindClientIdx, 1);
  }

  const requestList = [];
  for (const client of reminderList) {
    const promise = sendAnnualKycReminder(client.workflow_id);
    requestList.push(promise);
  }
  for (const clientId of invitationList) {
    const promise = createAnnualKycWorkflow(
      clientId,
      clients.find((c) => c.client_id === clientId).client_no_dataphile,
    );
    requestList.push(promise);
  }
  return Promise.allSettled(requestList)
    .then((results) => {
      results.forEach((res) => {
        if (!res.value || res.value.status !== 200) {
          throw new Error('Failed to notified some clients');
        }
      });
    });
};

export const fetchAnnualKycStatusCount = () => api.get(`${api.datacaptureUrl()}/annual_kyc/clients_count_by_status/`);

export const createNoUpdateAkycWorkflow = (clients) => {
  const clientIds = clients.map((c) => c.client_id);
  const promises = [];

  for (const id of clientIds) {
    const messageType = 'workflow.annual_kyc.create';

    const payload = {
      message: messageType,
      data: {
        client_id: id,
        dataphile_client_ids: clients.find((c) => c.client_id === id).client_no_dataphile,
        no_update: true, // skip the survey
        send_email: false, // do not send emails to client / advisor
        completed_date: clients.find((c) => c.client_id === id).completed_date,
      },
    };
    const promise = api.post('/advisor_portal/send_message', payload);
    promises.push(promise);
  }

  return Promise.allSettled(promises)
    .then((res) => {
      res.forEach(({ value }) => {
        if (value['error code']) {
          throw new Error('Failed to mark client\'s Annual KYC as completed');
        }
      });

      return res;
    });
};
