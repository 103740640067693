<template>
    <div>
        <div @click="picker = true">
          <v-layout row child-flex>
            <pas-display-chip
                class="date-range-picker-chip justify-center"
            > {{start ? moment(start).format('YYYY-MM-DD') : ' ---- -- -- '}}
            </pas-display-chip>
            <pas-display-chip
                class="date-range-picker-chip justify-center"
            >{{end ? moment(end).format('YYYY-MM-DD') : ' ---- -- -- '}}
            </pas-display-chip>
            <v-icon
                class="ml-2"
                :style="{ fontSize: '15px' }"
            >mdi-chevron-down</v-icon>
          </v-layout>
        </div>
        <template v-if="picker === true">
            <v-card class="pas-date-range-picker">
                <v-layout row :style="{ borderBottom: '1px solid rgba(0, 0, 0, 0.1) !important'}">
                    <v-spacer></v-spacer>
                    <v-flex shrink :style="{ padding: '20px 10px 0px 0px' }">
                        <span class="light-text">Period:</span>
                    </v-flex>
                    <v-flex shrink>
                        <pas-input-field
                            :value="start ? moment(start).format('ll') : ''"
                            :readonly="true"
                            class="ma-2 date-picker-input"
                            :key="`input-field-start`"
                            :prependInnerIcon="'mdi-calendar-text'"
                        ></pas-input-field>
                    </v-flex>
                    <v-flex shrink>
                        <pas-input-field
                            :value="end ? moment(end).format('ll') : ''"
                            :readonly="true"
                            class="ma-2 date-picker-input"
                            :key="`input-field-end`"
                            :prependInnerIcon="'mdi-calendar-text'"
                        ></pas-input-field>
                    </v-flex>
                </v-layout>
                <v-layout mt-2 row>
                    <v-flex>
                        <v-date-picker
                            v-model="start"
                            no-title
                            :key="`picker-start`"
                            :style="{ boxShadow: 'none !important' }"
                            :color="primaryColor"
                            :max="maxStart"
                        ></v-date-picker>
                    </v-flex>
                    <v-flex>
                        <v-date-picker
                            v-model="end"
                            no-title
                            :key="`picker-end`"
                            :style="{ boxShadow: 'none !important' }"
                            :color="primaryColor"
                            :max="maxEnd"
                            :min="minEnd"
                        ></v-date-picker>
                    </v-flex>
                </v-layout>
                <v-layout
                    row
                    align-center
                    :style="{
                        borderTop: '1px solid rgba(0, 0, 0, 0.1) !important',
                        height: '60px'
                    }"
                >
                    <v-flex>
                        <span class="error--text">{{validationText}}</span>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex mr-2 shrink>
                        <pas-button
                            @click="cancel"
                            theme="inverted"
                            class="px-4"
                        >Cancel</pas-button>
                    </v-flex>
                    <v-flex mr-2 shrink>
                        <pas-button
                            @click="done"
                            theme="primary"
                            class="px-4"
                        >Done</pas-button>
                    </v-flex>
                </v-layout>
            </v-card>
        </template>
    </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'pas-date-range-picker',
  data() {
    return {
      picker: false,
      start: '',
      end: '',
      validationText: '',
      minEnd: '',
      maxEnd: moment().format('YYYY-MM-DD'),
      maxStart: moment().format('YYYY-MM-DD'),
    };
  },
  props: {
    _start: String,
    _end: String,
  },
  mounted() {
    this.start = this._start;
    this.end = this._end;
  },
  computed: {
    dateRange() {
      return [this.start, this.end];
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
  methods: {
    done() {
      this.$emit('input', this.dateRange);
      this.picker = false;
    },
    cancel() {
      this.start = '';
      this.end = '';
      this.picker = false;
    },
  },
  watch: {
    end() {
      this.maxStart = this.end;
    },
    start() {
      this.minEnd = this.start;
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

.pas-date-range-picker {
    position: absolute !important;
    z-index: 2;
    right: 0px;
}
.date-picker-input {
    width: 180px;
    margin-bottom: '4px';
    .v-input__slot {
        padding-left: 10px !important;
        padding-right: 10px !important;
            .v-input__prepend-inner {
            padding-right: 10px !important;
        }
    }
    .v-icon.mdi.mdi-calendar-text.theme--light {
        font-size: 20px;
    }
}
.date-range-picker-chip {
    .v-chip__content{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    background-color: #ddd !important;
    color: #666 !important;
    min-width: 100px !important;
}
</style>
