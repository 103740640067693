import {
  BASE_ACCOUNT_TYPES,
} from 'Services/constants';

const getBaseType = (account) => {
  if (account.base_type_string) return account.base_type_string;
  const accountTypeCode = account.account_type_short;
  const shortAccountLabel = account.account_type_short_description;
  if (!accountTypeCode) {
    return '';
  }
  switch (accountTypeCode) {
    case '5':
    case '6':
    case '7':
    case '8':
      return 'JOINT CASH';
    case '14':
      return 'SPOUSAL RRSP';
    case '17':
    case '18':
      return 'RESP';
    case '20':
      return 'SPOUSAL RRIF';
    case '25':
      return 'CASH ITF';
    case '26':
      return 'JOINT CASH ITF';
    default:
      return BASE_ACCOUNT_TYPES.find(
        (b) => b.slice(0, 3).toUpperCase()
                    === shortAccountLabel.slice(0, 3).toUpperCase(),
      );
  }
};

export default getBaseType;
