<template>
  <PasFullscreenModal :isOpen="isOpen" @close="closeModal">
    <v-container v-show="loading">
      <v-layout align-center class="loader-layout">
      <v-flex>
        <PasCircularLoader :loadingMessage="`It'll Just Be A Moment...`"/>
      </v-flex>
    </v-layout>
    </v-container>
    <v-container v-show="!loading">
      <v-layout mb-2>
        <v-flex>
          <span>
            Some information is required to add this client as a
            Trusted Contact Person. Please fill out the required fields
            below.
          </span>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-container>
          <v-layout column mb-5>
            <v-form v-model="tcpForm.isValid" ref="form">
                <v-layout row mb-4>
                  <v-flex md4 px-1>
                    <div class="strong-text mb-2">{{tcpForm.name.fieldName}}</div>
                    <pas-input-field disabled :value="tcpForm.name.value" />
                  </v-flex>
                  <v-flex md4 px-1>
                    <div class="strong-text mb-2">{{tcpForm.email.fieldName}}</div>
                    <pas-input-field :rules="tcpForm.email.rules" v-model="tcpForm.email.value" />
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex md4 px-1>
                    <div class="strong-text mb-2">
                      {{tcpForm.homePhone.fieldName}}
                    </div>
                    <pas-phone-input :rules="getPhoneRules(tcpForm.homePhone.fieldName)" v-model="tcpForm.homePhone.value"/>
                  </v-flex>
                  <v-flex md4 px-1>
                    <div class="strong-text mb-2">
                      {{tcpForm.workPhone.fieldName}}
                    </div>
                    <pas-phone-input :rules="getPhoneRules(tcpForm.workPhone.fieldName)" v-model="tcpForm.workPhone.value"/>
                  </v-flex>
                  <v-flex md4 px-1>
                    <div class="strong-text mb-2">
                      {{tcpForm.mobilePhone.fieldName}}
                    </div>
                    <pas-phone-input :rules="getPhoneRules(tcpForm.mobilePhone.fieldName)" v-model="tcpForm.mobilePhone.value"/>
                  </v-flex>
                </v-layout>
            </v-form>
          </v-layout>
          <v-layout column>
            <v-flex mb-3><h3>Addresses</h3></v-flex>
            <addresses
              class="pt-4"
              :client="tcpClient"
              :_addresses="tcpForm.addresses.active"
              modalMode
              noTitle
              @addressesChanged="updateAddresses"
              @addressesValidated="isAddressValid = $event"
            />
          </v-layout>
        </v-container>
      </v-layout>
      <v-layout row>
        <v-layout justify-start>
          <pas-button
            @click="closeModal()"
            class="inverted-btn"
          >
            Cancel
          </pas-button>
        </v-layout>
        <v-layout justify-end>
          <pas-button
            @click="submitAndClose"
            class="primary-btn"
            :disabled="!isTcpInfoValid"
            :processing="isProcessingTcpForm"
          >
            Save
          </pas-button>
        </v-layout>
      </v-layout>
    </v-container>
  </PasFullscreenModal>
</template>

<script>
import Addresses from 'Components/Shared/Addresses';
import v from 'Services/validation';
import apiv2 from 'Services/apiV2';

export default {
  name: 'trusted-contact-person-info',
  components: {
    Addresses,
  },
  props: {
    tcpId: String,
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      tcpClient: {},
      isProcessingTcpForm: false,
      isAddressValid: true,
      tcpForm: {
        isValid: true,
        name: { fieldName: 'Name', value: null },
        email: { fieldName: 'E-mail', rules: [v.isValidEmail], value: null },
        homePhone: { fieldName: 'Home Phone Number', rules: [], value: null },
        workPhone: { fieldName: 'Work Phone Number', rules: [], value: null },
        mobilePhone: { fieldName: 'Mobile Phone Number', rules: [], value: null },
        addresses: { active: [], deleted: [] },
      },
    };
  },
  mounted() {
    this.isOpen = true;
  },
  computed: {
    isTcpInfoValid() {
      return this.tcpForm.isValid && this.isAddressValid;
    },
  },
  methods: {
    validateForm() {
      if (this.$refs.form) this.$refs.form.validate();
    },
    getPhoneRules(fieldName) {
      const rules = [];
      const fields = [
        this.tcpForm.homePhone,
        this.tcpForm.mobilePhone,
        this.tcpForm.workPhone,
      ];

      const otherPhoneFields = fields.filter((f) => f.fieldName !== fieldName);

      const hasAnotherPhone = otherPhoneFields.some((f) => !!f.value);

      if (hasAnotherPhone) {
        rules.push(v.emptyOrPhone);
      } else {
        rules.push(v.isRequired(), v.isValidPhone);
      }

      return rules;
    },
    setSnackBar(text, type) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    closeModal(isInfoUpdated = false) {
      this.isOpen = false;
      this.$emit('close', isInfoUpdated);
    },
    async fetchClient() {
      try {
        const tcpClient = await apiv2.get(`/client_all_details/${this.tcpId}`);

        this.tcpClient = tcpClient;

        this.tcpForm.name.value = tcpClient.display_name;
        this.tcpForm.email.value = tcpClient.email;
        this.tcpForm.homePhone.value = tcpClient.home_number;
        this.tcpForm.workPhone.value = tcpClient.office_number;
        this.tcpForm.mobilePhone.value = tcpClient.mobile_number;
        this.tcpForm.addresses.active = tcpClient.ClientAddresses;
        this.validateForm();
      } catch (e) {
        this.setSnackBar('Failed to fetch client\'s information', 'error');
        this.closeModal();
      }
    },
    updateAddresses(addresses) {
      this.tcpForm.addresses = addresses;
    },
    async submitAndClose() {
      this.isProcessingTcpForm = true;

      try {
        await this.$store.dispatch('updatePersonClient', {
          id: this.tcpId,
          data: {
            email: this.tcpForm.email.value,
            home_number: this.tcpForm.homePhone.value,
            mobile_number: this.tcpForm.mobilePhone.value,
            office_number: this.tcpForm.workPhone.value,
          },
          refetch: false,
        });

        const allAddresses = [
          ...this.tcpForm.addresses.active.map(
            (address) => this.$store.dispatch(
              address.id && !address.id.includes('temp') ? 'updateAddress' : 'addAddress',
              address,
            ),
          ),
          ...this.tcpForm.addresses.deleted.map(
            (address) => this.$store.dispatch(
              'deleteAddress',
              address,
            ),
          ),
        ];

        const responses = await Promise.all(allAddresses);
        responses.forEach((res) => {
          if (res.code && res.code !== 200) {
            throw new Error('Address failed to update');
          }
        });

        this.setSnackBar('Information updated successfully', 'success');
        this.closeModal(true);
      } catch (e) {
        this.setSnackBar('Failed to update information', 'error');
        this.closeModal();
      } finally {
        this.isProcessingTcpForm = false;
      }
    },
  },
  watch: {
    async isOpen(value) {
      if (value) {
        this.loading = true;
        await this.fetchClient();
        this.loading = false;
      }
    },
    'tcpForm.homePhone.value': 'validateForm',
    'tcpForm.workPhone.value': 'validateForm',
    'tcpForm.mobilePhone.value': 'validateForm',
  },
};
</script>

<style>

</style>
