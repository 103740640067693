
<template>
  <v-container>
    <v-card class="pa-5">
      <h3 class="subheader-text mt-3">{{greeting}}, {{currentUser.first_name}}</h3>
      <span>Here's what you have going on today.</span>
      <template v-if="transfersFeatureEnabled">
        <h3 class="subheader-text mt-5">Transfers</h3>
        <span v-if="!loadingTransfers"> Your clients have transferred ${{transferredPastMonth}} in the past 60 days.</span>
        <span v-else> Please wait while we are loading your transfers.</span>
        <span class="action-link" :style="{ float: 'right' }" @click="$router.push('/transfers/') ">View all transfers</span>

        <TransferList
          :transfers="loadingTransfers ? [] : filteredTransfers"
          :loading="loadingTransfers"
          :minHeight="false"
          class="mt-3"
        />

        <v-layout v-if="transfersPaginationLength > 1">
          <v-flex xs12 text-xs-center mt-4>
            <PasPagination
              :length="transfersPaginationLength"
              @input="transfersPage = $event"
              :value="transfersPage"
            />
          </v-flex>
        </v-layout>
      </template>

      <div v-if="canViewWorkflowsDashboard">
        <h1 class="mt-5 mb-2">Workflows</h1>
        <div class="mb-5"
             v-if="loadingWorkflows || (!loadingWorkflows && totalNeedsAttentionWorkflows > 0)"
        >
          <h2 class="subheader-text mt-2">Needs Attention</h2>
          <span v-if="loadingWorkflows"> Please wait while we are loading your workflows.</span>
          <WorkflowList
            :loading="loadingWorkflows"
            :workflows="loadingWorkflows ? [] : needsAttentionWorkflows"
            :minHeight="false"
            class="mt-3"
          />
          <pas-button
            v-if="!loadingWorkflows && totalNeedsAttentionWorkflows > 0"
            class="primary-btn wide-btn mt-3"
            @click="$router.push({path: '/workflows', query: {status: 'needs_attention'}})"
          >
            {{`View all ${totalNeedsAttentionWorkflows}`}}
          </pas-button>
        </div>
        <div class="mb-4"
             v-if="loadingWorkflows || (!loadingWorkflows && totalInProgressWorkflows > 0)"
        >
          <h2 class="subheader-text mt-2">In Progress</h2>
          <span v-if="loadingWorkflows"> Please wait while we are loading your workflows.</span>
          <WorkflowList
             :loading="loadingWorkflows"
             :workflows="loadingWorkflows ? [] : inProgressWorkflows"
             :minHeight="false"
             class="mt-3"
           />
          <pas-button
            v-if="!loadingWorkflows && totalInProgressWorkflows > 0"
            class="primary-btn wide-btn mt-3"
            @click="$router.push({path: '/workflows', query: {status: 'in_progress'}})"
          >
            {{`View all ${totalInProgressWorkflows}`}}
          </pas-button>
        </div>
        <div
          v-if="!loadingWorkflows
            && totalInProgressWorkflows === 0
            && totalNeedsAttentionWorkflows === 0"
          class="flex justify-center"
        >
          <div>
            <span>No workflows that need your attention.</span>
          </div>
          <pas-button
            class="primary-btn wide-btn mt-3"
            @click="$router.push('/workflows/')"
          >
            View All Workflows
          </pas-button>
        </div>
      </div>

    </v-card>
  </v-container>
</template>

<script>

// libraries and services
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
  fetchTopInProgressWorkflows,
  fetchTopNeedsAttentionWorkflows,
} from 'Services/api/Workflows';
import { modal } from 'Services/mixins';
import { commaFormatted } from 'Services/helpers/currency';

// components
import TransferList from 'Components/Vue-Routes/MyTransfers/TransferList';
import WorkflowList from './DashboardV2/WorkflowList';

export default {
  name: 'dashboardV2',
  components: {
    WorkflowList,
    TransferList,
  },
  mixins: [modal],
  mounted() {
    Promise.all([
      this.$store.dispatch('getAtonTransfers'),
      fetchTopInProgressWorkflows(),
      fetchTopNeedsAttentionWorkflows(),
    ]).then((res) => {
      this.totalInProgressWorkflows = res[1].totalCount;
      this.inProgressWorkflows = res[1].workflows;
      this.totalNeedsAttentionWorkflows = res[2].totalCount;
      this.needsAttentionWorkflows = res[2].workflows;
      this.loadingWorkflows = false;
      this.filterTransfers();
    }).catch(() => {
      this.loadingWorkflows = false;
      this.snackBar('fail', 'We had trouble fetching data. Please reload the page');
    });
  },
  data() {
    return {
      inProgressWorkflows: [],
      totalInProgressWorkflows: 0,
      needsAttentionWorkflows: [],
      totalNeedsAttentionWorkflows: 0,
      loadingWorkflows: true,
      filteredTransfers: ['loading'],
      perPage: 5,
      transfersPaginationLength: 1,
      transfersPage: 1,
    };
  },
  computed: {
    ...mapGetters([
      'canViewWorkflowsDashboard',
    ]),
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    currentUserRepCodes() {
      return this.$store.state.users.currentUserRepCodes;
    },
    transfers() {
      return this.$store.state.clients.atonTransfers;
    },
    loadingTransfers() {
      return this.filteredTransfers.length && this.filteredTransfers[0] === 'loading';
    },
    transferredPastMonth() {
      const earliestDate = moment().subtract(60, 'days');
      const filteredTransfers = this.transfers.filter((t) => t.date && moment(t.date).isAfter(earliestDate.format('YYYY-MM-DD')));
      return commaFormatted(filteredTransfers.reduce((acc, t) => acc + t.amount_estimated, 0));
    },
    greeting() {
      const myDate = new Date();
      const hrs = myDate.getHours();
      if (hrs < 12) return 'Good Morning';
      if (hrs >= 12 && hrs <= 17) return 'Good Afternoon';
      if (hrs >= 17 && hrs <= 24) return 'Good Evening';
    },
    transfersFeatureEnabled() {
      return this.$store.state.app.features.aton_transfers;
    },
  },
  methods: {
    filterTransfers() {
      const transfers = _.cloneDeep(this.transfers);
      this.transfersPaginationLength = transfers.length ? Math.ceil(transfers.length / this.perPage) : 1;
      const start = (this.transfersPage - 1) * this.perPage;
      const end = this.transfersPage * this.perPage;
      this.filteredTransfers = transfers.slice(start, end);
    },

  },
  watch: {
    transfersPage() {
      this.filterTransfers();
    },
  },
};
</script>

<style lang="scss">
</style>
