// Handle modal flow that is used in the modalWrapper
import * as AllFlows from '../../components/Vue-Routes/Householding/constants';

const defaultState = {
  currentFlow: null,
  currentStage: null,
  currentModal: null,
};

const mutations = {
  SET_CURRENT_FLOW(state, flowName) {
    state.currentFlow = flowName;
  },
  CLEAR_CURRENT_FLOW(state) {
    state.currentFlow = null;
  },
  SET_CURRENT_STAGE(state, currentStage) {
    state.currentStage = currentStage;
  },
  CLEAR_CURRENT_STAGE(state) {
    state.currentFlow = null;
  },
  SET_CURRENT_MODAL(state, modalName) {
    state.currentModal = modalName;
  },
  CLEAR_CURRENT_MODAL(state) {
    state.currentModal = null;
  },
};

const actions = {
  setFlow({ commit }, { flow }) {
    commit('SET_CURRENT_FLOW', flow);
  },
  clearFlow({ commit }) {
    commit('CLEAR_CURRENT_FLOW');
  },
  setStage({ commit }, { stage }) {
    commit('SET_CURRENT_STAGE', stage);
  },
  clearStage({ commit }) {
    commit('CLEAR_CURRENT_STAGE');
  },
  setModal({ commit }, { modal }) {
    commit('SET_CURRENT_MODAL', modal);
  },
  clearModal({ commit }) {
    commit('CLEAR_CURRENT_MODAL');
  },
  openModal({ commit }, { flow, stage, modal }) {
    commit('SET_CURRENT_FLOW', flow);
    commit('SET_CURRENT_STAGE', stage);
    commit('SET_CURRENT_MODAL', modal);
  },
  closeModal({ commit }) {
    commit('CLEAR_CURRENT_FLOW');
    commit('CLEAR_CURRENT_STAGE');
    commit('CLEAR_CURRENT_MODAL');
  },
  goToNextModal({ commit, state }, { currentModal, stage, flow }) {
    const currentModalIndex = AllFlows[flow][stage].indexOf(currentModal);
    const nextModal = AllFlows[flow][stage][currentModalIndex + 1];
    if (nextModal) {
      commit('SET_CURRENT_MODAL', nextModal);
    } else {
      commit('CLEAR_CURRENT_MODAL');
    }
  },
  goToPreviousModal({ commit, state }, { currentModal, stage, flow }) {
    const currentModalIndex = AllFlows[flow][stage].indexOf(currentModal);
    const previousModal = AllFlows[flow][stage][currentModalIndex - 1];
    if (previousModal) {
      commit('SET_CURRENT_MODAL', previousModal);
    } else {
      commit('CLEAR_CURRENT_MODAL');
    }
  },
};

export const getters = {
  currentFlow: (state) => state.currentFlow,
  currentStage: (state) => state.currentStage,
  currentModal: (state) => state.currentModal,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
