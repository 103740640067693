<template>
  <div>
    <v-expansion-panel v-model="panel" expand>
      <v-expansion-panel-content>
        <div slot="header">
          <PasPageSubtitle>
            <slot :name="`panelTitle`"/>
          </PasPageSubtitle>
        </div>
        <slot :name="`panelContent`"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
export default {
  name: 'PasAccordion',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: this.expanded ? [true] : [false],
    };
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  margin-bottom: 2px;
  box-shadow: none !important;
  .v-expansion-panel__header {
    box-shadow: 0 2px 3px rgba(0,0,0,0.1) !important;
    flex-direction: row-reverse;
    .v-expansion-panel__header__icon {
      margin-right: 8px;
    }
  }
  .v-expansion-panel__container--active {
    .v-expansion-panel__header {
      background-color: rgb(250, 250, 250);
    }
  }
}
</style>
