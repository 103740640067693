<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent
    >
      <pas-page-subtitle v-if="!noTitle" class="px-2 mb-4">Financial Details</pas-page-subtitle>
      <v-layout wrap>
        <v-flex
          v-for="(item, i) in overview"
          :key="i"
          xs6 md4
          px-2 mb-3
          v-if="displyCheck(item)"
        >
          <div class="strong-text mb-3">{{item.name}}</div>
          <pas-autocomplete
            :items="item.options"
            v-model="item.value"
            v-if="item.name === `Income Source`"
            :rules="[v.isRequired()]"
            :readonly="disabled"
          ></pas-autocomplete>
          <money
            v-else
            :class="[ disabled ? 'greyed-out' : '', 'currency-input']"
            class="currency-input"
            v-model="item.value"
            v-bind="money"
            :rules="[v.isRequired()]"
            :disabled="disabled"
          >{{item.value}}</money>
        </v-flex>
        <v-flex
          xs6 md4
          px-2 mb-3
          v-if="displayIncomeDetails"
        >
          <div class="strong-text mb-3">{{incomeSourceDetails.name}}</div>
          <pas-input-field
            v-model="incomeSourceDetails.value"
            :rules="[v.maxChars(40)]"
            :readonly="disabled"
          ></pas-input-field>
        </v-flex>
      </v-layout>
      <pas-button
        class="primary-btn wide-btn mx-2 mb-2"
        @click="saveFinancialProfile"
        :processing="processingFinancials"
        v-if="!modalMode && editable"
      >Save</pas-button>
      <!-- v-if="clientType !== 'person' && !modalMode" -->
    </v-form>
  </div>
</template>

<script>

import v from 'Services/validation';
import { Money } from 'v-money';
import { INCOME_SOURCE } from 'Services/constants';

export default {
  name: 'financial-modal',
  components: {
    Money,
  },
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const incomeSourceItems = Object.keys(INCOME_SOURCE).map((x) => ({ text: INCOME_SOURCE[x], value: x }));
    return {
      overview: {
        expectedIncome: { name: 'Expected Annual Income', value: 0, clientType: ['person', 'corporation'] },
        incomeSource: {
          name: 'Income Source',
          value: null,
          clientType: ['person'],
          options: incomeSourceItems,
        },
        liquidAssets: { name: 'Liquid Assets', value: 0, clientType: ['person', 'corporation'] },
        fixedAssets: { name: 'Fixed Assets', value: 0, clientType: ['person', 'corporation'] },
        mortgageDebt: { name: 'Mortgage Debt', value: 0, clientType: ['person'] },
        consumerDebt: { name: 'Consumer Debt', value: 0, clientType: ['person'] },
        totalDebt: { name: 'Total Debt', value: 0, clientType: ['corporation'] },
        incomeSource: {
          name: 'Income Source',
          value: null,
          clientType: ['person'],
          options: incomeSourceItems,
        },
      },
      incomeSourceDetails: {
        name: 'Income Source Details',
        value: null,
        clientType: ['person'],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      v,
      processingFinancials: false,
      isInit: true,
      isEdited: false,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    clientType() {
      return this.activeClient.client && this.activeClient.client.client_type;
    },
    disabled() {
      return !this.editable;
      // return !this.modalMode && this.clientType === 'person';
    },
    displayIncomeDetails() {
      if (this.overview.incomeSource.value && this.overview.incomeSource.value !== 'Other') {
        return false;
      }
      return true;
    },
    kycFeatureFlag() {
      return this.$store.getters.hasFeatureFlag('client_maintenance') || this.$store.getters.hasFeatureFlag('annual_kyc');
    },
  },
  methods: {
    displyCheck(item) {
      if (item.name === 'Income Source' && this.modalMode) return false;
      return item.clientType.includes(this.clientType);
    },
    handleResponse(type) {
      const text = `${type === 'fail' ? 'Failed to update' : 'Successfully updated'} Financial Details`;
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type === 'fail' ? 'fail' : 'success');
    },
    async saveFinancialProfile() {
      if (this.kycFeatureFlag && this.$store.getters.hasSubmittedKycChangeRequest) {
        this.$store.commit('OPEN_MODAL', 'cannot-edit-profile');
        return;
      }
      if (!this.$refs.form.validate()) return null;
      this.processingFinancials = true;
      let totalDebt;
      if (this.clientType === 'person') {
        totalDebt = Number(this.overview.mortgageDebt.value) + Number(this.overview.consumerDebt.value);
      } else {
        totalDebt = Number(this.overview.totalDebt.value);
      }
      const netWorth = Number(this.overview.liquidAssets.value) + Number(this.overview.fixedAssets.value) - totalDebt;
      const incomeSource = this.overview.incomeSource.value === 'Other' ? this.incomeSourceDetails.value : this.overview.incomeSource.value;
      const data = {
        expected_income: this.overview.expectedIncome.value,
        income_source: incomeSource,
        liquid_assets: this.overview.liquidAssets.value,
        fixed_personal_assets: this.overview.fixedAssets.value,
        mortgage_debt: this.overview.mortgageDebt.value,
        consumer_debt: this.overview.consumerDebt.value,
        net_worth: netWorth,
        total_debt: totalDebt,
        client: this.activeClient.client.id,
        financials: this.activeClient.financials.id || null,
      };
      const callType = this.activeClient.financials.id ? 'updateFinancials' : 'createFinancials';
      await this.$store.dispatch(callType, data)
        .then((res) => this.handleResponse(res.client ? 'success' : 'fail'))
        .catch((err) => this.handleResponse('fail'));
      this.processingFinancials = false;
    },
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        let client = {};
        if (!this.isEdited) {
          client = { ...this.activeClient };
          this.overview.expectedIncome.value = Math.round(client.financials.expected_income) || 0;
          this.overview.liquidAssets.value = Math.round(client.financials.liquid_assets) || 0;
          this.overview.fixedAssets.value = Math.round(client.financials.fixed_personal_assets) || 0;
          this.overview.mortgageDebt.value = Math.round(client.financials.mortgage_debt) || 0;
          this.overview.consumerDebt.value = Math.round(client.financials.consumer_debt) || 0;
          this.overview.totalDebt.value = Math.round(client.financials.total_debt) || 0;
          if (client.financials.income_source && !Object.keys(INCOME_SOURCE).includes(client.financials.income_source)) {
            this.overview.incomeSource.value = 'Other';
            this.incomeSourceDetails.value = client.financials.income_source;
          } else {
            this.overview.incomeSource.value = client.financials.income_source || null;
          }
        }
      },
      deep: true,
    },
    overview: {
      deep: true,
      handler() {
        for (const item in this.overview) {
          if (this.overview[item].name !== 'Income Source' && this.overview[item].value < 0) { this.overview[item].value = this.overview[item].value * (-1); }
        }
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
    },
  },
  mounted() {
    this.isEdited = false;
    this.isInit = true;
    const client = { ...this.activeClient };
    if (client.financials) {
      this.overview.expectedIncome.value = Math.round(client.financials.expected_income) || 0;
      this.overview.liquidAssets.value = Math.round(client.financials.liquid_assets) || 0;
      this.overview.fixedAssets.value = Math.round(client.financials.fixed_personal_assets) || 0;
      this.overview.mortgageDebt.value = Math.round(client.financials.mortgage_debt) || 0;
      this.overview.consumerDebt.value = Math.round(client.financials.consumer_debt) || 0;
      this.overview.totalDebt.value = Math.round(client.financials.total_debt) || 0;
      if (client.financials.income_source && !Object.keys(INCOME_SOURCE).includes(client.financials.income_source)) {
        this.overview.incomeSource.value = 'Other';
        this.incomeSourceDetails.value = client.financials.income_source;
      } else {
        this.overview.incomeSource.value = client.financials.income_source || null;
      }
    }
  },
};
</script>

<style lang="scss">
.checkbox-left{
  width: 30px;
}
.v-input.checkbox-left.v-input--selection-controls.v-input--checkbox.v-input--is-disabled.theme--light {
  margin-top: 0px !important;
}
</style>
