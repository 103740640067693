<template>
  <v-form ref="form" lazy-validation @submit.prevent id="add-edit-address-form">
    <v-layout wrap row>
      <v-flex v-for="(item,i) in firstSection" :key="i" :class="`${item.class}`">
        <div class="strong-text mb-3 mt-3">{{item.text}}</div>
        <pas-input-field-list
          :items="getItems(item.text)"
          v-model="item.value"
          v-if="item.type === 2 && item.text !== 'Country' && item.text !== 'City'"
          :rules="item.rules"
          :placeholder="item.placeholder"
        ></pas-input-field-list>
        <pas-input-field-list
          :items="countries"
          v-model="item.value"
          v-if="item.text === 'Country'"
          :rules="item.rules"
          :placeholder="item.placeholder"
          @input="firstSection.province.value = ''"
        ></pas-input-field-list>
        <pas-input-field
          v-model="item.value"
          v-if="item.type === 2 && item.text === 'City'"
          :rules="item.rules"
        ></pas-input-field>
        <pas-input-field v-model="item.value" :maxLength="item.maxLength" :rules="item.rules" v-if="item.type === 1"></pas-input-field>
      </v-flex>
    </v-layout>
    <div class="pt-4">
      <span class="error--text">{{validationText}}</span>
    </div>
    <v-layout row px2 mt-3 v-if="!noButtons">
      <v-flex md2>
        <pas-button class="inverted-btn wide-btn" @click="close">Cancel</pas-button>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2>
        <pas-button class="primary-btn wide-btn" @click="updateAddress" id="save-address-info-btn">Update</pas-button>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import countriesCities from 'full-countries-cities';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import { PROVINCES } from 'Services/constants';

export default {
  name: 'AddEditAddress',
  mixins: [modal],
  props: {
    address: Object,
    client: Object,
    noButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstSection: {
        type: {
          text: 'Address Type',
          value: '',
          class: 'px-2 md4',
          type: 2,
          rules: [v.isRequired()],
          placeholder: 'Select from the list',
        },
        firstAddressLine: {
          text: 'Address Line 1',
          value: '',
          class: 'px-2 md4',
          type: 1,
          rules: [v.isRequired()],
        },
        secondAddressLine: {
          text: 'Address Line 2',
          value: '',
          class: 'px-2 md4',
          type: 1,
          rules: [],
        },
        country: {
          text: 'Country',
          value: '',
          class: 'px-2 md4',
          type: 2,
          rules: [v.isRequired()],
          placeholder: 'Select from the list',
        },
        province: {
          text: 'Province/State',
          value: '',
          class: 'px-1 md2',
          type: 1,
          rules: [v.emptyOrSpecifiedChars(2)],
          maxLength: 2,
        },
        city: {
          text: 'City',
          value: '',
          class: 'px-2 md4',
          type: 2,
          rules: [v.isRequired()],
          placeholder: 'Select the country first',
        },
        postalCode: {
          text: 'Postal Code',
          value: '',
          class: 'px-2 md2',
          type: 1,
          rules: [v.isRequired(), v.maxChars(6)],
          maxLength: 6,
        },
      },
      v,
      validationText: '',
    };
  },
  mounted() {
    this.populateForm();
  },
  computed: {
    countries() {
      return countriesCities.getCountryNames();
    },
    cities() {
      return this.firstSection.country.value ? countriesCities.getCities(this.firstSection.country.value) : [];
    },
    clientType() {
      return this.client ? this.client.client_type : '';
    },
    types() {
      const personChoices = [
        { text: 'Personal', value: 'P' },
        { text: 'Employment', value: 'E' },
      ];
      const corpChoices = [{ text: 'Corporate', value: 'C' }];
      return this.clientType === 'person' ? personChoices : corpChoices;
    },
    provinces() {
      return Object.keys(PROVINCES);
    },
  },
  methods: {
    updateAddress() {
      if (!this.$refs.form.validate()) {
        this.validationText = 'Please review any requirements above and try again.';
        return;
      }
      this.validationText = '';
      const address = { ...this.address };
      address.id = this.address.id;
      address.address_line_1 = this.firstSection.firstAddressLine.value;
      address.address_line_2 = this.firstSection.secondAddressLine.value || null;
      address.city = this.firstSection.city.value;
      address.province = this.firstSection.province.value ? this.firstSection.province.value.toUpperCase() : null;
      address.postal_code = this.firstSection.postalCode.value.replace(/\s/g, '').toUpperCase();
      address.country = this.firstSection.country.value;
      address.client_address_type = this.firstSection.type.value;
      address.client = this.client.id;
      delete address.new;
      this.$emit('updateAddress', address);
    },
    populateForm() {
      if (this.address && this.address.new) return;
      const address = { ...this.address };
      this.firstSection.firstAddressLine.value = address.address_line_1 || '';
      this.firstSection.secondAddressLine.value = address.address_line_2 || '';
      this.firstSection.city.value = address.city || '';
      this.firstSection.province.value = address.province || '';
      this.firstSection.postalCode.value = address.postal_code || '';
      this.firstSection.country.value = address.country || '';
      this.firstSection.type.value = address.client_address_type || '';
    },
    getItems(item) {
      if (this.firstSection.country.value === 'Canada' && item === 'Province/State') {
        return this.provinces;
      } if (item === 'Address Type') {
        return this.types;
      }
    },
  },
  watch: {
    address: {
      handler() { this.populateForm(); },
      deep: true,
    },
    'firstSection.country.value': {
      handler() {
        this.firstSection.country.value === 'Canada' ? this.firstSection.province.type = 2 : this.firstSection.province.type = 1;
        if (this.firstSection.country.value === 'Canada' || this.firstSection.country.value === 'United States') {
          this.firstSection.province.rules = [v.emptyOrSpecifiedChars(2), v.isRequired()];
        } else {
          this.firstSection.province.rules = [v.emptyOrSpecifiedChars(2)];
        }
      },
    },
  },
};
</script>

<style lang="scss">
</style>
