<template>
  <v-container>
    <div class="my-4">
      <PasPageTitle class="my-4" :style="{ display: 'inline'}">All Transfers</PasPageTitle>
      <div class="subheading grey--text" :style="{ display: 'inline'}">({{searchResults}})</div>
    </div>

    <v-layout align-center mb-4>
      <v-flex xs5 md3 >
        <PasSearchBox v-model="filterOptions.search"/>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="sort-by-text" shrink> Status: </v-flex>
      <v-flex shrink :style="{ maxWidth: '150px' }">
        <PasDropDown
          :value="filterOptions.status"
          @change="$set(filterOptions, 'status', $event)"
          class="transfers-sorter"
          :items="statuses"
        />
      </v-flex>
      <v-flex class="sort-by-text" shrink> Period: </v-flex>
      <v-flex shrink>
        <PasDateRangePicker
          @input="$set(filterOptions, 'sortByPeriod', $event)"
          :style="{ width: '240px !important', marginBottom: '4px' }"
          :_start="filterOptions.sortByPeriod[0]"
          :_end="filterOptions.sortByPeriod[1]"
        />
      </v-flex>
    </v-layout>

    <TransferList
      :transfers="filteredTransfers"
      :loading="loading"
      @toggleSort="toggleSort($event)"
      :sortBy="filterOptions.sortBy"
      :sortDir="filterOptions.sortDir"
    />

    <v-flex xs12 text-xs-center mt-4>
      <PasPagination
        :length="paginationLength"
        @input="filterOptions.page = $event"
        :value="filterOptions.page"
      />
    </v-flex>
  </v-container>
</template>

<script>

// Components
import TransferList from 'Components/Vue-Routes/MyTransfers/TransferList';

// Libraries and services
import _ from 'lodash';
import moment from 'moment';
import { ATON_TRANSFER } from 'Services/constants';

export default {
  name: 'MyTransfers',
  components: {
    TransferList,
  },
  data() {
    return {
      filteredTransfers: [],
      filterOptions: {
        search: '',
        status: 'All',
        sortBy: '',
        sortDir: -1,
        page: 1,
        perPage: 15,
        sortByPeriod: [
          moment().subtract(1, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      },
      statuses: ['All', 'New', 'Pending', 'Cancelled', 'Completed'],
      paginationLength: 1,
      searchResults: 0,
      loading: false,
    };
  },
  computed: {
    transfers() {
      return this.$store.state.clients.atonTransfers;
    },
    computedFilterOptions() {
      return { ...this.filterOptions };
	  },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch('getAtonTransfers');
    this.loading = false;
    this.filterAndSort();
  },
  methods: {
    toggleSort({ sortBy, sortDir }) {
      this.filterOptions.sortBy = sortBy;
      this.filterOptions.sortDir = sortDir;
    },
    filterAndSort() {
      const opts = this.filterOptions;
      this.filteredTransfers = _.cloneDeep(this.transfers);

      // search filter
      if (opts.search) {
        this.filteredTransfers = this.filteredTransfers.filter((c) => c.account_holder && c.account_holder.toLowerCase().indexOf(opts.search.toLowerCase()) !== -1);
      }

      // status filter
      let matchingstatuses = [];
      if (opts.status) {
        if (opts.status === 'New') matchingstatuses = ['NEW'];
        if (opts.status === 'Pending') matchingstatuses = ['CONF', 'CONP', 'INIT'];
        if (opts.status === 'Cancelled') matchingstatuses = ['DEL', 'REJ', 'RESC'];
        if (opts.status === 'Completed') matchingstatuses = ['COMP'];
        if (opts.status === 'All') matchingstatuses = ['NEW', 'CONF', 'CONP', 'INIT', 'DEL', 'REJ', 'RESC', 'COMP'];
      }
      this.filteredTransfers = this.filteredTransfers.filter((t) => t.transfer_status && matchingstatuses.includes(t.transfer_status.toUpperCase()));

      // date filter
      if (opts.sortByPeriod[0] && opts.sortByPeriod[1]) {
        this.filteredTransfers = this.filteredTransfers.filter((t) => {
          const after = moment(opts.sortByPeriod[0]);
          const before = moment(opts.sortByPeriod[1]);
          const { date } = t;
          return moment(date).isAfter(after) && moment(date).isBefore(before);
        });
      }
      this.filteredTransfers = _.sortBy(this.filteredTransfers, (x) => x.date).reverse();

      // sort filter
      if (opts.sortBy) {
        if (opts.sortBy === 'Customer Name') {
          this.filteredTransfers = this.filteredTransfers.sort((a, b) => (a.account_holder.toLowerCase().trim() < b.account_holder.toLowerCase().trim() ? opts.sortDir : opts.sortDir * -1));
        }
        if (opts.sortBy === 'Date') {
          this.filteredTransfers = this.filteredTransfers.sort((a, b) => (moment(b.date).isSameOrAfter(moment(a.date)) ? opts.sortDir : opts.sortDir * -1));
        }
        if (opts.sortBy === 'Amount') {
          this.filteredTransfers = this.filteredTransfers.sort((a, b) => (parseInt(a.amount_estimated) < parseInt(b.amount_estimated) ? opts.sortDir : opts.sortDir * -1));
        }
      }

      // display results count
      this.searchResults = this.filteredTransfers.length;

      // pagination
      this.paginationLength = this.filteredTransfers ? Math.ceil(this.filteredTransfers.length / this.filterOptions.perPage) : 1;
      const iStart = (opts.page - 1) * opts.perPage;
      const iEnd = opts.page * opts.perPage;
      this.filteredTransfers = this.filteredTransfers.slice(iStart, iEnd);
    },
  },
  watch: {
    computedFilterOptions: {
      deep: true,
      handler(oldVal, newVal) {
        if (newVal.page === oldVal.page) this.filterOptions.page = 1;
        this.filterAndSort();
      },
    },
  },

};
</script>

<style lang="scss">
  .transfers-sorter {
    .v-input__slot {
      margin: 0px 10px 0px 10px;
    }
  }
  .sort-by-text {
    color: rgba(0,0,0,0.5) !important;
    font-size: 14px;
    margin-bottom: 4px;
    width: 50px !important;
  }
</style>
