import api from 'Services/api';
import apiV2 from 'Services/apiV2';

export const fetchFeeGroup = (clientId) => api.get(`/datahub/client/fee_group/${clientId}/`)
  .then((res) => (res.status === 'success' ? res.result : false))
  .catch((err) => false);

export const createFeeGroup = (data) => api.post('/datahub/fee_groups/', data);
export const deleteFeeGroup = (id) => api.deleteCall(`/datahub/fee_groups/${id}/`);
export const updateFeeGroup = (data) => api.post(`/datahub/fee_groups/${data.id}/`, data);

export const joinFeeGroup = (data) => api.post('/datahub/client_fee_groups/', data);
export const leaveFeeGroup = (id) => api.deleteCall(`/datahub/client_fee_groups/${id}/`);

export const fetchDebitAccounts = (id) => {
  return apiV2.get(`/get_debit_accounts/?client_id=${id}`)
    .then((res) => {
      return res.debit_accounts || []
    })
    .catch((err) => []);
}
export const fetchPlanningSchedules = () => apiV2.get('/planning_schedules/', { limit: 100 })
  .then((res) => res.results || [])
  .catch((err) => []);
export const fetchManagementSchedules = () => apiV2.get('/management_schedules/', { limit: 100 })
  .then((res) => res.results || [])
  .catch((err) => []);
export const fetchSubadvisorSchedules = () => apiV2.get('/subadvisor_schedules/', { limit: 100 })
  .then((res) => res.results || [])
  .catch((err) => []);

export const fetchPlanningSchedule = (id) => api.get(`/datahub/planning_schedules/${id}/`);
export const fetchManagementSchedule = (id) => api.get(`/datahub/management_schedules/${id}/`);
export const fetchSubadvisorSchedule = (id) => api.get(`/datahub/subadvisor_schedules/${id}/`);

export const createPlanningSchedule = (data) => api.post('/datahub/planning_schedules/', data);
export const createManagementSchedule = (data) => api.post('/datahub/management_schedules/', data);
export const createSubadvisorSchedule = (data) => api.post('/datahub/subadvisor_schedules/', data);

export const updateAccountFeesConfig = (data) => {
  apiV2.post(`/set_account_fees_config/?account_id=${data.id}`, data);
};

export const updatePlanningSchedule = (data) => api.post(`/datahub/planning_schedules/${data.id}/`, data);
export const updateManagementSchedule = (data) => api.post(`/datahub/management_schedules/${data.id}/`, data);
export const updateSubadvisorSchedule = (data) => api.post(`/datahub/subadvisor_schedules/${data.id}/`, data);

export const deletePlanningSchedule = (id) => api.deleteCall(`/datahub/planning_schedules/${id}/`);
export const deleteManagementSchedule = (id) => api.deleteCall(`/datahub/management_schedules/${id}/`);
export const deleteSubadvisorSchedule = (id) => api.deleteCall(`/datahub/subadvisor_schedules/${id}/`);

export const createPlanningScheduleBracket = (data) => api.post('/datahub/planning_schedule_brackets/', data);
export const createManagementScheduleBracket = (data) => api.post('/datahub/management_schedule_brackets/', data);
export const createSubadvisorScheduleBracket = (data) => api.post('/datahub/subadvisor_schedule_brackets/', data);

export const deletePlanningScheduleBracket = (id) => api.deleteCall(`/datahub/planning_schedule_brackets/${id}/`);
export const deleteManagementScheduleBracket = (id) => api.deleteCall(`/datahub/management_schedule_brackets/${id}/`);
export const deleteSubadvisorScheduleBracket = (id) => api.deleteCall(`/datahub/subadvisor_schedule_brackets/${id}/`);

export const updatePlanningScheduleBracket = (data) => api.post(`/datahub/planning_schedule_brackets/${data.id}/`, data);
export const updateManagementScheduleBracket = (data) => api.post(`/datahub/management_schedule_brackets/${data.id}/`, data);
export const updateSubadvisorScheduleBracket = (data) => api.post(`/datahub/subadvisor_schedule_brackets/${data.id}/`, data);
