<template>
  <div>
    <iframe :title="url" :src="url" class="reportIframe" />
  </div>
</template>

<script>
export default {
  name: 'Thumbnail',
  components: {},
  props: {
    url: { type: String, default: '' },
  },
};
</script>

<style>
.reportIframe {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
}
</style>
