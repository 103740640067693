<template>
  <PModal
    body-class="py-3"
    content-class="w-75 mx-auto"
    :visible="visible"
    v-bind="$attrs"
    @hidden="$emit('close-modal', { confirm: false })"
  >
    <PRow class="flex-row-reverse">
      <PButton
        class="close-btn"
        size="sm"
        appendIcon="times"
        :isIconOnly="true"
        variant="tertiary"
        @click="$emit('close-modal', { confirm: false })"
      />
    </PRow>
    <div class="subtitle bold">
      {{content}}
      <div class="py-3" v-if="showCalendar">
        <PCalendar v-model="selectedDate" :max="max" locale="en" block hide-header />
      </div>
    </div>

    <div class="text-right">
      <PButton
        variant="tertiary"
        class="mt-3 mr-1 cancel-btn"
        size="lg"
        @click="$emit('close-modal', { confirm: false })"
      >
        {{labelCancel}}
      </PButton>
      <PButton
        variant="success"
        class="mt-3 confirm-btn"
        size="lg"
        @click="$emit('close-modal', { confirm: true, selectedDate: selectedDate })"
      >
        {{labelSubmit}}
      </PButton>
    </div>
  </PModal>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ConfirmActionModal',
  props: {
    visible: Boolean,
    content: String,
    labelSubmit: String,
    labelCancel: String,
    showCalendar: Boolean,
  },
  data() {
    const now = new Date();
    const maxDate = new Date(now);
    const today = moment().format('YYYY-MM-DD');
    return {
      selectedDate: today,
      max: maxDate,
    };
  },
};
</script>
