<template>
	<v-container>

		<pas-page-title class="mb-4">My Notifications</pas-page-title>

		<notification-filter
			@on-date-range="onDateRangeChange"
			@on-filter="onFilterChange"
			@searchChange="$set(filterOptions, 'searchWord', $event);"
		></notification-filter>

		<v-layout>
			<v-flex xs8 v-if="loading">
			<pas-circular-loader></pas-circular-loader>
			</v-flex>
			<template v-else>
			<v-flex :xs8="firmsAllowedToNotifySalesforce.includes(isFirm(firm))" class="pr-4">
				<template
					v-for="(groupItem, index) in filteredNotificationGroups"
					class="mb-2"
				>
					<v-subheader
						v-if="groupItem.group.length > 0"
						class="pl-0 notification-groups"
						:key="`notif-group-${index}`"
					>
						<span>{{ groupItem.day }}</span>
						<v-spacer></v-spacer>
						<span
							v-if="index === 0"
							class="action-text"
							:class="{'no-pointer': dismissAllProcessing || loading || !notifications.length}"
							@click="dismissAll"
						>Dismiss All</span>
					</v-subheader>
					<pas-list
						v-if="groupItem.group.length > 0"
						:columns="[]"
						:loading="false"
						:items="groupItem.group"
						class="notification-list"
						:key="`notif-group-list-${index}`"
					>
						<template slot-scope="{ item, list_index }">
							<drag
								class="drag"
								:transfer-data="{ 'groupId': index, 'itemId': list_index }"
							>
								<v-layout fill-height row>
									<v-flex xs1>
										<v-layout fill-height row align-center justify-start>
											<router-link :to="`clients/${item.id}`">
												<pas-avatar
													class="ml-3"
													:avatar="getAvatar('clients',item.client)">
												</pas-avatar>
											</router-link>
										</v-layout>
									</v-flex>
									<v-flex xs10 ml-4>
										<v-layout fill-height row align-center justify-start>
												<v-list-tile-content>
												<v-list-tile-sub-title v-html="item.description"></v-list-tile-sub-title>
												<v-list-tile-sub-title>
													<pas-status-bullet
														:color="'green'"
														:value="100"
														class="notification-bullet"
													></pas-status-bullet>
													<span class="notification-time">{{ calculateTimeDiff(item.date_sent) }} ago</span>
												</v-list-tile-sub-title>
											</v-list-tile-content>
										</v-layout>   
									</v-flex>
									<v-flex xs1 mr-3>
										<v-layout align-center fill-height>
											<v-spacer></v-spacer>
												<pas-more-menu
											@click="$event(index, list_index)"
											:editOptions="item.status.read_at ? readEditOptions : unreadEditOptions"
										></pas-more-menu>	
										</v-layout>          
									</v-flex>			
								</v-layout>
							</drag>
						</template>
					</pas-list>
				</template>
			</v-flex>
			</template>
			<v-flex xs4 v-if="firmsAllowedToNotifySalesforce.includes(isFirm(firm))" class="d-flex pl-4 pt-4">
				<drop
					ref="drop"
					class="drop mt-4"
					@dragover="onDragOver"
					@dragleave="onDragLeave"
					@drop="onDropped"
				>
					<v-layout fill-height column class="align-start justify-center pa-5">
						<span class="create-task-text" ><v-icon class="add-icon">add</v-icon>
						<a href="https://foundationwealth.lightning.force.com/lightning/o/Task/home" target="_blank"> Create a new task </a></span>
						<span class="drag-text mt-1">or drag and drop a notification here</span>
					</v-layout>
				</drop>
			</v-flex>
		</v-layout>
	</v-container>

</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import NotificationFilter from './MyNotifications/NotificationFilter'
import { getTimeDiffString } from 'Services/helpers/time'
import { getAvatar } from 'Services/helpers/files'
import { isFirm } from 'Services/helpers/auth';

export default {
	name: 'my-notifications',
	components: {
		NotificationFilter
	},
	data () {
		return {
			tasks: [],
			unreadEditOptions: [
				{
					title: 'Mark Read',
					onClick: (index, nIndex) => this.onItemMenuClicked("Read", index, nIndex)
				},
				{
					title: 'Dismiss',
					onClick: (index,nIndex) => this.onItemMenuClicked("Dismiss", index, nIndex) 
				}
			],
			readEditOptions: [
				{
					title: 'Mark Unread',
					onClick: (index, nIndex) => this.onItemMenuClicked("Unread", index, nIndex)
				},
				{
					title: 'Dismiss',
					onClick: (index,nIndex) => this.onItemMenuClicked("Dismiss", index, nIndex) 
				}
			],
			filterOptions: {
				searchWord: '',
				dateRange: '',
				filterType: null,
			},
			getAvatar,
			isFirm,
			loading: false,
			dismissAllProcessing: false
		}
	},
	async mounted()  {
		this.loading = true
		await this.$store.dispatch('fetchNotifications')
		this.loading = false
	},
	computed: {
		firm() {
			return this.$store.getters.firm;
		},
		firmsAllowedToNotifySalesforce() {
			return ['fwp', 'fwp_fcc'];
		},		
		notifications() {
			return this.$store.state.users.notifications;
		},
		currentUser () {
			return this.$store.state.users.currentUser;
		},
		orderedNotifications () {
			let notificationsCopy = _.cloneDeep(this.notifications)		
			notificationsCopy = _.sortBy(notificationsCopy, (item) => {
				return new moment(item.date_sent)
			}).reverse()

			notificationsCopy = notificationsCopy.map( function (notification) {
				if (moment(notification.date_sent).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
					notification.timeGroup = "Today";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 2) {
					notification.timeGroup = "Yesterday";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 3) {
					notification.timeGroup = "2 Days Ago";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 4) {
					notification.timeGroup = "3 Days Ago";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 5) {
					notification.timeGroup = "4 Days Ago";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 6) {
					notification.timeGroup = "5 Days Ago";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 11) {
					notification.timeGroup = "1 Week Ago";
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 15) {
					notification.timeGroup = "2 Week Ago";			
				} else if (moment(new Date()).diff(moment(notification.date_sent), 'days') < 31) {
					notification.timeGroup = "About a Month Ago";	
				} else {
					notification.timeGroup = "Over 30 Days Ago";
				}
				return notification;
			})

			return notificationsCopy;
		},
		filteredNotificationGroups() {
			return this.filterAndSort()
		}
	},
	methods: {
		filterAndSort() {
			let notifications;
			const today = moment(new Date).format('YYYY-MM-DD')
			if (this.filterOptions.dateRange === '3 days') {
				notifications = this.orderedNotifications.filter(notification => {
					return (moment(today).diff(notification.date_sent, 'days') <= 3)
				})
			} else if (this.filterOptions.dateRange === '7 days') {
				notifications = this.orderedNotifications.filter(notification => {
					return (moment(today).diff(notification.date_sent, 'days') <= 7)
				})			
			} else if (this.filterOptions.dateRange === '2 weeks') {
				notifications = this.orderedNotifications.filter(notification => {
					return (moment(today).diff(notification.date_sent, 'days') <= 14)
				})			
			} else {
				notifications = this.orderedNotifications
			}
			if (this.filterOptions.searchWord) {
				notifications = notifications.filter(notification => {
					return notification.description.toUpperCase().includes(this.filterOptions.searchWord.toUpperCase())
				} )
			}
			if (this.filterOptions.filterType) {
				notifications = notifications.filter(notification => {
					return this.filterOptions.filterType.includes(notification.notification_text)
				} )
			}
			return this.groupNotifications(notifications);
		},
		calculateTimeDiff (createdTime) {
			// const today = moment(new Date).format('YYYY-MM-DD')
			return getTimeDiffString(createdTime)
		},
		searchNotifications(searchWord) {
			this.filterOptions.searchWord = searchWord;
		},
		onDateRangeChange (dateRange) {
			this.filterOptions.dateRange = dateRange;
		},
		onFilterChange (filters) {
			if (filters.length === 0) {
				this.filterOptions.filterType = null;
			} else {
				this.filterOptions.filterType = filters;
			}
		},
		onItemMenuClicked (menuItem, groupId, itemId) {
			const notification = this.filteredNotificationGroups[groupId].group[itemId];
			this.$store.dispatch('isNotificationUpdatePending')
			.then(res => {
				let doesRequireUpdateAfterOperation = res
				if (menuItem === 'Dismiss') {
					let notificationsCopy = this.notifications.filter(function(item){
						return item.id !== notification.id;
					});
					this.$store.dispatch('updateNotificationStatus', { notification, action: 'dismiss' })
					.then(res => { 
						if (res.length && res.length > 0) {
							this.$store.dispatch('setNotifications', notificationsCopy)
							this.$store.dispatch('setDismissedNotificationsBuffer', [notification])
							this.$store.dispatch('setSnackbar', {text: `You have successfully dismissed an alert`, type: 'success', topic: 'alert'})
							this.$store.dispatch('flipSnackbarSwitch')
						}
					})
					.catch(err => {
						console.log('err', err)
						doesRequireUpdateAfterOperation = false
					}) 	
				}
				else if (menuItem === 'Read') {
					let notificationsCopy = [...this.notifications];	
					this.$store.dispatch('updateNotificationStatus', { notification, action: 'mark_as_read' })
					.then(res => { 
						if (res.length && res.length > 0) {
							notificationsCopy.find(x => x.id === notification.id).status.read_at = new Date()
							this.$store.dispatch('setNotifications', notificationsCopy)
						}
					})
					.catch(err => {
						this.$store.dispatch('setSnackbar', 
							{text: `Error updating notification status`, type: 'error'})
						this.$store.dispatch('flipSnackbarSwitch')
						console.log('err', err)
						doesRequireUpdateAfterOperation = false
					}) 	
				}
				else if (menuItem === 'Unread') {
					let notificationsCopy = [...this.notifications];	
					this.$store.dispatch('updateNotificationStatus', { notification, action: 'revert_mark_as_read' })
					.then(res => { 
						if (res.length && res.length > 0) {
							notificationsCopy.find(x => x.id === notification.id).status.read_at = null
							this.$store.dispatch('setNotifications', notificationsCopy)
						}
					})
					.catch(err => {
						this.$store.dispatch('setSnackbar', 
							{text: `Error updating notification status`, type: 'error'})
						this.$store.dispatch('flipSnackbarSwitch')
						console.log('err', err)
						doesRequireUpdateAfterOperation = false
					}) 
				}
				if (doesRequireUpdateAfterOperation) {
					// Queue another notification update request in case the user changed
					// something while the state polling was currently under way. This is 
					// to ensure we won't be displaying dirty data until the next polling 
					// period.
					this.$store.dispatch('pollAndUpdateNotifications')
				}
			})
			.catch(err => {
				console.log('err', err)
			}) 			
		},	
		async dismissAll () {
			this.dismissAllProcessing = true
			await this.$store.dispatch('updateUserNotificationStatus', { action: 'mark_as_read' })
			.then(res => {
				if(res.length) {
					this.$store.dispatch('setDismissedNotificationsBuffer', this.notifications)
					this.$store.dispatch('setNotifications', [])
					this.$store.dispatch('setSnackbar', 
						{text: `You have successfully dismissed ${this.$store.state.users.dismissedNotificationsBuffer.length} alerts`, type: 'success', topic: 'alert'})
					this.$store.dispatch('flipSnackbarSwitch')
				}
			})
			.catch(err => {
				console.log('err', err)
			}) 
			this.dismissAllProcessing = false
		},
		onDragOver () {
			this.$refs.drop.$el.style.border = '2px dashed black'
		},
		onDragLeave () {
			this.$refs.drop.$el.style.border = '2px dashed #bfbfbf'
		},
		createTask() {
			window.open("https://foundationwealth.lightning.force.com/lightning/o/Task/home","_blank")
		},
		onDropped (data, event) {
			this.$refs.drop.$el.style.border = '2px dashed #bfbfbf'
			this.filteredNotificationGroups[data.groupId].group.splice(data.itemId, 1)
			this.createTask()
		},
		groupNotifications (notificationList) {
			const groups = _.groupBy(notificationList, (notification) => {
				return notification.timeGroup
			})

			const newGroups = _.map(groups, (group, day) => ({
				group,
				day,
			}))

			return newGroups;
		}, 				
	},
	watch: {
		filterOptions: {
			handler() {
				this.filterAndSort()
			},
			deep: true
		}
	}
}

</script>

<style lang="scss" scoped>
	.notification-list {
		padding: 0px !important;
	}
	.notification-list-item {
		cursor: pointer !important;
		padding:5px !important;
		.notification-time {
			font-size: 12px !important;
		}
		&.read {
			background-color: #eeeeee !important;
			&:hover,&:focused,&:active {
				background-color: #eeeeee !important;
			}
		}
	}
	.item-border-top {
		border-top: 1px solid #bfbfbf;
	}
	.drop {
		width: 90%;
		height: 150px;
		border: 1px dashed #bfbfbf;
	}
	.create-task-text {
		color: var(--primary-color);
		font-size: 18px;
		font-weight: bold;
		cursor: pointer;
		a {
		color: var(--primary-color) !important;
		}
	}
	.add-icon {
		color: var(--primary-color) !important;
		font-size: 18px;
		font-weight: bold;
		padding-bottom: 2px;
	}
	.drag-text {
		color: #757575;
		font-size: 18px;
		font-weight: normal;
	}
.notification-groups {
	font-size: 10px !important;
    font-weight: 800 !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}	
</style>