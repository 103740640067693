<template>
  <auth-page>
    <v-layout column>
      <template v-if="screen === screens.indexOf('resetForm')">
        <v-flex>
          <pas-page-title>Password Reset</pas-page-title>
        </v-flex>
        <v-flex>
          <div class="light-text">To reset your password, enter the email address you use to sign in</div>
        </v-flex>
        <v-flex mt-4>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent
            @keyup.enter.native="sendResetLink"
          >
            <v-layout column>
              <div class="strong-text form-label">Email Address</div>
              <pas-input-field
                :placeholder="`Email`"
                v-model="email"
                :rules="[v.isRequired(), v.isValidEmail]"
              />
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex :style="{ height: '30px' }" mt-4>
          <pas-error-div :show="errorText !== ''" :style="{ display: 'inline' }">{{errorText}}</pas-error-div>
        </v-flex>
        <v-flex mt-1>
          <v-layout>
            <v-flex shrink text-xs-left>
              <div class="light-text">Changed your mind?</div>
              <router-link to="/login" class="action-text">Sign in.</router-link>
            </v-flex>
            <v-spacer />
            <v-flex shrink>
              <pas-button
                class="px-5"
                @click="sendResetLink"
                :processing="requestProcessing"
              >
                Send Reset Link
              </pas-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
      <template v-if="screen === screens.indexOf('successMessage')">
        <v-flex>
          <pas-page-title>Email Sent!</pas-page-title>
        </v-flex>
        <v-flex>
          <div class="light-text">
            Check your email {{ this.email }} for instructions from us on how to reset your password.
            If you don’t see an email, make sure you entered the correct email address.
            If you continue to have difficulty, please contact your administrator.
          </div>
        </v-flex>
      </template>
    </v-layout>
  </auth-page>
</template>

<script>
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage';
import v from 'Services/validation';
import api from 'Services/api';

const screens = [
  'resetForm',
  'successMessage',
];

export default {
  name: 'forgot',
  components: {
    AuthPage,
  },
  data() {
    return {
      v,
      email: '',
      errorText: '',
      screen: screens.indexOf('resetForm'),
      screens,
      requestProcessing: false,
    };
  },
  methods: {
    async sendResetLink() {
      this.errorText = '';
      if (!(this.$refs.form.validate())) return;
      this.requestProcessing = true;
      await api.post('/advisor_portal/api/reset_password', { username: this.email, firm: this.firm })
        .then((res) => {
          if (res.success) {
            this.screen = screens.indexOf('successMessage');
          } else {
            this.errorText = 'There was a problem processing your request. Please try again.';
          }
        })
        .catch((err) => {
          console.log('error with reset link', err);
        });
      this.requestProcessing = false;
    },
  },
  computed: {
    firm() { return this.$store.getters.firm; },
  },
  watch: {
    email() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>

</style>
