import { getLabelFromId } from '../../../components/Vue-Routes/FundingV2/confirmationHelper';
import { isClientOfTypePerson } from '../../../helpers';

const FUNDING_TYPE = {
  SWP: 'withdrawal',
  PAC: 'deposit',
  TRANSFER: 'transfer',
};

const REQUEST_TYPES = {
  DEPOSIT: '1',
  WITHDRAWAL: '2',
  INTERNAL_TRANSFER: '7',
  EXTERNAL_TRANSFER: '8',
};

const DEFAULT_DEPOSIT_SUBTYPE = {
  id: '1', name: 'One-time & Recurring events', type: REQUEST_TYPES.DEPOSIT, docList: [],
};
const DEFAULT_WITHDRAWAL_SUBTYPE = {
  id: '5', name: 'One-time & Recurring events', type: REQUEST_TYPES.WITHDRAWAL, docList: [],
};
const SHARE_DETAILS_CURRENCY_TYPES = ['CAD', 'USD'];
const WITHDRAWAL_CURRENCY_TYPES = ['CAD', 'USD', 'EUR', 'GBP'];
const FREQUENCY_ONE_TIME_ID = '9';

// Custom Amount IDs
const CUSTOM_AMOUNT_IDS = {
  GROSS: 1,
  NET: 2,
};
const CUSTOM_WITHDRAWAL_TYPE_IDS = {
  INCOMING_PAYMENT_MOD: '13',
  ONE_TIME_WITHDRAWAL: '14'
};
const isCustomWithdrawalType = (type) => Object.keys(CUSTOM_WITHDRAWAL_TYPE_IDS).map((key) => CUSTOM_WITHDRAWAL_TYPE_IDS[key]).includes(type)

const DEFAULTSUBTYPES = {
  PAC: DEFAULT_DEPOSIT_SUBTYPE,
  SWP: DEFAULT_WITHDRAWAL_SUBTYPE,
};
const DEFAULT_SUBTYPE_IDS = Object.values(DEFAULTSUBTYPES).map((subtype) => subtype.id);

const isDefaultSubtype = (subtype, type = null) => {
  switch (type) {
    case REQUEST_TYPES.DEPOSIT:
      return subtype.id === DEFAULT_DEPOSIT_SUBTYPE.id;
    case REQUEST_TYPES.WITHDRAWAL:
      return subtype.id === DEFAULT_WITHDRAWAL_SUBTYPE.id;
    default:
      return DEFAULT_SUBTYPE_IDS.includes(subtype.id);
  }
};
const isTransferType = (typeId) => [REQUEST_TYPES.INTERNAL_TRANSFER, REQUEST_TYPES.EXTERNAL_TRANSFER].includes(typeId);
const transferOptions = {
  types: [
    {
      id: '1',
      text: 'Full',
      label: 'Full',
    },
    {
      id: '2',
      text: 'Partial',
      label: 'Partial',
    },
  ],
  methods: [
    {
      id: '1',
      text: 'In-Kind',
      label: 'In-Kind',
    },
    {
      id: '2',
      text: 'In-Cash',
      label: 'In-Cash',
    },
  ],
};
const transferMixed = {
  id: '3',
  text: 'Mixed',
  label: 'Mixed',
};
const TRANSFER_TYPE_IDS = {
  FULL: '1',
  PARTIAL: '2',
};
const TRANSFER_METHOD_IDS = {
  IN_KIND: '1',
  IN_CASH: '2',
  MIXED: '3',
};

const isPersonalTransferInRequest = (client, requestSubtypeId) => isClientOfTypePerson(client) && requestSubtypeId === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const removeEmptyVals = (data) => {
  Object.keys(data).forEach((key) => (!data[key] ? delete data[key] : {}));
  return data;
};

const formatInvestmentAcctsResponse = (response, keys = {}) => {
  const formattedAccounts = response.map((acc) =>
    Object.assign(acc, {
      label: `${(keys.name && acc.primary_holder?.name + ' . ') || ''} ${acc.name || ''} · ${
        acc.type || ''
      } ${acc.account_number ? `· ${acc.account_number}` : ''} · ${currencyFormatter.format(
        acc.aum.market_value
      )} ${acc.currency}`,
    })
  );
  return formattedAccounts;
};

const formatAmountToDecimal = (amount) => (amount ? Number(amount).toFixed(2) : null);

const dataFromValidatedRequest = (requestData, validatedRequest, documents = []) => {
  requestData.amount = formatAmountToDecimal(validatedRequest.net_amount)
                    || formatAmountToDecimal(validatedRequest.amount);
  requestData.shareDetails = validatedRequest.share_certificates
    ? validatedRequest.share_certificates.map((s) => ({
      shareName: s.share_name,
      shareAmount: s.share_amount,
      shareQuantity: s.share_quantity,
      certificateNumber: s.share_certificate_no,
      cusipCode: s.cusip,
      currency: s.currency,
      minimumAmount: s.minimum_amount,
      maximumAmount: s.maximum_amount,
    })) : null;
  requestData.recurringEvent = validatedRequest.recurring_event || null;
  requestData.frequency = validatedRequest.frequency || null;
  requestData.currency = validatedRequest.currency || null;
  requestData.startDate = validatedRequest.start_date || null;
  requestData.endDate = validatedRequest.end_date || null;
  requestData.note = validatedRequest.notes || null;
  requestData.documents = documents || null;
  return requestData;
};

const dataFromValidatedAssetDetails = (assets) => assets?.map((asset) => ({
  assetName: asset.symbol,
  assetAmount: asset.amount,
  assetQuantity: asset.quantity,
  transferAllAssets: asset.transfer_all,
  currency: asset.currency,
  assetTypeId: asset.transfer_method,
  assetType: getLabelFromId(asset.transfer_method, [...transferOptions.methods, transferMixed]),
}));

const parseBankDetails = (validatedRequestData) => (
  validatedRequestData ? removeEmptyVals({
    beneficiaryName: validatedRequestData.beneficiary_name || null,
    beneficiaryAddress: validatedRequestData.beneficiary_address || null,
    bankName: validatedRequestData.name || null,
    accountNumber: validatedRequestData.account_no || validatedRequestData.external_institution_account_no || null,
    transit: validatedRequestData.transit_no || null,
    institutionCode: validatedRequestData.institution_no || null,
    swiftCode: validatedRequestData.swift_code || null,
    intermediaryBankAcctNum: validatedRequestData.intermediary_account_no || null,
    intermediaryBankSwift_ABA: validatedRequestData.intermediary_swift_code || null,
    intermediaryBankName: validatedRequestData.intermediary_name || null,
    currency: validatedRequestData.currency || null,
    contactInfo: validatedRequestData.contact_information || null,
    institutionName: validatedRequestData.external_institution || null,
    relinquishingInstitution: validatedRequestData.external_institution || null,
    organizationName: validatedRequestData.organization_name || null,
  }) : null
);

const parseWithdrawalTypeAmounts = (validatedRequest) => {
  if (validatedRequest.educational_assistance_payment
    || validatedRequest.postsecondary_educational_payment
    || validatedRequest.non_educational_capital_withdrawal_amount) {
    return {
      eapAmount: formatAmountToDecimal(validatedRequest.educational_assistance_payment),
      pseAmount: formatAmountToDecimal(validatedRequest.postsecondary_educational_payment),
      capitalAmount: formatAmountToDecimal(validatedRequest.non_educational_capital_withdrawal_amount),
      totalAmount: formatAmountToDecimal(validatedRequest.amount),
    };
  } return null;
};

const isOneTimeRequest = (request) => request.frequency === FREQUENCY_ONE_TIME_ID.toString();

const REQUEST_SUBTYPE_IDS = {
  DEPOSIT_ONE_TIME_RECURRING: '1',
  DEPOSIT_CHEQUE: '2',
  DEPOSIT_RECURRING_EVENT_AUTO_PURCHASE: '3',
  DEPOSIT_SHARE_CERTIFICATE: '4',
  WITHDRAWAL_ONE_TIME_AND_RECURRING: '5',
  WITHDRAWAL_HBP_LLP_EXCESS: '6',
  WITHDRAWAL_CHEQUE_OR_WIRE_REQUEST: '7',
  WITHDRAWAL_INCOME_PAYMENT_MODIFICATION: '8',
  WITHDRAWAL_ONE_TIME_RRIF_SRRIF_LIF: '9',
  WITHDRAWAL_RECURRING_EVENT_AUTO_SELL: '10',
  WITHDRAWAL_RESP: '11',
  WITHDRAWAL_THIRD_PARTY_WIRE_REQUEST: '12',
  WITHDRAWAL_UNLOCKING: '13',
  WITHDRAWAL_INCOME_SWEEP: '14',
  INTERNAL_TRANSFER_ACCOUNT_ROLLOVER: '15',
  INTERNAL_TRANSFER_SAME_CLIENT: '16',
  INTERNAL_TRANSFER_DIFFERENT_CLIENTS: '17',
  INTERNAL_TRANSFER_MARGIN_DRAW_DOWN: '18',
  EXTERNAL_TRANSFER_DONATION_REQUEST: '19',
  EXTERNAL_TRANSFER_IN: '20',
};

const RESP_RECIPIENT_IDS = {
  ACCOUNT_OWNER: '1',
  BENEFICIARY: '2',
};

const INVESTMENT_ACCT_BY_TYPE = {
  MARGIN_DRAW_DOWN: 'margin',
  ROLLOVER_SAVINGS: 'rollover-savings',
  ROLLOVER_INCOME: 'rollover-income',
  DIFFERENT_CLIENTS: 'different-clients',
  CASH: 'cash',
  TFSA: 'tfsa',
  RRIF_SRRIF_LIF: 'rrif,lif,lrif',
  RESP: 'resp',
  HBP_LLP: 'rrsp,fhsa',
};

const AMOUNT_VALUES = {
  MAX: 'Maximum',
  MIN: 'Minimum',
};

export {
  DEFAULTSUBTYPES, DEFAULT_DEPOSIT_SUBTYPE, DEFAULT_SUBTYPE_IDS, DEFAULT_WITHDRAWAL_SUBTYPE,
  REQUEST_SUBTYPE_IDS, REQUEST_TYPES, FUNDING_TYPE, SHARE_DETAILS_CURRENCY_TYPES,
  WITHDRAWAL_CURRENCY_TYPES, RESP_RECIPIENT_IDS, INVESTMENT_ACCT_BY_TYPE,
  TRANSFER_TYPE_IDS, TRANSFER_METHOD_IDS, AMOUNT_VALUES, transferOptions, transferMixed,
  currencyFormatter, formatInvestmentAcctsResponse, formatAmountToDecimal,
  isDefaultSubtype, isOneTimeRequest, isTransferType, dataFromValidatedRequest,
  parseBankDetails, parseWithdrawalTypeAmounts, removeEmptyVals, CUSTOM_WITHDRAWAL_TYPE_IDS,
  isCustomWithdrawalType, CUSTOM_AMOUNT_IDS, isPersonalTransferInRequest,
  dataFromValidatedAssetDetails,
};

export const FUNDING_DOCUMENT_TYPE = "54"; // Complex Funding Documents
export const FUNDING_WORKFLOW = "funding workflow";
