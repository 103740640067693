<template>
  <v-card class="pa-3" :style="{ minHeight: '541px' }">
    <v-layout row>
      <v-flex>
        <h2 class="section-title">{{`Workflows (${workflows.length})`}}</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex text-xs-right>
        <div @click="toggleSortDir" class="date-sort">
          Date &nbsp;&nbsp;
          <v-icon small v-if="filterOptions.sortDir === 1">mdi-arrow-up</v-icon>
          <v-icon small v-else-if="filterOptions.sortDir === -1">mdi-arrow-down</v-icon>
        </div>
      </v-flex>
    </v-layout>
    <v-layout mt-3>
      <v-flex xs7 pl-2 class="table-header">Assigned</v-flex>
      <v-flex xs3 pl-2 class="table-header">Type</v-flex>
      <v-flex xs2 pl-2 class="table-header">Status</v-flex>
    </v-layout>
    <v-layout mt-3 column>
      <template v-for="(w, i) in filteredWorkflows">
        <!-- <v-flex :key="`workflow-${i}`"> -->
          <v-card class="mb-2 list-card">
            <v-tooltip top>
              <v-layout row align-center fill-height text-xs-center slot="activator">
                <v-flex xs2 text-xs-center @click="viewWorkflow(w._id)">
                  <pas-avatar
                    size="50" v-on="on"
                    :avatar="getAvatar('platform_users',teamMembersAdj[w.assigned_user])"
                  ></pas-avatar>
                </v-flex>
                <v-flex xs3 text-xs-left>
                  <div
                    v-if="!isUsersWorkflow(w) || w.status === 'completed'"
                    class="assigned-user-name p-text"
                    @click="viewWorkflow(w._id)"
                  >{{w.assigned_user_name}}</div>
                  <pas-drop-down
                    v-else-if="isUsersWorkflow(w)"
                    :items="formattedTeamMembers"
                    :value="w.assigned_user"
                    :key="`pas-drop-down-${i}`"
                    id="wf-user-dd"
                    @change="reAssignWorkflow(w, $event)"
                    @click.stop
                  ></pas-drop-down>
                </v-flex>
                <v-flex xs2 text-xs-center @click="viewWorkflow(w._id)">
                  <pas-display-chip
                    :small="true"
                    :textColor="chipTextColor"
                    :color="chipColor"
                  >{{isUsersWorkflow(w) ? "You" : "Team"}}</pas-display-chip>
                </v-flex>
                <v-flex xs3 text-xs-left pl-2 @click="viewWorkflow(w._id)" class="p-text">{{w.type}}</v-flex>
                <v-flex xs2 text-xs-center @click="viewWorkflow(w._id)">
                  <!-- handle bad workflow status with v-if -->
                  <pas-status-bullet
                    v-if="statusMap[`${w.status}`.toLowerCase()]"
                    :value="statusMap[`${w.status}`.toLowerCase()].value"
                    :color="statusMap[`${w.status}`.toLowerCase()].color"
                  ></pas-status-bullet>
                </v-flex>
              </v-layout>
              <span>Created: {{`${w.created_at.substring(0,10)}`}}</span>
            </v-tooltip>
          </v-card>
        <!-- </v-flex> -->
      </template>
    </v-layout>

    <v-spacer fill-height></v-spacer>
    <v-layout text-xs-center row fill-height>
      <v-flex xs12>
        <pas-pagination :length="paginationLength" v-model="filterOptions.page"></pas-pagination>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import api from 'Services/api';
import { WORKFLOW } from 'Services/constants';
import { getAvatar } from 'Services/helpers/files';

export default {
  name: 'workflows',
  props: {
    workflows: Array,
  },
  data() {
    return {
      on: false,
      filterOptions: {
        sortDir: -1, // -1 for down (most recent first), 1 for up
        page: 1,
        perPage: 4, // not modifiable
      },
      paginationLength: 1,
      filteredWorkflows: _.cloneDeep(this.workflows),
      statusMap: {
        not_started: {
          color: '#d9ad4a',
          value: 100,
        },
        in_progress: {
          color: 'green',
          value: 50,
        },
        needs_attention: {
          color: 'red',
          value: 100,
        },
        completed: {
          color: 'green',
          value: 100,
        },
        cancelled: {
          color: 'green',
          value: 100,
        },
      },
      getAvatar,
    };
  },
  computed: {
    ...mapState({
      chipColor: (state) => state.app.themeVars['primary-translucent-2'],
      chipTextColor: (state) => state.app.themeVars['primary-translucent-7'],
    }),
    formattedWorkflows() {
      return this.workflows.map((w) => {
        w = _.cloneDeep(w);
        w.type = WORKFLOW.DISPLAY_NAMES[w.type];
        return w;
      });
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    teamMembers() {
      return this.$store.getters.teamMembers;
    },
    teamMembersAdj() {
      const teamMembersAdj = {};
      this.teamMembers.forEach((m) => teamMembersAdj[m.ac_mongo_id] = m.id);
      return teamMembersAdj;
    },
    formattedTeamMembers() {
      const ftm = this.teamMembers.map((m) => ({ text: `${m.first_name} ${m.last_name}`, value: m.ac_mongo_id }));
      return ftm;
    },
  },
  mounted() {
    this.filterAndSort();
  },
  methods: {
    viewWorkflow(workflowID) {
      this.$router.push(`/workflow/${workflowID}`);
    },
    filterAndSort() {
      const opts = this.filterOptions;
      this.filteredWorkflows = _.cloneDeep(this.formattedWorkflows);
      this.filteredWorkflows = this.filteredWorkflows.sort((a, b) => (a.updated_at < b.updated_at ? -1 * opts.sortDir : opts.sortDir));

      this.paginationLength = this.getPaginationLength();
      const iStart = (opts.page - 1) * opts.perPage;
      const iEnd = opts.page * opts.perPage;
      this.filteredWorkflows = this.filteredWorkflows.slice(iStart, iEnd);
    },
    getPaginationLength() {
      return this.filteredWorkflows
        ? Math.ceil(this.filteredWorkflows.length / this.filterOptions.perPage)
        : 1;
    },
    toggleSortDir() {
      this.$set(this.filterOptions, 'sortDir', this.filterOptions.sortDir * -1);
    },
    isUsersWorkflow(workflow) {
      return workflow.assigned_user === this.currentUser._id;
    },
    reAssignWorkflow(workflow, userID) {
      this.$store
        .dispatch('reassignWorkflow', { workflow, userID })
        .then((res) => {
          if (!res.error) {
            this.$store.dispatch('setSnackbar', {
              text: `You have successfully reassigned a workflow to ${
                workflow.assigned_user_name
              }`,
              type: 'success',
            });
            this.$store.dispatch('flipSnackbarSwitch');
          } else {
            this.$store.dispatch('setSnackbar', {
              text: `Error in reassigning workflow to ${
                workflow.assigned_user_name
              }`,
              type: 'error',
            });
            this.$store.dispatch('flipSnackbarSwitch');
            console.log('res', res.res);
          }
        })
        .catch((err) => {
          this.$store.dispatch('setSnackbar', {
            text: `Error in reassigning workflow to ${
              workflow.assigned_user_name
            }`,
            type: 'error',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          console.log('res', res.res);
        });
    },
  },
  watch: {
    filterOptions: {
      handler() {
        this.filterAndSort();
      },
      deep: true,
    },
    workflows: {
      handler() {
        this.filterAndSort();
        this.$forceUpdate();
      },
      deep: true,
    },
  },
};
</script>

<style>


.table-header:last-of-type {
  border-right: none !important;
}
.date-sort {
  cursor: pointer !important;
}
#wf-user-dd .v-select__selection {
  /*overflow: hidden !important;*/
  width: 100% !important;
  font-size: 13px !important;
}
#wf-user-dd .v-select__selections {
  max-width: 80% !important;
}

#wf-user-dd .v-input__icon {
  z-index: 0 !important;
  background: #fff !important;
}
.assigned-user-name {
  text-transform: capitalize;
  overflow: none !important;
}
</style>
