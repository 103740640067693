<template>
  <v-text-field
    :prepend-inner-icon="prependInnerIcon"
    single-line
    outline
    solo
    flat
    clear-icon="cancel"
    :clearable="clearable"
    class="search-box"
    :value="value"
    @input="$emit('input', $event)"
    :placeholder="placeholder"
    ></v-text-field>
</template>

<script>
export default {
  name: 'pas-search-box',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: 'Type search text here...',
    },
    prependInnerIcon: {
      type: String,
      default: 'search',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

.search-box {
  font-size: 14px !important;
  cursor: pointer !important;
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot {
    cursor: pointer !important;
    input {
      cursor: pointer !important;
    }
  }
}
.search-box .v-input__slot:hover,
.search-box .v-input__slot:focus,
.search-box .v-input__slot:active,
.search-box .v-input__slot:focus-within {
}


</style>
