<template>
  <div v-if="landingPageInfo">
    <PCard
      clickable
      class="mb-1"
      v-for="(landingCard, index) in dashboardData"
      :key="index"
      @click="$emit('update:status', landingCard.status)"
    >
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-row">
          <div class="flex-column align-self-start mr-2">
            <font-awesome-icon :icon="['fal', landingCard.icon]" />
          </div>
          <div class="flex-column">
            <h3 class="mb-0">{{ landingCard.title }}</h3>
            <small class="helper">{{ landingCard.description }}</small>
          </div>
        </div>
        <div class="flex-column align-self-center">
          <PBadge :variant="landingCard.badgeVariant">{{ landingPageInfo[landingCard.key] }}</PBadge>
        </div>
      </div>
    </PCard>
  </div>
</template>

<script>

export default {
  name: 'FundingLanding',
  props: {
    landingPageInfo: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      dashboardData: [
        {
          title: 'Client signature',
          description: 'We are waiting on client signatures',
          badgeVariant: 'danger',
          key: 'client_signing',
          status: 'client-signature',
          icon: 'pencil',
        },
        {
          title: 'Under review',
          description: 'We are fixing issues. Expect some slight delays',
          badgeVariant: 'warning',
          key: 'under_review',
          status: 'under review',
          icon: 'clock',
        },
        {
          title: 'Upcoming',
          description: 'Upcoming transactions for the next 90 days',
          badgeVariant: 'secondary',
          key: 'upcoming',
          status: 'pending',
          icon: 'calendar',
        },
        {
          title: 'Completed',
          description: 'Completed transactions in the last 90 days',
          badgeVariant: 'primary',
          key: 'completed',
          status: 'processed',
          icon: 'check',
        },
        {
          title: 'Cancelled',
          description: 'Cancelled transactions in the last 90 days',
          badgeVariant: 'light',
          key: 'cancelled',
          status: 'cancelled',
          icon: 'ban',
        },
      ],
    };
  },
};
</script>
