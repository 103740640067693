<template>
  <ClientListTableComponent
    @view-profile="goToClientsProfile($event)"
  />
</template>

<script>
export default {
  name: 'ClientsListTable',
  mounted() {
  },
  data() {
    return {
      placeholder: [],
    };
  },
  methods: {
    goToClientsProfile(clientId) {
      this.$router.push({ path: `/clients/${clientId}` });
    },
  },
};

</script>
