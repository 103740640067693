import { max } from 'moment';
import { REQUEST_TYPES, REQUEST_SUBTYPE_IDS, TRANSFER_TYPE_IDS } from '../../../services/helpers/funding';

const shareDetailsData = (data) => {
  const formattedShares = [];

  if (!data.shareDetails) return {};

  data.shareDetails.forEach((element) => {
    formattedShares.push({
      share_name: element.shareName,
      share_quantity: element.shareQuantity,
      share_amount: element.shareAmount,
      share_certificate_no: element.certificateNumber,
      cusip: element.cusipCode,
      currency: element.currency,
      minimum_amount: element.minimumAmount,
      maximum_amount: element.maximumAmount,
    });
  });

  return { share_certificates: formattedShares };
};

const assetDetailsData = (data) => {
  const formattedAssets = [];

  if (!data.assetDetails) return {};

  data.assetDetails.forEach((element) => {
    formattedAssets.push({
      symbol: element.assetName,
      quantity: element.assetQuantity,
      amount: element.assetAmount,
      transfer_method: element.assetTypeId,
      transfer_all: !!element.transferAllAssets,
      currency: element.currency,
    });
  });

  return { assets: formattedAssets };
};

const externalInformation = (reviewPayload) => {
  if (!reviewPayload.externalInformation) {
    return {};
  }

  const info = reviewPayload.externalInformation;
  return {
    external_institution_account_no: info.accountNumber,
    external_institution: reviewPayload.subtype === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN? info.relinquishingInstitution : info.institutionName,
    contact_information: info.contactInfo,
    organization_name: info.organizationName,
  };
};

const parseForInternal = (payload) => ({
  to_account: payload.toInvestAccount,
  from_account: payload.fromInvestAccount,
});

const parseForExternal = (payload) => ({
  ...externalInformation(payload),
  investment_account_no: payload.fromInvestAccount || payload.toInvestAccount,
});

const generateAmountFields = (payload) => {
  if (!payload.amount) {
    return null;
  }
  const amount = {};
  if (payload.type === REQUEST_TYPES.INTERNAL_TRANSFER) {
    amount.amount = payload.amount;
  } else {
    if (payload.transferType?.id === TRANSFER_TYPE_IDS.FULL) {
      amount.amount_estimated = payload.amount;
    }
    if (payload.transferType?.id === TRANSFER_TYPE_IDS.PARTIAL) {
      amount.amount_cash = payload.amount;
    }
  }

  return amount;
};

export const parseTransferReviewPayload = (reviewPayload, clientId) => {
  let fields = {
    client_id: clientId,
    subtype: reviewPayload.subtype,
    type: reviewPayload.type,
    run_date: reviewPayload.runDate,
    notes: reviewPayload.notes,
    document_ids: [],
    transfer_method: reviewPayload.transferMethod?.id,
    transfer_type: reviewPayload.transferType?.id,
    ...shareDetailsData(reviewPayload),
    ...assetDetailsData(reviewPayload),
    ...generateAmountFields(reviewPayload),
    transfer_remaining_assets_method: reviewPayload.transferRemainingAssetsMethod,
  };

  if (reviewPayload.type === REQUEST_TYPES.INTERNAL_TRANSFER) {
    fields = {
      is_donation: Boolean(reviewPayload.isDonation),
      taxable: Boolean(reviewPayload.taxable),
      ...fields,
      ...parseForInternal(reviewPayload),
    };
  } else if (reviewPayload.type === REQUEST_TYPES.EXTERNAL_TRANSFER) {
    fields = {
      ...fields,
      ...parseForExternal(reviewPayload),
    };
  }
  
  return fields;
};
