<template>
  <HouseholdListTable />
</template>

<script>
import HouseholdListTable from '../HouseholdListTable';

export default {
  name: 'HouseholdList',
  components: {
    HouseholdListTable,
  },
  mounted() {
  },
  data() {
    return {
      sidebarSections: [
        {
          id: 0,
          title: 'Create Household',
          icon: 'home',
          route: '',
          enabled: true,
        },
        {
          id: 1,
          title: 'New Client',
          icon: 'user',
          route: '',
          enabled: true,
        },
      ],
    };
  },
};
</script>
