<template>
  <v-dialog width="600px" px-5 :value="isOpen" persistent>
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon
          @click="$emit('close'); $refs.form.reset();"
          class="close-icon"
        >close</v-icon>
      </PasFab>
      <PasPageTitle class="mb-3">Upload</PasPageTitle>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent
      >
        <div class="my-4">Select subadvisor</div>
        <PasInputFieldList
          v-model="subAdvisor"
          :items="subAdvisors"
          :rules="[v.isRequired()]"
        />
        <div v-if="!!subAdvisor">
          <div class="mb-4">Select Model Portfolio you are uploading the details for</div>
          <PasInputFieldList
            v-model="modelPortfolio"
            :items="filteredModelPortfolios"
            :rules="[v.isRequired()]"
          />
        </div>
        <div v-if="documentExists" class="strong-text my-3">
          You already have a document detailing asset allocation for this model portfolio. Please note that upon upload, the existing document will be replaced.
        </div>
      </v-form>
      <span>Note: File size must not exceed {{CLIENT_DOCUMENT_MAX_FILESIZE}} MB</span>
      <PasDropzone
        ref="pasDropzone"
        :id="'documentUpload'"
        :class="'mb-3'"
        v-if="displayDropzone"
        :options="dropzoneOptions"
      />
      <v-layout justify-end>
        <PasButton
          @click="upload"
          :disabled="!isUploaded"
          :processing="isProcessing"
        >Upload</PasButton>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

import { CLIENT_DOCUMENT_MAX_FILESIZE, CLIENT_DOCUMENT_TIMEOUT } from 'Services/constants';
import _ from 'lodash';
import v from 'Services/validation';
import { modal } from 'Services/mixins';

export default {
  name: 'DocumentUpload',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    documents: Array,
    modelPortfolios: Array,
  },
  data() {
    const that = this;
    return {
      dropzoneOptions: {
        url: '/advisor_portal/upload_portfolio_details',
        init() {
          this.on('addedfile', (file) => {
            that.isUploaded = true;
          });
          this.on('removedfile', (file) => {
            that.isUploaded = false;
          });
          this.on('sending', (file, xhr, formData) => {
            that.isProcessing = true;
            formData.append('id', that.documentExists || '');
            formData.append('document_name', that.documentName);
            formData.append('model_portfolio', that.modelPortfolio);
            xhr.ontimeout = () => this.emit('canceled', file);
          });
          this.on('success', (file, res) => {
            that.handleResponse(res, file);
          });
          this.on('error', (file, err) => {
            that.handleResponse(err, file);
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: CLIENT_DOCUMENT_MAX_FILESIZE,
        maxFiles: 1,
        chunking: false,
        chunkSize: 3000000, // Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        timeout: CLIENT_DOCUMENT_TIMEOUT,
        addRemoveLinks: true,
        autoProcessQueue: false,
        acceptedFiles: '.pdf',
      },
      modelPortfolio: undefined,
      subAdvisor: undefined,
      isUploaded: false,
      isProcessing: false,
      v,
      CLIENT_DOCUMENT_MAX_FILESIZE,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    displayDropzone() {
      return this.modelPortfolio || this.modelPortfolio === 0;
    },
    documentExists() {
      if (!this.modelPortfolio) return null;
      return this.documents.filter((d) => d.model_portfolio === this.modelPortfolio).map((d) => d.id)[0];
    },
    documentName() {
      if (!this.modelPortfolio) return '';
      return this.modelPortfolios.filter((mp) => mp.id === this.modelPortfolio)[0].name;
    },
    subAdvisors() {
      if (!this.modelPortfolios.length) return [];
      return _.uniq(this.modelPortfolios.map((mp) => mp.sub_advisor_name));
    },
    filteredModelPortfolios() {
      return this.modelPortfolios
        .filter((mp) => mp.sub_advisor_name === this.subAdvisor)
        .map((mp) => ({
          value: mp.id,
          text: `${mp.portfolio_category_name} - ${mp.name}`,
        }));
    },
  },
  methods: {
    upload() {
      this.$refs.pasDropzone.$refs.dropzone.processQueue();
    },
    async handleResponse(res, file) {
      this.isUploaded = false;
      this.isProcessing = false;
      if (res.code !== 200) {
        this.snackBar('fail', this.getErrorMsg(res));
        this.$refs.pasDropzone.$refs.dropzone.removeFile(file);
        return;
      }
      const text = 'Successfully uploaded the document';
      this.snackBar('success', 'Successfully uploaded the document');
      this.$refs.form.reset();
      this.$emit('close');
    },
    getErrorMsg(err) {
      if (err === 'Upload canceled.') return 'Upload took too long and was canceled. Please try uploading again.';
      if (typeof err === 'string' && err.includes('File is too big')) {
        return `File must be smaller than ${CLIENT_DOCUMENT_MAX_FILESIZE} MB`;
      }
      return 'Failed to upload document. If problem persists, please contact PAS Operations.';
    },
  },
};
</script>
