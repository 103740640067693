<template>
  <div :class="`pas-icon-circle ${theme}-theme`"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'pas-icon-circle',
  props: {
    theme: {
      type: String,
      default: 'grey',
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';
.pas-icon-circle {
    border-radius: 90px;
    max-width: 25px !important;
    height: 25px;width: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 2px !important;
    cursor: pointer;
    i {
      font-size: 10px !important;
    }
    &.pas-theme {
      background-color: var(--primary-translucent);
      i {
        color: var(--primary-color) !important;
      }
    }
    &.grey-theme {
      background-color: rgba(0,0,0,0.05);
      i {
        color: rgba(0,0,0,0.7);
      }
    }
    &.pas-transparent-theme {
      border: 1px solid var(--primary-color) !important;
      background-color: transparent !important;
      i {
        color: var(--primary-color) !important;
      }
    }
  }
  .pas-icon-circle.small-icon-circle {
    height: 16px !important;
    width: 16px !important;
    max-height: 16px !important;
    min-height: 16px !important;
    max-width: 16px !important;
    min-width: 16px !important;
  }
</style>
