<template>
	<v-container>
    <PasPageTitle>Model Portfolio Details</PasPageTitle>
    <div v-if="missingModelPortfolios" class="my-3">
      <span :style="{ 'font-weight': 600 }">
        The following Model Portfolios are missing PDF documents:
      </span>
      {{missingModelPortfolios}}
    </div>
    <v-layout my-4 row justify-start>
      <v-flex mr-3>
        <PasSearchBox
          v-model="filterOptions.search"
          :placeholder="`Search document...`"
        />
      </v-flex>
      <v-flex>
        <PasInputFieldList
          :noDataText="'No filters available'"
          :items="subAdvisors"
          v-model="filterOptions.sortBySubadvisor"
        />
      </v-flex>
      <v-flex ml-3>
        <PasInputFieldList
          :noDataText="'No filters available'"
          :items="portfolioCategory"
          v-model="filterOptions.sortByPortfolio"
        />
      </v-flex>
      <v-flex v-if="canDeletePortfolioDetails" ml-3>
        <PasButton @click="uploadDocumentDialog = true" class="primary-btn px-5">
          Upload
        </PasButton>
      </v-flex>
    </v-layout>

    <PasList
      :columns="columns"
      :items="filteredDocuments"
      :itemName="'documents with model portfolio details'"
      :loading="false"
      @toggleSort=""
    >
      <template slot-scope="{ item }">
        <v-layout fill-height align-center md6>
          <v-flex xs1>
            <v-layout justify-center>
              <img height="32px" width="32px" :src="pdf">
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <div class="p-text">{{item.portfolio_category_name}}</div>
          </v-flex>
          <v-flex xs3 pl-4>
            <div class="p-text">{{item.sub_advisor_name}}</div>
          </v-flex>
          <v-flex xs3>
            <div class="p-text">{{item.model_portfolio_name}}</div>
          </v-flex>
          <v-flex xs2>
            <div class="p-text">{{moment(item.updated_at).format('YYYY/MM/DD')}}</div>
          </v-flex>
          <v-flex xs1>
            <v-layout fill-height row align-center justify-end pr-2>
              <PasFab>
              <v-icon @click="download(item)">mdi-download</v-icon>
            </PasFab>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </PasList>
    <PortfolioDetailsUpload
      :isOpen="uploadDocumentDialog"
      @close="closeUploadDialog"
      :documents="documents"
      :modelPortfolios="modelPortfolios"
    />
	</v-container>
</template>

<script>
import _ from 'lodash';
import { fetchModelPortfolioDetails } from 'Services/api/Documents';
import { modal } from 'Services/mixins';
import pdf from 'Assets/icons/pdf.svg';
import { mapGetters } from 'vuex';
import PortfolioDetailsUpload from './PortfolioDetailsUpload';


export default {
  name: 'PortfolioDetails',
  components: {
    PortfolioDetailsUpload,
  },
  data() {
    return {
      uploadDocumentDialog: false,
      documents: [],
      filteredDocuments: [],
      pdf,
      filterOptions: {
        search: '',
        sortBySubadvisor: 'All Subadvisors',
        sortByPortfolio: 'All Portfolios',
      },
      columns: [
        {
          header: '', size: 'xs1', isSortable: false,
        },
        {
          header: 'Portfolio Category', size: 'xs2', isSortable: false,
        },
        {
          header: 'Subadvisor', size: 'xs3 pl-4', isSortable: false,
        },
        {
          header: 'Model Portfolio', size: 'xs3', isSortable: false,
        },
        {
          header: 'Last Update', size: 'xs2', isSortable: false,
        },
        {
          header: '', size: 'xs1', isSortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'canDeletePortfolioDetails',
      'allModelPortfolios',
    ]),
    modelPortfolios() {
      return this.allModelPortfolios;
    },
    subAdvisors() {
      if (!this.documents.length) return [];
      const subAdvisors = _.uniq(this.documents.map((d) => d.sub_advisor_name));
      subAdvisors.push('All Subadvisors');
      return subAdvisors;
    },
    portfolioCategory() {
      if (!this.documents.length) return [];
      const portfolioCategories = _.uniq(this.documents.map((d) => d.portfolio_category_name));
      portfolioCategories.push('All Portfolios');
      return portfolioCategories;
    },
    computedFilterOptions() {
      return { ...this.filterOptions };
    },
    missingModelPortfolios() {
      const current = this.documents.map((d) => d.model_portfolio);
      return this.modelPortfolios.filter((mp) => !current.includes(mp.id))
        .map((mp) => mp.name).join(', ');
    },
  },
  mixins: [modal],
  async mounted() {
    if (!this.allModelPortfolios.length) await this.$store.dispatch('fetchAllModelPortfolios');
    this.fetchDocuments();
  },
  methods: {
    async fetchDocuments() {
      try {
        const { results } = await fetchModelPortfolioDetails();
        this.documents = results;
        this.filterAndSort();
      } catch {
        this.snackBar('fail', 'Please refresh the page');
      }
    },
    filterAndSort() {
      const opts = this.computedFilterOptions;
      this.filteredDocuments = _.cloneDeep(this.documents);
      if (opts.search) {
        this.filteredDocuments = this.filteredDocuments.filter((d) => {
          const searchWord = opts.search.toLowerCase();
          const documentNameCheck = d.document_name.toLowerCase().indexOf(searchWord) !== -1;
          const modelPortfolioCheck = d.model_portfolio_name.toLowerCase().indexOf(searchWord) !== -1;
          const portfolioCategoryCheck = d.portfolio_category_name.toLowerCase().indexOf(searchWord) !== -1;
          const subadvisorCheck = d.sub_advisor_name.toLowerCase().indexOf(searchWord) !== -1;
          return documentNameCheck || modelPortfolioCheck || portfolioCategoryCheck || subadvisorCheck;
        });
      }
      if (opts.sortBySubadvisor !== 'All Subadvisors') {
        this.filteredDocuments = this.filteredDocuments.filter((d) => d.sub_advisor_name === opts.sortBySubadvisor);
      }
      if (opts.sortByPortfolio !== 'All Portfolios') {
        this.filteredDocuments = this.filteredDocuments.filter((d) => d.portfolio_category_name === opts.sortByPortfolio);
      }
    },
    closeUploadDialog() {
      this.uploadDocumentDialog = false;
      this.fetchDocuments();
    },
    download(doc) {
      let url = `${window.location.protocol}//${window.location.host}`;
      url += `/advisor_portal/view_document?doc=${encodeURIComponent(doc.aws_key)}`;
      fetch(url, {
        method: 'GET',
        redirect: 'manual',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      }).then((res) => {
        if (res.error) throw Error;
        const filename = `${doc.document_name}.pdf`;

        res.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', filename);
          a.click();
        });
      });
    },
  },
  watch: {
    computedFilterOptions: {
      handler() {
        this.filterAndSort();
      },
      deep: true,
    },
    documents() {
      this.filterAndSort();
    },
  },
};

</script>
