<template>
  <div>
    <div style="margin-top: 36px">
      <pas-page-subtitle class="px-2">Portfolio Assignment</pas-page-subtitle>
      <div v-if="!loading && !unexisting">
        <v-layout class="pt-2">
          <span
            class='light-text'
          >
            The below responses were gathered from the client's risk questionnaire. This information helps to determine the initial assignment of an investment portfolio.
          </span>
        </v-layout>
        <v-container px-2>
          <v-flex v-if="fieldsToDisplay.includes('age')">
            <span class="strong-text">
              Age: &nbsp;
            </span>
            <span>{{age || ''}}</span>
          </v-flex>
          <v-flex v-if="fieldsToDisplay.includes('employment_status')">
            <span class="strong-text">
              Employment status: &nbsp;
            </span>
            <span>{{ $t(employmentStatus) }}</span>
          </v-flex>
          <v-flex v-if="fieldsToDisplay.includes('employer')">
            <span class="strong-text">
              Employer: &nbsp;
            </span>
            <span>{{ employer || ''}}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('annual_income')">
            <span class="strong-text">
              Annual Income: &nbsp;
            </span>
            <span>{{ annualIncome }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('liquid_assets')">
            <span class="strong-text">
              Liquid Assets: &nbsp;
            </span>
            <span>{{ liquidAssets }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('real_estate_other_fixed_assets')">
            <span class="strong-text">
              Real Estate & Other Fixed Assets: &nbsp;
            </span>
            <span>{{ realEstateOtherFixedAssets }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('debts')">
            <span class="strong-text">
              Debts: &nbsp;
            </span>
            <span>{{ debts }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('net_worth')">
            <span class="strong-text">
              Net Worth: &nbsp;
            </span>
            <span>{{ netWorth }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('debt_of_net_worth')">
            <span class="strong-text">Debt of Net Worth: &nbsp;</span>
            <span>{{ debtOfNetWorth }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('time_horizon')">
            <span class="strong-text">Time Horizon: &nbsp;</span>
            <span>
              {{ clientSuitability.time_horizon_options
                ? clientSuitability.time_horizon_options : '' }}
            </span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('goal_one')">
            <span class="strong-text">Goal 1: &nbsp;</span>
            <span>{{ clientSuitability.goal_option_one || '' }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('goal_two')">
            <span class="strong-text">Goal 2: &nbsp;</span>
            <span>{{ clientSuitability.goal_option_two || '' }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('goal_three')">
            <span class="strong-text">Goal 3: &nbsp;</span>
            <span>{{ clientSuitability.goal_option_three || '' }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('goal_and_risk_profile')">
            <span class="strong-text">Goal and Risk Profile: &nbsp;</span>
            <span>
              {{ goalAndRiskProfileOption }}
            </span>
          </v-flex>
          <v-flex
            pt-1
            v-if="fieldsToDisplay.includes('tolerance_and_capacity')"
          >
            <span class="strong-text">Tolerance and Capacity: &nbsp;</span>
            <span>{{ toleranceAndCapacityOption }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('cash_flow_needs')">
            <span class="strong-text">Cash Flow Needs: &nbsp;</span>
            <span>{{ formatCashFlowNeeds() }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('goal_score')">
            <span class="strong-text">Goal Score: &nbsp;</span>
            <span>{{ formatGoalScore(clientSuitability.goal_score) }}</span>
          </v-flex>
          <v-flex pt-1 v-if="fieldsToDisplay.includes('notes')">
            <span class="strong-text">Notes: &nbsp;</span>
            <span>{{clientSuitability.notes || '--'}}</span>
          </v-flex>
          <v-flex pt-1>
            <PasButton
              class="primary-btn mt-4 mx-2 wide-btn"
              @click="openMoal">
              Edit Goals
            </PasButton>
          </v-flex>
        </v-container>
      </div>
      <div v-else-if="loading">
        <pas-circular-loader
          class="pb-4"
        />
      </div>
      <div v-else>
        <span class='light-text ml-2'>Portfolio information is not available now.</span>
      </div>
    </div>
    <v-dialog v-model="modalOpen" :click:outside="close" max-height="60rem" max-width="60rem">
      <v-card class="pa-2" max-height="60rem" max-width="60rem">
        <v-form @submit.prevent ref="form" lazy-validation>
          <v-container>
            <v-layout wrap>
              <v-flex sm6 md3 v-if="fieldsToDisplay.includes('time_horizon')">
                <div class="strong-text my-3">Time Horizon</div>
                <pas-autocomplete
                  :items="timeHorizonDropOptions"
                  v-model="timeHorizon"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex sm6 md3 v-if="fieldsToDisplay.includes('goal_one')">
                <div class="strong-text my-3">Goal 1</div>
                <pas-autocomplete
                  :items="goal1DropOptions"
                  v-model="goalOne"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex sm6 md3 v-if="fieldsToDisplay.includes('goal_two')">
                <div class="strong-text my-3">Goal 2</div>
                <pas-autocomplete
                  :items="goalDropOptions"
                  v-model="goalTwo"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex sm6 md3 v-if="fieldsToDisplay.includes('goal_three')">
                <div class="strong-text my-3">Goal 3</div>
                <pas-autocomplete
                  :items="goalDropOptions"
                  v-model="goalThree"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex
                sm6
                md3
                v-if="fieldsToDisplay.includes('goal_and_risk_profile')"
              >
                <div class="strong-text my-3">Goal and Risk Profile</div>
                <pas-autocomplete
                  :items="consistencyOptions.map(e => e.text)"
                  v-model="goalAndRiskProfile"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex
                sm6
                md3
                v-if="fieldsToDisplay.includes('tolerance_and_capacity')"
              >
                <div class="strong-text my-3">Tolerance and Capacity</div>
                <pas-autocomplete
                  :items="consistencyOptions.map(e => e.text)"
                  v-model="toleranceAndCapacity"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex
                sm6
                md3
                v-if="fieldsToDisplay.includes('cash_flow_needs')"
              >
                <div class="strong-text my-3">Cash Flow Needs</div>
                <pas-autocomplete
                  :items="needCashFlowOptions"
                  v-model="needCashFlow"
                  :readonly="!canEditRiskProfile"
                  attach
                />
              </v-flex>
              <v-flex sm6 md3 v-if="fieldsToDisplay.includes('goal_score')">
                <div class="strong-text my-3">Goal Score</div>
                <pas-number-input
                  @upClick="incrementGoalScore()"
                  @downClick="decrementGoalScore()"
                  step="1"
                  min="0"
                  v-model="goalScore"
                  :readonly="!canEditRiskProfile"
                  class="editable-scores"
                />
              </v-flex>
              <v-flex v-if="fieldsToDisplay.includes('notes')">
                <div class="strong-text my-3">Notes</div>
                <PasTextarea
                  v-model="notes"
                  :rules="notesRules"
                  :readonly="!canEditRiskProfile"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <span class="error--text">{{ validationText }}</span>
              </v-flex>
            </v-layout>
            <v-layout my-3>
              <v-flex shrink>
                <PasButton
                  class="inverted-btn wide-btn"
                  @click="close"
                >Cancel</PasButton>
              </v-flex>
              <v-spacer />
              <v-flex shrink>
                <PasButton
                  class="primary-btn wide-btn"
                  :processing="saving"
                  @click="save"
                  :disabled="!canEditRiskProfile"
                >Save</PasButton>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { getAge } from 'Services/helpers/time';
import {
  updateClientPortfolio,
  createClientPortfolio,
  fetchFieldsToDisplay,
} from 'Services/api/PortfolioAssignment';
import { commaFormatted } from 'Services/helpers/currency';
import validation from 'Services/validation';

export default {
  name: 'EditablePortfolio',
  components: {},
  props: {
    clientPortfolio: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      consistencyOptions: [{ text: 'Consistent', value: false }, { text: 'Inconsistent', value: true }],
      needCashFlowOptions: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
      portfolioDiscussion: {},
      clientSuitability: {},
      unexisting: false,
      modalOpen: false,
      timeHorizon: null,
      goalOne: null,
      goalTwo: null,
      goalThree: null,
      goalAndRiskProfile: null,
      toleranceAndCapacity: null,
      needCashFlow: null,
      goalScore: '0.0',
      notes: '',
      notesRules: [validation.maxCharsOrEmpty(255)],
      validationText: '',
      saving: false,
      fieldsToDisplay: [],
    };
  },
  async mounted() {
    if (!this.goalOptions.length) await this.$store.dispatch('fetchGoalOptions');
    if (!this.timeHorizonOptions.length) await this.$store.dispatch('fetchtimeHorizonOptions');
    try {
      await this.fetchFieldsToDisplay();
      this.initClientPortfolio();
    } catch (err) {
      console.error(err);
      this.unexisting = true;
      this.loading = false;
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters([
      'goalOptions',
      'timeHorizonOptions',
      'canEditRiskProfile',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    age() {
      return this.activeClient.person.date_of_birth
        ? getAge(this.activeClient.person.date_of_birth)
        : '';
    },
    employmentStatus() {
      return this.activeClient.employment.employment_status
        ? `onboarding_survey:client_employment_status.inputs.employment_status.options.${this.activeClient.employment.employment_status}`
        : '';
    },
    employer() {
      return this.activeClient.employment.employer;
    },
    annualIncome() {
      return this.activeClient.financials.expected_income
        ? `$${commaFormatted(this.activeClient.financials.expected_income)}`
        : '';
    },
    liquidAssets() {
      return this.activeClient.financials.liquid_assets
        ? `$${commaFormatted(this.activeClient.financials.liquid_assets)}`
        : '';
    },
    realEstateOtherFixedAssets() {
      return this.activeClient.financials.fixed_personal_assets
        ? `$${commaFormatted(this.activeClient.financials.fixed_personal_assets)}`
        : '';
    },
    debts() {
      return this.activeClient.financials.total_debt
        ? `$${commaFormatted(this.activeClient.financials.total_debt)}`
        : '';
    },
    netWorth() {
      return this.activeClient.financials.net_worth
        ? `$${commaFormatted(this.activeClient.financials.net_worth)}`
        : '';
    },
    debtOfNetWorth() {
      const {
        net_worth: netWorth,
        total_debt: totalDebt,
      } = this.activeClient.financials;

      return netWorth && !Number.isNaN(parseFloat(totalDebt))
        ? `${((totalDebt / netWorth) * 100).toFixed(2)}%`
        : '';
    },
    timeHorizonDropOptions() {
      return _.concat('', this.timeHorizonOptions.map((e) => e.name));
    },
    goal1DropOptions() {
      return this.goalOptions.map((e) => e.name);
    },
    goalDropOptions() {
      return _.concat('', this.goalOptions.map((e) => e.name));
    },
    goalAndRiskProfileOption() {
      return this.consistencyOptions.find((e) => e.value === this.clientSuitability.goal_risk_threshold_flag)?.text;
    },
    toleranceAndCapacityOption() {
      return this.consistencyOptions.find((e) => e.value === this.clientSuitability.tolerance_capacity_bounds_flag)?.text;
    },
  },
  methods: {
    formatGoalScore(gs) {
      if (gs && !Number.isNaN(parseFloat(gs))) {
        return parseFloat(gs).toFixed(1);
      }

      return '0.0';
    },
    formatCashFlowNeeds() {
      const needCashFlows = this.clientSuitability?.need_cash_flow;
      return needCashFlows != null
        ? this.needCashFlowOptions.find((e) => e.value === this.clientSuitability.need_cash_flow).text
        : '';
    },
    openMoal() {
      this.modalOpen = true;
    },
    close() {
      this.modalOpen = false;
    },
    validateForm() {
      const goals = [this.goalOne, this.goalTwo, this.goalThree]
        .filter((g) => !!g);

      if (goals.length !== new Set(goals).size) {
        return 'You cannot save duplicate goals.';
      }

      if (this.notes.length > 255) {
        return 'Notes cannot be longer than 255 characters.';
      }
      return '';
    },
    async save() {
      this.validationText = this.validateForm();
      if (!this.validationText) {
        const body = {
          client: this.activeClient.client.id,
          time_horizon_options: this.timeHorizon
            ? this.timeHorizonOptions
              .find((e) => e.name === this.timeHorizon).id
            : null,
          goal_option_one: this.goalOne
            ? this.goalOptions.find((e) => e.name === this.goalOne).id
            : null,
          goal_option_two: this.goalTwo
            ? this.goalOptions.find((e) => e.name === this.goalTwo).id
            : null,
          goal_option_three: this.goalThree
            ? this.goalOptions.find((e) => e.name === this.goalThree).id
            : null,
          tolerance_capacity_bounds_flag: this.consistencyOptions
            .find((e) => e.text === this.toleranceAndCapacity)?.value,
          goal_risk_threshold_flag: this.consistencyOptions
            .find((e) => e.text === this.goalAndRiskProfile)?.value,
          need_cash_flow: this.needCashFlow,
          goal_score: this.goalScore,
          notes: this.notes,
        };
        this.saving = true;
        try {
          let res;
          if (this.clientSuitability.id) {
            res = await updateClientPortfolio(this.clientSuitability.id, body);
          } else {
            res = await createClientPortfolio(body);
          }
          this.$emit('portfolio-change', res);
          this.$store.dispatch('setSnackbar', { text: "Successfully updated client's portfolio", type: 'success' });
          this.$store.dispatch('flipSnackbarSwitch');
          this.close();
        } catch (err) {
          this.$store.dispatch('setSnackbar', { text: "Failed to update client's portfolio", type: 'fail' });
          this.$store.dispatch('flipSnackbarSwitch');
        }
        this.saving = false;
      }
    },
    initClientPortfolio() {
      if (this.clientPortfolio) {
        this.clientSuitability = this.clientPortfolio;

        const {
          time_horizon_options: timeHorizon,
          goal_option_one: goalOne,
          goal_option_two: goalTwo,
          goal_option_three: goalThree,
          goal_risk_threshold_flag: goalAndRiskProfile,
          tolerance_capacity_bounds_flag: toleranceAndCapacity,
          goal_score: goalScore,
          need_cash_flow: needCashFlow,
          notes,
        } = this.clientSuitability;
        if (timeHorizon) {
          this.timeHorizon = timeHorizon;
        }
        this.goalOne = goalOne;
        this.goalTwo = goalTwo;
        this.goalThree = goalThree;
        this.goalAndRiskProfile = this.consistencyOptions
          .find((e) => e.value === goalAndRiskProfile).text;
        this.toleranceAndCapacity = this.consistencyOptions
          .find((e) => e.value === toleranceAndCapacity).text;

        this.goalScore = this.formatGoalScore(goalScore);
        this.needCashFlow = needCashFlow;
        this.notes = notes || '';
      }
      if (!this.clientSuitability) {
        // suitability score doesn't exsit, need to create a new one
        this.clientSuitability = {
          client: this.activeClient.client.id,
          time_horizon_options: null,
          goal_option_one: null,
          goal_option_two: null,
          goal_option_three: null,
          tolerance_capacity_bounds_flag: false, // default to False, same with datahub model
          goal_risk_threshold_flag: false, // default to False, same with datahub model
          need_cash_flow: null,
          goal_score: '0.0',
        };
      }
    },
    async fetchFieldsToDisplay() {
      const fieldsToDisplay = await fetchFieldsToDisplay('portfolio_assignment');
      this.fieldsToDisplay = fieldsToDisplay.map((f) => f.display_field);
    },
    incrementGoalScore() {
      this.goalScore = (this.goalScore * 10 + 1) / 10;
    },
    decrementGoalScore() {
      this.goalScore = (this.goalScore * 10 - 1) / 10;
    },
  },
  watch: {
    clientPortfolio: {
      immediate: true,
      handler() {
        this.initClientPortfolio();
      },
    },
  },
};
</script>
