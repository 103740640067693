<template>
  <auth-page>
  </auth-page>
</template>


<script>
import api from 'Services/api';
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage';

export default {
  name: 'grant',
  components: {
    AuthPage,
  },
  mounted: async function () {
    const firm = this.firm
    const code = this.$route.query.code; // Use to retrieve the access token
    const state = this.$route.query.state;    // Used to prevent CSRF attacks
    const client_id = this.$auth.clientId;    // To tell Axon which client is requesting the token

    // Retrieve the code verifier from the local storage that we set during login
    const code_verifier = localStorage.getItem('code_verifier', undefined);
    const redirect_uri = localStorage.getItem('redirect_uri', undefined);

    // Get datahub base URL
    const datahubUrl = localStorage.getItem('datahubUrl');
    const url = new URL(datahubUrl);
    let baseUrl = `${url.protocol}//${url.hostname}`
    if (url.port) {
      baseUrl += `:${url.port}`
    }

    try {
      const res = await api.post('/advisor_portal/api/token_from_grant', {
            client_id: client_id,
            code: code,
            state: state,
            code_verifier: code_verifier,
            firm: firm,
            redirect_uri: redirect_uri});

      if (res.access && res.access != '') {
        localStorage.setItem('jwt', res.access)
        await this.$store.dispatch('fetchUserInfoOnLogin');
        this.$router.push({ path: '/' }).catch(() => {});
      } else {

        console.log('System has refused to grant access to authenticated user. Token=' + res.access)

        // Force a logout of the IdP. Otherwise, the user won't have a chance to change the
        // user credentials to use as the IdP will always return the same user as it was
        // successfully authenticated.
        console.log('Forcing logout of IdP');
        await this.$store.dispatch('idPUserLogOut', {
            reason: 'unauthorized'
          });
      }

    } catch (err) {
      console.log(err)
    }
  },
  computed: {
    firm() {
      return this.$store.getters.firm;
    },
  },
};
</script>
