<!-- eslint-disable max-len -->
<template>
  <v-dialog
    :value="isOpen"
    max-height="50rem"
    max-width="50rem"
    @input="closeModal"
  >
    <v-card
      class="pa-5"
      max-height="50rem"
      max-width="50rem"
    >
      <PasFab class="close-btn">
        <v-icon
          @click="closeModal"
          class="close-icon"
        >close</v-icon>
      </PasFab>
      <PasPageTitle>
        Client's profile cannot be edited at the moment
      </PasPageTitle>
      <div class="my-4">
        <p>
          You cannot edit this client's profile while their KYC is pending. For questions, contact us at
          <a
            :href="`mailto:${opsEmail}`"
            @click.stop=""
          >{{opsEmail}}</a>.
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getMetadata } from 'Services/api/metadata';
import { modal } from 'Services/mixins';

export default {
  name: 'CannotEditProfile',
  mixins: [modal],
  data() {
    return {
      value: this.isOpen,
      opsEmail: null,
    };
  },
  props: {
    isOpen: { type: Boolean },
  },
  watch: {
    async isOpen(value) {
      if (value) {
        // closeModal resets opsEmail, re-assign opsEmail when modal opens
        this.opsEmail = await getMetadata('pas_operations_email');
      }
    },
  },
};
</script>
