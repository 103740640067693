<template>
  <v-container>
    <div class="my-4">
      <PasPageTitle class="my-4" :style="{ display: 'inline'}">All Money Movements</PasPageTitle>
      <div class="subheading grey--text" :style="{ display: 'inline'}">({{searchResults}})</div>
    </div>

    <v-layout align-center mb-4>
      <v-flex xs5 md3>
        <PasSearchBox v-model="filterOptions.search" />
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex class="sort-by-text" shrink>Status:</v-flex>
      <v-flex shrink :style="{ maxWidth: '150px' }">
        <PasDropDown
          :value="filterOptions.status"
          @change="$set(filterOptions, 'status', $event)"
          class="money-movements-sorter"
          :items="statuses"
        />
      </v-flex>
      <v-flex class="sort-by-text" shrink>Period:</v-flex>
      <v-flex shrink>
        <PasDateRangePicker
          @input="$set(filterOptions, 'sortByPeriod', $event)"
          :style="{ width: '240px !important', marginBottom: '4px' }"
          :_start="filterOptions.sortByPeriod[0]"
          :_end="filterOptions.sortByPeriod[1]"
        />
      </v-flex>
    </v-layout>

    <MoneyMovementList
      :moneyMovements="filteredMoneyMovements"
      :loading="loading"
      @toggleSort="toggleSort($event)"
      :sortBy="filterOptions.sortBy"
      :sortDir="filterOptions.sortDir"
    />

    <v-flex xs12 text-xs-center mt-4>
      <PasPagination
        :length="paginationLength"
        @input="filterOptions.page = $event"
        :value="filterOptions.page"
      />
    </v-flex>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import MoneyMovementList from './MoneyMovementList';

export default {
  name: 'MyMoneyMovements',
  components: {
    MoneyMovementList,
  },
  data() {
    return {
      filteredMoneyMovements: [],
      filterOptions: {
        search: '',
        status: 'All',
        sortBy: '',
        sortDir: -1,
        page: 1,
        perPage: 15,
        sortByPeriod: [
          moment().subtract(2, 'years').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      },
      statuses: [
        'All',
        'Created',
        'Processing',
        'Pending',
        'Active',
        'Error',
        'Cancelled',
      ],
      paginationLength: 1,
      searchResults: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['allSystematics', 'hasFeatureFlag']),
    computedFilterOptions() {
      return { ...this.filterOptions };
    },
    isMoneyMovementFeature() {
      return this.hasFeatureFlag('money_movement');
    },
  },
  async mounted() {
    if (this.isMoneyMovementFeature) {
      this.loading = true;
      await this.$store.dispatch('fetchAllSystematics');
      this.loading = false;
      this.filterAndSort();
    }
  },
  methods: {
    toggleSort({ sortBy, sortDir }) {
      this.filterOptions.sortBy = sortBy;
      this.filterOptions.sortDir = sortDir;
    },
    clientFullName(clientDetails) {
      let fullName = '';
      if (clientDetails && clientDetails.first_name) {
        fullName += clientDetails.first_name;
      }
      if (clientDetails && clientDetails.last_name) {
        fullName += ` ${clientDetails.last_name}`;
      }
      return fullName;
    },
    filterAndSort() {
      const opts = this.filterOptions;
      this.filteredMoneyMovements = _.cloneDeep(this.allSystematics);
      // search filter
      if (opts.search) {
        this.filteredMoneyMovements = this.filteredMoneyMovements.filter(
          (c) => c.client_details
            && this.clientFullName(c.client_details)
              .toLowerCase()
              .indexOf(opts.search.toLowerCase()) !== -1,
        );
      }

      // status filter
      if (opts.status !== 'All') {
        this.filteredMoneyMovements = this.filteredMoneyMovements.filter(
          (t) => t.status && this.moneyMovementStatusCode(opts.status) === t.status,
        );
      }

      // date filter
      if (opts.sortByPeriod[0] && opts.sortByPeriod[1]) {
        this.filteredMoneyMovements = this.filteredMoneyMovements.filter(
          (t) => {
            const after = moment(opts.sortByPeriod[0]);
            const before = moment(opts.sortByPeriod[1]);
            const createdAt = t.created_at ? t.created_at.split('T')[0] : '';
            return (
              moment(createdAt).isSameOrAfter(after)
              && moment(createdAt).isSameOrBefore(before)
            );
          },
        );
      }
      this.filteredMoneyMovements = _.sortBy(
        this.filteredMoneyMovements,
        (x) => x.created_at,
      ).reverse();

      // sort filter
      if (opts.sortBy) {
        if (opts.sortBy === 'Client Name') {
          this.filteredMoneyMovements = this.filteredMoneyMovements.sort(
            (a, b) => (this.clientFullName(a.client_details).toLowerCase().trim()
              < this.clientFullName(b.client_details).toLowerCase().trim()
              ? opts.sortDir
              : opts.sortDir * -1),
          );
        }
        if (opts.sortBy === 'Date Created') {
          this.filteredMoneyMovements = this.filteredMoneyMovements.sort(
            (a, b) => (moment(b.created_at).isSameOrAfter(moment(a.created_at))
              ? opts.sortDir
              : opts.sortDir * -1),
          );
        }
        if (opts.sortBy === 'Amount') {
          this.filteredMoneyMovements = this.filteredMoneyMovements.sort(
            (a, b) => (parseInt(a.amount, 10) < parseInt(b.amount, 10)
              ? opts.sortDir
              : opts.sortDir * -1),
          );
        }
      }

      // display results count
      this.searchResults = this.filteredMoneyMovements.length;

      // pagination
      this.paginationLength = this.filteredMoneyMovements
        ? Math.ceil(
          this.filteredMoneyMovements.length / this.filterOptions.perPage,
        )
        : 1;
      const iStart = (opts.page - 1) * opts.perPage;
      const iEnd = opts.page * opts.perPage;
      this.filteredMoneyMovements = this.filteredMoneyMovements.slice(
        iStart,
        iEnd,
      );
    },
    moneyMovementStatusCode(status) {
      let statusCode = '0';
      switch (status) {
        case 'Created':
          // Created
          statusCode = '0';
          break;
        case 'Processing':
          // Processing
          statusCode = '1';
          break;
        case 'Pending':
          // Waiting on first transaction
          statusCode = '2';
          break;
        case 'Active':
          // Active
          statusCode = '3';
          break;
        case 'Error':
          // Error
          statusCode = '4';
          break;
        case 'Cancelled':
          // Cancelled
          statusCode = '5';
          break;
        default:
          break;
      }

      return statusCode;
    },
  },
  watch: {
    computedFilterOptions: {
      deep: true,
      handler(oldVal, newVal) {
        if (newVal.page === oldVal.page) this.filterOptions.page = 1;
        this.filterAndSort();
      },
    },
  },
};
</script>

<style lang="scss">
.money-movements-sorter {
  .v-input__slot {
    margin: 0px 10px 0px 10px;
  }
}
.sort-by-text {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
  margin-bottom: 4px;
  width: 50px !important;
}
</style>
