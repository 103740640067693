<template>
  <v-chip
    disabled
    @click="$emit('click')"
    class="pas-selectable-chip"
    :color="color"
    :text-color="textColor"
  >
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  name: 'pas-selectable-chip',
  props: {
    color: {
      default: 'white',
      type: String,
    },
    textColor: {
      default: '#757575',
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

  .pas-selectable-chip {
    border: 1px solid;
    border-color: #eeeeee !important;
    cursor: pointer !important;
    text-align: center !important;
    &.selected-chip {
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color) !important;
      color: white !important;
    }
    .v-chip__content {
      margin: auto !important;
      cursor: pointer !important;
    }
  }
</style>
