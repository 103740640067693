<template>
  <div style="margin-top: 36px">
    <pas-page-subtitle class="px-2">Portfolio Assignment</pas-page-subtitle>
    <div v-if="!loading && !unexisting">
      <v-layout class="pt-2">
      <span class='light-text'>The below responses were gathered from the client's risk questionnaire. This information helps to determine the initial assignment of an investment portfolio. </span>
      </v-layout>
      <v-container px-2>
        <v-flex>
          <span class="strong-text">Age: &nbsp;</span> <span>{{this.portfolioDiscussion.age || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Annual Income: &nbsp;</span> <span>{{this.activeClient.financials.expected_income || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Net Worth: &nbsp;</span> <span>{{this.portfolioDiscussion.net_worth || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Debt of Net Worth: &nbsp;</span> <span>{{this.portfolioDiscussion.debt_of_net_worth || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Time Horizon: &nbsp;</span> <span>{{this.portfolioDiscussion.time_horizon || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Three Goals: &nbsp;</span> <span>{{this.portfolioDiscussion.three_goals ? Object.values(this.portfolioDiscussion.three_goals).join(', '):''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Goal and Risk Profile: &nbsp;</span> <span>{{this.portfolioDiscussion.goal_and_risk_profile_discrepency ? "Inconsistent" : "Consistent" || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Tolerance and Capacity: &nbsp;</span> <span>{{this.portfolioDiscussion.tolerance_capacity_inconsistency ? "Inconsistent" : "Consistent" || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Portfolio Assigned: &nbsp;</span> <span>{{this.portfolioStatusForSurvey.portfolio.suitability_score_id ? 'Assigned' : 'Not assigned'}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Risk Profile: &nbsp;</span> <span>{{this.portfolioStatusForSurvey.risk.risk_approval_status || ''}}</span>
        </v-flex>
        <v-flex pt-1>
          <span class="strong-text">Portfolio Recommendation: &nbsp;</span> <span>{{this.portfolioStatusForSurvey.portfolio.portfolio_approval_status || ''}}</span>
        </v-flex>
      </v-container>
    </div>
    <div v-else-if="loading">
      <pas-circular-loader
        class="pb-4"
      ></pas-circular-loader>
    </div>
    <div v-else>
      <span class='light-text ml-2'>Portfolio information is not available now.</span>
    </div>
  </div>
</template>

<script>
import { getAge } from 'Services/helpers/time';
import { fetchPortfolioDiscussion, fetchPortfolioStatusForSurvey } from 'Services/api/PortfolioAssignment';
import { commaFormatted } from 'Services/helpers/currency';

export default {
  name: 'AssignmentDiscussionTab',
  components: {},
  props: {},
  data() {
    return {
      loading: true,
      portfolioDiscussion: {},
      unexisting: false,
      portfolioStatusForSurvey: {},
    };
  },
  async mounted() {
    try {
      this.portfolioDiscussion = await fetchPortfolioDiscussion(this.activeClient.client.id);
      this.portfolioStatusForSurvey = await fetchPortfolioStatusForSurvey(this.activeClient.client.id);
    } catch (err) {
      console.log(err);
      this.unexisting = true;
      this.loading = false;
    }
    this.loading = false;
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    age() {
      return this.activeClient.person.date_of_birth ? getAge(this.activeClient.person.date_of_birth) : null;
    },
    netWorth() {
      return this.activeClient.financials.net_worth ? commaFormatted(this.activeClient.financials.net_worth) : null;
    },
    debtOfNetWorth() {
      return this.activeClient.financials.net_worth && !isNaN(parseFloat(this.activeClient.financials.total_debt)) ? (this.activeClient.financials.total_debt / this.activeClient.financials.net_worth) * 100 : null;
    },
  },
};
</script>

<style>

</style>
