<template>
  <div>
    <v-form
      ref="client"
      lazy-validation
      @submit.prevent
    >
      <v-layout wrap mt-5>
        <v-flex
          v-for="(item, i) in corporation"
          :key="i"
          sm6 md4
          px-2 mb-5
          v-if="!item.isHidden"
        >
          <template v-if="!item.type === 0 || item.fieldName !== 'Gender Identity' || corporation.sex.value === 'other'">
          <div class="strong-text mb-3">
            {{item.fieldName}}
            <sup>{{item.required}}</sup>
            <v-icon
              size="15px"
              :style="{ 'margin-right': '10px' }"
              color="grey"
              v-if="item.fieldName === 'Email'">
              mdi-information
            </v-icon>
          </div>
          <v-btn
            class="favorite-number"
            :class="{ selected: item.preferredNumber }"
            fab
            depressed
            round
            @click="selectPreferredNumber(item.fieldName)"
            v-if="item.type === 2 && item.fieldName !== 'Fax Number' && item.fieldName !== 'Phone Number'"
          >
            <v-icon small>favorite</v-icon>
          </v-btn>
          <pas-phone-input
            v-model="item.value"
            v-if="item.type === 2"
            :rules="item.rules"
            :readonly="!editable"
          ></pas-phone-input>
          <pas-input-field
            v-model="item.value"
            v-if="item.type === 0"
            :rules="item.rules"
            :maxLength="item.maxLength"
            :readonly="!editable || item.readOnly"
          ></pas-input-field>
          <pas-autocomplete
            v-if="item.type === 3"
            :items="item.items"
            v-model="item.value"
            :rules="item.rules"
            :readonly="!editable"
          ></pas-autocomplete>
          </template>
        </v-flex>
      </v-layout>
    <div class="px-2">
        <div v-if="!modalMode">
          <div class="strong-text mb-3">Notes</div>
          <pas-textarea
            v-model="notes"
            :placeholder="`Write user notes here`"
            :readonly="!editable"
          ></pas-textarea>
        </div>
        <span class="error--text">{{validationText}}</span>
        <pas-button
          v-if="!modalMode && editable"
          class="primary-btn wide-btn"
          @click="save"
          :processing="processingCorporation"
        >Save</pas-button>
      </div>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import v from 'Services/validation';
import {
  EMPLOYMENTINDUSTRIES,
} from 'Services/constants';

export default {
  name: 'corporation-details-modal',
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const isFcc = this.$store.getters.custodian === 'fcc';
    const employmentIndustries = EMPLOYMENTINDUSTRIES.concat(['Other']).concat(['']).sort();

    return {
      corporation: {
        displayName: {
          fieldName: 'Display Name', value: null, rules: [v.isRequired()], type: 0, required: '*',
        },
        name: {
          fieldName: 'Name', value: null, rules: [v.isRequired()], required: '*', type: 0,
        },
        corporateEmail: {
          fieldName: 'Email', value: null, rules: [v.isValidEmail], type: 0, required: '*',
        },
        cityOfIncorp: {
          fieldName: 'City of Incorporation', value: null, rules: [v.isRequired(), v.isAlphabetical()], type: 0, required: '*',
        },
        provinceOfIncorp: {
          fieldName: 'State or Province of Incorporation', value: null, type: 0, required: '*', rules: [v.isAlphabetical(), v.specifiedChars(2)], maxLength: 2,
        },
        countryOfIncorp: {
          fieldName: 'Country of Incorporation (Country Code)', value: null, required: '*', rules: [v.isAlphabetical(), v.specifiedChars(2)], type: 0, maxLength: 2,
        },
        phone: {
          fieldName: 'Phone Number', value: null, rules: [v.isValidPhone], type: 2, required: '*',
        },
        faxNumber: {
          fieldName: 'Fax Number', value: null, rules: [v.emptyOrPhone], type: 2,
        },
        industry: {
          fieldName: 'Industry', value: null, rules: [v.isRequired()], required: '*', type: 3, items: employmentIndustries,
        },
        BIN: {
          fieldName: 'BIN', value: null, type: 0, required: '*', rules: [v.isAlphanumeric(), v.minChars(14), v.maxChars(15)], maxLength: 15,
        },
        reportingIssuer: {
          fieldName: 'Is the client a reporting issuer?', required: '*', value: null, rules: [v.isRequired()], type: 3, items: ['Yes', 'No'],
        },
        corporateSeal: {
          fieldName: 'Does the Legal Entity have a Corporate Seal?', value: null, required: '*', rules: [v.isRequired()], type: 3, items: ['Yes', 'No'],
        },
      },
      notes: '',
      v,
      validationText: '',
      processingCorporation: false,
      isEdited: false,
      isInit: true,
      isFcc,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    registeredOnIP() {
      return this.$store.getters.hasFeatureFlag('investor_portal') && this.activeClient.IPAccountStatus;
    },
  },
  methods: {
    selectPreferredNumber(fieldName) {
      this.corporation.homePhone.preferredNumber = (fieldName === 'Home Phone Number');
      this.corporation.workPhone.preferredNumber = (fieldName === 'Work Phone Number');
      this.corporation.mobilePhone.preferredNumber = (fieldName === 'Mobile Phone Number');
    },
    handleResponses(type, msg) {
      const text = msg !== null ? msg : `${type === 'fail' ? 'Failed to update' : 'Updated'} Client Details`;
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type === 'fail' ? 'fail' : 'success');
    },
    async save() {
      this.validationText = '';
      if (!this.$refs.client.validate()) return null;
      this.processingCorporation = true;
      const data = {
        id: this.activeClient.client.id,
        display_name: this.corporation.displayName.value,
        fax_number: this.corporation.faxNumber.value || null,
        quick_notes: this.notes,
        client_type: 'corporation',
      };

      data.city_of_incorporation = this.corporation.cityOfIncorp.value || null;
      data.state_or_province = this.corporation.provinceOfIncorp.value || null;
      data.country_of_incorporation = this.corporation.countryOfIncorp.value || null;
      data.industry = this.corporation.industry.value || null;
      data.name = this.corporation.name.value || null;
      data.business_id_number = this.corporation.BIN.value || null;
      data.office_number = this.corporation.phone.value || null;
      data.email = this.corporation.corporateEmail.value || null;
      data.corporate_seal = this.corporation.corporateSeal.value === 'Yes';
      if (this.corporation.reportingIssuer.value === 'Yes') {
        data.reporting_issuer = 1;
      } else if (this.corporation.reportingIssuer.value === 'No') {
        data.reporting_issuer = 2;
      } else {
        data.reporting_issuer = 0;
      }
      await this.$store.dispatch('updateClient', data)
        .then((res) => this.handleResponses(res.id ? 'success' : 'fail', res.id ? 'Corporation details successfully updated.' : 'Error in updating client profile.'))
        .catch((err) => this.handleResponses('fail', err));
      this.processingCorporation = false;
    },
  },
  mounted() {
    const client = _.cloneDeep(this.activeClient);
    console.log(client.person);
    if (client.person) {
      this.corporation.BIN.value = client.person.business_id_number || null;
      this.corporation.cityOfIncorp.value = client.person.city_of_incorporation || null;
      this.corporation.provinceOfIncorp.value = client.person.state_or_province || null;
      this.corporation.countryOfIncorp.value = client.person.country_of_incorporation || null;
      this.corporation.industry.value = client.person.industry || null;
      this.corporation.name.value = client.person.name || null;
      this.corporation.reportingIssuer.value = [null, 'Yes', 'No'][client.person.reporting_issuer];
      this.corporation.corporateSeal.value = client.person.corporate_seal ? 'Yes' : 'No';
    }
    if (client.client) {
      this.corporation.displayName.value = client.client.display_name || null;
      this.corporation.corporateEmail.value = client.client.email || null;
      this.corporation.phone.value = client.client.office_number || null;
      this.corporation.faxNumber.value = client.client.fax_number || null;
      this.notes = client.client.quick_notes || '';
      // Initial email validation here
      this.corporation.corporateEmail.rules = [v.isValidEmail];
    }

    this.isEdited = false;
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        if (!this.isEdited) {
          const client = _.cloneDeep(this.activeClient);
          if (client.person) {
            this.corporation.BIN.value = client.person.business_id_number || null;
            this.corporation.cityOfIncorp.value = client.person.city_of_incorporation || null;
            this.corporation.provinceOfIncorp.value = client.person.state_or_province || null;
            this.corporation.countryOfIncorp.value = client.person.country_of_incorporation || null;
            this.corporation.industry.value = client.person.industry || null;
            this.corporation.name.value = client.person.name || null;
            this.corporation.reportingIssuer.value = [null, 'Yes', 'No'][client.person.reporting_issuer];
            this.corporation.corporateSeal.value = client.person.corporate_seal ? 'Yes' : 'No';
          }
          if (client.client) {
            this.corporation.displayName.value = client.client.display_name || null;
            this.corporation.corporateEmail.value = client.client.email || null;
            this.corporation.phone.value = client.client.office_number || null;
            this.corporation.faxNumber.value = client.client.fax_number || null;
            this.notes = client.client.quick_notes || '';
          }
        }
      },
      deep: true,
    },
    corporation: {
      handler(val, oldVal) {
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
      deep: true,
    },
  },
};

</script>

<style lang="scss">
.favorite-number {
  &:hover {
    border-style: none !important;
  }
  position: absolute !important;
  right: 3px;
  bottom: 42px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white !important;
  .v-icon {
    color: rgba(0,0,0,0.2) !important;
  }
}
.favorite-number.selected {
  background-color: #EAEAEA !important;
  border: none !important;
}
</style>
