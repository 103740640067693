<template>
  <div v-if="clientId">
    <PasPageHeader :path="`/clients/${clientId}`" v-if="!hideHeader">
      {{clientDisplayName}}
    </PasPageHeader>
    <div class="tabs-page-view">
      <risk-tolerance-tab />
      <AssignmentDiscussionTab v-if="portfolioAssignmentFeatureEnabled && !ipsFeatureEnabled" />
      <EditablePortfolio
        v-if="ipsFeatureEnabled"
        :editable="canEditRiskProfile"
        :clientPortfolio="clientPortfolio"
        @portfolio-change="portfolioChange"
      />
      <PortfolioAssignmentTab
        v-if="portfolioAssignmentFeatureEnabled || ipsFeatureEnabled"
        :editable="canEditRiskProfile"
        :clientPortfolio="clientPortfolio"
        @portfolio-change="portfolioChange"
      />
      <PasPageSubtitle class="px-2 py-4">Discussion</PasPageSubtitle>
      <PasDiscussion
        :categoryId="1"
        :clientId="clientId"
        :maxChars="5000"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fetchClientPortfolio } from 'Services/api/PortfolioAssignment';
import PasDiscussion from 'Components/Shared/Discussion/Discussion';
import RiskToleranceTab from './RiskToleranceTab';
import PortfolioAssignmentTab from './PortfolioAssignmentTab';
import AssignmentDiscussionTab from './AssignmentDiscussionTab';
import EditablePortfolio from './EditablePortfolio';

export default {
  name: 'RiskProfileTab',
  components: {
    AssignmentDiscussionTab,
    EditablePortfolio,
    PasDiscussion,
    PortfolioAssignmentTab,
    RiskToleranceTab,
  },
  props: {
    clientID: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientPortfolio: null,
    };
  },
  methods: {
    async portfolioChange(clientPortfolio) {
      if (!this.clientId) {
        return;
      }

      if (clientPortfolio) {
        this.clientPortfolio = clientPortfolio;
        return;
      }

      const { results } = await fetchClientPortfolio(this.clientId);
      [this.clientPortfolio] = results;
    },
  },
  computed: {
    ...mapGetters([
      'canEditRiskProfile',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    portfolioAssignmentFeatureEnabled() {
      return this.$store.state.app.features.portfolio_assignment;
    },
    ipsFeatureEnabled() {
      return this.$store.state.app.features.ips;
    },
    clientId() {
      return this.$store.state.clients.activeClient?.client?.id;
    },
    clientDisplayName() {
      return this.activeClient?.client?.display_name;
    },
  },
  watch: {
    clientID: {
      immediate: true,
      async handler() {
        await this.$store.dispatch('fetchClientDetails', this.clientID);
        await this.portfolioChange();
      },
    },
  },
};
</script>
