<template>
  <div>
    <confirmation-auth v-if="showAuthPage" @onAuthSubmit="checkClientAuth" />
    <v-container v-else-if="showMainPage" class="client-confirmation-container">
      <div v-if="error">
        <v-card class="pa-4">
          <v-layout mb-4>
            <v-flex xs12 text-xs-center>
              <PasPageTitle>Could not load data.</PasPageTitle>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
      <div v-else-if="!submitted">
        <client-questions v-if="isFATCAStep" @submit="submitClientAnswers" />
        <client-info v-else-if="isClientInfoStep" @submit="submitClientDetailsForm" />
      </div>
      <confirmation-submitted
        v-else
        :redirectToSurvey="redirectToSurvey"
        :approved="approved"
        :clientId="clientId"
        :firmId="firmId"
      />
    </v-container>
    <v-container v-else-if="showInvalidSessionPage">
      <confirmation-not-found />
    </v-container>
    <v-container v-else-if="showSubmissionPage">
      <confirmation-submitted
        :redirectToSurvey="redirectToSurvey"
        :approved="approved"
        :clientId="clientId"
        :firmId="firmId"
      />
    </v-container>
  </div>
</template>

<script>
import {
  submitClientTaxAnswers,
  submitClientDecision,
} from 'Services/api/ClientConfirmationTool';

import ConfirmationAuth from './ConfirmationAuthentication';
import ConfirmationSubmitted from './ConfirmationSubmitted';
import ConfirmationNotFound from './ConfirmationNotFound';
import ClientInfo from './ClientInfo';
import ClientQuestions from './ClientQuestions';

export default {
  components: {
    ConfirmationNotFound,
    ConfirmationSubmitted,
    ConfirmationAuth,
    ClientInfo,
    ClientQuestions,
  },
  watch: {
    isClientAuthenticated(newVal) {
      if (newVal === true) {
        if (this.isPerson && !this.isFatcaCrsComplete) {
          this.step = 1;
        } else if (this.noCall) {
          this.step = 2;
        } else {
          this.submitted = true;
          this.approved = true;
        }
      }
    },
  },
  data() {
    return {
      clientId: null,
      isPerson: false,
      isFatcaCrsComplete: false,
      isConfirmationIdValid: false,
      isClientAuthenticated: false,
      authenticationAttempted: false,
      approved: false,
      submitted: false,
      redirectToSurvey: true,
      error: false,
      step: 0,
    };
  },
  computed: {
    sessionToken() {
      return this.$route.query.sessionToken;
    },
    firmId() {
      return this.$route.query.firm;
    },
    noCall() {
      return this.$route.query.noCall === 'true';
    },
    isFATCAStep() {
      return this.step === 1;
    },
    isClientInfoStep() {
      return this.step === 2;
    },
    showAuthPage() {
      return (
        !this.isClientAuthenticated
        && !this.isConfirmationIdValid
        && !this.authenticationAttempted
      );
    },
    showMainPage() {
      return (
        this.isClientAuthenticated
        && this.isConfirmationIdValid
        && this.authenticationAttempted
      );
    },
    showInvalidSessionPage() {
      return (
        !this.isClientAuthenticated
        && !this.isConfirmationIdValid
        && this.authenticationAttempted
      );
    },
    showSubmissionPage() {
      return (
        !this.isClientAuthenticated
        && this.isConfirmationIdValid
        && this.authenticationAttempted
      );
    },
  },
  methods: {
    checkClientAuth(authResponse) {
      if (authResponse.tokenValid) {
        this.isConfirmationIdValid = true;
        this.clientId = authResponse.clientId;
        this.isPerson = authResponse.isPerson;
        this.isFatcaCrsComplete = authResponse.isFatcaCrsComplete;
      }
      if (authResponse.authenticated) {
        this.isClientAuthenticated = true;
        this.displaySnackbar('You have been authenticated');
      }
      if (authResponse.tokenStatus.toLowerCase() === 'confirmed' || authResponse.tokenStatus.toLowerCase() === 'completed') {
        this.approved = true;
        this.submitted = true;
      }
      if (authResponse.tokenStatus.toLowerCase() === 'denied') {
        this.approved = false;
        this.submitted = true;
      }
      this.authenticationAttempted = true;
    },
    submitClientDetailsForm(clientAction) {
      this.approved = clientAction;
      const status = clientAction ? 'Confirmed' : 'Denied';
      submitClientDecision(this.sessionToken, status).then(() => {
        this.submitted = true;
        this.displaySnackbar(
          `Your details have been ${status.toLowerCase()} successfully`,
        );
      });
    },
    submitClientAnswers(answers) {
      answers.client = this.clientId;
      submitClientTaxAnswers(answers)
        .then(() => {
          if (this.noCall) {
            this.step = 2;
          } else {
            this.approved = true;
            submitClientDecision(this.sessionToken, 'Completed').then(() => {
              this.submitted = true;
            });
          }
          this.displaySnackbar('Answers submitted successfully');
        })
        .catch((err) => {
          // if (this.noCall) {
          //   this.step = 2;
          // } else {
          //   this.approved = true;
          //   submitClientDecision(this.sessionToken, 'Completed').then(() => {
          //     this.submitted = true;
          //   });
          // }
          console.log(err);
          this.displaySnackbar(err, true);
        });
    },
    displaySnackbar(message, error) {
      this.$store.dispatch('setSnackbar', {
        text: message,
        type: error ? 'error' : 'success',
      });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
};
</script>

<style lang="scss" scoped>
.client-confirmation-container {
  max-width: 920px;
}
</style>
