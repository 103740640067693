<template>
	<v-dialog :value="isOpen" persistent fullscreen>
        <v-card :style="{ overflow: 'hidden !important' }">
            <PasFab class="close-btn">
                <v-icon @click="closeModal" class="close-icon">close</v-icon>
            </PasFab>
            <v-container class="modal-container">
                <v-layout mb-3>
                    <v-flex xs12 text-xs-center>
                        <pas-page-title>Create New Account Transfer</pas-page-title>
                    </v-flex>
                </v-layout>
                <v-layout mb-5>
                    <v-flex xs12 text-xs-center>
                        <span>Please fill out information below</span>
                    </v-flex>
                </v-layout>
                <v-form @submit.prevent ref="form" lazy-validation>
                    <v-layout column>
                        <v-flex>
                            <v-layout row>
                                <v-flex px-2 xs12 md5>
                                    <div class="strong-text my-4">Select External Account Type</div>
                                    <pas-autocomplete
                                        :items="accountTypes"
                                        :rules="[v => !!v || 'Account type is required']"
                                        v-model="accountType"
                                    ></pas-autocomplete>
                                </v-flex>
                                <v-flex px-2 xs8 md5>
                                    <div class="strong-text my-4">Transfer Type</div>
                                    <pas-autocomplete
                                        :items="transferTypes"
                                        :rules="[v => !!v || 'Transfer type is required']"
                                        v-model="transferType"
                                    ></pas-autocomplete>
                                </v-flex>
                                <v-flex px-2 xs4 md2 v-if="transferType === 'ALL'">
                                    <div class="strong-text my-4">Transfer Method</div>
                                    <pas-autocomplete
                                        :items="transferMethods"
                                        :rules="[v => !!v || 'Transfer method is required']"
                                        v-model="transferMethod"
                                    ></pas-autocomplete>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <div v-if="(transferType === 'ALL' && transferMethod === 'Mixed') || transferType === 'SOME'">
                                <v-form ref="assetForm">
                                    <v-layout row>
                                        <v-flex px-2 xs8 md4>
                                            <div class="strong-text my-4">Transfer Method</div>
                                            <pas-autocomplete
                                                :items="displayedAssetTransferTypes"
                                                :rules="[v => !!v || 'Transfer method is required']"
                                                v-model="asset.transferType"
                                            ></pas-autocomplete>
                                        </v-flex>
                                        <template v-if="acccountTransferMethods[asset.transferType] === 'In-Kind'">
                                          <v-flex px-2 xs8 md4>
                                              <div class="strong-text my-4">Symbol</div>
                                              <pas-input-field
                                                  v-model="asset.symbol"
                                                  :rules="[v => !!v || 'Asset symbol is required']"
                                              ></pas-input-field>
                                          </v-flex>
                                          <v-flex px-2 xs8 md4>
                                              <div class="strong-text my-4">Description</div>
                                              <pas-input-field
                                                  v-model="asset.description"
                                                  :rules="[v => !!v || 'Asset description is required']"
                                              ></pas-input-field>
                                          </v-flex>
                                        </template>
                                        <v-flex px-2 xs8 md4>
                                            <div class="strong-text my-4">
                                                {{ !asset.transferType || acccountTransferMethods[asset.transferType] === 'In-Cash' ? 'Amount' : 'Quantity'}}
                                            </div>
                                            <pas-input-currency
                                                v-model="asset.amount"
                                                v-if="refreshAsset && (!asset.transferType || acccountTransferMethods[asset.transferType] === 'In-Cash')"
                                                :rules="[v => !!v || 'Amount is required',v.isCurrency, v.isPositive]"
                                            ></pas-input-currency>
                                            <pas-input-field
                                                v-else
                                                v-model="asset.quantity"
                                                :rules="[v => !!v || 'Asset quantity is required', v.isNumeric(), v.isPositive]"
                                                required
                                            ></pas-input-field>
                                        </v-flex>
                                        <v-flex align-center mt-3 px-2 xs8 md4>
                                          <!--TODO: Need to add the asset validation for both FWP / MIL in the near future.-->
                                          <template v-if="firm === 'har_fcc' || firm === 'har'">
                                            <v-tooltip
                                              top
                                              :disabled="assetTransfers.length < 3"
                                            >
                                              <pas-add-item
                                                  :text="'Save Asset'"
                                                  @click="addAsset"
                                                  class="mt-5 pt-3"
                                                  slot="activator"
                                                  :disabled="assetTransfers.length === 3"
                                              ></pas-add-item>
                                              <span>When transferring more than 3 assets, please email completed transfer form to inquiries@harnessinvest.ca</span>
                                            </v-tooltip>
                                          </template>
                                          <template v-else>
                                            <pas-add-item
                                                :text="'Save Asset'"
                                                @click="addAsset"
                                                class="mt-5 pt-3"
                                            ></pas-add-item>
                                          </template>
                                        </v-flex>
                                    </v-layout>
                                </v-form>
                                <v-layout mt-4 row wrap>
                                    <v-flex shrink v-for="(asset, index) in assetTransfers" :key="index">
                                        <v-chip color="#eeeeee" class="little-pill">
                                            {{ acccountTransferMethods[asset.transferType] === 'In-Cash' ? `$${asset.amount}` : `${asset.symbol}: ${asset.quantity}` }}
                                            <v-icon @click="assetTransfers.splice(index, 1)" :style="{fontSize: '16px', opacity: '0.6'}" class="ml-3">close</v-icon>
                                        </v-chip>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-4 row wrap v-if="transferMethod === 'Mixed' && assetTransfers.length">
                                    <v-flex px-2 xs8>
                                        <div class="strong-text my-4">
                                            Are there any assets (cash or otherwise) in your external account that are not listed above, and that you wish to transfer over to {{`${firmFullName}`}}?
                                        </div>
                                    </v-flex>
                                    <v-flex px-2 xs8 md4>
                                        <v-switch
                                        :color="primaryColor"
                                        v-model="leftoverAssetsExist"
                                        :label="leftoverAssetsExist ? 'Yes' : 'No'"
                                        ></v-switch>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-4 row wrap v-if="leftoverAssetsExist">
                                    <v-flex px-2 xs8>
                                        <div class="strong-text my-4">
                                            How would you like the remaining assets to be transferred?
                                        </div>
                                    </v-flex>
                                    <v-flex px-2 xs8 md4>
                                        <v-radio-group v-model="leftoverTransferMethod" row class="pas-radio-group"  :rules="[v.isRequired()]">
                                            <v-radio :color="primaryColor" :value="2" :label="`In-Cash`"></v-radio>
                                            <v-radio :color="primaryColor" :value="1" :label="'In-Kind'"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex>
                            <v-layout>
                                <v-flex px-2 xs8 md3>
                                    <div class="strong-text my-4">Investment Account Internal</div>
                                    <pas-autocomplete
                                        :items="investmentAccounts"
                                        :rules="[v => !!v || 'Investment account is required']"
                                        v-model="investmentAccount"
                                        noDataText='No internal accounts available with selected account type.'
                                    ></pas-autocomplete>
                                </v-flex>
                                <v-flex px-2 xs8 md3>
                                    <div class="strong-text my-4">Estimated Transfer Amount</div>
                                    <pas-input-currency
                                        v-if="refresh"
                                        ref="inputCurrency"
                                        v-model="estimatedTransferAmount"
                                        :rules="[v.isRequired(), v.isCurrency, v.isPositive]"
                                    ></pas-input-currency>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex>
                            <v-layout row wrap>
                                <v-flex px-2 xs12 md4>
                                    <div class="strong-text my-4">Relinquishing Institution</div>
                                    <pas-autocomplete
                                        :items="relinquishingInstitutions"
                                        :rules="[v => !!v || 'Relinquishing institution is required']"
                                        v-model="relinquishingInstitution"
                                    ></pas-autocomplete>
                                </v-flex>
                                <v-flex px-2 xs12 md5 lg4>
                                    <div class="strong-text my-4">Account Number At Relinquishing Institution</div>
                                    <pas-input-field
                                        v-model="externalAccountNumber"
                                        :rules="[v => !!v || 'Account number is required']"
                                    ></pas-input-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex>
                            <div class="strong-text my-4">Upload An External Account Statement</div>
                        </v-flex>
                        <v-flex>
                            <pas-dropzone
                                ref="pasDropzone"
                                :id="'account-transfer-drapzone'"
                                :options="dropzoneOptions"
                            ></pas-dropzone>
                        </v-flex>
                    </v-layout>
					<pas-error-div :show="errorText ? true : false">{{errorText}}</pas-error-div>
                    <v-layout mt-4 row>
                        <v-spacer></v-spacer>
                        <pas-button
                            class="primary-btn wide-btn"
                            @click="save"
                            :disabled="saving"
                            :processing="isDocumentUploading"
                        > Save </pas-button>
                    </v-layout>
                </v-form>
            </v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACCOUNT_TYPE_LONG,
  ACCOUNT_TRANSFER_METHODS,
  ACCOUNT_TYPE_EXCLUDED_EXTERNAL_TRANSFER_LONG,
  CLIENT_DOCUMENT_MAX_FILESIZE,
  DOCUMENT_SOURCE,
  ACCOUNTTYPE,
  EXTERNAL_TRANSFER_TO_ACCOUNT_TYPE_LIST_FOR_EXTERNAL_ACCOUNT_TYPE
} from 'Services/constants';
import { modal, cursorToEnd } from 'Services/mixins';

import babelPolyfill from '@babel/polyfill';
import v from 'Services/validation';
import _ from 'lodash';

// move thie call to store
import api from 'Services/api';
import { getMetadata } from 'Services/api/metadata';

export default {
  name: 'add-new-account-transfer',
  mixins: [modal, cursorToEnd],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const that = this;
    return {
      accountType: null,
      transferType: null,
      transferTypes: [
        { value: 'ALL', text: 'Transfer ALL assets in account' },
        { value: 'SOME', text: 'Transfer SOME of the assets in account' },
      ],
      transferMethod: null,
      transferMethods: [
        'In-Kind',
        'In-Cash',
      ],
      calculatedTransferMethod: null,
      assetTransfers: [],
      asset: {
        transferType: null,
        symbol: null,
        description: null,
        assetAmount: null,
        assetQuantity: null,
      },
      assetTransferTypes: [
        { text: 'In-Kind', value: 1 },
        { text: 'In-Cash', value: 2 },
      ],
      investmentAccount: null,
      leftoverAssetsExist: false,
      leftoverTransferMethod: 0,
      estimatedTransferAmount: null,
      firmFullName: null,
      relinquishingInstitution: null,
      externalAccountNumber: null,
      saving: false,
      refresh: true,
      refreshAsset: true,
      errorText: '',
      v,
      acccountTransferMethods: ACCOUNT_TRANSFER_METHODS,
 			dropzoneOptions: {
        url: '/advisor_portal/upload_document',
        init() {
				    this.on('sending', (file, xhr, formData) => {
            that.updateDocumentLoading(true);
            formData.append('client_id', that.activeClient.client.id);
            formData.append('document_type', 12);
            formData.append('document_name', 'External Account Statement');
            formData.append('document_source', that.documentSource.Manual);
          });
          this.on('success', (file, response) => {
            that.documentUploadReponse(response.message === 'Success' ? 'success' : 'fail');
            that.updateDocumentLoading(false);
            if (response.message !== 'Success') {
              this.removeFile(file);
            }
          });
          this.on('error', (file, response) => {
            that.documentUploadReponse('fail');
            that.updateDocumentLoading(false);
            this.removeFile(file);
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: CLIENT_DOCUMENT_MAX_FILESIZE,	// MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 3000000,	// Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
      },
      isDocumentUploading: false,
    };
  },
  mounted() {
    getMetadata('firm_name_full').then((res) => {
      this.firmFullName = res;
    });
  },
  computed: {
    ...mapGetters([
      'relinquishingInstitutions',
    ]),
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    investmentAccounts() {
      if (EXTERNAL_TRANSFER_TO_ACCOUNT_TYPE_LIST_FOR_EXTERNAL_ACCOUNT_TYPE[this.externalAccountType]) {
        return this.activeClient.investment_accounts
          .filter((investmentAccount) => EXTERNAL_TRANSFER_TO_ACCOUNT_TYPE_LIST_FOR_EXTERNAL_ACCOUNT_TYPE[this.externalAccountType]
            .includes(investmentAccount.account_type_short))
          .map(({ account_no_dataphile: accountNoDataphile, id, nickname }) => ({
            text: `${accountNoDataphile} - ${nickname || ''}`,
            value: id,
          }));
      }
      return this.activeClient.investment_accounts
        .filter((investmentAccount) => investmentAccount.account_type_short === this.externalAccountType)
        .map(({ account_no_dataphile: accountNoDataphile, id, nickname }) => ({
          text: `${accountNoDataphile} - ${nickname || ''}`,
          value: id,
        }));
    },
    transfers() {
      return this.activeClient.transfers;
    },
    accountTypes() {
      return ACCOUNT_TYPE_LONG.filter(
        (account) => !ACCOUNT_TYPE_EXCLUDED_EXTERNAL_TRANSFER_LONG
          .includes(account),
      );
    },
    displayedAssetTransferTypes() {
      if (this.transferType === 'Partial') return this.assetTransferTypes.slice(0, 2);
      return this.assetTransferTypes.slice(0, 3).filter((a) => a.text !== this.transferMethod);
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
    externalAccountType() {
      return String(ACCOUNT_TYPE_LONG.indexOf(this.accountType));
    },
    firm() {
      return this.$store.getters.firm;
    },
  },
  watch: {
    transferType() {
      this.calculatedTransferMethod = this.transferType === 'ALL' ? this.transferMethod : 'Partial';
    },
    transferMethod() {
      this.calculatedTransferMethod = this.transferType === 'ALL' ? this.transferMethod : 'Partial';
    },
    isOpen(val) {
      if (val && !this.$store.state.lists.relinquishingInstitutions.length) {
        this.$store.dispatch('fetchRelinquishingInstitutions');
      }
    },
  },
  methods: {
    tail: ([, ...t]) => t,
    addAsset() {
      if (!this.$refs.assetForm.validate()) return false;
      const shallowAsset = { ...this.asset };
      this.assetTransfers.push(shallowAsset);
      this.$refs.assetForm.reset();
      this.refreshAsset = false;
      this.asset.assetAmount = 0;
      this.$nextTick().then(() => { this.refreshAsset = true; });
    },
    documentUploadReponse(type) {
      let text = 'Oops... Something went wrong, please try uploading the file again';
      if (type === 'success') {
        text = 'Document uploaded successfully!';
        this.$store.dispatch('setDocuments', this.activeClient.client.id);
      }
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    validate() {
      if (!(this.$refs.form.validate())) return false;
      if (this.assetTransfers.length === 0 && (this.transferMethod === 'Mixed' || this.transferType === 'SOME')) {
        this.errorText = 'Please add assets to transfer';
        return false;
      }
      this.errorText = '';
      return true;
    },
    updateDocumentLoading(value) {
      this.isDocumentUploading = value;
    },
    async save() {
      if (!this.validate()) return false;
      this.saving = true;
      try {
        const transfer = await api.post('/datahub/external_account_transfers/', {
          account_number_external: this.externalAccountNumber,
          account_type_short: ACCOUNT_TYPE_LONG.indexOf(this.accountType),
          account_subtype: '0',
          amount_estimated: this.estimatedTransferAmount,
          transfer_method: ACCOUNT_TRANSFER_METHODS.indexOf(this.calculatedTransferMethod),
          transfer_status: '0',
          relinquishing_institution: this.relinquishingInstitution,
          relinquishing_transit_code: null,
          locked_in_indicator: this.accountType[0] === 'L',
          locked_in_legislation: null,
          margin_status: this.accountType.includes('M'),
          short_status: this.accountType.slice(-1) === 'S' || this.accountType.slice(-2) === 'SH',
          option_status: 'NA',
          date_completed: null,
          status: null,
          pas_account: this.investmentAccount,
        });
        if (!transfer.id) throw Error('error generating an external account transfer');
        if (this.assetTransfers.length) {
          if (this.leftoverAssetsExist) {
            const leftoverAsset = {};
            leftoverAsset.transferType = this.leftoverTransferMethod,
            leftoverAsset.isLeftover = true,
            this.assetTransfers.push(leftoverAsset);
          }
          const transferAssets = await Promise.all(
            this.assetTransfers.map((asset) => api.post('/datahub/external_account_transfer_assets/', {
              transfer_method: asset.transferType,
              symbol: asset.symbol || null,
              description: asset.description || null,
              amount: asset.amount || null,
              quantity: asset.quantity || null,
              all_other_positions: asset.isLeftover || false,
              transfer_status: 0,
              transfer_reference: transfer.id,
            })),
          );
          const goodResponses = transferAssets.filter((a) => a.id);
          if (goodResponses.length !== transferAssets.length) throw Error('error generating transfer assets');
        }
        this.refresh = false;
        this.$nextTick().then(() => { this.refresh = true; });
        this.$refs.form.reset();
        this.transferGenerationReponse('success');
        this.closeModal();
        this.$refs.pasDropzone.$refs.dropzone.getAcceptedFiles().forEach((file) => {
          this.$refs.pasDropzone.$refs.dropzone.removeFile(file);
        });
      } catch (err) {
        this.transferGenerationReponse('fail');
      }
    },
    transferGenerationReponse(type) {
      this.saving = false;
      let text = 'Failed to generate an external account transfer';
      if (type === 'success') {
        text = 'Successfully generated an external account transfer';
        this.$store.dispatch('fetchTransfersByClientID', this.activeClient.client.id);
      }
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    closeModal() {
      // temp fix, need to replace v-money
      this.$refs.inputCurrency ? this.$refs.inputCurrency.$el.getElementsByTagName('input')[0].value = 0 : null;
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.close();
    },
  },
};
</script>

<style lang="scss" >

</style>
