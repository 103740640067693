
<template>
  <PasFullscreenModal @close="closeModal" :isOpen="isOpen">
    <v-container class="modal-container add-internal-transfers">
      <v-layout column>
        <v-flex xs12 text-xs-center mb-3>
          <PasPageTitle>Set up an Internal Transfer</PasPageTitle>
        </v-flex>
        <v-flex xs12 text-xs-center mb-4>
          <span>
            A form will be sent to your client for signature via docusign before processing.<br>
            Please allow 3-5 business days to process your request.
          </span>
        </v-flex>
      </v-layout>
      <v-form
        lazy-validation
        ref="addInternalTransfer"
        @submit.prevent=""
      >
        <v-layout row mt-4>
          <v-flex xs5 mr-5>
            <div class="strong-text my-3">From Account</div>
            <PasAutocomplete
              v-model="fromAccount"
              returnObject
              :items="filteredFromAccounts"
              @change="getPositions"
              :rules="[v.isRequired()]"
            />
          </v-flex>
          <v-flex xs5>
            <div class="strong-text my-3">To Account</div>
            <PasAutocomplete
              v-model="toAccount"
              returnObject
              :items="filteredToAccounts"
              :rules="[v.isRequired()]"
            />
          </v-flex>
        </v-layout>
        <v-layout row mt-4>
          <v-flex xs3 mr-5>
            <div class="strong-text my-3">Net Amount</div>
            <v-checkbox
              v-model="transferAccountBalance"
              :label="'Total balance'"
            />
            <PasInputCurrency
              v-model="netAmount"
              :rules="[v.isRequired()]"
              :disabled="transferAccountBalance"
            />
          </v-flex>
        </v-layout>
        <v-layout row mt-4>
          <v-flex xs12>
            <div class="strong-text my-3">Positions</div>
            <v-checkbox
              v-model="transferAllPositions"
              :label="'All positions'"
              v-if="positions.length > 0"
            />
            <v-data-table
              :headers="headers"
              :items="positions"
              hide-actions
              class="elevation-1 positions-table"
              :no-data-text="'No positions available for the selected account...'"
              :loading="isPositionsLoading"
              :loading-text="'Loading...please wait'"
            >
              <template v-slot:items="props">
                <td class="text-xs-center">{{ props.item.security_name }}</td>
                <td class="text-xs-center">{{ props.item.fund_account_no }}</td>
                <td class="text-xs-center">{{ props.item.market_price }}</td>
                <td class="text-xs-center">{{ props.item.quantity }}</td>
                <td class="text-xs-center quantity-to-transfer">
                  <PasInputField
                    v-model="props.item.quantity_to_transfer"
                    :rules="[v.isNumeric(), v.isRequired(), v.isLessThanOrEqual(props.item.quantity, 'Cannot transfer more than available')]"
                    :disabled="transferAllPositions"
                  />
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <v-layout row mt-4>
          <v-flex xs5>
            <v-checkbox
              v-model="closeAccountAfterTransfer"
              :label="'Close account after transfer'"
              :disabled="!transferAllAssets"
            />
            <p>* To close the account, you must transfer all assets</p>
          </v-flex>
        </v-layout>
        <v-layout row mt-5>
          <v-flex>
            <PasButton
              class="primary-btn wide-btn"
              :processing="isProcessing"
              @click="submitInternalTransfer"
            >Submit</PasButton>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
  </PasFullscreenModal>
</template>

<script>

import _ from 'lodash';
import { addInternalTransfer, getInternalTransfersValidation } from 'Services/api/MoneyMovement';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import { mapGetters } from 'vuex';

export default {
  name: 'add-internal-transfer',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v,
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'center',
          sortable: false,
          width: '25%',
        },
        {
          text: 'Code',
          value: 'code',
          align: 'center',
          sortable: false,
          width: '15%',
        },
        {
          text: 'Price',
          value: 'price',
          align: 'center',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Available',
          value: 'available',
          align: 'center',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Transfer',
          value: 'transfer',
          align: 'center',
          sortable: false,
          width: '17.5%',
        },
      ],
      transferAccountBalance: false,
      closeAccountAfterTransfer: false,
      transferAllPositions: false,
      netAmount: '0.00',
      fromAccount: {},
      toAccount: {},
      positions: [],
      isPositionsLoading: false,
      isProcessing: false,
      validations: [],
      accountSpecificValidations: [],
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
    fromAccountId() {
      return this.fromAccount.value;
    },
    toAccountId() {
      return this.toAccount.value;
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts
        .filter((account) => account.account_status === 'O');
    },
    fromAccountTypeShort() {
      return this.fromAccount.account_type_short || 0;
    },
    filteredFromAccounts() {
      return this.investmentAccounts
        // .filter((account) => !this.allForbiddenAccountTypes.includes(account.account_type_short)) // filter out all forbidden accounts
        .map((account) => ({
          text: `${account.nickname ? account.nickname : ''} (${account.currency}${account.account_no_dataphile ? `, ${account.account_no_dataphile}` : ''})`,
          value: account.id,
          account_type_short: Number(account.account_type_short),
        }));
    },
    filteredToAccounts() {
      return this.investmentAccounts
      // .filter((account) => !this.allForbiddenAccountTypes.includes(account.account_type_short)) // filter out all forbidden accounts
      // .filter((account) => !this.individualForbiddenAccountTypes[this.fromAccountIdTypeShort].includes(Number(account.account_type_short)))
        .filter((account) => this.fromAccountId !== account.id) // filter out selected account
        .map((account) => ({
          text: `${account.nickname ? account.nickname : ''} (${account.currency}${account.account_no_dataphile ? `, ${account.account_no_dataphile}` : ''})`,
          value: account.id,
          account_type_short: Number(account.account_type_short),
        }));
    },
    transferAllAssets() {
      return this.transferAllPositions && this.transferAccountBalance;
    },
  },
  async mounted() {
    this.validations = await getInternalTransfersValidation();
  },
  methods: {
    getPositions() {
      this.positions = [];
      this.isPositionsLoading = true;
      this.$store.dispatch('fetchPositions', {
        context: 'account',
        value: this.fromAccountId,
        security_classes: '90,91,92,95,96,97,98,100',
      })
        .then((res) => {
          const accounts = res.accounts.find((a) => a.id === this.fromAccountId);
          accounts.holdings[0].positions.forEach((position) => {
            this.positions.push(_.assign(_.pick(position, ['id', 'security_name', 'fund_account_no', 'market_price', 'quantity']), { quantity_to_transfer: 0 }));
          });
          this.isPositionsLoading = false;
        })
        .catch((err) => {
          this.snackBar('fail', 'Failed to load positions. Please try again.');
        });
    },
    submitInternalTransfer() {
      if (!this.$refs.addInternalTransfer.validate()) return;
      this.isProcessing = true;
      const internalTransfer = {
        from_account: this.fromAccountId,
        to_account: this.toAccountId,
        client_ids: [this.activeClient.client.id],
        amount: this.netAmount,
        transfer_total_balance: this.transferAccountBalance,
        transfer_all_positions: this.transferAllPositions,
        close_account_post_transfer: this.closeAccountAfterTransfer,
        positions: this.positions
          .filter((position) => position.quantity_to_transfer > 0)
          .map((position) => ({
            id: position.id,
            quantity: Number(position.quantity_to_transfer),
          })),
      };
      addInternalTransfer(internalTransfer)
        .then((res) => {
          let text = 'Successfully generated an internal transfer.';
          console.log('the response', res);
          if (res.code === 201) this.snackBar('success', text);
          else {
            text = 'An internal error has occurred. Please try again.';
            this.snackBar('fail', text);
          }
          this.isProcessing = false;
          this.closeModal();
        });
    },
  },
};
</script>

<style lang="scss">
.add-internal-transfers {
  .quantity-to-transfer {
    input {
      text-align: center !important;
    }

  }
  table.v-datatable {

    tbody {
        td  {
            font-size: 14px;
        }
        .v-input--selection-controls__input {
            margin: 0;
        }
        tr {
          margin-bottom: 25px !important;
          line-height: 98px !important;
          .disabled {
            color: #d1d1d1;
          }
        }
    }
    thead {
        tr {
            background-color: rgb(254, 254, 254) !important;
        }
        th {
            font-size: 14px !important;
            color: black !important;
            font-weight: 600;
        }
    }
  }
}

</style>
