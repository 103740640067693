<template>
  <div class="notification-div" v-if="show">
    <slot></slot>
    <div>&nbsp;&nbsp;<v-icon size="small" @click="hideNotification">close</v-icon></div>
  </div>
</template>

<script>
export default {
  name: 'pas-notification-div',
  data() {
    return {
      show: true,
    };
  },
  methods: {
    hideNotification() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.notification-div {
    color: var(--v-error-base) !important;
    background: rgba(249, 122, 117, 0.1);
    padding: 10px 20px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    i {
      color: var(--v-error-base) !important;
      cursor: pointer;
      font-size: 15px !important;
    }
  }
</style>
