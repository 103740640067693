
<template>
	<v-container>
		<v-card class="pa-5">
			<h3 class="subheader-text mt-3">{{greeting}}, {{currentUser.first_name}}</h3>
			<span>Here's what you have going on today.</span>
      <template>
			  <h3 class="subheader-text mt-5">Transfers</h3>
        <span v-if="!loadingTransfers"> Your clients have transferred ${{transferredPastMonth}} in the past 60 days.</span>
        <span v-else> Please wait while we are loading your transfers.</span>
        <span class="action-link" :style="{ float: 'right' }" @click="$router.push('/transfers/') ">View all transfers</span>
      </template>
			<TransferList
				:transfers="loadingTransfers ? [] : filteredTransfers"
				:loading="loadingTransfers"
				:minHeight="false"
				class="mt-3"
			/>

			<v-layout v-if="transfersPaginationLength > 1">
				<v-flex xs12 text-xs-center mt-4>
					<PasPagination
						:length="transfersPaginationLength"
						@input="transfersPage = $event"
						:value="transfersPage"
					/>
				</v-flex>
			</v-layout>

			<h3 class="subheader-text mt-5">Workflows</h3>
			<span v-if="!loadingWorkflows">Here are your team's most recent workflows.</span>
			<span v-else> Please wait while we are loading your workflows.</span>
			<span class="action-link" :style="{ float: 'right' }" @click="$router.push('/workflows/')">View all workflows</span>

			<WorkflowList
				:loading="loadingWorkflows"
				:workflows="loadingWorkflows ? [] : filteredWorkflows"
				:minHeight="false"
				class="mt-3"
			/>

			<v-layout v-if="transfersPaginationLength > 1">
				<v-flex xs12 text-xs-center mt-4>
					<PasPagination
						:length="workflowsPaginationLength"
						@input="workflowsPage = $event"
						:value="workflowsPage"
					/>
				</v-flex>
			</v-layout>

		</v-card>
	</v-container>
</template>

<script>

// libraries and services
import moment from 'moment';
import api from 'Services/api';
import { modal } from 'Services/mixins';
import { commaFormatted } from 'Services/helpers/currency';

// components
import TransferList from 'Components/Vue-Routes/MyTransfers/TransferList';
import WorkflowList from './MyWorkflows/WorkflowList';

export default {
  name: 'dashboard',
  components: {
    WorkflowList,
    TransferList,
  },
  mixins: [modal],
  mounted() {
    Promise.all([
      this.$store.dispatch('getAtonTransfers'),
      api.get('/advisor_portal/api/workflows'),
    ]).then((res) => {
      this.workflows = res[1].workflows;
      this.filterTransfers();
      this.filterWorkflows();
    }).catch((err) => {
      this.snackBar('fail', 'We had trouble fetching data. Please reload the page');
    });
  },
  data() {
    return {
      workflows: [],
      filteredTransfers: ['loading'],
      perPage: 5,
      transfersPaginationLength: 1,
      transfersPage: 1,
      filteredWorkflows: ['loading'],
      workflowsPaginationLength: 1,
      workflowsPage: 1,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    currentUserRepCodes() {
      return this.$store.state.users.currentUserRepCodes;
    },
    transfers() {
      return this.$store.state.clients.atonTransfers;
    },
    loadingTransfers() {
      return this.filteredTransfers.length && this.filteredTransfers[0] === 'loading';
    },
    loadingWorkflows() {
      return this.filteredWorkflows.length && this.filteredWorkflows[0] === 'loading';
    },
    transferredPastMonth() {
      const earliestDate = moment().subtract(60, 'days');
      const filteredTransfers = this.transfers.filter((t) => t.date && moment(t.date).isAfter(earliestDate.format('YYYY-MM-DD')));
      return commaFormatted(filteredTransfers.reduce((acc, t) => acc + t.amount_estimated, 0));
    },
    greeting() {
      const myDate = new Date();
      const hrs = myDate.getHours();
      if (hrs < 12) return 'Good Morning';
      if (hrs >= 12 && hrs <= 17) return 'Good Afternoon';
      if (hrs >= 17 && hrs <= 24) return 'Good Evening';
    },
  },
  methods: {
    filterTransfers() {
      const transfers = _.cloneDeep(this.transfers);
      this.transfersPaginationLength = transfers.length ? Math.ceil(transfers.length / this.perPage) : 1;
      const start = (this.transfersPage - 1) * this.perPage;
      const end = this.transfersPage * this.perPage;
      this.filteredTransfers = transfers.slice(start, end);
    },
    filterWorkflows() {
      const workflows = _.cloneDeep(this.workflows);
      this.workflowsPaginationLength = workflows.length ? Math.ceil(workflows.length / this.perPage) : 1;
      const start = (this.workflowsPage - 1) * this.perPage;
      const end = this.workflowsPage * this.perPage;
      this.filteredWorkflows = workflows.slice(start, end);
    },
  },
  watch: {
    transfersPage() {
      this.filterTransfers();
    },
    workflowsPage() {
      this.filterWorkflows();
    },
  },
};
</script>

<style lang="scss">
</style>
