import Vue from 'vue';
import Vuex from 'vuex';
import IdleVue from 'idle-vue';

// Store Modules
import users from './modules/users';
import modals from './modules/modals';
import snackbar from './modules/snackbar';
import workflow from './modules/workflow';
import clients from './modules/clients';
import lists from './modules/lists';
import app from './modules/app';
import permissions from './modules/permissions';
import funding from './modules/funding';
import modalManager from './modules/modalManager';

Vue.use(Vuex);

const store = new Vuex.Store({
  mutations: {
    SET_API(state, api) {
      state.api = api;
    },
  },
  actions: {
    setAPI({ commit }, { api }) {
      commit('SET_API', api);
    },
  },
  modules: {
    users,
    modals,
    snackbar,
    workflow,
    clients,
    lists,
    app,
    permissions,
    funding,
    modalManager: {
      namespaced: true,
      ...modalManager,
    },
  },
});

// The following sets the idle timeout/expiration for the application session
let idleTime = 30 * 60 * 1000; // 30 minutes for production

if (process.env.VUE_APP_ENVIRONMENT === 'local' || process.env.VUE_APP_ENVIRONMENT === 'dev') {
  idleTime = 120 * 60 * 1000; // 2 hour for development
}

Vue.use(IdleVue, {
  store,
  idleTime,
  startAtIdle: false,
});

export default store;

// Workaround to access store with Composition API component
// Cannot upgrade to Vuex 4 as its not compatible with Vue 2.7
export const useStore = () => store;
