<template>
  <div>
    <v-layout justify-center>
    <v-flex class="wrapper">
    <v-layout row align-center class="top-row-layout" mb-4>

    <v-flex v-if="filteredbankAccountLength > 0" pl-5>{{`${filteredbankAccountLength} ${filteredbankAccountLength === 1 ? 'account' : 'accounts'}`}}</v-flex>
    <v-spacer></v-spacer>
    <v-flex text-xs-right>
    </v-flex>
    </v-layout>
    <v-layout row justify-space-around class="ba-cards-row">
    <v-flex text-xs-center v-if="bankAccounts.length === 0">{{clientName}} has no bank accounts on record</v-flex>
    <template v-for="(a, i) in filteredbankAccounts">
      <v-flex class="bank-card" :key="i">
      <v-card
        :class="{ 'onlyCard': filteredbankAccounts.length === 1, 'pa-3': true}"
      >
        <v-layout
          column
          justify-space-around
          fill-height
        >
          <v-flex>
            <v-layout row align-center>
              <v-flex class="light-white-text">
                Account Name
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex v-if="a.status === 'verified'" :style="{ display: 'contents' }">
                <v-tooltip top>
                  <v-icon size="20px" slot="activator" :style="{ margin: '0px 5px 0px 0px' }" color="rgba(255,255,255,0.6)">mdi-check</v-icon>
                  <span>Account is active</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-if="a.document" @click="downloadVoidCheque(a.document)" :style="{ display: 'contents' }">
                <v-tooltip top>
                  <v-icon size="20px" slot="activator" :style="{ margin: '0px' }" color="rgba(255,255,255,0.6)">mdi-checkbook</v-icon>
                  <span>View bank statement <br> or void cheque</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-if="canEditBankAccounts && canDeleteAccount(a)" @click="deleteAccount(a, a.document)" :style="{ display: 'contents' }">
                <v-icon size="20px" :style="{ margin: '0px 0px 0px 5px' }" color="rgba(255,255,255,0.6)">mdi-delete</v-icon>
              </v-flex>
            </v-layout>
            <pas-editable-span
              class="bank-accounts-editable"
              :value="a.description"
              @editSaved="updateAccountName(a.id, $event)"
              :editableSpanClass="'solid-white-text'"
              :rules="[
                v => (!!v || v === 0) || 'Field is required',
                v => /^(?!\s*$).+/.test(v) || 'Field is required',
                v => (v.length <= 24) || 'Name is too long'
                ]"
              :editIconClass="'edit-icon-light edit-icon-small'"
              :disabled="!canEditBankAccounts || a.external"
            >
            </pas-editable-span>
          </v-flex>
          <v-flex class="account-no-flex">
            &bull;&bull;&bull;&bull;
            <div class="account-no-spacer"></div>
            &bull;&bull;&bull;&bull;
            <div class="account-no-spacer"></div>
            <div class="account-no-value">
              {{a.account_no.slice(0,2)}}
              <div class="account-no-value-spacer">
              {{a.account_no.slice(2)}}
              </div>
            </div>
          </v-flex>
          <v-flex>
            <v-layout row>
              <v-flex class="light-white-text">Institution <br />
                <span class="solid-white-text">{{a.institution_no}}</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex pl-5 class="light-white-text">Transit <br />
                <span class="solid-white-text">{{a.transit_no}}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      </v-flex>
    </template>
    </v-layout>

    <v-layout justify-center pt-4>
      <pas-pagination
        :length="paginationLength"
        v-model="filterOptions.page"
      ></pas-pagination>
    </v-layout>
    </v-flex>
    </v-layout>

    <v-dialog :value=deleteDialog width="430px" height="400px">
      <v-card :style="{ padding: '20px'}">
        <v-card-text>Are you sure you want to delete this bank account for {{clientName}}?</v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-layout align-center justify-end row fill-height>
            <v-flex class="no-flex-grow pr-2">
              <pas-button class="inverted-btn" @click="deleteBankAccount">Yes</pas-button>
            </v-flex>
            <v-flex class="no-flex-grow pl-2">
              <pas-button class="primary-btn" @click="deleteDialog = false"> No</pas-button>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import _ from 'lodash';
import { FILE_EXTENSIONS, FILE_TYPES } from 'Services/constants';
import api from 'Services/api';
import { getMetadata } from 'Services/api/metadata';
import { mapGetters } from 'vuex';


export default {
  name: 'bank-accounts-tab',
  props: {
    bankAccounts: {
      type: Array,
      default() {
        return [];
      },
    },
    person: Object,
  },
  data() {
    return {
      filterOptions: {
        page: 1,
        perPage: 2, // not modifiable
      },
      filteredbankAccounts: _.cloneDeep(this.bankAccounts),
      paginationLength: 1,
      deleteDialog: false,
      accountToDelete: null,
      docToDelete: null,
      filteredbankAccountLength: 0,
    };
  },
  computed: {
    ...mapGetters([
      'canEditBankAccounts',
      'custodian',
    ]),
    clientName() {
      return this.person.first_name ? this.person.first_name : this.person.name;
    },
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
    clientID() {
      return this.$store.state.clients.activeClient.client.id;
    },
    firm() {
      return this.$store.getters.firm;
    },
  },
  mounted() {
    this.bankAccounts ? this.filterAndSort() : null;
  },
  methods: {
    filterAndSort() {
      const opts = this.filterOptions;
      this.filteredbankAccounts = _.cloneDeep(this.bankAccounts).filter((a) => a.status !== 'inactive');
      this.paginationLength = this.getPaginationLength();
      const iStart = (opts.page - 1) * opts.perPage;
      const iEnd = opts.page * opts.perPage;
      if (this.custodian === 'fcc') {
        this.filteredbankAccounts = this.filteredbankAccounts.filter((bk) => bk.client === this.clientID);
      }
      this.filteredbankAccountLength = this.filteredbankAccounts.length;
      this.filteredbankAccounts = this.filteredbankAccounts.slice(iStart, iEnd);
    },
    getPaginationLength() {
      return (this.filteredbankAccounts ? Math.ceil(this.filteredbankAccounts.length / this.filterOptions.perPage) : 1);
    },
    updateAccountName(accountID, accountName) {
      const data = { description: accountName, client: this.clientID };
      this.$store.dispatch('updateBankAccounts', { accountID, data });
    },
    downloadVoidCheque(docID) {
      const awsKey = this.documents.filter((doc) => doc.id === docID)[0].aws_key;
      const docType = this.documents.filter((doc) => doc.id === docID)[0].document_type;
      const fileType = this.documents.filter((doc) => doc.id === docID)[0].file_type;
      let filename = this.documents.filter((doc) => doc.id === docID)[0].document_name;
      const link = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
      const url = `${link}/advisor_portal/view_document?doc=${awsKey}`;
      fetch(url).then((t) => {
        // the three lines below can later be deleted if all the documents names have ext
        const fileNameExt = filename.substr(filename.lastIndexOf('.') + 1);
        const awsKeyExt = awsKey.substr(awsKey.lastIndexOf('.') + 1);
        !FILE_EXTENSIONS.includes(fileNameExt) && FILE_EXTENSIONS.includes(awsKeyExt)
          ? filename = `${filename}.${awsKeyExt}`
          : filename = `${filename}.${FILE_TYPES[fileType]}`;

        return t.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', filename);
          a.click();
        });
      });
    },
    canDeleteAccount(account){
      return account.status !== 'verified';
    },
    deleteAccount(account, docID) {
      this.accountToDelete = account,
      this.docToDelete = docID,
      this.deleteDialog = true;
    },
    snackbar(type) {
      let text = 'Bank Account successfully deleted';
      if (type === 'fail') {
        text = 'There was an error deleting the account. Please try again and contact support if the issue persists.';
      }
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async deleteBankAccount() {
      this.deleteDialog = false;
      try {
        const data = {
          id: this.accountToDelete.id,
          client: this.clientID,
          document: this.docToDelete,
        };
        const bankAccountRes = await this.$store.dispatch('deleteBankAccounts', data);
        if (!bankAccountRes) throw Error;
        this.snackbar('success');

        const email = await getMetadata('operations_email');
        const account = this.accountToDelete;
        const link = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
        const clientUrl = `${link}/advisor_portal/clients/${account.client}`;
        const clientFullName = this.person.display_name || `${this.person.first_name} ${this.person.last_name}` || this.person.name;
        const messageData = {
          subject: 'Bank Account Deleted',
          email,
        };
        messageData.message = 'Hi,\n\n'
                              + 'Please see the details below.\n\n'
                              + `	Client : ${account.client}\n`
                              + `	Client Name: ${clientFullName}\n`
                              + `	Client Profile: ${clientUrl}\n`
                              + `	Institution Number: ${account.institution_no}\n`
                              + `	Transit Number: ${account.transit_no}\n`
                              + `	Account Number: ${account.account_no}\n\n`
                              + 'Thanks';
        api.post('/advisor_portal/send_email', messageData);

        if (this.custodian !== 'fcc') {
          // find the workflow with this bank account
          const workflows = (await this.$store.dispatch('getWorkflows', this.clientID)).filter((wf) => {
            if (!wf.workflow_specific_data) return false;
            if (!wf.workflow_specific_data.bank_accounts) return false;
            const bankAccount = wf.workflow_specific_data.bank_accounts.filter((ba) => ba.id === this.accountToDelete.id);
            return !!bankAccount.length;
          });

          // if there is no workflow associated with the bank account, return
          if (!workflows[0]) return null;

          // delete the workflow if there is only one bank account associated with it
          const workflow = workflows[0];

          if (workflow.workflow_specific_data.bank_accounts.length === 1) {
            const deleteRes = await this.$store.dispatch('deleteWorkflow', workflow._id);
            if (deleteRes) this.$store.dispatch('getWorkflows', this.clientID);
          // update the workflow if there are more than one bank account associated with it
          } else {
            const workflowData = workflow.workflow_specific_data;
            workflowData.bank_accounts = workflowData.bank_accounts.filter((acc) => acc.id !== this.accountToDelete.id);
            await this.$store.dispatch('updateWorkflow', {
              workflow: workflow._id,
              action: 'update_workflow_specific_data',
              workflow_specific_data: workflowData,
            });
          }
        }
      } catch (err) {
        this.snackbar('fail');
      }
    },
  },
  watch: {
    filterOptions: {
      handler() {
        this.bankAccounts ? this.filterAndSort() : null;
      },
      deep: true,
    },
    bankAccounts: {
      handler() {
        this.bankAccounts ? this.filterAndSort() : null;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .bank-card {
    &:first-child {margin-right: 12px !important;}
    &:last-child {margin-left: 12px !important;}
    .v-card {
      background: -webkit-linear-gradient(left, var(--primary-translucent-7) 0%, var(--primary-translucent-3) 100%);
      border-radius: 10px;
      color: #fff;
      height: 165px !important;
    }
  }
  .bolded-value {
    font-weight: 600;
  }
  .top-row-layout {
    line-height: 3.6em;
  }
  .account-no-flex {
    letter-spacing: 8px;
  }
  .account-no-spacer {
    margin: 0px -5px;
    display: inline-block;
  }
  .account-no-value {
    font-size: 20px !important;
    letter-spacing: 2px;
    display: inline-block;
    position: relative;
    top: 2px;
  }
  .account-no-value-spacer {
    display: inline-block;
    width: 6px;
  }
  .onlyCard {
    width: 50% !important;
    position: relative;
    left: -12px;
  }
  .ba-cards-row {
    min-height: 280px !important;
  }
  .wrapper {
    max-width: 640px !important;
  }
  .light-white-text {
    color: rgba(255,255,255,0.6);
  }
  .solid-white-text {
    color: rgba(255,255,255,1) !important;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.1) !important;
  }
</style>
