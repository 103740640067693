<template>
  <div class="upload-avatar">
    <vue-dropzone
        v-if="!readonly"
        :id="id"
        :ref="reference"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        :include-styling="false"
    >
        <slot></slot>
    </vue-dropzone>
    <slot v-if="readonly"></slot>
    <v-dialog  v-model="editorDialog" width="700px" persistent v-if="!readonly">
      <v-card color="white" height="790px" width="700px" :style="{ paddingTop: '80px'}">
        <PasFab class="close-btn" >
          <v-icon @click="closeCropper" id="cancelButton" class="close-icon">close</v-icon>
        </PasFab>
        <div id="avatarEditor" :style="{ height: '600px', width: '600px', marginLeft: '50px'}"></div>
        <pas-button
          style="margin-left: 550px"
          id="confirmButton"
          class="primary-btn"
        >OK</pas-button>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import vueDropzone from 'vue2-dropzone';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'upload-avatar',
  components: {
    vueDropzone,
  },
  props: {
    id: String,
    reference: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: Boolean, // true if client, false if user
  },
  data() {
    const that = this;
    return {
      dropzoneOptions: {
        url: '/advisor_portal/upload_avatar',
        transformFile(file, done) {
          that.editorDialog = true;

          const image = new Image();
          image.src = URL.createObjectURL(file);
          image.setAttribute('id', 'avatarImg');
          document.getElementById('avatarEditor').appendChild(image);

          that.cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 2,
            zoomable: false,
          });

          document.getElementById('confirmButton').addEventListener('click', () => {
            that.cropper.getCroppedCanvas().toBlob((blob) => {
              that.editorDialog = false;
              that.snackbar('success', 'Please wait while we are uploading the avatar');
              that.newFile = blob;
              done(blob);
            }, 'image/jpeg', 0.2);
          });
        },
        init() {
          this.on('sending', (file, xhr, formData) => {
            formData.append('document', that.newFile);
            formData.append('id', that.type ? that.activeClientID : that.currentUserID);
            formData.append('type', that.type ? 'clients' : 'platform_users');
          });
          this.on('success', (file, res) => {
            this.removeFile(file);
            if (res.message !== 'Success') return that.snackbar('fail');
            that.$emit('updateDropzone');
            that.snackbar('success');
          });
          this.on('error', (file, err) => {
            console.log('Failed to upload:', err);
            this.removeFile(file);
            // that.snackbar('fail')
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: 3,	// MB
        maxFiles: 1,
        chunking: true,
        chunkSize: 3000000,	// Bytes
        acceptedFiles: '.png,.jpeg,.jpg',
        createImageThumbnails: false,
      },
      newFile: null,
      editorDialog: false,
      cropper: null,
    };
  },
  computed: {
    activeClientID() {
      return this.$store.state.clients.activeClient.client.id;
    },
    currentUserID() {
      return this.$store.state.users.currentUser.platform_user_id;
    },
  },
  methods: {
    snackbar(type, textMsg = '') {
      let text = `${this.type ? 'Client' : 'User'} avatar was successfully updated`;
      text = textMsg || text;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    closeCropper() {
      this.editorDialog = false;
      this.$emit('updateDropzone');
    },
  },
};
</script>

<style lang="scss">

.upload-avatar {
  img {
    max-width: 100%
  }
  .v-dialog.v-dialog--active.v-dialog--persistent {
    max-height: none !important
  }
}
.cropper-modal {
  background-color: white !important;
}

.cropper-container.cropper-bg{
  width: 600px !important;
  height: 600px !important;
  overflow: hidden !important;
  background-color: white !important;
}
.avatar-ok{
  position: absolute;
  top: 30px;
  left: 300px;
}
.avatar-dialog{
  max-height: 800px !important
}
</style>
