import api from 'Services/apiV2';
import acApi from 'Services/api';
import { fetchProgramById } from 'Services/api/ClientDataHierarchy';
import { getMetadata } from './metadata';
import { transformTopWorkflows } from './Workflows/workflowUtils';

export const ALL_AKYC_CM_WORKFLOW_STEPS_EXCEPT_CHANGE_REQUEST = 'fcc_validation_kyc,advisor_approval,document_generation_kyc,client_signing,document_processing_kyc,fcc_document_processing,fcc_process_request';

export const fetchWorkflows = (offset, sortBy, sortDir, type, status, searchVal, clientId, step, limit) => api.get(
  '/new_workflows_list',
  {
    offset,
    limit: limit || 25,
    sort_dir: sortDir,
    sort_by: sortBy,
    type,
    status,
    client_id: clientId,
    step: step || undefined,
    search_val: searchVal || undefined,
  },
)
  .then(transformTopWorkflows);

export const fetchActiveWorkflowSteps = (type, status, searchVal, clientId) => api.get(
  '/new_workflow_steps_list',
  {
    type,
    status,
    client_id: clientId,
    search_val: searchVal || undefined,
  },
);

export const fetchTopInProgressWorkflows = () => api.get(
  '/new_workflows_list',
  {
    limit: 5,
    sort_dir: 'desc',
    status: 'in_progress',
  },
)
  .then(transformTopWorkflows);

export const fetchTopNeedsAttentionWorkflows = () => api.get(
  '/new_workflows_list',
  {
    limit: 5,
    sort_dir: 'desc',
    status: 'needs_attention',
  },
)
  .then(transformTopWorkflows);

export const fetchWorkflowsForClient = (clientId, limit = 25, offset = 0) => api.get(
  '/new_workflows_list',
  {
    offset,
    limit,
    client_id: clientId,
    status: ['in_progress', 'needs_attention', 'process_error'],
  },
)
  .then(transformTopWorkflows);

export const fetchNotInProgressWorkflowsForClient = (clientId, limit = 25, offset = 0) => api.get(
  '/new_workflows_list',
  {
    offset,
    limit,
    client_id: clientId,
    status: ['completed', 'Cancelled', 'cancelled'],
  },
)
  .then(transformTopWorkflows);

export const fetchNotCancelledOnboardingForClient = (clientId, limit = 25, offset = 0) => api.get(
  '/new_workflows_list',
  {
    offset,
    limit,
    client_id: clientId,
    type: 'client_onboarding_fcc_ip',
    status: ['completed', 'in_progress', 'needs_attention'],
  },
)
  .then(transformTopWorkflows);

export const fetchInProgressWorkflowsForClient = async (limit, clientIds, workflowType, workflowStep=null) => {
  const data = await api.get('/new_workflows_list', {
    client_id: clientIds,
    limit,
    status: 'in_progress',
    type: workflowType,
    step: workflowStep,
  });

  return data;
};

export const fetchLatestCompletedKycWorkflow = (clientIds) => api.get(
  '/new_workflows_list',
  {
    client_id: clientIds,
    limit: 1,
    status: ['completed'],
    type: ['client_onboarding_fcc_ip', 'client_maintenance', 'annual_kyc']
  },
).then((res) => res);

export const createOnboardingWorkflow = async (clientIds, accountIds, sendEmail = false, programId) => {
  const custodian = await getMetadata('custodian');

  let isNoCall = false;
  if (programId) {
    isNoCall = (await fetchProgramById(programId)).is_no_call_model;
  }

  let custodianType = '';
  if (custodian === 'fcc') {
    custodianType = '_fcc';
  }
  const messageType = `workflow.client_onboarding${isNoCall ? '_no_call' : ''}${custodianType}.create`;

  const payload = {
    message: messageType,
    data: {
      client_ids: clientIds,
      account_ids: accountIds,
      send_email: sendEmail,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const createOnboardingIPWorkflow = async (clientIds, inPerson = false) => {
  const custodian = await getMetadata('custodian');
  let custodianType = '';
  if (custodian === 'fcc') {
    custodianType = '_fcc';
  }
  const messageType = `workflow.client_onboarding${custodianType}_ip.create`;
  const payload = {
    message: messageType,
    data: {
      client_ids: clientIds,
      in_person: inPerson,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const createBankAccountOpeningWorkflow = async (clientIds, bankAccountIds) => {
  const custodian = await getMetadata('custodian');

  let messageType = 'workflow.bank_account_setup.create';
  if (custodian === 'fcc') {
    messageType = 'workflow.bank_account_setup_fcc.create';
  }

  const payload = {
    message: messageType,
    data: {
      client_ids: clientIds,
      bank_account_ids: bankAccountIds,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const createAccountOpeningWorkflow = async (clientIds, accountIds) => {
  const custodian = await getMetadata('custodian');

  let messageType = 'workflow.account_opening.create';
  if (custodian === 'fcc') {
    messageType = 'workflow.account_opening_fcc.create';
  }

  const payload = {
    message: messageType,
    data: {
      client_ids: clientIds,
      account_ids: accountIds,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const createIpsWorkflow = async (clientIds, ipsData) => {
  const custodian = await getMetadata('custodian');
  let messageType = 'workflow.ips.create';

  if (custodian === 'fcc') {
    messageType = 'workflow.ips_workflow.create';
  }

  const { id } = await api.post('/ips_data/', ipsData);
  const payload = {
    message: messageType,
    data: {
      client_ids: clientIds,
      ips_data_id: id,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const createExternalAccountTransferWorkflow = async (clientIds, transferIds) => {
  const custodian = await getMetadata('custodian');
  let messageType = 'workflow.external_account_transfer.create';
  if (custodian === 'fcc') {
    messageType = 'workflow.external_account_transfer_fcc.create';
  }

  const payload = {
    message: messageType,
    data: {
      client_ids: clientIds,
      transfer_ids: transferIds,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const sendEquifaxCheck = async (workflow, photoIdUploaded = false) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.equifax_check`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      photo_id_uploaded: photoIdUploaded,
    },
  };
  if (type === 'fintrac_repapering') {
    payload.data.run_equifax = true;
  }
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendFatcaCheckSuccess = async (workflow, clientId) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.fatca_check.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      client_ids: [clientId],
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendCrsCheckSuccess = async (workflow, clientId) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.crs_check.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      client_ids: [clientId],
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendRiskQuestionnaireSuccess = async (workflow, clientId) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.risk_questionnaire.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      client_ids: [clientId],
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAdvisorDocuments = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.advisor_approval`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAdvisorApprovalSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.advisor_approval.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAdvisorApprovalFailure = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.advisor_approval.failure`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendApprovalDocuments = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.pm_approval`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendPMApprovalSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.pm_approval.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendPMApprovalFailure = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.pm_approval.failure`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendComplianceDocuments = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.compliance_approval`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendComplianceAccountOpeningDocuments = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.document_generation`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAccountOpeningReviewAndApprovalDocuments = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.review_and_approval`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendComplianceApprovalSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.compliance_approval.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendComplianceApprovalFailure = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.compliance_approval.failure`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAccountOpeningReviewAndApprovalSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.review_and_approval.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAccountOpeningReviewAndApprovalFailure = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.review_and_approval.failure`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAccountOpeningSuccess = async (workflow, clientId) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.account_opening.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      client_ids: [clientId],
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendDocumentGenerationSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.document_generation.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendDocumentProcessingSuccess = async (workflow, step) => {
  const { id, type } = workflow;
  const name = step ? step.name : 'document_processing';
  const messageType = `workflow.${type}.${name}.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendFCCPayloadSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.fcc_payload.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendDocumentSigningSuccess = async (workflow, role) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.${role}_signing.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendFccProcessingSuccess = async (workflow) => {
  const { id } = workflow;
  const messageType = 'workflow.client_onboarding_fcc.fcc_processing.success';
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendAMLManualApproval = async (workflow, clientId, amlData) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.aml_check`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      client_ids: [clientId],
      is_manual: true,
      aml_data: amlData,
    },
  };
  if (type === 'fintrac_repapering') {
    payload.data.run_aml = true;
  }
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendPortfolioAssignmentSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.portfolio_assignment.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const resendClientConfirmationTool = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.client_info_confirmation`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const fetchWorkflowDetails = (workflowId) => api.get(`/new_workflows/${workflowId}/`);

export const fetchWorkflowAccounts = (workflowId) => api.get(`/new_workflow_accounts/?workflow_id=${workflowId}`);

// export const fetchWorkflowSteps = (workflowId) => api.get(`/new_workflows/${workflowId}/new_workflow_steps`);

export const fetchWorkflowSteps = (workflowId) => api.get(`/new_workflow_steps/${workflowId}/actionable_items/`);

export const fetchWorkflowClients = (workflowId) => api.get(`/new_workflows/${workflowId}/new_workflow_clients`);
export const fetchWorkflowIpsGroup = (workflowId) => api.get(`/new_workflows/${workflowId}/new_workflow_ips_groups`)
  .then((res) => ({
    ipsGroup: res.new_workflow_ips_groups?.[0]?.ips_group,
  }));

export const fetchWorkflowClient = (workflowId) => api.get(`/new_workflows/${workflowId}/new_workflow_clients`)
  .then((res) => ({
    client: res.new_workflow_clients?.[0]?.client,
  }));

export const sendFatcaCrsRemediationSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.fatca_crs_remediation.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendReceiveAccountNumberSuccess = async (workflow) => { // for Pinnacle
  const { id, type } = workflow;
  const messageType = `workflow.${type}.receive_account_number.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendOnboardingIPWorkflowSuccess = async (workflow) => {
  const custodian = await getMetadata('custodian');
  let custodianType = '';
  if (custodian === 'fcc') {
    custodianType = '_fcc';
  }
  const messageType = `workflow.client_onboarding${custodianType}_ip.create.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: workflow,
    },
  };
  acApi.post('/advisor_portal/send_message', payload);
};

export const sendDocumentGenerationKYCSuccess = async (workflow) => {
  const { id, type } = workflow;
  const messageType = `workflow.${type}.document_generation_kyc.success`;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const sendMessage = async (
  workflow,
  { messageType, additionalData },
) => {
  const { id } = workflow;
  const payload = {
    message: messageType,
    data: {
      workflow_id: id,
      ...additionalData,
    },
  };
  return acApi.post('/advisor_portal/send_message', payload);
};

export const resendDocuSignPackage = async (workflow) => {
  const { id } = workflow;
  const payload = {
    workflow_id: id,
  };
  return acApi.post('/advisor_portal/docusign_resend_package', payload);
}

export const handleStepSuccess = async (res, store, id) => {
  if (res.status === 200) {
    store.dispatch('setSnackbar', {
      text: 'Updating Workflow',
      type: 'success',
    });
    store.dispatch('flipSnackbarSwitch');
    await store.dispatch('fetchWorkflowV2Steps', id);
  }
};

export const handleStepError = async (store) => {
  store.dispatch('setSnackbar', {
    text: 'Fail to update workflow',
    type: 'error',
  });
  store.dispatch('flipSnackbarSwitch');
};
