<template>
  <div>
    <TransactionCard
      v-for="(transaction, index) in allTransactions"
      @click="$emit('click', transaction)"
      :transaction="transaction"
      :key="index"
    />
  </div>
</template>

<script>
import TransactionCard from '../TransactionCard/TransactionCard';

export default {
  name: 'TransactionList',
  components: {
    TransactionCard,
  },
  props: {
    allTransactions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: true,
    };
  },
};
</script>
