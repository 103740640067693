<template>
  <div class="client-edit-wrapper">
    <template v-if="!clientLoaded">
      <v-layout align-center class="loader-layout">
        <v-flex>
          <pas-circular-loader :loadingMessage="`It'll Just Be A Moment...`" />
        </v-flex>
      </v-layout>
    </template>

    <template v-else-if="clientLoadedIsTrustOrFoundation">
      <PasPageHeader :path="`/clients/${clientId}`">
        {{activeClient.client ? activeClient.client.display_name : ""}}
      </PasPageHeader>
      <pas-tabs class="tabs-page-view" :value="activeTab">
        <template v-slot:tabTitle1 v-if="canViewClientDetails">Client Details</template>
        <template v-slot:tabTitle4 v-if="feeGroupsEnabled || feeScheduleEnabled">Fee Management</template>
        <template v-slot:tabItem1 v-if="canViewClientDetails">
          <ClientGeneralInfoTab
            :activeClient="activeClient"
            :areClientDetailsEditable="areClientDetailsEditable"
          />
        </template>
        <template v-slot:tabItem4 v-if="feeGroupsEnabled || feeScheduleEnabled">
          <FeeManagementTab
            :clientID="clientID"
            :hideHeader="true"
          />
        </template>
      </pas-tabs>
    </template>

    <template v-else-if="clientLoaded">
      <PasPageHeader :path="`/clients/${clientId}`">
        {{activeClient.client ? activeClient.client.display_name : ""}}
      </PasPageHeader>
      <pas-tabs class="tabs-page-view" :value="activeTab">
        <template v-slot:tabTitle1 v-if="canViewClientDetails">Client Details</template>
        <template v-slot:tabTitle2 v-if="canViewRiskProfile">Risk Profile</template>
        <template v-slot:tabTitle3 v-if="canViewFinancialProfile">Financial Profile</template>
        <template v-slot:tabTitle4 v-if="feeGroupsEnabled || feeScheduleEnabled">Fee Management</template>
        <template v-slot:tabTitle5 v-if="!!clientGroupingsTabTitle">{{clientGroupingsTabTitle}}</template>
        <template v-slot:tabItem1 v-if="canViewClientDetails">
          <ClientDetailsTab
            :activeClient="activeClient"
            :areClientDetailsEditable="areClientDetailsEditable"
            :canShowClientMaintenanceLink="canShowClientMaintenanceLink"
            :formattedLastUpdatedDate="formattedLastUpdatedDate"
          />
        </template>
        <template v-slot:tabItem2 v-if="canViewRiskProfile">
          <RiskProfileTab
            :clientID="clientID"
            :hideHeader="true"
          />
        </template>
        <template v-slot:tabItem3 v-if="canViewFinancialProfile">
          <financial-profile-tab :editable="isFinancialProfileEditable" />
        </template>
        <template v-slot:tabItem4 v-if="feeGroupsEnabled || feeScheduleEnabled">
          <FeeManagementTab
            :clientID="clientID"
            :hideHeader="true"
          />
        </template>
        <template v-slot:tabItem5 v-if="!!clientGroupingsTabTitle">
          <PasAccordion v-if="reportingGroupsEnabled && canViewReportingGroups">
            <template v-slot:panelTitle>Reporting Group</template>
            <template v-slot:panelContent>
              <ReportingGroupTab />
            </template>
          </PasAccordion>
          <PasAccordion v-if="pmGroupsEnabled && canViewPMGroups">
            <template v-slot:panelTitle>PM Group</template>
            <template v-slot:panelContent>
              <PMGroupTap />
            </template>
          </PasAccordion>
        </template>
      </pas-tabs>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import { fetchLatestCompletedKycWorkflow } from 'Services/api/Workflows';
import { fetchClientPortfolio } from 'Services/api/PortfolioAssignment';
import FinancialProfileTab from './ClientEdit/FinancialProfileTab';
import ReportingGroupTab from './ClientEdit/ReportingGroupTab';
import PMGroupTap from './ClientEdit/PMGroupTap';
import ClientDetailsTab from './ClientEdit/ClientDetailsTab/ClientDetailsTab';
import ClientGeneralInfoTab from './ClientEdit/ClientGeneralInfoTab';
import RiskProfileTab from './ClientEdit/RiskProfileTab/RiskProfileTab';
import FeeManagementTab from './ClientEdit/FeeManagementTab'

export default {
  name: 'clientDetails',
  components: {
    ClientDetailsTab,
    ClientGeneralInfoTab,
    FinancialProfileTab,
    PMGroupTap,
    ReportingGroupTab,
    RiskProfileTab,
    FeeManagementTab,
  },
  props: {
    clientID: String,
    clientLoaded: Boolean,
  },
  data() {
    return {
      activeTab: null,
      formattedLastUpdatedDate: null,
      clientPortfolio: null,
    };
  },
  computed: {
    ...mapGetters([
      'canViewClientDetails',
      'canEditClientDetails',
      'canViewFinancialProfile',
      'canEditFinancialProfile',
      'canViewRiskProfile',
      'canEditRiskProfile',
      'canViewReportingGroups',
      'canViewPMGroups',
      'feeGroup',
      'canViewFeeGroups',
      'canViewPlanningSchedules',
      'canViewManagementSchedules',
      'canViewSubadvisorSchedules',
      'hasFeatureFlag',
      'isClientStatusActive',
    ]),
    areClientDetailsEditable() {
      const isClientMaintenanceEnabled = this.hasFeatureFlag('client_maintenance');
      if (!isClientMaintenanceEnabled) {
        return this.canEditClientDetails;
      }
      if (this.activeClient.client.client_type !== 'person') {
        return this.canEditClientDetails;
      }
      return this.canEditClientDetails && !this.isClientStatusActive;
    },
    isFinancialProfileEditable() {
      const isClientMaintenanceEnabled = this.hasFeatureFlag('client_maintenance');
      const isCorporation = this.activeClient.client.client_type === 'corporation';
      if (!isClientMaintenanceEnabled || isCorporation) {
        return this.canEditFinancialProfile;
      }
      return this.canEditFinancialProfile && !this.isClientStatusActive;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientId() {
      return this.$store.state.clients.activeClient?.client?.id;
    },
    clientType() {
      return this.activeClient.client ? this.activeClient.client.client_type : '';
    },
    router() {
      return this.$router;
    },
    feeGroupsEnabled() {
      return this.$store.state.app.features.fee_groups && this.canViewFeeGroups
      && (this.activeClient.client.client_status === 'active' || this.activeClient.client.client_status === 'prospect');
    },
    feeScheduleEnabled() {
      return this.$store.state.app.features.fee_schedules
      && (this.activeClient.client.client_status === 'active' || this.activeClient.client.client_status === 'prospect')
      && (this.canViewPlanningSchedules || this.canViewManagementSchedules || this.canViewSubadvisorSchedules);
    },
    portfolioAssignmentFeatureEnabled() {
      return this.$store.state.app.features.portfolio_assignment;
    },
    ipsFeatureEnabled() {
      return this.$store.state.app.features.ips;
    },
    reportingGroupsEnabled() {
      return this.$store.state.app.features.reporting_groups && ['prospect', 'active'].includes(this.activeClient.client.client_status);
    },
    pmGroupsEnabled() {
      return this.$store.state.app.features.pm_groups;
    },
    clientGroupingsTabTitle() {
      const reportingGroup = this.reportingGroupsEnabled && this.canViewReportingGroups;
      const pmGroup = this.pmGroupsEnabled && this.canViewPMGroups;
      if (reportingGroup && pmGroup) return 'Reporting & PM Groups';
      if (reportingGroup && !pmGroup) return 'Reporting Group';
      if (!reportingGroup && pmGroup) return 'PM Group';
      if (!reportingGroup && !pmGroup) return false;
    },
    canShowClientMaintenanceLink() {
      return this.hasFeatureFlag('client_maintenance')
        && this.activeClient.client.client_status === 'active'
        && this.activeClient.client.client_type === 'person';
    },
    clientLoadedIsTrustOrFoundation() {
      return this.clientLoaded && this.activeClient?.client?.client_type !== 'person' && this.activeClient?.client?.client_type !== 'corporation';
    },
  },
  methods: {
    async fetchLastUpdatedDate() {
      const response = await fetchLatestCompletedKycWorkflow(this.clientId);
      const workflow = (response && response.results && response.results[0] && response.results[0].workflow) || {};
      const lastUpdatedDate = workflow.date_completed || workflow.updated_at;
      this.formattedLastUpdatedDate = lastUpdatedDate ? moment(lastUpdatedDate).format('LL') : null;
    },
  },
  async mounted() {
    this.$store.dispatch('setFeatures');
    if (this.$route.hash) {
      if (this.$route.hash === '#risk') {
        this.activeTab = this.clientType === 'person' ? 1 : null;
      }
      if (this.$route.hash === '#financials') {
        this.activeTab = this.clientType === 'person' ? 2 : 1;
      }
      if (this.$route.hash === '#details') {
        this.activeTab = 0;
      }
    }
  },
  watch: {
    clientID: {
      immediate: true,
      async handler() {
        await this.$store.dispatch('fetchClientDetails', this.clientID);
        await this.fetchLastUpdatedDate();
      },
    },
  },
};
</script>

<style lang="scss">
  .client-edit-wrapper {
    .v-expansion-panel {
      .primary-btn {
        margin-bottom: 25px;
      }
      .v-avatar {
        margin-top: 10px !important;
      }
      .action-text {
        margin-bottom: 25px !important;
      }
    }
  }
</style>
