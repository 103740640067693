<template>
  <v-dialog
    :value="isOpen"
    persistent
    fullscreen
  >
    <v-card class="pa-5" id="addnewclientcard">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <v-container class="modal-container">
        <template v-if="loading">
          <pas-circular-loader></pas-circular-loader>
        </template>
      <template v-else-if="clientType === 'contact'">
        <v-layout justify-center child-flex  mb-3>
          <v-flex text-xs-center>
            <h1>Add a New Contact</h1>
          </v-flex>
        </v-layout>
        <v-layout mb-5 justify-center>
          <v-flex xs12 row text-xs-center>
            <span class="p-text">To add a new client, enter all the information in the proper fields. You may also</span>
              <span @click="clientType = 'corporation'"  class="action-text" id="link-to-add-legal-entity">
                add a new legal entity
              </span>
          </v-flex>
        </v-layout>
        <v-form
          lazy-validation
          ref="contactForm"
          @submit.prevent=""
        >
          <v-layout column mb-3>
            <v-flex xs12>
              <div class="strong-text form-label">What's the relationship?<sup>*</sup></div>
              <v-radio-group
                v-model="selectedRelationship"
                :rules="[v.isRequired()]"
              >
                <v-layout align-start child-flex row id="relationship-radios">
                  <v-radio
                    v-for="(item, i) in relationshipOptions"
                    :key="i"
                    color="#c48f3e"
                    :label="item"
                    :value="item"
                    class="mr-4"
                  ></v-radio>
                </v-layout>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout row mb-5 mt-4 class="form-row">
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">First Name<sup>*</sup></div>
                <pas-input-field
                  v-model="firstName"
                  :placeholder="`First Name`"
                  :rules="[v.isRequired(), v.isValidName()]"
                  id="first-name-input"
                ></pas-input-field>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">Middle Name</div>
                <pas-input-field
                  v-model="middleName"
                  :placeholder="`Middle Name`"
                  :rules="[v.isValidName()]"
                  id="middle-name-input"
                ></pas-input-field>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout column>
                <div class="strong-text form-label">Last Name<sup>*</sup></div>
                <pas-input-field
                  v-model="surname"
                  :placeholder="`Last Name`"
                  :rules="[v.isRequired(), v.isValidName()]"
                  id="last-name-input"
                ></pas-input-field>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="form-row email-row" mb-5>
            <v-flex xs5>
              <v-layout column>
                <div class="strong-text form-label">Email<sup v-if="selectedRelationship === 'Prospect'">*</sup></div>
                <pas-input-field
                  v-model="email"
                  :placeholder="`Email`"
                  :rules="selectedRelationship === 'Prospect' ? [v.isRequired(), v.isValidEmail] : [v.emptyOrEmail]"
                  id="email-input"
                ></pas-input-field>
              </v-layout>
            </v-flex>
            <v-flex xs5>
              <v-layout column>
                <div class="strong-text form-label">Mobile Phone Number<sup v-if="selectedRelationship === 'Prospect'">*</sup></div>
                <pas-phone-input
                  v-model="mobileNo"
                  :placeholder="`Mobile Number`"
                  :rules="selectedRelationship === 'Prospect' ? [v.isRequired(), v.isValidPhone] : [v.emptyOrPhone]"
                  id="phone-number-input"
                ></pas-phone-input>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="form-row" mb-5>
            <v-flex xs5>
              <div class="strong-text form-label">Assigned Advisor<sup>*</sup></div>
              <pas-input-field-list
                v-model="selectedAdvisor"
                :rules="[v.isRequired()]"
                :items="autocompleteAdvisors"
                :placeholder="`Select Advisor`"
                id="advisor-input"
              ></pas-input-field-list>
            </v-flex>
            <v-flex xs3>
              <div class="strong-text form-label">Advisor Code<sup>*</sup></div>
              <pas-input-field-list
                :key="`contact-advisor`"
                v-model="selectedRepCode"
                :rules="[v.isRequired()]"
                :items="dropdownRepCodes"
                :placeholder="`Select Code`"
                class="rep-code-dropdown text-sm-left"
                id="rep-code-input"
              ></pas-input-field-list>
            </v-flex>
          </v-layout>
        </v-form>
      </template>
      <template v-else-if="clientType === 'corporation'">
        <v-form
          ref="corporationForm"
          lazy-validation
          @submit.prevent=""
          id="corporationdetails"
        >

          <v-layout justify-center  mb-3>
            <v-flex text-xs-center>
              <h1>Add a New Legal Entity</h1>
            </v-flex>
          </v-layout>
          <v-layout mb-5 justify-center>
            <v-flex xs12 row text-xs-center>
              <span class="p-text">To add a new legal entity, enter all the information in the proper fields. You may also add</span>
                <span class="action-text" @click="clientType = 'contact'" id="link-to-add-client"> a new client</span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <div class="strong-text form-label">Record Type</div>
              <v-radio-group
                v-model="recordType"
                :style="{justifyContent: 'center'}"
                :rules="[v.isRequired()]"
              >
                <v-layout align-start child-flex row id="relationship-radios">
                  <v-radio
                    v-for="(item, i) in recordTypes"
                    :key="i"
                    color="#c48f3e"
                    :label="item"
                    :value="item"
                    class="mr-4"
                  ></v-radio>
                </v-layout>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="form-row" mb-5 >
            <v-flex xs5>
              <v-layout column>
                <div class="strong-text form-label">Name of Corporation or Legal Entity*</div>
                <pas-input-field
                  v-model="corporationName"
                  :placeholder="`Legal Name`"
                  :rules="[v.isRequired()]"
                  id="legal-name-input"
                ></pas-input-field>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="form-row" mb-5>
            <v-flex xs5>
              <v-layout column>
                <div class="strong-text form-label">Email*</div>
                <pas-input-field
                  v-model="email"
                  :placeholder="`Email`"
                  :rules="[v.isRequired(), v.isValidEmail]"
                  id="email-input"
                  ></pas-input-field>
              </v-layout>
            </v-flex>
            <v-flex xs5>
              <v-layout column>
                <div class="strong-text form-label">Phone</div>
                <pas-phone-input
                  v-model="corporationPhone"
                  :rules="[v.emptyOrPhone]"
                  id="phone-number-input"
                ></pas-phone-input>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout mb-4>
            <v-flex xs4 mr-5>
              <div class="strong-text form-label">Assigned Advisor<sup>*</sup></div>
              <pas-input-field-list
                v-model="selectedCorpAdvisor"
                :rules="[v.isRequired()]"
                :items="autocompleteAdvisors"
                placeholder="Select Advisor"
                id="advisor-input"
              ></pas-input-field-list>
            </v-flex>
            <v-flex xs3>
              <div class="strong-text form-label">Advisor Code<sup>*</sup></div>
              <pas-input-field-list
                v-model="selectedCorpRepCode"
                :rules="[v.isRequired()]"
                :items="dropdownRepCodes"
                :placeholder="`Select Code`"
                class="rep-code-dropdown text-sm-left"
                id="rep-code-input"
              ></pas-input-field-list>
            </v-flex>
          </v-layout>
        </v-form>
      </template>
        <v-layout mt-1>
          <v-flex text-xs-left>
            <pas-button
              @click="close"
              class=" inverted-btn px-5"
              id="cancel-add-client-btn"
            >Cancel</pas-button>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex text-xs-right>
            <pas-button
              @click="save"
              class="primary-btn px-5"
              :processing="saving"
              :id="`add-client-${clientType}-btn`"
            >{{clientType === 'corporation' ? `Add Contact` : `Add Client` }}
            </pas-button>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>
import babelPolyfill from '@babel/polyfill';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import { mapGetters } from 'vuex';
import { joinReportingGroup, createReportingGroup } from 'Services/api/ClientGroupings';
import {
  createPerson, createCorporation, createFoundation, createTrust, createIPP, createNonProfit,
} from 'Services/api/Clients';

export default {
  name: 'add-new-client',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    prePopulatedValues: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.prePopulatedValues) {
      this.selectedRepCode = this.$store.state.clients.activeClient.client.rep_code;
      this.selectedAdvisor = this.$store.state.clients.activeClient.client.advisor_id;
    }
  },
  data() {
    return {
      v,
      clientType: 'contact', // contact, corporation
      firstName: '',
      middleName: '',
      surname: '',
      email: '',
      mobileNo: '',
      relationshipOptions: [
        'Prospect',
        'Other Contact',
        'Professional',
      ],
      selectedRelationship: 'Prospect',
      corporationName: '',
      corporationPhone: '',
      recordTypes: [
        'Corporation',
        'Foundation',
        'Trust',
        'IPP',
        'Non-Profit',
      ],
      recordTypeToClientType: {
        Corporation: 'corporation',
        Foundation: 'foundation',
        Trust: 'trust',
        IPP: 'ipp',
        'Non-Profit': 'nonprofit',
      },
      recordType: 'Corporation',
      loading: false,
      selectedRepCode: '',
      selectedAdvisor: '',
      selectedCorpRepCode: '',
      selectedCorpAdvisor: '',
      saving: false,
    };
  },
  computed: {
    ...mapGetters([
      'teamMembers',
    ]),
    autocompleteAdvisors() {
      return this.teamMembers.map((m) => ({
        text: `${m.first_name} ${m.last_name}`,
        value: m.id,
      }));
    },
    dropdownRepCodes() {
      return this.$store.getters.currentUserRepCodes.map((rc) => String(rc));
    },
    firm() { return this.$store.getters.firm; },
  },
  methods: {
    async save() {
      if (this.clientType === 'contact') await this.saveContact();
      else await this.saveCorporation(this.recordTypeToClientType[this.recordType]);
      this.$store.dispatch('fetchClientsWithPermissions');
    },
    saveCorporation(type) {
      if (!this.$refs.corporationForm.validate()) return false;
      this.saving = true;
      const corporationData = {
        client_type: type,
        client_status: 'prospect',
        display_name: this.corporationName,
        advisor_id: this.selectedCorpAdvisor ? this.selectedCorpAdvisor : undefined,
        firm_id: this.firm,
        rep_code: this.selectedCorpRepCode ? this.selectedCorpRepCode : undefined,
        office_number: this.corporationPhone ? this.corporationPhone : undefined,
        preferred_number: 'O',
        reporting_fx: 'CAD',
        name: this.corporationName,
        email: this.email ? this.email : undefined,
      };
      return this.saveLegalEntity(corporationData)
        .then((res) => {
          if (!res.id) throw Error;
          this.snackBar(`${this.corporationName}`, 'success', res.id);
          return true;
        }).catch((err) => {
          this.snackBar('legal entity', 'fail');
          return false;
        });
    },
    saveLegalEntity(data) {
      if (this.recordType === 'Corporation') return createCorporation(data);
      if (this.recordType === 'Foundation') return createFoundation(data);
      if (this.recordType === 'IPP') return createIPP(data);
      if (this.recordType === 'Non-Profit') return createNonProfit(data);
      return createTrust(data);
    },
    snackBar(client, type, id = null) {
      this.saving = false;
      let text = `Error saving ${client}`;
      if (type === 'success') text = `New client ${client} has been successfully created`;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
      if (type === 'success') {
        this.close();
        if (this.redirect) this.$router.push({ path: `clients/${id}` });
      }
    },
    async saveContact() {
      try {
        if (!this.$refs.contactForm.validate()) return false;
        this.saving = true;
        const data = {
          client_type: 'person',
          client_status: {
            Prospect: 'prospect',
            'Other Contact': 'Other Contact',
            Professional: 'professional',
          }[this.selectedRelationship] || '',
          first_name: this.firstName,
          surname: this.surname,
          display_name: `${this.firstName} ${this.surname}`,
          email: this.email ? this.email : undefined,
          advisor_id: this.selectedAdvisor ? this.selectedAdvisor : undefined,
          firm_id: this.firm,
          rep_code: this.selectedRepCode ? this.selectedRepCode : undefined,
          mobile_number: this.mobileNo ? this.mobileNo : undefined,
          preferred_number: 'M',
          reporting_fx: 'CAD',
          first_name: this.firstName,
          middle_name: this.middleName ? this.middleName : undefined,
          last_name: this.surname,
        };
        const person = await createPerson(data);
        if (!person.id) throw Error;
        this.snackBar(`${this.firstName} ${this.surname}`, 'success', person.id);
        return true;
      } catch (err) {
        this.snackBar('contact', 'fail');
        return false;
      }
    },
  },
};
</script>

<style>
  .form-row > .flex {
    margin-right: 60px;
  }
  .form-row > .flex:last-child {
    margin-right: 0px !important;
  }
  .top-right-absolute {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .email-row {
    margin-bottom: 28px !important;
  }
  .rep-code-dropdown {
    width: 200px !important;
  }
</style>
