<template>
  <div>
    <v-layout mb-4 class="light-text">
      See what files you recently uploaded.
    </v-layout>
    <v-layout mb-2>
      <v-flex xs12 text-xs-left>
        <span class="form-label strong-text">Search</span>
      </v-flex>
    </v-layout>
    <v-layout mb-4>
      <v-flex xs12>
        <pas-search-box v-model="searchText"></pas-search-box>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="docs-layout">
      <div v-if="!(documents.length)" class="light-text">
        No documents have been uploaded for this workflow
      </div>
      <template v-for="(doc, index) in filteredDocs">
        <v-flex :key="`recent-doc-${index}`" :style="{maxWidth: '115px', minWidth: '100px'}">
          <v-layout column :style="{maxWidth: '115px', minWidth: '100px'}" mr-2 mb-5>
            <v-flex mb-2 text-xs-center>
              <img height="60px" v-if="doc.file_type == 0" src="../../../../assets/icons/pdf.svg">
              <img height="60px" v-if="doc.file_type == 1" src="../../../../assets/icons/doc.svg">
              <img height="60px" v-if="doc.file_type == 2" src="../../../../assets/icons/jpeg.svg">
              <img height="60px" v-if="doc.file_type == 3" src="../../../../assets/icons/png.svg">
              <img height="60px" v-if="doc.file_type == 4" src="../../../../assets/icons/excel.svg">
              <img height="60px" v-if="doc.file_type == 5" src="../../../../assets/icons/other.svg">
            </v-flex>
            <v-flex mb-2 text-xs-center class="light-text small-text doc-name">
              {{doc.name.toLowerCase()}} 
            </v-flex>
            <v-flex  text-xs-center>
              <v-layout row nowrap>
                <v-flex xs4>
                  <div v-if="FILE_TYPES[doc.file_type] == 'doc'" class="clickable-icon-circle"  @click="downloadFile(doc.key, doc.name)">
                    <v-icon>mdi-eye-outline</v-icon>
                  </div>
                  <router-link v-else :to="`/view_document?doc=${doc.key}`" target="_blank">
                    <div class="clickable-icon-circle">
                      <v-icon>mdi-eye-outline</v-icon>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs4>
                  <div class="clickable-icon-circle" @click="downloadFile(doc.key, doc.name, doc.file_type)">
                    <v-icon>mdi-download</v-icon>
                  </div>
                </v-flex>
                <v-flex xs4>
                  <div class="clickable-icon-circle" @click="confirmDeleteDoc(doc._id)">
                    <v-icon>mdi-close</v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
      
      <ConfirmActionDialog
      @close="cancelDocDeletion"
      @ok="deleteFile"
      :isOpen="deleteDialog"
      :width="'400px'"
      >
        <template v-slot:header>Delete this file</template>
        Are you sure you want to proceed?
        <template v-slot:btn>Delete</template>
      </ConfirmActionDialog>
    </v-layout>
  </div>
</template>

<script>

import _ from 'lodash'
import { FILE_TYPES} from 'Services/constants'
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog'

export default {
  name: 'workflow-view-related-documents-tab',
  components: {
    ConfirmActionDialog
  },
  mounted() {
    document.cookie = `jwtCookie=${localStorage.getItem('jwt')}; path= /`
  },
  data() {
    return {
      searchText: '',
      docForDelete: '',
      deleteDialog: false,
      FILE_TYPES
    }
  },
  props: {
    workflowId: String,
    documents: Array
  },
  computed: {
    filteredDocs() {
      let filteredDocs = _.cloneDeep(this.documents)
      //filter the docs on searchText
      filteredDocs = filteredDocs.filter(d => {
        return (d.name.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1)
      })
      //select the 3 most recent docs
      filteredDocs.reverse()
      //filteredDocs = filteredDocs.slice(0, 3)
      return filteredDocs
    },
    activeClient(){
      return this.$store.state.clients.activeClient;
    },    
  },
  methods: {
    cancelDocDeletion() {
      this.docForDelete = ''
      this.deleteDialog = false
    },
    confirmDeleteDoc(docId) {
      this.docForDelete = docId
      this.deleteDialog = true
    },
    downloadFile(key, filename, filetype) {
      filename += `.${FILE_TYPES[filetype] || 'pdf'}`;
      let url = `${window.location.protocol}//${window.location.host}`;
      url += `/advisor_portal/view_document?doc=${key}`;
      fetch(url, {
        method: 'GET',
        redirect: 'manual',
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('jwt')}`
        }
      })
      .then(res => {
        if (res.error) throw Error('document download failed');

				return res.blob().then(b => {
					var a = document.createElement("a");
					a.href = URL.createObjectURL(b);
					a.setAttribute("download", filename);
					a.click();
				});
			});
    },
    deleteFile() {
      this.deleteDialog = false
      const docForDelete = this.documents.filter(doc => doc._id === this.docForDelete)[0];
      this.$store.dispatch('deleteWorkflowDocument', { workflow: this.workflowId, awsKey: docForDelete.key })
      .then(res => {
        const text = res === true ? `Successfully deleted the document` : `Document deletion failed`;
        this.$store.dispatch('setSnackbar', { text , type: res === true ? 'success' : 'fail' });
        this.$store.dispatch('flipSnackbarSwitch');
        this.$store.dispatch('fetchWorkflow', this.workflowId);
        this.$store.dispatch("setDocuments", this.activeClient.client.id);
      })
      .catch(err => {
        console.log('err', err)
      })
    }
  }
}
</script>

<style lang="scss">
 
  .clickable-icon-circle {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #dddddd;
    i {
      font-size: 15px !important;
    }
  }
  .related-docs-icon {
    font-size: 80px !important;
  }
  .docs-layout > .flex {
    margin-right: 10px !important;
  }
  .doc-name {
    height: 2.5em;
    vertical-align: baseline;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
