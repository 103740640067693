import api from 'Services/api';
import apiV2 from 'Services/apiV2';
import { FIRMS } from 'Services/constants';
import ThemeVars from 'Services/ThemeHelpers/ThemeVars';
import ThemeAssets from 'Services/ThemeHelpers/ThemeAssets';
import { buildStylesheetHref } from '../../services/ThemeHelpers/ThemeHelpers';

const defaultState = {
  env: null,
  theme: '',
  themeVars: {},
  themeAssets: {},
  features: {},
  custodian: '',
};

const mutations = {
  SET_ENV(state, env) {
    state.env = env;
  },
  SET_THEME(state, { theme, vars, assets }) {
    state.theme = theme;
    state.themeVars = vars;
    state.themeAssets = assets;
  },
  SET_FEATURES(state, features) {
    state.features = features;
  },
  SET_CUSTODIAN(state, custodian) {
    state.custodian = custodian;
  },
};

const actions = {
  getEnv({ commit }) {
    return api.get('/environment')
      .then((res) => commit('SET_ENV', res.env))
      .catch((err) => console.log('err', err));
  },
  setTheme({ commit }, theme) {
    // set the theme_stylesheet to the appropriate sheet reference
    document.getElementById('theme_stylesheet').href = buildStylesheetHref(theme);
    const vars = ThemeVars[theme];
    const assets = { ...ThemeAssets.shared, ...ThemeAssets[theme] };
    commit('SET_THEME', { theme, vars, assets });
  },
  setFeatures({ commit }) {
    return api.get('/datahub/features/')
      .then((res) => {
        if (!res.results) throw Error;
        commit('SET_FEATURES', res.results[0]);
      })
      .catch((err) => console.log('err', err));
  },
  getCustodian({ commit }) {
    return apiV2.get('/metadata/', { key: 'custodian' })
      .then((res) => commit('SET_CUSTODIAN', res.value))
      .catch((err) => console.log('err', err));
  },
};

export const getters = {
  firm: (state) => Object.keys(FIRMS).find((key) => FIRMS[key] === state.theme),
  currentTheme: (state) => state.theme,
  hasFeatureFlag: (state) => (feature) => !!state.features[feature],
  env: (state) => state.env,
  isNoCall: (state) => state.features.no_call,
  isCCTFeature: (state) => state.features.client_confirmation_tool,
  custodian: (state) => state.custodian,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
