export default {
  fwp: {
    primaryColor: '#c48f3e',
    primaryTranslucent: 'rgba(245,179,78,0.1)',
    'primary-translucent-2': 'rgba(196,143,62,0.2)',
    'primary-translucent-7': 'rgba(196,143,62,0.7)',
  },
  milestone: {
    primaryColor: '#2d5d8b',
    primaryTranslucent: 'rgba(45,93,139, 0.1)',
    'primary-translucent-2': 'rgba(45,93,139, 179,0.2)',
    'primary-translucent-7': 'rgba(45,93,139,0.7)',
  },
  harness: {
    primaryColor: '#2d5d8b',
    primaryTranslucent: 'rgba(45,93,139, 0.1)',
    'primary-translucent-2': 'rgba(45,93,139, 179,0.2)',
    'primary-translucent-7': 'rgba(45,93,139,0.7)',
  },
  'harness-fcc': {
    primaryColor: '#2d5d8b',
    primaryTranslucent: 'rgba(45,93,139, 0.1)',
    'primary-translucent-2': 'rgba(45,93,139, 179,0.2)',
    'primary-translucent-7': 'rgba(45,93,139,0.7)',
  },
  samara: {
    primaryColor: '#2d5d8b',
    primaryTranslucent: 'rgba(45,93,139, 0.1)',
    'primary-translucent-2': 'rgba(45,93,139, 179,0.2)',
    'primary-translucent-7': 'rgba(45,93,139,0.7)',
  },
  'fwp-fcc': {
    primaryColor: '#c48f3e',
    primaryTranslucent: 'rgba(245,179,78,0.1)',
    'primary-translucent-2': 'rgba(196,143,62,0.2)',
    'primary-translucent-7': 'rgba(196,143,62,0.7)',
  },
  ofc: {
    primaryColor: '#1c3a55',
    primaryTranslucent: 'rgba(28,58,85,0.1)',
    'primary-translucent-2': 'rgba(28,58,85,0.2)',
    'primary-translucent-7': 'rgba(28,58,85,0.7)',
  },
  wwm: {
    primaryColor: '#383838',
    primaryTranslucent: 'rgba(56,56,56,0.1)',
    'primary-translucent-2': 'rgba(56,56,56,0.2)',
    'primary-translucent-7': 'rgba(56,56,56,0.7)',
  },
  owa: {
    primaryColor: '#1E1E1E',
    primaryTranslucent: 'rgba(30,30,30,0.1)',
    'primary-translucent-2': 'rgba(30,30,30,0.2)',
    'primary-translucent-7': 'rgba(30,30,30,0.7)',
  },
  fim: {
    primaryColor: '#4D5EAA',
    primaryTranslucent: 'rgba(77, 94, 170, 0.1)',
    'primary-translucent-2': 'rgba(77, 94, 170, 0.2)',
    'primary-translucent-7': 'rgba(77, 94, 170, 0.7)',
  },
  vcm: {
    primaryColor: '#FF8200',
    primaryTranslucent: 'rgba(255, 130, 0, 0.1)',
    'primary-translucent-2': 'rgba(255, 130, 0, 0.2)',
    'primary-translucent-7': 'rgba(255, 130, 0, 0.7)',
  },
  pic: {
    primaryColor: '#FF8200',
    primaryTranslucent: 'rgba(255, 130, 0, 0.1)',
    'primary-translucent-2': 'rgba(255, 130, 0, 0.2)',
    'primary-translucent-7': 'rgba(255, 130, 0, 0.7)',
  },
  mwm: {
    primaryColor: '#2d5d8b',
    primaryTranslucent: 'rgba(45,93,139, 0.1)',
    'primary-translucent-2': 'rgba(45,93,139, 179,0.2)',
    'primary-translucent-7': 'rgba(45,93,139,0.7)',
  },
  tsw: {
    primaryColor: '#8b6b2e',
    primaryTranslucent: 'rgba(139,107,46, 0.1)',
    'primary-translucent-2': 'rgba(139,107,46,0.2)',
    'primary-translucent-7': 'rgba(139,107,46,0.7)',
  },
  dsg: {
    primaryColor: '#642b84',
    primaryTranslucent: 'rgba(100, 43, 132, 0.1)',
    'primary-translucent-2': 'rgba(100, 43, 132, 0.2)',
    'primary-translucent-7': 'rgba(100, 43, 132, 0.7)',
  },
  awi: {
    primaryColor: '#064e75',
    primaryTranslucent: 'rgba(6, 78, 117, 0.1)',
    'primary-translucent-2': 'rgba(6, 78, 117, 0.2)',
    'primary-translucent-7': 'rgba(6, 78, 117, 0.7)',
  },
};
