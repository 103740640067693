<template>
  <v-progress-linear
    :color="primaryColor"
    :indeterminate="true"
  ></v-progress-linear>
</template>

<script>
export default {
  name: 'pas-linear-loader',
  props: {
  },
  data() {
    return {};
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style>

</style>
