<template>
    <div>
    <vue-dropzone
        :ref="`dropzone`"
        :id="id"
        :options="options"
        :useCustomSlot="useCustomSlot"
    >
        <v-layout align-center>
            <v-flex>
                <span class="vue-dropzone-header">Drag or drop files here <br>
                or <span id="gold-header">browse</span> your computer</span>
            </v-flex>
        </v-layout>

    </vue-dropzone>

    </div>
</template>

<script>

import vueDropzone from 'vue2-dropzone';

export default {
  name: 'pas-dropzone',
  components: {
    vueDropzone,
  },
  props: {
    options: Object,
    useCustomSlot: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">

#light-header {
    font-weight: 500;
}
#gold-header {
    color: var(--primary-color);
}
.vue-dropzone-header {
    line-height: 16px;
    letter-spacing: 0.8px !important;
    font-size: 14px;
    color:#9e9e9e;
}
.vue-dropzone {
    border: 2px dotted #9e9e9e !important;
    background-color: #F6F6F6 !important;
    border-radius: 6px !important;
    color: var(--regular-text) !important;
}
.short-dropzone .vue-dropzone {
    min-height: 90px !important;
    height: 90px !important;
}
.short-dropzone .dz-message {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

</style>
