<template>
  <PContainer fluid class="px-0">
    <PRow no-gutters>
      <PColumn class="household-sidenav">
        <HouseholdSidebar
          :sections="sidebarSections"
          @onSideNavItemClick="onSideNavItemClick"
        />
      </PColumn>
      <PColumn class="household-tabs">
        <PTabs class="mt-5" :showBottomBorder="true" @input="onTabActivated">
          <PTab title="Households">
            <HouseholdList />
          </PTab>
          <PTab title="Clients">
            <ClientsTab />
          </PTab>
        </PTabs>
      </PColumn>
    </PRow>
    <ModalWrapper />
  </PContainer>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

import HouseholdSidebar from './HouseholdSidebar';
import HouseholdList from './Households/HouseholdList';
import ClientsTab from './ClientsTab';
import ModalWrapper from '../../ModalWrapper';

const modalManagerVux = createNamespacedHelpers('modalManager');
const analytics = createNamespacedHelpers('analytics');

export default {
  name: 'Households',
  components: {
    HouseholdSidebar,
    HouseholdList,
    ClientsTab,
    ModalWrapper,
  },
  data() {
    return {
      sidebarSections: [
        {
          id: 1,
          title: 'Create Household',
          icon: 'house-user',
          route: '',
          modalAction: 'openCreateHouseholdModal',
          enabled: true,
        },
        {
          id: 2,
          title: 'New Client',
          icon: 'user-plus',
          modalAction: 'openNewClientModal',
          route: '',
          enabled: true,
        },
      ],
    };
  },
  computed: {
    ...modalManagerVux.mapGetters([
      'currentModal',
      'currentFlow',
      'currentStage',
    ]),
    ...analytics.mapState({
      analyticsRegistered: 'registered',
    }),
  },
  methods: {
    ...modalManagerVux.mapActions([
      'openModal',
      'goToPreviousModal',
      'goToNextModal',
    ]),
    ...analytics.mapActions(['trackEvents']),
    async onSideNavItemClick(item) {
      if (item.modalAction === 'openCreateHouseholdModal') {
        await this.openModal({
          flow: 'HouseholdFlows',
          stage: 'createNewHousehold',
          modal: 'EnterHouseholdName',
        });
        await this.trackEvents({ event: 'Households', eventProperties: { view: 'index', action: 'createHousehold' } });
      } else if (item.modalAction === 'openNewClientModal') {
        await this.openModal({
          flow: 'HouseholdFlows',
          stage: 'SelectClientType',
          modal: 'SelectClientType',
        });
        await this.trackEvents({ event: 'Households', eventProperties: { view: 'index', action: 'addNewClient' } });
      }
    },
    async onTabActivated(tabIndex) {
      // tabIndex should be equal to the Clients tab index, collecting analytics for this tab
      if (tabIndex === 1) {
        await this.trackEvents({ event: 'Households', eventProperties: { view: 'index', action: 'clientsTabActivated' } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.household-sidenav {
  max-width: 365px;
}
.household-tabs {
  height:100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.purposeful-tabs {
  border-bottom: 1px solid var(--subtle-styling);
}
</style>
