import _ from 'lodash';
import {
  REQUEST_SUBTYPE_IDS,
  REQUEST_TYPES,
  RESP_RECIPIENT_IDS,
  FUNDING_TYPE,
  AMOUNT_VALUES,
  transferMixed,
  transferOptions,
} from '../../../services/helpers/funding/index';
import { currencyFormatter } from '../../../helpers';

export const confirm = {
  accountNumber: (value) => ({
    label: 'Account Number',
    value,
  }),
  amount: (value) => ({
    label: 'Amount',
    value,
  }),
  assets: (details) => ({
    label: 'Asset Details',
    assets: details,
  }),
  bankAccount: (value) => ({
    label: 'Bank Account',
    value,
  }),
  complexTo: (valueArray) => ({
    label: 'To',
    valueArray,
  }),
  contactInformation: (value) => ({
    label: 'Contact Information',
    value,
  }),
  organizationName: (value) => ({
    label: 'Organization Name',
    value,
  }),
  currency: (value) => ({
    label: 'Currency',
    value,
  }),
  details: (value) => ({
    label: 'Additional Details',
    value,
  }),
  document: (valueArray) => ({
    label: 'Document(s)',
    valueArray,
  }),
  endDate: (value) => ({
    label: 'End date',
    value,
  }),
  runDate: (value) => ({
    label: 'Run date',
    value,
  }),
  frequency: (value) => ({
    label: 'Frequency',
    value,
  }),
  from: (value) => ({
    label: 'From',
    value,
  }),
  fromBankAccount: (value) => ({
    label: 'From',
    value,
  }),
  fromInvestmentAccount: (value) => ({
    label: 'From',
    value,
  }),
  isDonation: (val = false) => ({
    label: 'Is this a donation?',
    value: val ? 'Yes' : 'No',
  }),
  paymentMethod: (value) => ({
    label: 'Method of Payment',
    value,
  }),
  receiverInstitution: (value) => ({
    label: 'Receiver Institution',
    value,
  }),
  recurringEvent: (value) => ({
    label: 'Existing recurring event',
    value,
  }),
  respRecipient: (value) => ({
    label: 'Recipient',
    value,
  }),
  shareDetails: (details) => ({
    label: 'Details',
    shareDetails: details,
  }),
  startDate: (value, dateLabel = 'Start Date') => ({
    label: dateLabel,
    value,
  }),
  taxable: (val = false) => ({
    label: 'Taxable',
    value: val ? 'Taxable' : 'Non-taxable',
  }),
  to: (value) => ({
    label: 'To',
    value,
  }),
  toBankAcct: (value) => ({
    label: 'To',
    value,
  }),
  toInvestmentAccount: (value) => ({
    label: 'To',
    value,
  }),
  totalAmount: (totalAmount, totalAmountArr) => ({
    label: 'Total Amount',
    value: totalAmount,
    valueArray: totalAmountArr,
  }),
  transferMethod: (value) => ({
    label: 'Transfer method',
    value,
  }),
  transferRemainingAssets: (value) => ({
    transferRemaining: value,
  }),
  transferType: (value) => ({
    label: 'Transfer type',
    value,
  }),
  withdrawalTax: (value) => ({
    label: 'Withdrawal Tax',
    value: `${value}%`,
  }),
  withdrawalType: (value) => ({
    label: 'Withdrawal Type',
    value,
  }),
  withholdingTax: (value) => ({
    label: 'Withholding Tax',
    value: `${value}%`,
  }),
  withholdingTaxType: (value) => ({
    label: 'Withholding Tax Type',
    value,
  }),
};

const respPaymentType = (paymentType) => {
  switch (paymentType) {
    case 'educational_assistance_payment':
      return 'EAP';
    case 'postsecondary_educational_payment':
      return 'PSE';
    case 'non_educational_capital_withdrawal_amount':
      return 'NEC';
    default:
      return null;
  }
};

export const getObject = (objectList, id) => objectList.find((e) => e.id == id);

export const accountFormatter = (account, includePrimaryAccName = false) => `${(includePrimaryAccName && account.primary_holder?.name + ' ') || ''}"${account?.name}" ${account?.type || ''} ${account?.account_number || ''}`;
export const recurringEventAccountFormatter = (revent, fundingType) => {
  if (fundingType === FUNDING_TYPE.DEPOSIT) {
    return `${revent.to_account.account_name}`;
  }

  // WITHDRAWALS
  return `"${revent.from_account.account_name}" ${revent.from_account.account_type || ''} ${revent.from_account.account_no}`;
};
export const amountFormatter = (amount, account) => `${Object.values(AMOUNT_VALUES).includes(amount) ? amount : currencyFormatter(amount)}${account?.currency ? ` ${account?.currency}` : ''}`;
export const subAmount = (amount, paymentType, account) => `${amountFormatter(amount || '0.00', account)} (${respPaymentType(paymentType)})`;
export const docFormatter = (doc) => (doc.name.length > 10 ? `${doc.name.substring(0, 20)}...${doc.name.substr(doc.name.length - 4)}` : doc.name);
export const recurringEventFormatter = (revent, fundingType) => `${amountFormatter(revent.amount)} - ${revent.frequency} - ${recurringEventAccountFormatter(revent, fundingType)}`;
export const getLabelFromId = (id, arr) => arr.find((x) => x.id === id)?.label;

const respTotalAmount = (
  educationalAssistancePayment,
  postsecondaryEducationalPayment,
  nonEducationalCapitalWithdrawalAmount,
  fromInvestmentAcct,
) => ([
  subAmount(educationalAssistancePayment, 'educational_assistance_payment', fromInvestmentAcct),
  subAmount(postsecondaryEducationalPayment, 'postsecondary_educational_payment', fromInvestmentAcct),
  subAmount(nonEducationalCapitalWithdrawalAmount, 'non_educational_capital_withdrawal_amount', fromInvestmentAcct),
]);

export const requestConfirmation = ({
  bankAcct,
  frequency,
  fromInvestmentAcct,
  investmentAcct,
  subtype,
  toBankAcct,
  toInvestmentAcct,
  validatedRequest,
  defaultRequestType,
  paymentMethod,
  withdrawalTypes,
  withdrawalType,
  withholdingTaxType,
  fundingDocuments,
  currency,
  selectedType,
  externalTransferInvestAcct,
}) => {
  let dateLabel = 'Start date';
  if (frequency === 'One Time') dateLabel = 'Date';

  let fields = [];

  if (defaultRequestType) {
    if (selectedType === REQUEST_TYPES.WITHDRAWAL) { // withdrawal
      const withdrawalFields = [
        {
          label: 'Gross amount',
          value: amountFormatter(validatedRequest.amount, investmentAcct),
        }, {
          label: 'Net amount',
          value: amountFormatter(validatedRequest.net_amount, investmentAcct),
        }, {
          label: 'Tax',
          value: amountFormatter(validatedRequest.withholding_tax, investmentAcct),
        }, {
          label: 'From',
          value: `"${investmentAcct?.name}" ${investmentAcct?.type} ${investmentAcct?.account_number || ''}`,
        }, {
          label: 'To',
          value: bankAcct?.label,
        }, {
          label: 'Frequency',
          value: frequency,
        }, {
          label: dateLabel,
          value: validatedRequest.start_date,
        },
      ];
      if (validatedRequest.reason) {
        withdrawalFields.push({
          label: 'Type',
          value: getObject(withdrawalTypes, validatedRequest.reason).reason,
        });
      }
      if (validatedRequest.end_date) {
        withdrawalFields.push(confirm.endDate(validatedRequest.end_date));
      }
      fields = fields.concat(withdrawalFields);
    } else if (selectedType === REQUEST_TYPES.DEPOSIT) {
      const depositFields = [
        {
          label: 'From',
          value: bankAcct?.label,
        }, {
          label: 'To',
          value: `"${investmentAcct?.name}" ${investmentAcct?.type} ${investmentAcct?.account_number || ''}`,
        }, {
          label: 'Amount',
          value: amountFormatter(validatedRequest.amount, bankAcct),
        }, {
          label: 'Frequency',
          value: frequency,
        }, {
          label: dateLabel,
          value: validatedRequest.start_date,
        },
      ];
      if (validatedRequest.end_date) {
        depositFields.push(confirm.endDate(validatedRequest.end_date));
      }
      fields = fields.concat(depositFields);
    }
  } else {
    if (selectedType === REQUEST_TYPES.DEPOSIT) {
      fields.push(...[
        confirm.to(accountFormatter(toInvestmentAcct)),
        confirm.startDate(validatedRequest.start_date, dateLabel),
      ]);
    } else if (selectedType === REQUEST_TYPES.WITHDRAWAL) {
      fields.push(...[
        confirm.from(accountFormatter(fromInvestmentAcct)),
      ]);
    } else if (selectedType === REQUEST_TYPES.INTERNAL_TRANSFER) {
      fields.push(...[
        confirm.from(accountFormatter(fromInvestmentAcct)),
        confirm.to(accountFormatter(
          toInvestmentAcct,
          subtype === REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_DIFFERENT_CLIENTS,
        )),
        confirm.transferMethod(getLabelFromId(validatedRequest.transfer_method, transferOptions.methods)),
        confirm.transferType(getLabelFromId(validatedRequest.transfer_type, transferOptions.types)),
      ]);
      if (validatedRequest.share_certificates?.length > 0) {
        fields.push(confirm.shareDetails(validatedRequest.share_certificates));
      }
      if (validatedRequest.amount) {
        fields.push(confirm.amount(amountFormatter(validatedRequest.amount, fromInvestmentAcct)));
      }
      if (subtype === REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_DIFFERENT_CLIENTS) {
        fields.push(
          confirm.isDonation(validatedRequest.is_donation),
          confirm.taxable(validatedRequest.taxable),
        );
      }
    } else if (selectedType === REQUEST_TYPES.EXTERNAL_TRANSFER) {
      if (validatedRequest.subtype === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_DONATION_REQUEST) {
        fields.push(confirm.from(accountFormatter(externalTransferInvestAcct)));
        fields.push(confirm.organizationName(validatedRequest.organization_name));
      } else if (validatedRequest.subtype === REQUEST_SUBTYPE_IDS.EXTERNAL_TRANSFER_IN) {
        fields.push(confirm.to(accountFormatter(externalTransferInvestAcct)));
      }
      fields.push(...[
        confirm.receiverInstitution(validatedRequest.external_institution),
        confirm.accountNumber(validatedRequest.external_institution_account_no),
        confirm.contactInformation(validatedRequest.contact_information),
        confirm.transferMethod(getLabelFromId(validatedRequest.transfer_method, [...transferOptions.methods, transferMixed])),
        confirm.transferType(getLabelFromId(validatedRequest.transfer_type, [...transferOptions.types, transferMixed])),
        confirm.runDate(validatedRequest.run_date),
      ]);
      if (validatedRequest.share_certificates?.length > 0) {
        fields.push(confirm.shareDetails(validatedRequest.share_certificates));
      }
      if (validatedRequest.assets?.length > 0) {
        const assets = validatedRequest.assets.map((asset) => ({
          ...asset,
          transfer_method_label: transferOptions.methods.find((method) => method.id === asset.transfer_method)?.label,
        }));
        if (validatedRequest.transfer_remaining_assets_method) {
          assets.push(confirm.transferRemainingAssets(validatedRequest.transfer_remaining_assets_method));
        }
        fields.push(confirm.assets(assets));
      }
      if (validatedRequest.amount_estimated || validatedRequest.amount_cash) {
        fields.push(confirm.amount(amountFormatter(validatedRequest.amount_estimated || validatedRequest.amount_cash, externalTransferInvestAcct)));
      }
      if (validatedRequest.expected_completion_date) {
        fields.push(confirm.startDate(validatedRequest.expected_completion_date, dateLabel));
      }
    }

    /* DEPOSIT TYPES */
    switch (subtype) {
      case REQUEST_SUBTYPE_IDS.DEPOSIT_CHEQUE:
        fields.push(...[
          confirm.amount(amountFormatter(validatedRequest.amount, toInvestmentAcct)),
          confirm.currency(currency),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.DEPOSIT_RECURRING_EVENT_AUTO_PURCHASE:
        fields.push(...[
          confirm.recurringEvent(recurringEventFormatter(validatedRequest.recurring_event), FUNDING_TYPE.DEPOSIT),
          confirm.shareDetails(validatedRequest.share_certificates),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.DEPOSIT_SHARE_CERTIFICATE:
        fields.push(...[
          confirm.shareDetails(validatedRequest.share_certificates),
        ]);
        break;
      /* WITHDRAWAL TYPES */
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_HBP_LLP_EXCESS:
        fields.push(...[
          confirm.paymentMethod(paymentMethod),
          confirm.to(toBankAcct?.label),
          confirm.amount(amountFormatter(validatedRequest.amount, fromInvestmentAcct)),
          confirm.withdrawalType(withdrawalType),
          confirm.startDate(validatedRequest.start_date, dateLabel),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_CHEQUE_OR_WIRE_REQUEST:
        fields.push(...[
          confirm.to(toBankAcct?.label),
          confirm.amount(amountFormatter(validatedRequest.amount, fromInvestmentAcct)),
          confirm.currency(currency),
          confirm.paymentMethod(paymentMethod),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_INCOME_PAYMENT_MODIFICATION:
        fields.push(...[
          confirm.to(toBankAcct?.label || toInvestmentAcct?.label),
          (validatedRequest.amount || validatedRequest.net_amount) ? confirm.amount(amountFormatter(validatedRequest.amount || validatedRequest.net_amount, fromInvestmentAcct)) : '',
          confirm.frequency(frequency),
          confirm.withdrawalType(withdrawalType),
          confirm.withholdingTaxType(withholdingTaxType),
          validatedRequest.withholding_tax ? confirm.withholdingTax(validatedRequest.withholding_tax) : '',
          confirm.startDate(validatedRequest.start_date, dateLabel),
        ]);
        if (validatedRequest.end_date) {
          fields.push(confirm.endDate(validatedRequest.end_date));
        }
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_ONE_TIME_RRIF_SRRIF_LIF:
        fields.push(...[
          confirm.to(toBankAcct?.label || toInvestmentAcct?.label),
          confirm.paymentMethod(paymentMethod),
          (validatedRequest.amount || validatedRequest.net_amount) ? confirm.amount(amountFormatter(validatedRequest.amount || validatedRequest.net_amount, fromInvestmentAcct)) : '',
          confirm.withdrawalType(withdrawalType),
          confirm.withholdingTaxType(withholdingTaxType),
          validatedRequest.withholding_tax ? confirm.withholdingTax(validatedRequest.withholding_tax) : '',
          confirm.startDate(validatedRequest.start_date, dateLabel),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_RECURRING_EVENT_AUTO_SELL:
        fields.push(...[
          confirm.recurringEvent(recurringEventFormatter(validatedRequest.recurring_event, FUNDING_TYPE.WITHDRAWAL)),
          confirm.shareDetails(validatedRequest.share_certificates),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_RESP:
        const totalAmountArr = respTotalAmount(
          validatedRequest.educational_assistance_payment,
          validatedRequest.postsecondary_educational_payment,
          validatedRequest.non_educational_capital_withdrawal_amount,
          fromInvestmentAcct
        );
        let respRecipient;
        if (validatedRequest.resp_recipient && validatedRequest.resp_recipient === RESP_RECIPIENT_IDS.ACCOUNT_OWNER) {
          fields.push(confirm.to(toBankAcct?.label));
          respRecipient = 'Account Owner';
        } else if (validatedRequest.resp_recipient === RESP_RECIPIENT_IDS.BENEFICIARY) {
          respRecipient = "Beneficiary"
        }

        fields.push(...[
          confirm.respRecipient(respRecipient),
          confirm.to(toBankAcct?.label || toInvestmentAcct?.label),
          confirm.totalAmount(amountFormatter(validatedRequest.amount, fromInvestmentAcct), totalAmountArr),
          confirm.paymentMethod(paymentMethod),
          confirm.startDate(validatedRequest.start_date, dateLabel),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_THIRD_PARTY_WIRE_REQUEST:
        const toWireTransferData = _.cloneDeep(validatedRequest.to_wire_transfer_bank_account);
        // Remove currency since duplicate entry.
        delete toWireTransferData.currency;

        const arr = Object.keys(toWireTransferData)?.map(
          (e) => `(${_.upperFirst(_.lowerCase(e))}) ${toWireTransferData[e]}`
        );
        fields.push(...[
          confirm.complexTo(arr),
          confirm.paymentMethod(paymentMethod),
          confirm.amount(amountFormatter(validatedRequest.amount, toWireTransferData)),
          confirm.currency(currency),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_UNLOCKING:
        fields.push(...[
          confirm.to(toBankAcct?.label || toInvestmentAcct?.label),
          confirm.amount(amountFormatter(validatedRequest.amount, fromInvestmentAcct)),
          confirm.paymentMethod(paymentMethod),
        ]);
        break;
      case REQUEST_SUBTYPE_IDS.WITHDRAWAL_INCOME_SWEEP:
        fields.push(...[
          confirm.to(toBankAcct?.label || toInvestmentAcct?.label),
          confirm.paymentMethod(paymentMethod),
          confirm.frequency(frequency),
          confirm.startDate(validatedRequest.start_date, dateLabel),
        ]);
        if (validatedRequest.end_date) {
          fields.push(confirm.endDate(validatedRequest.end_date));
        }
        break;
      default:
        break;
    }
  }

  // For all subtypes
  if (fundingDocuments?.length > 0) {
    fields.push(confirm.document(fundingDocuments.map((doc) => docFormatter(doc))));
  }

  return fields;
};
