<template>
  <div>
    <v-form
      ref="editForm"
      lazy-validation
      @submit.prevent=""
      v-if="editMode"
      @click.stop=""
    >
      <v-text-field
        type="text"
        v-model="editedValue"
        class="light-text inline-edit-field input-field no-border-input-field"
        @keyup.enter="saveEdit"
        @blur="!saved ? saveEdit() : null"
        :rules="rules"
        outline
        single-line
        :maxlength="maxlength"
        ref="textField"
      ></v-text-field>
    </v-form>
    <span
      v-else
      :class="`${editableSpanClass} editable-span`"
      @dblclick="toggleEditMode"
    >
      <span class="edit-content" >{{editedValue}}</span>
      <v-icon v-if="!disabled" :class="`edit-icon ${editIconClass}`">{{editIcon}}</v-icon>
    </span>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';


export default {
  name: 'pas-editable-span',

  data() {
    return {
      editMode: false,
      editedValue: this.value,
      saved: false,
    };
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editableSpanClass: {
      type: String,
      default: 'light-text',
    },
    editIcon: {
      type: String,
      default: 'edit',
    },
    editIconClass: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
  },
  methods: {
    saveEdit() {
      if (!(this.$refs.editForm.validate())) return false;
      this.toggleEditMode();
      this.saved = true;
      this.$emit('editSaved', this.editedValue);
    },
    toggleEditMode() {
      if (this.disabled) return null;
      const that = this;
      this.editMode = !(this.editMode);
      if (this.editMode) {
        this.saved = false;
        setTimeout(() => {
          that.$refs.textField.focus();
        }, 150);
      }
    },
  },
  watch: {
    value() {
      this.editedValue = this.value;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
.edit-icon {
  font-size: 15px !important;
  margin-left: 10px;
}
.edit-icon-small {
  font-size: 10px !important;
  margin-left: 10px;
  margin-bottom: 3px;
}
.edit-icon-light {
  color: rgba(255,255,255,0.6) !important;
}
.editable-span {
  cursor: pointer !important;
  display: flex;
}
.edit-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
