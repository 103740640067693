export const AKYC_STATUS_OVERDUE = 'overdue';
export const AKYC_STATUS_AT_RISK = 'at_risk';
export const AKYC_STATUS_UPCOMING = 'upcoming';

export const AKYC_MAP_STATUS_LABEL = {
  [AKYC_STATUS_OVERDUE]: 'overdue',
  [AKYC_STATUS_AT_RISK]: 'at risk',
  [AKYC_STATUS_UPCOMING]: 'upcoming',
};

export const AKYC_MAP_STATUS_VARIANT = {
  [AKYC_STATUS_OVERDUE]: 'danger-light',
  [AKYC_STATUS_AT_RISK]: 'warning-light',
  [AKYC_STATUS_UPCOMING]: 'success-light',
};

export const NOTIFIED_ITEMS = [
  {
    label: 'All',
  },
  {
    key: 'one_week_ago',
    label: '1 Week Ago',
  },
  {
    key: 'two_weeks_ago',
    label: '2 Weeks Ago',
  },
  {
    key: 'over_four_weeks_ago',
    label: 'Over 4 Weeks Ago',
  },
  {
    key: 'not_notified',
    label: 'Not Notified',
  },
];

export const STATUS_ITEMS = [
  {
    label: 'All',
  },
  {
    key: 'overdue',
    label: 'Overdue',
  },
  {
    key: 'at_risk',
    label: 'At Risk',
  },
  {
    key: 'upcoming',
    label: 'Upcoming',
  },
];
