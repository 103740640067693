<template>
  <v-flex xs12 class="comment-div" py-4>
    <v-layout
      row 
      nowrap
    >
      <v-flex :style="{'maxWidth': '60px'}">
        <pas-avatar>
        </pas-avatar>
      </v-flex>
      <v-flex pl-2>
        <v-layout column>
          <v-flex xs12>
            <span class="strong-text">
              {{activity.author_name}}
            </span>
            <span class="light-text">
              &nbsp;{{`${activity.content.charAt(0).toLowerCase()}${activity.content.slice(1)}`}}
            </span>
          </v-flex>
          <v-flex text-xs-left>
            <span class="light-text small-text">
              {{getTimeDiffString(activity.timestamp).indexOf('seconds') !== -1 ? 'Just now' : getTimeDiffString(activity.timestamp)}}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { getTimeDiffString } from 'Services/helpers/time'

export default {
  name: 'workflow-view-feed-item',
  data() {
    return {
      getTimeDiffString: getTimeDiffString,      
    }
  },
  props: {
    activity: Object
  }
}
</script>

<style>
</style>
