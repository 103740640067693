<template>
  <v-progress-circular
    :value="value"
    :size="8"
    :width="2"
    :color="mappedColor"
    class="notification-bullet"
  ></v-progress-circular>
</template>

<script>
export default {
  name: 'status-bullet',
  props: {
    color: {
      type: String,
      default: 'green',
    },
    value: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return { };
  },
  computed: {
    mappedColor() {
      const map = {
        yellow: '#d9ad4a',
        red: 'red',
        green: 'green',
      };
      return map.hasOwnProperty(this.color) ? map[this.color] : this.color;
    },
  },
};
</script>

<style lang="scss">
.notification-bullet {
  margin-bottom: 2px !important;
	font-size: 13px !important;
  line-height: 18px !important;
  margin-right: 4px !important;
}
</style>
