<template>
  <v-btn
    fab
    small
    @click="$emit('click')"
    class="pas-fab"
    :disabled="disabled"
    :color="primaryColor"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'pas-fab',
  data() {
    return {};
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

  .pas-fab {
    // position: absolute !important;
    i {
      color: rgba(0,0,0,0.5) !important;
    }
    height: 50px !important;
    width: 50px !important;
    // &:hover, &:focus {
    //   position: absolute !important;
    // }
    // &:focus:before {
    //   background-color: #fff !important;
    // }
    &:hover:not(.close-btn) {
      i {
        color: var(--primary-color) !important;
      }
    }
    &:hover.close-btn {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
    &:focus:before {
      color: var(--primary-color) !important;
    }
  }
</style>
