<template>
  <v-tabs
    :value="value"
    :class="['pas-tabs', numberOfTabs === 1 ? 'single-tab' : '']"
    :grow="numberOfTabs === 1"
    :slider-color="primaryColor"
  >
    <v-tab
      v-for="tabTitle in tabTitles"
      :key="tabTitle"
    >
      <pas-page-subtitle class="pas-tab-title">
        <slot :name="tabTitle" />
      </pas-page-subtitle>
    </v-tab>
    <v-tabs-items touchless>
      <v-tab-item
        v-for="tabItem in tabItems"
        :key="tabItem"
      >
        <slot :name="tabItem" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'pas-tabs',
  props: {
    value: {
      default: null,
      type: Number,
    },
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    tabTitles() {
      return Object.keys(_.pickBy(this.$slots, (value, key) => _.startsWith(key, 'tabTitle')));
    },
    tabItems() {
      return Object.keys(_.pickBy(this.$slots, (value, key) => _.startsWith(key, 'tabItem')));
    },
    numberOfTabs() {
      return this.tabTitles.length;
    },
  },
};
</script>

<style lang="scss">
  .pas-tabs {
    background: white;
    margin: 0 auto;
  }
  .single-tab {
    .v-tabs__item.v-tabs__item--active {
      justify-content: start !important;
    }
    .pas-tab-title {
      color: rgba(0,0,0,.87);
      margin-left: 20px;
    }
  }
</style>
