<template>
  <IpsGroupsTable
    @view-ips-group="goToIpsGroupDetailPage($event)"
    @add-member="openModal($event)"
  />
</template>

<script>
export default {
  name: 'IpsGroupsTab',
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    goToIpsGroupDetailPage(ipsGroupGuid) {
      this.$router.push({ name: 'IpsGroupPage', params: { ipsGroupGuid } });
    },
    openModal(ipsGroupGuid) {
      console.log('openModal', ipsGroupGuid);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
