<template>
  <v-dialog v-model="isOpen" persistent max-width="650px">
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <pas-page-title class="mb-4">Confirm Account Number</pas-page-title>
      <v-form ref="accounts" lazy-validation @submit.prevent>
      <v-layout wrap class="px-2">
          <v-flex xs2>
            <div class="strong-text mb-3">Account ID</div>
          </v-flex>
          <v-flex xs3>
            <div class="strong-text mb-3 mr-2">Account Type</div>
          </v-flex>
          <v-flex xs3>
            <div class="strong-text mb-3 mr-2">Account Nickname</div>
          </v-flex>
          <v-flex xs3>
            <div class="strong-text mb-3">Dataphile Number</div>
          </v-flex>
          <template>
            <v-layout
              v-for="(account, i) in tempInvestmentAccounts"
              :key="i"
              xs12 md4
              px-2 py-1 mb-3row wrap
            >
            <v-flex xs2>
              <div class="mb-3">{{account.id}}</div>
            </v-flex>
            <v-flex xs3>
              <div class="mb-3 mr-2">{{ACCOUNT_TYPE_LONG[account.account_type_long]}}</div>
            </v-flex>
            <v-flex xs3>
              <div class="mb-3 mr-2">{{account.nickname}}</div>
            </v-flex>
            <v-flex xs3>
              <pas-input-field class="mb-3"
                v-model="account.account_no_dataphile"
                :rules="[v.isValidDataphileNum]"
                :readonly="editable(account.id)"
              ></pas-input-field>
            </v-flex>
            </v-layout>
          </template>
        </v-layout>
      <div class="error--text mt-2 ml-3" v-if="errorText">
        <span class="v-messages__message">{{errorText}}</span>
      </div>
      <v-layout mt-4 align-center justify-end row fill-height>
        <v-flex shrink>
          <pas-button @click="close">Cancel</pas-button>
        </v-flex>
        <v-flex shrink>
          <PasButton
            class="wide-btn ml-2"
            :processing="processingAccount"
            @click="saveDataphileNum"
          >Save</PasButton>
        </v-flex>
      </v-layout>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import _ from 'lodash';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import { ACCOUNT_TYPE_LONG } from 'Services/constants';

export default {
  name: 'ConfirmAccountNumber',
  mixins: [modal],
  props: {
    isOpen: Boolean,
    workflowId: Number,
  },
  data() {
    return {
      tempInvestmentAccounts: [],
      errorText: '',
      v,
      ACCOUNT_TYPE_LONG,
      processingAccount: false,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    investmentAccounts() {
      return this.$store.state.clients.activeClient.investment_accounts;
    },
  },
  methods: {
    editable(accountID) {
      const curAccount = this.investmentAccounts.filter((a) => a.id === accountID)[0];
      return !!curAccount.account_no_dataphile;
    },
    snackBar(type) {
      let text = 'Oops... Something went wrong, please try saving again';
      if (type === 'success') text = 'Account was successfully updated';
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async saveDataphileNum() {
      if (!this.$refs.accounts.validate()) return null;
      const accountsToUpdate = [];
      this.investmentAccounts.forEach((a) => {
        if (!a.account_no_dataphile && this.tempInvestmentAccounts.filter((tempAcc) => tempAcc.id === a.id)[0].account_no_dataphile) {
          accountsToUpdate.push(this.tempInvestmentAccounts.filter((tempAcc) => tempAcc.id === a.id)[0]);
        }
      });
      if (accountsToUpdate.length) {
        this.processingAccount = true;
        try {
          await Promise.all(accountsToUpdate.map((a) => this.$store.dispatch('updateAccount', a)));
          await this.$store.dispatch('getInvestmentAccounts', this.activeClient.client.id);
          this.snackBar('success');
          this.$emit('close');
        } catch (err) {
          console.log('update dataphile number error', err);
          this.snackBar('fail');
        }
        this.processingAccount = false;
      } else {
        return null;
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.tempInvestmentAccounts = _.cloneDeep(this.investmentAccounts).filter((a) => a.primary_client_id === this.activeClient.client.id);
      }
    },
  },
};
</script>

<style>
</style>
