<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span
      v-if="step.status==='needs_attention'"
      @click.prevent="completeStep"
      class="action-text"
    >Resend email</span>
  </WorkflowStep>
</template>

<script>
import { resendClientConfirmationTool } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'PortfolioAssignmentStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async completeStep() {
      try {
        const res = await resendClientConfirmationTool(
          this.workflow,
          'client',
        );
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>
</style>
