<template>
  <v-dialog :width="width" v-model="isOpen" persistent>
    <v-card class="confirm-action-card pa-5">
      <PasFab class="close-btn">
        <v-icon @click="$emit('close')" class="close-icon">close</v-icon>
      </PasFab>
      <PasPageTitle>
        <slot name="header"></slot>
      </PasPageTitle>
      <div class="my-4">
        <slot></slot>
      </div>
      <v-layout justify-start>
        <PasButton
          :processing="disableAction"
          class="primary-btn"
          @click="$emit('ok')"
        >
          <slot name="btn"></slot>
        </PasButton>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmActionDialog',
  props: {
    width: {
      type: String,
      default: '500px',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
