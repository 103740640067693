<template>
  <div class="px-5">
 <v-tabs  fluid class="pas-tabs justify-left">
    <v-tab
    :key="`tab-1`"
    >
    <h2 class="section-title pas-tabs-title">Discuss Workflow</h2>
    </v-tab>
    <v-tab
    :key="`tab-2`"
    >
    <h2 class="section-title pas-tabs-title">Activity Feed</h2>
    </v-tab>
    <v-tab-item
    :key="`tab-item-1`"
    >
        <workflow-view-discussion
          :workflow="workflow"
        >
        </workflow-view-discussion>
    </v-tab-item>
    <v-tab-item
    :key="`tab-item-2`"
    >
      <workflow-view-feed
        :workflow="workflow"
      >
      </workflow-view-feed>
    </v-tab-item>
  </v-tabs>
  </div>
</template>

<script>
import WorkflowViewDiscussion from './WorkflowViewDiscussionFeed/WorkflowViewDiscussion'
import WorkflowViewFeed from './WorkflowViewDiscussionFeed/WorkflowViewFeed'

export default {
  name: 'workflow-view-discussion-feed',
  components: {
    WorkflowViewDiscussion,
    WorkflowViewFeed
  },
  data() {
    return {}
  },
  props: {
    workflow: {
    type: Object,
    default: function() {
      return {}
    } 
    }
  }
}
</script>

<style>
  .justify-left .v-tabs__container {
    justify-content: left !important;
  }
</style>
