var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"mt-4":"","row":"","nowrap":""}},[_c('v-flex',{class:{
      'selection-div': true,
      'selection-selected': _vm.selected
    },attrs:{"xs6":"","mr-3":"","pl-4":""},on:{"click":function($event){return _vm.$emit('select', true)}}},[_c('v-layout',{attrs:{"row":"","align-center":"","fill-height":""}},[_c('div',{staticClass:"investment-type-circle-div mr-3"},[(_vm.selected)?_c('v-icon',[_vm._v("check")]):_vm._e()],1),_vm._v(" "),_c('div',[_c('span',[_vm._t('1')],2)])])],1),_vm._v(" "),_c('v-flex',{class:{
      'selection-div': true,
      'selection-selected': !_vm.selected
    },attrs:{"xs6":"","pl-4":""},on:{"click":function($event){return _vm.$emit('select', false)}}},[_c('v-layout',{attrs:{"row":"","align-center":"","fill-height":""}},[_c('div',{staticClass:"investment-type-circle-div mr-3"},[(!_vm.selected)?_c('v-icon',[_vm._v("check")]):_vm._e()],1),_vm._v(" "),_c('div',[_c('span',[_vm._t('2')],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }