<template>
	<v-menu
		offset-y
		left
		boo = rlrmyd
		:close-on-content-click="false"
		class="notification"
	>
		<v-btn slot="activator" light flat :style="{marginRight: '-20px !important'}">
			<v-badge overlap>
				<span
					v-if="visibleNotifications.length > 0"
					slot="badge"
					class="badge-number"
				>{{ unreadNotifications.length }}
				</span>
				<v-icon medium>notifications</v-icon>
			</v-badge>
		</v-btn>
		<v-list subheader class="notification-list">
			<v-subheader v-if="notifications.length > 0">
				<v-layout class="notifications-list-header">
					<v-flex d-flex align-center justify-start>
						Notifications
					</v-flex>
					<v-flex d-flex align-center justify-end>
						<v-btn
							flat
							:class="[{'no-pointer': readAllProcessing || !visibleNotifications.length}, 'link-btn', 'pa-0']"
							@click="readAllNotifications"
						>
							<span class="link-btn-text">Mark all as Read</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-subheader>
			<v-list-tile
				v-if="visibleNotifications.length === 0"
				class="notification-list-item d-flex align-center justify-start pa-3"
			>
				<v-list-tile-title>
					No unread notifications.
				</v-list-tile-title>
			</v-list-tile>
			<v-list-tile
				v-for="(item, index) in visibleNotifications"
				:key="index"
				avatar
				:class="{'notification-list-item': true, 'pa-3': true, 'no-pointer': item.id === processingNotificationId}"
			>
				<v-list-tile-avatar class="notification-item-avatar cursor-pointer" @click="$router.push({path: `/clients/${item.client}`})">
					<pas-avatar :avatar="getAvatar('clients',item.client)"></pas-avatar>
				</v-list-tile-avatar>
				<v-list-tile-content class="notification-content pl-3">
					<v-list-tile-sub-title
						v-html="item.description"
						class="notification-description"
					></v-list-tile-sub-title>
					<v-list-tile-sub-title class="mt-1">
						<pas-status-bullet
							:color="'green'"
							:value="100"
							class="notification-bullet"
						></pas-status-bullet>
						<span class="notification-time">
							{{ calculateTimeDiff(item.date_sent) }} ago
						</span>
					</v-list-tile-sub-title>
				</v-list-tile-content>
				<v-list-tile-action>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-icon
								class="dismiss-icon"
								v-on="on"
								@click="onReadItem(item.id)"
							>mdi-circle-medium</v-icon>
						</template>
						<span>Mark as Read</span>
					</v-tooltip>
				</v-list-tile-action>
			</v-list-tile>
			<v-list-tile v-if="notifications.length > 0">
				<v-layout d-flex align-center justify-center>
					<v-btn
						flat
						class="link-btn pa-0"
						@click="viewAllNotifications"
					>
						<span class="link-btn-text">View All</span>
					</v-btn>
				</v-layout>
			</v-list-tile>
		</v-list>
	</v-menu>
</template>

<script>

import moment from 'moment'
import _ from 'lodash'
import { getAvatar } from 'Services/helpers/files'
import { mapGetters } from 'vuex';

const NOTIFICATION_POLLING_INTERVAL_MSEC = (60*1000)

export default {
	name: 'notification-list',
	data () {
		return {
			getAvatar,
			readAllProcessing: false,
			processingNotificationId: 0, //id
			notification_polling_timer: null
		}
	},
	async mounted(){
		try {
			this.$store.dispatch('pollAndUpdateNotifications')
			if (this.isNotificationPollingFeatureEnabled()) {
				this.notification_polling_timer = setInterval(() => {
					this.$store.dispatch('pollAndUpdateNotifications')
				}, NOTIFICATION_POLLING_INTERVAL_MSEC)
			}
		} catch (err) {
			console.log('err', err)
		}			
	},
	async unmounted(){
		clearInterval(this.notification_polling_timer)
		this.notification_polling_timer = null
	},
	computed:{
		...mapGetters([
      		'hasFeatureFlag'
    	]),		
		notifications(){
			return this.$store.state.users.notifications
		},
		reversedNotifications() {
		    return _.cloneDeep(this.notifications).reverse()
		},
		visibleNotifications() {
			return this.reversedNotifications.filter(x => !x.status.read_at).slice(0, 3)
		},
		unreadNotifications() {
			return this.notifications.filter(x => !x.status.read_at)
		},
		router(){
			return this.$router
		}
	},
	methods: {
		onReadItem(notificationId){
			this.processingNotificationId = notificationId
			let notification = this.notifications.find(x => x.id === notificationId)
			this.$store.dispatch('updateNotificationStatus', { notification, action: 'mark_as_read' })
			.then(res => {
				if (res.length && res.length > 0) {
					let updatedNotifications = this.notifications.map(x => x.id === notificationId ? {...x, status: { read_at: new Date() }} : {...x})
					this.$store.dispatch('setNotifications', updatedNotifications)
					this.processingNotificationId = 0
				}
			})
			.catch(err => {
				this.$store.dispatch('setSnackbar', 
						{text: `Error updating notification status`, type: 'error'})
					this.$store.dispatch('flipSnackbarSwitch')
				console.log('err', err)
			})
		},
		isNotificationPollingFeatureEnabled() {
      		return this.hasFeatureFlag('notification_polling')
    	},		
		readAllNotifications(){
			this.readAllProcessing = true
			this.$store.dispatch('updateUserNotificationStatus', { action: 'mark_as_read' })
			.then(res => {
				if (res.length) {
					let newNotifs = this.notifications.map(x => res.find(r => r.notification_details.id === x.id) ? {...x, status: {read_at: new Date()}} : {...x})
					this.$store.dispatch('setNotifications', newNotifs)
					this.readAllProcessing = false
				}
			})
			.catch(err => {
				this.$store.dispatch('setSnackbar', 
						{text: `Error updating notifications status`, type: 'error'})
					this.$store.dispatch('flipSnackbarSwitch')
				console.log('err', err)
			})    
		},	
		viewAllNotifications(){
			this.$router.push('/notifications')
		},   				
		calculateTimeDiff(createdTime){
			const today = moment(new Date).format('HH:mm:ss.sss')
			const diffDays = moment(new Date()).diff(moment(createdTime), 'days')
			const diffHours = moment(new Date()).diff(moment(createdTime), 'hours')
			const diffMins = moment(new Date()).diff(moment(createdTime), 'minutes')
			const diffSecs = moment(new Date()).diff(moment(createdTime), 'seconds')

			if (diffDays === 0) {
				if (diffHours === 0) {
					if (diffMins === 0) {
						if (diffSecs === 0) {
							return '0 seconds'
						} else {
							return `${diffSecs} second${((diffSecs===1) ? '' : 's')}`
						}
					} else {
						return `${diffMins} minute${((diffMins===1) ? '' : 's')}` 
					}
				} else {
					return `${diffHours} hour${((diffHours===1) ? '' : 's')}`
				}
			} else {
				return `${diffDays} day${((diffDays===1) ? '' : 's')}`
			}
		},

	}
}
</script>

<style lang="scss" scoped>
.v-menu__content {
	z-index: 999 !important;
}
.notification-list {
	width: 505px;
	max-height: 365px !important;
	overflow-y: auto;
	overflow-x: hidden !important;
	padding: 0;
}
.notification-list > .v-subheader {
	padding-right: 0px;
}
.notification-list-item {
	width: 505px !important;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	
}
.notification-list-item:nth-of-type(2) {
	border-top: 1px solid rgba(0,0,0,0.1);
}
.notification-description {
	margin-bottom: 3px !important;
}
.notification-time {
	font-size: 12px !important;
}
.dismiss-icon {
	color: rgba(0,0,0,0.2);
	cursor: pointer;
}
.link-btn-text {
	color: var(--primary-color);
	&:hover {
		text-decoration: underline;
	}
}
</style>

<style lang="scss">
.notification {
	.v-badge {
		.v-icon {
			color: #757575 !important;
		}
		.v-badge__badge {
			width: 25px;
			height: 25px;
			background-color: #f97a75 !important;
			z-index: 1;
			box-shadow: 0 0 0 rgba(249,122,117, 0.4);
			animation: pulse 2s infinite;

			.badge-number {
				font-size: 0.75rem;
				color: white;
			}
		}	
	}
}

.notifications-list-header {
	padding: 0px 26px 0px 14px !important;
}

.v-menu__content .v-list {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	text-align: right !important;
}

.v-btn:hover > .v-btn__content .v-badge .v-badge__badge .badge-number {
	color: white !important;
}

.v-btn:focus > .v-btn__content .v-badge .v-badge__badge .badge-number {
	color: white !important;
}
.v-list__tile__sub-title {
	overflow: visible;
	white-space: normal;
	line-height: 1.2rem;
	height: auto;
}

.link-btn {
	flex: none !important;
	color: white !important;

	&:hover {
		color: white !important;
	}

	.v-btn__content {
		text-transform: capitalize !important;
		line-height: 1.25rem;
	}
}
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(249,122,117, 0.4);
	}
	70% {
			-webkit-box-shadow: 0 0 0 10px rgba(249,122,117, 0);
	}
	100% {
			-webkit-box-shadow: 0 0 0 0 rgba(249,122,117, 0);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(249,122,117, 0.4);
		box-shadow: 0 0 0 0 rgba(249,122,117, 0.4);
	}
	70% {
			-moz-box-shadow: 0 0 0 10px rgba(249,122,117, 0);
			box-shadow: 0 0 0 10px rgba(249,122,117, 0);
	}
	100% {
			-moz-box-shadow: 0 0 0 0 rgba(249,122,117, 0);
			box-shadow: 0 0 0 0 rgba(249,122,117, 0);
	}
}

</style>
