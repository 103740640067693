export const FATCA_RENEWAL_STATUS_OVERDUE = "overdue";
export const FATCA_RENEWAL_STATUS_AT_RISK = "at_risk";
export const FATCA_RENEWAL_STATUS_UPCOMING = "upcoming";
export const FATCA_RENEWAL_STATUS_COMPLETED = "completed";

export const FATCA_RENEWAL_MAP_STATUS_LABEL = {
  [FATCA_RENEWAL_STATUS_OVERDUE]: "overdue",
  [FATCA_RENEWAL_STATUS_AT_RISK]: "at risk",
  [FATCA_RENEWAL_STATUS_UPCOMING]: "upcoming",
  [FATCA_RENEWAL_STATUS_COMPLETED]: "completed",
};

export const FATCA_RENEWAL_MAP_STATUS_VARIANT = {
  [FATCA_RENEWAL_STATUS_OVERDUE]: "danger-light",
  [FATCA_RENEWAL_STATUS_AT_RISK]: "warning-light",
  [FATCA_RENEWAL_STATUS_UPCOMING]: "primary-light",
  [FATCA_RENEWAL_STATUS_COMPLETED]: "success-light",
};

export const STATUS_ITEMS = [
  {
    label: "All",
  },
  {
    key: "overdue",
    label: "Overdue",
  },
  {
    key: "at_risk",
    label: "At Risk",
  },
  {
    key: "upcoming",
    label: "Upcoming",
  },
  {
    key: "completed",
    label: "Completed",
  },
];
