<template>
  <v-dialog
    :value="isOpen"
    persistent
    fullscreen
  >
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <v-container class="modal-container">
        <template v-if="loading">
          <pas-circular-loader />
        </template>
        <template v-else>
          <v-layout justify-center child-flex mb-3>
            <v-flex text-xs-center>
              <h1>Add a New {{!isCorporate ? 'Contact' : 'Legal Entity' }}</h1>
            </v-flex>
          </v-layout>
          <v-layout mb-5 justify-center>
            <v-flex xs12 row text-xs-center>
              <span class="p-text">To add a new {{!isCorporate ? 'client' : 'legal entity'}}, enter all the information in the proper fields. You may also</span>
              <span @click="changeClientType()" class="action-text">
                add a new {{!isCorporate ? 'legal entity' : 'client'}}
              </span>
            </v-flex>
          </v-layout>
          <v-form
            lazy-validation
            ref="createClientForm"
            @submit.prevent=""
          >
            <v-layout row mb-3>
              <v-flex xs12 v-show="!isCorporate">
                <div class="strong-text form-label">Contact's status<sup>*</sup></div>
                <v-radio-group
                  v-model="clientStatus"
                  @change="resetProgram()"
                  :rules="[v.isRequired()]"
                  data-testid="add-new-client-radio-contact-status"
                >
                  <v-layout align-start child-flex row>
                    <v-radio
                      v-for="(item, i) in clientStatuses"
                      :key="i"
                      color="#c48f3e"
                      :label="item"
                      :value="item"
                      class="mr-4"
                    />
                  </v-layout>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 v-show="isCorporate">
                <div class="strong-text form-label">Legal Entity Type<sup>*</sup></div>
                <v-radio-group
                  v-model="legalEntity"
                  @change="resetProgram()"
                  :rules="[v.isRequired()]"
                  data-testid="add-new-client-radio-legal-entity-type"
                >
                  <v-layout align-start child-flex row>
                    <v-radio
                      v-for="(item, i) in legalEntities"
                      :key="i"
                      color="#c48f3e"
                      :label="item"
                      :value="item"
                      class="mr-4"
                    />
                  </v-layout>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 v-if="canSelectProgram()">
                <div class="strong-text form-label">Please select the appropriate investment program for this client.<sup>*</sup></div>
                <v-radio-group
                  v-model="selectedProgram"
                  @change="resetTeam()"
                  :rules="[v.isRequired('Please select an investment program')]"
                  data-testid="add-new-client-input-program"
                >
                  <v-layout row>
                    <div
                      v-for="(item, i) in programsWithMyRepCodes"
                      :key="i"
                      :style="{ display: 'inline-flex' }"
                    >
                      <v-radio
                        color="#c48f3e"
                        :value="item"
                        :label="item.text"
                        :style="{ margin: '5px' }"
                      />
                      <div>
                        <v-tooltip bottom color="white" max-width="40%">
                          <v-icon
                            slot="activator"
                            size="15px"
                            :style="{ 'margin-right': '10px' }"
                            color="grey"
                          >mdi-alert-circle</v-icon>
                          <span :style="{ color: 'black' }">
                            {{item.description}}
                          </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-layout>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout v-if="!isCorporate" row mb-5 mt-4 class="form-row">
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">First Name<sup>*</sup></div>
                  <pas-input-field
                    v-model="firstName"
                    :placeholder="`First Name`"
                    :rules="[v.isRequired(), v.isValidName()]"
                    id="first-name-input"
                    data-testid="add-new-client-input-first-name"
                  />
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">Middle Name</div>
                  <pas-input-field
                    v-model="middleName"
                    :placeholder="`Middle Name`"
                    :rules="[v.isValidName()]"
                    id="middle-name-input"
                    data-testid="add-new-client-input-middle-name"
                  />
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">Last Name<sup>*</sup></div>
                  <pas-input-field
                    v-model="surname"
                    :placeholder="`Last Name`"
                    :rules="[v.isRequired(), v.isValidName()]"
                    data-testid="add-new-client-input-last-name"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout v-if="isCorporate" row wrap mb-5 class="form-row">
              <v-flex xs5>
                <v-layout column>
                  <div class="strong-text form-label">Name of Corporation or Legal Entity*</div>
                  <pas-input-field
                    v-model="corporationName"
                    placeholder="Legal Name"
                    :rules="[v.isRequired()]"
                    data-testid="add-new-client-input-corporation-name"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="form-row email-row" mb-5>
              <v-flex xs5>
                <v-layout column>
                  <div class="strong-text form-label">Email<sup v-if="isProspect || isCorporate">*</sup></div>
                  <pas-input-field
                    v-model="email"
                    :placeholder="`Email`"
                    :rules="(isProspect || isCorporate) ? [v.isRequired(), v.isValidEmail] : [v.emptyOrEmail]"
                    data-testid="add-new-client-input-email"
                  />
                </v-layout>
              </v-flex>
              <v-flex xs5>
                <v-layout column>
                  <div class="strong-text form-label">{{isCorporate ? '' : 'Mobile'}} Phone Number<sup v-if="isProspect">*</sup></div>
                  <pas-phone-input
                    v-model="phoneNumber"
                    :placeholder="isCorporate ? 'Phone' : 'Mobile Number'"
                    :rules="isProspect ? [v.isRequired(), v.isValidPhone] : [v.emptyOrPhone]"
                    data-testid="add-new-client-input-phone-number"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row class="form-row" mb-5>
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">Advisor Team<sup>*</sup>
                    <v-tooltip top color="white" max-width="20%">
                      <v-icon
                        slot="activator"
                        size="15px"
                        :style="{ 'margin-right': '10px' }"
                        color="grey"
                      >mdi-alert-circle</v-icon>
                      <span :style="{ color: 'black' }">
                        If you are part of a multi-advisor team, please select the appropriate team that will be servicing this client going forward.
                        If you are not, this does not apply to you.
                      </span>
                    </v-tooltip>
                  </div>
                  <pas-input-field-list
                    data-testid="add-new-client-input-team"
                    v-model="selectedAdvisorTeam"
                    :rules="[v.isRequired()]"
                    :items="teamsInSelectedProgram"
                    placeholder="Select Advisor Team"
                  />
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">
                    Designated Advisor<sup>*</sup>
                  </div>
                  <pas-input-field-list
                    data-testid="add-new-client-input-advisor"
                    v-model="selectedAdvisor"
                    :rules="[v.isRequired()]"
                    :items="advisorOptions"
                    placeholder="Select Advisor"
                    :noDataText="loadingAdvisorText"

                  />
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">
                    Designated PM Team<sup>*</sup>
                  </div>
                  <pas-input-field-list
                    data-testid="add-new-client-input-pm-team"
                    v-model="selectedPMTeam"
                    :rules="[v.isRequired()]"
                    :items="pmTeamsOptions"
                    placeholder="Select PM Team"
                    noDataText="No PM Team available"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout v-if="pmAssignmentEnabled" row class="form-row" mb-5>
              <v-flex xs4>
                <v-layout column>
                  <div class="strong-text form-label">
                    Designated Portfolio Manager<sup>*</sup>
                  </div>
                  <pas-input-field-list
                    data-testid="add-new-client-input-pm"
                    v-model="selectedPM"
                    :rules="[v.isRequired()]"
                    :items="pmOptions"
                    placeholder="Select Portfolio Manager"
                    noDataText="No Portfolio Manager available"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </template>
        <v-layout mt-1>
          <v-flex text-xs-left>
            <pas-button
              @click="close"
              class=" inverted-btn px-5"
              data-testid="add-new-client-btn-cancel"
            >Cancel</pas-button>
          </v-flex>
          <v-spacer />
          <v-flex text-xs-right>
            <pas-button
              @click="createClient"
              class="primary-btn px-5"
              :processing="saving"
              data-testid="add-new-client-btn-add-client"
            >Add Client
            </pas-button>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>
/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import {
  createPerson, createCorporation, createFoundation, createTrust, createIPP, createNonProfit,
} from 'Services/api/Clients';
import { fetchTeamMembers } from 'Services/api/ClientDataHierarchy';
import { mapGetters } from 'vuex';
import { CLIENTSTATUS, LEGAL_ENTITIES } from 'Services/constants';
import _ from 'lodash';

export default {
  name: 'AddNewClientNoCall',
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v,
      isCorporate: false,
      firstName: null,
      middleName: null,
      surname: null,
      corporationName: null,
      email: null,
      phoneNumber: null,
      clientStatus: 'Prospect',
      clientStatuses: Object.values(CLIENTSTATUS).filter((status) => !['Active', 'Inactive'].includes(status)),
      legalEntity: 'Corporation',
      legalEntities: LEGAL_ENTITIES,
      loading: false,
      loadingAdvisorText: 'No Advisor Available',
      loadingPmText: 'No Portfolio Manager Available',
      selectedProgram: null,
      selectedAdvisorTeam: null,
      selectedAdvisor: null,
      selectedPMTeam: null,
      selectedPM: null,
      advisorOptions: [],
      pmOptions: [],
      saving: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'currentUser',
      'advisorTeams',
      'allPmTeams',
      'currentUserRepCodes',
      'allRepCodeDetails',
      'allPrograms',
      'firm',
    ]),
    isProspect() {
      return !this.isCorporate && this.clientStatus === 'Prospect';
    },
    myRepCodeDetails() {
      return this.allRepCodeDetails.filter((detail) => this.currentUserRepCodes.includes(detail.rep_code));
    },
    repCodeDetailsWithPrograms() {
      return this.myRepCodeDetails.filter((detail) => detail.program_id);
    },
    repCodesWithPrograms() {
      return this.repCodeDetailsWithPrograms.map((detail) => detail.rep_code);
    },
    programsWithMyRepCodes() {
      return this.allPrograms
        .filter((program) => (this.myRepCodeDetails
          .map((detail) => detail.program_id)).includes(program.value));
    },
    teamsWithProgram() {
      return this.advisorTeams
        .filter((team) => team.rep_code_access
          .some((repCode) => this.repCodesWithPrograms.includes(repCode)));
    },
    teamsInSelectedProgram() {
      if (this.selectedProgram) {
        return this.teamsWithProgram
          .filter((team) => team.rep_code_access
            .some((repCode) => this.getRepCodesByProgram(this.selectedProgram.value).includes(repCode)));
      }
      return this.advisorTeams;
    },
    advisorTeamsRepCodes() {
      return this.advisorTeams
        .find((team) => team.value === this.selectedAdvisorTeam)?.rep_code_access || [];
    },
    accessibleRepCodes() {
      // filter myRepCodeDetails based on selected Program and selected Advisor team
      let baseRepCodes = this.myRepCodeDetails;
      if (this.selectedProgram) {
        baseRepCodes = this.repCodeDetailsWithPrograms.filter((repCode) => repCode.program_id === this.selectedProgram.value);
      }
      if (this.selectedAdvisorTeam) {
        return baseRepCodes.filter((repCode) => this.advisorTeamsRepCodes.includes(repCode.rep_code));
      }
      return baseRepCodes;
    },
    pmTeamsOptions() {
      if (!this.selectedAdvisorTeam) { return []; }
      return this.allPmTeams
        .filter((team) => this.accessibleRepCodes.some((repCode) => repCode.pm_team_id === team.id))
        .map((team) => ({
          text: team.name,
          value: team.id,
        }));
    },
    pmAssignmentEnabled() {
      return this.hasFeatureFlag('pm_assignment');
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchAllPmTeams');
  },
  watch: {
    isOpen() {
      this.resetProgram();
    },
    selectedAdvisorTeam: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          // clear dependent fields if advisor team has changed
          this.selectedAdvisor = null;
          this.selectedPMTeam = null;
          await this.getAdvisorsInSelectedTeam();
        } else {
          this.advisorOptions = [];
        }
      },
    },
    selectedPMTeam: {
      immediate: true,
      async handler(newValue) {
        if (newValue && this.pmAssignmentEnabled) {
          this.selectedPM = null;
          await this.getPmInSelectedTeam();
        } else {
          this.pmOptions = [];
        }
      },
    },
  },
  methods: {
    async getAdvisorsInSelectedTeam() {
      const advisorRoles = ['referral_advisor', 'investment_advisor'];
      this.loadingAdvisorText = 'Loading Advisors...';
      this.advisorOptions = []; // reset options
      if (this.selectedAdvisorTeam) {
        const { team_members: teamMembers } = await fetchTeamMembers(({ context: 'team', team: this.selectedAdvisorTeam }));
        this.advisorOptions = teamMembers
          .filter((member) => member.roles.some((r) => advisorRoles.includes(r.role_name)))
          .map((member) => (
            {
              text: `${member.first_name} ${member.last_name}`,
              value: member.id,
            }
          ));
      } else {
        this.advisorOptions = [];
      }
      this.loadingAdvisorText = 'No Advisor Available';
    },
    async getPmInSelectedTeam() {
      const pmRoles = ['portfolio_manager'];
      this.loadingPmText = 'Loading Advisors...';
      this.pmOptions = [];
      if (this.selectedPMTeam) {
        const { team_members: teamMembers } = await fetchTeamMembers(({ context: 'team', team: this.selectedPMTeam }));
        this.pmOptions = teamMembers
          .filter((member) => member.roles.some((r) => pmRoles.includes(r.role_name)))
          .map((member) => (
            {
              text: `${member.first_name} ${member.last_name}`,
              value: member.id,
            }
          ));
      } else {
        this.pmOptions = [];
      }
      this.loadingPmText = 'No Portfolio Manager Available';
    },
    changeClientType() {
      this.isCorporate = !this.isCorporate;
      this.resetProgram();
    },
    canSelectProgram() {
      return this.hasFeatureFlag('no_call') && !!this.teamsWithProgram && !this.hasFeatureFlag('sub_advisor_consent');
    },
    getRepCodesByProgram(program) {
      return this.myRepCodeDetails
        .filter((detail) => detail.program_id === program)
        .map((detail) => detail.rep_code);
    },
    snackBar(text, type, id = null) {
      this.saving = false;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
      if (type === 'success') {
        this.close();
        if (this.redirect) this.$router.push({ path: `clients/${id}` });
      }
    },
    resetProgram() {
      this.selectedProgram = null;
      this.resetTeam();
    },
    resetTeam() {
      this.selectedAdvisorTeam = null;
    },
    autoAssignRepCode() {
      let selectedRepCode;
      if (this.selectedPMTeam) {
        const repCodes = this.accessibleRepCodes.filter((repCode) => repCode.pm_team_id === this.selectedPMTeam);
        if (this.selectedProgram) {
          selectedRepCode = repCodes.find((repCode) => repCode.program_id === this.selectedProgram.value).rep_code;
        } else {
          selectedRepCode = repCodes[0].rep_code;
        }
      }
      return selectedRepCode;
    },
    createClient() {
      if (!this.$refs.createClientForm.validate()) return false;
      this.saving = true;
      const selectedRepCode = this.autoAssignRepCode();
      const data = {
        firm_id: this.firm,
        team: this.selectedAdvisorTeam,
        rep_code: selectedRepCode,
        reporting_fx: 'CAD',
        advisor_id: this.selectedAdvisor,
        pm_id: this.selectedPM,
      };
      if (!this.isCorporate) {
        return this.savePersonRecord(data).then((res) => {
          if (!res.id) throw Error(`Error saving ${this.firstName} ${this.surname}`);
          this.snackBar(`New client ${this.firstName} ${this.surname} has been successfully created`, 'success', res.id);
          this.$store.dispatch('fetchClientsWithPermissions');
        }).catch((err) => this.snackBar(err, 'error'));
      }
      return this.saveLegalEntity(data).then((res) => {
        if (!res.id) throw Error(`Error saving ${this.corporationName}`);
        this.snackBar(`New ${this.legalEntity} ${this.corporationName} has been successfully created`, 'success', res.id);
        this.$store.dispatch('fetchClientsWithPermissions');
      }).catch((err) => this.snackBar(err, 'error'));
    },
    saveLegalEntity(data) {
      const recordTypeToClientType = {
        Corporation: 'corporation',
        Foundation: 'foundation',
        Trust: 'trust',
        IPP: 'ipp',
        'Non-Profit': 'nonprofit',
      };
      data.client_type = recordTypeToClientType[this.legalEntity];
      data.client_status = 'prospect';
      data.display_name = this.corporationName;
      data.office_number = this.phoneNumber || null;
      data.email = this.email;
      data.preferred_number = 'O';
      data.name = data.display_name;
      if (this.legalEntity === 'Corporation') return createCorporation(data);
      if (this.legalEntity === 'Foundation') return createFoundation(data);
      if (this.legalEntity === 'IPP') return createIPP(data);
      if (this.legalEntity === 'Non-Profit') return createNonProfit(data);
      return createTrust(data);
    },
    savePersonRecord(data) {
      data.client_type = 'person';
      data.client_status = _.invert(CLIENTSTATUS)[this.clientStatus] || '';
      data.first_name = this.firstName;
      data.middle_name = this.middleName?.trim() || null;
      data.last_name = this.surname;
      data.surname = data.last_name;
      data.display_name = `${data.first_name} ${data.last_name}`;
      data.preferred_number = 'M';
      data.mobile_number = this.phoneNumber;
      data.email = this.email;
      return createPerson(data);
    },
  },
};
</script>

<style>
  .form-row > .flex {
    margin-right: 60px;
  }
  .form-row > .flex:last-child {
    margin-right: 0px !important;
  }
  .top-right-absolute {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .email-row {
    margin-bottom: 28px !important;
  }
  .rep-code-dropdown {
    width: 200px !important;
  }
</style>
