<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent
    >
    <PasPageSubtitle v-if="!noTitle" class="mb-3 px-2 client-edit-section">FATCA/QI/CRS</PasPageSubtitle>

      <div v-if="clientType === 'person' && isTaxAnsExisted">
      <div class="strong-text mb-1 px-2">Tax Treaty Benefits</div>

        <v-radio-group
          class="px-2 financial-profile-pref-lang"
          v-model="taxTreatyBenefits"
          :disabled="!editable"
          row
        >
          <v-radio :color="primaryColor" :label="`Yes`" :value="true"></v-radio>
          <v-radio :color="primaryColor" :label="`No`" :value="false"></v-radio>
        </v-radio-group>
        <PasButton
          class="primary-btn wide-btn px-2 my-2"
          @click="saveTaxAns"
          :processing="processingTaxAns"
          v-if="!modalMode && editable"
        >Save</PasButton>
      </div>
      <div v-else-if="clientType == 'person' && !isTaxAnsExisted">
        <div class="strong-text mb-1 px-2">Client has not completed the survey yet</div>
      </div>
      <div v-else>
        <div class="strong-text mb-1 px-2">Related information is not available</div>
      </div>
      </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import v from 'Services/validation';

export default {
  name: 'FatcaQiCrsModal',
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taxTreatyBenefits: null,
      claimSpecialRatesAndConditions: null,
      specialRatesAndConditionsArticle: '',
      specialRatesAndConditionsParagraph: '',
      withHoldingPercentageRate: '',
      incomeWithheldType: '',
      shareholderCommPref: 0,
      preferredLanguage: null,
      v,
      processingTaxAns: false,
      isInit: true,
      isEdited: false,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    clientType() {
      return this.activeClient.client && this.activeClient.client.client_type;
    },
    disabled() {
      return !this.modalMode && this.clientType === 'person';
    },
    isTaxAnsExisted() {
      return !_.isEmpty(this.activeClient.taxAns);
    },
  },
  methods: {
    handleResponse(type) {
      const text = `${type === 'fail' ? 'Failed to update' : 'Successfully updated'} client tax information`;
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type);
    },
    async saveTaxAns() {
      if (!this.$refs.form.validate()) return false;
      this.processingTaxAns = true;
      const data = {
        claim_tax_treaty_benefits: this.taxTreatyBenefits,
        claim_special_rates_and_conditions: this.claimSpecialRatesAndConditions,
        special_rates_and_conditions_article: this.specialRatesAndConditionsArticle,
        special_rates_and_conditions_paragraph: this.specialRatesAndConditionsParagraph,
        withholding_percentage_rate: this.withHoldingPercentageRate,
        income_withheld_type: this.incomeWithheldType,
        client: this.activeClient.client.id,
      };
      if (!this.claimSpecialRatesAndConditions) {
        data.special_rates_and_conditions_article = null;
        data.special_rates_and_conditions_paragraph = null;
        data.withholding_percentage_rate = null;
        data.income_withheld_type = null;
      }
      await this.$store.dispatch('updateClientTaxAns', data)
        .then(() => this.handleResponse('success'))
        .catch(() => this.handleResponse('fail'));
      this.processingTaxAns = false;
    },
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        let client = {};
        if (!this.isEdited) {
          client = { ...this.activeClient };
          this.taxTreatyBenefits = client.taxAns.claim_tax_treaty_benefits;
          this.claimSpecialRatesAndConditions = client.taxAns.claim_special_rates_and_conditions;
          this.specialRatesAndConditionsArticle = client.taxAns.special_rates_and_conditions_article;
          this.specialRatesAndConditionsParagraph = client.taxAns.special_rates_and_conditions_paragraph;
          this.withHoldingPercentageRate = client.taxAns.withholding_percentage_rate;
          this.incomeWithheldType = client.taxAns.income_withheld_type;
        }
      },
      deep: true,
    },
    shareholderPrefChange: {
      handler(val, oldVal) {
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
    },
  },
  mounted() {
    this.isInit = true;
    this.isEdited = false;
    const client = { ...this.activeClient };
    if (client.client) {
      this.taxTreatyBenefits = client.taxAns.claim_tax_treaty_benefits;
      this.claimSpecialRatesAndConditions = client.taxAns.claim_special_rates_and_conditions;
      this.specialRatesAndConditionsArticle = client.taxAns.special_rates_and_conditions_article;
      this.specialRatesAndConditionsParagraph = client.taxAns.special_rates_and_conditions_paragraph;
      this.withHoldingPercentageRate = client.taxAns.withholding_percentage_rate;
      this.incomeWithheldType = client.taxAns.income_withheld_type;
    }
  },
};
</script>

<style lang="scss">
.checkbox-left{
  width: 30px;
}
.v-input.checkbox-left.v-input--selection-controls.v-input--checkbox.v-input--is-disabled.theme--light {
  margin-top: 0px !important;
}
.fatca-input-field {
  max-width: 50%
}
</style>
