<template>
  <div class="fee-schedule">
    <v-layout class="overflow-x-scroll">
      <v-data-table
        :headers="headers"
        :items="investmentAccounts"
        :items-per-page="10"
        hide-actions
      >
        <template v-slot:items="props">
          <td class="text-xs-left">
            <div>
              {{props.item.account_no_dataphile}}
            </div>
          </td>
          <td class="text-xs-left short_description">
            <div style='color:#cccccc'>{{props.item.account_type_short_description}}
            </div>
          </td>
          <td v-if="canViewAccountFeesConfig" class="text-xs-left">
            <v-select
              :items="props.item.debitAccounts"
              :value="props.item.account_fees_config.debit_account_id"
              @input="$emit('changeAccount', { accountId: props.item.id, data: $event, type: 'debit_account_id'})"
              :disabled="!canEditAccountFeesConfig"
            />
          </td>
          <td v-if="canViewAccountFeesConfig" class="text-xs-left">
            <PasDatepicker
              v-model="props.item.account_fees_config.start_date"
              @input="updateDate({ accountId: props.item.id, data: $event, type: 'start_date'})"
              iconLocation="before"
              :isClearable="false"
              :minDate="props.item.open_date"
              :readonly="!canEditAccountFeesConfig"
              :rules="[v.isValidDate]"
            />
          </td>
          <td v-if="canViewAccountFeesConfig" class="text-xs-left">
            <PasDatepicker
              v-model="props.item.account_fees_config.end_date"
              @input="updateDate({ accountId: props.item.id, data: $event, type: 'end_date'})"
              @clearDate="clearEndDate(props.item.id)"
              iconLocation="before"
              :minDate="getMinEndDate(props.item.account_fees_config.start_date)"
              :maxDate="getToday"
              :isClearable="true"
              :readonly="!canEditAccountFeesConfig"
              :rules="[v.isValidDate]"
          />
          </td>
          <td v-if="canViewPlanningSchedules" class="text-xs-left">
            <v-select
              :items="planningSchedules"
              :value="props.item.planning_schedule"
              @change="$emit('change', { accountId: props.item.id, scheduleId: $event, type: 'planning_schedule'})"
              :disabled="!canDeletePlanningSchedules"
            />
          </td>
          <td v-if="canViewManagementSchedules" class="text-xs-left">
            <v-select
              :items="managementSchedules"
              :value="props.item.management_schedule"
              @change="$emit('change', { accountId: props.item.id, scheduleId: $event, type: 'management_schedule'})"
              :disabled="!canDeleteManagementSchedules"
            />
          </td>
          <td v-if="canViewSubadvisorSchedules" class="text-xs-left">
            <v-select
              :items="subadvisorSchedules"
              :value="props.item.subadvisor_schedule"
              @change="$emit('change', { accountId: props.item.id, scheduleId: $event, type: 'subadvisor_schedule'})"
              :disabled="!canDeleteSubadvisorSchedules"
            />
          </td>
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>

import { ACCOUNT_TYPE_LONG } from 'Services/constants';
import { mapGetters } from 'vuex';
import moment from 'moment';
import v from 'Services/validation';


export default {
  name: 'FeeScheduleList',
  props: {
    debitAccounts: Array,
    planningSchedules: Array,
    managementSchedules: Array,
    subadvisorSchedules: Array,
    investmentAccounts: Array,
  },
  data() {
    return {
      accountType: ACCOUNT_TYPE_LONG,
      v,
    };
  },
  computed: {
    ...mapGetters([
      'canEditAccountFeesConfig',
      'canDeletePlanningSchedules',
      'canDeleteManagementSchedules',
      'canDeleteSubadvisorSchedules',
      'canViewAccountFeesConfig',
      'canViewPlanningSchedules',
      'canViewManagementSchedules',
      'canViewSubadvisorSchedules',
    ]),
    getToday() {
      return moment().format('YYYY-MM-DD');
    },
    headers() {
      const headers = [
        {
          text: 'Account',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Type',
          align: 'left',
          sortable: false,
        },
      ];
      if (this.canViewAccountFeesConfig) {
        headers.push({
          text: 'Debit Account',
          align: 'left',
          sortable: false,
        });
        headers.push({
          text: 'Start Date',
          align: 'left',
          sortable: false,
        });
        headers.push({
          text: 'End Date',
          align: 'left',
          sortable: false,
        });
      }
      if (this.canViewPlanningSchedules) {
        headers.push({
          text: 'Planning Schedule',
          align: 'left',
          sortable: false,
        });
      }
      if (this.canViewManagementSchedules) {
        headers.push({
          text: 'Management Schedule',
          align: 'left',
          sortable: false,
        });
      }
      if (this.canViewSubadvisorSchedules) {
        headers.push({
          text: 'Subadvisor Schedule',
          align: 'left',
          sortable: false,
        });
      }
      return headers;
    },
  },
  methods: {
    getMinEndDate(startDate) {
      const dateFirstDayCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
      if (dateFirstDayCurrentMonth > startDate) return dateFirstDayCurrentMonth;

      return startDate;
    },
    clearEndDate(accountId) {
      this.$emit('clearEndDate', { accountId, type: 'clear_end_date' });
    },
    updateDate(event) {
      if (event.data.length < 10) return;

      this.$emit('changeAccount', event);
    },
  },
};
</script>
<style lang="scss">
.overflow-x-scroll {
  overflow-x: scroll;
}

.fee-schedule {

  table.v-table tbody td {
    padding: 0px 18px !important;
  }

  .datepicker .v-input__slot {
    border: 0px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 0px 0px 0px !important;
    width: 95px;
  }

  .prepend-datepicker .v-text-field__slot {
    border-left: none;
    left: -30px !important;
    padding-left: 20px !important;
  }

  td.short_description{
    width:122px;
  }

  table.v-table tbody td {
    border-bottom: 1px solid white !important;
  }
}
</style>
