<template>
  <PCard body-class="p-4" @click="$emit('filter')" clickable>
    <PContainer fluid>
      <PRow class="align-items-center">
        <PColumn class="mb-lg-0 p-0" lg="12">
          <StatusBadge
            class="mb-2 text-uppercase font-weight-normal"
            :status="status"
            :statusLabelMap="statusLabelMap"
            :statusVariantMap="statusVariantMap"
          />
          <p class="mb-1 text-secondary title">
            <span class="bold text-primary client-count">{{ clientCount }}</span> {{ clientText }} {{ mainText }}
          </p>
          <p class="text-secondary mb-2">{{ detailsText }}</p>
          <PButton :size="'sm'" :isIconOnly="true" :appendIcon="'chevron-right'" :variant="'tertiary'" />
        </PColumn>
      </PRow>
    </PContainer>
  </PCard>
</template>

<script>
import StatusBadge from 'Components/Shared/StatusBadge';

export default {
  name: 'FilterCard',
  components: {
    StatusBadge,
  },
  props: {
    status: String,
    clientCount: Number,
    mainText: String,
    detailsText: String,
    statusLabelMap: Object,
    statusVariantMap: Object,
  },
  computed: {
    statusToLabel() {
      return this.statusLabelMap[this.status] || '';
    },
    clientText() {
      return this.clientCount === 1 ? 'Client has' : 'Clients have';
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 140%;
}

.client-count {
  font-size: 24px !important;
}
</style>
