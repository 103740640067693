<template>
	<v-layout align-center id="baseActions">
		<template v-if="!isReadOnly">
			<v-select
				:items="teamMembersParsed"
				label="Re-Assign Workflow To"
				v-model="userToReAssign"
			></v-select>
			<v-btn flat round :color="primaryColor" :disabled="!userToReAssign" @click="reassign">Re-Assign Workflow</v-btn>
			<v-btn flat round :color="primaryColor" @click="markComplete">Confirm Account</v-btn>
			<confirm-account-modal :dialog="dialog" :close="close" :done="done"></confirm-account-modal>
		</template>
	</v-layout>
</template>

<script>
import api from '../../../services/api.js'

import confirmAccountModal from './ConfirmAccountModal'

export default {
	name: 'confirm-account',
	props: {
		teamMembers: Array,
		workflow: Object,
		stage: Number,
		step: Number,
		readOnly: Boolean,
	},
	components: {
		confirmAccountModal,
	},
	data () {
		return {
			userToReAssign: null,
			dialog: false,
		}
	},
	computed:{
		primaryColor() {
			return this.$store.state.app.themeVars.primaryColor
		},
		currentUser(){ return this.$store.state.users.currentUser },
		teamMembersParsed(){
			return this.teamMembers.map(member => ({text: member.name, value: member}))
		},
		isReadOnly() {
			return this.readOnly
		}
	},
	methods: {
		reassign(){
			api.post('/advisor_portal/workflow/put', {
				workflow: this.workflow._id,
				action: 'assign_user',
				user: this.userToReAssign._id
			})
			this.$router.push('/')
		},
		close() {
			this.dialog = false
		},
		done(){
			this.dialog = false
			api.post('/advisor_portal/workflow/put', {
				workflow: this.workflow._id,
				action: 'complete_step',
				stage: this.stage,
				step: this.step
			})
			this.$emit('complete-step')
		},
		markComplete(){
      this.dialog = true
		},
	}
}
</script>

<style lang="scss">
</style>
