<template>
  <v-layout align-center row wrap>
    <v-flex
      v-for="(item,i) in filteredAddresses"
      :key="i"
      sm6 md3
    >
      <v-card class="ma-2 address-card">
        <PasFab v-if="!readonly" class="address-delete-btn">
          <v-icon small @click="$emit('deleteAddress', item.id)">delete</v-icon>
        </PasFab>
        <PasFab v-if="!readonly" class="address-edit-btn">
          <v-icon small @click="$emit('editAddress', item.id)">edit</v-icon>
        </PasFab>
        <div class="address-type pl-3">
          <pas-display-chip
            v-if="item.client_address_type"
            class="client-status-chip"
            :textColor="chipTextColor"
            :color="chipColor"
          >{{item.client_address_type}}
          </pas-display-chip>
        </div>
        <v-layout column>
          <v-flex>
            <div class="address-line pa-3">
              <li class="address-line-item">{{item.address_line_1}}</li>
              <li class="address-line-item" v-if="item.address_line_2">{{item.address_line_2}}</li>
              <li class="address-line-item">{{item.city}}, {{item.province ? `${item.province} ` : ''}}</li>
              <li class="address-line-item">{{item.postal_code}}, {{item.country}}</li>
            </div>
          </v-flex>
          <v-flex>
            <div class="strong-text pa-3">This is the primary:</div>
          </v-flex>
          <v-flex>
            <v-layout align-center row mb-2 class="primary-address">
              <v-flex xs4 px-3>
                <v-switch
                  v-model="item.is_mailing"
                  class="switch"
                  :color="primaryColor"
                  @change="$emit('updateAddressType', item.is_mailing, item.id, `is_mailing`)"
                  :disabled="readonly"
                ></v-switch>
              </v-flex>
              <v-flex>
                <v-card-title class="pl-0">Mailing address</v-card-title>
              </v-flex>
            </v-layout>
            <v-layout align-center row mb-2 class="primary-address">
              <v-flex xs4 px-3>
                <v-switch
                  v-model="item.is_residential"
                  class="switch"
                  :color="primaryColor"
                  @change="$emit('updateAddressType', item.is_residential, item.id, `is_residential`)"
                  :disabled="readonly"
                ></v-switch>
              </v-flex>
              <v-flex>
                <v-card-title class="pl-0">{{clientType === 'person' ? `Residential` : `Business`}}
                  address
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'address-list',
  props: {
    addresses: Array,
    clientType: String,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    filteredAddresses() {
      return this.addresses.filter((address) => !address.deleted);
    },
    ...mapState({
      chipColor: (state) => state.app.themeVars['primary-translucent-2'],
      chipTextColor: (state) => state.app.themeVars['primary-translucent-7'],
      primaryColor: (state) => state.app.themeVars.primaryColor,
    }),
  },
};
</script>

<style lang="scss">

  .primary-address {
    height: 35px !important;
  }

  .address-line {
    font-size: 16px;
    min-height: 120px;
  }

  .address-line-item {
    list-style-type: none;
    text-align: left;
  }

  .address-delete-btn {
    position: absolute !important;
    right: 0px;
    top: 0px;
  }

  .address-edit-btn {
    position: absolute !important;
    top: 0px;
    right: 45px;
  }

  .address-card {
    max-width: 240px !important;
    min-width: 230px !important;

    .pas-fab {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .address-type {
    height: 30px;
  }
</style>
