<template>
  <v-layout mt-4 row nowrap>
    <v-flex xs6 mr-3 pl-4
      :class="{
        'selection-div': true,
        'selection-selected': selected
      }"
      @click="$emit('select', true)"
    >
      <v-layout row align-center fill-height>
        <div class="investment-type-circle-div mr-3">
          <v-icon v-if="selected">check</v-icon>
        </div>
        <div><span><slot :name="'1'"/></span></div>
      </v-layout>
    </v-flex>
    <v-flex xs6 pl-4
      :class="{
        'selection-div': true,
        'selection-selected': !selected
      }"
      @click="$emit('select', false)"
    >
      <v-layout row align-center fill-height>
        <div class="investment-type-circle-div mr-3">
          <v-icon v-if="!selected">check</v-icon>
        </div>
        <div><span><slot :name="'2'"/></span></div>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'PasSections',
  props: {
    selected: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">

.selection-div {
	height: 70px !important;
	background-color: #eeeeee !important;
	border-radius: 6px !important;
	-webkit-border-radius: 6px !important;
	-moz-border-radius: 6px !important;
	cursor: pointer !important;
}
.investment-type-circle-div {
	border-radius: 50%;
  width: 20px;
	height: 20px;
	background-color: white !important;
}
.selection-selected {
	background-color: var(--primary-color) !important;
	color: white !important;
	i {
		position: relative;
    right: -2px;
    font-size: 16px;
    color: var(--primary-color) !important;
	}
}
</style>
