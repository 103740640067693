<template>
  <v-dialog 
      max-width="700px" 
      v-model="isOpen"
      persistent
    >
      <v-card class="confirm-action-card pa-4">
        <PasFab
        class="close-btn"
        >
          <v-icon
            @click="$emit('close')"
            class="close-icon"
          >
            close
          </v-icon>
        </PasFab>
        <v-card-title class="headline">Confirm Age for RRIF Account</v-card-title>
        <v-flex xs12 text-xs-left mt-3 pl-3>
          <div>
            You must be 55 years or older to open a RRIF account. Please enter your date of birth to confirm your age.
          </div>
          </v-flex>
        <v-card-text>
          <v-form
            @submit.prevent=""
            lazy-validation
            ref="form"
          >
            <v-layout row>
              <v-flex xs6 v-if="!(this.person.date_of_birth)">
                <v-layout column>
                  <v-flex class="form-label strong-text">Date of Birth</v-flex>
                  <v-flex>
                    <pas-datepicker
                      :rules="[v.isRequired(), v.isValidDate, v.isOverAge(55)]"
                      v-model="DOB"
                    >
                    </pas-datepicker>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-layout row justify-end mt-4>
          <pas-button class="primary-btn px-5" @click="save">Save</pas-button>
        </v-layout>
      </v-card>
    </v-dialog>
</template>

<script>
import v from 'Services/validation'
import { modal } from 'Services/mixins'


export default {
  name: 'confirm-client-age',
  mixins: [modal],
  data() {
    return {
      v,
      DOB: ''
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    person: Object
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) return false
      this.$store.dispatch('updatePersonClient', { 
        id: this.person.id,
        data: { date_of_birth: this.DOB }
      })
      .then(res => {
        if (res.status > 300) {
          this.$store.dispatch('setSnackbar', {text: "Error in update client'", type: 'fail', topic: ''})
          this.$store.dispatch('flipSnackbarSwitch')  
        } else {
          this.$store.dispatch('setSnackbar', {text: "Client updated successfully", type: 'success', topic: ''})
          this.$store.dispatch('flipSnackbarSwitch')  
          this.$store.dispatch('fetchRelationships', this.$store.state.clients.activeClient.client.id)
          this.$emit('close')
        }
      })
      .catch(err => {
        this.$store.dispatch('setSnackbar', {text: "Error in update client'", type: 'fail', topic: ''})
        this.$store.dispatch('flipSnackbarSwitch')  
        console.log('err', err)
      })
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.person) {
          this.DOB = this.person.date_of_birth
        }
      }
    },
    person(val) {
      this.DOB = val.date_of_birth
    }
  }
}
</script>

<style>
</style>