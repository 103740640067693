<template>
  <div>
    <pas-page-subtitle v-if="canEditRiskProfile && hasIps" class="px-2">{{ `Risk Profile`}}
        <pas-autocomplete
          :items="riskProfileOptions"
          v-model="riskProfile"
          class="max-width-50 mt-2 mb-3"
          :value="riskProfile"
        ></pas-autocomplete>
    </pas-page-subtitle>
    <pas-page-subtitle v-if="fieldsToDisplay.includes('all')" class="px-2">{{'Risk Scores'}}
      <div class="strong-text my-3 px-2">Overall</div>
      <pas-display-chip class="scores mx-2">{{parseFloat(overall).toFixed(1)}}</pas-display-chip>
      <v-layout>
        <v-flex v-for="(item, i) in riskScore" :key="i" shrink px-2>
          <div class="strong-text my-3">{{item.name}}</div>
          <pas-number-input
            @upClick="incrementScore(item)"
            @downClick="decrementScore(item)"
            step="1"
            min="0"
            v-model="item.value"
            class="editable-scores"
            v-if="canEditRiskProfile"
            >
          </pas-number-input>
        <pas-display-chip v-else class="scores">{{item.value}} </pas-display-chip>
        </v-flex>
      </v-layout>
    </pas-page-subtitle>

    <span class="error--text">{{validationText}}</span>
    <pas-button  v-if="fieldsToDisplay.includes('all') || hasIps"  :disabled="!isEdited" class="primary-btn mt-4 mx-2" @click="saveScores" :processing="processingScores">Save</pas-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  fetchFieldsToDisplay,
} from 'Services/api/PortfolioAssignment';

export default {
  name: 'risk-tolerance-tab',
  data() {
    return {
      riskScore: {
        tolerance: { name: 'Tolerance', value: '0.0' },
        capacity: { name: 'Capacity', value: '0.0' },
        perception: { name: 'Perception', value: '0.0' },
        knowledge: { name: 'Knowledge', value: '0.0' },
        requiredReturn: { name: 'Required Return', value: '0.0' },
      },
      overall: '0.0',
      riskProfile: 'Unknown',
      validationText: '',
      processingScores: false,
      isEdited: false,
      fieldsToDisplay: [],
    };
  },
  async created() {
    await this.$store.dispatch('fetchAllRiskProfiles');
  },
  computed: {
    ...mapGetters([
      'canViewRiskProfile',
      'canEditRiskProfile',
      'allRiskProfiles'
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    hasIps() {
      return this.$store.state.app.features.ips;
    },
    overallScore() { // formula to calculate overall score from risk score categories
      const unscaled = parseFloat(
        (
          Math.cbrt(
            Math.min((Number(this.riskScore.tolerance.value)+Number(this.riskScore.perception.value)), Number(this.riskScore.capacity.value))**2
            * Math.max((Number(this.riskScore.tolerance.value)+Number(this.riskScore.perception.value)), Number(this.riskScore.capacity.value))
          )
          - 1
        )
        * 40
        / 9
        + 10
      ).toFixed(2);
      const scaled = parseFloat(((unscaled - 10)*(9/40)+1)).toFixed(1); // scaling down from 10-50 scale to 1-10
      return scaled;
    },
    riskProfileOptions() {
      return this.allRiskProfiles.map((profile) => (profile.description));
    },
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        const client = { ...this.activeClient };
        if (client.riskScores) {
          this.riskProfile = client.riskScores.risk_profile || 'Unknown';
          this.riskScore.tolerance.value = parseFloat(client.riskScores.tolerance) || 0.0;
          this.riskScore.capacity.value = parseFloat(client.riskScores.capacity) || 0.0;
          this.riskScore.perception.value = parseFloat(client.riskScores.perception) || 0.0;
          this.riskScore.knowledge.value = parseFloat(client.riskScores.knowledge) || 0.0;
          this.riskScore.requiredReturn.value = parseFloat(client.riskScores.required_return) || 0.0;
          this.overall = parseFloat(client.riskScores.overall) || 0.0;
        }
        this.isEdited = false;
      },
      deep: true,
    },
    riskScore: {
      handler() {
        this.isEdited = true;
      },
      deep: true,
    },
    riskProfile: {
      handler() {
        this.isEdited = true;
      },
    },
  },
  async mounted() {
    const client = { ...this.activeClient };
    if (client.riskScores) {
      this.riskProfile = client.riskScores.risk_profile || 'Unknown';
      this.riskScore.tolerance.value = client.riskScores.tolerance || 0.0;
      this.riskScore.capacity.value = client.riskScores.capacity || 0.0;
      this.riskScore.perception.value = client.riskScores.perception || 0.0;
      this.riskScore.knowledge.value = client.riskScores.knowledge || 0.0;
      this.riskScore.requiredReturn.value = client.riskScores.required_return || 0.0;
      this.overall = client.riskScores.overall || 0.0;
    }
    this.$nextTick(() => {
      this.isEdited = false;
    });

    await this.fetchFieldsToDisplay();
  },
  methods: {
    handleResponses(type, message) {
      console.log(message);
      const text = `${type === 'fail' ? 'Failed to update' : 'Successfully updated'} Risk Scores`;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async saveScores() {
      const client = { ...this.activeClient };
      const data = {
        overall: parseFloat(this.overallScore).toFixed(1),
        tolerance: parseFloat(this.riskScore.tolerance.value).toFixed(1),
        capacity: parseFloat(this.riskScore.capacity.value).toFixed(1),
        perception: parseFloat(this.riskScore.perception.value).toFixed(1),
        required_return: parseFloat(this.riskScore.requiredReturn.value).toFixed(1),
        knowledge: parseFloat(this.riskScore.knowledge.value).toFixed(1),
        risk_profile: this.riskProfile,
      };
      if (this.activeClient.riskScores.id) {
        data.id = this.activeClient.riskScores.id;
      } else {
        data.client = this.activeClient.client.id;
      }
      const callType = this.activeClient.riskScores.id ? 'updateRiskScores' : 'createRiskScores';
      await this.$store.dispatch(callType, data)
        .then((res) => this.handleResponses(res.id ? 'success' : 'fail', res))
        .catch((err) => this.handleResponses('fail', err));
      this.processingScores = false;
    },
    incrementScore(event) {
      const newValue = (event.value * 10 + 1) / 10;
      event.value = newValue;
    },
    decrementScore(event) {
      const newValue = (event.value * 10 - 1) / 10;
      event.value = newValue;
    },
    async fetchFieldsToDisplay() {
      const fieldsToDisplay = await fetchFieldsToDisplay('risk_score');
      this.fieldsToDisplay = fieldsToDisplay.map((f) => f.display_field);
    },
  },
};
</script>

<style lang="scss">
.scores {
  height: 42px !important;
  padding-left: 34px !important;
  width: 110px !important;
}
.editable-scores {
  height: 42px !important;
  width: 110px !important;
}
</style>
