<template>
  <v-flex xs12>
    <div class="strong-text">
      {{ commenterName }}
      <span class="small-text">{{
        `(${moment(date).format("YYYY-MM-DD")})`
      }}</span>
    </div>
    <p class="text-wrap">{{ content }}</p>
  </v-flex>
</template>

<script>

export default {
  name: 'PasDiscussionComment',
  props: {
    commenterName: {
      type: String,
    },
    content: {
      type: String,
      default: '',
    },
    date: {
      type: String,
    },
  },
};
</script>
