<template>
  <div class="client-edit-wrapper">
    <template v-if="!clientLoaded">
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 600px;"
      >
        <PSpinner style="width: 3rem; height: 3rem;" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    clientID: {
      type: String,
      required: true,
    },
    clientLoaded: Boolean,
  },
  data() {
    return {
      showModal: true,
    };
  },
  watch: {
    clientLoaded: {
      handler(value) {
        if (value) {
          this.$store.commit('OPEN_MODAL', 'unlock-investor-account');
        }
      },
      immediate: true,
    },
  },
};
</script>
