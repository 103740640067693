import Autocomplete from './Autocomplete';
import ButtonToggle from './ButtonToggle';
import Checkbox from './Checkbox';
import Combobox from './Combobox';

import InputCurrency from './InputCurrency';
import Datepicker from './Datepicker';
import DateRangePicker from './DateRangePicker';
import DropDown from './DropDown';
import Dropzone from './Dropzone';
import EditableSpan from './EditableSpan';
import InputField from './InputField';
import InputFieldList from './InputFieldList';
import NumberInput from './NumberInput';
import PercentageInput from './PercentageInput';
import PhoneInput from './PhoneInput';
import SearchBox from './SearchBox';
import Switch from './Switch';
import Textarea from './Textarea';

export default {
  install(Vue) {
    Vue.component('PasAutocomplete', Autocomplete);
    Vue.component('PasButtonToggle', ButtonToggle);
    Vue.component('PasCheckbox', Checkbox);
    Vue.component('PasCombobox', Combobox);
    Vue.component('PasDatepicker', Datepicker);
    Vue.component('PasDateRangePicker', DateRangePicker);
    Vue.component('PasDropDown', DropDown);
    Vue.component('PasDropzone', Dropzone);
    Vue.component('PasEditableSpan', EditableSpan);
    Vue.component('PasInputCurrency', InputCurrency);
    Vue.component('PasInputField', InputField);
    Vue.component('PasInputFieldList', InputFieldList);
    Vue.component('PasNumberInput', NumberInput);
    Vue.component('PasPercentageInput', PercentageInput);
    Vue.component('PasPhoneInput', PhoneInput);
    Vue.component('PasSearchBox', SearchBox);
    Vue.component('PasSwitch', Switch);
    Vue.component('PasTextarea', Textarea);
  },
};
