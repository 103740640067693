<template>
    <v-layout px-0 row class="action-text" :disabled="disabled" @click="$emit('click')">
        <v-flex  class="add-item">
            <pas-icon-circle theme="pas-transparent">
                <v-icon>mdi-plus</v-icon>
            </pas-icon-circle>
        </v-flex>
        <v-flex ml-1 shrink>
            <span>{{text}}</span>
        </v-flex>
    </v-layout>
</template>

<script>

export default {
  name: 'pas-add-item',
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.add-item{
    max-width: 20px !important;
    .pas-icon-circle {
        margin: auto !important;
        margin-top: 2px !important;
        height: 16px !important;
        width: 16px !important;
        max-height: 16px !important;
        min-height: 16px !important;
        max-width: 16px !important;
        min-width: 16px !important;
    }
}
</style>
