<template>
  <PContainer fluid class="px-0">
    <PRow no-gutters>
      <PColumn class="household-profile-sidenav">
        <HouseholdSidebar
          :sections="sidebarSections"
          @onSideNavItemClick="onSideNavItemClick"
        />
      </PColumn>
      <PColumn class="household-profile-tabs">
        <HouseholdBio />
        <PTabs class="mt-5" :showBottomBorder="true" v-model="tabIndex">
          <PTab title="Members">
            <HouseholdMembersTab />
          </PTab>
          <PTab v-if="showIpsGroups" title="IPS">
            <IpsGroupsTab />
          </PTab>
        </PTabs>
      </PColumn>
    </PRow>
    <ModalWrapper />
  </PContainer>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex';
import HouseholdSidebar from '../HouseholdSidebar';
import HouseholdMembersTab from './HouseholdMembersTab';
import IpsGroupsTab from '../IPS/IpsGroupsTab';
import ModalWrapper from '../../../ModalWrapper';
import { createAndGoToIpsGroup } from '../../../../services/helpers/ipsGroup';

const ipsGroupsVuex = createNamespacedHelpers('ipsGroups');
const modalManagerVux = createNamespacedHelpers('modalManager');
const analytics = createNamespacedHelpers('analytics');

export default {
  name: 'HouseholdProfile',
  components: {
    HouseholdSidebar,
    HouseholdMembersTab,
    IpsGroupsTab,
    ModalWrapper,
  },
  data() {
    return {
      sidebarOptions: {
        BACK: 0,
        HOUSEHOLD_MEMBER: 1,
        CREATE_IPS: 2,
      },
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['hasFeatureFlag']),
    ...ipsGroupsVuex.mapGetters(['isLoading']),
    ...analytics.mapState({
      analyticsRegistered: 'registered',
    }),
    showIpsGroups() {
      return this.hasFeatureFlag('ips_groups') && this.$store.getters.canAccessIpsGroups;
    },
    sidebarSections() {
      return [
        {
          id: this.sidebarOptions.BACK,
          title: 'Back',
          route: '/households',
          icon: 'chevron-left',
          enabled: true,
        },
        {
          id: this.sidebarOptions.HOUSEHOLD_MEMBER,
          title: 'Household member',
          icon: 'plus',
          enabled: true,
        },
        {
          id: this.sidebarOptions.CREATE_IPS,
          title: this.$t('household:household_profile.sidebar.create_ips'),
          icon: 'file',
          enabled: this.showIpsGroups,
        },
      ].filter((section) => section.enabled);
    },
  },
  methods: {
    ...ipsGroupsVuex.mapActions(['createIpsGroup']),
    ...modalManagerVux.mapActions(['openModal']),
    ...analytics.mapActions(['trackEvents']),
    async onSideNavItemClick(event) {
      if (event.id === this.sidebarOptions.HOUSEHOLD_MEMBER) {
        await this.openModal({
          flow: 'HouseholdFlows',
          stage: 'createNewHousehold',
          modal: 'AddHouseholdMember',
        });
        await this.trackEvents({ event: 'Households', eventProperties: { view: 'HouseholdProfile', action: 'addMember' } });
      } else if (event.id === this.sidebarOptions.CREATE_IPS) {
        createAndGoToIpsGroup(this.$store, this.$router);
        await this.trackEvents({ event: 'Households', eventProperties: { view: 'HouseholdProfile', action: 'createAndGoToIpsGroup' } });
      } else if (event.id === this.sidebarOptions.BACK) {
        this.$router.push(event.route);
      }
    },
  },
  watch: {
    tabIndex: {
      handler(newValue) {
        newValue = Number(newValue);
        if (newValue !== Number(this.$route?.query?.tab)) {
          this.$router.replace({ query: { tab: Number(newValue) } });
        }
      },
    },
    $route: {
      handler(newValue, oldValue) {
        const oldTab = oldValue?.query?.tab;
        const newTab = newValue?.query?.tab;
        const isBadValue = !newTab || Number.isNaN(Number(newTab));
        // Set any non-number values to 0
        const tabIndex = isBadValue ? 0 : Number(newTab);
        // Only change tabIndex for initial route change to avoid infinite loop
        if (oldTab === undefined && tabIndex !== undefined) {
          this.tabIndex = tabIndex;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.household-profile-sidenav {
  max-width: 365px;
}
.household-profile-tabs {
  height:100vh;
  overflow-y: scroll;
}
</style>
