import api from 'Services/apiV2';
import { ACCOUNT_TYPES } from 'Services/constants';

export const fetchLandingPageInfo = () => api.get('/get_funding_landing_page_info')
  .then((res) => res)
  .catch((err) => err);

export const fetchFundingTransactions = ({
  clientId, accountType, requestType, status,
}, offset) => api.get('/get_funding_transactions_data/', {
  client_id: clientId,
  account_types: ACCOUNT_TYPES[accountType],
  trans_status: status,
  request_type: requestType,
  limit: 10,
  time_delta: 90,
  offset,
})
  .then((res) => {
    if (res) {
      return res;
    }
    return [];
  })
  .catch((err) => err);
