<template>
  <div>
    <AddedTransfers
      :stagedTransfers="stagedTransfers"
      :loading="loading"
      :openDeleteModal="openDeleteModal"
      :disabledSubmit="disabledSubmit"
      v-on:submitRequest="submit"
      v-on:delete="deleteTransfer"
      v-on:openDelete="openDelete"
      v-on:closeDelete="closeDelete"
      v-on:edit="editTransfer"
      v-on:addAnother="toTransferInForm"
      v-on:close="close" />
    <PToast id="no-staged-transfers">
      No transfers are selected.
    </PToast>
    <PToast id="workflow-success">
      Account transfer workflow has been successfully created.
    </PToast>
    <PToast id="workflow-failure">
      Account transfer workflow creation failed.
    </PToast>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createExternalAccountTransferWorkflow } from '../../../services/api/Workflows';
import {
  deleteExternalTransferRequest,
  submitExternalTransfersRequest,
} from '../../../services/api/FundingVersion2';

export default {
  name: 'ReviewAddedTransfers',
  props: {
  },
  data() {
    return {
      loading: false,
      transferPendingDelete: null,
      openDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'fundingSubtypes',
      'activeClient',
      'stagedExternalTransfers',
    ]),
    clientId() {
      return this.activeClient.client.id;
    },
    stagedTransfers() {
      return this.stagedExternalTransfers;
    },
    stagedTransferIds() {
      return this.stagedTransfers.map((transfer) => Number(transfer.id));
    },
    externalTransferWithActiveAccounts() {
      return this.stagedTransfers.filter((transfer) => transfer.investment_account_info?.account_status === 'opened');
    },
    externalTransferWithActiveAccountsIds() {
      return this.externalTransferWithActiveAccounts.map((transfer) => transfer.id);
    },
    disabledSubmit() {
      return !this.externalTransferWithActiveAccounts.length;
    },
  },
  methods: {
    close() {
      this.$router.push({ path: `/clients/${this.clientId}/funding-v2` });
    },
    openDelete(transfer) {
      this.transferPendingDelete = transfer;
      this.openDeleteModal = true;
    },
    closeDelete() {
      this.transferPendingDelete = null;
      this.openDeleteModal = false;
    },
    async deleteTransfer() {
      this.loading = true;
      await deleteExternalTransferRequest(this.transferPendingDelete.id);
      this.$store.dispatch('setStagedExternalTransfers', { clientId: this.clientId }).then(() => {
        this.loading = false;
      });
      this.openDeleteModal = false;
    },
    editTransfer(transfer) {
      const request = this.stagedTransfers.find((t) => t.id === transfer.id);
      this.$store.dispatch('setValidatedRequest', {
        ...request,
        subtype: request.subtype.id,
      });
      this.$store.dispatch('setEditMode', true);
      this.toTransferInForm();
    },
    toTransferInForm() {
      this.$router.push({ path: `/clients/${this.$route.params.clientID}/funding-v2/transfer/` });
    },
    async submit() {
      if (!this.stagedTransfers.length) {
        this.$bvToast.show('no-staged-transfers');
      } else {
        const externalTransferIds = this.externalTransferWithActiveAccountsIds.join(',');
        await submitExternalTransfersRequest({
          externalTransferIds,
        });
        await this.initiateWorkflow();
      }
    },
    async initiateWorkflow() {
      try {
        if (this.externalTransferWithActiveAccounts.length) {
          await createExternalAccountTransferWorkflow([this.clientId], this.externalTransferWithActiveAccountsIds);
          this.$bvToast.show('workflow-success');
          this.$store.dispatch('getWorkflows', this.clientId);
          this.$store.dispatch('setEditMode', false); // for safety
          setTimeout(() => { // to allow toast to show for a couple seconds
            this.close();
          }, 3000);
        } else {
          throw new Error('No transfers are selected.');
        }
      } catch (err) {
        this.$bvToast.show('workflow-failure');
      }
    },
  },
};
</script>

<style></style>
