<template>
  <div>
    <v-layout>
      <v-flex xs12 text-xs-left class="light-text">
        Drag the files you would like to submit one at a time into this box
      </v-flex>
    </v-layout>
    <v-layout :style="{padding: '40px 0px'}">
      <v-flex xs12>
        <pas-dropzone
          ref="pasDropzone"
          :id="`dropzone`"
          class="gold-header short-dropzone docs-dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
        ></pas-dropzone>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <template v-for="(file, index) in uploadingFiles">
      <v-flex xs12 :key="`uploading-file-${index}`">
        <v-layout row>
          <v-flex xs2 pt-2>
            <img height="60px" width="50px" :src="getIcon(file.file.name)">
          </v-flex>
          <v-flex xs10 text-xs-left>
            <v-layout row wrap>
              <v-flex xs12 mb-2>
                <div class="strong-text">{{file.customName.toLowerCase()}}</div>
              </v-flex>
              <v-flex xs12 :style="{fontSize: '9px'}">
                {{typeof file.progress === "string" ? `UPLOAD ${file.progress.toUpperCase()}` : `${file.progress}% COMPLETED`}}
              </v-flex>
              <v-flex xs12 nowrap>
                <v-layout row nowrap>
                  <v-flex xs10 mr-3>
                    <v-progress-linear 
                      :value="file.progress" 
                      :color="primaryColor" 
                      class="docs-progress-bar"
                    ></v-progress-linear>
                  </v-flex>
                  <v-flex xs2 v-if="typeof file.progress === 'number'" align-center>
                    <pas-icon-circle
                      @click="removeFile(file.file)"
                      :style="{marginTop: '8px'}"
                      :theme="`pas`"
                    >
                      <v-icon>mdi-close</v-icon>
                    </pas-icon-circle>
                  </v-flex>
                </v-layout>
                <v-spacer>
                </v-spacer>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      </template>
    </v-layout>
    <select-document-type-modal
      :isOpen="modal"
      @close="fileOptionsModalClose"
      @fileOptionsSelected="fileOptionsSelected($event)"
    ></select-document-type-modal>
  </div>
</template>

<script>

import pdf from 'Assets/icons/pdf.svg'
import doc from 'Assets/icons/doc.svg'
import jpeg from 'Assets/icons/jpeg.svg'
import png from 'Assets/icons/png.svg'
import excel from 'Assets/icons/excel.svg'
import other from 'Assets/icons/other.svg'
import { v4 as uuidv4 } from 'uuid';
import { FILE_EXTENSIONS, DOCUMENT_SOURCE } from 'Services/constants'
import selectDocumentTypeModal from 'Components/Shared/SelectDocumentTypeModal'

export default {
  name: 'workflow-view-upload-documents-tab',
  components: {
    selectDocumentTypeModal
  },
  props: {
    workflowId: String
  },
  data() {
    let acceptedFiles = FILE_EXTENSIONS.map(f => `.${f}`)
    let that = this;
    return {
      dropzoneOptions: {
         url: '/advisor_portal/workflow/upload_workflow_document', //TODO: not sure what the difference is between this and upload_client_docs
				init: function() {
					let docType = 'Account Application' //TODO: need to provide options
					this.on("sending", (file, xhr, formData) => {
            formData.append('document_type', that.nextDocumentType); //Account Application
            formData.append('document_name', that.nextDocumentName);
            formData.append("document_source", that.documentSource.Manual);
						formData.append('workflow', that.workflowId);
            that.uploadingFiles = that.uploadingFiles.concat([{file, progress: 0, customName: that.nextDocumentName}])
            
            //reset doc values
            that.nextDocumentName = ''
            that.nextDocumentType = ''
          });
          this.on("addedfile", file => {
            //only allow one upload at a time by only adding if no files in queue
            //assign a unique id
            
            if (!acceptedFiles.includes(`.${that.getExtension(file).toLowerCase()}`)) {
              console.log("Unaccepted file")
              that.$refs.pasDropzone.$refs.dropzone.removeFile(file)
            }
            else {
              file.uuid = uuidv4()
              if (that.$refs.pasDropzone.$refs.dropzone.getQueuedFiles().length > 0) {
                that.$refs.pasDropzone.$refs.dropzone.removeFile(file)
              } else {
                that.modal = true
                that.addedFile = file
              }
            }
          })
          this.on("uploadprogress", (file, progress) => {
            //find file in uploads list and update progress
            that.updateFileProgress(file, progress)
          })
          this.on("success", file => {
            that.updateFileProgress(file, "complete")
            that.$store.dispatch('fetchWorkflow', that.workflowId);
          })
          this.on("canceled", file => {
            that.updateFileProgress(file, "canceled")
          })
          this.on("error", (file, err) => {
            that.$store.dispatch('setSnackbar', {text: `Upload error - invalid document type. Use one of the following types: ${FILE_EXTENSIONS.map(f => `.${f}`).join(',')}`, type: 'error'})
						that.$store.dispatch('flipSnackbarSwitch')
            that.updateFileProgress(file, "failed")
          })
				},
				headers: { "Authorization": `Bearer ${localStorage.getItem('jwt')}` },
				maxFiles: 4,
        addRemoveLinks: true,
        autoProcessQueue: false,
        createImageThumbnails: false,
        // thumbnailWidth: 0,
        // thumbnailHeight: 0,
        acceptedFiles: acceptedFiles.join(',')

      },
      modal: false,
      nextDocumentName: '',
      nextDocumentType: '',
      addedFile: '',
      uploadingFiles: [],
      pdf,
      doc,
      jpeg,
      png,
      excel,
      other      
    }
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  methods: {
    getIcon(fileName) {
      let extension = fileName.substr(fileName.lastIndexOf('.')+1).toLowerCase();
      const icons = { 
        pdf: this.pdf, 
        doc: this.doc, 
        docx: this.doc, 
        jpeg: this.jpeg, 
        jpg: this.jpeg, 
        png: this.png, 
        xls: this.excel, 
        xlsm: this.excel, 
        xlsx:  this.excel
      };
      return icons[extension] ? icons[extension] : this.other;
    },    
    updateFileProgress(file, progress) {
      let index = this.uploadingFiles.findIndex(f => f.file.uuid === file.uuid)
      if (index > -1) {
        this.$set(this.uploadingFiles, index, {...this.uploadingFiles[index], progress})
      }
    },
    getFileType(fileName) {
      let extension = fileName.substr(fileName.lastIndexOf('.')+1).toLowerCase();
      const fileTypeCode = { pdf: 0, doc: 1, docx: 1, jpeg: 2, jpg: 2, png: 3, xls: 4, xlsm: 4, xlsx: 4 };
      return fileTypeCode[extension] || fileTypeCode[extension] == 0 ? fileTypeCode[extension] : 5;
    },
    fileOptionsSelected({ documentName, documentType }) {
      this.modal = false;
      this.nextDocumentName = documentName;
      this.nextDocumentType = documentType;
      this.$refs.pasDropzone.$refs.dropzone.processQueue();
    },
    fileOptionsModalClose() {
      this.$refs.pasDropzone.$refs.dropzone.removeFile(this.addedFile);
      this.modal = false;
    },
    removeFile(file) {
      this.$refs.pasDropzone.$refs.dropzone.removeFile(file)
      this.uploadingFiles = this.uploadingFiles.filter(f => f.file.uuid !== file.uuid)
    },
    getExtension(file) {
      return file.name.split('.').slice(-1)[0].toLowerCase();
    }
  }
}
</script>

<style lang="scss">
@import '~Styles/variables';

  .upload-docs-icon {
    font-size: 60px !important;
  }
  .docs-dropzone .dz-preview, .dz-file-preview {
    display: none !important;
  }
  .docs-dropzone .dz-started .dz-message {
    display: block !important;
  }
  .docs-progress-bar {
    border-radius: 8px !important;
  }
  
</style>
