<template>
  <v-dialog
    :value="isOpen"
    persistent
    fullscreen
  >
    <v-card :style="{overflow: 'hidden !important'}">
      <PasFab class="close-btn">
        <v-icon @click="$emit('close')" class="close-icon">
          close
        </v-icon>
      </PasFab>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'pas-fullscreen-modal',
  data() {
    return {};
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>
