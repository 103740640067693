import { result } from 'lodash';
import { info } from 'logrocket';
import api from 'Services/api';

export const addOneTimeSystematic = (data) => api.post('/advisor_portal/money_movement/one_time_funding', data);
export const addRecurringSystematic = (data) => api.post('/advisor_portal/money_movement/add_recurring_event', data);
export const addInternalTransfer = (data) => api.post('/advisor_portal/money_movement/add_internal_transfer_fcc', data);
export const getInternalTransfersValidation = () => api.get('/datahub/internal_account_transfer_validations/');
export const getActiveAccounts = async (data) => {
  const accountResult = await api.get(`/datahub/get_account_banking_info/?client_id=${data.client_id}&active_only=${data.active_only}`).then((res) => res);
  return accountResult.results;
};
