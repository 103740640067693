<!-- eslint-disable max-len -->
<template>
  <div class="w-100 mb-3">
    <!-- MOBILE VIEW -->
    <div class="d-block d-lg-none">
      <div class="text-center" v-if="isLoading">
        <PSpinner />
      </div>
      <dl v-else>
        <PCard class="mb-2 shadow-none" body-class="py-0" v-for="client in clients" v-bind:key="client.client_id">
          <div class="client-row border-bottom border-grey-500">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column align-items-start">
                <router-link :to="`clients/${client.client_id}`" class="text-primary text-semibold">{{ client.name }}</router-link>
              </div>
              <StatusBadge
                class="text-uppercase font-weight-normal ml-2"
                v-if="client.fatca_renewal_status"
                :status="client.fatca_renewal_status"
                :statusLabelMap="statusLabelMap"
                :statusVariantMap="statusVariantMap"
              />
              <div class="d-flex justify-content-end">
                <button
                  v-if="client.fatca_renewal_status === 'completed'"
                  class="ml-2 btn btn-light rounded-pill"
                  @click="router.push({ path: `clients/${client.client_id}` })"
                >
                  View
                </button>
                <PButton
                  v-else
                  @click="openConfirmFATCARenewalModal(client)"
                  class="ml-2 btn btn-light rounded-pill"
                  variant="dark"
                >
                  Renew
                </PButton>
              </div>
            </div>
          </div>
          <div class="client-row border-bottom border-grey-500">
            <dt class="text-grey-600 text-semibold">Team</dt>
            <dd class="font-weight-normal">{{ client.team_name }}</dd>
          </div>
          <div class="client-row border-bottom border-grey-500">
            <dt class="text-grey-600 text-semibold">Due date</dt>
            <dd class="font-weight-normal">
              <div>{{ client.next_fatca_renewal_due ? moment(client.next_fatca_renewal_due).format(dateFormat) : 'N/A' }}</div>
            </dd>
          </div>
        </PCard>
      </dl>
      <PCard class="mb-2" v-if="!clients.length">No matching clients</PCard>
    </div>
    <!-- DESKTOP VIEW -->
    <div class="d-none d-lg-block">
      <PTable
        thead-class="fixed-thead"
        tbody-class="bg-white"
        :fields="fields"
        :items="clients"
        :busy="isLoading"
        :sort-by.sync="query.sortBy"
        :sort-desc.sync="query.sortDescending"
        no-local-sorting
        show-empty
        @sort-changed="sort($event)"
      >
        <template #cell(fatca_renewal_status)="data">
          <StatusBadge
            class="text-uppercase font-weight-normal"
            v-if="data.item.fatca_renewal_status"
            :status="data.item.fatca_renewal_status"
            :statusLabelMap="statusLabelMap"
            :statusVariantMap="statusVariantMap"
          />
        </template>
        <template #cell(name)="data">
          <div class="d-flex flex-column align-items start">
            <router-link :to="`clients/${data.item.id}`" class="text-dark text-semibold">{{ data.item.name }}</router-link>
          </div>
        </template>
        <template #cell(contact)="data">
          <div class="flex-column align-items start">
            <a :href="`mailto:${data.item.email}`">{{ data.item.email }}</a>
            <br />
            <a :href="`tel:+${data.item.preferred_number}`">{{ formatPhoneNumber(data.item.preferred_number) }}</a>
          </div>
        </template>
        <template #cell(team_name)="data">
          {{ data.item.team_name }}
        </template>
        <template #head(due_date)="data">
          <span class="mb-0"> Due date
            <font-awesome-icon class="pl-1" size="lg" :icon="['fal', sortIcon]" />
          </span>
        </template>
        <template #cell(next_fatca_renewal_due)="data">
          <div>{{ data.item.next_fatca_renewal_due ? moment(data.item.next_fatca_renewal_due).format(dateFormat) : 'N/A' }}</div>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <button
              v-if="data.item.fatca_renewal_status === 'completed'"
              class="ml-2 btn btn-light rounded-pill"
              @click="router.push({ path: `clients/${data.item.id}` })"
            >
              View
            </button>
            <PButton
              v-else
              @click="openConfirmFATCARenewalModal(data.item)"
              class="ml-2 btn btn-primary rounded-pill"
              variant="dark"
            >
              Renew
            </PButton>
          </div>
        </template>
        <template #empty>
          <h3>No matching clients</h3>
        </template>
        <template #table-busy>
          <div class="text-center">
            <PSpinner />
          </div>
        </template>
      </PTable>
    </div>
    <div class="d-flex justify-content-center" v-if="hasClientsToLoad && !isLoading">
      <PButton variant="dark" @click="loadClients(query.offset + query.limit)">See more</PButton>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import { formatPhoneNumber } from 'Services/mixins';
import { fetchFATCARenewalsList } from 'Services/api/FATCARenewalsList';
import { fetchInProgressWorkflowsForClient } from 'Services/api/Workflows';
import StatusBadge from 'Components/Shared/StatusBadge';
import ConfirmActionModal from 'Components/Shared/ConfirmActionModal';
import { FATCA_RENEWAL_MAP_STATUS_LABEL, FATCA_RENEWAL_MAP_STATUS_VARIANT } from './constants';

const modalManagerVux = createNamespacedHelpers('modalManager');

export default {
  name: 'ClientsTable',
  mixins: [
    formatPhoneNumber,
  ],
  components: {
    StatusBadge,
    ConfirmActionModal,
  },
  props: {
    clientId: { type: Number, default: () => null },
    status: { type: String, default: () => null },
    team: { type: Number, default: () => null },
  },
  data() {
    return {
      moment,
      clients: [],
      fields: [
        {
          key: 'fatca_renewal_status', label: '', thClass: 'border-0', tdClass: 'align-middle align-center',
        },
        {
          key: 'name', label: 'Client', thClass: 'border-0', tdClass: 'align-middle',
        },
        {
          key: 'contact', label: 'Contact', thClass: 'border-0 text-grey-600 align-middle', tdClass: 'align-middle',
        },
        {
          key: 'team_name', label: 'Team', thClass: 'border-0 text-grey-600 align-middle', tdClass: 'align-middle',
        },
        {
          key: 'next_fatca_renewal_due', label: 'Due date', thClass: 'border-0 text-grey-600 align-middle', tdClass: 'align-middle', sortable: true,
        },
        {
          key: 'actions', label: '', thClass: 'border-0 text-grey-600 pr-lg-5 align-middle', tdClass: 'pr-lg-5 align-middle',
        },
      ],
      dateFormat: 'ddd, MMM D, YYYY',
      hasClientsToLoad: false,
      isLoading: true,
      query: {
        limit: 8,
        offset: 0,
        sortBy: 'next_fatca_renewal_due',
        sortDirection: 'asc',
        sortDescending: false,
        teamIds: [],
        status: null,
        clientId: null,
      },
      selectedClientForRenewal: undefined,
      clientIdResetFilters: false,
      statusLabelMap: FATCA_RENEWAL_MAP_STATUS_LABEL,
      statusVariantMap: FATCA_RENEWAL_MAP_STATUS_VARIANT,
    };
  },
  computed: {
    sortIcon() {
      return this.query.sortDirection === 'asc' ? 'chevron-up' : 'chevron-down';
    },
    router() {
      return this.$router;
    },
  },
  methods: {
    ...modalManagerVux.mapActions([
      'openModal',
    ]),
    async loadClients(offset) {
      this.isLoading = true;
      this.query.offset = offset;
      await fetchFATCARenewalsList(this.query)
        .then((clients) => {
          this.hasClientsToLoad = clients.length === this.query.limit;
          this.clients = !offset ? clients : this.clients.concat(clients);
        })
        .catch(() => {
          this.$emit('show-toast', 'Sorry, something went wrong. Please try again, or contact us for more details.');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetTable() {
      this.query.offset = 0;
    },
    sort(event) {
      this.query.sortDirection = event.sortDesc ? 'desc' : 'asc';
      this.query.sortDescending = event.sortDesc;
      this.resetTable();
      this.loadClients(this.query.offset);
    },
    async openConfirmFATCARenewalModal(client) {
      this.selectedClientForRenewal = client;

      await this.openModal({
        flow: 'FATCARenewalFlows',
        stage: 'StartRenewal',
        modal: 'ConfirmFATCARenewal',
      });
    },
    async startFATCARenewal(client) {
      try {
        const inProgressWorkflowsForClient = await fetchInProgressWorkflowsForClient(
          1,
          [client.id],
          'fatca_repapering',
        );

        if (inProgressWorkflowsForClient.count === 0) {
          console.log("Start workflow questionnaire");
          // await createAndStartFATCARenewalWorkflow(client.guid);
        }
      } catch (error) {
        this.$emit('show-toast', 'Something went wrong. Please try again, or contact us for more details.');
        this.loadClients(this.query.offset);
      }
    },
  },
  mounted() {
    this.loadClients(0);
  },
  watch: {
    status(value) {
      if (value) {
        this.query.clientId = null;
        this.clientIdResetFilters = false;
      }
      this.query.status = value;
      if (this.clientIdResetFilters === false) {
        this.resetTable();
        this.loadClients(0);
      }
    },
    team(value) {
      if (value) {
        this.query.clientId = null;
        this.clientIdResetFilters = false;
      }
      this.query.teamIds = [value];
      if (this.clientIdResetFilters === false) {
        this.resetTable();
        this.loadClients(0);
      }
    },
    async clientId(value) {
      if (value) {
        this.clientIdResetFilters = true;
        this.$emit('reset-filters');
      }
      this.query.clientId = value;
      this.resetTable();
      this.loadClients(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-thead {
  font-size: 0.75rem;
  padding: 0.125rem !important;
}

.client-row {
  align-items: center;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  margin: 0rem -1.5rem !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  & > :last-child {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .row-header {
    flex: auto;
  }
}

dd {
  margin: 0 !important;
}

table:deep {
  thead.fixed-thead {
    height: 56px;
    tr > th[aria-sort] {
        background-image: none !important;
    }
  }

  td.align-center {
    text-align: center;
  }
}

.text-semibold {
  font-weight: 600;
}
</style>
