<template>
  <div class="mt-5 d-flex flex-column justify-content-center align-items-center">
    <h3 class="mt-3 mb-2">Sorry, no results</h3>
    <PButton @click="$emit('click')" variant="dark">Clear filters</PButton>
  </div>
</template>

<script>

export default {
  name: 'TransactionNoResults',
};
</script>
