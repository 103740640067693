<template>
  <div>
    <v-layout>
      <v-flex xs12 text-xs-center mb-4>
        <pas-page-title>
          What would you like to do next?
        </pas-page-title>
      </v-flex>
    </v-layout>
    <transition name="slide-fade">
      <v-layout column nowrap justify-center>
        <v-layout row nowrap justify-center>
          <v-flex xs4 text-xs-center mr-2>
            <action-card
              :action="'Save and Send'"
              :imagePath="EmailSvg"
              @click="saveAndSend"
            >Send the account opening documents to your client by email.
            </action-card>
          </v-flex>
          <v-flex xs4 text-xs-center>
            <action-card
              :action="'Save and come back'"
              :imagePath="ScreenshareSvg"
              @click="saveAndComeBack"
              data-testid="onboarding-save-and-send"
            >Save the new account information and send to the client at another time.
            </action-card>
          </v-flex>
        </v-layout>
      </v-layout>
    </transition>
  </div>
</template>

<script>
import ActionCard from 'Components/Shared/ActionCard';
import EmailSvg from 'Assets/icons/email-01.svg';
import ScreenshareSvg from 'Assets/icons/screenshare-01.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'SaveOptions',
  components: {
    ActionCard,
  },
  data() {
    return {
      EmailSvg,
      ScreenshareSvg,
      skipSurvey: false,
    };
  },
  props: {
    savingAccounts: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
    ]),
  },
  methods: {
    saveAndSend() {
      if (!this.savingAccounts) {
        this.$emit('createNewAccount');
      }
    },
    saveAndComeBack() {
      if (!this.savingAccounts) {
        this.$emit('saveTempAccts');
      }
    },
  },
};
</script>

<style>

</style>
