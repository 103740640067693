<!-- eslint-disable max-len -->
<template>
  <div class="mt-5">
    <h2>Insights</h2>
    <div v-for="category in categories" :key="category.id" class="mt-7" style="margin-top:56px">
      <div v-if="reportsByCategory(category.id).length">
        <div class="subtitle">{{category.category_name}}</div>
        <div class="mt-2" style="display:flex; width: 100%; height: 100%">
          <div v-for="(report, i) in reportsByCategory(category.id)" :key="i" class="mr-2">
            <Thumbnail :url="report.url" />
            <div @click="$emit('selectReport', report)" class="mt-2">
              <p class="link">{{report.display_name}} <font-awesome-icon :icon="['far', 'chevron-right']" class='icon ml-2' /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Thumbnail from './Thumbnail.vue';

export default {
  name: 'AllThumbnails',
  components: {
    Thumbnail,
  },
  props: {
    reportList: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    selectedCategory() {
      return _.uniqBy(this.reportList.map((e) => e.category), 'id');
    },
  },
  methods: {
    reportsByCategory(categoryId) {
      return this.reportList.filter((e) => e.category.id === categoryId);
    },
  },

};
</script>

<style scoped>

</style>
