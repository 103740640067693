<template>
  <v-combobox
    single-line
    outline
    solo
    flat
    :items="items"
    :value="value"
    :label="label"
    :rules="rules"
    :prefix="prefix"
    :prepend-inner-icon="prependInnerIcon"
    :small-chips="smallChips"
    :deletable-chips="deletableChips"
    :multiple="multiple"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
    @keyup.enter="$emit('enter', $event)"
    class="combobox"
    :return-object="returnObject"
    :color="primaryColor"
    :no-data-text="noDataText"
  ></v-combobox>
</template>

<script>
export default {
  name: 'pas-combobox',
  props: {
    items: Array,
    value: [Array, Number, String, Object],
    label: {
      type: String,
      default: 'Select',
    },
    noDataText: {
      type: String,
      default: 'No data available',
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    prefix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style lang="scss">

.combobox {
  .v-input__slot {
    border-radius: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 20px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 40px !important;
    margin: 0px !important;
    input {
      padding: 0px !important;
      margin: 0px !important;
    }
        // overflow: scroll;
    .v-chip {
      background-color: #F3EDE3 !important;
      color:var(--primary-color) !important;
    }
  }
}

</style>
