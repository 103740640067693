<template>
  <h1>
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'PasPageTitle',
};
</script>

<style scoped>
h1 {
  font-weight: 500;
}
</style>
