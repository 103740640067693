import api from 'Services/apiV2';

// PERSONS
export const fetchPerson = (clientId) => api.get(`/persons/${clientId}/`);
export const createPerson = (data) => api.post('/persons/', data);

// CORPORATIONS
export const createCorporation = (data) => api.post('/corporations/', data);
export const createFoundation = (data) => api.post('/foundations/', data);
export const createTrust = (data) => api.post('/trusts/', data);
export const createIPP = (data) => api.post('/ipps/', data);
export const createNonProfit = (data) => api.post('/nonprofits/', data);

// Clients List

export const fetchClientsList = (
  offset,
  sortBy,
  sortDir,
  type,
  status,
  searchVal,
  advisorCodes,
  requestId,
  excludeKycClients,
  selectedAdvisorTeams,
) => api.get('/clients_list/', {
  offset,
  search_val: searchVal,
  type,
  status,
  sort_by: sortBy,
  sort_dir: sortDir,
  advisor_code: advisorCodes,
  limit: 25,
  exclude_kyc_clients: excludeKycClients,
  team_ids: selectedAdvisorTeams,
}, requestId);
