<template>
  <PContainer v-if="clientId">
    <PRow class="m-1">
      <span>Equifax Upload Placeholder</span>
    </PRow>
  </PContainer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EquifaxUpload',
  computed: {
    ...mapGetters(['activeClient']),
    clientId() {
      return this.activeClient.client && this.activeClient.client.id;
    },
    navigation() {
      return {
        back: {
          routeName: 'ClientHomePage',
          text: this.activeClient?.client?.display_name || `${this.activeClient.client?.first_name} ${this.activeClient.client?.last_name}`,
        },
      };
    },
  },
};
</script>
