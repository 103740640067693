<template>
  <div>
    <v-container class='platform-users-manager'>
      <template v-if="this.isLoading">
        <v-layout justify-center align-center>
            <PasCircularLoader :loadingMessage="`Loading platform users manager...`"  />
        </v-layout>
      </template>
      <template v-else-if="this.rolesAndPermissionsFeatureEnabled">
        <v-layout align-baseline mt-4 justify-space-between>
          <PasPageTitle class="mb-4">Platform Users ({{ this.numOfPlatformUsers }})</PasPageTitle>
        </v-layout>
        <v-layout align-center>
          <v-flex xs4>
            <PasSearchBox
              placeholder="Search for a platform user..."
              v-model="searchText"
              :clearable="false"
              @input="filterOptions.page = 1;"
            />
          </v-flex>
          <v-flex xs6>
            <v-layout align-center justify-end>
              <v-flex xs1 mr-3>
                <span>Status:</span>
              </v-flex>
              <v-flex xs2>
                <v-select
                  @change="filterOptions.page = 1;"
                  v-model="status.sortBy"
                  :items="status.options"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout justify-end>
              <PasButton
                  @click="saveChanges"
                  class="primary-btn px-4 mr-3"
                  :disabled="!isRoleEdited && !isTeamEdited && !isInsightEdited"
                  :processing="isSavingChanges"
              >Save Changes</PasButton>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout justify-end>
              <PasButton
                  @click="addPlatformUser"
                  class="primary-btn darken-1 px-4"
              >Add Platform User</PasButton>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-spacer></v-spacer>
        <pas-list
          :columns="columns"
          :itemName="'platform users'"
          :items="filteredPlatformUsers"
        >
          <template slot-scope="{ item }" >
            <v-layout fill-height row>
              <v-flex xs2 strong-text>
                <v-layout fill-height align-center ml-3>
                  {{ `${item.first_name} ${item.last_name}` }}
                </v-layout>
              </v-flex>
              <v-flex xs3>
                <v-layout fill-height align-center>
                  {{ item.email }}
                </v-layout>
              </v-flex>
              <v-flex xs3>
                <v-layout fill-height align-center pr-5>
                  <v-autocomplete
                    @change="isTeamEdited = true, item.isTeamEdited = true"
                    v-model="item.teams"
                    :items="allTeams"
                    multiple
                    :key="item.team"
                    :placeholder="'No team assigned'"
                  >
                    <template v-slot:selection="data">
                      <v-chip v-if="data.index === 0">
                        <span>{{ data.item.text }}</span>
                      </v-chip>
                      <span
                        v-if="data.index === 1"
                        class="caption"
                      >(+{{ item.teams.length - 1 }})</span>
                    </template>
                  </v-autocomplete>
                </v-layout>
              </v-flex>
              <v-flex xs2>
                <v-layout fill-height align-center pr-5>
                  <v-select
                    @change="isRoleEdited = true, item.isRoleEdited = true"
                    v-model="item.roles"
                    :items="allRoles"
                    multiple
                    chips
                    :key="item.role"
                    :placeholder="'No roles assigned'"
                  >
                    <template v-slot:selection="data">
                      <v-chip v-if="data.index === 0">
                        <span>{{ data.item.text }}</span>
                      </v-chip>
                      <span
                        v-if="data.index === 1"
                        class="caption"
                      >(+{{ item.roles.length - 1 }})</span>
                    </template>
                  </v-select>
                </v-layout>
              </v-flex>
              <v-flex xs2 v-if="insightsFeatureEnabled">
                <v-layout fill-height row align-center justify-center>
                  <v-select
                    @change="isInsightEdited = true, item.isInsightEdited = true"
                    v-model="item.modules"
                    :items="allModules"
                    multiple
                    chips
                    :key="item.module"
                    :placeholder="'No insight access'"
                  >
                    <template v-slot:selection="data">
                      <v-chip v-if="data.index === 0">
                        <span>{{ data.item.text }}</span>
                      </v-chip>
                      <span
                        v-if="data.index === 1"
                        class="caption"
                      >(+{{ item.modules.length - 1 }})</span>
                    </template>
                  </v-select>
                </v-layout>
              </v-flex>
              <v-flex xs2>
                <v-layout fill-height row align-center justify-center>
                  <PasSelectableChip
                    :color="item.activationStatus.color"
                    :text-color="'white'"
                    :disabled="item.activationStatus.disabled"
                    justify-center
                    @click="item.activationStatus.onClick(item)"

                  >{{ item.activationStatus.text }}
                  </PasSelectableChip>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </pas-list>
        <v-spacer fill-height></v-spacer>
        <v-layout text-xs-center row fill-height>
          <v-flex xs12>
            <pas-pagination :length="paginationLength" v-model="filterOptions.page"></pas-pagination>
          </v-flex>
        </v-layout>
      </template>
    </v-container>
    <AddPlatformUser
      :isOpen="isAddPlatformUserModalOpen"
      @close="isAddPlatformUserModalOpen = false"
      @getPlatformUsers="getPlatformUsers"
      :allRoles="allRoles"
    ></AddPlatformUser>
  </div>
</template>

<script>

import {
  fetchRoles, fetchModules, fetchPlatformUsers, updatePlatformUserRoles, updatePlatformUserTeams, updatePlatformUserApproval, updatePlatformUserModules, resetPassword,
} from 'Services/api/RolesAndPermissions';
import { fetchTeams } from 'Services/api/ClientDataHierarchy';
import AddPlatformUser from 'Components/Modals/AddPlatformUser';
import _ from 'lodash';
import { formatPhoneNumber } from 'Services/mixins';
import moment from 'moment';

export default {
  name: 'PlatformUsersManager',
  components: {
    AddPlatformUser,
  },
  mixins: [formatPhoneNumber],
  data() {
    return {
      filterOptions: {
        sortDir: -1, // -1 for down (most recent first), 1 for up
        page: 1,
        perPage: 10, // not modifiable
      },
      paginationLength: 1,
      allColumns: [
        {
          header: 'Name', size: 'xs2',
        },
        {
          header: 'Email', size: 'xs3',
        },
        {
          header: 'Assigned Team', size: 'xs3',
        },
        {
          header: 'Assigned Role', size: 'xs2',
        },
        {
          header: 'Feature Access', size: 'xs2',
        },
        {
          header: 'Invite', size: 'xs2 text-align-center',
        },
      ],
      status: {
        sortBy: 'All',
        options: [
          'All',
          'Approve',
          'Send',
          'Sent',
          'Resend',
          'Accepted',
        ],
      },
      statusButtons: [
        {
          text: 'Send', color: 'green', disabled: false, onClick: (item) => this.sendActivationEmail(item),
        },
        {
          text: 'Sent', color: 'grey', disabled: true,
        },
        {
          text: 'Resend', color: 'red', disabled: false, onClick: (item) => this.sendActivationEmail(item),
        },
        {
          text: 'Approve', color: 'green', disabled: true, onClick: (item) => this.approveUser(item),
        },
        {
          text: 'Accepted', color: 'grey', disabled: true,
        },
      ],
      isAddPlatformUserModalOpen: false,
      allRoles: [],
      allTeams: [],
      allModules: [],
      isLoading: true,
      searchText: '',
      isRoleEdited: false,
      isTeamEdited: false,
      isInsightEdited: false,
      allPlatformUsers: [],
      isSavingChanges: false,
    };
  },
  async mounted() {
    await this.getPlatformUsers();
    this.allRoles = await fetchRoles();
    this.allModules = await fetchModules();
    const response = await fetchTeams();
    this.allTeams = response.teams.map((team) => ({
      text: _.startCase(team.name),
      value: team.id,
    }));
    this.isLoading = false;
  },
  computed: {
    rolesAndPermissionsFeatureEnabled() {
      return this.$store.state.app.features.roles_and_permissions;
    },
    insightsFeatureEnabled() {
      return this.$store.state.app.features.insights;
    },
    filteredPlatformUsers() {
      return this.filterStatus(this.allPlatformUsers
        .filter((platformUser) => (`${platformUser.first_name} ${platformUser.last_name}
         ${platformUser.email} ${platformUser.phone}`).toLowerCase()
          .includes(this.searchText.toLowerCase())));
    },
    numOfPlatformUsers() {
      return this.allPlatformUsers.length;
    },
    firm() {
      return this.$store.getters.firm;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    columns() {
      if (!this.insightsFeatureEnabled) {
        return this.allColumns.filter(x => x.header !== 'Feature Access');
      }
      return this.allColumns;
    }
  },
  methods: {
    addPlatformUser() {
      this.isAddPlatformUserModalOpen = true;
    },
    async approveUser({ user_account_id, email, is_approved }) {
      await updatePlatformUserApproval(user_account_id, { username: email, is_approved: true, is_approved_by_email: true }, this.$store.dispatch);
      this.getPlatformUsers();
    },
    async saveRoles() {
      const modifiedRoles = [];
      this.allPlatformUsers.forEach((platformUser) => {
        if (platformUser.isRoleEdited) {
          modifiedRoles.push(_.pick(platformUser, ['user_account_id', 'roles']));
        }
      });
      const response = await updatePlatformUserRoles(modifiedRoles);
      if (response.status !== 'success') {
        this.$store.dispatch('setSnackbar', { text: 'Failed to save modified roles.', type: 'fail' });
        this.$store.dispatch('flipSnackbarSwitch');
        return;
      }
      this.isRoleEdited = false;
    },
    async saveTeams() {
      const modifiedTeams = [];
      this.allPlatformUsers.forEach((platformUser) => {
        if (platformUser.isTeamEdited) {
          modifiedTeams.push(_.pick(platformUser, ['platform_user_id', 'teams']));
        }
      });
      const response = await updatePlatformUserTeams(modifiedTeams);
      if (response.status !== 'success') {
        this.$store.dispatch('setSnackbar', { text: 'Failed to save modified teams.', type: 'fail' });
        this.$store.dispatch('flipSnackbarSwitch');
        return;
      }
      this.isTeamEdited = false;
    },
    async saveModules() {
      const modifiedModules = [];
      this.allPlatformUsers.forEach((platformUser) => {
        if (platformUser.isInsightEdited) {
          modifiedModules.push(_.pick(platformUser, ['user_account_id', 'modules']));
        }
      });
      const response = await updatePlatformUserModules(modifiedModules);
      if (response.status !== 'success') {
        this.$store.dispatch('setSnackbar', { text: 'Failed to save modified insight modules.', type: 'fail' });
        this.$store.dispatch('flipSnackbarSwitch');
        return;
      }
      this.isInsightEdited = false;
    },
    async saveChanges() {
      this.isSavingChanges = true;
      if (this.isRoleEdited) {
        await this.saveRoles();
      }
      if (this.isTeamEdited) {
        await this.saveTeams();
      }
      if (this.isInsightEdited) {
        await this.saveModules();
      }
      if (!this.isTeamEdited && !this.isRoleEdited && !this.isInsightEdited) {
        this.$store.dispatch('setSnackbar', { text: 'Sucessfully saved changes.', type: 'success' });
        this.$store.dispatch('flipSnackbarSwitch');
      }
      this.isSavingChanges = false;
    },
    filterStatus(platformUsers) {
      const iStart = (this.filterOptions.page - 1) * this.filterOptions.perPage;
      const iEnd = this.filterOptions.page * this.filterOptions.perPage;
      let filteredPlatformUsers;

      if (this.status.sortBy === 'All') {
        filteredPlatformUsers = platformUsers;
      } else {
        filteredPlatformUsers = platformUsers.filter((platformUser) => platformUser.activationStatus.text === this.status.sortBy);
      }

      filteredPlatformUsers.sort((a, b) => (new Date(a.date_joined) < new Date(b.date_joined) ? 1 : -1));
      this.updatePaginationLength(filteredPlatformUsers);
      return filteredPlatformUsers.slice(iStart, iEnd);
    },
    async getPlatformUsers() {
      await fetchPlatformUsers(this.$store.dispatch)
        .then((res) => {
          if (res) {
            this.allPlatformUsers = res.map((user) => ({
              user_account_id: user.id,
              platform_user_id: user.platform_user_id,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              phone: user.phone,
              isRoleEdited: false,
              isTeamEdited: false,
              isInsightEdited: false,
              is_approved: user.is_approved,
              is_active: user.is_active,
              email_link_sent_at: user.email_link_sent_at,
              date_joined: user.date_joined,
              activationStatus: this.determineActivationStatus(user),
              roles: user.roles.map((role) => ({
                value: role.role_id,
              })),
              teams: user.teams.map((team) => ({
                value: team.team_id,
              })),
              modules: user.modules.map((module) => ({
                value: module.module_id,
              })),
            }));
          }
        });
    },
    sendActivationEmail({ user_account_id, email }) {
      const currentDate = moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS');
      updatePlatformUserApproval(user_account_id, { username: email, is_approved: false }, this.$store.dispatch)
        .then(() => {
          resetPassword({ username: email, firm: this.firm }, this.$store.dispatch)
            .then(() => {
              updatePlatformUserApproval(user_account_id, { username: email, is_approved: true, email_link_sent_at: currentDate }, this.$store.dispatch)
                .then(() => this.getPlatformUsers());
            });
        });
    },
    updatePaginationLength(platformUsers) {
      this.paginationLength = platformUsers ? Math.ceil(platformUsers.length / this.filterOptions.perPage) : 1;
    },
    determineActivationStatus({
      is_approved, is_active, email_link_sent_at, is_approved_by_email,
    }) {
      const emailSentTimeDifference = moment().diff(email_link_sent_at, 'days');
      if (is_approved && !is_approved_by_email && !email_link_sent_at) {
        return this.statusButtons[0];
      }
      if (is_approved && !is_approved_by_email && email_link_sent_at) {
        if (emailSentTimeDifference < 7) {
          return this.statusButtons[1];
        }
        if (emailSentTimeDifference > 7) {
          return this.statusButtons[2];
        }
      }
      if (!is_approved) {
        return this.statusButtons[3];
      }
      return this.statusButtons[4];
    },
  },
};
</script>
