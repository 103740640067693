<template>
  <v-menu bottom left offset-y class="more-menu">
    <v-btn fab small class="pas-fab" slot="activator" icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-list>
      <v-list-tile
        v-for="(item, i) in editOptions"
        :key="i"
        @click="$emit('click', item.onClick)"

      >
        <v-list-tile-title>
          <div class="px-4 more-menu-item" :id="item.id">{{ item.title }}</div>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>


export default {
  name: 'pas-more-menu',
  props: {
    editOptions: Array,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

  .more-menu-item {
    color: $light-text !important;
    text-align: right !important;
  }
  .more-menu-item:hover {
    color: var(--primary-color) !important;
  }
</style>
