<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <!-- Risk Questionnaire -->
    <span
      v-if="!(step.status==='completed')"
    >
      This step is in progress -
      <span
        @click.prevent="completeRiskQuestionnaire"
        class="action-text"
      >
        mark as complete.
      </span>
    </span>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendRiskQuestionnaireSuccess } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'RiskQuestionnaireStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
    ]),
  },
  methods: {
    async completeRiskQuestionnaire() {
      try {
        const res = await sendRiskQuestionnaireSuccess(
          this.workflow,
          this.activeClient.client.id,
        );
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Complete Risk Questionnaire',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          // TODO: Manually update the front-end, eventually we can use a notification service to know when new data is available
          this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Error updating survey status',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
