<template>
  <PModal
    :visible="isOpen"
    :hide-backdrop="false"
    centered
    ref="modalRef"
  >
    <PRow class="flex-row-reverse">
      <PButton
        class="close-btn"
        size="sm"
        appendIcon="times"
        :isIconOnly="true"
        variant="tertiary"
        @click="onModalSubmit(activeClient.id)"
      />
    </PRow>
    <p class="subtitle bold my-2">
      Next Steps!
    </p>
    <p class="mt-2 mb-4">
      Now that you have completed the In-Person Onboarding for {{ activeClient.display_name }}, please select what you would like to do next.
    </p>
    <PRow align-h="end" class="my-2">
      <PButton
        v-if="needRiskSurvey"
        variant="outline-primary"
        @click="openNewTab(riskSurveyLink)"
      >
        Complete the Risk Questionnaire
      </pbutton>
    </PRow>
    <PRow align-h="end" class="my-2">
      <PButton
        variant="outline-primary"
        @click="onModalSubmit(activeClient.id)"
      >
        Go to {{ activeClient.display_name }}'s Profile Page
      </PButton>
    </PRow>
    <PRow align-h="end" class="my-2">
      <PButton
        v-if="jointClient?.id"
        variant="outline-primary"
        @click="onModalSubmit(jointClient.id)"
      >
        Go to {{ jointClient.display_name }}'s Profile Page
      </PButton>
    </PRow>
  </PModal>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
} from 'vue';

const emit = defineEmits(['submit']);

defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  jointClient: {
    type: Object,
    required: false,
    default: null,
  },
  activeClient: {
    type: Object,
    required: true,
  },
  needRiskSurvey: {
    type: Boolean,
    required: true,
  },
  riskSurveyLink: {
    type: String,
    required: false,
    default: '',
  },
});

const openNewTab = (link) => {
  window.open(link, '_blank');
};

const onModalSubmit = (clientId) => {
  emit('submit', { clientId });
};

</script>
