<template>
  <PContainer>
    <PRow>
      <PColumn cols="12" lg="6" offset-lg="3">
        <template v-if="!isLoadingClients">
          <FundingSearch
            :clientList="Object.values(clientList)"
            :clientId.sync="transactionParams.clientId"
          />
          <TransactionsFilters
            v-if="currentPage === 'transactions'"
            class="mb-2"
            :transactionParams="transactionParams"
            :requestType.sync="transactionParams.requestType"
            :status.sync="transactionParams.status"
            :accountType.sync="transactionParams.accountType"
          />
          <template v-if="!isLoadingTransactions">
            <FundingLanding
              v-if="currentPage === 'landing-page'"
              :status.sync="transactionParams.status"
              :landingPageInfo="landingPageInfo"
            />
            <FundingTransactions
              v-if="currentPage === 'transactions'"
              :allTransactions="allTransactions"
              :requestType.sync="transactionParams.requestType"
              :status.sync="transactionParams.status"
              :accountType.sync="transactionParams.accountType"
              @loadMoreTransactions="loadMore"
            />
          </template>
        </template>
        <template v-if="isLoadingClients || isLoadingTransactions">
          <div class="mt-5 d-flex flex-column justify-content-center align-items-center">
            <PSpinner class="mt-3 mb-3" style="width: 3rem; height: 3rem;" />
          </div>
        </template>
      </PColumn>
    </PRow>
  </PContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import FundingLanding from './FundingLanding/FundingLanding';
import FundingSearch from './FundingSearch/FundingSearch';
import FundingTransactions from './FundingTransactions/FundingTransactions';
import TransactionsFilters from './FundingTransactions/TransactionFilters/TransactionFilters';
import { fetchFundingTransactions, fetchLandingPageInfo } from '../../../services/api/FundingPage';

export default {
  name: 'funding-page',
  components: {
    FundingLanding,
    FundingSearch,
    FundingTransactions,
    TransactionsFilters,
  },
  async created() {
    this.$router.push({ path: '/funding-page', query: { page: 'landing-page' } });
    await this.$store.dispatch('fetchClientsWithPermissions');
    this.landingPageInfo = await fetchLandingPageInfo();
    this.isLoadingClients = false;
  },
  data() {
    return {
      isLoadingClients: true,
      isLoadingTransactions: false,
      isLoadingMoreTransactions: false,
      showLandingPage: true,
      showTransactions: false,
      allTransactions: null,
      landingPageInfo: null,
      showFilters: false,
      transactionParams: {
        clientId: null,
        accountType: null,
        requestType: null,
        status: null,
      },
    };
  },
  methods: {
    async fetchTransactions() {
      if (this.transactionParams.accountType || this.transactionParams.requestType || this.transactionParams.status || this.transactionParams.clientId) {
        this.$router.push({ path: '/funding-page', query: { page: 'transactions' } });
      }
      this.isLoadingTransactions = true;
      this.showFilters = true;
      this.transactionResults = await fetchFundingTransactions(this.transactionParams, 0);
      this.allTransactions = this.transactionResults.results;
      this.isLoadingTransactions = false;
      this.showLandingPage = false;
      this.showTransactions = true;
      this.nextTransactionLink = this.transactionResults.next;
      this.nextTransactionOffset = 10;
    },
    async loadMore() {
      if (this.nextTransactionLink === null) {
        // do nothing
      } else if (!this.isLoadingMoreTransactions) {
        this.isLoadingMoreTransactions = true;
        this.nextResults = await fetchFundingTransactions(this.transactionParams, this.nextTransactionOffset);
        this.isLoadingMoreTransactions = false;
        if (this.allTransactions) {
          this.allTransactions = this.allTransactions.concat(this.nextResults.results);
          this.nextTransactionLink = this.nextResults.next;
          if (this.nextResults.next !== null) {
            this.nextTransactionOffset += 10;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'clientList',
    ]),
    currentPage() {
      return this.$route.query.page;
    },
  },
  watch: {
    $route(to) {
      if (to.query.page === 'landing-page') {
        this.transactionParams.accountType = null;
        this.transactionParams.requestType = null;
        this.transactionParams.status = null;
        this.transactionParams.clientId = null;
        this.allTransactions = null;
      }
    },
    transactionParams: {
      deep: true,
      handler() {
        this.fetchTransactions();
      },
    },
  },
};
</script>
