<template>
  <v-layout
    fill-height
    row
    justify-start
    align-center
  >
    <v-flex
      text-xs-right
      mr-4
    > 
      <span class="strong-text form-label">Assigned To:</span>
    </v-flex>
    <v-flex>
      <v-layout row nowrap justify-start>
        <v-flex :style="{'maxWidth': '60px'}">
          <pas-avatar
            :avatar="getAvatar('platform_users',teamMembers[this.workflow.assigned_user])"
          ></pas-avatar>
        </v-flex>
        <pas-reassign-workflow
          :workflow="workflow"
          @reassignSuccess="$emit('reassignSuccess')"
          class="mt-2"
        >
        </pas-reassign-workflow>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

import { getAvatar } from 'Services/helpers/files'

export default {
  name: 'assign-to',
  data() {
    return {
      getAvatar
    }
  },
  props: {
    workflow: Object
  },
  computed: {
    teamMembers() {
      const teamMembers = {};
      this.$store.getters.teamMembers.forEach(m => teamMembers[m.ac_mongo_id] = m.id)
      return teamMembers
    }
  },
};
</script>

<style>

</style>
