<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent
    >
      <pas-page-subtitle v-if="!noTitle" class="mb-3 px-2 client-edit-section">Shareholder Preference</pas-page-subtitle>

      <div class="strong-text mb-1 px-2">Disclosure of Information</div>

      <p :class="[clientType === 'person' ? 'greyed-out' : '', 'manual-checkbox-label']">
      <v-radio-group
        class="px-2 financial-profile-pref-lang"
        v-model="beneficialOwnershipDisc"
        :disabled="!canEdit"
        column
      >
        <v-radio :color="primaryColor" :label="`CLIENT OBJECTS to you disclosing the information`" :value="`2`"></v-radio>
        <v-radio :color="primaryColor" :label="`CLIENT DOES NOT OBJECT to you disclosing the information`" :value="`1`"></v-radio>
      </v-radio-group>
      </p>

      <div class="strong-text mb-1 px-2">Receiving security holder materials</div>

      <p :class="[clientType === 'person' ? 'greyed-out' : '', 'manual-checkbox-label']">
      <v-radio-group
        class="px-2 financial-profile-pref-lang"
        v-model="shareholderCommPref"
        :disabled="!canEdit"
        column
      >
        <v-radio :color="primaryColor" :label="`CLIENT WANTS to receive ALL security holder materials`" :value="`3`"></v-radio>
        <v-radio :color="primaryColor" :label="`CLIENT DECLINES to receive security holder materials`" :value="`1`"></v-radio>
        <v-radio :color="primaryColor" :label="`CLIENT WANTS to receive ONLY proxy-related materials sent in connection with a special meeting`" :value="`2`"></v-radio>
      </v-radio-group>
      </p>

      <div class="strong-text mb-1 px-2">Preferred Language</div>
      <div>
      <div v-if="isMultilingualEnabled">
        <v-radio-group
          class="px-2 financial-profile-pref-lang"
          v-model="preferredLang"
          :disabled="!canEdit"
          row
        >
          <v-radio
            v-for="(item, i) in languages"
            :key="i"
            :color="primaryColor"
            :label="item.name"
            :value="item.id"
          ></v-radio>
        </v-radio-group>
      </div>
      <!-- TODO: remove preferredLanguage completely, together with datahub client.preferred_lang field once Multilingual is released. -->
      <div v-else>
        <v-radio-group
          class="px-2 financial-profile-pref-lang mb-1"
          v-model="preferredLanguage"
          :disabled="!canEdit"
          row
        >
          <v-radio :color="primaryColor" :label="`EN`" :value="`EN`"></v-radio>
          <v-radio :color="primaryColor" :label="`FR`" :value="`FR`"></v-radio>
        </v-radio-group>
      </div>
      </div>

      <div v-if="isWetSignatureFeatureEnabled">
        <div class="strong-text mb-1 px-2">Client Preferred Signature Type</div>
        <v-radio-group
          class="px-2 financial-profile-pref-lang"
          v-model="preferredSignatureType"
          :disabled="!canEdit"
          column
        >
          <v-radio
            v-for="(item, i) in allSignatureTypes"
            :key="i"
            :color="primaryColor"
            :label="item.description"
            :value="item.key"
          ></v-radio>
        </v-radio-group>
      </div>

      <pas-button
        class="primary-btn wide-btn mx-2"
        @click="savePreferences"
        :processing="processingPreferences"
        v-if="!modalMode && canEdit"
      >Save</pas-button>
    </v-form>
  </div>
</template>

<script>
import v from 'Services/validation';
import { mapGetters } from 'vuex';

export default {
  name: 'shareholder-pref-modal',
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      beneficialOwnershipDisc: 0,
      shareholderCommPref: 0,
      preferredLanguage: null,
      preferredLang: null,
      v,
      processingPreferences: false,
      isInit: true,
      isEdited: false,
      preferredSignatureType: null,
    };
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'allSignatureTypes',
      'languages',
    ]),
    canEdit() {
      // until we enable the client page to modify these value on client maintenance, we must be able
      // to change them, event if the client is active
      // once usable, replace canEdit by editable
      return true;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    clientType() {
      return this.activeClient.client && this.activeClient.client.client_type;
    },
    disabled() {
      return !this.modalMode && this.clientType === 'person';
    },
    shareholderPrefChange() {
      const { beneficialOwnershipDisc, shareholderCommPref, preferredLanguage } = this;
      return { beneficialOwnershipDisc, shareholderCommPref, preferredLanguage };
    },
    isWetSignatureFeatureEnabled() {
      return this.hasFeatureFlag('enable_wet_signature');
    },
    isMultilingualEnabled() {
      return this.hasFeatureFlag('multilingual_investor_comms');
    },
  },
  methods: {
    handleResponse(type) {
      const text = `${type === 'fail' ? 'Failed to update' : 'Successfully updated'} Shareholder Preferences`;
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type);
    },
    async savePreferences() {
      if (!this.$refs.form.validate()) return false;
      this.processingPreferences = true;
      let preferred_signature_type = null;
      if (this.preferredSignatureType) {
        preferred_signature_type = this.allSignatureTypes.find((tp) => tp.key === this.preferredSignatureType).id;
      }
      const data = {
        shareholder_comm_pref: this.shareholderCommPref,
        beneficial_ownership_disc: this.beneficialOwnershipDisc === 0 ? 1 : this.beneficialOwnershipDisc,
        preferred_lang: this.preferredLanguage || '0',
        preferred_signature_type,
        id: this.activeClient.client.id,
        client_type: this.clientType,
        preferred_language: this.preferredLang,
      };
      await this.$store.dispatch('updateClient', data)
        .then((res) => this.handleResponse(res.id ? 'success' : 'fail'))
        .catch((err) => this.handleResponse('fail'));
      this.processingPreferences = false;
    },
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        let client = {};
        if (!this.isEdited) {
          client = { ...this.activeClient };
          this.preferredLanguage = client.client.preferred_lang.toUpperCase() || null;
          this.beneficialOwnershipDisc = client.client.beneficial_ownership_disc;
          this.shareholderCommPref = client.client.shareholder_comm_pref;
          this.preferredSignatureType = client.client.preferred_signature_type;
          this.preferredLang = client.client.preferred_language;
        }
      },
      deep: true,
    },
    shareholderPrefChange: {
      handler(val, oldVal) {
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
    },
  },
  async mounted() {
    this.isInit = true;
    this.isEdited = false;
    const client = { ...this.activeClient };
    if (!this.allSignatureTypes?.length) await this.$store.dispatch('fetchAllSigntureTypes');
    if (!this.languages?.length) await this.$store.dispatch('fetchAllLanguages');
    if (client.client) {
      this.preferredLanguage = client.client.preferred_lang.toUpperCase() || null;
      this.beneficialOwnershipDisc = client.client.beneficial_ownership_disc;
      this.shareholderCommPref = client.client.shareholder_comm_pref ? client.client.shareholder_comm_pref : '3';
      this.preferredSignatureType = client.client.preferred_signature_type;
      this.preferredLang = client.client.preferred_language;
    }
  },
};
</script>

<style lang="scss">
.checkbox-left{
  width: 30px;
}
.v-input.checkbox-left.v-input--selection-controls.v-input--checkbox.v-input--is-disabled.theme--light {
  margin-top: 0px !important;
}
</style>
