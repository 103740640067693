<template>
  <PasList
    :columns="columns"
    :items="documents"
    :itemName="'documents'"
    :loading="loading"
    @toggleSort="toggleSort($event)"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height align-center>
        <v-flex xs1>
          <v-layout justify-center>
            <img height="32px" width="32px" :src="getIcon(item.file_type)">
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <div class="p-text">{{item.document_name}}</div>
        </v-flex>
        <v-flex xs3>
          <div class="p-text">{{documentCategory[item.document_type]}}</div>
        </v-flex>
        <v-flex xs2>
          <div class="p-text">{{documentSource[item.document_source]}}</div>
        </v-flex>
        <v-flex xs2>
          <v-layout row align-center>
          <div class="p-text">{{moment(item.archived_at).format('YYYY/MM/DD')}}</div>
          <v-spacer/>
          <pas-more-menu
            @click="$emit('documentAction',$event, item.id)"
            :editOptions="editOptions"
          />
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </PasList>
</template>

<script>

import { mapGetters } from 'vuex';
import { DOCUMENT_SOURCE, DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE } from 'Services/constants';

import pdf from 'Assets/icons/pdf.svg';
import doc from 'Assets/icons/doc.svg';
import jpeg from 'Assets/icons/jpeg.svg';
import png from 'Assets/icons/png.svg';
import excel from 'Assets/icons/excel.svg';
import other from 'Assets/icons/other.svg';

export default {
  name: 'DocumentList',
  props: {
    documents: Array,
    sortDir: Number,
    loading: Boolean,
  },
  data() {
    return {
      columns: [
        {
          header: 'Title', size: 'xs5', isSortable: true, sortValue: 1,
        },
        {
          header: 'Category', size: 'xs3', isSortable: true, sortValue: 1,
        },
        {
          header: 'Upload Type', size: 'xs2', isSortable: true, sortValue: 1,
        },
        {
          header: 'Archived Date', size: 'xs2', isSortable: true, sortValue: 1,
        },
      ],
      pdf,
      doc,
      jpeg,
      png,
      excel,
      other,
      documentCategory: DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE,
      documentSource: DOCUMENT_SOURCE,
    };
  },
  computed: {
    ...mapGetters([
      'canEditDocuments',
      'canDeleteDocuments',
    ]),
    editOptions() {
      if (this.canDeleteDocuments) {
        return [
          { title: 'Unarchive', onClick: 'unarchive' },
          { title: 'Download', onClick: 'download' },
          { title: 'Delete', onClick: 'delete' },
        ];
      } else if (this.canEditDocuments && !this.canDeleteDocuments) {
        return [
          { title: 'Unarchive', onClick: 'unarchive' },
          { title: 'Download', onClick: 'download' },
        ];
      } else if (!this.canEditDocuments && !this.canDeleteDocuments) {
        return [
          { title: 'Download', onClick: 'download' },
        ];
      }
      else {
        return [];
      }
    },
  },
  methods: {
    getIcon(fileType) {
      return [this.pdf, this.doc, this.jpeg, this.png, this.excel, this.other][fileType];
    },
    toggleSort(item) {
      this.columns = this.columns.map((c) => {
        if (c.header === item.header) {
          c.sortValue = item.sortValue ? item.sortValue * -1 : 0;
        }
        return c;
      });
      const newSort = this.columns.find((c) => c.header === item.header);
      if (!newSort) return;
      this.$emit('toggleSort', { archivedSortBy: newSort.header, archivedSortDir: newSort.sortValue });
    },
  },
};
</script>
