<template>
    <div>
        <pas-dropzone
            ref="pasDropzone"
            :id="'bank-drapzone'"
            :options="dropzoneOptions"
        ></pas-dropzone>
        <v-layout v-if="file.progress" justify-center row fill-height mt-3>
            <v-flex>
                <div class="strong-text bank-file-name">Void Cheque or Bank Statement</div>
            </v-flex>
            <v-flex xs10 pr-4>
                <v-progress-linear :value="file.progress" :color="primaryColor" class="docs-progress-bar"></v-progress-linear>
            </v-flex>
            <v-flex text-xs-center pr-2 class="bank-file-progress">
                {{typeof file.progress === "string" ? `UPLOAD ${file.progress.toUpperCase()}` : `${Math.round(parseFloat(file.progress))}%`}}
            </v-flex>
            <v-flex  v-if="typeof file.progress === 'number'">
                <pas-icon-circle
                    @click="removeFile(file.file)"
                    :style="{marginTop: '8px'}"
                    :theme="`pas`"
                >
                    <v-icon>mdi-close</v-icon>
                </pas-icon-circle>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

import { DOCUMENT_TYPES, DOCUMENT_SOURCE } from 'Services/constants';
import _ from 'lodash';

export default {
  name: 'upload-bank-cheque',
  data() {
    const that = this;
    const isFcc = this.$store.getters.custodian === 'fcc';
    const acceptedFiles = isFcc ? '.pdf' : '.png,.jpeg,.jpg,.pdf';
    return {
      dropzoneOptions: {
        url: '/advisor_portal/upload_document',
        init() {
          this.on('sending', (file, xhr, formData) => {
            that.$emit('disableSave', true);
            formData.append('document_type', DOCUMENT_TYPES.indexOf('Void Cheque or Bank Statement'));
            formData.append('client_id', that.activeClient.client.id);
            formData.append('void_cheque', true);
            formData.append('document_name', 'Void Cheque or Bank Statement');
            formData.append('document_source', that.documentSource.Manual);
            that.file = { file, progress: 0 };
          });
          this.on('uploadprogress', (file, progress) => {
            that.file.progress = progress;
          });
          this.on('success', (file, response) => {
            if (response.message !== 'Success') {
              that.$store.dispatch('setSnackbar', { text: 'Oops... Something went wrong, please try upoading the file again', type: 'fail' });
              that.$store.dispatch('flipSnackbarSwitch');
              that.removeFile(file);
              that.file = {};
              that.$emit('disableSave', false);
            } else {
              that.$emit('addVoidCheque', response.doc_id);
              that.$emit('voidChequeExt', response.ext);
              that.$emit('ocr', response.ocr_results);
              that.$store.dispatch('setSnackbar', { text: 'The file was successfully uploaded', type: 'success' });
              that.$emit('disableSave', false);
              that.$store.dispatch('flipSnackbarSwitch');
            }
          });
          this.on('error', (file, response) => {
            that.$store.dispatch('setSnackbar', { text: 'Oops... Something went wrong, please try upoading the file again', type: 'fail' });
            that.$emit('disableSave', false);
            that.$store.dispatch('flipSnackbarSwitch');
            that.removeFile(file);
            that.file = {};
            console.log('upload responseErr', response);
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: 3,	// MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 3000000,	// Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles,
      },
      file: {},
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  methods: {
    removeFile(file) {
      this.$refs.pasDropzone.$refs.dropzone.removeFile(file);
      this.file = {};
      this.$emit('removeVoidCheque');
    },
  },
};
</script>

<style lang="scss">

#bank-drapzone {
    min-height: 140px !important;
}
.bank-file-name{
    width: 130px;
}
.bank-file-progress{
    font-size: 9px;
    padding-top: 11px;
}

</style>
