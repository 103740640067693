import AddItem from './AddItem';
import Button from './Button';
import FloatingActionButton from './FloatingActionButton';
import IconCircle from './IconCircle';
import MoreMenu from './MoreMenu';
import PageHeader from './PageHeader';
import SelectableChip from './SelectableChip';
import UpdateButton from './UpdateButton';

export default {
  install(Vue) {
    Vue.component('PasAddItem', AddItem);
    Vue.component('PasButton', Button);
    Vue.component('PasFab', FloatingActionButton);
    Vue.component('PasIconCircle', IconCircle);
    Vue.component('PasMoreMenu', MoreMenu);
    Vue.component('PasPageHeader', PageHeader);
    Vue.component('PasSelectableChip', SelectableChip);
    Vue.component('PasUpdatebutton', UpdateButton);
  },
};
