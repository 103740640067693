import api from 'Services/apiV2';

export const fetchPrograms = () => api.get('/programs/');
export const fetchProgramById = (programID) => api.get(`/programs/${programID}/`);
export const fetchRepCodes = () => api.get('/rep_codes/', { limit: 1000 });
export const fetchTeams = (data) => api.get('/get_teams/', data);
export const fetchTeamMembers = (data) => api.get('/team_members/', data);
export const fetchTeamMandates = (teamID) => api.get(`/teams/${teamID}/mandates/`);
export const fetchTeamLanguages = (teamID) => api.get(`/teams/${teamID}/languages/`);
export const fetchTeamById = (teamID) => api.get(`/teams/${teamID}/`);
