import api from 'Services/api.js';

const state = {

};

const mutations = {

};

const actions = {
  getAccountsByClientID({}, clientID) {
    return api.get(`/datahub/clients/${clientID}/accounts/`)
      .then((res) => res)
      .catch((err) => ({ err }));
  },
  createAccount({ }, account) {
    return api.post('/datahub/accounts/', account)
      .then((res) => res)
      .catch((err) => ({ error: err }));
  },
  updateAccount({ }, account) {
    return api.patch(`/datahub/accounts/${account.id}/`, account)
      .then((res) => res)
      .catch((err) => ({ err }));
  },
  deleteAccount({}, accountID) {
    return api.deleteCall(`/datahub/accounts/${accountID}/`)
      .then((res) => res)
      .catch((err) => ({ err }));
  },
  // beneficiaries for registered accounts
  addBeneficiary({}, data) {
    return 	api.post(`/datahub/accounts/${data.account}/beneficiaries/`, data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  getBeneficiaries({}, accountID) {
    return 	api.get(`/datahub/accounts/${accountID}/beneficiaries/`)
      .then((res) => {
        if (res.results && res.results.length) {
          return res.results.filter((r) => r.client).map((r) => {
            r.account = accountID;
            return r;
          });
        }
        return false;
      })
      .catch((err) => console.log('err', err));
  },
  getRESPBeneficiaries({}, accountID) {
    return api.get(`/datahub/accounts/${accountID}/resp_beneficiaries/`)
      .then((res) => {
        if (res.results && res.results.length) {
          return res.results.map((r) => {
            r.account = accountID;
            r.priority = 'P';
            r.client = r.beneficiary_client;
            return r;
          });
        }
        return false;
      })
      .catch((err) => console.log('err', err));
  },
  updateBeneficiary({}, data) {
    return 	api.put(`/datahub/accounts/${data.account}/beneficiaries/${data.id}/`, data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  deleteBeneficiary({}, data) {
    return 	 api.deleteCall(`/datahub/accounts/${data.account}/beneficiaries/${data.id}/`)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  addRESPgrants({}, data) {
    return 	 api.post(`/datahub/accounts/${data.account}/resp_grants/`, data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  getAccountValueByAccountID({}, id) {
    return api.get('/datahub/get_total_market_value',
      'context=account',
      `value=${id}`)
      .then((response) => response)
      .catch((err) => console.log('err', err));
  },
};

export default {
  state,
  mutations,
  actions,
};
