<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 text-xs-center mb-5>
        <div>
          Please fill out the following information for {{beneficiary.client_details[0].display_name}}
        </div>
      </v-flex>
    </v-layout>
    <PasPageSubtitle>
      {{beneficiary.client_details[0].display_name}}'s Details
    </PasPageSubtitle>
    <v-form
      lazy-validation
      ref="missingInfoForm"
      @submit.prevent=""
    >
      <v-layout>
        <v-flex mr-3 class="max-width-50">
          <div class="strong-text form-label">
            SIN
          </div>
          <PasInputField
            v-model="SIN"
            placeholder="Social insurance number"
            :rules="[v.isNumeric(),v.specifiedChars(9),v.isSINValid()]"
            :maxLength=9
          />
        </v-flex>
        <v-flex mr-3 class="max-width-50">
          <div class="strong-text form-label">
            Date of Birth
          </div>
          <PasDatepicker
            :value="dateOfBirth"
            @input="dateOfBirth = $event"
            iconLocation="before"
            label="Select Date"
            :key="`date-field`"
          />
        </v-flex>
        <v-flex mr-3 class="max-width-50">
          <div class="strong-text form-label">
            Gender
            <v-tooltip top color="white" max-width="20%" v-if="custodian === 'fcc'">
              <v-icon
                slot="activator"
                size="15px"
                :style="{ 'margin-right': '10px' }"
                color="grey"
              >mdi-alert-circle</v-icon>
              <span :style="{'color': 'black'}">
                {{fccGenderMessage}}
              </span>
            </v-tooltip>
          </div>
          <v-radio-group
            v-model="gender"
            :rules="[Object.values(genderOptions).includes(gender) || `Please select from the available options.`]"
          >
            <v-layout align-start child-flex row>
              <v-radio
                v-for="(item, i) in genderOptions"
                :key="i"
                color="#c48f3e"
                :label="item"
                :value="item"
              ></v-radio>
            </v-layout>
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-layout justify-end row>
        <v-flex>
          <v-layout row justify-start>
            <PasCheckbox
              :value="isPrimaryCaregiver"
              @change="isPrimaryCaregiver = $event"
              class="shrink mt-0 pt-0 mr-3"
            />
            <div class="mt-1">{{activeClient.client.display_name}} is the primary caregiver</div>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout justify-start row v-if="!isPrimaryCaregiver && custodian === 'fcc'">
        <v-flex class="max-width-50">
          <div class="strong-text form-label">
            Primary care giver
          </div>
          <div class="mb-1">
            If the person is not yet a client, you can
          <span @click="openNewClientModal()" class="action-link underline">add a new client.</span>
          </div>
          <!-- Combobox with all clients -->
          <pas-autocomplete
            v-model="primaryCareGiver"
            class="mb-3"
            :items="clients"
            :rules="[ v => !!v || 'Please select a name from the list']"
            :prependInnerIcon="`search`"
            :placeholder="`Type the name of the client...`"
            :returnObject='true'
          ></pas-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout v-if="!isPrimaryCaregiver && custodian !== 'fcc'">
        <v-flex ml-2 mb-3 sx6 class="max-width-50">
            <v-layout column>
              <v-flex class="form-label strong-text">Parent/Guardian - First Name<sup>*</sup></v-flex>
              <v-flex>
                <PasInputField
                  :rules="[v.isRequired()]"
                  v-model="guardianFirstName"
                />
              </v-flex>
            </v-layout>
        </v-flex>
        <v-flex ml-2 mb-3 sx6 class="max-width-50">
          <v-layout column>
              <v-flex class="form-label strong-text">Parent/Guardian - Last Name<sup>*</sup></v-flex>
            <v-flex>
              <PasInputField
                :rules="[v.isRequired()]"
                v-model="guardianLastName"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout v-if="requiresBeneAddress">
        <AddEditAddress
          :address="beneficiaryAddress"
          :client="beneficiary.client_details[0]"
          @updateAddress="updateAddress($event)"
          ref="addEditAddress"
          noButtons
        />
      </v-layout>
      <v-layout mt-5>
          <PasButton theme="inverted" class="px-5" @click="$emit('back')">
            Back
          </PasButton>
          <v-spacer/>
          <PasButton class="px-5" @click="save">
            Save and Continue
          </PasButton>
      </v-layout>
    </v-form>
    <add-new-client
        :isOpen="newClientModalOpen && !hasFeatureFlag('client_data_hierarchy')"
        @close="newClientModalOpen = false"
      ></add-new-client>
      <AddNewClientNoCall
        :isOpen="newClientModalOpen && hasFeatureFlag('client_data_hierarchy')"
        @close="newClientModalOpen = false"
      />
  </div>
</template>
<script>
import v from 'Services/validation';
import api from 'Services/apiV2';
import AddEditAddress from 'Components/Shared/Addresses/AddEditAddress';
import AddNewClientNoCall from 'Components/Modals/AddNewClientNoCall';
import AddNewClient from 'Components/Modals/AddNewClient';
import { mapGetters } from 'vuex';
import { GENDERS, FCC_GENDERS, WARNING_MESSAGES } from 'Services/constants';

export default {
  name: 'confirm-beneficiary-info',
  components: {
    AddEditAddress,
    AddNewClient,
    AddNewClientNoCall,
  },
  props: {
    beneficiary: Object,
  },
  mounted() {
    this.populateBeneficiary();
  },
  data() {
    return {
      v,
      beneficiaryAddress: {},
      isPrimaryCaregiver: this.beneficiary.isPrimaryCaregiver,
      fccGenderMessage: WARNING_MESSAGES.fcc_genders,
      newClientModalOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'custodian',
      'hasFeatureFlag',
      'activeClient',
      'advisorTeams',
    ]),
    genderOptions() {
      const respGenderOptions = this.custodian === 'fcc' ? FCC_GENDERS : GENDERS;

      return {
        male: respGenderOptions.male,
        female: respGenderOptions.female,
      };
    },
    requiresBeneAddress() {
      return !this.isPrimaryCaregiver || this.custodian === 'fcc';
    },
    guardianFirstName: {
      get() {
        return this.beneficiary.guardianFirstName;
      },
      set(newVal) {
        this.beneficiary.guardianFirstName = newVal;
      },
    },
    guardianLastName: {
      get() {
        return this.beneficiary.guardianLastName;
      },
      set(newVal) {
        this.beneficiary.guardianLastName = newVal;
      },
    },
    primaryCareGiver: {
      get() {
        return this.beneficiary.primaryCareGiver;
      },
      set(newVal) {
        this.beneficiary.primaryCareGiver = newVal;
      },
    },
    SIN: {
      get() {
        return this.beneficiary.client_details[1].person_details[0].SIN;
      },
      set(newVal) {
        this.beneficiary.client_details[1].person_details[0].SIN = newVal;
      },
    },
    dateOfBirth: {
      get() {
        return this.beneficiary.client_details[1].person_details[0].date_of_birth;
      },
      set(newVal) {
        this.beneficiary.client_details[1].person_details[0].date_of_birth = newVal;
      },
    },
    gender: {
      get() {
        return this.genderOptions[this.beneficiary.client_details[1].person_details[0].sex];
      },
      set(newVal) {
        this.beneficiary.client_details[1].person_details[0].sex = newVal.toLowerCase();
      },
    },
    clients() {
      if (this.$store.state.clients.list[0] === 'loading') return [];

      return this.$store.state.clients.list
        .filter((client) => client.client_type === 'person' && client.id !== this.activeClient.id)
        .sort((clientA, clientB) => {
          const compareLast_name = clientA.person.last_name.localeCompare(clientB.person.last_name);
          const compareFirst_name = clientA.person.first_name.localeCompare(clientB.person.first_name);
          return compareLast_name || compareFirst_name;
        })
        .map((client) => {
          const { id, display_name } = client;
          return {
            text: display_name
              ? display_name + (client.client_no_dataphile ? ` (${client.client_no_dataphile})` : '')
              : '',
            value: id,
          };
        });
    },
  },
  methods: {
    save() {
      if (!this.$refs.missingInfoForm.validate()) return;
      if (this.requiresBeneAddress) {
        this.$refs.addEditAddress.updateAddress();
        this.saveAddress().then((res) => { if (res) this.saveBeneficiary(); });
        return;
      }
      this.saveBeneficiary();
    },
    saveAddress() {
      if (!this.hasAddress) {
        return this.$store.dispatch('addAddress', this.beneficiaryAddress);
      }
      return this.$store.dispatch('updateAddress', this.beneficiaryAddress);
    },
    updateAddress(address) {
      if (!this.hasAddress) {
        address.is_residential = true;
        address.is_mailing = true;
        address.back_office = true;
      }
      this.beneficiaryAddress = address;
    },
    saveBeneficiary() {
      this.beneficiary.isPrimaryCaregiver = this.isPrimaryCaregiver;
      this.$emit('save', this.beneficiary);
    },
    getBeneficiaryAddress() {
      const beneficiaryClientID = this.beneficiary.client_details[0].id;
      return api.get(`/clients/${beneficiaryClientID}/addresses`).then((res) => {
        this.beneficiaryAddress = res.addresses.find((address) => address.is_residential) || {};
        this.hasAddress = !_.isEmpty(this.beneficiaryAddress);
      });
    },
    populateBeneficiary() {
      this.isPrimaryCaregiver = this.beneficiary.isPrimaryCaregiver;
      this.getBeneficiaryAddress().then(() => this.$refs.addEditAddress.populateForm());
    },
    openNewClientModal() {
      if (this.hasFeatureFlag('client_data_hierarchy') && !this.advisorTeams.length) {
        this.CDHIneligibleDialog = true;
        return;
      }
      this.newClientModalOpen = true;
    },
  },
  watch: {
    beneficiary: {
      handler() {
        this.populateBeneficiary();
      },
    },
  },
};
</script>
