
<template>
  <pas-fullscreen-modal @close="closeModal" :isOpen="isOpen">
    <div v-if="screen !== 1" class="strong-text back-doodle" @click="goBack">&larr; Back</div>

    <v-container class="modal-container">

      <v-layout column>
        <v-flex xs12 text-xs-center mb-3>
          <pas-page-title v-if="screen !== 1.5">
            {{ screen === 1 ? 'Investment Account Setup' : screen === 11 ? 'Document Checklist' : 'Account Details'}}
          </pas-page-title>
        </v-flex>
        <v-flex xs12 text-xs-center mb-4>
          <span v-if="!tempAccounts.length && screen === 1">Let's start by adding a corporate cash account.</span>
          <span v-if="tempAccounts.length && screen === 1"> Here's a list of the newly created uncomfirmed accounts.</span>
          <span v-if="screen !== 1 && screen !== 11 && screen !== 1.5">
            Your response to these questions is required to meet various regulatory requirements.
          </span>
          <span v-if="screen === 11">
            You can validate the documents included in the package.
          </span>
        </v-flex>
      </v-layout>

      <template v-if="screen === 1">
        <div>
          <v-layout mt-1 mb-4 row nowrap class="accounts-table-header-layout">
            <v-flex xs2 class="table-header"></v-flex>
            <v-flex xs3 class="table-header">Type</v-flex>
            <v-flex xs3 class="table-header">Account Name</v-flex>
            <v-flex xs3></v-flex>
            <v-flex xs3 class="table-header">Currency</v-flex>
            <v-flex xs3 class="table-header">Add-ons</v-flex>
          </v-layout>
          <v-list pt-3 mb-5 class="pas-list-wrapper">
            <template v-for="(account, index) in tempAccountsEdit">
              <v-card
                :key="`tile-${index}`"
                :class="{'my-2': true, 'list-card': true }"
              >
                <v-layout row fill-height justify-center align-center class="accounts-row-layout">
                  <v-flex xs2 text-xs-center>
                    <pas-icon-circle
                    theme="grey"
                    @click="removeAccount(index)"
                    :style="{ margin: 'auto !important' }"
                    >
                      <v-icon>mdi-close</v-icon>
                    </pas-icon-circle>
                  </v-flex>
                  <v-flex xs3>
                    <v-layout row wrap>
                      <v-flex xs12  :style="{height: '36px !important'}">
                        <pas-drop-down
                          :items="['CORP CASH','CORP CASH']"
                          :value="'CORP CASH'"
                          :disabled="account.id ? true : false"
                        ></pas-drop-down>
                      </v-flex>
                      <v-flex v-if="!account.id && !existingAccounts">
                        <div class="action-text">
                          <span v-if="!account.corp_roles" class="action-text" @click="defineRolesModal = true">
                            + Define Roles
                          </span>
                          <span v-else class="action-text" @click="defineRolesModal = true">Edit Roless</span>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs3>
                    <pas-editable-span
                      :value="account.nickname"
                      @editSaved="$set(account, 'nickname', $event)"
                      :rules="[v.isRequired()]"
                      :editIcon="''"
                      :disabled="account.id ? true : false"
                    ></pas-editable-span>
                  </v-flex>
                  <v-flex xs3>
                  </v-flex>
                  <v-flex xs3>
                    <pas-button-toggle
                      :value="account.currency === 'CAD' ? 0 : 1"
                      @input="$event === 0 ? account.currency = 'CAD' : account.currency = 'USD'"
                      :class="{'pas-toggle': true,
                              'flexColumn': ['xs','sm','md'].includes(breakpoint),
                              'btnBottomMargin': ['xs','sm','md'].includes(breakpoint)
                              }"
                      :mandatory="true"
                      :disabled="account.id ? true : false"
                    >
                      <v-btn :disabled="account.id ? true : false" class="px-4" flat>CAD</v-btn>
                      <v-btn :disabled="account.id ? true : false" class="px-4" flat>USD</v-btn>
                    </pas-button-toggle>
                  </v-flex>
                  <v-flex xs3>
                    <pas-button-toggle
                      :multiple="true"
                      :valueArray="[account.margin_status ? 0 : null, account.options_status ? 1 : null]"
                      @input="setAccountOptions($event, account)"
                      :class="{'pas-toggle': true,
                                'flexColumn': ['xs','sm','md'].includes(breakpoint),
                                'btnBottomMargin': ['xs','sm','md'].includes(breakpoint)
                              }"
                      :disabled="account.id ? true : false"
                    >
                      <v-btn class="px-4" :disabled="account.id ? true : false" flat>Margin</v-btn>
                      <v-btn class="px-4" :disabled="account.id ? true : false" flat>Options</v-btn>
                    </pas-button-toggle>
                  </v-flex>
                </v-layout>
              </v-card>
            </template>
          </v-list>
        </div>
        <v-layout mt-3>
          <v-flex>
            <pas-add-item
              :text="'Add Another'"
              @click="addAccount"
            ></pas-add-item>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 1.5">
        <AddToFeeGroup @continueClick="continueClick"/>
      </template>

      <template v-if="screen === 2">
        <v-layout px-4 column>
          <v-flex xs12 mt-4 mb-3>
            <div class="strong-text">
              Who is the primary contact for the legal entity?
            </div>
            <span>The primary contact person will act as the authorized representative for this corporate onboarding.</span>
          </v-flex>
          <v-flex>
            <v-radio-group
              class="px-2 financial-profile-pref-lang"
              v-model="authorizedSignatory"
              column
            >
              <template v-for="(signatory, i) in authorizedSignatories">
                <v-radio
                  :color="primaryColor"
                  :label="clientList[signatory.client]"
                  :value="signatory.client"
                ></v-radio>
              </template>
            </v-radio-group>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 3">
        <v-layout px-4 column>
          <v-flex xs12 mt-4 mb-3>
            <div class="strong-text">
              Will any persons other than the ones that have been assigned roles have a financial interest in this account?
            </div>
          </v-flex>
          <v-flex>
            <v-switch
              :color="primaryColor"
              v-model="financialInterest"
              :label="financialInterest ? 'Yes' : 'No'"
            ></v-switch>
          </v-flex>
          <v-flex v-if="financialInterest">
            <template v-for="(finInterest, index) in financialInterestOptions">
              <v-layout row wrap>
                <v-flex xs3 md2 pt-1 my-2>
                  <span>{{clientList[finInterest.client]}}</span>
                </v-flex>
                <v-flex my-2 v-if="!finInterest.financial_interest">
                  <pas-add-item
                    class="mt-2 px-0"
                    :text="'Select Relationship'"
                    @click="openSelectRelationshipModal(finInterest.client)"
                  ></pas-add-item>
                </v-flex>
                <v-flex my-3 v-if="finInterest.financial_interest">
                  <div>
                    <span>{{getFinancialInterestText(finInterest.financial_interest)}} </span>
                    <span>{{finInterest.financial_interest.length === 1 ? 'has' : 'have'}} </span>
                    <span>financial interest in {{activeClient.client.display_name}}.</span>
                    <span
                      @click="openSelectRelationshipModal(finInterest.client)"
                      class="action-link underline"
                    > Edit </span>
                  </div>
                </v-flex>
              </v-layout>
            </template>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 4">
        <v-layout column>
          <v-flex xs12 px-4 mt-4 mb-3>
            <div class="strong-text">
              Does the legal entity have, or control the trading of,
              or have a financial interest in, any accounts with other investment firms?
              If yes, provide details:
            </div>
            <v-switch
              :color="primaryColor"
              v-model="other_accounts"
              @change="toggleOtherAccounts"
              :label="other_accounts ? 'Yes' : 'No'"
            ></v-switch>
          </v-flex>
          <v-flex v-if="other_accounts">
            <v-form
              ref="otherAccounts"
              lazy-validation
              @submit.prevent
            >
              <v-layout column>
                <template v-for="(item, i) in otherAccounts">
                  <v-flex py-3>
                    <v-layout row>
                      <v-flex xs7 md4 pl-4>
                        <pas-icon-circle
                          theme="grey"
                          @click="otherAccounts.splice(i, 1)"
                          :style="{position: 'absolute', top: '45px', left: '0px'}"
                        >
                          <v-icon>mdi-close</v-icon>
                        </pas-icon-circle>
                        <div class="strong-text mb-3">Firm Name</div>
                        <pas-autocomplete
                          :rules="[v.isRequired('Please select company your selection')]"
                          :placeholder="`Select Company...`"
                          :items="firms"
                          :value="item.company_name"
                          @input="item.company_name = $event"
                        ></pas-autocomplete>
                      </v-flex>
                      <v-flex xs5 md4 lg4 pl-4 v-if="item.company_name === 'Other'">
                        <div class="strong-text mb-3">Enter the firm name</div>
                        <pas-input-field
                          v-model="item.company_name_details"
                          :placeholder="'Firm Name...'"
                        ></pas-input-field>
                      </v-flex>
                      <v-flex xs5 md4 lg4 pl-4>
                        <div class="strong-text mb-3">Account Number (Optional)</div>
                        <pas-input-field
                          v-model="item.account_number"
                          :placeholder="'Account Number...'"
                        ></pas-input-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-layout>
            </v-form>
          </v-flex>
          <v-flex>
            <pas-add-item
              v-if="other_accounts"
              class="mt-2 px-4"
              :text="'Add Another Account'"
              @click="addOtherAccount"
            ></pas-add-item>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 5">
        <v-layout column>
          <v-flex xs12 px-4 mt-4 mb-3>
            <div class="strong-text">
              Does the legal entity have another account with CGWM?
              If yes, please provide the account number.
            </div>
            <v-switch
              :color="primaryColor"
              v-model="other_cg_accounts"
              @change="toggleCanaccord"
              :label="other_cg_accounts ? 'Yes' : 'No'"
            ></v-switch>
          </v-flex>
            <v-flex v-if="other_cg_accounts">
              <v-layout column>
                <template v-for="(item, i) in canaccordAccounts">
                  <v-flex py-3>
                    <v-layout row>
                      <v-flex xs7 md4 px-4>
                        <pas-icon-circle
                          theme="grey"
                          @click="canaccordAccounts.splice(i, 1)"
                          :style="{position: 'absolute', top: '45px', left: '0px'}"
                        >
                          <v-icon>mdi-close</v-icon>
                        </pas-icon-circle>
                        <div class="strong-text mb-3">Firm Name</div>
                        <pas-input-field
                          :readonly="true"
                          v-model="item.company_name"
                          :placeholder="'Company Name...'"
                          :rules="[v.isRequired(`Please enter company's name`)]"
                        ></pas-input-field>
                      </v-flex>
                      <v-flex xs5 md4 pl-4>
                        <div class="strong-text mb-3">Account Number (Optional)</div>
                        <pas-input-field
                          v-model="item.account_number"
                          :placeholder="'Account Number...'"
                          :rules="[v.isRequired(`Please enter account number`)]"
                        ></pas-input-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-layout>
            </v-flex>
            <v-flex>
              <pas-add-item
                v-if="other_cg_accounts"
                class="mt-2 px-4"
                :text="'Add Another Account'"
                @click="addCanaccordAccount"
              ></pas-add-item>
            </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 6">
        <v-layout px-4 column>
          <v-flex xs12 mt-4 mb-3>
            <div class="strong-text">
              What is the purpose and intended nature of the legal entity’s business relationship with {{ firmNames[firm] }}?
            </div>
          </v-flex>
            <v-flex xs6>
              <v-form
                ref="natureOfBusiness"
                lazy-validation
                @submit.prevent
              >
                <pas-autocomplete
                  :rules="[v.isRequired('Please make your selection')]"
                  :placeholder="`Choose`"
                  :items="business_relationship_items"
                  :value="business_relationship"
                  @input="business_relationship = $event"
                  :style="{ width: '350px'}"
                ></pas-autocomplete>
              </v-form>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 7">
        <v-layout px-4 column>
          <v-flex xs12 mt-4 mb-3>
            <div class="strong-text">
              Is any corporate officer or beneficial owner, or their spouse/partner or anyone living with them,
              an employee of an IIROC-regulated firm?
            </div>
          </v-flex>
          <v-flex>
            <v-switch
              :color="primaryColor"
              v-model="same_as_employee_addess"
              :label="same_as_employee_addess ? 'Yes' : 'No'"
            ></v-switch>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 8">
        <v-layout px-4 column>
          <v-flex xs12 mt-4 mb-3>
            <div class="strong-text">
              Is any person with authority over, or who is a beneficial owner of more than 10% of,
              this account, or a defined relative of any of these persons:
            </div>
          </v-flex>
          <v-flex>
            <v-layout row align-center>
              <v-flex xs6 md3 mr-3>
                <span>A foreign politically exposed person (PEP)</span>
              </v-flex>
              <v-flex>
                <v-switch
                  :color="primaryColor"
                  v-model="is_foreign_pep"
                  :label="is_foreign_pep ? 'Yes' : 'No'"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row align-center>
              <v-flex xs6 md3 mr-3>
                <span>A domestic politically exposed person (PEP)</span>
              </v-flex>
              <v-flex>
                <v-switch
                  :color="primaryColor"
                  v-model="is_domestic_pep"
                  :label="is_domestic_pep ? 'Yes' : 'No'"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row align-center>
              <v-flex xs6 md3 mr-3>
                <span>A head of an international organization</span>
              </v-flex>
              <v-flex>
                <v-switch
                  :color="primaryColor"
                  v-model="is_hio"
                  :label="is_hio ? 'Yes' : 'No'"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 9">
        <v-layout column>
          <v-flex mt-4 mb-3 xs12>
            <div class="strong-text">
              Is any authorized representative an “Insider”,
              “Control Person” and/or “5% Owner (US)” of any public company?
              If yes, please provide details:
            </div>
            <v-switch
              :color="primaryColor"
              v-model="is_insider"
              @change="toggleInsider"
              :label="is_insider ? 'Yes' : 'No'"
            ></v-switch>
          </v-flex>
          <v-flex xs12>
            <v-form
              ref="insider"
              lazy-validation
              @submit.prevent
            >
              <template v-for="(item, i) in insider">
                <v-layout row wrap>
                  <v-flex xs2 md1 px-2 my-3>
                    <pas-icon-circle
                      theme="grey"
                      @click="insider.splice(i, 1)"
                      :style="{position: 'absolute', top: '45px', right: '100px'}"
                    >
                      <v-icon>mdi-close</v-icon>
                    </pas-icon-circle>
                    <div class="strong-text mb-3"> Ticker </div>
                    <pas-input-field
                      v-model="item.ticker_symbol"
                      :placeholder="'Ticker'"
                      :rules="[v.isRequired('Required')]"
                    ></pas-input-field>
                  </v-flex>
                  <v-flex xs3 md2 px-2 my-3>
                    <div class="strong-text mb-3">Market</div>
                    <pas-autocomplete
                      :rules="[v.isRequired('Please indicate market')]"
                      :placeholder="`Choose...`"
                      :items="['CAD','USD','Other']"
                      :value="item.market"
                      @input="$set(item, 'market', $event)"
                    ></pas-autocomplete>
                  </v-flex>
                  <v-flex xs6 md5 px-2 my-3>
                    <div class="strong-text mb-3"> What is the name of the company? </div>
                    <pas-input-field
                      v-model="item.company"
                      :placeholder="'Company Name...'"
                      :rules="[v.isRequired(`Please enter company's name`)]"
                    ></pas-input-field>
                  </v-flex>
                  <v-flex xs9 md2 px-2 my-3>
                    <div class="strong-text mb-3"> Relationship </div>
                    <pas-autocomplete
                      :rules="[v.isRequired('Please make your selection')]"
                      :placeholder="`Choose...`"
                      :items="insiderItems"
                      :value="item.kyc_type"
                      @input="$set(item, 'kyc_type', $event)"
                    ></pas-autocomplete>
                  </v-flex>
                  <v-flex xs2 md1 px-2 my-3>
                    <div class="strong-text mb-3"> Percentage </div>
                    <pas-input-field
                      v-model="item.approx_percentage_ownership"
                      :placeholder="'10'"
                      :masked="item.approx_percentage_ownership && item.approx_percentage_ownership[0] == 1 ? '###' : '##'"
                    ></pas-input-field>
                  </v-flex>
                </v-layout>
              </template>
            </v-form>
          </v-flex>
          <v-flex>
            <pas-add-item
              class="mt-5"
              v-if="is_insider"
              :text="'Add Another Company'"
              @click="addInsiderInfo"
            ></pas-add-item>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 10">
        <v-layout px-4 column>
          <v-flex xs12 mt-4 mb-3>
            <div class="strong-text">
              What portion of the income of the entity is from passive income (interest dividend etc.)?
            </div>
          </v-flex>
          <v-flex>
            <v-form
              ref="nonFinancialEntity"
              lazy-validation
              @submit.prevent
            >
              <pas-autocomplete
                :rules="[v.isRequired('Please make your selection')]"
                :placeholder="`Choose`"
                :items="[
                  { value: '1', text: 'Less than 50% (fill out active portion on the W-8BEN-E)' },
                  { value: '2', text: 'More than 50% (fill out passive portion on the W-8BEN-E)' }
                ]"
                :value="non_financial_entity_type"
                @input="non_financial_entity_type = $event"
                :style="{ width: '435px'}"
              ></pas-autocomplete>
            </v-form>
          </v-flex>
        </v-layout>
      </template>

      <template v-if="screen === 11">
        <v-layout column>
          <v-flex offset-xs2>
            <div class="strong-text mb-3"> Corporate Onboarding: </div>
            <v-icon :color="primaryColor">check_circle</v-icon> Account Information - Legal Entity <br>
            <v-icon :color="primaryColor">check_circle</v-icon> Margin Disclaimer (if applicable) <br>
            <v-icon :color="primaryColor">check_circle</v-icon> Investment Management Agreement <br>
            <v-icon :color="primaryColor">check_circle</v-icon> Suitability Waiver <br>
            <v-icon :color="primaryColor">check_circle</v-icon> W-8BEN-E <br>
            <v-icon :color="primaryColor">check_circle</v-icon> Corporate Resolution <br>
            <v-icon :color="primaryColor">check_circle</v-icon> No Seal Letter <br>
            <v-icon :color="primaryColor">check_circle</v-icon> Guarantor Form (if applicable) <br>
          </v-flex>
          <v-flex offset-xs2 mt-4>
            <span>Please send the following to
              <a
                :href="`mailto:${operationsEmail}`"
                target="_blank"
                :style="{ display: 'inline' }"
                class="action-link"
              >{{operationsEmail}}</a>
            </span>
          </v-flex>
          <v-flex offset-xs2>
            <ul>
              <li>Certificate of Incorporation</li>
              <li>Corporate Profile</li>
            </ul>
          </v-flex>
        </v-layout>
      </template>

      <v-layout v-if="continueError">
        <v-flex xs12 text-xs-center>
          <div class="error--text">
            <span class="v-messages__message">{{continueError}}</span>
          </div>
        </v-flex>
      </v-layout>

      <v-layout mt-5>
        <v-spacer></v-spacer>
        <pas-button
          v-if="screen !== 1.5"
          theme="primary"
          class="px-4"
          @click="continueClick"
          :disabled="disabled"
        >{{screen === 11 || existingAccounts ? 'Done' : 'Continue'}}</pas-button>
      </v-layout>
    </v-container>
    <define-roles
      :isOpen="defineRolesModal"
      @close="defineRolesModal = false"
      @save="saveRoles"
    ></define-roles>
    <select-relationship
      :isOpen="selectRelationshipModal"
      :client="selectRelationshipClient"
      @close="selectRelationshipModal = false"
      @done="closeSelectRelationshipModal"
    ></select-relationship>
  </pas-fullscreen-modal>
</template>

<script>

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import api from 'Services/api';
import { getMetadata } from 'Services/api/metadata';
import v from 'Services/validation';
import { modal } from 'Services/mixins';
import ActionCard from 'Components/Shared/ActionCard';
import EmailSvg from 'Assets/icons/email-01.svg';
import InPersonSvg from 'Assets/icons/meeting-in-person.svg';
import ScreenshareSvg from 'Assets/icons/screenshare-01.svg';
import AddNewClient from 'Components/Modals/AddNewClient';
import babelPolyfill from '@babel/polyfill';
import DefineRoles from 'Components/Shared/DefineRoles';
import AddToFeeGroup from 'Components/Shared/FeeManagement/AddToFeeGroup';
import { joinFeeGroup, createFeeGroup } from 'Services/api/FeeManagement';
import SelectRelationship from './AddCorporateInvestmentAccounts/SelectRelationship';

export default {
  name: 'add-corp-investment-accounts',
  mixins: [modal],
  components: {
    ActionCard,
    AddNewClient,
    DefineRoles,
    SelectRelationship,
    AddToFeeGroup,
  },
  data() {
    return {
      v,
      screen: 1,
      disabled: false,
      tempAccountsEdit: [],
      continueError: '',
      defineRolesModal: false,
      financialInterest: false,
      financialInterestOptions: [],
      other_accounts: false,
      other_cg_accounts: false,
      is_insider: false,
      business_relationship: 'Discretionary Wealth Management',
      business_relationship_items: [
        'Investment Advice and Trade Execution',
        'Discretionary Wealth Management',
        'Trade Execution',
      ],
      operationsEmail: '',
      same_as_employee_addess: false,
      is_foreign_pep: false,
      is_domestic_pep: false,
      is_hio: false,
      otherAccounts: [],
      canaccordAccounts: [],
      insider: [],
      insiderItems: [
        { text: 'Insider', value: 'I' },
        { text: 'Control Person', value: 'C' },
        { text: '5% Owner (U.S.)', value: 'O' },
      ],
      non_financial_entity_type: null,
      env: '',
      EmailSvg,
      InPersonSvg,
      ScreenshareSvg,
      workflowIsCreating: false,
      surveyOptionsLoaded: false,
      firms: [],
      authorizedSignatory: null,
      selectRelationshipModal: false,
      selectRelationshipClient: null,
      firmNames: {
        fwp: 'Foundation Wealth Partners',
        harness: 'Harness Investment Management',
        milestone: 'Milestone Wealth Management',
      },
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.tempAccountsEdit = _.cloneDeep(this.tempAccounts);
    const email = await getMetadata('operations_email');
    if (email) {
      this.operationsEmail = email;
    }
    api.get('/datahub/aton_firms/simple/')
      .then((res) => this.firms = res.map((firm) => firm.institution_name)
        .filter((firm) => firm)
        .concat('Other'))
      .catch((err) => err);
  },
  computed: {
    firm() {
      return this.$store.state.app.theme;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    repCode() {
      return this.activeClient.client.rep_code;
    },
    tempAccounts() {
      if (!this.activeClient.investment_accounts.length) return [];
      const ias = this.activeClient.investment_accounts
        .filter((a) => a.account_status === 'T' || a.account_status === 'P');
      return ias;
    },
    existingAccounts() {
      return !!this.activeClient.investment_accounts.length;
    },
    clientList() {
      const clientList = {};
      this.$store.state.clients.list
        .forEach((client) => {
          const { id, display_name } = client;
          clientList[id] = display_name;
        });
      return clientList;
    },
    corporateRoles() {
      return this.activeClient.corporate_roles;
    },
    authorizedSignatories() {
      return this.corporateRoles.filter((r) => r.authorized_signatory);
    },
    clientStatus() {
      return this.activeClient.client.client_status;
    },
    feeGroupsEnabled() {
      return this.$store.state.app.features.fee_groups
      && this.activeClient.client.client_type === 'corporation'
      && (this.clientStatus === 'active' || this.clientStatus === 'prospect');
    },
    relationships() {
      return this.client.relationships.map((r) => {
        const display_name = r.client_details[0] ? r.client_details[0].display_name : '';
        const client_2_id = r.client_details[0] ? r.client_details[0].id : '';
        return { text: display_name || '', value: client_2_id };
      });
    },
  },
  methods: {
    async saveRoles() {
      this.defineRolesModal = false;
      await this.$store.dispatch('fetchCorporateRoles', this.activeClient.client.id);
      this.financialInterestOptions = this.corporateRoles;
    },
    getFinancialInterestText(financialIntrests) {
      const finInt = financialIntrests.map((fi) => `${fi.clientDisplayName} (${fi.relationship})`);
      return finInt.join(', ');
    },
    openSelectRelationshipModal(clientId, index) {
      this.selectRelationshipModal = true;
      this.selectRelationshipClient = clientId;
    },
    closeSelectRelationshipModal(data, id) {
      this.selectRelationshipModal = false;
      this.financialInterestOptions = this.financialInterestOptions.map((intrest) => {
        if (intrest.client === id) {
          intrest.financial_interest = data;
        }
        return intrest;
      });
    },
    goBack() {
      if (this.screen === 3 && this.authorizedSignatories.length === 1) return this.screen = 1;
      if (this.screen === 2 && this.feeGroupsEnabled || this.screen === 1.5) {
        this.screen -= 0.5;
      } else this.screen--;
    },
    closeModal() {
      this.$store.dispatch('getInvestmentAccounts', this.activeClient.client.id);
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.close();
    },
    addAccount() {
      const obj = {
        currency: 'CAD',
        options_status: false,
        margin_status: false,
        nickname: 'CORP CASH',
        tempID: uuidv4(),
      };
      this.tempAccountsEdit.push(obj);
    },
    removeAccount(index) {
      this.tempAccountsEdit.splice(index, 1);
    },
    toggleOtherAccounts() {
      if (this.other_accounts) return this.addOtherAccount();
      this.otherAccounts = [];
    },
    toggleCanaccord() {
      if (this.other_cg_accounts) return this.addCanaccordAccount();
      return this.canaccordAccounts = [];
    },
    toggleInsider() {
      if (this.is_insider) return this.addInsiderInfo();
      return this.insider = [];
    },
    addOtherAccount() {
      this.otherAccounts.push({
        account_number: '',
        company_name: '',
        company_name_details: '',
        client_id: this.activeClient.client.id,
      });
    },
    addCanaccordAccount() {
      this.canaccordAccounts.push({
        account_number: '',
        company_name: 'Canaccord Genuity Wealth Management',
        client_id: this.activeClient.client.id,
      });
    },
    addInsiderInfo() {
      this.insider.push({
        ticker_symbol: null,
        market: null,
        company: null,
        kyc_type: null,
        approx_percentage_ownership: null,
      });
    },
    setAccountOptions(optionsArray, account) {
      account.margin_status = optionsArray.includes(0);
      account.options_status = optionsArray.includes(1);
    },
    async continueClick() {
      this.continueError = '';
      if (this.screen === 1) {
        if (!this.tempAccountsEdit.length) {
          this.continueError = 'Please add an account to proceed';
          return false;
        }
        if (!this.authorizedSignatories.length) {
          this.continueError = 'Please add authorized signatory to proceed';
          return false;
        }
        if (this.authorizedSignatories.length === 1) {
          this.authorizedSignatory = this.authorizedSignatories[0].client_id;
        }
        const secretary = this.corporateRoles.filter((r) => r.secretary);
        if (!secretary.length) {
          this.continueError = 'Please add secretary to proceed';
          return false;
        }
        const guarantor = this.corporateRoles.filter((r) => r.guarantor);
        const marginAccounts = this.tempAccountsEdit.filter((a) => a.margin_status);
        if (marginAccounts.length && !guarantor.length) {
          this.continueError = 'You must indicate a guarantor if you are opening a margin account';
          return false;
        }
        if (!this.existingAccounts) {
          if ((this.authorizedSignatories.length === 1) && this.feeGroupsEnabled) {
            if (!this.activeClient.feeGroup.details) await this.createGroup();
            this.screen = 1.5;
          } else if (this.authorizedSignatories.length === 1) this.screen = 3;
          else this.screen = 2;
          return false;
        }
        this.disabled = true;
        this.update();
      } else if (this.screen === 1.5) {
        if (this.authorizedSignatories.length === 1) {
          this.authorizedSignatory = this.authorizedSignatories[0].client_id;
          this.screen = 3;
        } else this.screen = 2;
      } else if (this.screen === 2) {
        if (!this.authorizedSignatory) {
          return this.continueError = 'Please select a primary authorized signatory';
        }
        this.screen = 3;
      } else if (this.screen === 3) {
        this.screen = 4;
      } else if (this.screen === 4) {
        if (this.other_accounts && !this.$refs.otherAccounts.validate()) return;
        this.screen = 5;
      } else if (this.screen === 5) {
        this.screen = 6;
      } else if (this.screen === 6) {
        if (!this.$refs.natureOfBusiness.validate()) return;
        this.screen = 7;
      } else if (this.screen === 7) {
        this.screen = 8;
      } else if (this.screen === 8) {
        this.screen = 9;
      } else if (this.screen === 9) {
        if (this.is_insider && !this.$refs.insider.validate()) return;
        this.screen = 10;
      } else if (this.screen === 10) {
        if (!this.$refs.nonFinancialEntity.validate()) return;
        this.screen = 11;
      } else if (this.screen === 11) {
        this.disabled = true;
        const updateRoles = await this.updateRoles();
        if (!updateRoles) return false;
        const corporationRes = await this.updateCorporation();
        if (!corporationRes) return false;
        const kycAmlDetailsRes = await this.updateKycAmlDetails();
        if (!kycAmlDetailsRes) return false;
        const kycAmlDetails = await this.updateKycDetails();
        if (!kycAmlDetails) return false;
        this.update();
      }
    },
    async createGroup() {
      try {
        const feeGroup = await createFeeGroup({
          name: `${this.activeClient.client.display_name}'s Fee Group`,
          description: `${this.activeClient.client.display_name}'s Fee Group`,
        });
        if (!feeGroup.id) throw Error;
        const clientFeeGroupRes = await joinFeeGroup({
          client: this.activeClient.client.id,
          fee_group: feeGroup.id,
        });
        if (!clientFeeGroupRes.id) throw Error;
        await this.$store.dispatch('setFeeGroup', this.activeClient.client.id);
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Something went wrong, please refresh the page',
          type: 'fail',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async updateCorporation() {
      const data = {
        id: this.activeClient.client.id,
        client_type: 'corporation',
        other_accounts: this.other_accounts,
        other_cg_accounts: this.other_cg_accounts,
        is_insider: this.is_insider,
        business_relationship: this.business_relationship_items.indexOf(this.business_relationship) + 1,
        same_as_employee_addess: this.same_as_employee_addess,
        is_foreign_pep: this.is_foreign_pep,
        is_domestic_pep: this.is_domestic_pep,
        is_hio: this.is_hio,
        non_financial_entity_type: this.non_financial_entity_type,
      };
      if (this.clientStatus === 'Other Contact') {
        data.client_status = 'prospect';
      }
      let corporationRes = false;
      await this.$store.dispatch('updateClient', data)
        .then((res) => {
          if (!res.id) throw Error('error updating corporation details');
          corporationRes = true;
        })
        .catch((err) => this.handleResponse('fail', 'Client'));
      return corporationRes;
    },
    async updateKycAmlDetails() {
      let updateKycAmlDetails = false;
      await Promise.all(
        this.otherAccounts.concat(this.canaccordAccounts)
          .map((a) => this.$store.dispatch('addKycAmlDetails', {
            client: this.activeClient.client.id,
            account_number: a.account_number ? a.account_number : null,
            company_name: a.company_name_details ? a.company_name_details : a.company_name,
          })),
      ).then((responses) => {
        const goodRes = responses.filter((res) => res.id);
        if (goodRes.length !== responses.length) {
          throw Error('error updating corporation details');
        }
        updateKycAmlDetails = true;
      })
        .catch((err) => this.handleResponse('fail', 'Client'));
      return updateKycAmlDetails;
    },
    async updateKycDetails() {
      if (!this.insider || !this.insider.length) return true;
      let updateKycDetails = false;
      await Promise.all(
        this.insider.map((a) => this.$store.dispatch('addKycDetails', {
          client: this.activeClient.client.id,
          kyc_type: a.kyc_type,
          ticker_symbol: a.ticker_symbol,
          market: a.market,
          company: a.company,
          approx_percentage_ownership: Number(a.approx_percentage_ownership) / 100,
        })),
      ).then((responses) => {
        const goodRes = responses.filter((res) => res.id);
        if (goodRes.length !== responses.length) {
          throw Error('error updating corporation details');
        }
        updateKycDetails = true;
      })
        .catch((err) => this.handleResponse('fail', 'Client'));
      return updateKycDetails;
    },
    async updateRoles() {
      let updateRoles = false;
      await Promise.all(
        Array.prototype.concat.apply([],
          this.financialInterestOptions
            .filter((fio) => fio.financial_interest)
            .map((fio) => fio.financial_interest)
            .map((fio) => fio.map((fi) => this.$store.dispatch('addCorporateRole', {
              corporate_client: this.activeClient.client.id,
              client: fi.clientId,
              financial_interest: true,
            }))),
          this.authorizedSignatories
            .filter((role) => role.client === this.authorizedSignatory)
            .map((role) => this.$store.dispatch('updateCorporateRole', {
              id: role.id,
              primary: true,
            }))),
      )
        .then((responses) => {
          const goodRes = responses.filter((res) => res.id);
          if (goodRes.length !== responses.length) {
            throw Error('error updating corporate roles');
          }
          updateRoles = true;
        })
        .catch((err) => this.handleResponse('fail', 'Roles'));
      return updateRoles;
    },
    async update() {
      let accounts;
      try {
        accounts = await this.updateAccounts();
      } catch (err) {
        return;
      }
      if (!accounts) return false;
      try {
        const shouldCreateNewCorpWorkflow = await this.shouldCreateNewCorpWorkflow();
        if (shouldCreateNewCorpWorkflow) {
          await this.createWorkflows(accounts);
          this.disabled = false;
          this.$store.dispatch('getWorkflows', this.activeClient.client.id);
        } else {
          this.updateWorkflow(accounts);
        }
        this.$store.dispatch('getInvestmentAccounts', this.activeClient.client.id);
        this.closeModal();
      } catch (err) {
        this.handleResponse('fail', 'Workflow');
      }
    },
    async getActiveWorkflow() {
      // returns a workflow if it is not yet past the gather info stage
      const url = `/advisor_portal/workflow/get?pas_client_ids=${this.activeClient.client.id}&type=corporate_onboarding`;
      const existingWorkflows = await api.get(url);
      return existingWorkflows.docs.find((x) => x.stages[1].steps[0].current_status !== 'Completed');
    },
    async updateWorkflow(accounts) {
      try {
        const active_workflow = await this.getActiveWorkflow();
        const workflow_specific_data = { ...active_workflow.workflow_specific_data, accounts };
        await api.post('/advisor_portal/workflow/put', {
          workflow: active_workflow._id,
          action: 'update_workflow_specific_data',
          workflow_specific_data,
        });
      } catch (err) {
        console.log('err updating workflow', err.message);
        this.handleResponse('fail', 'Workflow');
      }
    },
    populateAccountInfo(account) {
      const obj = {
        rep_code: this.activeClient.client.rep_code,
        primary_client_id: this.activeClient.client.id,
        currency: account.currency,
        spousal_indicator: false,
        locked_in_indicator: false,
        discretionary_status: false,
        registration_status: false,
        trading_status: false,
        margin_status: account.margin_status,
        options_status: account.options_status,
        employee_account: '0',
        joint_indicator: false,
        joint_client_id: [],
        jurisdiction: '',
        account_status: account.account_status ? account.account_status : 'T',
        non_resident_code: false,
        account_type_short: account.margin_status ? 10 : 9,
        account_type_long: account.margin_status ? 10 : 9,
        rap_code: 'A',
        joint_type: 0,
        account_group: '1',
        subtype: '',
        nickname: account.nickname,
      };
      Object.assign(account, obj);
    },
    async updateAccounts() {
      try {
        let updatedAccounts = [];
        this.tempAccountsEdit.forEach((account) => {
          this.populateAccountInfo(account);
        });
        const accountsToAdd = this.tempAccountsEdit.filter((account) => !account.id);
        const accountsToDelete = this.tempAccounts.filter((account) => !(this.tempAccountsEdit.some((ea) => ea.id === account.id)));
        const unchangedAccounts = this.tempAccounts.filter((account) => (account.id && !(accountsToDelete.some((x) => x.id === account.id))));
        if (!accountsToAdd.length && !accountsToDelete.length) {
          this.disabled = false;
          this.closeModal();
        }
        await Promise.all(
          accountsToAdd.map((a) => this.$store.dispatch('createAccount', a)
            .then((res) => {
              updatedAccounts.push(res);
              return res;
            }))
            .concat(accountsToDelete.map((a) => this.$store.dispatch('deleteAccount', a.id))),
        ).then((reponses) => {
          const badRes = reponses.filter((res) => res.code);
          if (badRes.length) throw Error('error updating accounts');
          this.handleResponse('success', 'Account');
        }).catch((err) => {
          this.handleResponse('fail', 'Account');
          console.log('update account error', err.message);
        });
        updatedAccounts = updatedAccounts.concat(unchangedAccounts);
        return updatedAccounts;
      } catch (err) {
        console.log('error updating accounts', err.rmessage);
        this.handleResponse('fail', 'Account');
        throw new Error('error updating accounts');
      }
    },
    handleResponse(type, details) {
      let text = `Successfully updated ${details} Details`;
      if (type === 'fail') {
        text = `Failed to update ${details} Details. Please try again`;
        this.disabled = false;
      }
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async shouldCreateNewCorpWorkflow() {
      // whether to create a new corporate_onboarding wf or now
      try {
        const activeWorkflow = await this.getActiveWorkflow();
        return (activeWorkflow === undefined);
      } catch (err) {
        this.handleResponse('fail', 'Workflow');
        throw new Error('failed to check active workflow');
      }
    },
    async createWorkflows(accounts) {
      let updateWorkflows = false;
      const workflow_specific_data = {
        accounts,
        clients: [{
          role: 'Primary client',
          pas_client_id: this.activeClient.client.id,
          client_data: this.activeClient.client,
        }],
      };
      await api.post('/advisor_portal/workflow/new', {
        workflow_type: 'corporate_onboarding',
        pas_client_ids: [this.activeClient.client.id],
        workflow_specific_data,

      }).then((res) => {
        if (!res.workflow_id) throw Error('error creating workflow');
        this.handleResponse('success', 'Workflow');
        updateWorkflows = true;
      }).catch((err) => this.handleResponse('fail', 'Workflow'));
      return updateWorkflows;
    },
  },
  watch: {
    tempAccounts: {
      deep: true,
      handler() {
        this.tempAccountsEdit = _.cloneDeep(this.tempAccounts);
      },
    },
    isOpen(val) {
      if (!this.tempAccountsEdit.length) this.addAccount();
      if (val) this.financialInterestOptions = this.corporateRoles;
    },
  },
};
</script>

<style lang="scss">

@import '~Styles/variables';
  .btnBottomMargin {
    .v-btn {
      margin-bottom: 5px !important;
    }
  }
  .flexColumn {
    flex-direction: column;
  }
  .accounts-table-header-layout {
    .flex {
      margin-right: 10px !important;
    }
  }
  .accounts-row-layout {
    .flex {
      margin-right: 10px !important;
    }
    .drop-down .v-input__slot {
      margin-bottom: 0px !important;
    }
  }
  .pas-list-wrapper {
    box-shadow: none !important;
  }

</style>
