export default {
  fwp: {
    logo: require('Assets/themes/fwp/logo_main.png'),
    login: require('Assets/themes/fwp/login-illustration.svg'),
    charitableGiving: require('Assets/themes/fwp/charitable-giving.svg'),
    couple: require('Assets/themes/fwp/couple.svg'),
    education: require('Assets/themes/fwp/education.svg'),
    female: require('Assets/themes/fwp/female.svg'),
    legacy: require('Assets/themes/fwp/legacy.svg'),
    lifestyle: require('Assets/themes/fwp/lifestyle.svg'),
    male: require('Assets/themes/fwp/male.svg'),
    retirement: require('Assets/themes/fwp/retirement.svg'),
    significantPurchases: require('Assets/themes/fwp/significant-purchases.svg'),
  },
  shared: {
    login: require('Assets/themes/shared/login-illustration.svg'),
    charitableGiving: require('Assets/themes/shared/charitable-giving.svg'),
    couple: require('Assets/themes/shared/couple.svg'),
    education: require('Assets/themes/shared/education.svg'),
    female: require('Assets/themes/shared/female.svg'),
    legacy: require('Assets/themes/shared/legacy.svg'),
    lifestyle: require('Assets/themes/shared/lifestyle.svg'),
    male: require('Assets/themes/shared/male.svg'),
    retirement: require('Assets/themes/shared/retirement.svg'),
    significantPurchases: require('Assets/themes/shared/significant-purchases.svg'),
    virusThreat: require('Assets/themes/shared/virus-threat.svg'),
  },
  milestone: {
    logo: require('Assets/themes/milestone/logo_main.png'),
  },
  harness: {
    logo: require('Assets/themes/harness/logo_main.png'),
  },
  'harness-fcc': {
    logo: require('Assets/themes/harness-fcc/logo_main.png'),
  },
  samara: {
    logo: require('Assets/themes/samara/logo_main.png'),
  },
  'fwp-fcc': {
    logo: require('Assets/themes/fwp-fcc/logo_main.png'),
    login: require('Assets/themes/fwp-fcc/login-illustration.svg'),
    charitableGiving: require('Assets/themes/fwp-fcc/charitable-giving.svg'),
    couple: require('Assets/themes/fwp-fcc/couple.svg'),
    education: require('Assets/themes/fwp-fcc/education.svg'),
    female: require('Assets/themes/fwp-fcc/female.svg'),
    legacy: require('Assets/themes/fwp-fcc/legacy.svg'),
    lifestyle: require('Assets/themes/fwp-fcc/lifestyle.svg'),
    male: require('Assets/themes/fwp-fcc/male.svg'),
    retirement: require('Assets/themes/fwp-fcc/retirement.svg'),
    significantPurchases: require('Assets/themes/fwp-fcc/significant-purchases.svg'),
  },
  ofc: {
    logo: require('Assets/themes/ofc/logo_main.png'),
  },
  wwm: {
    logo: require('Assets/themes/wwm/logo_main.png'),
  },
  owa: {
    logo: require('Assets/themes/owa/logo_main.svg'),
  },
  fim: {
    logo: require('Assets/themes/fim/logo_main.png'),
  },
  vcm: {
    logo: require('Assets/themes/vcm/logo_main.png'),
  },
  pic: {
    logo: require('Assets/themes/pic/logo_main.png'),
  },
  mwm: {
    logo: require('Assets/themes/milestone/logo_main.jpg'),
  },
  tsw: {
    logo: require('Assets/themes/tsw/logo_main.jpg'),
  },
  dsg: {
    logo: require('Assets/themes/dsg/logo_main.png'),
  },
  awi: {
    logo: require('Assets/themes/awi/logo_main.png'),
  },
};
