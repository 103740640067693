import { logUserOut } from 'Services/helpers/auth';
import querystring from 'query-string';

const datahubUrl = localStorage.getItem('datahubUrl');
/**
 * Parses the JSON returned by a network request
 *
 * @param {object} response A response from a network request
 *
 * @return {object} The parsed JSON, status from the response
 */
function parseJSON(response) {
  return new Promise((resolve) => response.json()
    .then((json) => resolve({
      status: response.status,
      ok: response.ok,
      json,
    })));
}

const checkForAuthFail = (res) => {
  if (res.status === 'error' && res.detail === 'auth_fail') logUserOut();
  return res;
};

const get = (url, queryParams, requestId, baseUrl = datahubUrl) => new Promise((resolve, reject) => {
  const jwt = localStorage.getItem('jwt') || '';
  let queryParamsString;
  if (queryParams !== undefined) {
    queryParamsString = querystring.stringify(queryParams, { arrayFormat: 'comma' });
  }

  return fetch(`${baseUrl}${url}${queryParamsString ? `?${queryParamsString}` : ''}`, {
    method: 'GET',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(parseJSON)
    .then(checkForAuthFail)
    .then((response) => {
      response.json.requestId = requestId;
      if (response.ok) {
        return resolve(response.json);
      }
      // extract the error from the server's json
      return reject(new Error(response.json.message));
    })
    .catch((error) => reject(new Error(error.message)));
});

const post = (url, body, baseUrl = datahubUrl) => new Promise((resolve, reject) => {
  const jwt = localStorage.getItem('jwt') || '';

  return fetch(`${baseUrl}${url}`, {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(body || {}),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(parseJSON)
    .then(checkForAuthFail)
    .then((response) => {
      if (response.ok) {
        return resolve(response.json);
      }
      // extract the error from the server's json
      return reject(new Error(response.json.message));
    })
    .catch((error) => reject(new Error(error.message)));
});

const patch = (url, body, baseUrl = datahubUrl) => new Promise((resolve, reject) => {
  const jwt = localStorage.getItem('jwt') || '';

  return fetch(`${baseUrl}${url}`, {
    method: 'PATCH',
    redirect: 'manual',
    body: JSON.stringify(body || {}),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(parseJSON)
    .then(checkForAuthFail)
    .then((response) => {
      if (response.ok) {
        return resolve(response.json);
      }
      // extract the error from the server's json
      return reject(new Error(response.json.message));
    })
    .catch((error) => reject(new Error(error.message)));
});

const del = (url, baseUrl = datahubUrl) => new Promise((resolve, reject) => {
  const jwt = localStorage.getItem('jwt') || '';

  return fetch(`${baseUrl}${url}`, {
    method: 'DELETE',
    redirect: 'manual',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(parseJSON)
    .then(checkForAuthFail)
    .then((response) => {
      if (response.ok) {
        return resolve(response.json);
      }
      // extract the error from the server's json
      return reject(new Error(response.json.message));
    })
    .catch((error) => reject(new Error(error.message)));
});

const put = (url, body, baseUrl = datahubUrl) => new Promise((resolve, reject) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${baseUrl}${url}`, {
    method: 'PUT',
    redirect: 'manual',
    body: JSON.stringify(body || {}),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(parseJSON)
    .then(checkForAuthFail)
    .then((response) => {
      if (response.ok) {
        return resolve(response.json);
      }
      // extract the error from the server's json
      return reject(new Error(response.json.message));
    })
    .catch((error) => reject(new Error(error.message)));
});

export default {
  get,
  post,
  patch,
  del,
  put
};
