const ACCOUNT_STATUS = {
    TEMPORARY: 'temporary',
    OPENED: 'opened',
    PROCESSING: 'processing',
    CLOSED: 'closed',
    DELETED: 'deleted',
    SUSPENDED: 'suspended',
};

module.exports.ACCOUNT_STATUS = ACCOUNT_STATUS;

module.exports.LIST_VALID_ACCOUNT_STATUS = [
    ACCOUNT_STATUS.TEMPORARY,
    ACCOUNT_STATUS.OPENED,
    ACCOUNT_STATUS.PROCESSING,
];
