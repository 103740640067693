<template>
  <Deposit
    :editMode="editMode"
    :frequencyOptions="frequencyOptions"
    :currencyOptions="currencyOptions"
    :investmentAccts="setInvestmentAccts"
    :bankAccts="bankAccts"
    :depositRequest="depositRequest"
    :requestSubtype="selectedRequestSubtype"
    :docList="selectedRequestSubtype.docList"
    :optionalDocList="selectedRequestSubtype.optionalDocList"
    :recurringEventOptions="recurringEventOptions"
    :errorAlertVisible="errorAlertVisible"
    :errorMessage="errorMessage"
    :warningMessage="warningMessage"
    v-on:reviewDeposit="reviewDeposit"
    v-on:back="back"
    class="mt-5"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { postComplexFundingRequest, postRequest } from '../../../services/api/FundingVersion2';
import {
  DEFAULT_DEPOSIT_SUBTYPE,
  SHARE_DETAILS_CURRENCY_TYPES,
  REQUEST_SUBTYPE_IDS,
  formatAmountToDecimal,
  dataFromValidatedRequest,
} from '../../../services/helpers/funding/index';

export default {
  name: 'MakeDeposit',
  data() {
    return {
      depositRequest: {
        selectedInvestmentAcct: null, // pass account id only if needed
        amount: null,
        selectedBankAcct: null, // pass account id only if needed
        frequency: null,
        startDate: null,
        endDate: null,
      },
      errorAlertVisible: false,
      errorMessage: 'Sorry, something went wrong. Try again in a few minutes.',
    };
  },
  computed: {
    ...mapGetters([
      'fundingFrequencies',
      'currencyTypes',
      'investmentAccts',
      'bankAccts',
      'validatedRequest',
      'activeRequest',
      'editMode',
      'selectedRequestSubtype',
      'complexFundingFeatureFlag',
      'recurringEvents',
      'fundingDocuments',
      'warningMessage',
    ]),
    frequencyOptions() {
      if (this.fundingFrequencies.length) {
        return this.fundingFrequencies.filter((e) => e.id !== '1'); // remove unknown
      }
      return [];
    },
    setInvestmentAccts() {
      if (DEFAULT_DEPOSIT_SUBTYPE.id == 1) {
        return this.investmentAccts.filter((acc) => acc.primary_client_id === this.$route.params.clientID);
      }
      else {
        return this.investmentAccts;
      }
    },
    currencyOptions() {
      if (this.currencyTypes.length) {
        return this.currencyTypes.filter((e) => SHARE_DETAILS_CURRENCY_TYPES.includes(e.iso_code));
      }
      return [];
    },
    recurringEventOptions() {
      if (this.recurringEvents.length) {
        return this.recurringEvents.map((event) => ({ ...event, label: `$${event.amount} - ${event.frequency} - ${event.from_account?.account_name}` }));
      }
      return [];
    },
    activeRequestBankAcctId() {
      if (this.activeRequest.from_account && this.activeRequest.to_account) {
        if (this.activeRequest.from_account.type === 'bank_account') {
          return this.activeRequest.from_account.account_id;
        }
        if (this.activeRequest.to_account.type === 'bank_account') {
          return this.activeRequest.to_account.account_id;
        }
      }
      return null;
    },
    activeRequestInvestmentAcctId() {
      if (this.activeRequest.from_account && this.activeRequest.to_account) {
        if (this.activeRequest.from_account.type === 'investment_account') {
          return this.activeRequest.from_account.account_id;
        }
        if (this.activeRequest.to_account.type === 'investment_account') {
          return this.activeRequest.to_account.account_id;
        }
      }
      return null;
    },
  },
  methods: {
    async reviewDeposit(reviewPayload) {
      try {
        let validatedPayload;
        if (this.selectedRequestSubtype?.id === DEFAULT_DEPOSIT_SUBTYPE.id) {
          validatedPayload = await postRequest({ requestedScope: 'validate', body: reviewPayload });
        } else {
          if (this.selectedRequestSubtype?.id === REQUEST_SUBTYPE_IDS.DEPOSIT_RECURRING_EVENT_AUTO_PURCHASE) {
            if (!['RRIF', 'RRIF-S', 'PRIF', 'LIF', 'LRIF', 'RLIF'].includes(reviewPayload.recurring_event.account_type) && reviewPayload.share_certificates.some((s) => s.minimum_amount)) {
              throw new Error('Minimum amount is only allowed for RRIF, RRIF-S, PRIF, LIF, LRIF, and RLIF accounts');
            }
            if (!['LIF', 'LRIF', 'RLIF'].includes(reviewPayload.recurring_event.account_type) && reviewPayload.share_certificates.some((s) => s.maximum_amount)) {
              throw new Error('Maximum amount is only allowed for LIF, LRIF, and RLIF accounts');
            }
          }
          validatedPayload = await postComplexFundingRequest({ requestedScope: 'validate', body: reviewPayload });
        }
        this.$store.dispatch('setValidatedRequest', validatedPayload);
        this.$store.dispatch('setFundingDocuments', reviewPayload.documents);
        this.goToReviewCard();
      } catch (err) {
        this.errorAlertVisible = true;
        this.errorMessage = err.message;
      }
    },
    populateRequestData() {
      if (Object.keys(this.activeRequest).length !== 0 && Object.keys(this.validatedRequest).length === 0) {
        this.depositRequest.selectedBankAcct = this.activeRequestBankAcctId;
        this.depositRequest.selectedInvestmentAcct = this.activeRequestInvestmentAcctId;
        this.depositRequest.amount = formatAmountToDecimal(this.activeRequest.amount);
        this.depositRequest.frequency = this.fundingFrequencies.find((e) => e.frequency === this.activeRequest.frequency).id;
        this.depositRequest.startDate = this.activeRequest.start_date;
        this.depositRequest.endDate = this.activeRequest.end_date;
        this.depositRequest.note = this.activeRequest.notes;
      } else {
        this.depositRequest = dataFromValidatedRequest(this.depositRequest, this.validatedRequest, this.fundingDocuments);
        this.depositRequest.selectedBankAcct = this.validatedRequest.bank_account;
        this.depositRequest.selectedInvestmentAcct = this.validatedRequest.investment_account
                                                  || this.validatedRequest.to_investment_account;
      }
    },
    goToReviewCard() {
      this.$router.push({
        path: `${this.complexFundingFeatureFlag && !this.editMode ? 'deposit/' : ''}review/`,
      });
    },
    back() {
      this.$store.dispatch('setValidatedRequest', {});
      this.$store.dispatch('setActiveRequest', {});
      this.$store.dispatch('setWarningMessage', {});
      this.$router.go(-1);
    },
  },
  mounted() {
    if (Object.keys(this.validatedRequest).length || Object.keys(this.activeRequest).length) {
      this.populateRequestData();
    }
  },
  created() {
    if (Object.keys(this.validatedRequest).length || Object.keys(this.activeRequest).length) {
      this.populateRequestData();
    }
  },
};
</script>

<style>

</style>
