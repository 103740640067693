<template>
  <v-container>
    <v-layout column mt-4>
      <v-layout align-baseline>
        <PasPageTitle class="mb-2">{{workflowsPageTitle}}</PasPageTitle>
        <div class="ml-2 subheading grey--text">({{totalCount}})</div>
        <v-spacer></v-spacer>
        <v-flex xs1 mr-4 ml-5 text-xs-right>
        <pas-button slot="activator" theme="inverted" class="px-4 mr-3">
          <download-excel
            :fetch="exportWorkflowList"
            :before-generate="showFetchingWorkflowSnackbar"
            :before-finish="hideFetchingWorkflowSnackbar"
            worksheet="Workflow List"
            type="csv"
            :name="exportFileName">
            Export List
          </download-excel>
        </pas-button>
      </v-flex>
      </v-layout>
      <v-flex mb-2>
        <router-link v-if="clientId" :to="`/workflows`">View all workflows</router-link>
      </v-flex>
    </v-layout>
    <v-layout row align-center pt-1 class="list-filters-row">
      <v-flex shrink>
        <PasSearchBox v-model="searchValue"/>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex shrink mx-4>
        <span class="filter-label">Type:</span>
      </v-flex>
      <v-flex shrink mr-3>
        <PasDropDown
          :value="type"
          :items="workflowTypes"
          @change="setWorkflowTypeFilter"
        />
      </v-flex>
      <v-flex shrink mx-4>
        <span class="filter-label">Status:</span>
      </v-flex>
      <v-flex shrink mr-3>
        <PasDropDown
          :value="status"
          :items="filterByStatusOptions"
          @change="setWorkflowStatusFilter"
        />
      </v-flex>
      <v-flex shrink mx-4>
        <span class="filter-label">Step:</span>
      </v-flex>
      <v-flex shrink mr-3>
        <PasDropDown
          :value="step"
          :items="filterByStepNameOptions"
          @change="setWorkflowStepNameFilter"
        />
      </v-flex>
    </v-layout>
    <WorkflowList
      :loading="loading"
      :workflows="workflows"
      class="pt-3"
    />
    <PasCircularLoader v-if="loading && workflows.length > 0"></PasCircularLoader>
    <pas-button
      v-if="!loading && currentOffset < totalCount"
      class="primary-btn wide-btn mt-3"
      @click="fetchMoreWorkflows"
    >
      View more
    </pas-button>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { fetchWorkflows, fetchActiveWorkflowSteps } from 'Services/api/Workflows';
import { mapGetters } from 'vuex';
import WorkflowList from './MyWorkflowsV2/WorkflowList';
import { WORKFLOW } from '../../services/constants';

export default {
  name: 'MyWorkflows',
  components: {
    WorkflowList,
  },
  props: {
    search: { String, default: '' },
    sortBy: { String, default: undefined },
    sortDir: { String, default: undefined },
    type: { String, default: undefined },
    status: { String, default: undefined },
    step: { String, default: undefined },
    clientId: { String, default: undefined },
  },
  data() {
    return {
      totalCount: 0,
      currentOffset: 0,
      workflows: [],
      searchValue: this.search,
      filterByStatusOptions: [
        'All',
        ...(_.sortBy((_.keys(WORKFLOW.STATUS_NAMES).map(((status) => ({
          value: status,
          text: WORKFLOW.STATUS_NAMES[status],
        })))), ['text'])),
      ],
      filterByStepNameOptions: [],
      loading: true,
      searchTimer: undefined,
      workflowTypes: [],
      exportFileName: this.generateExportFileName(),
    };
  },
  async created() {
    await this.$store.dispatch('fetchAllWorkflowTypes');
    this.transformWorkflowTypes();
  },
  async mounted() {
    this.fetchWorkflows(0, true);
    this.updateWorkflowSteps();
  },
  computed: {
    ...mapGetters([
      'allWorkflowTypes',
    ]),
    workflowsPageTitle() {
      if (this.clientId && this.workflows.length) {
        return `Workflows for ${this.workflows[0].clientName}`;
      }
      return 'My Workflows';
    },
  },
  methods: {
    setWorkflowTypeFilter(value) {
      this.$router.push({ query: { ...this.$props, type: value } });
    },
    setWorkflowStatusFilter(value) {
      if (value === 'All') {
        value = undefined;
      }
      this.$router.push({ query: { ...this.$props, status: value } });
    },
    setWorkflowStepNameFilter(value) {
      if (value === 'All') {
        value = undefined;
      }
      this.$router.push({ query: { ...this.$props, step: value } });
    },
    async updateWorkflowSteps() {
      this.filterByStepNameOptions = [];
      const lastStep = this.step;

      try {
        const stepList = await fetchActiveWorkflowSteps(
          this.type,
          this.status,
          this.searchValue,
          this.clientId,
        );

        this.filterByStepNameOptions = [
          'All',
          ...(_.sortBy((stepList.map(((step) => ({
            value: step,
            text: WORKFLOW.STEP_DISPLAY_NAMES[step],
          })))), ['text'])),
        ];
        if (!stepList.includes(lastStep)) {
          this.step = '';
        }
      } catch (e) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error fetching active workflow steps',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async exportWorkflowList() {
      let workflowList = [];
      const { totalCount, workflows } = await fetchWorkflows(
        0,
        this.sortBy,
        this.sortDir,
        this.type,
        this.status,
        this.searchValue,
        this.clientId,
        this.step,
        this.totalCount,
      );
      workflowList = workflows.map((workflow) => {
        const currentStep = _.head(workflow.steps);
        let step = '';
        if (currentStep) {
          step = _.get(WORKFLOW.STEP_DISPLAY_NAMES, currentStep.stepName, '');
        }
        return {
          clientName: workflow.clientName,
          clientEmail: workflow.clientEmail,
          id: workflow.id,
          workflowType: workflow.workflowType,
          workflowStatus: workflow.workflowStatus,
          workflowUpdatedAt: workflow.workflowUpdatedAt,
          step,
        };
      });

      return workflowList;
    },
    async fetchWorkflows(offset, reset = false) {
      this.loading = true;
      this.currentOffset = offset;
      try {
        const { totalCount, workflows } = await fetchWorkflows(
          offset,
          this.sortBy,
          this.sortDir,
          this.type,
          this.status,
          this.searchValue,
          this.clientId,
          this.step,
          null,
        );
        this.totalCount = totalCount;
        if (reset) {
          this.workflows = workflows;
        } else {
          this.workflows = [...this.workflows, ...workflows];
        }
        this.currentOffset += workflows.length;
      } catch (e) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error fetching workflows',
          type: 'error',

        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
      this.loading = false;
    },
    showFetchingWorkflowSnackbar() {
      this.$store.dispatch('setSnackbar', {
        text: 'Fetching workflow list. This might take a few seconds...',
        type: 'success',

      });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    hideFetchingWorkflowSnackbar() {
      this.$store.dispatch('flipSnackbarSwitch', false);
    },
    generateExportFileName() {
      const d = new Date();
      return `workflows-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}.csv`;
    },
    fetchMoreWorkflows() {
      this.fetchWorkflows(this.currentOffset);
    },
    transformWorkflowTypes() {
      this.workflowTypes = this.allWorkflowTypes.map((wf) => ({
        text: wf.name,
        value: wf.workflow_type,
      }));

      this.workflowTypes
        .sort((a, b) => a.text.toLowerCase()
          .localeCompare(b.text.toLowerCase()))
        .unshift({ text: 'All', value: '' });
    },
  },
  watch: {
    clientId() {
      this.fetchWorkflows(0, true);
      this.updateWorkflowSteps();
    },
    status() {
      this.fetchWorkflows(0, true);
      this.updateWorkflowSteps();
    },
    type() {
      this.fetchWorkflows(0, true);
      this.updateWorkflowSteps();
    },
    step() {
      this.fetchWorkflows(0, true);
    },
    searchValue() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = undefined;
      }
      this.searchTimer = setTimeout(() => {
        this.$router.push({ query: { ...this.$props, search: this.searchValue } });
        this.fetchWorkflows(0, true);
      }, 500); // 0.5 sec delay to allow more typing before committing the search
    },
  },
};

</script>

<style lang="scss">
  @import '~Styles/variables';

  .filter-label {
    width: 50px;
    color: $light-text !important;
  }
</style>
