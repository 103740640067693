<template>
  <div>
    <template v-if="!clientLoaded">
      <v-layout align-center class="loader-layout">
        <v-flex>
          <PasCircularLoader :loadingMessage="`It'll Just Be A Moment...`"/>
        </v-flex>
      </v-layout>
    </template>

    <template v-else>
      <PasPageHeader :path="`/clients/${activeClient.client.id}`">
        {{activeClient.client ? `${activeClient.client.display_name}'s ` : ""}}Documents
      </PasPageHeader>

      <PasTabs class="tabs-page-view">
        <template v-slot:tabTitle1>Client Documents</template>
        <template v-slot:tabItem1>
          <v-layout row justify-start>
            <v-flex shrink mb-4>
              <PasSearchBox
                v-model="filterOptions.search"
                :placeholder="`Search document...`"
              />
            </v-flex>
            <v-flex shrink ml-3>
              <PasButton v-if="canEditDocuments" @click="action = 'upload'" class="primary-btn px-5">
                Upload
              </PasButton>
            </v-flex>
          </v-layout>
          <DocumentList
            :loading="!clientLoaded"
            :documents="activeFilteredDocuments"
            @documentAction="documentAction"
            @toggleSort="toggleSort($event)"
            :sortBy="filterOptions.sortBy"
            :sortDir="filterOptions.sortDir"
          />
          <v-flex xs12 text-xs-center mt-4>
            <PasPagination
              :length="activeDocPaginationLength"
              :value="filterOptions.page"
              @input="filterOptions.page = $event"
            />
          </v-flex>
        </template>

        <template v-slot:tabTitle2>Archived Documents</template>
        <template v-slot:tabItem2>
          <v-layout row justify-start>
            <v-flex shrink mb-4>
              <PasSearchBox
                v-model="archivedFilterOptions.search"
                :placeholder="`Search archived document...`"
              />
            </v-flex>
          </v-layout>
          <ArchivedDocumentList
            :loading="!clientLoaded"
            :documents="archivedFilteredDocuments"
            @documentAction="documentAction"
            @toggleSort="toggleSort($event)"
            :sortBy="archivedFilterOptions.sortBy"
            :sortDir="archivedFilterOptions.sortDir"
          />
          <v-flex xs12 text-xs-center mt-4>
            <PasPagination
              :length="archivedDocPaginationLength"
              :value="archivedFilterOptions.page"
              @input="archivedFilterOptions.page = $event"
            />
          </v-flex>
        </template>

        <template v-slot:tabTitle3>Template Forms</template>
        <template v-slot:tabItem3>
          <DocumentTemplateList
            :loading="!clientLoaded"
            :documents="templateDocuments"
            @documentAction="documentAction"
            @toggleSort="toggleSort($event)"
            :sortBy="filterOptions.sortBy"
            :sortDir="filterOptions.sortDir"
            :style="{ maxWidth: '620px' }"
          />
        </template>

      </PasTabs>
    </template>

    <DocumentUpload
      :isOpen="action === 'upload'"
      @close="action = ''"
      :documents="activeDocuments"
    />

    <ConfirmActionDialog
      :isOpen="action === 'delete'"
      @close="action = ''"
      @ok="deleteFile"
      :width="'400px'"
    >
      <template v-slot:header>Delete this file</template>
      Are you sure you want to proceed?
      <template v-slot:btn>Delete</template>
    </ConfirmActionDialog>

    <ConfirmActionDialog
      :isOpen="action === 'send'"
      @close="action = ''"
      @ok="sendFile"
      :width="'600px'"
    >
      <template v-slot:header>Share with the client</template>
      Documents can only be sent to client's email address:
      {{activeClient.client ? activeClient.client.email : ''}}
      <template v-slot:btn>Share</template>
    </ConfirmActionDialog>

    <ConfirmActionDialog
      :isOpen="action === 'archive'"
      @close="action = ''"
      @ok="updateFile('archive')"
      :width="'600px'"
    >
      <template v-slot:header>Archive this file</template>
      Are you sure you want to proceed?
      <template v-slot:btn>Archive</template>
    </ConfirmActionDialog>
  </div>
</template>

<script>

// Libraries and services
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import {
  FILE_EXTENSIONS, FILE_TYPES, DOCUMENT_SOURCE,
  DOCUMENT_TYPES, DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE,
} from 'Services/constants';
import api from 'Services/api';
import { modal } from 'Services/mixins';
import { fetchDocumentTemplates } from 'Services/api/Documents';

// Components
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';
import DocumentList from './ClientDocuments/DocumentList';
import DocumentUpload from './ClientDocuments/DocumentUpload';
import ArchivedDocumentList from './ClientDocuments/ArchivedDocumentList';
import DocumentTemplateList from './ClientDocuments/DocumentTemplateList';

export default {
  name: 'ClientDocuments',
  components: {
    DocumentTemplateList,
    ArchivedDocumentList,
    ConfirmActionDialog,
    DocumentUpload,
    DocumentList,
    DocumentList,
  },
  props: {
    clientLoaded: Boolean,
  },
  mixins: [modal],
  data() {
    return {
      filterOptions: {
        search: '',
        sortBy: '',
        sortDir: -1,
        page: 1,
        perPage: 7,
      },
      archivedFilterOptions: {
        search: '',
        sortBy: '',
        sortDir: -1,
        page: 1,
        perPage: 7,
      },
      activeFilteredDocuments: [],
      archivedFilteredDocuments: [],
      templateDocuments: [],
      activeDocPaginationLength: 1,
      archivedDocPaginationLength: 1,
      action: '',
      documentId: undefined,
    };
  },
  async created() {
    await this.fetchAllDocumentTypes();
  },
  mounted() {
    if (this.documents.length) this.filterAndSort();
    if (this.archivedDocuments.length) this.archiveDocFilterAndSort();
    fetchDocumentTemplates()
      .then((res) => this.templateDocuments = res.results)
      .catch((err) => {
        const text = 'Please refresh the page';
        this.snackBar('fail', text);
      });
  },
  computed: {
    ...mapGetters([
      'canEditDocuments',
      'custodian',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
    activeDocuments() {
      return this.$store.state.clients.activeClient.documents.filter((doc) => !doc.is_archived);
    },
    archivedDocuments() {
      return this.$store.state.clients.activeClient.documents.filter((doc) => doc.is_archived);
    },
    router() {
      return this.$router;
    },
    computedFilterOptions() {
      return { ...this.filterOptions };
    },
    archivedComputedFilterOptions() {
      return { ...this.archivedFilterOptions };
    },
    docManagementV2Enabled() {
      return this.$store.getters.hasFeatureFlag('doc_management_v2');
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  methods: {
    ...mapActions([
      'fetchAllDocumentTypes',
    ]),
    documentAction(action, documentId) {
      if (action === 'download') return this.downloadFile(documentId);
      this.action = action;
      this.documentId = documentId;
      if (action === 'unarchive') return this.updateFile();
    },
    sendFile() {
      this.action = '';
      const doc = this.documents.filter((doc) => doc.id === this.documentId)[0];
      const email = encodeURIComponent(this.activeClient.client.email);
      const aws_key = encodeURIComponent(doc.aws_key);
      const user = encodeURIComponent(this.currentUser.name);
      let name = doc.document_name;
      const fileNameExt = name.substr(name.lastIndexOf('.') + 1);
      if (!FILE_EXTENSIONS.includes(fileNameExt)) {
        const awsKeyExt = doc.aws_key.substr(doc.aws_key.lastIndexOf('.') + 1);
        name += `.${FILE_EXTENSIONS.includes(awsKeyExt) ? awsKeyExt : FILE_TYPES[doc.file_type]}`;
      }
      api.get('/advisor_portal/send_document', `doc=${aws_key}`, `email=${email}`, `filename=${encodeURIComponent(name)}`, `name=${user}`)
        .then((res) => {
          if (res.status !== 200) throw Error;
          this.handleResponse('send', 'success');
        })
        .catch((err) => this.handleResponse('send', 'fail'));
    },
    downloadFile(documentId) {
      let doc = this.templateDocuments.filter((d) => d.id === documentId)[0];
      if (!doc) doc = this.documents.filter((d) => d.id === documentId)[0];
      let url = `${window.location.protocol}//${window.location.host}`;
      url += `/advisor_portal/view_document?doc=${encodeURIComponent(doc.aws_key)}`;
      fetch(url, {
        method: 'GET',
        redirect: 'manual',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      }).then((res) => {
        if (res.error) throw Error;
        let filename = doc.document_name;

        // the three lines below can later be deleted if all the documents names have ext
        const fileNameExt = filename.substr(filename.lastIndexOf('.') + 1);
        if (!FILE_EXTENSIONS.includes(fileNameExt)) {
          const awsKeyExt = doc.aws_key.substr(doc.aws_key.lastIndexOf('.') + 1);
          filename += `.${FILE_EXTENSIONS.includes(awsKeyExt) ? awsKeyExt : FILE_TYPES[doc.file_type]}`;
        }

        return res.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', filename);
          a.click();
        });
      })
        .catch((err) => this.handleResponse('download', 'fail'));
    },
    handleResponse(action, type) {
      let text;
      if (action === 'delete') {
        text = type === 'success' ? 'Document successfully deleted' : 'Failed to delete the document';
      }
      if (action === 'send') {
        text = type === 'success' ? 'You have successfully shared the document' : 'Failed to send the document';
      }
      if (action === 'download') {
        text = 'Document download failed';
      }
      if (action === 'archive') {
        text = type === 'success' ? 'Document successfully archived' : 'Failed to archive the document';
      }
      if (action === 'unarchive') {
        text = type === 'success' ? 'Document successfully unarchived' : 'Failed to unarchive the document';
      }
      this.snackBar(type, text);
    },
    deleteFile() {
      this.action = null;
      const docToDelete = this.documents.filter((doc) => doc.id === this.documentId)[0];
      if (docToDelete.document_source === this.documentSource.Workflow) {
        const text = 'Cannot delete file uploaded through workflow';
        this.snackBar('fail', text);
        return;
      }
      // This is a complete hack. We need to refactor the deleteDocument and deleteWorkflowDocument.
      // But, for now, let's just check if the custodian is FCC and if so, use the deleteDocument.
      // Otherwise, use the deleteWorkflowDocument.
      let data = { docID: docToDelete.id, clientID: this.activeClient.client.id };
      let deleteCallType = 'deleteDocument';
      if (docToDelete.associated_workflows[0] && this.custodian !== 'fcc') {
        data = { awsKey: docToDelete.aws_key, workflow: docToDelete.associated_workflows[0] };
        deleteCallType = 'deleteWorkflowDocument';
      }

      this.$store.dispatch(deleteCallType, data)
        .then((res) => {
          this.handleResponse('delete', res ? 'success' : 'fail');
          this.$store.dispatch('setDocuments', this.activeClient.client.id);
        })
        .catch((err) => this.handleResponse('delete', 'fail'));
    },
    // archive and unarchive function
    updateFile() {
      const docToUpdate = this.documents.filter((doc) => doc.id === this.documentId)[0];
      if (this.action === 'archive') {
        docToUpdate.is_archived = true;
        docToUpdate.archived_at = moment().toISOString();
      }
      if (this.action === 'unarchive') {
        docToUpdate.is_archived = false;
        docToUpdate.archived_at = null;
      }
      this.$store.dispatch('updateDocument', docToUpdate)
        .then((res) => {
          this.handleResponse(this.action, res ? 'success' : 'fail');
          this.action = null;
        })
        .catch((err) => this.handleResponse('archive', 'fail'));
    },
    toggleSort({
      sortBy, sortDir, archivedSortBy, archivedSortDir,
    }) {
      this.filterOptions.sortBy = sortBy;
      this.filterOptions.sortDir = sortDir;
      this.archivedFilterOptions.sortBy = archivedSortBy;
      this.archivedFilterOptions.sortDir = archivedSortDir;
    },
    filterAndSort() {
      const opts = this.filterOptions;
      this.activeFilteredDocuments = _.cloneDeep(this.activeDocuments);

      // filter out GAP report
      this.activeFilteredDocuments = this.activeFilteredDocuments.filter((d) => d.document_type != '24');
      this.archivedFilteredDocuments = this.archivedFilteredDocuments.filter((d) => d.document_type != '24');

	    // search filter
      if (opts.search) {
        this.activeFilteredDocuments = this.activeFilteredDocuments.filter((d) => d.document_name.toLowerCase().indexOf(opts.search.toLowerCase()) !== -1);
      }

      // sort filter
      if (opts.sortBy === 'Title') {
        this.activeFilteredDocuments = this.activeFilteredDocuments.sort((a, b) => {
          const docA = a.document_name.toLowerCase().trim();
          const docB = b.document_name.toLowerCase().trim();
          return docA < docB ? opts.sortDir : opts.sortDir * -1;
        });
      }
      if (opts.sortBy === 'Category') {
        this.activeFilteredDocuments = this.activeFilteredDocuments.sort((a, b) => {
          const docA = DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE[a.document_type].toLowerCase().trim();
          const docB = DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE[b.document_type].toLowerCase().trim();
          return docA < docB ? opts.sortDir : opts.sortDir * -1;
        });
      }
      if (opts.sortBy === 'Upload Type') {
        this.activeFilteredDocuments = this.activeFilteredDocuments.sort((a, b) => {
          const docA = DOCUMENT_SOURCE[a.document_source].toLowerCase().trim();
          const docB = DOCUMENT_SOURCE[b.document_source].toLowerCase().trim();
          return docA < docB ? opts.sortDir : opts.sortDir * -1;
        });
      }
      if (opts.sortBy === 'Upload Date') {
        this.activeFilteredDocuments = this.activeFilteredDocuments.sort((a, b) => (moment(b.created_at).isSameOrAfter(moment(a.created_at)) ? opts.sortDir : opts.sortDir * -1));
      }

      this.activeDocPaginationLength = this.activeFilteredDocuments.length ? Math.ceil(this.activeFilteredDocuments.length / opts.perPage) : 1;
      const start = (opts.page - 1) * opts.perPage;
      const end = opts.page * opts.perPage;
      this.activeFilteredDocuments = this.activeFilteredDocuments.slice(start, end);
    },
    archiveDocFilterAndSort() {
      const archivedOpts = this.archivedFilterOptions;
      this.archivedFilteredDocuments = _.cloneDeep(this.archivedDocuments);

      // filter out GAP report
      this.archivedFilteredDocuments = this.archivedFilteredDocuments.filter((d) => d.document_type !== '24');

      // sort and search for archived document list
      if (archivedOpts.search) {
        this.archivedFilteredDocuments = this.archivedFilteredDocuments.filter((d) => d.document_name.toLowerCase().indexOf(archivedOpts.search.toLowerCase()) !== -1);
      }

      if (archivedOpts.sortBy === 'Title') {
        this.archivedFilteredDocuments = this.archivedFilteredDocuments.sort((a, b) => {
          const docA = a.document_name.toLowerCase().trim();
          const docB = b.document_name.toLowerCase().trim();
          return docA < docB ? archivedOpts.sortDir : archivedOpts.sortDir * -1;
        });
      }
      if (archivedOpts.sortBy === 'Category') {
        this.archivedFilteredDocuments = this.archivedFilteredDocuments.sort((a, b) => {
          const docA = DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE[a.document_type].toLowerCase().trim();
          const docB = DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE[b.document_type].toLowerCase().trim();
          return docA < docB ? archivedOpts.sortDir : archivedOpts.sortDir * -1;
        });
      }
      if (archivedOpts.sortBy === 'Upload Type') {
        this.archivedFilteredDocuments = this.archivedFilteredDocuments.sort((a, b) => {
          const docA = DOCUMENT_SOURCE[a.document_source].toLowerCase().trim();
          const docB = DOCUMENT_SOURCE[b.document_source].toLowerCase().trim();
          return docA < docB ? archivedOpts.sortDir : archivedOpts.sortDir * -1;
        });
      }
      if (archivedOpts.sortBy === 'Archived Date') {
        this.archivedFilteredDocuments = this.archivedFilteredDocuments.sort((a, b) => (moment(b.archived_at).isSameOrAfter(moment(a.archived_at)) ? archivedOpts.sortDir : archivedOpts.sortDir * -1));
      }

      this.archivedDocPaginationLength = this.archivedFilteredDocuments.length ? Math.ceil(this.archivedFilteredDocuments.length / archivedOpts.perPage) : 1;
      const archivedStart = (archivedOpts.page - 1) * archivedOpts.perPage;
      const archivedEnd = archivedOpts.page * archivedOpts.perPage;
      this.archivedFilteredDocuments = this.archivedFilteredDocuments.slice(archivedStart, archivedEnd);
    },
  },
  watch: {
    computedFilterOptions: {
      handler(newVal, oldVal) {
        if (newVal.page === oldVal.page) this.filterOptions.page = 1;
        this.filterAndSort();
      },
      deep: true,
    },
    archivedComputedFilterOptions: {
      handler(newVal, oldVal) {
        if (newVal.page === oldVal.page) this.archivedFilterOptions.page = 1;
        this.archiveDocFilterAndSort();
      },
      deep: true,
    },
    documents() {
      this.filterAndSort();
      this.archiveDocFilterAndSort();
    },
  },
};
</script>
