import { render, staticRenderFns } from "./SetupRifPayment.vue?vue&type=template&id=11229d77&scoped=true&"
import script from "./SetupRifPayment.vue?vue&type=script&lang=js&"
export * from "./SetupRifPayment.vue?vue&type=script&lang=js&"
import style0 from "./SetupRifPayment.vue?vue&type=style&index=0&id=11229d77&prod&lang=scss&scoped=true&"
import style1 from "./SetupRifPayment.vue?vue&type=style&index=1&id=11229d77&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11229d77",
  null
  
)

export default component.exports