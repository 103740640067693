<template>
  <v-dialog v-model="isOpen" persistent max-width="650px">
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <PasPageTitle class="mb-4">RC520 Document Upload</PasPageTitle>
      <PasDropzone ref="pasDropzone" :id="'docDrapzone'" :options="dropzoneOptions"/>
      <div class="error--text mt-2 ml-3" v-if="errorText">
        <span class="v-messages__message">{{errorText}}</span>
      </div>
      <v-layout mt-4 align-center justify-end row fill-height>
        <v-flex shrink>
          <PasButton @click="close">Cancel</PasButton>
        </v-flex>
        <v-flex shrink>
          <PasButton class="wide-btn ml-2" @click="complete">Mark as Completed</PasButton>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { modal } from 'Services/mixins';
import { DOCUMENT_SOURCE } from 'Services/constants';

export default {
  name: 'UploadDocument',
  mixins: [modal],
  props: {
    isOpen: Boolean,
    workflowId: Number,
  },
  data() {
    const that = this;
    return {
      dropzoneOptions: {
        url: '/advisor_portal/upload_document',
        init() {
          this.on('sending', (file, xhr, formData) => {
            const documentName = that.activeClient.client.display_name
              || that.activeClient.client.id;
            formData.append('client_id', that.activeClient.client.id);
            formData.append('document_type', 45); // RC520
            formData.append('workflow', that.workflowId);
            formData.append('document_source', that.documentSource.Manual);
            formData.append('document_name', `RC-520 for ${documentName}`);
          });
          this.on('success', (file, res) => {
            if (res.message === 'Success') {
              that.snackBar('success');
              that.$store.dispatch('setDocuments', that.activeClient.client.id);
            } else {
              that.snackBar('fail');
              this.removeFile(file);
            }
          });
          this.on('error', (file) => {
            that.snackBar('fail');
            this.removeFile(file);
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        maxFilesize: 3, // MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 3000000, // Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: '.png,.jpeg,.jpg,.pdf',
      },
      errorText: '',
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  methods: {
    complete() {
      this.errorText = '';
      if (!this.documents.some((d) => d.document_type === '45')) {
        this.errorText = 'In order to complete this step, please upload RC520 Document for the client';
        return;
      }
      this.$emit('done');
    },
    snackBar(type) {
      let text = 'Oops... Something went wrong, please try upoading the file again';
      if (type === 'success') text = 'The doument was successfully uploaded';
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
};
</script>

<style>
</style>
