<template>
  <h3>
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'pas-page-subtitle',
};
</script>

<style scoped>
h3 {
  font-weight: 300;
  font-size: 22px;
}
</style>
