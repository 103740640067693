<template>
  <v-layout row justify-space-between>
    <v-flex
      v-for="(item, i) in financialOverview"
      :key="i"
      class="financial-snapshot-flex"
      xs3
      >
      <v-card class="financial-snapshot" >
        <v-card-text>
        <div class="small-text p-text">Current</div>
        <!--Remove until editing functionality implemented
          <PasFab :class="`edit-btn ${item.class}`">
          <v-icon
            small
            @click=""
            >
              edit
          </v-icon>
        </PasFab>
        -->
        <br>
        <div class="strong-number">{{item.amount ? `$${item.amount}` : '$0'}}</div>
        <br>
        <div class="type p-text">{{item.type}}</div>    
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>

import { commaFormatted } from 'Services/helpers/currency';

export default {
  name: 'financial-overview',
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    financialOverview () {
      let totalAssets = Number(this.activeClient.financials.liquid_assets) + Number(this.activeClient.financials.fixed_personal_assets);
      let totalDebt = this.activeClient.financials.total_debt
      let netWorth = this.activeClient.financials.net_worth
      let total_aum = this.activeClient.total_aum ? this.activeClient.total_aum : 0

      return [
        {
          type: "Client AUM",
          amount: commaFormatted(Math.round(total_aum)),
          class: "icon hidden"
        },
        {
          type: "Total Value in Assets",
          amount: totalAssets ? commaFormatted(Math.round(totalAssets)) : null,
          class: "icon visible"
        },
        {
          type: "Total Debt",
          amount: totalDebt ? commaFormatted(Math.round(this.activeClient.financials.total_debt)) : null,
          class: "icon visible"
        },
        {
          type: "Total Net Worth",
          amount: netWorth ? commaFormatted(Math.round(this.activeClient.financials.net_worth)) : null,
          class: "icon hidden"
        }
      ];
    }
  },
}

</script>

<style lang="scss">
@import '~Styles/variables';

.financial-snapshot-flex {
  margin-right:24px !important;
  &:last-of-type {
    margin-right: 0px !important;
  }
}
.financial-snapshot {
  line-height:14px;
  

  .icon {
    font-size: 16px;
    color: rgba(0,0,0,0.5) !important;
  }
  .hidden {
    display:none;
  }
  .edit-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    &:hover, &:focus {
      position: absolute !important;
    }
    &:focus:before {
      background-color: #fff !important;
    }
  }
// .last { margin-bottom: 0 !important; margin-right: 0 !important; }


}

</style>




