<template>
  <WorkflowStep :isCanceled="workflow.status === 'cancelled'" :step="step">
    <div v-if="hasFullWorkflowEditPermission">
      <span>Document has been sent out to client</span>
      <br />
      <div>
        <span
          v-if="isManualWorkflow"
          @click.prevent="completeDocumentGeneration"
          class="action-text"
        >
          Mark as complete
        </span>
      </div>
    </div>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  sendDocumentGenerationKYCSuccess, handleStepSuccess, handleStepError,
} from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'DocumentGenerationStepKYCWorkflow',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      default: () => { },
    },
    isManualWorkflow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async completeDocumentGeneration() {
      try {
        const res = await sendDocumentGenerationKYCSuccess(this.workflow);
        await handleStepSuccess(res, this.$store, this.workflow.id);
      } catch (err) {
        await handleStepError(this.$store);
      }
    },
  },
  computed: {
    ...mapGetters([
      'hasFullWorkflowEditPermission',
    ]),
  },
};
</script>

<style scoped></style>
