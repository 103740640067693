<template>
  <pas-fullscreen-modal
    :isOpen="isOpen"
    @close="closeModal"
    >
    <template v-if="!clientDataCheck">
      <v-layout fill-height align-center justify-content :style="{'height': '100vh'}">
        <pas-notification-div :style="{margin: 'auto'}">
          Please ensure this client has completed their account opening & risk questionnaires
        </pas-notification-div>
      </v-layout>
    </template>
    <pas-wizard v-else
      @back="stage-=1"
      @next="stage+=1"
    >

      <!-- Wizard stage 1 - Choose IPS type -->
      <pas-wizard-stage
        :title="`Choose type of IPS material`"
        :subtitle="`Please select which option best matches your client's needs`"
        :displayed="stage===1"
        isFirstStage
        :progress="15"
        :key="`stage-1`"
        :showNextButton="false"
        contentMaxWidth="700px"
      >
        <v-layout>
          <v-flex xs6 text-xs-center>
            <action-card
              :action="`${activeClient.client.display_name}`"
              :imagePath="activeClient.person.sex === 'male' ? MaleSvg : FemaleSvg"
              :useButton="false"
              class="circle-image-action-card action-card-height"
              @click="ipsTypeClick('individual')"
            >
              If your client wants to maintain an individual degree of freedom over their finances
            </action-card>
          </v-flex>
          <v-flex xs6 text-xs-center v-if="spouse ">
            <action-card
              :action="`For both ${activeClient.client.display_name} & ${spouse ? spouse.display_name : ''}`"
              :imagePath="CoupleSvg"
              :useButton="false"
              class="circle-image-action-card action-card-height"
              @click="ipsTypeClick('spousal')"
            >
              Combine their IPS documents
            </action-card>
          </v-flex>
        </v-layout>
      </pas-wizard-stage>

      <!-- Wizard stage 2 - select accounts -->
      <pas-wizard-stage
        title="Specify which accounts this IPS covers"
        subtitle="Choose which account or legal/corporate entities you wish to include on this IPS"
        :displayed="stage===2"
        :progress="30"
        @next="submitStage(2)"
        :key="`stage-2`"
        contentMaxWidth="700px"
        showNextButton
        :navigationDisabled="loadingSpouseData || loadingJointData || loadingCorporateData || loadingSpouseCorporateData || loadingSpouseJointData"
        persistent
      >
        <v-layout fill-height align-content-center justify-content
          v-if="loadingSpouseData"
        >
          <pas-circular-loader :style="{margin: 'auto'}">Loading spousal data...</pas-circular-loader>
        </v-layout>
        <v-layout v-else>

        <!-- If the ips type is individual, use the full width. If spousal, split into two columns - one for individual, one for spousal -->
        <v-flex :xs12="ipsType==='individual'"
                :xs5="ipsType==='spousal'"
        >

        <!-- Column header - select all accounts switch + client name -->
        <v-layout row nowrap :style="{maxWidth: '700px'}">
          <v-flex>
            <span class="strong-text">{{`${activeClient.client.display_name}'s account(s)`}}</span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex>
            <v-layout justify-end>
                <pas-switch
                  @change="allAccounts=$event"
                  :value="allAccounts"
                  :input-value="allAccounts"
                  :key="`individual-switch`"
                ></pas-switch>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- Listing all individual accounts -->
        <v-layout column>
          <template v-for="(account, index) in individualAccounts">
            <v-layout justify-start align-center :key="`account-${index}` ">
              <PasCheckbox
                @change="selectAccount($event, account)"
                :value="accountsSelected.includes(account)"
                :key="`individual-account-checkbox-${index}`"
                class="shrink mr-3"
              />
              <div>{{account.nickname || `${ACCOUNTTYPE[19]} ${account.account_no_dataphile ? `- ${account.account_no_dataphile}` : ''}`}}</div>
            </v-layout>
          </template>
        </v-layout>
        </v-flex>

        <v-flex :xs2="ipsType==='spousal'"></v-flex>

        <!-- Conditional column if ips type is spousal, display the spouse's individual accounts -->
        <v-flex xs5 v-if="ipsType==='spousal'">

          <!-- Column header - select all accounts switch + spouse name -->
          <v-layout row nowrap :style="{maxWidth: '700px'}">
          <v-flex>
            <span class="strong-text">{{`${spouse.display_name}'s account(s)`}}</span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex>
            <v-layout justify-end>
                <pas-switch
                  @change="allSpouseAccounts=$event"
                  :value="allSpouseAccounts"
                  :input-value="allSpouseAccounts"
                  :key="`spousal-switch`"
                  :disabled="!spouseIndividualAccounts.length"
                ></pas-switch>
            </v-layout>
          </v-flex>
          </v-layout>

          <!-- List spouse's individual accounts -->
          <v-layout column>
            <template v-for="(account, index) in spouseIndividualAccounts">
              <v-layout justify-start align-center :key="`spousal-account-${index}` ">
                <PasCheckbox
                  @change="selectAccount($event, account)"
                  :value="accountsSelected.includes(account)"
                  :key="`spousal-account-checkbox-${index}`"
                  class="shrink mr-3"
                />
                <div>{{account.nickname}}</div>
              </v-layout>
            </template>
          </v-layout>
        </v-flex>
        </v-layout>

        <!-- List all primary client's joint accounts  -->
        <v-layout fill-height align-content-center justify-content
          v-if="loadingJointData"
        >
          <pas-circular-loader :style="{margin: 'auto'}">Loading joint account data...</pas-circular-loader>
        </v-layout>
        <v-layout v-else>
        <v-layout v-if="(jointClientName.primary && Object.keys(jointClientName.primary).length)" column mt-4>
            <v-layout row nowrap :style="{maxWidth: '700px'}">
              <v-flex>
                <span class="strong-text">{{`${activeClient.client.display_name}'s joint account(s)`}}</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex>
                <v-layout justify-end>
                  <pas-switch
                    @change="allJointAccounts=$event"
                    :value="allJointAccounts"
                    :input-value="allJointAccounts"
                    :key="`joint-switch`"
                  ></pas-switch>
                </v-layout>
              </v-flex>
        </v-layout>

        <v-layout column>
          <template v-for="(account, index) in jointAccounts">
            <v-layout justify-start align-center :key="`account-${index}` ">
              <PasCheckbox
                @change="selectAccount($event, account)"
                :value="accountsSelected.includes(account)"
                :key="`individual-account-checkbox-${index}`"
                class="shrink mr-3"
              />
              <div><span>{{account.nickname || `${ACCOUNTTYPE[19]} ${account.account_no_dataphile ? `- ${account.account_no_dataphile}` : ''}`}}</span><span>{{account.joint_client_id && jointClientName.primary[account.id].length ? ` with ${jointClientName.primary[account.id].join()}`:''}}</span></div>
            </v-layout>
          </template>
        </v-layout>
      </v-layout>
      </v-layout>

      <!-- List all corporate accounts for primary client-->
      <v-layout fill-height align-content-center justify-content
          v-if="loadingCorporateData"
        >
          <pas-circular-loader :style="{margin: 'auto'}">Loading corporate data...</pas-circular-loader>
        </v-layout>
        <v-layout v-else>
        <v-layout v-if="corporatesAccounts.length" column mt-4>
            <v-layout row nowrap :style="{maxWidth: '700px'}">
              <v-flex>
                <span class="strong-text">{{`${activeClient.client.display_name}'s corporates account(s)`}}</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex>
                <v-layout justify-end>
                  <pas-switch
                    @change="allCorporateAccounts=$event"
                    :value="allCorporateAccounts"
                    :input-value="allCorporateAccounts"
                    :key="`joint-switch`"
                  ></pas-switch>
                </v-layout>
              </v-flex>
        </v-layout>

        <v-layout column>
          <template v-for="(account, index) in corporatesAccounts">
            <v-layout justify-start align-center :key="`account-${index}` ">
              <PasCheckbox
                @change="selectAccount($event, account)"
                :value="accountsSelected.includes(account)"
                :key="`individual-account-checkbox-${index}`"
                class="shrink mr-3"
              />
              <div><span>{{account.nickname || `CORP CASH ${account.account_no_dataphile ? `- ${account.account_no_dataphile}` : ''}`}}</span><span>{{account.primary_client_display_name? ` with ${account.primary_client_display_name}`:''}}</span></div>
            </v-layout>
          </template>
        </v-layout>
      </v-layout>
    </v-layout>

      <!-- List all spouse joint accounts and spouse corporate accounts -->
      <v-layout fill-height align-content-center justify-content
          v-if="loadingSpouseJointData"
        >
        <pas-circular-loader :style="{margin: 'auto'}">Loading corporate data...</pas-circular-loader>
      </v-layout>

      <!-- spouse joint accounts-->
      <v-layout v-else>
      <v-layout v-if="ipsType==='spousal' && (jointClientName.spouse && Object.keys(jointClientName.spouse).length)" column mt-4>
            <v-layout row nowrap :style="{maxWidth: '700px'}">
              <v-flex>
                <span class="strong-text">{{`${spouse.display_name}'s joint account(s)`}}</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex>
                <v-layout justify-end>
                  <pas-switch
                    @change="allSpouseJointAccounts=$event"
                    :value="allSpouseJointAccounts"
                    :input-value="allSpouseJointAccounts"
                    :key="`spouse-joint-switch`"
                  ></pas-switch>
                </v-layout>
              </v-flex>
        </v-layout>

        <v-layout column>
          <template v-for="(account, index) in spouseJointAccounts">
            <v-layout justify-start align-center :key="`account-${index}` ">
              <PasCheckbox
                @change="selectAccount($event, account)"
                :value="accountsSelected.includes(account)"
                :key="`individual-account-checkbox-${index}`"
                class="shrink mr-3"
              />
              <div><span>{{account.nickname || `${ACCOUNTTYPE[19]} ${account.account_no_dataphile ? `- ${account.account_no_dataphile}` : ''}`}}</span><span>{{account.joint_client_id && jointClientName.spouse[account.id].length ? ` with ${jointClientName.spouse[account.id].join()}`:''}}</span></div>
            </v-layout>
          </template>
        </v-layout>
        </v-layout>
      </v-layout>

      <!-- spouse corporates accounts-->
      <v-layout fill-height align-content-center justify-content
        v-if="loadingSpouseCorporateData"
      >
        <pas-circular-loader :style="{margin: 'auto'}">Loading corporate data...</pas-circular-loader>
      </v-layout>
      <v-layout v-else>
        <v-layout v-if="ipsType==='spousal' &&spouseCorporateAccounts.length" column mt-4>
            <v-layout row nowrap :style="{maxWidth: '700px'}">
              <v-flex>
                <span class="strong-text">{{`${spouse.display_name}'s corporates account(s)`}}</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex>
                <v-layout justify-end>
                  <pas-switch
                    @change="allSpouseCorporateAccounts=$event"
                    :value="allSpouseCorporateAccounts"
                    :input-value="allSpouseCorporateAccounts"
                    :key="`joint-switch`"
                  ></pas-switch>
                </v-layout>
              </v-flex>
        </v-layout>

        <v-layout column>
          <template v-for="(account, index) in spouseCorporateAccounts">
            <v-layout justify-start align-center :key="`account-${index}` ">
              <PasCheckbox
                @change="selectAccount($event, account)"
                :value="accountsSelected.includes(account)"
                :key="`individual-account-checkbox-${index}`"
                class="shrink mr-3"
              />
              <div><span>{{account.nickname || `CORP CASH ${account.account_no_dataphile ? `- ${account.account_no_dataphile}` : ''}`}}</span><span>{{account.primary_client_display_name? ` with ${account.primary_client_display_name}`:''}}</span></div>
            </v-layout>
          </template>
        </v-layout>
      </v-layout>
      </v-layout>

        <v-layout mt-3 justify-center>
          <pas-error-div v-if="stageTwoErrorText !== ''">{{stageTwoErrorText}}</pas-error-div>
        </v-layout>
      </pas-wizard-stage>

      <!-- Stage 3, select investment objectives -->
      <pas-wizard-stage
        title="Define your client goals"
        :subtitle="`Let's start by determining ${namesString}'s investment goals and objectives`"
        :displayed="stage===3"
        :progress="45"
        contentMaxWidth="768px"
        :key="`stage-3`"
        @next="submitStage(3)"
        class="ips-wizard-stage-3"
      >
        <v-layout row nowrap>
          <v-flex xs4>
            <action-card
              action="Retirement Age"
              :imagePath="RetirementSvg"
              :useButton="false"
              :class="[{'action-card-selected': objectives.retirement},'circle-image-action-card', 'action-card-height']"
              @click="$set(objectives, 'retirement', !objectives.retirement)"
            >
              <v-layout :column="ipsType==='individual'" nowrap align-center justify-content>
                <v-flex v-if="!(ipsType==='spousal')" mb-2>
                  <span class="strong-text">{{activeClient.client.display_name}}</span>
                </v-flex>
                <v-layout align-baseline>
                  <v-flex xs6 v-if="ipsType==='spousal'">
                    <span class="strong-text">{{activeClient.client.display_name.substr(0, activeClient.client.display_name.indexOf(' '))}}</span>
                  </v-flex>
                  <v-flex pl-3 :class="[ipsType==='spousal'? 'xs6':'']" :key="`client-age-dropdown`">
                    <v-hover>
                      <div slot-scope="{ hover }" :class="{'padding-left-15': ipsType==='individual'}">
                        <div v-if="hover && ipsType==='spousal'" class="pas-dark-tooltip">
                          <span class="white-text">{{activeClient.client.display_name}}</span>
                        </div>
                        <pas-input-field-list
                          v-model="retirementAge"
                          :items="retirementAges"
                          :class="[ipsType==='spousal'? 'ips-spouse-retirement-age-dropdown':'ips-retirement-age-dropdown mr-3']"
                        >
                        </pas-input-field-list>
                      </div>
                    </v-hover>
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout align-baseline v-if="ipsType==='spousal'">
                <v-flex xs6>
                  <span class="strong-text">{{spouse.display_name.substr(0, spouse.display_name.indexOf(' '))}}</span>
                </v-flex>
                <v-flex xs6 pl-3 :key="`spouse-age-dropdown`">
                  <v-hover>
                    <div slot-scope="{ hover }">
                      <div v-if="hover" class="pas-dark-tooltip">
                        <span class="white-text">{{spouse.display_name}}</span>
                      </div>
                      <pas-input-field-list
                        v-model="spouseRetirementAge"
                        :items="retirementAges_S"
                        :class="[ipsType==='spousal'? 'ips-spouse-retirement-age-dropdown':'ips-retirement-age-dropdown']"
                      >
                      </pas-input-field-list>
                    </div>
                  </v-hover>
                </v-flex>
              </v-layout>

            </action-card>
          </v-flex>

          <v-flex xs4>
            <action-card
              action="Education for Children or Grandchildren"
              :imagePath="EducationSvg"
              :useButton="false"
              :class="{'circle-image-action-card':true, 'action-card-selected': objectives.education, 'action-card-height': true} "
              @click="$set(objectives, 'education', !objectives.education)"
            >Help out with education expenses.</action-card>
          </v-flex>
          <v-flex xs4>
            <action-card
              action="Lifestyle Enhancements"
              :imagePath="LifestyleSvg"
              :useButton="false"
              :class="{'circle-image-action-card':true, 'action-card-selected': objectives.lifestyle, 'action-card-height': true}"
              @click="$set(objectives, 'lifestyle', !objectives.lifestyle)"
            >Help achieve their desired lifestyle.</action-card>
          </v-flex>
        </v-layout>
        <v-layout row nowrap mt-3>
          <v-flex xs4>
            <action-card
              action="Charitable Giving"
              :imagePath="CharitableGivingSvg"
              :useButton="false"
              :class="{'circle-image-action-card':true, 'action-card-selected': objectives.charity, 'action-card__pt': true, 'action-card-height': true}"
              @click="$set(objectives, 'charity', !objectives.charity)"
            >Help strategize generosity.</action-card>
          </v-flex>
          <v-flex xs4>
            <action-card
              action="Legacy for Future Generations"
              :imagePath="LegacySvg"
              :useButton="false"
              :class="{'circle-image-action-card':true, 'action-card-selected': objectives.legacy, 'action-card-height': true,  }"
              @click="$set(objectives, 'legacy', !objectives.legacy)"
            >Help secure wealth for future generations.</action-card>
          </v-flex>
          <v-flex xs4>
            <action-card
              action="Significant Purchases"
              :imagePath="SignificantPurchasesSvg"
              :useButton="false"
              :class="{'circle-image-action-card':true, 'action-card-selected': objectives.purchases, 'action-card__pt': true, 'action-card-height': true}"
              @click="$set(objectives, 'purchases', !objectives.purchases)"
            >Plan for future big spends.</action-card>
          </v-flex>
        </v-layout>
        <v-layout mt-3 justify-center>
          <pas-error-div v-if="stageThreeErrorText ? true : false">{{stageThreeErrorText}}</pas-error-div>
        </v-layout>
      </pas-wizard-stage>

      <!-- Stage 4, Specify liquidity needs and mandates -->
      <pas-wizard-stage
        title="Specify liquidity needs and mandate"
        :subtitle="`Define ${namesString}'s liquidity requirements and investment mandate`"
        :displayed="stage===4"
        :progress="60"
        contentMaxWidth="768px"
        :key="`stage-4`"
        @next="submitStage(4)"
      >
        <!-- List existing systematic withdrawals -->
        <v-form
          ref="stageFourForm"
          lazy-validation
          @submit.prevent=""
        >


        <v-layout>
          <v-flex xs6>
            <v-layout row wrap>
              <v-flex xs12>
                <span class="strong-text form-label">{{activeClient.client.display_name}}</span>
              </v-flex>
              <v-flex xs12 mt-4>
                <span class="strong-text form-label">Recurring withdrawals:</span>
              </v-flex>
              <template v-for="(systematic, index) in recurringWithdraws">
                <v-flex xs12 :key="`withdrawals-${index}`">
                  <span v-if="!recurringWithdraws.length">{{`No recurring withdraws currently set up for ${activeClient.client.display_name}`}}</span>
                  <span v-else>
                    <span v-if="systematic.minimum">{{`Minimum out of ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}</span>
                    <span v-else-if="systematic.maximum">{{`Maximum out of ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}</span>
                    <span v-else>{{`$${systematic.amount} out of ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}</span>
                  </span>
                </v-flex>
              </template>
              <v-flex xs12 v-if="!recurringWithdraws.length">
                {{ `None currently set up`}}
              </v-flex>
            </v-layout>

            <!-- List existing systematic deposits -->
            <v-layout mt-4>
              <span class="strong-text form-label">Recurring deposits:</span>
            </v-layout>
            <v-layout row wrap>
              <template v-for="(systematic, index) in recurringDeposits">
                <v-flex xs12  :key="`deposits-${index}`">
                  {{`$${systematic.amount} into ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}
                </v-flex>
              </template>
              <v-flex xs12 v-if="!recurringDeposits.length">
                {{ `None currently set up`}}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 v-if="ipsType==='spousal'">
            <v-layout row wrap>
              <v-flex xs12>
                <span class="strong-text form-label">{{spouse.display_name}}</span>
              </v-flex>
              <v-flex xs12 mt-4>
                <span class="strong-text form-label">Recurring withdrawals:</span>
              </v-flex>
              <template v-for="(systematic, index) in spousalRecurringWithdraws">
                <v-flex xs12 :key="`withdrawals-${index}`">
                  <span v-if="!spousalRecurringWithdraws.length">{{`No recurring withdraws currently set up for ${spouse.display_name}`}}</span>
                  <span v-else>
                    <span v-if="systematic.minimum">{{`Minimum out of ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}</span>
                    <span v-else-if="systematic.maximum">{{`Maximum out of ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}</span>
                    <span v-else>{{`$${systematic.amount} out of ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}</span>
                  </span>
                </v-flex>
              </template>
              <v-flex xs12 v-if="!spousalRecurringWithdraws.length">
                {{ `None currently set up`}}
              </v-flex>
            </v-layout>

            <!-- List existing systematic deposits -->
            <v-layout mt-4>
              <span class="strong-text form-label">Recurring deposits:</span>
            </v-layout>
            <v-layout row wrap>
              <template v-for="(systematic, index) in spousalRecurringDeposits">
                <v-flex xs12  :key="`deposits-${index}`">
                  {{`$${systematic.amount} into ${systematic.accountName} - ${systematic.accountDataphileId}, ${systematic.frequency}`}}
                </v-flex>
              </template>
              <v-flex xs12 v-if="!spousalRecurringDeposits.length">
                {{ `None currently set up`}}
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>


        <!-- Liquidity requirements statements   -->
        <v-layout mt-4>
          <span class="strong-text form-label">Are there any other requirements related with liquidity? Please specify below.</span>
        </v-layout>
        <v-layout>
          <pas-textarea
            :placeholder="`Type your notes here...`"
            v-model="liquidityRequirementsNotes"
          >
          </pas-textarea>
        </v-layout>

        <!-- Investment mandate dropdowns  -->
        <v-layout mt-4>
          <span class="strong-text form-label">Select the type of Investment Mandate</span>
        </v-layout>
        <v-layout v-if="mandatesLoading">
          <pas-circular-loader :loadingMessage="`Loading Team Mandates...`">
          </pas-circular-loader>
        </v-layout>
        <template v-else>
        <v-layout v-if="ipsType==='spousal'">
          <v-flex :xs6="['xs','sm','md'].includes(breakpoint)"
                  :xs4="['lg','xl'].includes(breakpoint)"
          >{{activeClient.client.display_name}}</v-flex>
          <v-flex xs4 v-if="['lg','xl'].includes(breakpoint)"></v-flex>
          <v-flex :xs6="['xs','sm','md'].includes(breakpoint)"
                  :xs4="['lg','xl'].includes(breakpoint)"
          >{{spouse.display_name}}</v-flex>
        </v-layout>
        <v-layout row nowrap mt-2>
          <v-flex :xs6="['xs','sm','md'].includes(breakpoint)"
                  :xs4="['lg','xl'].includes(breakpoint)"
          >
            <pas-input-field-list
              v-model="investmentMandate"
              :items="investmentMandates"
              :style="{maxWidth: '280px'}"
              :rules="[v.isRequired('Please select an investment mandate')]"
            >
            </pas-input-field-list>
          </v-flex>
          <v-flex xs4 v-if="['lg','xl'].includes(breakpoint)"></v-flex>
          <template v-if="ipsType==='spousal'">
            <v-flex :xs6="['xs','sm','md'].includes(breakpoint)"
                    :xs4="['lg','xl'].includes(breakpoint)"
            >
              <pas-input-field-list
                v-model="spousalInvestmentMandate"
                :items="investmentMandates"
                :style="{maxWidth: '280px'}"
                :rules="[v.isRequired('Please select an investment mandate')]"
              ></pas-input-field-list>
            </v-flex>
          </template>
        </v-layout>
        </template>
        </v-form>
      </pas-wizard-stage>

      <!-- Stage 5 statements -->
      <pas-wizard-stage
        title="How will the investments be managed"
        :subtitle="`Help your client have peace of mind by defining how the investments will be managed`"
        :displayed="stage===5"
        :progress="75"
        contentMaxWidth="768px"
        :key="`stage-5`"
        @next="submitStage(5)"
      >
        <v-form
          ref="stageFiveForm"
          lazy-validation
          @submit.prevent=""
        >
          <v-layout mt-4>
            <span class="strong-text form-label">Identify any relevant preferences or restrictions on the investment (e.g. non-tradable securities, sectors, industries, etc...)</span>
          </v-layout>
          <v-layout>
            <pas-textarea
              :placeholder="`Type your notes here...`"
              v-model="restrictionsNotes"
              class="p-text"
            >
            </pas-textarea>
          </v-layout>
          <v-layout mt-4>
            <span class="strong-text form-label">Rebalancing*: Describe your rebalancing process to set expectations with your client</span>
          </v-layout>
          <v-layout>
            <pas-textarea
              class="p-text"
              v-model="rebalancingStatement"
              :placeholder="`Type your notes here...`"
              :rules="[v.isRequired('Please provide a rebalancing statement')]"
              >
            </pas-textarea>

          </v-layout>
          <v-layout mt-4>
            <span class="strong-text form-label">Security Selection*: Describe your investment methodology</span>
          </v-layout>
          <v-layout>
            <pas-textarea
              class="p-text"
              v-model="methodologyStatement"
              :placeholder="`Type your notes here...`"
              :rules="[v.isRequired('Please provide a methodology statement')]"
              >
            </pas-textarea>
          </v-layout>
        </v-form>
      </pas-wizard-stage>

      <!-- Stage 6 statements  -->
      <pas-wizard-stage
        title="Final Details"
        :subtitle="`Let's access issues such as risk management process, portfolio performance reviews and proxy voting`"
        :displayed="stage===6"
        :progress="90"
        contentMaxWidth="768px"
        :key="`stage-6`"
        @next="stage+=1"
        :showNextButton="custodian === 'fcc' && portfolioAssignmentFeatureEnabled"
      >
        <v-form
          ref="stageSixForm"
          lazy-validation
          @submit.prevent=""
          :class="{'no-pointer': createIpsProcessing}"
        >
        <!-- Risk management statement -->
        <v-layout mt-4>
          <span class="strong-text form-label">Risk Management Statement*: Describe all the risk management processes you follow</span>
        </v-layout>
        <v-layout>
          <pas-textarea
            class="p-text"
            v-model="riskManagementStatement"
            :placeholder="`Type your notes here...`"
            :rules="[v.isRequired('Please provide a risk management statement')]"
            >
          </pas-textarea>
        </v-layout>
        <!-- Review frequency -->
        <v-layout mt-4>
          <span class="strong-text form-label">Review Statement*: How frequently will you review the portfolio performance? What type of information will you provide?</span>
        </v-layout>
        <v-layout>
          <pas-textarea
            class="p-text"
            v-model="reviewStatement"
            :placeholder="`Type your notes here...`"
            :rules="[v.isRequired(`Please provide a portfolio review statement`)]"
            >
          </pas-textarea>
        </v-layout>

        <!-- Proxy voting -->
        <v-layout mt-4>
          <span class="strong-text form-label">Proxy Voting:</span>
        </v-layout>
        <v-layout mt-2 justify-space-between>
          <v-flex>
            <v-layout row child-flex>
              <PasCheckbox
               @change="proxyVoteOption!==IPSTYPES.PROXY_VOTING.ALL ? proxyVoteOption = IPSTYPES.PROXY_VOTING.ALL : proxyVoteOption = ''"
               :value="proxyVoteOption === IPSTYPES.PROXY_VOTING.ALL"
               :disabled="!proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.ALL]"
              />
              <v-flex class="margin-auto">
                Vote all proxies
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row child-flex justify-center>
              <PasCheckbox
               @change="proxyVoteOption!==IPSTYPES.PROXY_VOTING.OURS ? proxyVoteOption = IPSTYPES.PROXY_VOTING.OURS : proxyVoteOption = ''"
               :value="proxyVoteOption === IPSTYPES.PROXY_VOTING.OURS"
               :disabled="!proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.OURS]"
              />
              <v-flex class="margin-auto">
                Vote proxies for our models
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row child-flex justify-end>
              <PasCheckbox
               @change="proxyVoteOption!==IPSTYPES.PROXY_VOTING.NON_STANDARD ? proxyVoteOption = IPSTYPES.PROXY_VOTING.NON_STANDARD : proxyVoteOption = ''"
               :value="proxyVoteOption === IPSTYPES.PROXY_VOTING.NON_STANDARD"
               :disabled="!proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.NON_STANDARD]"
              />
              <v-flex class="margin-auto">
                Vote only non-standard proxies
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row child-flex justify-end>
              <PasCheckbox
               @change="proxyVoteOption!==IPSTYPES.PROXY_VOTING.NONE ? proxyVoteOption = IPSTYPES.PROXY_VOTING.NONE : proxyVoteOption = ''"
               :value="proxyVoteOption === IPSTYPES.PROXY_VOTING.NONE"
               :disabled="!proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.NONE]"
              />
              <v-flex class="margin-auto">
                Don’t vote proxies
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-flex class="fixedHeight">
          <pas-error-div
            :show="proxyVoteOption === ''"
            ref="proxyVote">
            Please select a proxy vote option.
          </pas-error-div>
        </v-flex>

        <v-layout mt-4>
          <span class="strong-text form-label">{{isFirm(activeClient.client.firm_id) === 'mil' ? `Tiered Fee Levels based on Household Assets` : `Fee Schedule`}}</span>
        </v-layout>

        <v-layout v-if="isFirm(activeClient.client.firm_id) === 'mil'" mt-2 >
          <v-data-table class="milFeeSchedule"
            :headers="feeRangeHeadersMil"
            :items="feeRangeBrackets(activeClient.client.firm_id, activeClient.client.rep_code)"
            :items-per-page="8"
            hide-actions
          >
          <template v-slot:items="props">
            <td class="text-xs-left">{{ props.item.aum }}</td>
            <td class="text-xs-left">{{ props.item.fee1 }}</td>
            <td class="text-xs-left">{{ props.item.fee2 }}</td>
            <td class="text-xs-left">{{ props.item.fee3 }}</td>
            <td class="text-xs-left">{{ props.item.fee4 }}</td>
            <td class="text-xs-left">{{ props.item.fee5 }}</td>
            <td class="text-xs-left">{{ props.item.fee6 }}</td>
            <td class="text-xs-left">{{ props.item.fee7 }}</td>
          </template>
          </v-data-table>
        </v-layout>

        <v-layout v-if="isFirm(activeClient.client.firm_id) === 'mil'" mt-2>
          <v-data-table
            :headers="ICPFeeHeaders"
            :items="ICPFee"
            hide-actions
          >
          <template v-slot:items="props">
            <td class="text-xs-left">{{ props.item.leftCol }}</td>
            <td class="text-xs-left">{{ props.item.SMAValue }}</td>
            <td class="text-xs-left">{{ props.item.ETFValue }}</td>
            <td class="text-xs-left">{{ props.item.note }}</td>
          </template>
          </v-data-table>
        </v-layout>

        <v-layout v-else mt-2>
          <v-data-table
            :headers="feeRangeHeaders"
            :items="feeRangeBrackets(activeClient.client.firm_id, activeClient.client.rep_code)"
            :items-per-page="5"
            hide-actions
          >
          <template v-slot:items="props">
            <td class="text-xs-left">{{ props.item.aum }}</td>
            <td class="text-xs-rleft">{{ props.item.fee }}</td>
          </template>
          </v-data-table>
        </v-layout>
        </v-form>
        <V-layout v-if="custodian !== 'fcc' || !portfolioAssignmentFeatureEnabled" justify-end mt-5>
          <pas-button class="primary-btn px-5 mr-4" @click="preview" :processing="previewIpsProcessing">
            Preview
          </pas-button>
          <pas-button @click="save" class="primary-btn wide-btn px-5" :processing="createIpsProcessing">
            Create IPS
          </pas-button>
        </V-layout>
      </pas-wizard-stage>
      <pas-wizard-stage
        :title="`Portfolio assignment`"
        :subtitle="`Please select a model portfolio for each account`"
        :displayed="stage===7"
        :progress="100"
        :key="`stage-7`"
        :showNextButton="false"
        contentMaxWidth="800px"
      >
        <PortfolioAssignmentTab :saveBtn="false" ref="portfolioAssignment"/>
        <V-layout justify-end mt-5>
          <pas-button class="primary-btn px-5 mr-4" @click="preview" :processing="previewIpsProcessing">
            Preview
          </pas-button>
          <pas-button @click="save" class="primary-btn wide-btn px-5" :processing="createIpsProcessing">
            Create IPS
          </pas-button>
        </V-layout>
      </pas-wizard-stage>
    </pas-wizard>
  </pas-fullscreen-modal>
</template>

<script>
import _ from 'lodash';
import '@babel/polyfill';
import { mapGetters } from 'vuex';
import v from 'Services/validation';
import api from 'Services/api';
import {
  FREQUENCIES, IPSTYPES, ACCOUNTTYPE, TRILOGY_REP_CODES, LAINCHBURY_REP_CODES,
  LAKESHORE_REP_CODES, NICOLL_REP_CODES, GAIT_REP_CODES,
} from 'Services/constants';
import { fetchTeamMandates, fetchTeamLanguages } from 'Services/api/ClientDataHierarchy';
import { fetchRrifPayments, fetchSystematics } from 'Services/api/Client';
import ActionCard from 'Components/Shared/ActionCard';
import { isFirm } from 'Services/helpers/auth';
import { getAge } from 'Services/helpers/time';
import { modal } from 'Services/mixins';
import { createIpsWorkflow } from 'Services/api/Workflows';
import {
  fetchClientPMGroup, leavePMGroup, createPMGroup, joinPMGroup,
} from 'Services/api/ClientGroupings';
import {
  fetchClientPortfolio, createClientPortfolio,
} from 'Services/api/PortfolioAssignment';
import PortfolioAssignmentTab from '../ClientEdit/RiskProfileTab/PortfolioAssignmentTab';


export default {
  name: 'generate-ips-docs',
  components: {
    ActionCard,
    PortfolioAssignmentTab,
  },
  data() {
    return {
      IPSTYPES,
      createIpsProcessing: false,
      previewIpsProcessing: false,
      ACCOUNTTYPE,
      allowedAccountTypes: ['T', 'P', 'O'],
      ipsLoading: false,
      loadingSpouseData: false,
      loadingJointData: false,
      stageTwoErrorText: '',
      stageThreeErrorText: '',
      v,
      stage: 0,
      ipsType: '',
      spousalSystematics: [],
      spousalRrifPayments: [],
      allJointAccounts: false,
      allSpouseJointAccounts: false,
      allSpouseAccounts: false,
      allAccounts: false,
      allCorporateAccounts: false,
      allSpouseCorporateAccounts: false,
      spouseAccounts: [], // all spouse accounts
      corporatesAccounts: [], // primary client corporate accounts
      spouseCorporateAccounts: [],
      loadingCorporateData: false,
      loadingSpouseJointData: false,
      loadingSpouseCorporateData: false,
      clientAccountsClone: _.cloneDeep(this.clientAccounts),
      accountsSelected: [],
      objectives: {
        retirement: false,
        education: false,
        lifestyle: false,
        charity: false,
        legacy: false,
        purchases: false,
      },
      retirementAge: 65,
      spouseRetirementAge: 65,
      liquidityRequirementsNotes: '',
      investmentMandates: [],
      investmentMandate: '',
      spousalInvestmentMandate: '',
      restrictionsNotes: '',
      rebalancingStatement: '',
      methodologyStatement: '',
      reviewStatement: '',
      riskManagementStatement: '',
      proxyVoteOption: null,
      proxyVotingIPSDescription: {},
      fees: _.map(_.range(0, 100), (n) => (n / 10).toFixed(2)),
      fee: 1.25,
      feeRangeHeaders: [
        {
          text: 'Assets Under Management (CAD$)',
          align: 'left',
          sortable: false,
          value: 'aum',
        },
        {
          text: 'Management Fees (per annum)',
          sortable: false,
          value: 'fee',
        },
      ],
      feeRangeHeadersMil: [
        {
          text: 'MWM Account Classification',
          align: 'start',
          sortable: false,
          value: 'aum',
        },
        {
          text: '<500K',
          sortable: false,
          value: 'fee1',
        },
        {
          text: '500K<1M',
          sortable: false,
          value: 'fee2',
        },
        {
          text: '1M<2M',
          sortable: false,
          value: 'fee3',
        },
        {
          text: '2M<3M',
          sortable: false,
          value: 'fee4',
        },
        {
          text: '3M<5M',
          sortable: false,
          value: 'fee5',
        },
        {
          text: '5M<10M',
          sortable: false,
          value: 'fee6',
        },
        {
          text: '>10M',
          sortable: false,
          value: 'fee7',
        },
      ],
      ICPFeeHeaders: [
        {
          text: '',
          sortable: false,
          value: 'leftCol',
        },
        {
          text: 'SMA',
          sortable: false,
          value: 'SMAValue',
        },
        {
          text: 'ETF Portfolios',
          sortable: false,
          value: 'ETFValue',
        },
        {
          text: 'Note',
          sortable: false,
          value: 'note',
        },
      ],
      ICPFee: [
        {
          leftCol: "Canaccord's ICP Fees:",
          SMAValue: '0.70%',
          ETFValue: '0.30%',
          note: 'This includes 5bps technology fee',
        },
      ],
      mandatesLoading: false,
      isFirm,
      jointClientName: {
        primary: {},
        spouse: {},
      },
    };
  },
  mixins: [modal],
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'firm',
    ]),
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    custodian() {
      return this.$store.state.app.custodian;
    },
    age() {
      return this.activeClient.person.date_of_birth ? getAge(this.activeClient.person.date_of_birth) : null;
    },
    retirementAges() {
      return _.range(this.age ? this.age : 18, 99);
    },
    spouseAge() {
      return this.spousePersonDetails.date_of_birth ? getAge(this.spousePersonDetails.date_of_birth) : null;
    },
    retirementAges_S() {
      return _.range(this.spouseAge ? this.spouseAge : 18, 99);
    },
    spouse() {
      const spouse = this.activeClient.relationships.find((rel) => rel.relationship_type === 'SP' || rel.relationship_type === 'CL');
      if (spouse) return spouse.client_details[0];
      return null;
    },
    spousePersonDetails() {
      const spouse = this.activeClient.relationships.find((rel) => rel.relationship_type === 'SP' || rel.relationship_type === 'CL');
      if (spouse) return spouse.client_details[1].person_details[0];
      return null;
    },
    relationships() {
      return this.$store.state.clients.activeClient.relationships.filter((a) => this.allowedAccountTypes.includes(a.account_status));
    },
    systematics() {
      const systematics = [];
      this.$store.state.clients.activeClient.systematics.forEach((s) => {
        s.systematics.forEach((systematic) => systematics.push({
          amount: systematic.amount,
          accountName: s.account_info.nickname,
          accountDataphileId: s.account_info.account_no_dataphile,
          frequency: FREQUENCIES[systematic.frequency],
          type: systematic.type,
        }));
      });
      return systematics;
    },
    rrifPayments() {
      const rrifPayments = [];
      this.$store.state.clients.activeClient.rrifPayments.forEach((r) => {
        const acc = this.clientAccounts.filter((a) => a.id === r.account_id)[0];
        rrifPayments.push({
          amount: r.payment_amount,
          accountName: acc.nickname,
          accountDataphileId: acc.account_no_dataphile,
          frequency: FREQUENCIES[r.payment_frequency],
          minimum: r.minimum,
          maximum: r.lockedin_max_pay_selected,
          type: '2',
        });
      });
      return rrifPayments;
    },
    recurringWithdraws() {
      let withdraws = [];
      withdraws = this.systematics.filter((s) => ['2'].includes(s.type) && s.frequency.toLowerCase() !== 'one-time').concat(this.rrifPayments);
      return withdraws;
    },
    recurringDeposits() {
      return this.systematics.filter((s) => ['1'].includes(s.type) && s.frequency.toLowerCase() !== 'one-time');
    },
    spousalRecurringWithdraws() {
      let withdraws = [];
      withdraws = this.spousalSystematics.filter((s) => ['2'].includes(s.type) && s.frequency.toLowerCase() !== 'one-time').concat(this.spousalRrifPayments);
      return withdraws;
    },
    spousalRecurringDeposits() {
      return this.spousalSystematics.filter((s) => ['1'].includes(s.type) && s.frequency.toLowerCase() !== 'one-time');
    },
    clientAccounts() {
      return this.activeClient.investment_accounts.filter((a) => this.allowedAccountTypes.includes(a.account_status));
    },
    individualAccounts() {
      return this.clientAccounts.filter((a) => !a.joint_indicator);
    },
    jointAccounts() {
      return this.clientAccounts.filter((a) => a.joint_indicator);
    },
    spouseIndividualAccounts() {
      return this.spouseAccounts.filter((a) => !a.joint_indicator);
    },
    spouseJointAccounts() {
      const { activeClient } = this;
      return this.spouseAccounts.filter((a) => a.joint_indicator
      && a.joint_client_id[0] !== activeClient.client.id
      && a.primary_client_id !== activeClient.client.id);
    },
    namesString() {
      if (this.ipsType === 'individual') return this.activeClient.client.display_name;
      if (this.ipsType === 'spousal') return `${this.activeClient.client.display_name} and ${this.spouse.display_name}`;
    },
    clientDataCheck() {
      // TODO convert this to a more complete check
      return this.activeClient.employment && Object.keys(this.activeClient.employment).length && this.activeClient.riskScores && Object.keys(this.activeClient.riskScores).length;
    },
    pmGroupsEnabled() {
      return this.$store.state.app.features.pm_groups;
    },
    portfolioAssignmentFeatureEnabled() {
      return this.$store.state.app.features.portfolio_assignment;
    },
    FemaleSvg() { return this.$store.state.app.themeAssets.female; },
    MaleSvg() { return this.$store.state.app.themeAssets.male; },
    CoupleSvg() { return this.$store.state.app.themeAssets.couple; },
    CharitableGivingSvg() { return this.$store.state.app.themeAssets.charitableGiving; },
    EducationSvg() { return this.$store.state.app.themeAssets.education; },
    LegacySvg() { return this.$store.state.app.themeAssets.legacy; },
    LifestyleSvg() { return this.$store.state.app.themeAssets.lifestyle; },
    RetirementSvg() { return this.$store.state.app.themeAssets.retirement; },
    SignificantPurchasesSvg() { return this.$store.state.app.themeAssets.significantPurchases; },
  },
  async mounted() {
    try {
      this.mandatesLoading = true;
      let pmTeam;
      if (this.hasFeatureFlag('client_data_hierarchy')) {
        pmTeam = this.activeClient.client.pm_team;
      } else {
        // delete these hardcoded PM teams once data hierarchy is moved to all firms.
        const pmTeamMap = { har: 4, fwp: 4, mil: 1 };
        pmTeam = pmTeamMap[this.firm];
      }
      const responses = await Promise.all([fetchTeamMandates(pmTeam), fetchTeamLanguages(pmTeam)]);

      const mandatesRes = responses[0];
      const languageRes = responses[1];
      this.investmentMandates = mandatesRes.results.map((m) => ({
        text: m.name,
        value: m,
      }));
      this.mandatesLoading = false;
      languageRes.results.forEach((m) => {
        const { text } = m;
        switch (m.language_type) {
          case IPSTYPES.REBALANCING:
            this.rebalancingStatement = text;
            break;
          case IPSTYPES.SECURITY_SELECTION:
            this.methodologyStatement = text;
            break;
          case IPSTYPES.RISK_MANAGEMENT:
            this.riskManagementStatement = text;
            break;
          case IPSTYPES.REVIEWS:
            this.reviewStatement = text;
            break;
          case IPSTYPES.PROXY_VOTING.ALL:
            this.proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.ALL] = text;
            break;
          case IPSTYPES.PROXY_VOTING.OURS:
            this.proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.OURS] = text;
            break;
          case IPSTYPES.PROXY_VOTING.NON_STANDARD:
            this.proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.NON_STANDARD] = text;
            break;
          case IPSTYPES.PROXY_VOTING.NONE:
            this.proxyVotingIPSDescription[IPSTYPES.PROXY_VOTING.NONE] = text;
            break;
        }
      });
      this.loadingJointData = true;
      this.fetchJointAccountName('individual');
      this.loadingJointData = false;
    } catch (err) {
      console.log('err', err);
    }

    if (this.custodian === 'fcc' && this.portfolioAssignmentFeatureEnabled) {
      const suitabilityScore = await fetchClientPortfolio(this.activeClient.client.id);
      if (!suitabilityScore.results || !suitabilityScore.results.length) await createClientPortfolio({ client: this.activeClient.client.id });
    }

    if (this.activeClient.client.fee_group) {
      api.get(`/datahub/fee_groups/${this.activeClient.client.fee_group}/`)
        .then((feeGroup) => {
          this.fee = (feeGroup.fixed_fee * 100).toFixed(2);
        })
        .catch((err) => {
          console.log('get fee group error', err);
        });
    }
  },
  methods: {
    submitStage(stage) {
      switch (stage) {
        case 2:
          if (!this.accountsSelected.length) {
            this.stageTwoErrorText = 'Please select at least one account to generate the IPS for';
            return false;
          }
          this.stageTwoErrorText = '';
          this.stage += 1;

          break;
        case 3:
          if (!Object.values(this.objectives).some((v) => v)) {
            this.stageThreeErrorText = 'Please select at least one objective';
            return false;
          }
          this.stageThreeErrorText = '';
          this.stage += 1;

          break;
        case 4:
          if (!this.$refs.stageFourForm.validate()) return false;
          this.stage += 1;
          break;
        case 5:
          if (!this.$refs.stageFiveForm.validate()) return false;
          this.stage += 1;
          break;
      }
    },
    async save() {
      if (this.proxyVoteOption === null) this.proxyVoteOption = '';
      if (!(this.$refs.stageSixForm.validate() && this.proxyVoteOption !== '')) return false;
      this.createIpsProcessing = true;
      const that = this;

      const ipsData = await this.getIpsData();
      // TODO: fee_groups end points are being redesigned, need to revisit related api calls once the endpoints is done.
      // update client fee groups
      // const fee_res = await api.post('/datahub/fee_groups/', {
      //   fee_type: "F",
      //   fixed_fee: this.fee / 100
      // })
      // if (fee_res.code) {
      //   this.snackbarFail()
      //   this.createIpsProcessing = false
      //   return
      // }
      const clientPromises = [];
      // clientPromises.push(api.post(`/datahub/clients/${this.activeClient.client.id}/`, {
      //   fee_group_id: fee_res.id
      // }))
      // if (this.ipsType === 'spousal') {
      //   clientPromises.push(api.post(`/datahub/clients/${this.spouse.id}/`, {
      //     fee_group_id: fee_res.id
      //   }))
      // }
      if (this.custodian === 'fcc' && this.portfolioAssignmentFeatureEnabled) {
        this.$refs.portfolioAssignment.save();
      }

      const client_promise_res = await Promise.all(clientPromises);
      if (client_promise_res.some((res) => res.code)) {
        this.snackbarFail();
        this.createIpsProcessing = false;
        return;
      }

      const mandatePromises = [];
      mandatePromises.push(api.post(`/datahub/clients/${this.activeClient.client.id}/mandates/`, {
        mandate: ipsData.mandates.primary.id,
      }));

      if (ipsData.mandates.secondary) {
        mandatePromises.push(api.post(`/datahub/clients/${this.spouse.id}/mandates/`, {
          mandate: ipsData.mandates.secondary.id,
        }));
      }
      let mandates_res;
      try {
        mandates_res = await Promise.all(mandatePromises);
      } catch (err) {
        console.log('error in mandates promises', err);
        throw new Error('error upading mandates');
      }
      if (mandates_res.some((res) => res.code)) {
        this.snackbarFail();
        this.createIpsProcessing = false;
        console.log('update client mandates failed', res);
        return;
      }
      if (this.hasFeatureFlag('new_workflows')) {
        try {
          if (this.pmGroupsEnabled) {
            this.setPMGroup(this.activeClient.client.id, this.ipsType === 'spousal' ? this.spouse.id : null);
          }
          await createIpsWorkflow([this.activeClient.client.id], {
            ips_data: ipsData,
            client: this.activeClient.client.id,
            spouse: this.spouse ? this.spouse.id : null,
          });
          this.$store.dispatch('setSnackbar', { text: 'IPS workflow created', type: 'success' });
          this.$store.dispatch('flipSnackbarSwitch');
          this.createIpsProcessing = false;
          this.$store.dispatch('getWorkflows', this.activeClient.client.id);
          this.closeModal();
        } catch (err) {
          this.createIpsProcessing = false;
          this.snackbarFail();
          console.log('ips signing workflow creation error', err);
        }
      } else {
        if (this.pmGroupsEnabled) {
          this.setPMGroup(this.activeClient.client.id, this.ipsType === 'spousal' ? this.spouse.id : null);
        }
        const res = await api.post('/advisor_portal/workflow/new/', {
          pas_client_ids: [this.activeClient.client.id],
          workflow_type: 'ips_signing',
          workflow_specific_data: {
            ipsData,
            clientId: this.activeClient.client.id,
            spouseId: this.spouse ? this.spouse.id : null,
          },
        });
        try {
          if (res.workflow_id) {
            this.$store.dispatch('setSnackbar', { text: 'IPS workflow created', type: 'success' });
            this.$store.dispatch('flipSnackbarSwitch');
            this.createIpsProcessing = false;
            this.$store.dispatch('getWorkflows', this.activeClient.client.id);
            this.closeModal();
          }
        } catch (err) {
          this.createIpsProcessing = false;
          this.snackbarFail();
          console.log('ips signing workflow creation error', err);
        }
      }
    },
    async setPMGroup(primaryId, secondaryId) {
      const primary = await fetchClientPMGroup(primaryId);
      if (primary.result) {
        const [primaryMembershipId] = primary.result.clients.filter((c) => c.client === primaryId).map((c) => c.id);
        await leavePMGroup(primaryMembershipId);
      }
      let name = `${this.activeClient.client.display_name}'s PM group`;
      if (secondaryId) {
        const { person } = this.activeClient;
        name = `${person.first_name}'s and ${this.spouse.display_name}'s PM group`;
      }
      const newGroup = await createPMGroup({ name, description: name });

      await joinPMGroup({
        pm_group: newGroup.id,
        client: primaryId,
      });
      if (secondaryId) {
        const secondary = await fetchClientPMGroup(secondaryId);
        if (secondary.result) {
          const [secondaryMembershipId] = secondary.result.clients.filter((c) => c.client === secondaryId).map((c) => c.id);
          await leavePMGroup(secondaryMembershipId);
        }
        await joinPMGroup({
          pm_group: newGroup.id,
          client: secondaryId,
        });
      }
      this.$store.dispatch('setPMGroup', primaryId);
    },
    snackbarFail() {
      this.$store.dispatch('setSnackbar', { text: 'IPS workflow creation failed', type: 'error' });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    previewFail() {
      this.$store.dispatch('setSnackbar', { text: 'IPS preview failed', type: 'error' });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    getCorporateAccounts(corporate_roles) {
      let corporatesAccounts = [];
      return Promise.all(corporate_roles.filter((role) => role.trading_authority).map((role) => this.$store.dispatch('getAccountsByClientID', role.corporate_client)))
        .then((res) => {
          res.forEach((corporate) => {
            if (corporate.accounts.length) {
              corporate.accounts.filter((a) => this.allowedAccountTypes.includes(a.account_status))
                .map((acc) => { acc.primary_client_display_name = corporate.display_name || ''; acc.corporate_account_indicator = true; });
              corporatesAccounts = [...corporatesAccounts, ...corporate.accounts];
            }
          });
          return corporatesAccounts;
        })
        .catch((err) => console.log('get corporate account error', err));
    },
    async fetchAllAccountsValue(accountsSelected) { // get missing account value in accountSelected, and push value into accountSelected Array
      return Promise.all(accountsSelected.filter((account) => !account.hasOwnProperty('value')).map((acc) => this.$store.dispatch('getAccountValueByAccountID', acc.id)))
        .then((res) => {
          const accountValueMap = new Map();
          res.forEach((r) => { if (r.status === 'success') accountValueMap.set(r.requested_payload.accounts[0].id, r.requested_payload.accounts[0].holdings.length ? r.requested_payload.accounts[0].holdings[0].total_value : 0); });
          accountsSelected = accountsSelected.map((acc) => ({
            ...acc,
            value: acc.hasOwnProperty('value') ? acc.value : accountValueMap.get(acc.id),
          }));
          return accountsSelected;
        });
    },
    async getIpsData() {
      const accountsSelected = await this.fetchAllAccountsValue(_.uniqBy(this.accountsSelected, 'id')); // remove dupllicate accounts
      return {
        ipsType: this.ipsType,
        accountsSelected,
        objectives: {
          ...this.objectives,
          primaryRetirementAge: this.retirementAge,
          spouseRetirementAge: this.spouseRetirementAge,
        },
        systematics: this.systematics,
        rrifPayments: this.rrifPayments,
        spousalSystematics: this.spousalSystematics,
        spousalRrifPayments: this.spousalRrifPayments,
        restrictions: this.restrictionsNotes,
        mandates: {
          primary: this.investmentMandate,
          secondary: this.spousalInvestmentMandate,
        },
        statements: {
          liquidityRequirements: this.liquidityRequirementsNotes,
          restrictionsNotes: this.restrictionsNotes,
          rebalancingStatement: this.rebalancingStatement,
          methodologyStatement: this.methodologyStatement,
          reviewStatement: this.reviewStatement,
          riskManagementStatement: this.riskManagementStatement,
        },
        proxyVotingIPSDescription: this.proxyVotingIPSDescription,
        proxyVoteOption: this.proxyVoteOption,
        fee: this.fee,
      };
    },
    async preview() {
      if (this.proxyVoteOption === null) this.proxyVoteOption = '';
      if (!(this.$refs.stageSixForm.validate() && this.proxyVoteOption !== '')) return false;
      this.previewIpsProcessing = true;
      const ipsData = await this.getIpsData();
      // if (!this.$refs.stageSixForm.validate()) return false
      const jwt = localStorage.getItem('jwt') || '';
      const that = this;
      fetch('/advisor_portal/ips/preview_statement', {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify({
          clientId: this.activeClient.client.id,
          spouseId: this.spouse ? this.spouse.id : null,
          ipsData,
        }),
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
	    })
        .then(function (t) {
          if (t.status !== 200) {
            this.previewIpsProcessing = false;
            throw new Error('Preview generation failed');
          } else {
            return t.blob().then((b) => {
              const pdfURL = that.showPDF(b, 'ips_statement.pdf');
              window.open('/advisor_portal/loader', '_blank').location = pdfURL;
              that.previewIpsProcessing = false;
            });
          }
        })
        .catch((err) => {
          console.log('error getting IPS preview', err);
          this.$store.dispatch('setSnackbar', { text: 'IPS preview generation failed', type: 'error' });
			  this.$store.dispatch('flipSnackbarSwitch');
        });
    },
    showPDF(blob, filename) {
      const newBlob = new Blob([blob], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      return data;


      // var link = document.createElement('a')
      // link.href = data
      // link.target="_blank"
      // link.click()
      // // window.open(data)
      // setTimeout(function () {
      // document.body.removeChild(link)
      // window.URL.revokeObjectURL(data)
      // 100
      // })
    },
    getJointAccountNameString(account) {
      const names = [this.activeClient.client.display_name];
      if (account.spousal_indicator) { // spousal
        names.push(this.spouse.display_name);
        return `${names.join("'s and ")}'s spousal account - ${account.nickname}`;
      }
      // joint
      account.joint_client_id.forEach((id) => {
        const client = this.relationships.find((r) => r.client_details[0].id === id);
        if (client) names.push(client.client_details[0].display_name);
      });
      return `${names.join("'s and ")}'s joint account - ${account.nickname}`;
    },
    selectAllIndividualAccounts(bool) {
      this.individualAccounts.forEach((account) => {
        this.selectAccount(bool, account);
      });
    },
    selectAllSpouseAccounts(bool) {
      this.spouseIndividualAccounts.forEach((account) => {
        this.selectAccount(bool, account);
      });
    },
    selectAllJointAccounts(bool) {
      this.jointAccounts.forEach((account) => {
        this.selectAccount(bool, account);
      });
    },
    selectAllCorporateAccounts(bool) {
      this.corporatesAccounts.forEach((account) => {
        this.selectAccount(bool, account);
      });
    },
    selectAllSpouseJointAccounts(bool) {
      this.spouseJointAccounts.forEach((account) => {
        this.selectAccount(bool, account);
      });
    },
    selectAllSpouseCorporateAccounts(bool) {
      this.spouseCorporateAccounts.forEach((account) => {
        this.selectAccount(bool, account);
      });
    },
    async selectAccount(bool, account) {
      const acc = account;
      if (bool) {
        this.accountsSelected = _.union(this.accountsSelected, [acc]);
      } else {
        this.accountsSelected = this.accountsSelected.filter((a) => a.id !== acc.id);
      }
    },
    ipsTypeClick(type) {
      this.ipsType = type, this.stage = 2;
    },
    feeRangeBrackets(firm, rep_code) {
      // todo: pull from database
      let feeSchedule = [
        {
          aum: `test fee schedule for ${rep_code}`,
          fee: 'test',
        },
      ];
      // FWP fee schedule
      if (isFirm(firm) === 'fwp') {
        feeSchedule = [
          {
            aum: 'On the first $1,000,000',
            fee: '1.00%',
          },
          {
            aum: 'On the next $1,000,000',
            fee: '1.00%',
          },
          {
            aum: 'On the next $2,000,000',
            fee: '0.75%',
          },
          {
            aum: 'Over $4,000,000',
            fee: '0.50%',
          },
        ];
      }
      if (isFirm(firm) === 'har') {
        if (['9H06', '9H07'].includes(rep_code)) {
          // fee schedule for Harness 9H06 9H07
          feeSchedule = [
            {
              aum: 'On the first $500,000',
              fee: '0.95%',
            },
            {
              aum: 'On the next $500,000',
              fee: '0.90%',
            },
            {
              aum: 'On the next $1,000,000',
              fee: '0.70%',
            },
            {
              aum: 'On the next $2,000,000',
              fee: '0.55%',
            },
            {
              aum: 'Over $4,000,000',
              fee: '0.35%',
            },
          ];
        }
        if (TRILOGY_REP_CODES.includes(rep_code)) {
          feeSchedule = [
            {
              aum: 'On the first $1,000,000',
              fee: '0.65%',
            },
            {
              aum: 'Over $1,000,000',
              fee: '0.55%',
            },
          ];
        }
        if (LAINCHBURY_REP_CODES.includes(rep_code)) {
          feeSchedule = [
            {
              aum: 'On the first $1,000,000',
              fee: '0.65%',
            },
            {
              aum: 'Over $1,000,000',
              fee: '0.55%',
            },
          ];
        }
        if (LAKESHORE_REP_CODES.includes(rep_code) || NICOLL_REP_CODES.includes(rep_code) || GAIT_REP_CODES.includes(rep_code)) {
          feeSchedule = [
            {
              aum: 'On the first $1,000,000',
              fee: '0.65%',
            },
            {
              aum: 'Over $1,000,000',
              fee: '0.55%',
            },
          ];
        }
        if (['9H01', '9H02', '9H03', '9H04', '9H05', '9H08'].includes(rep_code)) {
          // fee shedule for Harness 9H01 9H02 9H03 9H04 9H05 9H08
          feeSchedule = [
            {
              aum: 'On the first $1,000,000',
              fee: '1.25%',
            },
            {
              aum: 'On the next $1,000,000',
              fee: '1.00%',
            },
            {
              aum: 'On the next $4,000,000',
              fee: '0.75%',
            },
            {
              aum: 'On the next $4,000,000',
              fee: '0.50%',
            },
            {
              aum: 'Over $10,000,000',
              fee: '0.35%',
            },
          ];
        }
      }
      if (isFirm(firm) === 'mil') {
        feeSchedule = [
          {
            aum: 'Conservative',
            fee1: '1.25%',
            fee2: '1.15%',
            fee3: '1.05%',
            fee4: '0.80%',
            fee5: '0.55%',
            fee6: '0.40%',
            fee7: 'Negotiable',
          },
          {
            aum: 'Moderate',
            fee1: '1.50%',
            fee2: '1.35%',
            fee3: '1.15%',
            fee4: '0.90%',
            fee5: '0.65%',
            fee6: '0.45%',
            fee7: 'Negotiable',
          },
          {
            aum: 'Balanced & Growth',
            fee1: '1.75%',
            fee2: '1.50%',
            fee3: '1.25%',
            fee4: '1.00%',
            fee5: '0.75%',
            fee6: '0.50%',
            fee7: 'Negotiable',
          },
          {
            aum: 'Mutual Fund only accounts',
            fee1: '1.00%',
            fee2: '0.90%',
            fee3: '0.80%',
            fee4: '0.60%',
            fee5: '0.50%',
            fee6: '0.40%',
            fee7: 'Negotiable',
          },
          {
            aum: 'HISA(MM) Fund only accounts',
            fee1: '0.25%',
            fee2: '0.25%',
            fee3: '0.25%',
            fee4: '0.25%',
            fee5: '0.25%',
            fee6: '0.25%',
            fee7: 'Negotiable',
          },
          {
            aum: 'CGWM ICP (SMA)*',
            fee1: '1.40%',
            fee2: '1.25%',
            fee3: '1.05%',
            fee4: '0.85%',
            fee5: '0.65%',
            fee6: '0.45%',
            fee7: 'Negotiable',
          },
          {
            aum: 'CGWM ICP ETF Portfolios*',
            fee1: '1.40%',
            fee2: '1.25%',
            fee3: '1.05%',
            fee4: '0.85%',
            fee5: '0.65%',
            fee6: '0.45%',
            fee7: 'Negotiable',
          },
        ];
      }
      return feeSchedule;
    },
    update(type, id, value) {
      this.accountsSelected = this.accountsSelected.map((acc) => {
        const account = acc;
        if (acc.id === id) account[type] = value;
        return account;
      });
    },
    fetchJointAccountName(type) { // get joint cilent name
      if (type === 'individual') {
        this.jointAccounts.forEach((account) => {
          account.accountHolderName = [this.activeClient.client.display_name];
          this.jointClientName.primary[account.id] = [];
          const isActivePrimary = (account.primary_client_id === this.activeClient.client.id);
          const clientsToFetch = isActivePrimary ? account.joint_client_id : [account.primary_client_id];
          Promise.all(clientsToFetch.map((id) => this.$store.dispatch('fetchClientByID', id)))
            .then((res) => {
              account.accountHolderName = account.accountHolderName.concat(res.map((r) => r.display_name));
              this.jointClientName.primary[account.id].push(res.map((r) => r.display_name));
            })
            .catch((err) => console.error('cannot fetch joint client name', err));
        });
      } else { // get joint clients' name for spouse joint account
        this.spouseJointAccounts.forEach((account) => {
          account.accountHolderName = [this.spouse.display_name || `${this.activeClient.client.display_name}'s spouse`];
          this.jointClientName.spouse[account.id] = [];
          const isSpousePrimay = (account.primary_client_id === this.spouse.id);
          const clientsToFetch = isSpousePrimay ? account.joint_client_id : [account.primary_client_id];
          Promise.all(clientsToFetch.map((id) => this.$store.dispatch('fetchClientByID', id)))
            .then((res) => {
              account.accountHolderName = account.accountHolderName.concat(res.map((r) => r.display_name));
              this.jointClientName.spouse[account.id].push(res.map((r) => r.display_name));
            })
            .catch((err) => console.error('cannot fetch joint client name', err));
        });
      }
    },
    closeModal() {
      this.$store.commit('CLOSE_CURRENT_MODAL');
    },
  },
  watch: {
    async isOpen(val) {
      if (val) {
        setTimeout(() => {
          this.stage = 1;
        }, 50);

        // get spousal accounts and corporate roles
        if (this.spouse) {
          const spouseAccounts = await this.$store.dispatch('getAccountsByClientID', this.spouse.id);
          api.get(`/datahub/client_all_details/${this.spouse.id}`)
            .then((res) => {
              this.spouse.corporate_roles = res.CorporateRoles || [];
            }).catch((err) => console.log('err in fetching spouse corporate roles'));
          if (!spouseAccounts.err) {
            this.spouseAccounts = spouseAccounts.accounts.filter((a) => this.allowedAccountTypes.includes(a.account_status));
          }
        }

        // get primary client corporate accounts
        if (this.activeClient.corporate_roles.length) {
          this.loadingCorporateData = true;
          this.corporatesAccounts = await this.getCorporateAccounts(this.activeClient.corporate_roles);
          this.loadingCorporateData = false;
        }
      }
    },
    async ipsType(val) {
      if (val === 'spousal') {
        // fetch spousal info
        this.loadingSpouseData = true;
        if (this.spouseJointAccounts.length) {
          this.loadingSpouseJointData = true;
          this.fetchJointAccountName('spousal');
          this.loadingSpouseJointData = false;
        }
        // get corporate client corporate accounts
        if (this.spouse.corporate_roles && this.spouse.corporate_roles.length) {
          this.loadingSpouseCorporateData = true;
          const { corporatesAccounts } = this;
          this.spouseCorporateAccounts = (await this.getCorporateAccounts(this.spouse.corporate_roles))
            .filter((spouseCorpAcct) => !((corporatesAccounts
              .map((primaryCorpAcct) => primaryCorpAcct.id))
              .includes(spouseCorpAcct.id)));
          this.loadingSpouseCorporateData = false;
        }
        fetchSystematics(this.spouse.id)
          .then((res) => {
            const systematics = [];
            res.forEach((s) => {
              if (Array.isArray(s.systematics)) {
                s.systematics.forEach((systematic) => systematics.push({
                  amount: systematic.amount,
                  accountName: s.account_info.nickname,
                  accountDataphileId: s.account_info.account_no_dataphile,
                  frequency: FREQUENCIES[systematic.frequency],
                  type: systematic.type,
                }));
              }
            });
            this.spousalSystematics = systematics;
          })
          .catch((err) => {
            console.log('err in fetching spousal systematics', err);
            this.loadingSpouseData = false;
          });
        fetchRrifPayments(this.spouse.id)
          .then((res) => {
            const spousalRrifPayments = [];
            res.rrif_pmt.forEach((r) => {
              const acc = this.spouseIndividualAccounts.filter((a) => a.id === r.account_id)[0];
              spousalRrifPayments.push({
                amount: r.payment_amount,
                accountName: acc.nickname,
                accountDataphileId: acc.account_no_dataphile,
                frequency: FREQUENCIES[r.payment_frequency],
                minimum: r.minimum,
                maximum: r.lockedin_max_pay_selected,
                type: '2',
              });
            });
            this.spousalRrifPayments = spousalRrifPayments;
            this.loadingSpouseData = false;
          })
          .catch((err) => {
            console.log('err in fetching spousal rrif payments', err);
            this.loadingSpouseData = false;
          });
      }
    },

    clientAccounts() {
      this.clientAccountsClone = _.cloneDeep(this.clientAccounts);
    },
    allAccounts(val) {
      this.selectAllIndividualAccounts(val);
    },
    allSpouseAccounts(val) {
      this.selectAllSpouseAccounts(val);
    },
    allJointAccounts(val) {
      this.selectAllJointAccounts(val);
    },
    allCorporateAccounts(val) {
      this.selectAllCorporateAccounts(val);
    },
    allSpouseJointAccounts(val) {
      this.selectAllSpouseJointAccounts(val);
    },
    allSpouseCorporateAccounts(val) {
      this.selectAllSpouseCorporateAccounts(val);
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';
  .padding-left-15 {
    padding-left: 15px !important;
  }
  .action-card__pt.v-card.action-card {
    padding-top: 55px !important;
  }
  .circle-image-action-card.v-card {
    max-width: 280px;
    margin: auto;
    padding: 16px 30px;
    margin-right: 20px !important;
  }
  .action-card-height {
    min-height: 410px !important;
  }
  .ips-wizard-stage-3 {
    .circle-image-action-card {
      max-width: 240px !important;
    }
  }
  .ips-retirement-age-dropdown {
    max-width: 110px !important;
    margin: auto !important;
    .v-input__slot {
      padding: 0px 5px 0px 30px !important;
    }
  }
  .ips-spouse-retirement-age-dropdown{
    max-width: 110px !important;
    margin: auto !important;
    .v-input__slot {
      padding: 0px 5px 0px 15px !important;
    }
  }
  .margin-auto {
    margin: auto !important;
  }
  .pas-dark-tooltip {
		position: absolute;
    top: -38px;
    right: -5px;
		background-color: black !important;
    border: 1px solid black !important;
    border-radius: 5px;
		padding: 5px 15px !important;
		z-index: 99999 !important;
		box-shadow: $medium-box-shadow !important;
		text-align: center;
		min-width: 150px;
		min-height: 1.7em !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.pas-dark-tooltip::after {
		content: " ";
		position: absolute;
		top: 100%;
		left: 45%;
		margin-left: 5px;
		border-width: 5px;
		border-style: solid;
		border-color: black transparent transparent transparent;
		box-shadow: $medium-box-shadow !important;
  }
  .fixedHeight{
    height: 25px;
    max-height: 25px;
  }
  .milFeeSchedule {
    margin-left: -70px;
  }
</style>
