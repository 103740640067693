<template>
  <v-textarea
    outline
    :value="value"
    @keydown.backspace="$emit('backspace')"
    @keydown.delete="$emit('backspace')"
    @input="$emit('input', $event)"
    :placeholder="placeholder"
    :prefix="prefix"
    class="base-textarea"
    single-line
    :no-resize="noResize"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
  >
    <slot></slot>
  </v-textarea>
</template>

<script>
export default {
  name: 'pas-textarea',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    prefix: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

.base-textarea .v-input__slot {
  &:hover, &:focus-within, &:focus, &:active {
    border-color: var(--primary-translucent-5) !important;
  }
  border-color: rgba(0,0,0,0.2) !important;
  border-width: 1px !important;
  font-size: 13px !important;
  padding: 0px 30px !important;
  border-radius: 24px !important;
}
</style>
