
<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="true"
    :left="true"
    :multi-line="true"
    :timeout="9000"
    :color="`grey--text`"
    class="the-snackbar"
  >
    <PasStatusBullet
      :color="snackbarType === 'success' ? 'green' : 'red'"
      :value="100"
      class="notification-bullet mr-3"
    />
    {{ snackbarText }}
    <v-icon
      v-if="snackbarTopic === 'alert'"
      @click="reinstateNotifications"
    >mdi-redo</v-icon>
    <v-icon @click="snackbar = false">mdi-window-close</v-icon>
  </v-snackbar>
</template>

<script>

// Base Component

export default {
  name: "Snackbar",
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar.snackbar;
      },
      set() {
        this.$store.dispatch("flipSnackbarSwitch", false);
      }
    },
    snackbarType() {
      return this.$store.state.snackbar.messageType;
    },
    snackbarText() {
      return this.$store.state.snackbar.snackbarText;
    },
    snackbarTopic() {
      return this.$store.state.snackbar.snackbarTopic;
    },
  },
  methods: {
    reinstateNotifications() {
      Promise.all(
        this.$store.state.users.dismissedNotificationsBuffer.map(notification =>
          this.$store.dispatch("updateNotificationStatus", {
            notification,
            action: 'revert_dismiss'
          }))
      ).then(res => {
        this.$store.dispatch("reinstateDismissedNotificationsBuffer");
      });
      this.snackbar = false;
    }
  }
};
</script>

<style lang="scss">

.notification-bullet {
	margin-bottom: 2px !important;
	font-size: 13px !important;
  line-height: 18px !important;
  margin-right: 10px !important;
}

</style>
