<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div v-if="hasFullWorkflowEditPermission">
      <span>Document has been sent out to client, you can</span>
      <br>
      <div>
        <span @click.prevent="complianceApproved" class="action-text">Send Again</span>
        <span> or </span>
        <span @click.prevent="completeDocumentGeneration" class="action-text">
          Mark as complete
        </span>
      </div>
    </div>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import api from 'Services/api';
import {
  sendComplianceApprovalSuccess, sendDocumentGenerationSuccess,
} from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'DocumentGenerationStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      complianceDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
    ]),
  },
  methods: {
    async checkCompliance() {
      const { compliance_check } = await api.get('/advisor_portal/compliance_check/');
      if (!compliance_check) {
        this.complianceDialog = true;
        return false;
      }
      return true;
    },
    async completeDocumentGeneration() {
      try {
        const res = await sendDocumentGenerationSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async complianceApproved() {
      const complianceCheck = await this.checkCompliance();
      if (!complianceCheck) return;
      try {
        const res = await sendComplianceApprovalSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>

</style>
