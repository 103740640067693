<template>
  <div class="my-4">
    <PasPageSubtitle class="mt-4">{{activeClient.client.display_name}}'s PM Group</PasPageSubtitle>
    <template v-if="!pmGroup.name">
      <div class="mt-2">
        <span>
          {{activeClient.client.display_name}} is currently not part of a PM Group.
          The client will be assigned to a PM Group during an IPS generation.
        </span>
      </div>
    </template>
    <template v-else>
      <v-layout column mt-2>
        <v-flex mb-3>
          <span class="strong-text">Name:  </span>
            <PasEditableSpan
              :value="pmGroup.name"
              :editableSpanClass="'solid-white-text'"
              :rules="[v.isRequired()]"
              :style="{ display: 'inline' }"
              @editSaved="update('name', $event)"
              :disabled="!canDeletePMGroups"
            />
        </v-flex>
        <v-flex mb-3>
          <span class="strong-text">Description:  </span>
            <PasEditableSpan
              :value="pmGroup.description"
              :editableSpanClass="'solid-white-text'"
              :rules="[v.isRequired()]"
              :style="{ display: 'inline' }"
              @editSaved="update('description', $event)"
              :disabled="!canDeletePMGroups"
            />
        </v-flex>
      </v-layout>

      <div class="strong-text">Membership:</div>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-actions
        :style="{ width: '650px' }"
      >
        <template v-slot:items="props">
          <td class="text-xs-left">
            <router-link  class="action-link" :to="`/clients/${props.item.id}`">
              {{props.item.name}}
            </router-link>
          </td>
          <td class="text-xs-rleft">{{ props.item.relationship }}</td>
          <td class="text-xs-rleft">{{ props.item.status }}</td>
        </template>
      </v-data-table>

      <PasAddItem
        v-if="!dropdown && canDeletePMGroups"
        :text="'Add a member'"
        @click="dropdown = true"
        class="pt-2"
      />
      <v-layout mt-3 v-if="dropdown">
        <v-flex shrink mr-3>
          <PasAutocomplete
            :items="relationships"
            v-model="selectedRelationship"
            :prependInnerIcon="`search`"
            :autofocus="true"
            :noDataText="`No client relationships`"
            :smallChips="true"
            :style="{ width: '300px' }"
          />
        </v-flex>
        <v-flex>
          <PasButton @click="join">Add</PasButton>
        </v-flex>
      </v-layout>
    </template>

    <v-dialog width="400px" :value="!!groupToLeave" persistent>
      <v-card class="confirm-action-card pa-5">
        <PasPageTitle>Move client</PasPageTitle>
        <div class="my-4">
          Are you sure you want to continue? This client is already in a PM group.
          Adding them to {{activeClient.client.display_name}}'s PM group will remove them from their current group.
        </div>
        <v-layout justify-space-between>
          <PasButton :theme="'grey'" @click="groupToLeave = undefined">
            No
          </PasButton>
          <PasButton @click="joinExistingPMGroup">
            Yes
          </PasButton>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  updatePMGroup, joinPMGroup, fetchClientPMGroup, leavePMGroup,
} from 'Services/api/ClientGroupings';
import { RELATIONS } from 'Services/constants';
import { modal } from 'Services/mixins';
import _ from 'lodash';
import v from 'Services/validation';

export default {
  name: 'PMGroupTab',
  mixins: [modal],
  data() {
    return {
      headers: [
        {
          text: 'Client Name',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Relationship',
          sortable: false,
          value: 'relationship',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
        },
      ],
      dropdown: false,
      selectedRelationship: undefined,
      groupToLeave: undefined,
      v,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'clientId',
      'clientList',
      'canDeletePMGroups',
    ]),
    pmGroup() {
      return this.activeClient.pmGroup;
    },
    clientType() {
      return this.activeClient.client.client_type;
    },
    relationships() {
      const members = [];
      if (this.pmGroup.clients) {
        this.pmGroup.clients.forEach((m) => {
          members.push(m.client);
        });
      }
      const rel = [];
      this.activeClient.relationships.forEach((r) => {
        rel.push({
          value: r.client_details[0].id,
          text: this.clientList[r.client_details[0].id].display_name,
        });
      });
      this.activeClient.corporate_roles.forEach((c) => {
        const clientId = this.clientType !== 'person' ? c.client : c.corporate_client;
        rel.push({
          value: clientId,
          text: this.clientList[clientId].display_name,
        });
      });
      return rel.filter((r) => !members.includes(r.value));
    },
    relationshipTypes() {
      const rel = {};
      this.activeClient.relationships.forEach((r) => {
        rel[r.client_details[0].id] = RELATIONS[r.relationship_type];
      });
      this.activeClient.corporate_roles.forEach((c) => {
        rel[c.client] = 'Corporation'; // placeholder
        rel[c.corporate_client] = 'Corporation'; // placeholder
      });
      rel[this.clientId] = '---';
      return rel;
    },
    items() {
      if (!this.pmGroup.clients) return [];
      return this.pmGroup.clients.map((c) => ({
        id: c.client,
        name: this.clientList[c.client].display_name,
        relationship: this.relationshipTypes[c.client] || '---',
        status: this.clientList[c.client].client_status || '---',
      }));
    },
  },
  methods: {
    async update(type, value) {
      const data = { id: this.pmGroup.id };
      data[type] = value;
      await updatePMGroup(data);
      this.$store.dispatch('setPMGroup', this.clientId);
    },
    async joinExistingPMGroup() {
      try {
        if (this.groupToLeave) await leavePMGroup(this.groupToLeave);
        this.groupToLeave = undefined;
        const clientPMGroupRes = await joinPMGroup({
          client: this.selectedRelationship,
          pm_group: this.pmGroup.id,
        });
        if (!clientPMGroupRes.id) throw Error;
        this.snackBar('success', 'Successfully joined the PM group');
      } catch (err) {
        this.snackBar('fail', 'Failed to join the PM group');
      }
      this.$store.dispatch('setPMGroup', this.clientId);
    },
    async join() {
      try {
        if (!this.selectedRelationship) {
          this.dropdown = false;
          return;
        }
        const { result } = await fetchClientPMGroup(this.selectedRelationship);
        console.log('checkign result', result);
        if (result) {
          const [clientPMGroup] = result.clients.filter((c) => c.client === this.selectedRelationship);
          this.groupToLeave = clientPMGroup.id;
          return;
        } this.joinExistingPMGroup();
      } catch (err) {
        this.snackBar('fail', 'Oops... something went wrong. Please refresh the page');
      }
    },
  },
};
</script>
