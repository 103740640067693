<template>
  <v-container>
    <v-layout align-baseline pt-4>
      <pas-page-title class="mb-4">Clients</pas-page-title>
      <div class="ml-2 mb-2 subheading grey--text">{{currentOffset}} of {{totalClientCount}}</div>
      <v-spacer />
      <v-flex xs1 mr-4 ml-5 text-xs-right>
        <pas-button slot="activator" theme="inverted" class="px-4 mr-3">
          <download-excel
            :data="clients"
            worksheet="Client List"
            type="csv"
            name="List.csv">
            Export List
          </download-excel>
        </pas-button>
      </v-flex>
      <v-flex v-if="canEditMyClientsBasicInformation" pl-1 ml-3 text-xs-right xs1>
        <pas-button
          @click="openNewClientModal()"
          class="primary-btn px-4"
          data-testid="all-clients-btn-add-new"
        >Add New</pas-button>
      </v-flex>
    </v-layout>
    <v-layout row align-center>
      <pas-search-box
        class="client-list-search"
        v-model="searchValue"
        placeholder="Search here..."
      />
      <v-spacer />
      <div class="filter-names">
        <span>Type:</span>
      </div>
      <div>
        <v-select
          class="set-width clients-sorter multi-filter"
          :items="filterTypeOpts"
          :value="selectedTypes"
          @change="setSelectedTypes"
          multiple
          append-icon="mdi-chevron-down"
          menu-props="offsetY"
          placeholder="All"
        >
          <template slot="selection" slot-scope="{ item, index }">
            <span v-if="index === 0" class="caption">{{ item.text }} </span>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ selectedTypes.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </div>
      <div class="filter-names">
        <span>Status:</span>
      </div>
      <div>
        <v-select
          class="set-width clients-sorter multi-filter"
          :items="filterStatusOpts"
          :value="selectedStatuses"
          @change="setSelectedStatus"
          multiple
          placeholder="All"
          append-icon="mdi-chevron-down"
          menu-props="offsetY"
        >
          <template slot="selection" slot-scope="{ item, index }">
            <span v-if="index === 0" class="caption">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ selectedStatuses.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </div>
      <div class="filter-names">
        <span>Advisor {{hasFeatureFlag('client_data_hierarchy') ? 'Team' : 'Code'}}:</span>
      </div>
      <div v-if="!hasFeatureFlag('client_data_hierarchy')">
        <v-select
          class="set-width clients-sorter multi-filter"
          :items="this.currentUserRepCodes"
          :value="selectedRepCodes"
          @change="setSelectedRepCodes"
          multiple
          placeholder="All"
          append-icon="mdi-chevron-down"
          menu-props="offsetY"
        >
          <template slot="selection" slot-scope="{ item, index }">
            <span v-if="index === 0" class="caption">{{ item }} </span>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ selectedRepCodes.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </div>
      <div v-else>
        <v-select
          class="set-width clients-sorter multi-filter"
          :items="advisorTeams"
          v-model="selectedTeam"
          @change="setSelectedAdvisorTeams"
          multiple
          placeholder="All"
          append-icon="mdi-chevron-down"
          menu-props="offsetY"
        >
          <template slot="selection" slot-scope="{ item, index }">
            <span v-if="index === 0" class="caption">{{ item.text }} </span>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ selectedTeam.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </div>
      <pas-button
        theme="inverted"
        @click="resetFilter"
        class="primary-btn px-3 mb-2 ml-4"
      >Clear All</pas-button>
    </v-layout>

    <p v-if="!clients">Loading...</p>
    <clients-list
      v-else
      :clients="clients"
      :loading="loading"
      class="clients-listacle"
      @toggleSort="toggleSort($event)"
      @deleteClientSuccess="fetchClients(0)"
    />
    <pas-button
      v-if="!loading && currentOffset < totalClientCount"
      class="primary-btn wide-btn mt-3"
      @click="fetchMoreClients"
    >
      View more
    </pas-button>
    <add-new-client
      :isOpen="newClientModalOpen && !hasFeatureFlag('client_data_hierarchy')"
      @close="handleAddNewClientModalClose"
      :redirect="true"
    />
    <AddNewClientNoCall
      :isOpen="newClientModalOpen && hasFeatureFlag('client_data_hierarchy')"
      @close="handleAddNewClientModalClose"
      :redirect="true"
    />
    <MessageDialog
      @ok="CDHIneligibleDialog = false"
      :isOpen="CDHIneligibleDialog"
      :width="'40%'"
    >
      <template v-slot:header>Cannot add clients</template>
      {{cantAddClientsMsg}}
      <template
        v-slot:btn
      >Okay</template>
    </MessageDialog>
  </v-container>
</template>

<script>
import ClientsList from 'Components/Vue-Routes/MyClients/AllClients/ClientsList';
import { fetchClientsList } from 'Services/api/Clients';
import AddNewClient from 'Components/Modals/AddNewClient';
import AddNewClientNoCall from 'Components/Modals/AddNewClientNoCall';
import MessageDialog from 'Components/Shared/MessageDialog';
import { mapGetters } from 'vuex';
import { WARNING_MESSAGES } from 'Services/constants';

export default {
  name: 'my-clients',
  components: {
    ClientsList,
    AddNewClient,
    AddNewClientNoCall,
    MessageDialog,
  },
  props: {
    search: { String, default: '' },
    sortBy: { String, default: undefined },
    sortDir: { String, default: undefined },
    type: { String, default: undefined },
    status: { String, default: undefined },
    advisorCode: { String, default: undefined },
    teamIds: { String, default: undefined },
  },
  data() {
    return {
      searchValue: this.search,
      selectedTeam: [],
      requestCounter: 0,
      clients: [],
      totalClientCount: 0,
      currentOffset: 0,
      filterTypeOpts: [
        { text: 'Person', value: 'person' },
        { text: 'Corporation', value: 'corporation' },
        { text: 'Trust', value: 'trust' },
        { text: 'Foundation', value: 'foundation' },
        { text: 'IPP', value: 'ipp' },
        { text: 'Non-Profit', value: 'nonprofit' },
      ],
      filterStatusOpts: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Prospect', value: 'prospect' },
        { text: 'Professional', value: 'professional' },
        { text: 'Other Contact', value: 'other contact' },
      ],
      loading: true,
      searchTimer: undefined,
      cantAddClientsMsg: WARNING_MESSAGES.cannot_add_clients,
      newClientModalOpen: false, //
      CDHIneligibleDialog: false,
    };
  },
  mounted() {
    this.fetchClients(0);
  },
  computed: {
    ...mapGetters([
      'canEditMyClientsBasicInformation',
      'hasFeatureFlag',
      'currentUserRepCodes',
      'advisorTeams',
    ]),
    selectedTypes() {
      if (!this.type) return [];
      return this.type.split(',');
    },
    selectedStatuses() {
      if (!this.status) return [];
      return this.status.split(',');
    },
    selectedRepCodes() {
      if (!this.advisorCode) return [];
      return this.advisorCode.split(',');
    },
    selectedAdvisorTeams() {
      if (!this.teamIds) return [];
      return this.teamIds.split(',');
    },
  },
  watch: {
    type() {
      this.fetchClients(0);
    },
    sortBy() {
      this.fetchClients(0);
    },
    sortDir() {
      this.fetchClients(0);
    },
    status() {
      this.fetchClients(0);
    },
    advisorCode() {
      this.fetchClients(0);
    },
    selectedAdvisorTeams() {
      this.fetchClients(0);
    },
    searchValue() {
      this.$router.push({ query: { ...this.$props, search: this.searchValue } });
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = undefined;
      }
      this.searchTimer = setTimeout(() => {
        this.fetchClients(0);
      }, 500); // 0.5 sec delay to allow more typing before committing the search
    },
  },
  methods: {
    handleAddNewClientModalClose() {
      this.newClientModalOpen = false;
      this.fetchClients(0);
    },
    resetFilter() {
      this.searchValue = '';
      this.$router.push({ query: {} });
    },
    setSelectedTypes(val) {
      this.$router.push({ query: { ...this.$props, type: val.join(',') } });
    },
    setSelectedStatus(val) {
      this.$router.push({ query: { ...this.$props, status: val.join(',') } });
    },
    setSelectedRepCodes(val) {
      this.$router.push({ query: { ...this.$props, advisorCode: val.join(',') } });
    },
    setSelectedAdvisorTeams(val) {
      this.$router.push({ query: { ...this.$props, teamIds: val.join(',') } });
    },
    toggleSort(event) {
      if (event) {
        const sortBy = event.id;
        const sortDir = event.sortValue === 1 ? 'desc' : 'asc';
        this.$router.push({ query: { ...this.$props, sortBy, sortDir } });
      }
    },
    async fetchClients(offset) {
      if (offset === 0) {
        this.clients = [];
        this.currentOffset = 0;
        this.totalClientCount = 0;
      }

      const excludeKycClients = false;
      this.loading = true;
      this.currentOffset = offset;
      this.requestCounter += 1;
      try {
        const { count, results, requestId } = await fetchClientsList(
          offset,
          this.sortBy,
          this.sortDir,
          this.selectedTypes,
          this.selectedStatuses,
          this.searchValue,
          this.selectedRepCodes,
          this.requestCounter,
          excludeKycClients,
          this.selectedAdvisorTeams,
        );
        // Keep track of latest request ID, if it doesnt match what fetch returns, do not update state
        // This way the list will not contain "old" data
        if (requestId === this.requestCounter) {
          this.clients = [...this.clients, ...results];
          this.totalClientCount = count;
          this.currentOffset += results.length;
          this.loading = false;
        }
      } catch (e) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error fetching clients',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
        this.loading = false;
      }
    },

    fetchMoreClients() {
      this.fetchClients(this.currentOffset);
    },

    openNewClientModal() {
      if (this.hasFeatureFlag('client_data_hierarchy') && !this.advisorTeams.length) {
        this.CDHIneligibleDialog = true;
        return;
      }
      this.newClientModalOpen = true;
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';
.multi-filter {
  i,input{
    font-size: 14px !important
  }
}
.multi-filter.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none !important;
}
.multi-filter.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none !important;
}
.set-width {
  max-width: 175px;
}
.client-list-search{
  margin-left: 25px;
  max-width: 450px;
  .v-input {
  font-size: 14px !important;
  letter-spacing: 0px !important;
  }
}
.clients-sorter {
  padding-top: 8px !important;
}
.clients-listacle {
  margin-top: 25px !important;
}
.filter-names {
  margin: -10px 15px 0px 15px;
  span {
    white-space: nowrap;
  }
}
</style>
