<template>
  <div class="mt-2" style="height:85vh; overflow:scroll">
    <iframe class="reportIframe" :title="url" :src="url" />
  </div>
</template>

<script>
export default {
  name: 'Report',
  components: {},
  props: {
    url: { type: String, default: '' },
  },
};
</script>
