<template>
  <div class="insightContainer">
      <PSideNav
        v-if="!loading && categories.length && currentCategory === null"
        :sections="categoryMenu"
        @item-click="categoryClick($event)"
      />
      <PSideNav
        v-else-if="!loading && currentCategory >= 1"
        :sections="reportMenu"
        @item-click="reportMenuClick($event)"
      />
    <!-- View all thumbnails -->
    <div v-if="viewDashboard" class="ml-3 pb-3" style="width:100%; overflow-y:scroll; max-height:100vh">
      <AllThumbnails :categories="categories" :reportList="reportList" @selectReport="selectReport" />
    </div>
    <!-- View a single report -->
    <div v-else-if="viewReport" class="mx-3 mt-5" style="width:100%">
      <h2>{{selectedReport.display_name}}</h2>
      <Report :url="selectedReport.url" />
    </div>
    <!-- Display error page -->
    <div v-else-if="displayError" class="mx-3" style="width:100%">
      <Error />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  fetchReportList, fetchCategories, fetchCategoryReportList, fetchFullReportUrl,
} from 'Services/api/Reports';

import AllThumbnails from './AllThumbnails.vue';
import Report from './Report.vue';
import Error from './Error.vue';

export default {
  name: 'Insights',
  components: {
    AllThumbnails,
    Report,
    Error,
  },
  data() {
    return {
      reportList: [],
      categories: [],
      loading: true,
      currentCategory: null,
      viewDashboard: false,
      viewReport: false,
      displayError: false,
      selectedReport: null,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
    categoryMenu() {
      const categoryMenu = this.categories.map((e) => ({
        id: e.id,
        title: e.category_name,
        icon: e.icon || 'file',
        active: this.currentCategory === e.id,
      }));
      const dashboard = {
        id: 0,
        title: 'Insight Dashboard',
        icon: 'eye',
        active: this.currentCategory === null,
      };
      return [dashboard].concat(categoryMenu);
    },
    reportMenu() {
      const reportMenu = this.reportList.map((e) => ({
        id: e.id,
        title: e.display_name,
        icon: 'file',
        active: this.selectedReport && this.selectedReport.id === e.id,
      }));
      const back = {
        id: 0,
        title: 'Back',
        icon: 'chevron-left',
      };
      return [back].concat(reportMenu);
    },
  },
  methods: {
    async reportMenuClick(event) {
      if (event.id === 0) { // click on Back
        await this.dashboardClick();
      } else { // click on report
        const report = this.reportList.find((e) => e.id === event.id);
        await this.selectReport(report);
      }
    },
    async dashboardClick() {
      if (this.viewReport && this.selectedReport !== null) { // back to category view
        this.currentCategory = this.selectedReport.category.id;
        await this.getReportList(this.currentCategory);
        this.viewReport = false;
        this.viewDashboard = true;
        this.selectedReport = null;
      } else { // back to dashboard view
        await this.getReportList(null);
        this.viewReport = false;
        this.selectedReport = null;
        this.viewDashboard = true;
        this.currentCategory = null;
      }
    },
    async getReportList(categoryId) {
      if (categoryId) {
        this.reportList = await fetchCategoryReportList(this.currentUser.username, categoryId);
      } else {
        this.reportList = await fetchReportList(this.currentUser.username);
      }
    },
    async getCategories() {
      const initialCategories = await fetchCategories(this.currentUser.platform_user_id);
      this.categories = initialCategories.reverse();
    },
    async selectReport(report) {
      try {
        await this.getFullReportUrl(report.id);
        if (this.selectedReport === null) {
          throw new Error('Cannot find report');
        }
        this.viewDashboard = false;
        this.currentCategory = report.category.id;
        // update report list for side nav, no need to regenerate url by using getReportList
        this.reportList = this.reportList.filter((e) => e.category.id === this.currentCategory);
        this.viewReport = true;
      } catch (err) {
        this.handleError();
      }
    },
    async getFullReportUrl(reportId) {
      const report = await fetchFullReportUrl({ user: this.currentUser.username, reportId });
      this.selectedReport = report.length ? report[0] : null;
    },
    handleError() {
      this.viewDashboard = false;
      this.viewReport = false;
      this.displayError = true;
    },
    async categoryClick(e) {
      this.currentCategory = e.id === 0 ? null : e.id;
      this.reportList = [];
      this.getReportList(this.currentCategory);
    },
  },
  async mounted() {
    try {
      await this.getCategories();
      await this.getReportList(this.currentCategory);
      this.loading = false;
      this.viewDashboard = true;
    } catch (err) {
      this.handleError();
    }
  },
};
</script>
<style scoped>
.insightContainer {
  display: flex;
}
.icon {
  width: 24px;
}
</style>
