<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div v-if="hasFullWorkflowEditPermission">
      <div>Review & Approve</div>
      <div>
        <button
          type="button"
          @click.prevent="complianceEmailDialog = true"
          class="action-text">
          Email copy of documents
        </button>
      </div>
      <div>
        <button type="button" @click.prevent="idModal = true" class="action-text">
          Upload documents
        </button>
      </div>

      <div>
        <button
          type="button"
          :class="{ 'action-text-disabled': !userHasAccountApprovalStatusPermission }"
          @click.prevent="complianceApproved"
          class="action-text">
          Approve
        </button>
        <span>or</span>
        <button
          type="button"
          :class="{ 'action-text-disabled': !userHasAccountApprovalStatusPermission }"
          @click.prevent="complianceRejected"
          class="action-text">
          Reject
        </button>
      </div>
    </div>
    <AdditionalDocumentUpload
      :workflowId="workflow.id"
      :isOpen="idModal"
      @close="idModal = false"
      @done="idModal = false" />
    <v-dialog max-width="450px" v-model="complianceDialog" persistent>
      <v-card class="pa-5">
        <v-layout column>
          <pas-page-subtitle>Compliance Approval</pas-page-subtitle>
          <div class="my-3">Only members of the compliance team can approve or reject</div>
          <v-layout>
            <v-spacer />
            <pas-button
              class="primary-btn mt-3"
              @click="complianceDialog = false"
            >OK
            </pas-button>
          </v-layout>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog max-width="450px" v-model="complianceEmailDialog" persistent>
      <v-card class="pa-5">
        <v-layout column>
          <pas-page-subtitle>Email copy of documents</pas-page-subtitle>
          <div class="my-3">Confirm Document Submission<br /><br />
            Are you ready to resend the selected documents to Compliance?<br /><br />
            Sending to: {{ complianceEmail }}</div>
          <v-layout>
            <v-spacer />
            <pas-button
            class="inverted-btn mt-3 mr-3"
            @click="complianceEmailDialog = false"
            >Cancel
          </pas-button>
          <pas-button
            class="primary-btn mt-3"
            @click="compliancePreviewDoc"
          >Send
          </pas-button>
          </v-layout>
        </v-layout>
      </v-card>
    </v-dialog>
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import api from 'Services/api';
import {
  sendAccountOpeningReviewAndApprovalSuccess,
  sendAccountOpeningReviewAndApprovalFailure,
  sendAccountOpeningReviewAndApprovalDocuments,
} from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';
import AdditionalDocumentUpload from './AdvisorApprovalStep/AdditionalDocumentUpload';

export default {
  name: 'DocumentReviewStep',
  components: { WorkflowStep, AdditionalDocumentUpload },
  props: {
    workflow: {
      type: Object,
      default: () => { },
    },
    step: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      idModal: false,
      complianceDialog: false,
      complianceEmailDialog: false,
      complianceEmail: '',
      userHasAccountApprovalStatusPermission: false,
    };
  },
  async created() {
    this.complianceEmail = await this.getComplianceEmail();
    this.userHasAccountApprovalStatusPermission = await this.checkUserHasAccountApprovalStatusPermission();
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
    ]),
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
  },
  methods: {
    async getComplianceEmail() {
      const { value } = await api.get('/datahub/metadata/?key=compliance_email');
      return value;
    },
    async checkUserHasAccountApprovalStatusPermission() {
      if (this.$store.getters.canEditAccountOpeningApprovalStatus === true) {
        return true;
      }
      return false;
    },
    async compliancePreviewDoc() {
      this.complianceEmailDialog = false;
      try {
        const res = await sendAccountOpeningReviewAndApprovalDocuments(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Generating Documents...',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id)
            .then(() => this.$forceUpdate());
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Error in Document generation',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async complianceApproved() {
      const userHasAccountApprovalStatusPermission = await this.checkUserHasAccountApprovalStatusPermission();
      if (!userHasAccountApprovalStatusPermission) {
        this.complianceDialog = true;
        return;
      }
      try {
        const res = await sendAccountOpeningReviewAndApprovalSuccess(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id)
            .then(() => this.$forceUpdate());
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async complianceRejected() {
      const userHasAccountApprovalStatusPermission = await this.checkUserHasAccountApprovalStatusPermission();
      if (!userHasAccountApprovalStatusPermission) {
        this.complianceDialog = true;
        return;
      }
      try {
        const res = await sendAccountOpeningReviewAndApprovalFailure(this.workflow);
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>
.action-text:hover {
  text-decoration: underline;
}

.action-text {
  user-select: none;
}

.action-text-disabled {
  color: #ccc;
  cursor: not-allowed;
}

</style>
