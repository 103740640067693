<template>
  <PContainer class="mt-3">
    <PRow>
      <PColumn class="mb-3" lg="3">
        <SideMenuNav
          v-if="backRoute"
          :backRoute="backRoute"
        />
      </PColumn>
      <PColumn class="list-col" lg="6" md="8">
        <div v-if="loading" style="margin: 50%;">
          <PSpinner />
        </div>
        <template v-if="differencesExist && !loading">
          <h2>Fatca Changes</h2>
          <PListGroup>
            <PListGroupItem v-for="(item, index) in differentSections" :key="index">
              <div class="w-100">
                <div class="d-flex justify-content-between">
                  <div class="title">
                    <p>{{ $t(`fatca_renewal:client_changes.title.${item.key}`) }}</p>
                  </div>
                  <div class="d-flex flex-column text-right">
                    <div class="description no-grow">
                      <p>{{ formatData(item.key, item.original) }}</p>
                    </div>
                    <div class="sub-description no-grow font-weight-bold">
                      <p>{{ formatData(item.key, item.modified) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </PListGroupItem>
          </PListGroup>
        </template>
        <div v-else-if="!loading && !differencesExist">
          <h2>{{ $t('cm_common:client_changes.no_changes') }}</h2>
        </div>
      </PColumn>
    </PRow>
  </PContainer>
</template>

<script>
import _ from 'lodash';
// import { mapGetters } from 'vuex';
import { fetchFATCADiff } from 'Services/api/FATCARenewalsList';

export default {
  name: 'FATCAChangesView',
  data() {
    return {
      loading: true,
      differencesExist: false,
      showChanges: false,
      originalData: null,
      modifiedData: null,
      differentSections: [],
    };
  },
  computed: {
    backRoute() {
      return {
        routeName: 'workflowV2',
        text: 'FATCA workflow',
      };
    },
    workflowId() {
      return this.$attrs.workflowID;
    },
  },
  methods: {
    getTitle(key) {
      return this.$t(`fatca_renewal:client_changes.title.${key}`);
    },
    formatData(key, data) {
      if (['is_canadian', 'is_us_citizen', 'tax_residnet_of_canada',
        'claim_tax_treaty_benefits'].includes(key)) {
        return data ? this.$t('common:yes') : this.$t('common:no');
      }
      if (['residential_address', 'mailing_address'].includes(key)) {
        return `${data.address_line_2 ? `${data.address_line_2}-` : ''}${data.address_line_1}, ${data.city}, ${data.province}, ${data.postal_code}`;
      }
      return data || '-';
    },
  },
  async mounted() {
    this.loading = true;
    const res = await fetchFATCADiff({ workflowId: this.workflowId });
    // format data
    const originalRecord = res.find((item) => item.is_current === false);
    const modifiedRecord = res.find((item) => item.is_current === true);
    if (originalRecord && modifiedRecord) {
      this.originalData = originalRecord.question_data_json;
      this.modifiedData = modifiedRecord.question_data_json;
      this.differencesExist = !_.isEqual(this.originalData, this.modifiedData);
      this.loading = false;
    }
    Object.keys(this.originalData).forEach((key) => {
      if (!_.isEqual(this.originalData[key], this.modifiedData[key])) {
        this.differentSections.push({
          key,
          original: this.originalData[key],
          modified: this.modifiedData[key],
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.description {
  p {
    font-weight: 400 !important;
    color: #657381;
  }
}

</style>
