import _ from 'lodash';
import LogRocket from 'logrocket';

import acApi, { getFirmFromJwt } from 'Services/api';
import api from 'Services/apiV2';
import { ENVIRONMENTS } from 'Services/constants';
import Vue from 'vue';
import teams from './users/teams';
import dataHierarchy from './users/dataHierarchy';

const defaultState = {
  currentUser: null,
  currentUserDatahub: null,
  currentUserRepCodes: null,
  notifications: [],
  notificationUpdatePending: false,
  dismissedNotificationsBuffer: [],
};

const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_CURRENT_USER_DATAHUB(state, user) {
    state.currentUserDatahub = user;
  },
  SET_CURRENT_USER_REP_CODES(state, repCodes) {
    state.currentUserRepCodes = repCodes;
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  SET_DISMISSED_BUFFER(state, notifications) {
    state.dismissedNotificationsBuffer = notifications;
  },
};

const actions = {
  logUserOut({ commit, dispatch }, { reason = null} = {}) {
    /// //////////////////////////////
    // Traditional logout
    /// //////////////////////////////
    let firm = '';
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      firm = getFirmFromJwt();
      localStorage.removeItem('jwt');
      commit('SET_CURRENT_USER', null);
      commit('SET_CURRENT_USER_DATAHUB', null);
      commit('SET_CURRENT_USER_REP_CODES', null);
    }

    if (jwt && Vue.prototype?.$auth?.isFirmSSOActivated(firm)) {
      /// //////////////////////////////
      // SSO logout
      /// //////////////////////////////
      dispatch('idPUserLogOut', { reason });
    }

    // Force blacklisting of the last token so it cannot be reused
    dispatch('blacklistUserAccessToken', jwt);
  },
  idPUserLogOut({ commit, dispatch }, { reason = null} = {}) {

    // Make sure to include the firm if we're on local
    let logoutUrl = `${window.location.origin}`;  // Back to the sign in page
    if (reason?.includes('idle')) {
      // Will display a pop-up that the user was idle for too long
      logoutUrl = `${window.location.origin}/advisor_portal/sso-logout?logoutDueToIdle=true`;
    } else if (reason?.includes('unauthorized')) {
      // Will display a pop-up that the user was authenticated but is unauthorized on this firm
      logoutUrl = `${window.location.origin}/advisor_portal/sso-logout?logoutDueToUnauthorized=true`;
    }

    // Internal user does not have access to AC
    Vue.prototype.$auth.logout({
      logoutParams: {
        clientId: Vue.prototype.$auth.clientId,
        returnTo: logoutUrl,
      },
    });
  },
  blacklistUserAccessToken({}, jwt) {
    console.log('Blacklisting user access token');
    if (jwt) {
      api.post('/api/token/blacklist/', { jwt })
        .then(() => {
          console.log('Successfully blacklisted user access token');
        })
        .catch((err) => {
          console.log('Error blacklisting user access token', err);
        });
    }
  },
  fetchUserInfoOnLogin({ commit, dispatch, rootState }) {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) return Promise.resolve();
    return api.get('/user_info_from_jwt/')
      .then(async (res) => {
        await dispatch('setFeatures');
        dispatch('getCustodian');
        const dhUser = res.platform_user;
        const userResult = {
          _id: dhUser.ac_mongo_id || dhUser.id,
          name: `${dhUser.first_name} ${dhUser.last_name}`,
          first_name: dhUser.first_name,
          last_name: dhUser.last_name,
          username: dhUser.account.username,
          role: dhUser.account.role,
          teams: dhUser.teams.map((x) => x.id),
          approved: dhUser.account.is_approved,
          platform_user_id: dhUser.id,
        };
        if ([ENVIRONMENTS.production, ENVIRONMENTS.demo].includes(rootState.app.env)) {
          LogRocket.init('utgp8t/advisor-center-xyqa7', {
            network: {
              requestSanitizer: (request) => {
                const requestUrl = request.url.toLowerCase();
                if (requestUrl.includes('password') || requestUrl.includes('login')) {
                  request.body = null;
                }
                return request;
              },
            },
          });
          LogRocket.identify(dhUser.id, {
            name: `${dhUser.first_name} ${dhUser.last_name}`,
            email: dhUser.email,
          });
        }
        commit('SET_CURRENT_USER', userResult);
        commit('SET_CURRENT_USER_DATAHUB', dhUser); // remove this. Only need 1 currentUser
        if (res.platform_user.account) {
          await dispatch('setPermissions', res.platform_user.account.id);
        }
        if (res.platform_user.teams) {
          let repCodes = res.platform_user.teams.reduce((acc, team) => acc.concat(team.rep_code_access), []);
          repCodes = [...new Set(repCodes)];
          commit('SET_CURRENT_USER_REP_CODES', repCodes);
          commit('SET_TEAMS', res.platform_user.teams);
          dispatch('setTeamMembers', res.platform_user.id);
          if (rootState.app.features.client_data_hierarchy) {
            commit('SET_ADVISOR_TEAMS', res.platform_user.teams.filter((team) => team.rep_code_access.length));
          }
        }
        dispatch('setPrograms');
        dispatch('setRepCodeDetails');
        return res;
      }).catch((err) => {
        dispatch('logUserOut');
        return false;
      });
  },
  updateCurrentUserDatahub({ commit }, { userID, data }) {
    return acApi.post(`/datahub/platform_users/${userID}/`, data)
      .then((res) => {
        res.code === 500 ? null : commit('SET_CURRENT_USER_DATAHUB', res);
        return res;
      })
      .catch((err) => {
        console.log('err', err);
        return err;
      });
  },
  pollAndUpdateNotifications({ dispatch }) {
    dispatch('fetchNotifications')
      .then((res) => {
        dispatch('setNotifications', res);
      });
  },
  setNotifications({ commit }, notifications) {
    commit('SET_NOTIFICATIONS', notifications);
  },
  isNotificationUpdatePending({}) {
    return this.notificationUpdatePending;
  },
  fetchNotifications({ state }) {
    this.notificationUpdatePending = true;
    return acApi.get(
      '/advisor_portal/api/notifications',
      'limit=100',
      'offset=0',
      'type=not_dismissed',
    )
      .then((res) => res.results
        .filter((n) => n.dismissed_at === null && n.completed_at === null)
        .map((x) => {
          const notification = x.notification_details;
          notification.description = notification.rendered_text;
          notification.status = { dismissed_at: x.dismissed_at, read_at: x.read_at };
          return notification;
        }))
      .then((res) => {
        this.notificationUpdatePending = false;
        return res;
      })
      .catch((err) => {
        this.notificationUpdatePending = false;
        console.log(err);
        return [];
      });
  },
  setDismissedNotificationsBuffer({ commit }, notifications) {
    commit('SET_DISMISSED_BUFFER', notifications);
  },
  clearDismissedNotificationsBuffer({ commit }) {
    commit('SET_DISMISSED_BUFFER', []);
  },
  reinstateDismissedNotificationsBuffer({ commit, dispatch, state }) {
    commit('SET_NOTIFICATIONS', state.notifications.concat(state.dismissedNotificationsBuffer));
    dispatch('clearDismissedNotificationsBuffer');
  },
  updateNotificationStatus({ dispatch, state }, { notification, action }) {
    return acApi.post(`/advisor_portal/api/notifications/${notification.id}/${action}/`, {
    })
      .then((res) => res);
  },
  updateUserNotificationStatus({ dispatch, state }, { action, start = null, end = null }) {
    if (!start && !end) {
      const sortedNotifications = _.cloneDeep(state.notifications).sort((a, b) => ((a.created_at < b.created_at) ? -1 : 1));
      start = sortedNotifications[0].created_at;
      end = sortedNotifications[sortedNotifications.length - 1].created_at;
    }
    return acApi.post(`/advisor_portal/api/notifications/${action}/`, {
      start, end,
    })
      .then((res) => res);
  },
  deleteUserAvatar({}, id) {
    return acApi.deleteCall(`/datahub/platform_users/${id}/avatar/`)
      .then((res) => (res ? 'success' : false))
      .catch((err) => console.log('err', err));
  },
};

export const getters = {
  currentUserDatahubAccountId: (state) => _.get(state, 'currentUserDatahub.account.id'),
  currentUser: (state) => state.currentUser,
  currentUserRepCodes: (state) => state.currentUserRepCodes,
};

const modules = {
  teams,
  dataHierarchy,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
  modules,
};
