<template>
  <v-layout align-center fill-height class="full-body-view-height white-background">
    <v-spacer class="white-background"></v-spacer>
    <v-flex shrink mr-5 px-4 class="white-background" fill-height xs8 sm6 :style="{'max-width': '400px'}">
      <v-layout nowrap row fill-height align-center>
        <v-flex>
          <slot></slot>
        </v-flex>
      </v-layout>
    </v-flex>
     <v-flex xs4 :style="{'background-color': '#f5f6fa'}" fill-height>
      <v-layout align-center fill-height>
        <v-flex>
          <v-img :src="loginSvg" :style="{height: '100%'}"></v-img>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'auth-page',
  data() {
    return {
    }
  },
  computed: {
    loginSvg() {
			return this.$store.state.app.themeAssets.login
    }
  }
}
</script>

<style>

</style>