<template>
  <v-container class="client-confirmation-auth-container">
    <PasCircularLoader v-if="step==0 && loading"></PasCircularLoader>
    <v-card class="pa-4" v-if="step==1">
      <v-layout mb-4 align-center>
        <v-flex xs12 text-xs-center>
          <h3>Please enter your phone number and we'll send you an access code.</h3>
        </v-flex>
      </v-layout>
      <v-layout mb-4 align-center>
        <v-flex xs12 text-xs-center>
          <PasPhoneInput v-model="clientPhoneNumber"></PasPhoneInput>
        </v-flex>
      </v-layout>
      <v-layout mb-4 align-center>
        <v-flex xs12 text-xs-center>
          <PasCircularLoader v-if="loading"></PasCircularLoader>
          <PasButton
            v-else
            theme="primary"
            :disabled="clientPhoneNumber == null"
            class="confirm-btn px-4"
            @click="checkPhoneAuth"
          >Continue</PasButton>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card class="pa-4" v-if="step==2">
      <v-layout mb-4 align-center>
        <v-flex xs12 text-xs-center>
          <h3>Please enter the access code sent to your phone number</h3>
        </v-flex>
      </v-layout>
      <v-layout mb-4 align-center>
        <v-flex xs12 text-xs-center>
          <PasInputField v-model="twilioToken" :placeholder="`Enter code`"></PasInputField>
        </v-flex>
      </v-layout>
      <v-layout mb-4 align-center>
        <v-flex xs12 text-xs-center>
          <PasCircularLoader v-if="loading"></PasCircularLoader>
          <PasButton
            v-else
            theme="primary"
            class="confirm-btn px-4"
            @click="checkTwilioCode"
          >Authorize</PasButton>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {
  clientLogin,
  verifySessionToken,
  verifyPhoneNumber,
  verifyTwilioToken,
} from 'Services/api/ClientConfirmationTool';

export default {
  data() {
    return {
      step: 0,
      loading: false,
      tokenStatus: 'Invalid',
      clientPhoneNumber: null,
      twilioToken: '',
      clientId: null,
      isPerson: null,
      isFatcaCrsComplete: null,
    };
  },
  async mounted() {
    this.loading = true;
    if (!this.firmId || !this.sessionToken) {
      this.$emit('onAuthSubmit', {
        tokenValid: false,
        tokenStatus: this.tokenStatus,
        authenticated: false,
      });
    } else {
      try {
        await clientLogin(this.firmId);
        try {
          const tokenValidationRes = await verifySessionToken(
            this.sessionToken,
          );
          this.tokenStatus = tokenValidationRes.approval_status;
          this.clientId = tokenValidationRes.client_id;
          this.isPerson = tokenValidationRes.is_person;
          this.isFatcaCrsComplete = tokenValidationRes.is_fatca_crs_complete;
          if (this.isSessionPending) {
            this.loading = false;
            this.step = 1;
          } else if (this.isSessionConfirmed || this.isSessionDenied || this.isSessionCompleted) {
            this.$emit('onAuthSubmit', {
              tokenValid: true,
              tokenStatus: this.tokenStatus,
              authenticated: false,
              clientId: this.clientId,
              isPerson: this.isPerson,
              isFatcaCrsComplete: this.isFatcaCrsComplete,
            });
          }
        } catch (err) {
          this.loading = false;
          this.$emit('onAuthSubmit', {
            tokenValid: false,
            tokenStatus: this.tokenStatus,
            authenticated: false,
          });
        }
      } catch (err) {
        this.loading = false;
        this.displaySnackbar('Unable to access page', true);
      }
    }
  },
  computed: {
    sessionToken() {
      return this.$route.query.sessionToken;
    },
    firmId() {
      return this.$route.query.firm;
    },
    isSessionInvalid() {
      return this.tokenStatus.toLowerCase() === 'invalid';
    },
    isSessionPending() {
      return this.tokenStatus.toLowerCase() === 'pending';
    },
    isSessionConfirmed() {
      return this.tokenStatus.toLowerCase() === 'confirmed';
    },
    isSessionCompleted() {
      return this.tokenStatus.toLowerCase() === 'completed';
    },
    isSessionDenied() {
      return this.tokenStatus.toLowerCase() === 'denied';
    },
  },
  methods: {
    checkPhoneAuth() {
      this.loading = true;
      verifyPhoneNumber(this.sessionToken, this.clientPhoneNumber, this.firmId)
        .then(() => {
          this.loading = false;
          this.step = 2;
          this.displaySnackbar('Phone number verified successfully');
        })
        .catch(() => {
          this.loading = false;
          this.displaySnackbar('Invalid phone number', true);
        });
    },
    checkTwilioCode() {
      this.loading = true;
      verifyTwilioToken(this.sessionToken, this.twilioToken)
        .then((response) => {
          this.clientId = response.client_id;
          this.loading = false;
          this.$emit('onAuthSubmit', {
            tokenValid: true,
            tokenStatus: this.tokenStatus,
            authenticated: true,
            clientId: this.clientId,
            isPerson: this.isPerson,
            isFatcaCrsComplete: this.isFatcaCrsComplete,
          });
        })
        .catch(() => {
          this.loading = false;
          this.displaySnackbar('Invalid access code', true);
        });
    },
    displaySnackbar(message, error) {
      this.$store.dispatch('setSnackbar', {
        text: message,
        type: error ? 'error' : 'success',
      });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
};
</script>

<style lang="scss" scoped>
.client-confirmation-auth-container {
  max-width: 500px;
}
</style>
