import { get } from 'lodash';
import { getModules, getSubmodulePermissions } from 'Services/helpers/permissionsHelpers';
import api from 'Services/api';

export const fetchRolesAndPermissions = (dispatch) => api.get('/datahub/permissions?is_available_to_user=true')
  .then((res) => {
    if (res.status !== 'success') {
      dispatch('setSnackbar', { text: 'Failed to retrieve roles and permissions. Please reload the page.', type: 'fail' });
      dispatch('flipSnackbarSwitch');
    }
    return res;
  })
  .catch((err) => err);

export const updateRolesAndPermissions = (data, dispatch) => api.put('/datahub/permissions', data)
  .then((res) => {
    if (res.status === 'success') {
      dispatch('setSnackbar', { text: 'Successfully saved permissions.', type: 'success' });
    } else {
      dispatch('setSnackbar', { text: 'Failed to save permissions.', type: 'fail' });
    }
    dispatch('flipSnackbarSwitch');
    return res;
  })
  .catch((err) => err);

export const toggleRolesAndPermissionsModule = (roleId, data, dispatch) => api.put(`/datahub/module_role_groups/${roleId}/`, data)
  .then((res) => {
    if (res.id) {
      dispatch('setSnackbar', { text: 'Successfully toggled module.', type: 'success' });
    } else {
      dispatch('setSnackbar', { text: 'Failed to toggle module.', type: 'fail' });
    }
    dispatch('flipSnackbarSwitch');
    return res;
  })
  .catch((err) => err);

export const fetchRolesAndPermissionsForUser = (userId) => api.get(`/datahub/permissions/?context=user&user=${userId}&is_module_available=true`)
  .then((res) => {
    const roles = get(res, 'permissions.roles');
    const modules = getModules(roles);
    const submodules = getSubmodulePermissions(roles);
    const userLevelModules = get(res, 'user_level_modules');
    return {
      roles, modules, submodules, userLevelModules,
    };
  })
  .catch((err) => err);

export const fetchRoles = (dispatch) => api.get('/datahub/roles/?is_available_to_user=true&limit=1000')
  .then((res) => {
    if (!res.results) {
      dispatch('setSnackbar', { text: 'Failed to retrieve roles. Please reload the page.', type: 'fail' });
      dispatch('flipSnackbarSwitch');
    }
    return res.results.map((role) => ({
      text: _.startCase(role.role_name),
      value: role.id,
    }));
  })
  .catch((err) => err);

export const fetchRolesForUser = (userId) => api.get(`/datahub/user_account_roles/?user=${userId}&is_available_to_user=true`)
  .then((res) => {
    if (!res.roles) {
      res.roles = [];
    }
    return res.roles;
  })
  .catch((err) => err);

export const fetchPlatformUsers = (dispatch) => api.get('/datahub/user_accounts/?is_available_to_user=true')
  .then((res) => {
    if (res.status !== 'success') {
      dispatch('setSnackbar', { text: 'Failed to retrieve roles. Please reload the page.', type: 'fail' });
      dispatch('flipSnackbarSwitch');
    }
    return res.user_accounts;
  })
  .catch((err) => err);

export const fetchModules = (dispatch) => api.get('/datahub/modules/?is_user_level_feature=1')
  .then((res) => {
    if (!res.results) {
      dispatch('setSnackbar', { text: 'Failed to retrieve modules. Please reload the page.', type: 'fail' });
      dispatch('flipSnackbarSwitch');
    }
    return res.results.map((module) => ({
      text: _.startCase(module.module_name),
      value: module.id,
    }));
  })
  .catch((err) => err);

export const fetchPlatformUserModules = (userId) => api.get(`/datahub/user_accounts_modules/?user=${userId}&is_user_level_feature=true`)
  .then((res) => {
    if (!res.modules) {
      res.modules = [];
    }
    return res.modules;
  })
  .catch((err) => err);

export const updatePlatformUserRoles = (data) => api.post('/datahub/user_account_roles', data);

export const updatePlatformUserModules = (data) => api.post('/datahub/user_account_modules', data);

export const registerPlatformUser = (data, dispatch) => api.post('/advisor_portal/api/register', data)
  .then((res) => {
    if (res.error) {
      if (res.error === 'User exists') {
        dispatch('setSnackbar', { text: 'The submitted email address is already in use.', type: 'fail' });
      } else {
        dispatch('setSnackbar', { text: 'Failed to create a new platform user. Please try again', type: 'fail' });
      }
    } else {
      dispatch('setSnackbar', { text: 'Sucessfully created new platform user.', type: 'success' });
    }
    dispatch('flipSnackbarSwitch');
    return res;
  })
  .catch((err) => err);

export const resetPassword = (data, dispatch) => api.post('/advisor_portal/api/reset_password', data)
  .then((res) => {
    if (res.success) {
      dispatch('setSnackbar', { text: 'Successfully sent activation email.', type: 'success' });
    } else {
      dispatch('setSnackbar', { text: 'Failed to send activation email.', type: 'fail' });
    }
    dispatch('flipSnackbarSwitch');
    return res;
  })
  .catch((err) => err);

export const updatePlatformUserApproval = (userAccountId, data, dispatch) => api.post(`/datahub/users/${userAccountId}/`, data)
  .then((res) => {
    if (data.is_approved) {
      if (res.username) {
        dispatch('setSnackbar', { text: 'Successfully approved platform user.', type: 'success' });
      } else {
        dispatch('setSnackbar', { text: 'Failed to approve platform user.', type: 'fail' });
      }
      dispatch('flipSnackbarSwitch');
    }
    return res;
  })
  .catch((err) => err);

export const updatePlatformUserTeams = (data) => api.post('/datahub/team_members', data);
