<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    lazy
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        solo
        flat
        outline
        v-on="on"
        mask="####-##-##"
        @input="emitTextFieldDate($event)"
        :disabled="readonly"
        :value="value"
        :class="{ datepicker: true, 'prepend-datepicker': iconLocation === 'before' }"
        :append-icon="iconLocation === 'after' ? 'date_range' : undefined"
        :prepend-inner-icon="iconLocation === 'before' ? 'date_range' : undefined"
        :rules="rules"
        :return-masked-value="true"
      />
    </template>
    <v-date-picker
      no-title
      @change="$emit('input', $event)"
      :value="value"
      :color="datepickerColor"
      :min="minDate"
      :max="maxDate"
    >
      <v-spacer />
      <v-btn v-if="!isClearable" flat color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn flat color="primary" @click="$refs.menu.save()">OK</v-btn>
      <v-btn v-if="isClearable" flat color="primary" @click="onClearClicked($event)">Clear</v-btn>

    </v-date-picker>
  </v-menu>
</template>

<script>
import validation from 'Services/validation';

export default {
  name: 'pas-datepicker',
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    iconLocation: {
      type: String,
      default: 'after', // after or before
    },
    rules: {
      type: Array,
      default() {
        return [
          (v) => !!v || 'Date of birth is required',
          (v) => (v >= '1900-01-01' && v <= '2100-12-31') || 'Year must be between 1900 and 2100',
          validation.isValidDate,
        ];
      },
    },
    minDate: {
      type: String,
      default: '1900-01-01',
    },
    maxDate: {
      type: String,
      default: '2100-12-31',
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    onClearClicked(event) {
      this.$emit('clearDate', event);
      this.menu = false;
    },
    emitTextFieldDate(event) {
      if (event.length < 10) return;
      // Only emit value when date is fully written out
      this.$emit('input', event);
    },
  },
  computed: {
    datepickerColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style lang="scss">

.datepicker {
  .v-input__slot {
    border-radius: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 15px 0px 20px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 40px !important;
    min-width: 120px !important;
    input {
        padding: 0px !important;
        margin: 0px !important;
    }
    i {
      font-size: 15px !important;
    }
  }
  min-height: 40px !important;
  height: 40px !important;
}
.prepend-datepicker {
  .v-input__prepend-inner {
    left: -11px;
  }
  .v-text-field__slot {
    border-left: 1px solid #dddddd;
    left: -10px !important;
    padding-left: 20px !important;
  }
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

</style>
