<template>
	<v-container>
		<v-layout align-baseline mt-4>
			<PasPageTitle class="mb-4">Workflows</PasPageTitle>
			<div class="ml-2 subheading grey--text">({{searchResults}})</div>
		</v-layout>
		<v-layout row align-center pt-1 class="list-filters-row">
			<v-flex shrink >
				<PasSearchBox v-model="filterOptions.search"/>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex shrink mx-4>
				<span class="filter-label">Type:</span>
			</v-flex>
			<v-flex shrink mt-2 mr-3>
				<PasDropDown
					:value="filterOptions.sortByType"
					:items="sortByTypeOptions"
					@change="$set(filterOptions, 'sortByType', $event)"
				/>
			</v-flex>
			<v-flex shrink mx-4>
				<span class="filter-label">Status:</span>
			</v-flex>
			<v-flex shrink mt-2 mr-3>
				<PasDropDown
					:value="filterOptions.sortByStatus"
					:items="sortByStatusOptions"
					@change="$set(filterOptions, 'sortByStatus', $event)"
				/>
			</v-flex>
			<v-flex shrink mx-4>
				<span class="filter-label">Period:</span>
			</v-flex>
			<v-flex shrink mr-3>
				<PasDateRangePicker
					@input="filterOptions.sortByPeriod = $event"
					:_start="filterOptions.sortByPeriod[0]"
        	:_end="filterOptions.sortByPeriod[1]"
				/>
			</v-flex>
		</v-layout>
		<WorkflowList
			:loading="loading"
			:workflows="filteredWorkflows"
			class="pt-3"
		/>
		<v-layout v-if="!loading" text-xs-center mt-4>
			<v-flex>
				<PasPagination
					:length="paginationLength"
					:value="filterOptions.page"
					@input="$set(filterOptions, 'page', $event)"
				/>
			</v-flex>
    	</v-layout>
	</v-container>
</template>

<script>
import api from 'Services/api';
import moment from 'moment';
import _ from 'lodash';
import WorkflowList from './MyWorkflows/WorkflowList';


export default {
  name: 'MyWorkflows',
  components: {
    WorkflowList,
  },
  data() {
    return {
      filterOptions: {
        search: '',
        sortByType: 'All types',
        sortByStatus: 'All',
        sortByPeriod: [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        page: 1,
        perPage: 15, // not modifiable
      },
      paginationLength: 1,
      filterByTypeOptions: [
        'Account opening',
        'Client onboarding',
        'Systematic setup',
        'EFT setup',
        'IPS signing',
        'Bank account setup',
        'External Account Transfer',
        'All types',
      ],
      sortByStatusOptions: [
        'Not started',
        'In progress',
        'Needs attention',
        'Completed',
        'Needs compliance review',
        'All',
      ],
      sortByPeriodOptions: ['Last week'],
      workflows: [],
      filteredWorkflows: [],
      searchResults: 0,
      loading: true,
    };
  },
  async mounted() {
    const { workflows } = await api.get('/advisor_portal/api/workflows');
    this.workflows = workflows;
    this.loading = false;
  },
  computed: {
    computedFilterOptions() {
      return { ...this.filterOptions };
    },
  },
  methods: {
    filterAndSort(paginationChanged) {
      const opts = this.filterOptions;
      this.filteredWorkflows = _.cloneDeep(this.workflows).filter((w) => w.type !== 'Unknown');

      if (opts.search && opts.search.length > 0) {
        this.filteredWorkflows = this.filteredWorkflows.filter((w) => {
          const searchWord = opts.search.toLowerCase();
          const clientCheck = w.client && w.client.toLowerCase().indexOf(searchWord) !== -1;
          const advisorCheck = w.assigned_user_name && w.assigned_user_name.toLowerCase().indexOf(searchWord) !== -1;
          return clientCheck || advisorCheck;
        });
      }
      if (opts.sortByType !== 'All types') {
        this.filteredWorkflows = this.filteredWorkflows.filter((w) => w.type && w.type === opts.sortByType);
      }
      if (opts.sortByStatus !== 'All') {
        this.filteredWorkflows = this.filteredWorkflows.filter((w) => (opts.sortByStatus !== 'Needs compliance review' ? w.status === opts.sortByStatus : w.review_needed));
      }

      if (opts.sortByPeriod[0] && opts.sortByPeriod[1]) {
        this.filteredWorkflows = this.filteredWorkflows.filter((w) => {
          const after = moment(opts.sortByPeriod[0]);
          const before = moment(opts.sortByPeriod[1]);
          const date = moment(w.created_at);
          return date >= after && date <= before;
        });
      }

      this.filteredWorkflows = _.sortBy(this.filteredWorkflows, (w) => new moment(w.created_at)).reverse();

      this.searchResults = this.filteredWorkflows.length;

      this.paginationLength = this.filteredWorkflows.length ? Math.ceil(this.filteredWorkflows.length / opts.perPage) : 1;
      const iStart = (opts.page - 1) * opts.perPage;
      const iEnd = opts.page * opts.perPage;
      this.filteredWorkflows = this.filteredWorkflows.slice(iStart, iEnd);
    },
  },
  watch: {
    computedFilterOptions: {
      handler(newValue, oldValue) {
        newValue.page === oldValue.page ? this.$set(this.filterOptions, 'page', 1) : null;
        this.filterAndSort();
      },
      deep: true,
    },
    workflows() {
      this.filterAndSort();
    },
  },
};

</script>

<style lang="scss">
@import '~Styles/variables';

.filter-label {
	width: 50px;
	color: $light-text !important;
}
</style>
