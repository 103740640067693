<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span
      v-if="step.status === 'needs_attention'"
      @click.prevent="completeStep"
      class="action-text"
    >
    Mark as Complete
    </span>
  </WorkflowStep>
</template>

<script>
import { sendMessage } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'KYCCustodianValidationStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
    },
  },
  methods: {
    async completeStep() {
      const res = await sendMessage(
        this.workflow,
        { messageType: `workflow.${this.workflow.type}.fcc_validation_kyc.success` },
      );
      try {
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow...',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style>

</style>
