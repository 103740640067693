import api from 'Services/api';
import apiV2 from 'Services/apiV2';

const defaultState = {
  relinquishingInstitutions: [],
  bankInstitutions: [],
  allSystematics: [],
  allMoneyMovementReasons: [],
  allPortfolioCategories: [],
  allSubadvisors: [],
  allModelPortfolios: [],
  docTypes: [],
  allWorkflowTypes: [],
  allAccountUsages: [],
  allRiskProfiles: [],
  goalOptions: [],
  timeHorizonOptions: [],
  allSignatureTypes: [],
  languages: [],
};

const mutations = {
  SET_RELINQUISHING_INSTITUTIONS(state, relinquishingInstitutions) {
    state.relinquishingInstitutions = relinquishingInstitutions;
  },
  SET_BANK_INFO(state, bankInstitutions) {
    state.bankInstitutions = bankInstitutions;
  },
  SET_ALL_SYSTEMATICS(state, systematics) {
    state.allSystematics = systematics;
  },
  SET_ALL_MONEY_MOVEMENT_REASONS(state, allMoneyMovementReasons) {
    state.allMoneyMovementReasons = allMoneyMovementReasons;
  },
  SET_ALL_PORTFOLIO_CATEGORY(state, allPortfolioCategories) {
    state.allPortfolioCategories = allPortfolioCategories;
  },
  SET_ALL_SUB_ADVISOR(state, allSubadvisors) {
    state.allSubadvisors = allSubadvisors;
  },
  SET_ALL_MODEL_PORTFOLIOS(state, allModelPortfolios) {
    state.allModelPortfolios = allModelPortfolios;
  },
  SET_DOCTYPES(state, docTypes) {
    state.docTypes = docTypes;
  },
  SET_ALL_WORKFLOW_TYPES(state, allWorkflowTypes) {
    state.allWorkflowTypes = allWorkflowTypes;
  },
  SET_ALL_ACCOUNT_USAGES(state, allAccountUsages) {
    state.allAccountUsages = allAccountUsages;
  },
  SET_ALL_RISK_PROFILES(state, allRiskProfiles) {
    state.allRiskProfiles = allRiskProfiles;
  },
  SET_GOAL_OPTIONS(state, goalOptions) {
    state.goalOptions = goalOptions;
  },
  SET_TIME_HORIZON_OPTIONS(state, timeHorizonOptions) {
    state.timeHorizonOptions = timeHorizonOptions;
  },
  SET_SIGNATURE_TYPES(state, allSignatureTypes) {
    state.allSignatureTypes = allSignatureTypes;
  },
  SET_LANGUAGES(state, languages) {
    state.languages = languages;
  },
};

const actions = {
  fetchRelinquishingInstitutions({ dispatch, commit }) {
    return api.get('/datahub/relinquishing_institutions/?limit=1000')
      .then((res) => {
        if (!res.results) {
          const text = 'An Error has occurred while loading relinquishing institutions, please refresh the page.';
          dispatch('setSnackbar', { text, type: 'fail' });
          dispatch('flipSnackbarSwitch');
          return;
        }
        commit('SET_RELINQUISHING_INSTITUTIONS', res.results.map((ri) => ri.name));
      }).catch((err) => console.log('err eft_bank_info', err));
  },
  fetchBankInstitutions({ commit }) {
    return api.get('/datahub/eft_bank_info/simple/').then((res) => {
      commit('SET_BANK_INFO', res);
    }).catch((err) => console.log('err eft_bank_info', err));
  },
  fetchAllSystematics({ commit }) {
    return api.get('/datahub/systematics_pas/')
      .then((res) => {
        if (res.code === 500) {
          commit('SET_ALL_SYSTEMATICS', []);
          return { success: false };
        }
        commit('SET_ALL_SYSTEMATICS', res);
        return { success: true };
      })
      .catch((err) => console.log('err', err));
  },
  fetchAllMoneyMovementReasons({ commit }) {
    return api.get('/datahub/money_movement_reasons/')
      .then((res) => {
        if (!res.results) {
          const text = 'An error has occurred. Please refresh the page.';
          dispatch('setSnackbar', { text, type: 'fail' });
          dispatch('flipSnackbarSwitch');
          return;
        }
        commit('SET_ALL_MONEY_MOVEMENT_REASONS', res.results);
      });
  },
  fetchAllPortfolioCategories({ commit }) {
    return apiV2.get('/portfolio_category/?limit=1000&offset=0')
      .then((res) => commit('SET_ALL_PORTFOLIO_CATEGORY', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllSubAdvisors({ commit }) {
    return apiV2.get('/subadvisors/?limit=1000&offset=0')
      .then((res) => commit('SET_ALL_SUB_ADVISOR', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllModelPortfolios({ commit }) {
    return apiV2.get('/model_portfolio/?limit=1000&offset=0')
      .then((res) => commit('SET_ALL_MODEL_PORTFOLIOS', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllDocumentTypes({ commit }) {
    return api.get('/advisor_portal/documents/get_doc_types')
      .then((res) => commit('SET_DOCTYPES', res.docTypes))
      .catch((err) => { throw err; });
  },
  fetchAllWorkflowTypes({ commit }) {
    return apiV2.get('/new_workflow_types/?limit=100')
      .then((res) => commit('SET_ALL_WORKFLOW_TYPES', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllAccountUsages({ commit }) {
    return api.get('/datahub/account_usage/')
      .then((res) => commit('SET_ALL_ACCOUNT_USAGES', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllRiskProfiles({ commit }) {
    return api.get('/datahub/risk_profile/')
      .then((res) => commit('SET_ALL_RISK_PROFILES', res.results))
      .catch((err) => { throw err; });
  },
  fetchGoalOptions({ commit }) {
    return api.get('/datahub/goal_options/')
      .then((res) => commit('SET_GOAL_OPTIONS', res.results))
      .catch((err) => { throw err; });
  },
  fetchtimeHorizonOptions({ commit }) {
    return api.get('/datahub/time_horizon_options/')
      .then((res) => commit('SET_TIME_HORIZON_OPTIONS', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllSigntureTypes({ commit }) {
    return api.get('/datahub/client_signature_types/')
      .then((res) => commit('SET_SIGNATURE_TYPES', res.results))
      .catch((err) => { throw err; });
  },
  fetchAllLanguages({ commit }) {
    return api.get('/datahub/languages/')
      .then((res) => commit('SET_LANGUAGES', res.results))
      .catch((err) => { throw err; });
  },
};

const getters = {
  relinquishingInstitutions: (state) => state.relinquishingInstitutions,
  bankInstitutions: (state) => state.bankInstitutions,
  allSystematics: (state) => state.allSystematics,
  allMoneyMovementReasons: (state) => state.allMoneyMovementReasons,
  allPortfolioCategories: (state) => state.allPortfolioCategories,
  allSubadvisors: (state) => state.allSubadvisors,
  allModelPortfolios: (state) => state.allModelPortfolios,
  docTypes: (state) => state.docTypes,
  allWorkflowTypes: (state) => state.allWorkflowTypes,
  allAccountUsages: (state) => state.allAccountUsages,
  allRiskProfiles: (state) => state.allRiskProfiles,
  goalOptions: (state) => state.goalOptions,
  timeHorizonOptions: (state) => state.timeHorizonOptions,
  allSignatureTypes: (state) => state.allSignatureTypes,
  languages: (state) => state.languages,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
