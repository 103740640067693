import { fetchTeamMembers, fetchTeams } from 'Services/api/ClientDataHierarchy';

const defaultState = {
  teamList: [],
  advisorTeams: [],
  teamMembers: [],
  pmTeams: [],
};

const mutations = {
  SET_TEAMS(state, teams) {
    state.teamList = teams;
  },
  SET_ADVISOR_TEAMS(state, teams) {
    state.advisorTeams = teams;
  },
  SET_TEAM_MEMBERS(state, teamMembers) {
    state.teamMembers = teamMembers;
  },
  SET_PM_TEAMS(state, teams) {
    state.pmTeams = teams;
  },
};

const actions = {
  setTeamMembers({ commit }, platformUserID) {
    return fetchTeamMembers({ context: 'platform_user', platform_user: platformUserID })
      .then((res) => {
        if (res.status === 'success') {
          commit('SET_TEAM_MEMBERS', res.team_members);
        }
      });
  },
  fetchAllPmTeams({ commit }) {
    return fetchTeams({ is_client_service_team: true })
      .then((res) => {
        if (res.status === 'success') {
          commit('SET_PM_TEAMS', res.teams);
        }
      });
  },
};

export const getters = {
  teamMembers: (state) => state.teamMembers,
  advisorTeams: (state) => state.advisorTeams.map((team) => ({
    text: team.name,
    value: team.id,
    rep_code_access: team.rep_code_access,
    email: team.email,
  })),
  allPmTeams: (state) => state.pmTeams,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
