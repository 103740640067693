<template>
  <div class="d-flex flex-row">
    <PDropdown :text="REQUEST_TYPES[transactionParams.requestType] || 'All transactions'" class="mr-1" no-caret>
      <PDropdownItem @click="$emit('update:requestType', '');">All Transactions</PDropdownItem>
      <PDropdownItem @click="$emit('update:requestType', 'PAC');">Deposits</PDropdownItem>
      <PDropdownItem @click="$emit('update:requestType', 'SWP');">Withdrawals</PDropdownItem>
      <PDropdownItem @click="$emit('update:requestType', 'EXTERNAL-TRANSFER');">Transfers In</PDropdownItem>
    </PDropdown>
    <PDropdown :text="transactionParams.accountType || 'All Accounts'" class="mr-1" no-caret>
      <PDropdownItem @click="$emit('update:accountType', '');">All Accounts</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'Cash');">Cash</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'Joint Cash');">Joint Cash</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'Cash-ITF');">Cash-ITF</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'RRSP');">RRSP</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'Spousal RRSP');">Spousal RRSP</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'LIRA');">LIRA</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'LRSP');">LRSP</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'RESP');">RESP</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'RIF');">RIF</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'Spousal RIF');">Spousal RIF</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'LRIF');">LRIF</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'LIF');">LIF</PDropdownItem>
      <PDropdownItem @click="$emit('update:accountType', 'TFSA');">TFSA</PDropdownItem>
    </PDropdown>
    <PDropdown :text="TRANSACTION_STATUSES[transactionParams.status] || 'All Statuses'" class="mr-1" no-caret>
      <PDropdownItem @click="$emit('update:status', '');">All Statuses</PDropdownItem>
      <PDropdownItem @click="$emit('update:status', 'client-signature');">Client Signature</PDropdownItem>
      <PDropdownItem @click="$emit('update:status', 'under review');">Under Review</PDropdownItem>
      <PDropdownItem @click="$emit('update:status', 'pending');">Upcoming</PDropdownItem>
      <PDropdownItem @click="$emit('update:status', 'processed');">Completed</PDropdownItem>
      <PDropdownItem @click="$emit('update:status', 'cancelled')">Cancelled</PDropdownItem>
    </PDropdown>
  </div>
</template>

<script>
import { TRANSACTION_STATUSES, REQUEST_TYPES } from 'Services/constants';

export default {
  name: 'TransactionFilters',
  props: {
    transactionParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedFilter: null,
      TRANSACTION_STATUSES,
      REQUEST_TYPES,
    };
  },
};
</script>
