<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span v-if="shouldShowActions && !equifaxIDFeatureEnabled">
      <ul>
        <li>
          <span
            @click.prevent="rerunEquifax"
            :class="['action-text']"
          >
            Rerun Equifax
          </span>
        </li>
        <li>
          <span
            @click.prevent="idModal = true"
            class="action-text"
          >
            upload ID
          </span>
        </li>
      </ul>
    </span>
    <span v-else-if="shouldShowActions">
      <pas-button
          class="primary-btn wide-btn"
          @click="$router.push({ name: 'EquifaxUpload', params: { clientID } })"
        >Resolve</pas-button>
    </span>
    <UploadId
      :workflowId="workflow.id"
      :isOpen="idModal && !photoUploaded"
      @close="idModal = false"
      @done="rerunEquifax(); idModal = false"
    />
  </WorkflowStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendEquifaxCheck } from 'Services/api/Workflows';
import WorkflowStep from './WorkflowStep';
import UploadId from './EquifaxCheckStep/UploadId';

export default {
  name: 'EquifaxCheckStep',
  components: { WorkflowStep, UploadId },
  props: {
    workflow: {
      type: Object,
      default: () => {},
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      idModal: false,
      photoUploaded: false,
    };
  },
  computed: {
    ...mapGetters(['activeClient', 'hasFullWorkflowEditPermission', 'hasFeatureFlag']),
    clientID() {
      return this.workflow.clientInfo.id;
    },
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
    shouldShowActions() {
      const { status, type } = this.step;
      return (status === 'needs_attention' || (status === 'process_error' && type === 'fintrac_repapering')) && this.hasFullWorkflowEditPermission;
    },
    equifaxIDFeatureEnabled() {
      return this.hasFeatureFlag('equifax_identification');
    },
  },
  methods: {
    async rerunEquifax() {
      this.$store.dispatch('setSnackbar', {
        text: 'Rerunning Equifax...',
        type: 'success',
      });
      this.$store.dispatch('flipSnackbarSwitch');
      const photoUploaded = (this.documents.some((d) => d.document_type === '19'));
      await sendEquifaxCheck(this.workflow, photoUploaded);
      await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
      await this.$store.dispatch('fetchWorkflowV2Details', this.workflow.id);
    }
  },
};
</script>

<style scoped>

</style>
