<template>
  <auth-page>
    <v-layout column>
      <template v-if="screen === screens.indexOf('Details')">
        <v-flex>
          <pas-page-title>Set up your account</pas-page-title>
        </v-flex>
        <v-flex>
          <div class="light-text">
            Let's finish this up. We need some info about you first!
          </div>
        </v-flex>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent
          @keyup.enter.native="register"
        >
          <v-flex mt-4>
            <v-layout row nowrap>
              <v-flex xs6 mr-1>
                <v-layout column>
                  <div class="strong-text form-label">First Name</div>
                  <pas-input-field
                    :placeholder="`First Name`"
                    v-model="firstName"
                    :rules="[v.isRequired()]"
                    :key="`first-name`"
                  ></pas-input-field>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout column>
                  <div class="strong-text form-label">Last Name</div>
                  <pas-input-field
                    :placeholder="`Last Name`"
                    v-model="lastName"
                    :rules="[v.isRequired()]"
                    :key="`last-name`"
                  ></pas-input-field>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex mt-4>
            <v-layout column>
              <v-flex>
                <v-layout row>
                  <div class="strong-text form-label">Password</div>
                </v-layout>
              </v-flex>
              <pas-input-field
                :placeholder="`Password`"
                :type="show ? `text` : `password`"
                :browserAutocomplete="'new-password'"
                v-model="password"
                :append-icon="`mdi-eye-outline`"
                :rules="[v.isRequired(), v.isValidPassword]"
                @appendClick="show = !show"
              ></pas-input-field>
            </v-layout>
          </v-flex>
        </v-form>
        <v-flex mt-4>
          <pas-error-div
            :show="errorText !== ''"
            :style="{ display: 'inline' }"
            >{{ errorText }}</pas-error-div
          >
        </v-flex>
        <v-flex mt-4>
          <v-layout>
            <v-flex shrink text-xs-left>
              <div class="light-text">Already have an account?</div>
              <router-link :to="{ name: 'login' }" class="action-text"
                >Sign-in.</router-link
              >
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <pas-button
                class="px-5"
                @click="register"
                :processing="registerProcessing"
              >
                Register
              </pas-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
      <template v-if="screen === screens.indexOf('Success')">
        <v-flex>
          <pas-page-title>You're all set!</pas-page-title>
        </v-flex>
        <v-flex>
          <div class="light-text">
            A member of our team will reach out to you.
          </div>
        </v-flex>
      </template>
    </v-layout>
  </auth-page>
</template>

<script>
import api from 'Services/api';
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage';
import v from 'Services/validation';

const screens = ['Details', 'Success'];

export default {
  name: 'new-account-details',
  components: {
    AuthPage,
  },
  data() {
    return {
      v,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      termsAndConditions: false,
      errorText: '',
      registerProcessing: false,
      screens,
      screen: screens.indexOf('Details'),
      show: false,
    };
  },
  mounted() {
    // grab email from query string
    this.email = this.$route.query.email;

    // TODO verify valid email, if not reroute to /confirm
  },
  computed: {
    firm() {
      return this.$store.getters.firm;
    },
    formInputs() {
      return [this.firstName, this.lastName, this.password];
    },
  },
  methods: {
    async register() {
      this.errorText = '';
      if (!this.$refs.form.validate()) return;
      this.registerProcessing = true;
      const data = {
        username: this.email,
        password: this.password,
        firstname: this.firstName,
        lastname: this.lastName,
        firm: this.firm,
      };
      await api
        .post('/advisor_portal/api/register', data)
        .then((res) => {
          if (res.hasOwnProperty('error')) {
            if (res.error.includes('exists') || res.error.includes('Exists')) {
              throw new Error(`This email already exists ${this.email}.`);
            } else {
              // generic error
              throw new Error('Error registering with provided details.');
            }
          } else {
            this.errorText = '';
            this.screen = this.screens.indexOf('Success');
          }
        })
        .catch((err) => {
          console.log('register err', err);
          this.errorText = err.message || 'There was an error signing up. Please try again.';
        });
      this.registerProcessing = false;
    },
  },
  watch: {
    formInputs() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>
