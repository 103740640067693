<template>
  <div class="loader-container">
    <div class="spinner">
      <v-layout justify-center>
        <svg width="100px" height="100px" viewBox="-26 -26 100 100" class="spinner_svg">
          <defs />
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" transform="translate(2.000000, 2.000000)" :stroke="primaryColor">
              <circle id="Oval-1" stroke-width="4" cx="22.5" cy="22.5" r="22.5" />
              <circle id="Oval-2" cx="22.5" cy="22.5" r="22.5" stroke-width="1.5" />
              <circle id="Oval-3" cx="22.5" cy="22.5" r="22.5" stroke-width="1.5" />
              <circle id="Oval-4" cx="22.5" cy="22.5" r="22.5" stroke-width="1.5" />
            </g>
          </g>
        </svg>
      </v-layout>
    </div>
    <div class="loading-message">{{loadingMessage}}</div>
  </div>
</template>

<script>
export default {
  name: 'pas-circular-loader',
  props: {
    loadingMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  background: #e0e0e0;
}
.loader-container .loading-message {
  width: 100%;
  text-align: center;
}
.spinner_svg {
  position: relative;
  z-index: 400;
}
#Group {
  stroke-dasharray: 142;
  stroke-dashoffset: 142;
  -webkit-animation: dash 8s cubic-bezier(0.02, 0.61, 0.85, 0.98) infinite;
  animation: dash 8s cubic-bezier(0.02, 0.61, 0.85, 0.98) infinite;
}
#Oval-1 {
  fill: none;
  -webkit-transform-origin: 22.5px 22.5px;
  transform-origin: 22.5px 22.5px;
  -webkit-animation: pulsate 8s infinite;
  animation: pulsate 8s infinite;
}
#Oval-2,
#Oval-3,
#Oval-4 {
  fill: none;
  -webkit-transform-origin: 22.5px 22.5px;
  transform-origin: 22.5px 22.5px;
  opacity: 0;
}
#Oval-2 {
  -webkit-animation: float 8s ease 3s infinite;
  animation: float 8s ease 3s infinite;
}
#Oval-3 {
  -webkit-animation: float2 8s ease 4s infinite;
  animation: float2 8s ease 4s infinite;
}
#Oval-4 {
  -webkit-animation: float3 8s ease 5s infinite;
  animation: float3 8s ease 5s infinite;
}
@-webkit-keyframes dash {
  25% {
    stroke-dashoffset: 0;
    opacity: inherit;
  }
  75% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  99% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 142;
  }
}
@keyframes dash {
  25% {
    stroke-dashoffset: 0;
    opacity: inherit;
  }
  75% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  99% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 142;
  }
}
@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  28% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  37.5% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  40.5% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  53% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  62.5% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  65.5% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  75% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    opacity: 1;
    fill: none;
  }
  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    fill: white;
  }
}
@keyframes pulsate {
  0% {
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  28% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  37.5% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  40.5% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  53% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  62.5% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
  }
  65.5% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  75% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    animation-timing-function: cubic-bezier(0, 0.96, 0.56, 0.97);
    opacity: 1;
    fill: none;
  }
  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    fill: white;
  }
}
@-webkit-keyframes float {
  0% {
    opacity: 0;
  }
  0.1% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.6;
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes float {
  0% {
    opacity: 0;
  }
  0.1% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.6;
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes float2 {
  0% {
    opacity: 0;
  }
  0.1% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes float2 {
  0% {
    opacity: 0;
  }
  0.1% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes float3 {
  0% {
    opacity: 0;
  }
  0.1% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes float3 {
  0% {
    opacity: 0;
  }
  0.1% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.6;
  }
  30% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.spinner {
  position: relative;
  z-index: 200;
  width: 300px;
  height: 100px;
  background: transparent;
  margin: 0 auto;
  margin-top: 0px;
  padding-top: 0px;
  border-radius: 4px;
  /*box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.2);*/
}
.spinner:after {
  content: "";
  position: absolute;
  z-index: 300;
  width: 300px;
  height: 0px;
  top: 0;
  left: 0;
  opacity: 0.3;
  background-size: 100%;
}
</style>
