<template>
    <div>
        <pas-avatar
            :size="'100px'"
            class="mt-4"
            :avatar="avatar"
            v-if="avatarRefresh"
        ></pas-avatar>
        <v-menu
            offset-y
            class="edit-client-avatar"
            open-on-hover
            open-on-click
            v-if="!readonly"
        >
            <v-btn fab small class="pas-fab" slot="activator" icon>
                <v-icon>edit</v-icon>
            </v-btn>
            <v-list>
                <v-list-tile>
                    <v-list-tile-title class="cursor-pointer">
                        <upload-avatar
                            :id="'userAvatarEdit'"
                            :type="true"
                            @updateDropzone="updateDropzone"
                            v-if="dropzoneRefresh"
                        >
                            <div class="px-4 more-menu-item">Upload</div>
                        </upload-avatar>
                    </v-list-tile-title>
                </v-list-tile>
                <v-list-tile>
                    <v-list-tile-title class="cursor-pointer" @click="deleteAvatar">
                        <div class="px-4 more-menu-item">Remove</div>
                    </v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
    </div>
</template>

<script>

import { getAvatar } from 'Services/helpers/files';
import UploadAvatar from 'Components/Shared/UploadAvatar'

export default {
  name: 'client-avatar',
  components: {
    UploadAvatar
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropzoneRefresh: true,
      avatarRefresh: true,
      getAvatar,
      avatar: '',
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
  },
  methods: {
    updateDropzone() {
      this.updateAvatar();
      this.dropzoneRefresh = false;
      this.$nextTick().then(() => {
        this.dropzoneRefresh = true;
      });
    },
    updateAvatar() {
      this.avatarRefresh = false;
      this.$nextTick().then(() => {
        this.avatar = `${getAvatar('clients', this.activeClient.client.id)}?${new Date().getTime()}`;
        this.avatarRefresh = true;
      });
    },
    deleteAvatar() {
      this.$store.dispatch('deleteAvatar', this.activeClient.client.id)
        .then((res) => this.updateAvatar());
    },
  },
  mounted() {
    this.avatar = getAvatar('clients', this.activeClient.client.id);
  },
};
</script>

<style lang="scss">
.edit-client-avatar {
  position: absolute !important;
  left: 65px;
  top: 85px;
}
</style>
