<template>
  <v-text-field
    single-line
    solo
    flat
    :id="id"
    :value="value"
    @input="$emit('input', $event)"
    class="input-field pas-input-field"
    :placeholder="placeholder"
    :prefix="prefix"
    :suffix="suffix"
    :rules="rules"
    :type="type"
    :readonly="readonly"
    :disabled="disabled"
    :label="label"
    :mask="masked"
    :prepend-inner-icon="prependInnerIcon"
    :append-icon="appendIcon"
    @click:append ="$emit('appendClick')"
    :browser-autocomplete="browserAutocomplete"
    :maxlength="maxLength"
    @click ="$emit('click')"
  ></v-text-field>
</template>

<script>
export default {
  name: 'pas-input-field',
  props: {
    id: {
      type: String,
      default: undefined,
    },
    value: [String, Number],
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    browserAutocomplete: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    masked: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default() {
        return null;
      },
    },
    min: {
      type: String,
      default: '',
    },
    step: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
    },
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">

.input-field {
  .v-input__slot {
    border-radius: 40px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 20px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 40px !important;
    margin: 0px !important;
    input {
      padding: 0px !important;
      margin: 0px !important;
    }
    .v-icon.mdi-eye-outline {
      opacity: 0.65;
    }
  }
  min-height: 40px !important;
  height: 40px !important;
}
</style>
