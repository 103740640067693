<template>
  <div>
    <v-layout pr-5>
      <span class="p-text">Takes notes and collaborate with your team. This is a place to discuss the workflow</span>
    </v-layout>
    <v-layout>
      <v-flex xs12 text-xs-right>
         <PasFab
          @click="onAddNewClick"
         >
          <v-icon
            small
            >
              edit
          </v-icon>
        </PasFab>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <template v-for="(comment, index) in displayedDiscussion">
        <workflow-view-discussion-comment
          :comment="comment"
          :key="`comment-${index}`"
          :inEditMode="commentBeingEdited.toString()===comment._id.toString()"
          :repliesExpanded="showReplies.includes(comment._id)"       
          :_deleteProcessing="deleteProcessing===comment._id"
          :_editProcessing="editProcessing===comment._id" 
          @edit="editComment($event)" 
          @cancelEdit="cancelEdit(comment._id)"
          @saveEdit="saveEdit($event)"
          @delete="deleteComment($event)"
          @showReplies="toggleReplyVisibility($event)"
          @reply="onReplyClick($event)"
        >
        </workflow-view-discussion-comment>
        <template v-if="showReplies.includes(comment._id)">
          <template v-for="(reply, index) in comment.replies">
            <workflow-view-discussion-comment
              :isReply="true"
              :isTopLevelReply="comment._id.toString() === reply.replyTo.comment.toString()"
              :comment="reply"
              :key="`reply-${index}`" 
              :inEditMode="commentBeingEdited.toString()===reply._id.toString()"   
              :_deleteProcessing="deleteProcessing===reply._id"    
              :_editProcessing="editProcessing===reply._id"    
              @edit="editComment($event)" 
              @cancelEdit="cancelEdit(reply._id)"
              @saveEdit="saveEdit($event)"
              @delete="deleteComment($event)"
              @reply="onReplyClick($event)"
            >
            </workflow-view-discussion-comment>
          </template>
        </template>
      </template>
     <v-flex xs12 text-xs-right pt-1 pb-4 
      v-if="discussion.length > 5"
     >
       <div class="action-text-small"
        @click="showMore = !showMore"
       >
         {{!showMore ? (`Show ${discussion.length - 5} more comment${(discussion.length - 5) === 1 ? '' : 's'}`) 
          :
          'Show less comments'
        }}
       </div>
     </v-flex>
      <v-flex class="comment-div" id="post-comment-div" ref="postCommentDiv">
        <v-layout row wrap mt-3>
          <v-flex xs12>
            <v-form
              ref="newCommentForm"
              @submit.prevent="() => {}"
              lazy-validation
            >
            <pas-textarea
              :placeholder="`Enter comment here`"
              v-model="newCommentBody"
              class="comments-textarea"
              :noResize="true"
              :rules="[v.isRequired()]"
              :prefix="replyTo ? `@${replyTo.author.name}` : ``"
              @backspace="commentsAreaBackspace"
            >
            </pas-textarea>
            </v-form>
          </v-flex>
          <v-flex xs12 text-xs-left>
            <pas-button
              class="primary-btn wide-btn"
              @click="postComment"
              :disabled="postButtonDisabled"
              :processing="postCommentProcessing"
            >
              Post Comment
            </pas-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash'
import api from "Services/api";
import WorkflowViewDiscussionComment from './WorkflowViewDiscussion/WorkflowViewDiscussionComment'
import v from 'Services/validation'

export default {
  name: 'workflow-view-discussion',
  components: {
    WorkflowViewDiscussionComment
  },
  data() {
    return {
      v: v,
      newCommentBody: '',
      replyTo: '',   //comment object being replied to
      postButtonDisabled: false,
      showReplies: [],  //array of top level comments to show replies for
      commentBeingEdited: '',  //the id of the comment being edited, empty string for none
      showMore: false,
      postCommentProcessing: false,
      deleteProcessing: 0,
      editProcessing: 0
    }
  },
  props: {
    workflow: Object
  },
  computed: {
    discussion() {
      let discussion = _.cloneDeep(this.workflow.discussion)
      discussion.forEach(comment => {this.setReplyTo(comment)})  
      discussion = this.removeDeletedReplies(discussion)
      discussion = discussion.reduce((acc, c) => {
        return acc.concat({...c, replies: this.flattenCommentReplies(c)})  //flatten replies
      }, [])
      discussion =  this.sortComments(discussion)
      
      return discussion
    },
    displayedDiscussion() {
      if (!this.showMore) {
        return (this.discussion.slice(0, 5))
      }
      return this.discussion
    },
    currentUser() {
      return this.$store.state.users.currentUser
    }
  },
  methods: {
    commentsAreaBackspace() {
      if (this.newCommentBody === '') {
        this.replyTo = '';
      }
    },
    onAddNewClick() {
      this.replyTo = ''
      this.scrollToAddComment()
    },
    onReplyClick(comment) {
      this.replyTo = comment
      this.scrollToAddComment()
    },
    sortComments(comments) {
      //recursively sorts comments by posted_at
      comments = comments.sort((a, b) => {
        return (new Date(a.posted_at).getTime() < new Date(b.posted_at).getTime() ? 1 : -1)
      })
      comments.forEach(c => {
        c.replies = this.sortComments(c.replies)
      })
      return comments
    },
    removeDeletedReplies(comments) {
      //recursively removes all comments & replies that are deleted with no replies, returns array
      let filteredComments = comments.filter(c => {
        return !(c.is_deleted && !(this.removeDeletedReplies(c.replies)).length)
      })
      filteredComments.forEach(c => {
        c.replies = this.removeDeletedReplies(c.replies)
      })
      return filteredComments
    },
    setReplyTo(comment) {
      //recursively populates replied to author in each reply
      if (!comment.replies.length) return
      comment.replies.forEach(r => {
        r.replyTo = {
          author: comment.author,
          comment: comment._id
        }
        if (r.replies.length) this.setReplyTo(r)
      })
    },
    postComment() {
      if (!(this.$refs.newCommentForm.validate())) return false
      this.postCommentProcessing = true
      this.$store.dispatch('addWorkflowComment', {
        workflowId: this.workflow._id,
        body: this.newCommentBody,
        replyTo: this.replyTo ? this.replyTo._id : ``
      })
      .then(res => {
        this.sendCommentNotification(this.newCommentBody, this.$store.state.users.currentUser)
        this.postCommentProcessing = false
        if (!res.error) {
          //refetch workflow
          this.$store.dispatch(
            "fetchWorkflow",
            this.workflow._id
          );
          this.newCommentBody = ''
          this.$refs.newCommentForm.reset();
        }
      })
      .catch(err => {
        this.postCommentProcessing = false
        console.log('err', err)
      })
    },
    async deleteComment(commentId) {
      this.deleteProcessing = commentId
      await this.$store.dispatch('deleteWorkflowComment', {
        workflowId: this.workflow._id,
        commentId
      })
      .then(async res => {
        if (!res.error) {
          await this.$store.dispatch(
            "fetchWorkflow",
            this.workflow._id
          );
        this.deleteProcessing = 0
        } else {
          this.deleteProcessing = 0
        }
      })
      .catch(err => {
        console.log('err', err)
        this.deleteProcessing = 0 
      })
    },
    editComment(commentId) {
      this.commentBeingEdited = commentId
    },
    cancelEdit(commentId) {
      this.commentBeingEdited = ''
    },
    async saveEdit(comment) {
      this.editProcessing = comment._id
      await this.$store.dispatch('editWorkflowComment',
      {
        workflowId: this.workflow._id,
        commentId: comment._id,
        body: comment.body
      })
      .then(async res => {
        this.commentBeingEdited = ''
        await this.$store.dispatch('fetchWorkflow', this.workflow._id)
        this.editProcessing = 0
      })
      .catch(err => {
        console.log('err', err)
        this.editProcessing = 0
      })      
    },
    toggleReplyVisibility(commentId) {
      if (this.showReplies.includes(commentId)) {
        this.showReplies = this.showReplies.filter(r => r !== commentId)
      } else {
        this.showReplies.push(commentId)
      }
    },
    scrollToAddComment() {
      this.$refs.postCommentDiv.scrollIntoView({
        behavior: "smooth",
        block: "end"
      })
    },
    flattenCommentReplies(comment) {
      if (!(comment.replies.length)) return []
      return comment.replies.reduce((acc, r) => {
        if (!(r.replies.length)) return acc.concat(r)
        else {
          return acc.concat(r).concat(this.flattenCommentReplies(r))
        }
      }, [])
    },
    sendCommentNotification(comment, user){
      const data = {
        client_id: this.workflow.pas_client_ids[0],
        workflow_id: this.workflow._id,
        comment: comment,
        commentor: user
      }

      api.post('/advisor_portal/send_comment_notification', data);
    }
  }
}
</script>

<style lang="scss">
@import '~Styles/variables';

  .comments-textarea .v-input__slot {
    height: 75px !important;
    .v-text-field__prefix {
      color: var(--primary-color) !important;
    }
  }

  .comments-textarea {
    .v-text-field__slot {
      flex-direction: column !important;
    }
    .v-text-field__prefix {
      margin-top: 4px !important;
      width: 100% !important;
      text-align: left !important;
    }
    .v-text-field__prefix + textarea {
      margin-top: 0px !important;
      padding-top: 0px !important;
    }
  }


</style>
