import { fetchPrograms, fetchRepCodes } from 'Services/api/ClientDataHierarchy';

const defaultState = {
  allPrograms: [],
  allRepCodeDetails: [],
};

const mutations = {
  SET_PROGRAMS(state, programs) {
    state.allPrograms = programs;
  },
  SET_REP_CODE_DETAILS(state, details) {
    state.allRepCodeDetails = details;
  },
};

const actions = {
  setPrograms({ commit }) {
    return fetchPrograms().then((res) => {
      const programs = res.results.map((program) => ({
        text: program.name,
        value: program.id,
        description: program.description,
      }));
      commit('SET_PROGRAMS', programs);
    });
  },
  setRepCodeDetails({ commit }) {
    return fetchRepCodes().then((res) => {
      const details = res.results.map((result) => ({
        rep_code: result.rep_code,
        program_id: result.program_id,
        pm_team_id: result.pm_team,
      }));
      commit('SET_REP_CODE_DETAILS', details);
    });
  },
};

export const getters = {
  allPrograms: (state) => state.allPrograms,
  allRepCodeDetails: (state) => state.allRepCodeDetails,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
