<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'pas-wizard',
  data() {
    return {};
  },
};
</script>

<style>

</style>
