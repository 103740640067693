<template>
  <div>
    <template v-if="onboardingSetupLoading">
      <v-layout justify-center class="investment-accounts-loader">
        <pas-circular-loader
          :loadingMessage="`Don't worry, we are processing your request. It will take just a moment. Please do not close this window or click the Back button on your browser`">
        </pas-circular-loader>
      </v-layout>
    </template>
    <template v-else>
      <v-layout>
        <v-flex xs12 text-xs-center mb-4>
          <pas-page-title>
            What would you like to do next?
          </pas-page-title>
        </v-flex>
      </v-layout>
      <v-layout mb-4 justify-center>
        <v-flex xs12 text-xs-center>
          <span>
            Send your client an invitation to join, complete the rest of their personal information, and sign their account opening documents.
          </span>
        </v-flex>
      </v-layout>
      <transition name="slide-fade">
        <v-layout column nowrap v-if="surveyOptionsLoaded" justify-center>
          <v-layout row nowrap v-if="surveyOptionsLoaded" justify-center>
            <v-flex xs4 text-xs-center mr-2 v-if="canEditInPersonOnboarding">
              <action-card
                :action="'In Person'"
                :imagePath="InPersonSvg"
                @click="!savingAccounts && $emit('triggerOnboardingWorkflowIP', { retrigger: false, inPerson: true, skipSurvey: false})"
                data-testid="onboarding-in-person-select"
              >Walk the client through the easy process of opening an account in person.
              </action-card>
            </v-flex>
            <v-flex v-if="!registered" xs4 text-xs-center>
              <action-card
                :action="'By Email'"
                :imagePath="EmailSvg"
                @click="!savingAccounts && $emit('triggerOnboardingWorkflowIP', { retrigger: false, inPerson: false, skipSurvey: false})"
                data-testid="onboarding-save-and-send"
              >Send a link to your client and let them go through the risk assessment on their own.
              </action-card>
            </v-flex>
            <v-flex v-else-if="registered" xs4 text-xs-center mr-2>
              <action-card
                :action="'Save and create workflow'"
                :imagePath="EmailSvg"
                @click="!savingAccounts && $emit('triggerOnboardingWorkflowIP', { retrigger:true, inPerson:false, skipSurvey})"
              >
                <v-checkbox
                  :color="primaryColor"
                  v-model="skipSurvey"
                  :label="'Complete the survey step automatically with information from previously filled survey.'"
                  v-if="surveyFilled"
                ></v-checkbox>
              </action-card>
            </v-flex>
          </v-layout>
          <v-layout row mb-4 justify-center >
              <PasButton @click="!savingAccounts && $emit('saveTempAccts')" class="px-5 mt-3 pas-border v-btn v-btn--round theme--light pas-border-btn">Save and come back</PasButton>
        </v-layout>
      </v-layout>
      </transition>
    </template>
  </div>
</template>

<script>
import ActionCard from 'Components/Shared/ActionCard';
import EmailSvg from 'Assets/icons/email-01.svg';
import InPersonSvg from 'Assets/icons/meeting-in-person.svg';
import ScreenshareSvg from 'Assets/icons/screenshare-01.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'TriggerOnboardingIP',
  components: {
    ActionCard,
  },
  data() {
    return {
      EmailSvg,
      InPersonSvg,
      ScreenshareSvg,
      skipSurvey: false,
    };
  },
  props: {
    onboardingSetupLoading: {
      type: Boolean,
      default: false,
    },
    surveyOptionsLoaded: {
      type: Boolean,
      default: false,
    },
    savingAccounts: {
      type: Boolean,
      default: false,
    },
    registered: {
      type: Boolean,
    },
    surveyFilled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters([
      'canEditInPersonOnboarding',
    ]),
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style>

</style>
