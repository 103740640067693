import api from 'Services/api';

// Reporting Group
export const createReportingGroup = (data) => api.post('/datahub/reporting_groups/', data);
export const joinReportingGroup = (data) => api.post('/datahub/client_reporting_groups/', data);
export const leaveReportingGroup = (id) => api.deleteCall(`/datahub/client_reporting_groups/${id}/`);
export const updateReportingGroup = (data) => api.post(`/datahub/reporting_groups/${data.id}/`, data);
export const updateСlientReportingGroup = (data) => api.post(`/datahub/client_reporting_groups/${data.id}/`, data);
export const fetchClientReportingGroup = (clientID) => api.get(`/datahub/client/reporting_group/${clientID}/`);

// PM Group
export const fetchClientPMGroup = (clientID) => api.get(`/datahub/client/pm_group/${clientID}/`);
export const createPMGroup = (data) => api.post('/datahub/pm_groups/', data);
export const updatePMGroup = (data) => api.post(`/datahub/pm_groups/${data.id}/`, data);
export const joinPMGroup = (data) => api.post('/datahub/client_pm_groups/', data);
export const leavePMGroup = (id) => api.deleteCall(`/datahub/client_pm_groups/${id}/`);
