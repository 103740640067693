<template>
  <router-view
    :clientID="clientID"
    :clientLoaded="clientLoaded"
  />
</template>

<script>
export default {
  name: 'client-view',
  data() {
    return {
      clientLoaded: false,
    };
  },
  computed: {
    clientID() {
      return this.$route.params.clientID;
    },
  },
  mounted() {
    // begin loading "nested/secondary" data - ex data to populate relationships modal
    // whatever we don't need for the initial render
    if (this.$store.state.clients.list[0] === 'loading') {
      this.$store.dispatch('setClients', ['fetching']);
      this.$store.dispatch('fetchClientsWithPermissions');
    }
  },
  beforeDestroy() {
    this.$store.dispatch('resetActiveClient');
  },
  watch: {
    clientID: {
      immediate: true,
      handler() {
        this.clientLoaded = false;
        this.$store.dispatch('resetActiveClient');
        Promise.all([
          this.$store.dispatch('fetchClientDetails', this.clientID),
          this.$store.dispatch('fetchRelationships', this.clientID),
          this.$store.dispatch('getWorkflows', this.clientID),
          this.$store.dispatch('fetchSystematics', this.clientID),
        ])
          .then((res) => this.clientLoaded = true)
          .catch((err) => console.log('err', err));
        this.$store.dispatch('fetchTotalAUM', this.clientID);
      },
    },
  },
};
</script>

<style>
</style>
