<template>
  <v-list-tile-content v-if="systematic.amount">
    <v-list-tile-title class="systematics-list-title">
      <v-layout
        row
        align-center
      >
        <v-flex>{{ systematic.type }}</v-flex>
        <v-spacer />
        <v-flex text-xs-right>
          {{ systematic.amount }}
        </v-flex>
      </v-layout>
    </v-list-tile-title>
    <v-list-tile-sub-title>
      <v-layout
        row
        align-center
      >
        <v-flex>
          <pas-status-bullet
            v-if="sysStatusMap[systematic.status]"
            :color="sysStatusMap[systematic.status].color"
            :value="sysStatusMap[systematic.status].value"
          />
          <span class="small-text">{{ systematic.nextPaymentDate }}</span>
        </v-flex>
        <v-spacer />

        <v-flex text-xs-right>
          <pas-display-chip
            :small="true"
            class="frequency-chip"
            :style="{marginRight: '0px'}"
          >
            {{ systematic.frequency }}
          </pas-display-chip>
        </v-flex>
      </v-layout>
    </v-list-tile-sub-title>
  </v-list-tile-content>
</template>

<script>
  export default {
    name: 'AccountSystematicInfo',
    props: {
      systematic: Object,
    },
    data() {
      return {
        sysStatusMap: [
          {
            color: '#d9ad4a',
            value: 50
          },
          {
            color: '#d9ad4a',
            value: 100
          },
          {
            color: '#d9ad4a',
            value: 100
          },
          {
            color: 'green',
            value: 100
          },
          {
            color: 'red',
            value: 50
          },
          {
            color: 'red',
            value: 100
          }
        ]
      }
    }
  };

</script>

<style scoped>
  .systematics-list > div[role="listitem"] {
    padding-top: 12px;
    padding-bottom: 6px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  .systematics-list > div[role="listitem"]:nth-last-child(1) {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .systematics-list-title {
    font-size: 12px;
    line-height: 16px;
  }
  .frequency-chip {
    font-size: 10px;
    border-radius: 1px;
    height: 24px
  }
  .frequency-chip {
    padding: 0px 7px;
  }
</style>
