<template>
  <PSideNav
    :sections="sections"
    @item-click="$emit('onSideNavItemClick', $event)"
    class="households-side-bar"
  />
</template>

<script>

export default {
  name: 'HouseholdSidebar',
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
  .households-side-bar {
    width: 100%;
  }
</style>
