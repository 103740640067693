<template>
  <v-text-field
    single-line
    outline
    solo
    flat
    type="number"
    :value="value"
    @input="$emit('input')"
    step="0.01"
    min="0.00"
    class="pas-percentage-input"
  >
  </v-text-field>
</template>

<script>
export default {

};
</script>

<style lang="scss">
  .pas-percentage-input {
    .v-input__slot {
    border-radius: 40px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 0px 30px !important;
    font-size: 14px !important;
    min-height: 40px !important;
    height: 40px !important;
    margin: 0px !important;
    &:active, &:focus, &:hover, &:focus-within {
        border-color: var(--primary-translucent-5) !important;
    }
    input {
        padding: 0px !important;
        margin: 0px !important;
    }
    &:before, &:after {
      display: none !important;
    }
  }
  .v-text-field {
    display: none;
  }
    min-height: 40px !important;
    height: 40px !important;
    &.v-input--is-focused:after {
      display: none !important;
    }
    &:before, &:after {
      display: none !important;
    }
  }
</style>
