<template>
  <PasList
    :columns="columns"
    :minHeight="minHeight"
    :itemName="'money movements'"
    :items="moneyMovements"
    :loading="loading"
    @toggleSort="toggleSort($event)"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height align-center>
        <v-flex xs2>
          <v-layout align-center>
            <router-link :to="`/clients/${item.client}`">
              <PasAvatar
                class="mr-4 ml-2"
                :avatar="getAvatar('clients', item.client)"
              />
            </router-link>
            <div class="strong-text">
              <router-link
                :to="`/clients/${item.client}`"
                :style="{ textTransform: 'capitalize' }"
                >{{
                  item.client_details ? clientFullName(item.client_details) : ''
                }}</router-link
              >
            </div>
          </v-layout>
        </v-flex>
        <v-flex xs2 light-text>{{
          moment(item.created_at).format('MMMM D, YYYY')
        }}</v-flex>
        <v-flex xs2 light-text>{{
          moment(item.start_date).format('MMMM D, YYYY')
        }}</v-flex>
        <v-flex xs1>
          <v-layout column>
            <v-flex xs12>{{ moneyMovementType(item.type) }}</v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs1>#{{ item.account_no_dataphile || '' }}</v-flex>
        <v-flex xs1 strong-text
          >${{ commaDecimalFormatted(item.amount) }}</v-flex
        >
        <v-flex xs1>{{ moneyMovementFrequency(item.frequency) }}</v-flex>
        <v-flex pl-3 xs1>{{ moneyMovementStatus(item.status) }}</v-flex>
        <v-flex xs1>
          <PasButton
            class="mr-4"
            :disabled="item.workflow_id == null || !canEditMoneyMovementConsolidatedView"
            @click="viewWorkflow(item.workflow_id)"
            >Open</PasButton
          >
        </v-flex>
      </v-layout>
    </template>
  </PasList>
</template>

<script>
import { commaDecimalFormatted } from 'Services/helpers/currency';
import { getAvatar } from 'Services/helpers/files';
import { mapGetters } from 'vuex';

export default {
  name: 'TransferList',
  props: {
    moneyMovements: Array,
    sortBy: String,
    sortDir: Number,
    loading: Boolean,
    minHeight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      getAvatar,
      commaDecimalFormatted,
      columns: [
        {
          header: 'Client Name',
          size: 'xs2',
          isSortable: true,
          sortValue: 1,
        },
        {
          header: 'Date Created',
          size: 'xs1',
          isSortable: true,
          sortValue: 1,
        },
        {
          header: 'Start Date',
          size: 'xs1',
          isSortable: true,
          sortValue: 1,
        },
        {
          header: 'Start Date',
          size: 'xs2',
          isSortable: true,
          sortValue: 1,
        },
        {
          header: 'Type',
          size: 'xs1',
          isSortable: true,
          sortValue: 1,
        },
        {
          header: 'Account #',
          size: 'xs1',
          isSortable: false,
        },

        {
          header: 'Amount',
          size: 'xs1',
          isSortable: true,
          sortValue: 1,
        },
        {
          header: 'Frequency',
          size: 'xs1',
          isSortable: false,
        },
        { header: 'Status', size: 'xs1', isSortable: false },
        { header: 'Workflow', size: 'xs1', isSortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'canViewMoneyMovementConsolidatedView',
      'canEditMoneyMovementConsolidatedView',
    ]),
  },
  methods: {
    toggleSort(event) {
      this.columns = this.columns.map((c) => {
        if (c.header === event.header) {
          c.sortValue = event.sortValue ? event.sortValue * -1 : 0;
        }
        return c;
      });
      const newSortColumn = this.columns.find((x) => x.header === event.header);
      if (!newSortColumn) return;
      this.$emit('toggleSort', {
        sortBy: newSortColumn.header,
        sortDir: newSortColumn.sortValue,
      });
    },
    clientFullName(clientDetails) {
      let fullName = '';
      if (clientDetails.first_name) {
        fullName += clientDetails.first_name;
      }
      if (clientDetails.last_name) {
        fullName += ` ${clientDetails.last_name}`;
      }
      return fullName;
    },
    moneyMovementStatus(statusCode) {
      let status = '';
      switch (statusCode) {
        case '0':
          // Created
          status = 'Created';
          break;
        case '1':
          // Processing
          status = 'Processing';
          break;
        case '2':
          // Waiting on first transaction
          status = 'Pending';
          break;
        case '3':
          // Active
          status = 'Active';
          break;
        case '4':
          // Error
          status = 'Error';
          break;
        case '5':
          // Cancelled
          status = 'Cancelled';
          break;
        default:
          break;
      }

      return status;
    },
    moneyMovementType(type) {
      let typeText = '';
      switch (type) {
        case '1':
          typeText = 'PAC';
          break;
        case '2':
          typeText = 'SWP';
          break;
        case '3':
          typeText = 'EFT-in';
          break;
        case '4':
          typeText = 'EFT-out';
          break;
        default:
          break;
      }
      return typeText;
    },
    moneyMovementFrequency(type) {
      let typeText = '';
      switch (type) {
        case 'W':
          typeText = 'Weekly';
          break;
        case 'B':
          typeText = 'Bi-Weekly';
          break;
        case 'N':
          typeText = 'Semi-Monthly';
          break;
        case 'M':
          typeText = 'Monthly';
          break;
        case 'Q':
          typeText = 'Quarterly';
          break;
        case 'S':
          typeText = 'Semi-Annually';
          break;
        case 'A':
          typeText = 'Annually';
          break;
        case 'O':
          typeText = 'One Time';
          break;
        default:
          break;
      }
      return typeText;
    },
    viewWorkflow(id) {
      this.$router.push(`/workflow/${id}`);
    },
  },
};
</script>
