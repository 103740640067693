import Store from 'Store/store';
import Households from 'Components/Vue-Routes/Householding';
import HouseholdProfile from 'Components/Vue-Routes/Householding/HouseholdProfile';
import IpsGroupPage from 'Components/Vue-Routes/Householding/IPS/IpsGroupPage';

const canAccessHouseholds = () => Store.getters.hasFeatureFlag('household') && Store.getters.canAccessHouseholds;
const canAccessIpsGroups = () => Store.getters.hasFeatureFlag('ips_groups') && Store.getters.canAccessIpsGroups;

export const householdRoutes = [
  {
    path: '/households',
    component: { render: (c) => c('router-view') },
    meta: {
      stylesheet: 'beta',
    },
    children: [
      {
        path: '/',
        component: Households,
        name: 'Households',
        meta: {
          canAccess: canAccessHouseholds,
          stylesheet: 'beta',
        },
      },
      {
        path: ':householdId',
        component: { render: (c) => c('router-view') },
        meta: {
          stylesheet: 'beta',
        },
        children: [
          {
            path: '/',
            component: HouseholdProfile,
            name: 'HouseholdProfile',
            meta: {
              canAccess: canAccessHouseholds,
              stylesheet: 'beta',
            },
          }, {
            path: 'ips_group/:ipsGroupGuid',
            component: IpsGroupPage,
            name: 'IpsGroupPage',
            meta: {
              canAccess: () => canAccessIpsGroups,
              stylesheet: 'beta',
            },
          },
        ],
      },
    ],
  },
];
