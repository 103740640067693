<template>
  <div>
    <v-text-field
      single-line
      outline
      solo flat
      :value="value"
      @input="$emit('input', $event ? $event.replace(/,/g, '') : $event)"
      class="input-field-currency input-field"
      prepend-inner-icon="mdi-currency-usd"
      v-money="money"
      :rules="rules"
      :disabled="disabled"
    ></v-text-field>
  </div>
</template>

<script>

import { VMoney } from 'v-money';

export default {
  name: 'pas-input-currency',
  directives: { money: VMoney },
  props: {
    value: [Number, String],
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      money: { // money formatting
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: true,
      },
    };
  },
};
</script>

<style lang="scss">

.input-field-currency {
  .v-input__prepend-inner {
    left: -11px;
    i {
      font-size: 16px !important;
    }
  }
  .v-text-field__slot {
    border-left: 1px solid #dddddd;
    left: -10px !important;
    padding-left: 20px !important;
  }
}

</style>
