<template>
  <v-card class="pa-4">
    <v-layout mb-4>
      <v-flex xs12 text-xs-center>
        <PasPageTitle>Sorry this link is invalid or has expired</PasPageTitle>
      </v-flex>
    </v-layout>
    <v-layout mb-4>
      <v-flex xs12 text-xs-center>
        <PasPageSubtitle>Please contact you Advisor if you have any questions</PasPageSubtitle>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>
