import Accordion from './Accordion';
import Avatar from './Avatar';
import CircularLoader from './CircularLoader';
import DisplayChip from './DisplayChip';
import ErrorDiv from './ErrorDiv';
import FullScreenModal from './FullscreenModal';
import LinearLoader from './LinearLoader';
import List from './List';
import NotificationDiv from './NotificationDiv';
import PageSubtitle from './PageSubtitle';
import PageTitle from './PageTitle';
import Pagination from './Pagination';
import Sections from './Sections';
import StatusBullet from './StatusBullet';
import Stepper from './Stepper';
import Tabs from './Tabs';
import Wizard from './Wizard';
import WizardStage from './WizardStage';

export default {
  install(Vue) {
    Vue.component('PasAccordion', Accordion);
    Vue.component('PasAvatar', Avatar);
    Vue.component('PasCircularLoader', CircularLoader);
    Vue.component('PasDisplayChip', DisplayChip);
    Vue.component('PasErrorDiv', ErrorDiv);
    Vue.component('PasFullscreenModal', FullScreenModal);
    Vue.component('PasLinearLoader', LinearLoader);
    Vue.component('PasList', List);
    Vue.component('PasNotificationDiv', NotificationDiv);
    Vue.component('PasPageSubtitle', PageSubtitle);
    Vue.component('PasPageTitle', PageTitle);
    Vue.component('PasPagination', Pagination);
    Vue.component('PasSections', Sections);
    Vue.component('PasStatusBullet', StatusBullet);
    Vue.component('PasStepper', Stepper);
    Vue.component('PasTabs', Tabs);
    Vue.component('PasWizard', Wizard);
    Vue.component('PasWizardStage', WizardStage);
  },
};
