<template>
  <div>
    <PasPageSubtitle class="mt-4">{{activeClient.client.display_name}}'s Reporting Group</PasPageSubtitle>
    <template v-if="!reportingGroup.primary">
      <div class="mt-2 mb-4">
        <span>{{activeClient.client.display_name}} is currently not part of a reporting group.</span>
        <span v-if="canDeleteReportingGroups">
          You can add {{activeClient.client.display_name}} to a reporting group by clicking
          <span class="action-link" @click="createReportingGroup">here</span>.
        </span>
      </div>
    </template>
    <template v-else>
      <v-layout column mt-2>
        <v-flex mb-3>
          <span class="strong-text">Name:  </span>
            <PasEditableSpan
              :value="reportingGroup.primary.name"
              :editableSpanClass="'solid-white-text'"
              :rules="[v.isRequired()]"
              :style="{ display: 'inline' }"
              :disabled="!canDeleteReportingGroups"
              @editSaved="update($event)"
            />
        </v-flex>
      </v-layout>

      <div class="strong-text">Membership:</div>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-actions
        :style="{ width: '650px' }"
      >
        <template v-slot:items="props">
          <td>
            <router-link  class="action-link" :to="`/clients/${props.item.client}`">
              {{props.item.name}}
            </router-link>
          </td>
          <td>
            {{props.item.clientType !== 'person' ? 'N/A' : birthdays[props.item.client] ? birthdays[props.item.client] : '---'}}
          </td>
          <td>{{props.item.status}}</td>
          <td class="text-xs-center">
            <v-icon v-if="props.item.isPrimary">check</v-icon>
          </td>
          <td class="text-xs-center">
            <PasCheckbox
              :style="{ margin: 'auto !important', height: '20px' }"
              :value="props.item.sendReport"
              :disabled="props.item.isPrimary || !canDeleteReportingGroups"
              @change="updateSentReport(props.item.id, !props.item.sendReport)"
            />
          </td>
          <td>
            <v-icon
              :style="{ fontSize: '20px' }"
              v-if="!props.item.isPrimary && canDeleteReportingGroups"
              @click="leaveGroup(props.item.id)"
            >delete</v-icon>
          </td>
        </template>
      </v-data-table>
      <PasAddItem
        v-if="!dropdown && canDeleteReportingGroups"
        :text="'Add a member'"
        @click="dropdown = true"
        class="pt-2"
      />
      <v-layout my-3 v-if="dropdown">
        <v-flex shrink mr-3>
          <PasAutocomplete
            :items="clients"
            v-model="selectedClient"
            :prependInnerIcon="`search`"
            :autofocus="true"
            :noDataText="`No client relationships`"
            :smallChips="true"
            :style="{ width: '300px' }"
          />
        </v-flex>
        <v-flex>
          <PasButton @click="add">Add</PasButton>
        </v-flex>
      </v-layout>
    </template>

    <div class="my-4" v-if="otherReportingGroups.length">
      <PasPageSubtitle class="mt-4 mb-2">{{activeClient.client.display_name}} is also a member in</PasPageSubtitle>
      <v-data-table
        :headers="[{ text: 'Reporting groups', align: 'left', sortable: false }]"
        :items="otherReportingGroups"
        hide-actions
        :style="{ width: '200px' }"
      >
        <template v-slot:items="props">
          <td>
            <router-link  class="action-link" :to="`/clients/${client}`">
              {{clientList[props.item].display_name}}
            </router-link>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

import v from 'Services/validation';
import {
  updateReportingGroup, joinReportingGroup,
  updateСlientReportingGroup, leaveReportingGroup,
  createReportingGroup,
} from 'Services/api/ClientGroupings';
import { mapGetters } from 'vuex';
import { fetchPerson } from 'Services/api/Clients';
import _ from 'lodash';
import { modal } from 'Services/mixins';

export default {
  name: 'ReportingGroupTab',
  mixins: [modal],
  data() {
    return {
      v,
      dropdown: false,
      selectedClient: undefined,
      headers: [
        {
          text: 'Client Name',
          align: 'left',
          sortable: false,
          width: 300,
        },
        {
          text: 'Birthday',
          sortable: false,
          width: 200,
        },
        {
          text: 'Status',
          sortable: false,
        },
        {
          text: 'Primary',
          sortable: false,
        },
        {
          text: 'Send Reports',
          sortable: false,
        },
        {
          text: '',
          sortable: false,
        },
      ],
      birthdays: {},
    };
  },
  mounted() {
    this.fetchBirthdays();
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'clientId',
      'clientList',
      'canDeleteReportingGroups',
    ]),
    reportingGroup() {
      return this.activeClient.reportingGroup;
    },
    items() {
      if (!this.reportingGroup.primary || !this.reportingGroup.primary.clients) return [];
      const reportingGroup = this.reportingGroup.primary.clients.map((c) => ({
        name: this.clientList[c.client]?.display_name,
        status: this.clientList[c.client]?.client_status || '---',
        isPrimary: c.is_primary,
        sendReport: c.send_report,
        id: c.id,
        clientType: this.clientList[c.client]?.client_type,
        client: c.client,
      }));
      const primary = reportingGroup.filter((x) => x.isPrimary);
      const notPrimary = reportingGroup.filter((x) => !x.isPrimary);
      return primary.concat(notPrimary);
    },
    otherReportingGroups() {
      if (!this.reportingGroup.secondary) return [];
      return this.reportingGroup.secondary.map((c) => c.client);
    },
    clients() {
      if (this.$store.state.clients.list[0] === 'loading') return [];
      const currentMembers = this.items.map((item) => item.client);
      return this.$store.state.clients.list
        .filter((client) => !currentMembers.includes(client.id))
        .map((client) => {
          const { id, display_name } = client;
          return { text: display_name || '', value: id };
        });
    },
  },
  methods: {
    update(name) {
      updateReportingGroup({ id: this.reportingGroup.primary.id, name });
      this.$store.dispatch('setReportingGroup', this.clientId);
    },
    async updateSentReport(id, send_report) {
      await updateСlientReportingGroup({ id, send_report });
      this.$store.dispatch('setReportingGroup', this.clientId);
    },
    async leaveGroup(id) {
      await leaveReportingGroup(id);
      this.$store.dispatch('setReportingGroup', this.clientId);
    },
    async add() {
      try {
        this.dropdown = false;
        if (!this.selectedClient) return;
        const clientReportingGroup = await joinReportingGroup({
          client: this.selectedClient,
          reporting_group: this.activeClient.reportingGroup.primary.id,
          is_primary: false,
          send_report: false,
        });
        if (!clientReportingGroup.id) throw Error;
        this.$store.dispatch('setReportingGroup', this.clientId);
        this.selectedClient = undefined;
      } catch (err) {
        this.snackBar('fail', 'Failed to create a Fee Group');
      }
    },
    async createReportingGroup() {
      try {
        const name = this.activeClient.person.last_name || `${this.activeClient.client.display_name}'s Reporting Group`;
        const reportingGroup = await createReportingGroup({ name });
        if (!reportingGroup.id) throw Error;
        const clientReportingGroup = await joinReportingGroup({
          client: this.clientId,
          reporting_group: reportingGroup.id,
          is_primary: true,
          send_report: true,
        });
        if (!clientReportingGroup.id) throw Error;
        this.$store.dispatch('setReportingGroup', this.clientId);
      } catch (err) {
        this.snackBar('fail', 'Failed to create a Fee Group');
      }
    },
    fetchBirthdays() {
      Promise.all(this.items.filter((x) => x.clientType === 'person').map((x) => fetchPerson(x.client)))
        .then((responses) => {
          const birthdays = {};
          responses.forEach((res) => birthdays[res.id] = res.date_of_birth || '---');
          this.birthdays = birthdays;
        })
        .catch((err) => err);
    },
  },
  watch: {
    items() {
      this.fetchBirthdays();
    },
  },
};
</script>
