<template>
  <div>
    <pas-accordion>
      <template v-slot:panelTitle>Financial Details</template>
      <template v-slot:panelContent>
        <financial-details-modal :editable="editable" class="pt-4" noTitle></financial-details-modal>
      </template>
    </pas-accordion>
    <pas-accordion v-if="clientType === 'person'">
      <template v-slot:panelTitle>Source of Funds</template>
      <template v-slot:panelContent>
        <v-layout wrap class="pt-4">
          <v-flex
            v-for="(item, i) in fundSources" :key="`fundsources-${i}`"
            xs6 md4 px-2
            class="funds-source-field"
          >
            <PasCheckbox
              :color="primaryColor"
              @change="$set(fundSources, i, {...item, value: $event})"
              :value="item.value"
              :label="item.name"
              :disabled="!editable"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <pas-button
            @click="saveFundSources"
            :processing="saving"
            v-if="editable"
            class="wide-btn"
          >
            Save
          </pas-button>
        </v-layout>
      </template>
    </pas-accordion>
    <pas-accordion>
      <template v-slot:panelTitle>Shareholder Preference</template>
      <template v-slot:panelContent>
        <shareholder-pref-modal :editable="editable" class="pt-4" noTitle></shareholder-pref-modal>
      </template>
    </pas-accordion>
    <pas-accordion v-if="displayFatca && clientType=='person'">
      <template v-slot:panelTitle>FATCA/QI/CRS</template>
      <template v-slot:panelContent>
        <FatcaQiCrsModal :editable="editable" class="pt-4" noTitle></FatcaQiCrsModal>
      </template>
    </pas-accordion>

  </div>
</template>

<script>

import AddInvestmentAccounts from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddInvestmentAccounts';
import v from 'Services/validation';
import FinancialDetailsModal from 'Components/Shared/FinancialDetailsModal';
import ShareholderPrefModal from 'Components/Shared/ShareholderPrefModal';
import FatcaQiCrsModal from 'Components/Shared/FatcaQiCrsModal';
import { mapGetters } from 'vuex';

export default {
  name: 'financial-profile-tab',
  components: {
    AddInvestmentAccounts,
    FinancialDetailsModal,
    ShareholderPrefModal,
    FatcaQiCrsModal,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fundSources: {
        investments: { name: 'Investment income', value: false },
        business: { name: 'Client-owned business', value: false },
        legal_settlement: { name: 'Legal or insurance claim', value: false },
        salary: { name: 'Salary', value: false },
        savings: { name: 'Savings', value: false },
        gift_inheritance: { name: 'Gift or inheritance', value: false },
        asset_sale: { name: 'A large sale', value: false },
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      v,
      investmentAccountDialog: false,
      bankAccountDialog: false,
      saving: false,
      isEdited: false,
      isInit: true,
    };
  },
  computed: {
    ...mapGetters([
      'firm',
      'hasFeatureFlag',
      'hasSubmittedKycChangeRequest',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    sourcesOfFunds() {
      return !!this.activeClient.fundsSources.id;
    },
    clientType() {
      return this.activeClient.client.client_type;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    firm() {
      return this.$store.getters.firm;
    },
    displayFatca() {
      return this.hasFeatureFlag('display_tax_treaty');
    },
    kycFeatureFlag() {
      return this.hasFeatureFlag('client_maintenance') || this.hasFeatureFlag('annual_kyc');
    },
  },
  methods: {
    async saveFundSources() {
      if (this.kycFeatureFlag && this.hasSubmittedKycChangeRequest) {
        this.$store.commit('OPEN_MODAL', 'cannot-edit-profile');
        return;
      }
      this.saving = true;
      const { id } = this.activeClient.fundsSources;
      const data = {};
      Object.keys(this.fundSources).forEach((x) => {
        data[x] = this.fundSources[x].value;
      });
      if (!id) {
        data.client = this.activeClient.client.id;
        await this.$store.dispatch('createFundsSources', data)
          .then((res) => {
            if (res.code) this.handleResponse('fail');
            else this.handleResponse('success');
          })
          .catch((err) => {
            console.log('error in funds source update', err);
            this.handleResponse('fail');
          });
      } else {
        await this.$store.dispatch('updateFundsSources', { id, data })
          .then((res) => {
            if (res.code) this.handleResponse('fail');
            else this.handleResponse('success');
          })
          .catch((err) => {
            console.log('error in funds source update', err);
            this.handleResponse('fail');
          });
      }
      this.saving = false;
    },
    openModal(modalType) {
      if (modalType === 'newBankAccount') {
        this.$store.commit('OPEN_MODAL', 'add-bank-account');
        return;
      }
      if (modalType === 'newInvestmentAccount') {
        this.$store.commit('OPEN_MODAL', 'add-investment-accounts');
      }
    },
    handleResponse(type) {
      const text = `${type === 'fail' ? 'Failed to update' : 'Successfully updated'} Financial Profile`;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
  },
  watch: {
    activeClient: {
      handler(val, oldVal) {
        let client = {};
        if (!this.isEdited) {
          client = { ...this.activeClient };
          if (client.fundsSources) {
            this.fundSources.investments.value = client.fundsSources.investments;
            this.fundSources.business.value = client.fundsSources.business;
            this.fundSources.legal_settlement.value = client.fundsSources.legal_settlement;
            this.fundSources.salary.value = client.fundsSources.salary;
            this.fundSources.savings.value = client.fundsSources.savings;
            this.fundSources.gift_inheritance.value = client.fundsSources.gift_inheritance;
            this.fundSources.asset_sale.value = client.fundsSources.asset_sale;
          }
        }
      },
      deep: true,
    },
    overview: {
      deep: true,
      handler() {
        for (const item in this.overview) {
          if (this.overview[item].name != 'Income Source' && this.overview[item].value < 0) this.overview[item].value = this.overview[item].value * (-1);
        }
      },
    },
    fundSources: {
      deep: true,
      handler(val) {
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
    },
  },
  mounted() {
    this.isEdited = false;
    this.isInit = true;
    const client = { ...this.activeClient };
    if (client.fundsSources) {
      this.fundSources.investments.value = client.fundsSources.investments;
      this.fundSources.business.value = client.fundsSources.business;
      this.fundSources.legal_settlement.value = client.fundsSources.legal_settlement;
      this.fundSources.salary.value = client.fundsSources.salary;
      this.fundSources.savings.value = client.fundsSources.savings;
      this.fundSources.gift_inheritance.value = client.fundsSources.gift_inheritance;
      this.fundSources.asset_sale.value = client.fundsSources.asset_sale;
    }
  },
};
</script>

<style lang="scss">
.checkbox-left{
  width: 30px;
}
.v-input.checkbox-left.v-input--selection-controls.v-input--checkbox.v-input--is-disabled.theme--light {
  margin-top: 0px !important;
}
.greyed-out{
  color: #757575;
}
.funds-source-field {
  white-space: nowrap;
}
.manual-checkbox-label {
  margin-bottom: 7px !important;
}
.client-edit-section {
  margin-top: 40px !important;
}
.financial-profile-pref-lang {
  margin-top: 0px !important;
}
</style>
