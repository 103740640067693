<template>
  <div>
    <FeeScheduleList
      class="my-4"
      :planningSchedules="planningSchedulesAdjusted"
      :managementSchedules="managementSchedulesAdjusted"
      :subadvisorSchedules="subadvisorSchedulesAdjusted"
      :investmentAccounts="investmentAccountsAdjusted"
      @change="changeSchedule($event)"
      @changeAccount="changeAccountFeesConfig($event)"
      @clearEndDate="clearAccountEndDate($event)"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  fetchDebitAccounts, updateAccountFeesConfig, fetchPlanningSchedules, fetchManagementSchedules, fetchSubadvisorSchedules,
} from 'Services/api/FeeManagement';
import FeeScheduleList from './AccountFeeSchedules/FeeScheduleList';


export default {
  name: 'AccountFeeSchedules',
  components: {
    FeeScheduleList,
  },
  data() {
    return {
      debitAccounts: [],
      planningSchedules: [],
      managementSchedules: [],
      subadvisorSchedules: [],
    };
  },
  async mounted() {
    this.debitAccounts = await fetchDebitAccounts(this.clientId);
    this.planningSchedules = await fetchPlanningSchedules();
    this.managementSchedules = await fetchManagementSchedules();
    this.subadvisorSchedules = await fetchSubadvisorSchedules();
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'clientId',
      'canEditAccountFeesConfig',
      'canDeletePlanningSchedules',
      'canDeleteManagementSchedules',
      'canDeleteSubadvisorSchedules',
    ]),
    investmentAccountsAdjusted() {
      return this.investmentAccounts.map(acc => ({ ...acc, debitAccounts: this.debitAccounts[acc.id] }))
    },
    planningSchedulesAdjusted() {
      return this.planningSchedules.map((s) => ({
        text: s.name,
        value: s.id,
      }));
    },
    managementSchedulesAdjusted() {
      return this.managementSchedules.map((s) => ({
        text: s.name,
        value: s.id,
      }));
    },
    subadvisorSchedulesAdjusted() {
      return this.subadvisorSchedules.map((s) => ({
        text: s.name,
        value: s.id,
      }));
    },
    investmentAccounts() {
      return this.$store.state.clients.activeClient.investment_accounts;
    },
  },
  methods: {
    changeSchedule(event) {
      const data = {
        id: event.accountId,
        primary_client_id: this.clientId,
      };
      data[event.type] = event.scheduleId;
      this.$store.dispatch('updateAccount', data)
        .then((res) => {
          if (res.id) this.$store.dispatch('getInvestmentAccounts', this.clientId);
          else throw Error;
        })
        .catch((err) => {
          const text = 'Failed to update schedule for the account';
          this.snackBar('fail', text);
        });
    },
    changeAccountFeesConfig(event) {
      const data = {
        id: event.accountId,
        [event.type]: event.data,
        client_id: this.activeClient.client.id,
      };
      updateAccountFeesConfig(data);
    },
    async clearAccountEndDate(event) {
      const data = {
        id: event.accountId,
        clear_end_date: true,
        client_id: this.activeClient.client.id,
      };
      await updateAccountFeesConfig(data);
      this.$store.dispatch('getInvestmentAccounts', this.clientId);
    },
  },
};
</script>
