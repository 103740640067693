<template>
  <v-card>
    <v-layout row align-center class="px-3 pt-3">
      <v-flex :style="{ width: '100%' }">
        <v-layout column>
          <v-flex
            :style="{display: 'flex'}"
            class="justify-space-between flex-row"
          >
            <pas-editable-span
              class="investment-accounts-editable"
              :value="account.nickname"
              :editable-span-class="'small-text p-text'"
              :maxlength="100"
              :rules="[
                v => (!!v || v === 0) || 'Field is required',
                v => /^(?!\s*$).+/.test(v) || 'Field is required'
              ]"
              :disabled="!editable"
              @editSaved="$emit('updateAccountName' , account.id, $event)"
              :editIconClass="'edit-icon-small'"
            />
            <v-icon
              v-if="account.account_status === 'T' && editable && canDeleteInvestmentAccounts"
              class="close-icon"
              @click="deleteDialogOpen = true"
            >
              mdi-close
            </v-icon>
          </v-flex>
          <v-flex pt-1>
            <span class="currency-span p-text">{{ account.currency }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-spacer/>
    </v-layout>
    <v-layout row wrap align-end class="px-3">
      <v-flex class="account-value">
        <span class="strong-number">{{ `$${account.value? Math.round(account.value).toLocaleString(): `0` }` }}</span>
      </v-flex>
      <v-spacer />
      <v-flex text-xs-right>
        <v-tooltip top>
          <pas-status-bullet
            v-if="statusMap[account.account_status]"
            slot="activator"
            class="ia-status-bullet"
            :color="statusMap[account.account_status].color"
            :value="statusMap[account.account_status].value"
          />
          <span v-if="account.account_status === 'O'">This account is open</span>
          <span v-if="account.account_status === 'T' || account.account_status === 'P'">This account is in the process of being opened</span>
          <span v-if="account.account_status === 'C'">This account has been closed{{ account.close_date ? ' on ' + account.close_date : '' }}</span>
        </v-tooltip>
        <span class="small-text dataphile-no p-text">
          {{ `${account.account_type_short_description} - ${account.account_no_dataphile}` }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-list class="systematics-list">
        <v-list-tile
          v-for="(systematic, i) in account.systematics"
          :key="`sys-${i}`"
        >
          <AccountSystematicInfo v-bind:systematic="systematic" />
        </v-list-tile>
      </v-list>
    </v-layout>

    <ConfirmActionDialog
      @close="deleteDialogOpen = false"
      @ok="handleDeleteAccount"
      :isOpen="deleteDialogOpen"
      :width="'450px'"
    >
      <template v-slot:header>Delete Investment Account</template>
      Are you sure you want to delete the {{ account.nickname }} account?
      <template v-slot:btn>OK</template>
    </ConfirmActionDialog>
  </v-card>
</template>

<script>
import ConfirmActionDialog from 'Components/Modals/ConfirmActionDialog';
import { mapGetters } from 'vuex';
import AccountSystematicInfo from './AccountSystematicInfo';

export default {
  name: 'InvestmentAccountCard',
  components: {
    AccountSystematicInfo,
    ConfirmActionDialog,
  },
  props: {
    account: Object,
    deleteAccount: { type: Function },
    updateAccountName: { type: Function },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deleteDialogOpen: false,
      statusMap: {
        T: {
          color: 'yellow',
          value: 100,
        },
        P: {
          color: 'yellow',
          value: 100,
        },
        O: { // open
          color: 'green',
          value: 100,
        },
        C: {
          color: 'red',
          value: 100,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'canDeleteInvestmentAccounts',
    ]),
  },
  methods: {
    handleDeleteAccount() {
      this.deleteDialogOpen = false;
      if (this.account.account_status === 'T') {
        this.$emit('deleteAccount', this.account.id);
      }
    },
  },

};
</script>

<style scoped>
  .close-icon {
    font-size: 15px;
    cursor: pointer;
  }
  .account-value {
    margin-right: 18px;
  }
  .ia-status-bullet {
    position: relative;
    left: -1px;
    top: -5px;
  }
  .dataphile-no {
    position: relative;
    top: -5px;
  }
  .systematics-list {
    width: 100%;
    box-shadow: none;
  }
  .systematics-list > div[role="listitem"] {
    padding-top: 12px;
    padding-bottom: 6px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  .systematics-list > div[role="listitem"]:nth-last-child(1) {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .currency-span {
    font-size: 16px;
  }
  .investment-accounts-editable {
    margin-bottom: 22px;
    max-width: 100%;
  }

  .investment-accounts-editable {
    margin-top: 0px;
  }
</style>
