<template>
  <auth-page>

    <!-- SSO login layout -->
    <template v-if="$auth.isFirmSSOActivated(this.firm)">
      <v-layout column align-center justify-center>
        <v-flex>
          <pas-page-title>Welcome</pas-page-title>
        </v-flex>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="login"
          id="login-form"
        >
        </v-form>
        <v-flex :style="{height: '30px'}" mt-4>
          <pas-error-div :show="errorText!==''" :style="{display: 'inline'}">{{errorText}}</pas-error-div>
        </v-flex>
        <v-flex mt-1>
          <v-layout>
            <v-flex shrink>
              <pas-button class="px-5" type="submit" form="login-form" :processing="loginProcessing">
                Sign in
              </pas-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>


    <!-- Regular username/password login layout -->
    <template v-if="!$auth.isFirmSSOActivated(this.firm)">
      <v-layout column>
        <v-flex>
          <pas-page-title>Welcome</pas-page-title>
        </v-flex>
        <v-flex>
          <div class="light-text">Sign in to your account.</div>
        </v-flex>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="login"
          id="login-form"
        >
        <v-flex mt-4>
          <v-layout column>
            <div class="strong-text form-label">Email Address</div>
            <pas-input-field
              :placeholder="`Email`"
              v-model="email"
              type="email"
              :rules="[v.isRequired(), v.isValidEmail]"
            ></pas-input-field>
          </v-layout>
        </v-flex>
        <v-flex mt-4>
          <v-layout column>
            <v-flex>
              <v-layout row child-flex>
                <div class="strong-text form-label">Password</div>
                <v-spacer></v-spacer>
                <router-link to="/forgot" class="action-text text-xs-right font-12">Forgot Password</router-link>
              </v-layout>
            </v-flex>
            <pas-input-field
              :placeholder="`Password`"
              :type="show ? 'text' : 'password'"
              v-model="password"
              :rules="[v.isRequired()]"
              :append-icon="`mdi-eye-outline`"
              @appendClick="show = !show"
            ></pas-input-field>
          </v-layout>
        </v-flex>
        </v-form>
        <v-flex :style="{height: '30px'}" mt-4>
          <pas-error-div :show="errorText!==''" :style="{display: 'inline'}">{{errorText}}</pas-error-div>
        </v-flex>
        <v-flex mt-1>
          <v-layout>
            <v-flex shrink>
              <pas-button class="px-5" type="submit" form="login-form" :processing="loginProcessing">
                Sign in
              </pas-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>

  </auth-page>
</template>

<script>
import apiv2 from 'Services/apiV2';
import AuthPage from 'Components/Vue-Routes/Auth/AuthPage';
import { ENVIRONMENTS } from 'Services/constants';
import v from 'Services/validation';


export default {
  name: 'login',
  components: {
    AuthPage,
  },
  data() {
    return {
      v,
      email: '',
      password: '',
      loginProcessing: false,
      errorText: '',
      show: false,
    };
  },
  methods: {
    async login() {
      this.errorText = '';
      if (!this.$refs.form.validate()) return;

      if (this.$auth.isFirmSSOActivated(this.firm)) {
        /////////////////////////////////
        // SSO login
        /////////////////////////////////
        if (this.$auth.loading != true) {
          this.loginProcessing = true;

          // Generate a code verifier that will be used to query the token
          const codeVerifier = this.$auth.createCodeVerifier();

          // Store it in the local storage (overwrite any if already present) as
          // it will be needed in the callback URL
          localStorage.setItem('code_verifier', codeVerifier);

          // for local development, we need to inject the 'firm' into the callback URL
          let callbackURL = window.location.origin + '/grant/';

          // Store the redirect URI as we need to generate a token
          localStorage.setItem('redirect_uri', callbackURL);

          // Find the organization ID for the firm
          let organizationId = undefined;
          try {
            const auth0OrganizationIds = localStorage.getItem('auth0OrganizationIds', '{}');
            organizationId = JSON.parse(auth0OrganizationIds)[this.firm];
          } catch(error) {
            this.errorText = 'Missing organization information. Please contact support.';
            this.loginProcessing = false;
          }

          if (organizationId) {
            try {
              let loginParams = {
                authorizationParams: {
                  redirect_uri: callbackURL,
                  code_verifier: codeVerifier,
                  organization: organizationId
                }
              }
              await this.$auth.loginWithRedirect(loginParams);

            } catch(error) {
              this.errorText = 'Login failed: ' + String(error);

            } finally {
              this.loginProcessing = false;
            }
          }
        }

      } else {

        /////////////////////////////////
        // Traditional login
        /////////////////////////////////
        try {
          this.loginProcessing = true;

          const res = await apiv2.post('/advisor_portal/api/login', {
            username: this.email,
            password: this.password,
            firm: this.firm },
            window.location.origin);

          if (res.token) {
            localStorage.setItem('jwt', res.token);
            await this.$store.dispatch('fetchUserInfoOnLogin');
            this.$router.push('/');
          } else {
            if (res.error === 'unapproved') this.errorText = 'Account pending approval';
            else if (res.error === 'suspended') this.errorText = 'Account suspended due to too many failed attempts.';
            else this.errorText = 'The username or password you entered is incorrect.';
          }

        } catch(error) {
          this.errorText = 'There was a problem logging in.';

        } finally {
          this.loginProcessing = false;
        }
      }
    },
  },
  computed: {
    passwordEmail() {
      return [this.password, this.email];
    },
    firm() {
      return this.$store.getters.firm;
    },
    env() {
      return this.$store.state.app.env;
    },
  },
  watch: {
    passwordEmail() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss">

.full-body-view-height {
  height: 100vh!important;
}
.white-background {
  background-color: #fff !important;
}
</style>
