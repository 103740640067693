<template>
  <v-card class="pa-4">
    <v-layout mb-4>
      <v-flex xs12 text-xs-center>
        <PasPageTitle
          v-if="approved"
        >Thank you for submitting this form</PasPageTitle>

        <PasPageTitle
          v-else
        >Thank you for letting us know that some of the information was incorrect.</PasPageTitle>
      </v-flex>
    </v-layout>
    <v-layout mb-4 v-if="!approved">
      <v-flex xs12 text-xs-center>
        <PasPageSubtitle>Your advisor or someone from our team will contact you to make any required changes</PasPageSubtitle>
      </v-flex>
    </v-layout>
    <p
      v-if="loadingSurvey && approved && redirectToSurvey"
    >Redirecting you to account opening questionnaires</p>
    <PasCircularLoader v-if="loadingSurvey && approved && redirectToSurvey"></PasCircularLoader>
    <p v-if="!loadingSurvey && approved && redirectToSurvey">
      If you are not redirected automatically,
      <a
        :href="surveyUrl"
      >click here to launch account opening questionnaires</a>
    </p>
  </v-card>
</template>

<script>
import { ENVIRONMENTS } from 'Services/constants';
import { mapGetters } from 'vuex';

export default {
  props: {
    redirectToSurvey: {
      type: Boolean,
      required: true,
      default: false,
    },
    approved: {
      type: Boolean,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    firmId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingSurvey: true,
    };
  },
  async created() {
    if (this.approved && this.redirectToSurvey) {
      const surveyUrl = await this.fetchSurveyUrl();
      this.loadingSurvey = false;
      if (surveyUrl) {
        window.location.href = surveyUrl;
      }
    }
  },
  computed: {
    ...mapGetters(['env']),
    surveyUrl() {
      let subDomain = 'dev.';
      if (ENVIRONMENTS.staging === this.env) {
        subDomain = 'staging.';
      } else if (ENVIRONMENTS.production === this.env) {
        subDomain = '';
      }
      return `https://${subDomain}discover.purposeadvisor.com/#/login?id=${this.clientId}&firm=${this.firmId}`;
    },
  },
  methods: {
    async fetchSurveyUrl() {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return this.surveyUrl;
    },
  },
};
</script>

<style>
</style>
