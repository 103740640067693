<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <div>
      <span>I confirm client has been documented</span>
      <br />
      <div>
        <span @click.prevent="markFatcaCrsCompleted" class="action-text">
          Mark as complete
        </span>
      </div>
    </div>
  </WorkflowStep>
</template>

<script>
import { sendFatcaCrsRemediationSuccess } from 'Services/api/Workflows';
import { mapGetters } from 'vuex';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'FatcaCrsRemediationStep',
  components: { WorkflowStep },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['activeClient']),
  },
  methods: {
    async markFatcaCrsCompleted() {
      try {
        const data = {
          is_fatca_crs_complete: 1,
        };
        this.$store.dispatch('updatePersonClient', {
          id: this.activeClient.client.id,
          data,
          refetch: false,
        }).then(async () => {
          const res = await sendFatcaCrsRemediationSuccess(this.workflow);
          if (res.status === 200) {
            this.$store.dispatch('setSnackbar', {
              text: 'Updating Workflow',
              type: 'success',
            });
            this.$store.dispatch('flipSnackbarSwitch');
            await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
          }
        });
      } catch (err) {
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>
</style>
