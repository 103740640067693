<!-- eslint-disable max-len -->
<template>
  <PasList
    :columns="columns"
    :items="documents"
    :itemName="'documents'"
    :loading="loading"
    @toggleSort="toggleSort($event)"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height align-center>
        <v-flex xs1>
          <v-layout justify-center>
            <img height="32px" width="32px" :src="getIcon(item)">
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <div class="p-text"> {{item.document_name}}</div>
        </v-flex>
        <v-flex xs3>
          <div class="p-text">{{documentCategory[item.document_type]}}</div>
        </v-flex>
        <v-flex xs2>
          <div class="p-text">{{documentSource[item.document_source]}}</div>
        </v-flex>
        <v-flex xs2>
          <div class="p-text"><span :class='`cStatus${getStatusId(item)}`'>{{getStatusDescription(item)}}</span></div>
        </v-flex>
        <v-flex xs2>
          <v-layout row align-center>
          <div class="p-text">{{moment(item.created_at).format('YYYY/MM/DD')}}</div>
          <v-spacer/>
          <pas-more-menu v-if="isFileSaved(item)"
            @click="$emit('documentAction',$event, item.id)"
            :editOptions="editOptions"
          />
          <pas-more-menu v-if="isFileInfected(item)"
            @click="$emit('documentAction',$event, item.id)"
            :editOptions="deleteInfected"
          />
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </PasList>
</template>

<script>

import { mapGetters } from 'vuex';
// import moment from 'moment';

import { DOCUMENT_SOURCE, DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE } from 'Services/constants';

import pdf from 'Assets/icons/pdf.svg';
import doc from 'Assets/icons/doc.svg';
import jpeg from 'Assets/icons/jpeg.svg';
import png from 'Assets/icons/png.svg';
import excel from 'Assets/icons/excel.svg';
import other from 'Assets/icons/other.svg';
import infected from 'Assets/icons/bug.jpg';

import { STATUS } from 'Constants/document_status';

export default {
  name: 'DocumentList',
  props: {
    documents: Array,
    sortDir: Number,
    loading: Boolean,
  },
  data() {
    return {
      columns: [
        {
          header: 'Title', size: 'xs5', isSortable: true, sortValue: 1,
        },
        {
          header: 'Category', size: 'xs3', isSortable: true, sortValue: 1,
        },
        {
          header: 'Upload Type', size: 'xs2', isSortable: true, sortValue: 1,
        },
        {
          header: 'Status', size: 'xs2', isSortable: true, sortValue: 1,
        },
        {
          header: 'Upload Date', size: 'xs2', isSortable: true, sortValue: 1,
        },
      ],
      pdf,
      doc,
      jpeg,
      png,
      excel,
      other,
      infected,
      documentCategory: DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE,
      documentSource: DOCUMENT_SOURCE,
    };
  },
  computed: {
    ...mapGetters([
      'canEditDocuments',
      'canDeleteDocuments',
    ]),
    deleteInfected() {
      if (this.canDeleteDocuments) {
        return [
          { title: 'Delete', id: 'delete', onClick: 'delete' },
        ];
      }

      return [];
    },
    editOptions() {
      if (this.canDeleteDocuments) {
        return [
          { title: 'Download', id: 'download', onClick: 'download' },
          { title: 'Send a copy', id: 'send-a-copy', onClick: 'send' },
          { title: 'Archive', id: 'archive', onClick: 'archive' },
          { title: 'Delete', id: 'delete', onClick: 'delete' },
        ];
      }

      if (this.canEditDocuments && !this.canDeleteDocuments) {
        return [
          { title: 'Download', id: 'download', onClick: 'download' },
          { title: 'Send a copy', id: 'send-a-copy', onClick: 'send' },
        ];
      }

      if (!this.canEditDocuments && !this.canDeleteDocuments) {
        return [{ title: 'Download', id: 'download', onClick: 'download' }];
      }

      return [];
    },
  },
  methods: {
    getIcon(item) {
      if (this.isFileInfected(item)) return this.infected;
      return [this.pdf, this.doc, this.jpeg, this.png, this.excel, this.other][item.file_type];
    },
    toggleSort(item) {
      this.columns = this.columns.map((c) => {
        if (c.header === item.header) {
          c.sortValue = item.sortValue ? item.sortValue * -1 : 0;
        }
        return c;
      });
      const newSort = this.columns.find((c) => c.header === item.header);
      if (!newSort) return;
      this.$emit('toggleSort', { sortBy: newSort.header, sortDir: newSort.sortValue });
    },
    getStatusId(item) {
      if (item.document_status == null) return 0;
      return item.document_status.status_id;
    },
    isFileInfected(item) {
      if (item.document_status == null) return false;
      if (item.document_status.status_id === STATUS.INFECTED) return true;
      return false;
    },
    isFileSaved(item) {
      if (item.document_status == null) return true;
      if (item.document_status.status_id === STATUS.SAVED) return true;
      return false;
    },
    getStatusDescription(item) {
      if (item.document_status == null) return '';
      return item.document_status.status_description;
    },
  },
};
</script>
<style scoped>
 .cStatus2{
    color:rgb(176,0,0);
    font-weight:bold;
 }
 .cStatus3, .cStatus4{
   color:rgb(0,0,0);
    font-weight:bold;
 }
</style>
