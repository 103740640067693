<template>
  <PasList
    :columns="[]" 
    :items="documents" 
    :itemName="'document templates'"
    :loading="loading"
    @toggleSort="toggleSort($event)"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height align-center md6>
        <v-flex xs2>
          <v-layout justify-center>
            <img height="32px" width="32px" :src="getIcon(item.file_type)"> 
          </v-layout>
        </v-flex>
        <v-flex xs8>
          <div class="p-text">{{item.document_name}}</div>
        </v-flex>
        <v-flex xs2>
          <v-layout fill-height row align-center justify-end>
            <PasFab>
            <v-icon @click="$emit('documentAction','download', item.id)">mdi-download</v-icon>
          </PasFab>
          </v-layout> 
        </v-flex>
      </v-layout>
    </template>
  </PasList>
</template>

<script>

import { mapState } from 'vuex';
import moment from 'moment';
import { DOCUMENT_SOURCE } from 'Services/constants';

import pdf from 'Assets/icons/pdf.svg';
import doc from 'Assets/icons/doc.svg';
import jpeg from 'Assets/icons/jpeg.svg';
import png from 'Assets/icons/png.svg';
import excel from 'Assets/icons/excel.svg';
import other from 'Assets/icons/other.svg';

export default {
  name: "DocumentList",
  props: {
    documents: Array,
    sortDir: Number,
    loading: Boolean
  },
  data() {
    return {
			columns:[],      
      editOptions: [
        { title: "Download", onClick: "download" },
        { title: "Send a copy", onClick: "send" },
        { title: "Delete", onClick: "delete" }
      ],
      pdf,
      doc,
      jpeg,
      png,
      excel,
      other,
    }
  },
  methods: {
    getIcon(fileType){
      return [this.pdf, this.doc, this.jpeg, this.png, this.excel, this.other][fileType];
    },
    toggleSort(item){
      this.columns = this.columns.map(c => {
        if (c.header === item.header) {
          c.sortValue = item.sortValue ? item.sortValue * -1 : 0;
        }
        return c;
      });
      const newSort = this.columns.find(c => c.header === item.header);
      if (!newSort) return;
      this.$emit('toggleSort', { sortBy: newSort.header, sortDir: newSort.sortValue });
    }
  }
};
</script>