import api from 'Services/apiV2';
import acApi from 'Services/api';


export const clientLogin = async (firmId) => acApi.post('/advisor_portal/api/loginCCT', {
  firm: firmId,
}).then(async (res) => {
  if (res.token) {
    localStorage.setItem('jwt', res.token);
  }
}).catch((err) => {
  throw new Error('Client Confirmation Tool: clientLogin call failed', err);
});

export const verifySessionToken = async (sessionToken) => {
  try {
    const response = await api.post(`/cct_sessions/${sessionToken}/verify_session_token/`);
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: verifySessionToken call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: verifySessionToken call failed', err);
  }
};


const generateTwilioToken = async (sessionToken, phoneNumber) => {
  try {
    const response = await acApi.post('/advisor_portal/twilio/', {
      session_token: sessionToken,
      phone_number: phoneNumber,
    });
    if (response.status && response.status === 'failure') {
      throw new Error('Client Confirmation Tool: generateTwilioToken call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: generateTwilioToken call failed', err);
  }
};

export const verifyPhoneNumber = async (sessionToken, phoneNumber, firmId) => {
  try {
    const response = await api.post(`/cct_sessions/${sessionToken}/verify_phone_number/`, {
      mobile_number: phoneNumber,
      firm_id: firmId,
    });
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: verifyPhoneNumber call failed', response.message);
    }
    const twilioRes = await generateTwilioToken(sessionToken, phoneNumber);
    return twilioRes;
  } catch (err) {
    throw new Error('Client Confirmation Tool: verifyPhoneNumber call failed', err);
  }
};

export const verifyTwilioToken = async (sessionToken, twilioToken) => {
  try {
    const response = await api.post(`/cct_sessions/${sessionToken}/verify_sms_code/`, {
      sms_code: twilioToken,
    });
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: verifyTwilioToken call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: verifyTwilioToken call failed', err);
  }
};

export const getFATCAQuestions = async () => {
  try {
    const response = await api.get('/fatca_questions/');
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: getFATCAQuestions call failed', response.message);
    }
    return response.results;
  } catch (err) {
    throw new Error('Client Confirmation Tool: getFATCAQuestions call failed', err);
  }
};

export const getCRSQuestions = async () => {
  try {
    const response = await api.get('/crs_questions/');
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: getCRSQuestions call failed', response.message);
    }
    return response.results;
  } catch (err) {
    throw new Error('Client Confirmation Tool: getCRSQuestions call failed', err);
  }
};

export const submitClientTaxAnswers = async (answers) => {
  await api.post('/client_tax_confirmation_answers/', answers);
};

export const getClientDetails = async (sessionToken) => {
  try {
    const response = await api.get(`/cct_client_details/?session_token=${sessionToken}`);
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: getClientDetails call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: getClientDetails call failed', err);
  }
};

export const getClientBankAccounts = async (sessionToken) => {
  try {
    const response = await api.get(`/cct_bank_accounts/?session_token=${sessionToken}`);
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: getClientBankAccounts call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: getClientBankAccounts call failed', err);
  }
};

export const getClientInvestmentAccounts = async (sessionToken) => {
  try {
    const response = await api.get(`/cct_investment_accounts/?session_token=${sessionToken}`);
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: getClientInvestmentAccounts call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: getClientInvestmentAccounts call failed', err);
  }
};

export const submitClientDecision = async (sessionToken, status) => {
  try {
    const response = await api.patch(`/cct_sessions/${sessionToken}/`, {
      approval_status: status,
    });
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: submitClientDecision call failed', err);
  }
};

export const isRiskAssesmentDone = async (clientId) => {
  try {
    const response = await api.get(`/is_risk_assessment_done/?client_id=${clientId}`);
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: isRiskAssesmentDone call failed', response.message);
    }
    return response;
  } catch (err) {
    throw new Error('Client Confirmation Tool: isRiskAssesmentDone call failed', err);
  }
};

export const getSessions = async (clientId) => {
  try {
    const response = await api.get(`/clients/${clientId}/cct_sessions`);
    if (response && response.code && response.code !== 200) {
      throw new Error('Client Confirmation Tool: getSessions call failed', response.message);
    }
    return {
      sessions: response.cct_sessions,
      program: response.program,
    };
  } catch (err) {
    throw new Error('Client Confirmation Tool: getSessions call failed', err);
  }
};
