import InputComponents from './input';
import ActionComponents from './action';
import DisplayComponents from './display';
import WorkflowsComponents from './Workflows';

export default {
  install(Vue) {
    Vue.use(InputComponents);
    Vue.use(ActionComponents);
    Vue.use(DisplayComponents);
    Vue.use(WorkflowsComponents);
  },
};
