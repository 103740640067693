/* eslint-disable max-len */
<template>
    <v-form
      ref="address"
      lazy-validation
      @submit.prevent
    >
      <pas-page-subtitle v-if="!noTitle" class="client-edit-section mb-1">Addresses</pas-page-subtitle>
      <address-list
        :addresses="addresses"
        @deleteAddress="deleteAddress($event)"
        @editAddress="editAddress($event)"
        @updateAddressType="updateAddressType"
        :clientType="clientType"
        :readonly="readonly"
        v-if="renderAddressList"
      ></address-list>
      <pas-add-item
        v-if="!readonly"
        :text="'Add Address'"
        @click="addAddress"
        class="my-2"
        id="add-address-btn"
      />
      <span class="error--text">{{validationText}}</span>
      <pas-button
        v-if="!modalMode && !readonly"
        class="primary-btn wide-btn"
        :processing="processingUpdate"
        @click="saveAddress"
      >Save</pas-button>
      <!-- :disabled="!changesMade"
      commenting this out for now because it it's inconsistent with the rest of section -->
      <v-dialog v-if="!readonly" v-model="updateAddressDialog" hide-overlay max-width="1000px">
        <v-card class="pa-5">
        <PasFab class="close-btn">
          <v-icon @click="updateAddressDialog = false" class="close-icon">close</v-icon>
        </PasFab>
        <add-edit-address
          v-if="!readonly"
          :address="addressToUpdate"
          @updateAddress="updateAddress($event)"
          @close="updateAddressDialog=false"
          :client="client"
        ></add-edit-address>
        </v-card>
      </v-dialog>
    </v-form>
</template>

<script>

import _ from 'lodash';
import AddressList from './Addresses/AddressList';
import AddEditAddress from './Addresses/AddEditAddress';

export default {
  name: 'addresses',
  components: {
    AddressList,
    AddEditAddress,
  },
  data() {
    return {
      addresses: [],
      addressToUpdate: {},
      updateAddressDialog: false,
      validationText: '',
      renderAddressList: true,
      disableSave: false,
      processingUpdate: false,
      isEdited: false,
      tempId: 0,
    };
  },
  props: {
    client: {
      type: Object,
      default() {
        return {};
      },
    },
    _addresses: {
      type: Array,
      default() { return []; },
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    changesMade() {
      return !(isArrayEqual(this._addresses, this.addresses.filter((a) => !a.deleted)));
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientType() {
      return this.activeClient.client ? this.activeClient.client.client_type : '';
    },
    kycFeatureFlag() {
      return this.$store.getters.hasFeatureFlag('client_maintenance') || this.$store.getters.hasFeatureFlag('annual_kyc');
    },
  },
  methods: {
    deleteAddress(addressID) {
      this.renderAddressList = false;
      for (let i = 0; i < this.addresses.length; i++) {
        if (this.addresses[i].id === addressID) {
          this.$set(this.addresses[i], 'deleted', true);
        }
      }
      this.$nextTick().then(() => {
        this.renderAddressList = true;
      });
    },
    editAddress(addressID) {
      this.updateAddressDialog = true;
      this.addressToUpdate = this.addresses.find((address) => address.id === addressID);
    },
    updateAddress(updatedAddress) {
      this.renderAddressList = false;
      this.updateAddressDialog = false;
      const newAddressCheck = [];
      for (let i = 0; i < this.addresses.length; i++) {
        if (updatedAddress.id && (this.addresses[i].id === updatedAddress.id)) {
          this.addresses.splice(i, 1, updatedAddress);
          newAddressCheck.push(updatedAddress.id);
        }
      }
      if (!newAddressCheck.length) {
        updatedAddress.id = `temp${this.tempId}`;
        this.tempId += 1;
        this.addresses.push(updatedAddress);
      }

      this.$nextTick().then(() => {
        this.renderAddressList = true;
      });
      this.updateAddressDialog = false;
    },
    addAddress() {
      this.addressToUpdate = {};
      this.updateAddressDialog = true;
    },
    updateAddressType(value, addressID, addressType) {
      this.addresses.forEach((address) => {
        value ? address[addressType] = (address.id === addressID)
          : address[addressType] = (address.id === addressID ? false : address[addressType]);
      });
    },
    handleResponses(type) {
      const text = type === 'fail' ? 'Failed to update addresses' : 'Successfully updated addresses';
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type === 'fail' ? 'fail' : 'success');
    },
    isAddressListValid() {
      this.validationText = '';
      const mailingAddress = this.addresses
        .filter((address) => address.is_mailing && !address.deleted);
      const residentialAddress = this.addresses
        .filter((address) => address.is_residential && !address.deleted);

      if (mailingAddress.length !== 1 || residentialAddress.length !== 1) {
        const addressType = this.clientType === 'person' ? 'residential' : 'business';
        this.validationText += `Please provide one mailing and one ${addressType} address. `;
      }

      const addressTypeCheck = residentialAddress.length && residentialAddress[0].client_address_type === 'E';

      if (addressTypeCheck && this.clientType === 'person') {
        this.validationText += "Please update the address type to 'Personal' if you wish to mark it as residential.";
      }

      if (this.validationText) return false;

      return true;
    },
    saveAddress() {
      if (this.kycFeatureFlag && this.$store.getters.hasSubmittedKycChangeRequest) {
        this.$store.commit('OPEN_MODAL', 'cannot-edit-profile');
        return;
      }
      if (this.isAddressListValid()) {
        for (let i = 0; i < this.addresses.length; i++) {
          this.addresses[i].back_office = this.addresses[i].is_residential || this.addresses[i].is_mailing;
          if (this.addresses[i].client_address_type === 'E') this.addresses[i].back_office = true;
        }

        const oldList = this._addresses.map((address) => address.id);
        const newList = this.addresses.map((address) => address.id);
        const addressesToDelete = this.addresses
          .filter((address) => address.deleted)
          .map((address) => address.id);
        const addressesToUpdate = oldList
          .filter((address) => newList.includes(address) && !addressesToDelete.includes(address));
        const addressesToAdd = newList
          .filter((address) => !oldList.includes(address));

        this.processingUpdate = true;

        Promise.all(
          this.addresses.map((address) => (addressesToUpdate.includes(address.id)
            ? this.$store.dispatch('updateAddress', { ...address, place_id: address.place_id || null })
            : addressesToAdd.includes(address.id)
              ? this.$store.dispatch('addAddress', address)
              : this.$store.dispatch('deleteAddress', address))),
        )
          .then((responses) => {
            const goodResponse = responses.filter((res) => res.id || res === true);
            this.handleResponses(goodResponse.length === this.addresses.length ? 'success' : 'fail');
            this.$store.dispatch('setAddresses');
            this.addresses = goodResponse.filter((res) => res.id);
            this.processingUpdate = false;
          })
          .catch((err) => {
            this.handleResponses('fail');
            this.processingUpdate = false;
          });
      }
    },
  },
  mounted() {
    this.isEdited = false;
    this.addresses = (this._addresses ? _.cloneDeep(this._addresses) : []);
  },
  watch: {
    client: {
      deep: true,
      handler(val) {
        if (!this.isEdited) {
          this.addresses = this._addresses ? _.cloneDeep(this._addresses) : [];
        }
      },
    },
    addresses: {
      deep: true,
      handler(val, oldVal) {
        if (oldVal.length !== 0) this.isEdited = true;

        if (this.modalMode) {
          const addresses = {
            active: this.addresses.filter((address) => !address.deleted),
            deleted: this.addresses.filter((address) => address.deleted),
          };

          this.$emit('addressesChanged', addresses);

          this.$emit('addressesValidated', this.isAddressListValid());
        }
      },
    },
  },
};

function isArrayEqual(x, y) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
}

</script>

<style lang="scss">

</style>
