<template>
  <div>
    <v-layout row wrap>
      <template
        v-for="(activity, index) in activities"
      >
        <workflow-view-feed-item
          :activity="activity"
          :key="`workflow-feed-item-${index}`"
        >
        </workflow-view-feed-item>
      </template>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash'
import WorkflowViewFeedItem from './WorkflowViewFeed/WorkflowViewFeedItem'


export default {
  name: 'workflow-view-feed',
  components: {
    WorkflowViewFeedItem
  },
  data() {
    return {}
  },
  props: {
    workflow: Object
  },
  computed: {
    activities() {
      let workflowCopy = _.cloneDeep(this.workflow)
      return workflowCopy.notes.sort((a, b) => {   //sort by most recent first
        return (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? 1 : -1)
      })
    }
  }
}
</script>

<style>

</style>
