<template>
  <v-btn
    @click="handleClick($event)"
    :class="`pas-btn ${theme}-btn`"
    depressed
    round
    :disabled="isDisabled"
  >
    <slot v-if="!processing"></slot>
    <v-progress-circular
      v-else
      :color="`#757575`"
      indeterminate
      :size="spinnerSize"
      :width="spinnerWidth"></v-progress-circular>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'pas-button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'primary', // primary, grey, inverted, action-card-inactive, disabled
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spinnerSize: 22,
      spinnerWidth: 2,
    };
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.app.themeVars.primaryColor,
    }),
    isDisabled() {
      return this.disabled || this.processing;
    },
  },
  methods: {
    handleClick(event) {
      if (!(this.isDisabled)) this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~Styles/variables';
.pas-btn{
  text-align: center !important;
  text-transform: none !important;
  box-shadow: var(--soft-box-shadow);
  line-height: 40px !important;
  height: 40px !important;
  margin: 0px;
  padding: 0px;
}
.pas-border {
  border: 1px solid var(--primary-color) !important;
}
</style>
