<template>
  <Consent>
    <SubAdvisorConsentForm @after-submit="afterSubmit"
      :subAdvisorPmData="subAdvisorPmData"
      @disagree="onDisagree"
    />
  </Consent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('consent');

export default {
  name: 'SubAdvisorConsentView',
  props: {
    activeClient: {
      type: Object,
      required: true,
    }
  },
  computed: {
    subAdvisorPmData () {
      return {
        subAdvisorName: this.activeClient?.suitabilityScore?.subadvisor_legal_name ? this.activeClient?.suitabilityScore?.subadvisor_legal_name : this.activeClient?.suitabilityScore?.subadvisor,
        subAdvisorID: this.activeClient?.suitabilityScore?.subadvisor_id,
        pmContactInfo: this.activeClient?.pmInfo?.email,
        shortName: this.activeClient?.suitabilityScore?.subadvisor_short_name,
        pmId: this.activeClient?.pmInfo?.id
      }
    },
    ...mapGetters([
      'subAdvisorConsentRequired'
    ]),
  },
  methods: {
    ...mapActions([
      'initUserConsent',
    ]),
    afterSubmit() {
      this.$emit('subAdvisorConsentCompleted');
    },
    onDisagree() {
      this.$router.push({ name: 'SubAdvisorConsentDisagree' })
    }
  },
  async mounted() {
    await this.initUserConsent();
    if (!this.subAdvisorConsentRequired) {
      this.$emit('subAdvisorConsentCompleted');
    }
  },
};
</script>
