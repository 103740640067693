<template>
  <div>
    <!-- Menu -->
    <v-speed-dial v-if="canAccessOperationalCapabilities" v-model="fab" :bottom="true" :right="true" direction="top"
      :open-on-hover="false" transition="slide-y-reverse-transition" :fixed="true" class="client-menu-speeddial">
      <v-btn slot="activator" v-model="fab" color="secondary" dark fab>
        <v-icon>menu</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn v-for="(item) in menuOptions" :key="item.text" :id="item.id" class="extended"
        @click="buttonAction(item.modal, item.route)">{{ item.text }}</v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from 'Services/api';
import { getMetadata } from 'Services/api/metadata';

export default {
  name: 'client-menu',
  data() {
    return {
      fab: false,
      bankAccount: false,
      corporateInvestmentAccount: false,
      investmentAccount: false,
      accountTransfer: false,
      fundTransfer: false,
      systematics: false,
      transferDocs: false,
      IPS: false,
      firm: null,
      menuOptions: [],
    };
  },
  mounted() {
    getMetadata('firm').then((res) => {
      this.firm = res;
      this.generateMenuOptions();
    });
  },
  computed: {
    ...mapGetters([
      'canEditOpenAccount',
      'canEditBankAccountSetup',
      'canEditTransferAccount',
      'canEditGenerateTransferDocuments',
      'canEditSetUpSystematic',
      'canEditTransferFunds',
      'canEditRiskProfile',
      'canViewRiskProfile',
      'canAccessOperationalCapabilities',
      'canEditGenerateIps',
      'hasFeatureFlag',
      'custodian',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientId() {
      return this.$store.state.clients.activeClient?.client?.id;
    },
    clientType() {
      return this.activeClient.client.client_type;
    },
    transfers() {
      return this.$store.state.clients.activeClient.transfers.filter(
        (transfer) => transfer.transfer_status === '0',
      );
    },
    isMoneyMovementFeature() {
      return this.hasFeatureFlag('money_movement');
    },
    internalTransfersFeatureFlag() {
      return this.hasFeatureFlag('internal_transfers');
    },
    corporateExternalTransfersFeatureFlag() {
      return this.hasFeatureFlag('corporate_external_transfers');
    },
    hasIps() {
      return this.hasFeatureFlag('ips');
    },
    isFundingV2Enabled() {
      return this.hasFeatureFlag('funding_v2');
    },
    portfolioAssignmentFeatureEnabled() {
      return this.$store.state.app.features.portfolio_assignment;
    },
    ipsFeatureEnabled() {
      return this.$store.state.app.features.ips;
    },
  },
  methods: {
    generateMenuOptions() {
      const options = [
        {
          text: 'Open Account',
          id: 'open-account',
          modal: 'investmentAccount',
          enabled: this.canEditOpenAccount && this.display('investmentAccount') && (this.clientType !== 'corporation' || this.custodian !== 'fcc'),
        },
        {
          text: 'Add Bank Account',
          id: 'add-bank-account',
          modal: 'bankAccount',
          enabled: this.canEditBankAccountSetup && this.display('bankAccount'),
        },
        {
          text: 'Transfer Account',
          id: 'transfer-account',
          modal: 'accountTransfer',
          enabled: this.canEditTransferAccount && this.display('accountTransfer') && this.custodian !== 'fcc',
        },
        {
          text: 'Transfers In',
          id: 'transfer-account',
          modal: 'accountTransfer',
          enabled: this.canEditTransferAccount && this.display('accountTransfer') && this.custodian === 'fcc' && (this.clientType === 'person') && !this.hasFeatureFlag('external_transfer_v2'),
        },
        {
          text: 'Generate Transfer Docs',
          id: 'generate-transfer-docs',
          modal: 'transferDocs',
          enabled: this.canEditGenerateTransferDocuments && this.display('transferDocs') && !this.hasFeatureFlag('external_transfer_v2'),
        },
        {
          text: 'Set Up Systematic',
          id: 'setup-systematic',
          modal: 'systematics',
          enabled: this.canEditSetUpSystematic && this.display('systematics') && this.custodian !== 'fcc',
        },
        {
          text: 'Deposits and Withdrawals',
          id: 'setup-systematic-fcc',
          modal: 'systematics-fcc',
          enabled: this.canEditSetUpSystematic && this.display('systematics-fcc') && this.custodian === 'fcc' && this.isMoneyMovementFeature && !this.isFundingV2Enabled,
        },
        {
          text: 'Transfer Funds',
          id: 'transfer-funds',
          modal: 'fundTransfer',
          getter: 'canEditTransferFunds',
          enabled: this.canEditTransferFunds && this.display('fundTransfer') && this.custodian !== 'fcc',
        },
        {
          text: 'Internal Transfers',
          id: 'transfer-funds',
          modal: 'internalTransfer',
          getter: 'canEditTransferFunds',
          enabled: this.canEditTransferFunds && this.display('fundTransfer') && this.custodian === 'fcc' && this.internalTransfersFeatureFlag,
        },
        {
          text: 'Generate IPS',
          id: 'generate-ips',
          modal: 'IPS',
          enabled: this.canEditGenerateIps && this.display('IPS') && !this.hasIps,
        },
        {
          // TODO: review permissions for fundingV2
          text: 'Funding',
          id: 'funding-v2',
          modal: 'fundingV2',
          route: `${this.activeClient.client.id}/funding-v2`,
          enabled: this.canEditSetUpSystematic && this.display('fundingV2') && this.isFundingV2Enabled,
        },
        {
          text: 'Risk Profile',
          id: 'risk-profile',
          route: `/clients/${this.clientId}/risk-profile`,
          enabled: (this.canViewRiskProfile || this.canEditRiskProfile) && (this.portfolioAssignmentFeatureEnabled || this.ipsFeatureEnabled),
        },
        {
          text: 'Fee Management',
          id: 'fee-management',
          route: `/clients/${this.clientId}/fee-management`,
          enabled: true,
        },
        {
          text: 'Shareholder Preferences',
          id: 'shareholder-preferences',
          route: `/clients/${this.clientId}/shareholder-preferences`,
          enabled: true,
        },
        {
          text: 'Generate KYC',
          id: 'generate-kyc',
          modal: 'kyc',
          enabled: this.display('kyc'),
        },
      ];
      this.menuOptions = options.filter(((o) => !!o.enabled));
    },
    snackBar(type, text) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    buttonAction(action, route) {
      if (action === 'kyc') {
        // Call the backend endpoint to generate the KYC form.
        // The form will be downloaded.

        let url = `${window.location.protocol}//${window.location.host}`;
        url += `/advisor_portal/generate_kyc_document?client_id=${this.activeClient.client.id}`;
        this.snackBar('success', 'Generating KYC form. This may take a few seconds. The file will be downloaded automatically when ready.');
        fetch(url, {
          method: 'GET',
          redirect: 'manual',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        }).then((res) => {
          if (res.status !== 200) throw Error;
          const filename = `KYC for ${this.activeClient.client.display_name}.pdf`;

          return res.blob().then((b) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(b);
            a.setAttribute('download', filename);
            a.click();
          });
        })
          .catch(() => {
            const text = 'Unable to generate KYC form';
            this.snackBar('fail', text);
          });

        return;
      }
      if (action === 'bankAccount') {
        this.$store.commit('OPEN_MODAL', 'add-bank-account');
        return;
      }
      if (action === 'transferDocs') {
        this.$store.commit('OPEN_MODAL', 'generate-account-transfer-docs');
        return;
      }
      if (action === 'systematics') {
        this.$store.commit('OPEN_MODAL', 'set-up-systematic');
        return;
      }
      if (action === 'systematics-fcc') {
        this.$store.commit('OPEN_MODAL', 'add-systematics-fcc');
        return;
      }
      if (action === 'fundTransfer') {
        this.$store.commit('OPEN_MODAL', 'add-fund-transfer');
        return;
      }
      if (action === 'accountTransfer') {
        this.$store.commit('OPEN_MODAL', 'add-account-transfer');
        return;
      }
      if (action === 'internalTransfer') {
        this.$store.commit('OPEN_MODAL', 'add-internal-transfer');
        return;
      }
      if (action === 'IPS') {
        this.$store.commit('OPEN_MODAL', 'generate-ips-docs');
        return;
      }
      if (action === 'investmentAccount') {
        if (this.clientType === 'person') {
          this.$store.commit('OPEN_MODAL', 'add-investment-accounts');
          return;
        }
        this.$store.commit('OPEN_MODAL', 'add-corporate-investment-accounts');
        return;
      }

      if (route) {
        this.$router.push(route);
      }
    },
    display(modal) {
      if (modal === 'kyc') {
        return this.firm === 'sam';
      }
      if (this.clientType === 'corporation') {
        if (modal === 'transferDocs') return !!this.transfers.length;
        return [
          'bankAccount',
          'investmentAccount',
          ...(this.custodian === 'fcc' && this.isMoneyMovementFeature ? ['systematics-fcc'] : ['systematics']),
          'fundTransfer',
          'accountTransfer',
          'fundingV2',
        ].includes(modal);
      }
      if (modal === 'transferDocs') return !!this.transfers.length;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.extended {
  width: 200px !important;
  height: 40px !important;
  padding: 0px 20px;
  margin-left: -10rem;
  border-radius: 25px;
}

.client-menu-speeddial {
  .v-btn.v-btn--floating.secondary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
}
</style>
