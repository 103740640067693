<template>

  <v-select
    class="drop-down text-xs-right"
    :value="value"
    @change="$emit('change', $event)"
    :items="items"
    menu-props="offsetY"
    :hide-selected="hideSelected"
    append-icon="mdi-chevron-down"
    :prepend-inner-icon="prependInnerIcon"
    :disabled="disabled"
    :rules="rules"
    :solo="solo"
    :placeholder="placeholder"
    :label="label"
    :hide-details="hideDetails"
    :return-object="returnObject"
  >
  </v-select>
</template>

<script>
export default {
  name: 'pas-drop-down',
  props: {
    items: Array,
    value: [String, Number],
    placeholder: {
      type: String,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
/*for workflows dropdown*/
  #wf-user-dd {
    font-size: 14px !important;
    margin-top: 20px;
  }
  .drop-down i {
    font-size: 15px !important;
  }
  /*.v-list__tile__title {
    font-size: 13px !important;
  }*/
  #wf-user-dd .v-select__selection {
    width: 80px !important;
    min-width: 80px;
  }
  .v-select__selection {
    font-size: 14px !important;
  }
  .v-menu__content {
		min-width: 60px !important;
  }
  .drop-down i {
    font-size: 15px !important;
  }
</style>
