<template>
  <div>
    <v-tooltip v-if="canShowClientMaintenanceLink" bottom>
      <template v-slot:activator="{ on }">
        <div class="d-inline-flex align-center mb-5">
          <v-btn
            class="mx-0 mr-4"
            color="tertiary"
            v-on="on"
            :to="editClientRoute(activeClient.client)"
          >
            <span>Client Maintenance</span>
            <v-icon right>auto_awesome</v-icon>
          </v-btn>
          <div v-if="formattedLastUpdatedDate">
            Last updated: {{formattedLastUpdatedDate}}
          </div>
        </div>
      </template>
      <span>Check out the new Client Maintenance page!</span>
    </v-tooltip>
    <PasAccordion>
      <template v-slot:panelTitle>General Information</template>
      <template v-slot:panelContent>
        <client-avatar :readonly="!areClientDetailsEditable" class="pt-4" :clientID="activeClient.client.id" />
        <EditDetailsModal :editable="areClientDetailsEditable" />
      </template>
    </PasAccordion>
    <PasAccordion>
      <template v-slot:panelTitle>Addresses</template>
      <template v-slot:panelContent>
        <addresses
          class="pt-4"
          :client="activeClient.client"
          :_addresses="activeClient.addresses"
          noTitle
          :readonly="!areClientDetailsEditable"
        />
      </template>
    </PasAccordion>
    <PasAccordion v-if="activeClient.client.client_type === `person`">
      <template v-slot:panelTitle>Employment Details</template>
      <template v-slot:panelContent>
        <employment class="pt-4" noTitle :readonly="!areClientDetailsEditable" />
      </template>
    </PasAccordion>
  </div>
</template>

<script>
import Addresses from 'Components/Shared/Addresses';
import EditDetailsModal from 'Components/Shared/EditDetailsModal';
import { editClientRoute } from 'Services/mixins';
import ClientAvatar from './ClientAvatar';
import Employment from './Employment';

export default {
  name: 'ClientDetailsTab',
  components: {
    Addresses,
    ClientAvatar,
    EditDetailsModal,
    Employment,
  },
  mixins: [editClientRoute],
  props: {
    formattedLastUpdatedDate: {
      type: Date,
      default: null,
    },
    activeClient: {
      type: Object,
      default: () => { },
    },
    areClientDetailsEditable: {
      type: Boolean,
      default: false,
    },
    canShowClientMaintenanceLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
