<template>
  <v-card flat :class="{'pa-3': true, 'action-card': true, 'action-card-disabled': disabled, 'no-button-action-card': !useButton}"
    @mouseover="!disabled ? buttonTheme='primary' : buttonTheme='pas-border'"
    @mouseleave="buttonTheme='pas-border'"
    @click="!useButton && $emit('click')"
    >
    <v-layout row wrap>
      <v-flex xs12 mt-2>
        <v-img
          :src="imagePath"
        >
        </v-img>
      </v-flex>
      <v-flex xs12 text-xs-center mt-4>
        <span class="action-text strong-text">{{action}}</span>
      </v-flex>
      <v-flex xs12 text-xs-center mt-3>
        <span>
          <slot></slot>
        </span>
      </v-flex>
      <v-flex xs12 text-xs-center mt-3 v-if="useButton">
        <pas-button
          :theme="buttonTheme"
          class="px-5 pas-border"
          @click="$emit('click')"
          v-bind="$attrs"
        >
          {{action}}
        </pas-button>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>

export default {
  name: 'action-card',
  data() {
    return {
      buttonTheme: 'pas-border',
    };
  },
  inheritAttrs: false,
  props: {
    action: String,
    imagePath: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    useButton: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';

  .v-card.action-card {
    background-color: transparent !important;
    box-shadow: none !important;
    &:not(.action-card-disabled):hover {
      background-color: white !important;
      box-shadow: $medium-box-shadow !important;
    }
    &.no-button-action-card {
      cursor: pointer;
    }
    border: 1px solid rgb(235, 236, 237) !important;
    border-radius: 15px;

  }
  .action-card-disabled {
    opacity: 0.2 !important;
  }
  .action-card-selected.action-card.v-card {
    background-color: white !important;
    box-shadow: $medium-box-shadow !important;
  }
</style>
