<template>
  <div>
    <div v-infinite-scroll="loadMoreTransactions" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
      <TransactionList
        @click="selectTransaction"
        :allTransactions="allTransactions"
      />
      <TransactionModal
        :transaction="selectedTransaction"
        :visible="isTransactionOpen"
        @closeModal="isTransactionOpen = false;"
      />
      <TransactionNoResults
        v-if="allTransactions && allTransactions.length < 1"
        @click="clearFilters"
      />
      <!-- <PToast
        :visible="showToast"
        toaster="b-toaster-bottom-left"
      >
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">All funds are in CAD and in net amounts</p>
          <PButton size="sm" @click="showToast = !showToast;">Close</PButton>
        </div>
      </PToast> -->
    </div>
  </div>
</template>

<script>
import TransactionModal from './TransactionModal/TransactionModal';
import TransactionNoResults from './TransactionNoResults/TransactionNoResults';
import TransactionList from './TransactionList/TransactionList';
import TransactionsFilters from './TransactionFilters/TransactionFilters';

export default {
  name: 'FundingTransactions',
  components: {
    TransactionModal,
    TransactionNoResults,
    TransactionList,
    TransactionsFilters,
  },
  props: {
    allTransactions: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.showToast = true;
  },
  data() {
    return {
      isLoading: true,
      isTransactionOpen: false,
      showToast: false,
      selectedTransaction: {},
    };
  },
  methods: {
    selectTransaction(transaction) {
      this.selectedTransaction = transaction;
      this.isTransactionOpen = true;
    },
    clearFilters() {
      this.$emit('update:accountType', '');
      this.$emit('update:requestType', '');
      this.$emit('update:status', '');
    },
    loadMoreTransactions() {
      this.$emit('loadMoreTransactions');
    },
  },
};
</script>
