<template>
  <v-chip
    :color="color"
    :text-color="textColor"
    disabled
    :small="small"
  >
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  name: 'pas-display-chip',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: 'rgba(0,0,0,0.4)',
    },
    color: {
      type: String,
      default: '#f5f5f5',
    },
  },
};
</script>

<style lang="scss">

</style>
