import api from 'Services/api';
import Store from 'Store/store';
import { WORKFLOW } from 'Services/constants';
import { fetchInProgressWorkflowsForClient, fetchWorkflowClients } from 'Services/api/Workflows'

export async function getJointClient(clientId) {
  const res = await fetchInProgressWorkflowsForClient(25, [clientId], 'client_onboarding_fcc_ip');

  if (res.count === 0) return null;

  const workflowId = res.results[0].workflow.id;
  const newWorkflowClients = await fetchWorkflowClients(workflowId);
  const newWorkflowClientsElements = newWorkflowClients.new_workflow_clients;
  const newWorkflowsClientElement = newWorkflowClientsElements.find((element) => element.client.id !== clientId);
  if (newWorkflowsClientElement) {
    return newWorkflowsClientElement.client;
  }

  return null;
}

export const getSurveyEngineURL = () => {
  const { env } = Store.state.app;
  const { firm } = Store.getters;
  const clientId = Store.state.clients.activeClient.client.id;
  let prepend = `${env}.`;
  if (env === 'production') prepend = '';
  if (env === 'local') prepend = 'dev.';
  return `https://${(prepend)}discover.purposeadvisor.com/#/login?id=${clientId}&firm=${firm}`;
};

export /* Building for corporate client onboarding now, add regular client onboarding later */
const launchOnboarding = async (accounts, client, workflowType, method, workflowId = null) => new Promise(async (resolve, reject) => {
  // TODO: DELETE THIS FUNCTION ONCE NEW_WORKFLOWS IS OUT
  try {
    if (workflowType === WORKFLOW.TYPES.client_onboarding) { // don't need to update anything for supplemental corporate onboarding
      workflowId = await updateWorkflow(accounts, client);
    }
    const surveyLaunchSuccess = await launchSurveyEngine(workflowType, accounts, client.id, workflowId, method);
  } catch (err) {
    console.log('error in launch onboarding helper', err);
    reject(err);
  }
  resolve();
});


async function updateWorkflow(accounts, client) {
  // TODO: DELETE THIS FUNCTION ONCE NEW_WORKFLOWS IS OUT
  /* Placeholder function, used for regular client onboarding */
  // Get onboarding workflow
  const clientWorkflows = await api.get('/advisor_portal/workflow/get', [
    `pas_client_ids=${client.id}`,
    'type=client_onboarding',
    'limit=1000',
  ]);

  const newAccounts = accounts;
  let clients = [
    {
      role: 'Primary client',
      pas_client_id: client.id,
      client_data: client,
    },
  ];
  const newClients = accounts.reduce((acc, r) => ((r.joint_client_id || []).length ? [...acc, ...r.joint_client_id] : acc), []);
  let clientsToAdd = [];
  if (newClients.length > 0) {
    clientsToAdd = await Promise.all(newClients.map(async (clientId) => {
      const client_data = await api.get(`/datahub/clients/${clientId}/`);
      return {
        role: 'Secondary Client',
        pas_client_id: clientId,
        client_data,
      };
    }));
  }

  clients = [...clients, ...clientsToAdd];


  if (clientWorkflows.docs.filter((w) => w.type === 'client_onboarding')[0]) { // workflow exists
    const existingWorkflow = clientWorkflows.docs.filter((w) => w.type === 'client_onboarding')[0];
    const workflow_specific_data = existingWorkflow.workflow_specific_data || {};
    workflow_specific_data.clients = clients;
    workflow_specific_data.accounts = newAccounts;

    await api.post('/advisor_portal/workflow/put', {
      workflow: existingWorkflow._id,
      action: 'update_workflow_specific_data',
      workflow_specific_data,
    });
    return existingWorkflow._id;
  }
  // Add newAccounts to workflow_specific_data
  const workflow_specific_data = {
    accounts: newAccounts,
    clients,
  };
  // Create onboarding workflow
  const workflow_response = await api.post('/advisor_portal/workflow/new', {
    pas_client_ids: [client.id],
    workflow_type: 'client_onboarding',
    firm: Store.getters.firm,
    workflow_specific_data,
  });


  if (workflow_response.workflow_id) {
    Store.dispatch('flipSnackbarSwitch');
    Store.dispatch('setSnackbar', {
      text: 'Client onboarding workflow was successfully created',
      type: 'success',
      topic: '',
    });
    return workflow_response.workflow_id;
  }
}

async function launchSurveyEngine(requestType, accounts, clientId, workflowId, method) {
  let surveyEngineResponse;
  const inPerson = method === 'inPerson';
  try {
    surveyEngineResponse = await api.post('/advisor_portal/initiate_survey_engine/', {
      request_type: requestType,
      client_id: clientId,
      workflowId,
      accounts,
      inPerson,
    });
    if (surveyEngineResponse.code) {
      Store.dispatch('setSnackbar', { text: 'Error initiating survey engine', type: 'error', topic: '' });
      Store.dispatch('flipSnackbarSwitch');
      return false;
    }
  } catch (error) {
    Store.dispatch('setSnackbar', { text: 'Error triggering survey engine', type: 'error', topic: '' });
    Store.dispatch('flipSnackbarSwitch');
    return false;
  }

  if (inPerson) {
    window.open(getSurveyEngineURL(), '_blank');
  }

  return true;
}
