<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      hide-actions
    >
      <template v-slot:items="props">
        <td class="text-xs-left">
          <div>
            {{props.item.name}}
          </div>
        </td>
        <template  v-for="(bracket, i) in props.item.brackets">
          <td :key="i" class="text-xs-left">
            <div v-if="bracket.maximum">
              <span v-if="bracket.minimum === '0.00' && bracket.maximum === '99999999.00'">
                At {{commaDecimalFormatted(bracket.rate * 100)}}%
              </span>
              <span v-if="bracket.minimum === '0.00' && bracket.maximum !== '99999999.00'">
                Up to ${{commaNoDecimalFormatted(bracket.maximum)}}
                at {{commaDecimalFormatted(bracket.rate * 100)}}%
              </span>
              <span v-if="bracket.minimum !== '0.00' && bracket.maximum === '99999999.00'">
                ${{commaNoDecimalFormatted(bracket.minimum)}} and onwards
                at {{commaDecimalFormatted(bracket.rate * 100)}}%
              </span>
              <span v-if="bracket.minimum !== '0.00' && bracket.maximum !== '99999999.00'">
                ${{commaNoDecimalFormatted(bracket.minimum)}} -
                ${{commaNoDecimalFormatted(bracket.maximum)}} at
                {{commaDecimalFormatted(bracket.rate * 100)}}%
              </span>
            </div>
            <div v-else>{{bracket}}</div>
          </td>
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { commaNoDecimalFormatted, commaDecimalFormatted } from 'Services/helpers/currency';

export default {
  name: 'FeeScheduleDetails',
  props: {
    schedules: Array,
  },
  data() {
    return {
      commaNoDecimalFormatted,
      commaDecimalFormatted,
    };
  },
  computed: {
    headers() {
      if (!this.schedules.length) return [];
      const columnCount = this.schedules
        .map((s) => s.brackets.length)
        .reduce((a, b) => Math.max(a, b));
      const headers = [{
        text: 'Schedule',
        align: 'left',
        sortable: false,
      }];
      for (let i = 0; i < columnCount; i++) {
        headers.push({
          text: `Tier ${i + 1}`,
          align: 'left',
          sortable: false,
        });
      }
      return headers;
    },
    items() {
      if (!this.schedules) return [];
      const schedules = this.schedules.map((s) => {
        const brackets = s.brackets.sort((a, b) => a.maximum - b.maximum);
        const schedule = { name: s.name, brackets: [] };
        if (!brackets.length) schedule.brackets.push('No brackets to display');
        else {
          for (let i = 0; i < this.headers.length - 1; i++) {
            if (brackets[i]) schedule.brackets.push(brackets[i]);
            else schedule.brackets.push('');
          }
        }
        return schedule;
      });
      return schedules;
    },
  },
};
</script>
