<template>
  <div>
    <v-form
      ref="client"
      lazy-validation
      @submit.prevent
    >
      <v-layout wrap mt-5>
        <v-flex
          v-for="(item, i) in nonprofit"
          :key="i"
          sm6 md4
          px-2 mb-5
        >
          <div class="strong-text mb-3">
            {{ item.fieldName }}
            <sup>{{ item.required }}</sup>
          </div>
          <pas-input-field
            v-model="item.value"
            v-if="item.type === 0"
            :rules="item.rules"
            :maxLength="item.maxLength"
            :readonly="!editable || item.readOnly"
          ></pas-input-field>
        </v-flex>
      </v-layout>
    <div class="px-2">
        <div v-if="!modalMode">
          <div class="strong-text mb-3">Notes</div>
          <pas-textarea
            v-model="notes"
            :placeholder="`Write user notes here`"
            :readonly="!editable"
          ></pas-textarea>
        </div>
        <span class="error--text">{{ validationText }}</span>
        <pas-button
          v-if="!modalMode && editable"
          class="primary-btn wide-btn"
          @click="save"
          :processing="processingNonprofit"
        >Save</pas-button>
      </div>
    </v-form>
  </div>
</template>

<script>

import _ from 'lodash';
import v from 'Services/validation';

export default {
  name: 'nonprofit-details-modal',
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nonprofit: {
        displayName: {
          fieldName: 'Display Name', value: null, rules: [v.isRequired()], type: 0, required: '*',
        },
        name: {
          fieldName: 'Name', value: null, rules: [v.isRequired()], required: '*', type: 0,
        },
        email: {
          fieldName: 'Email', value: null, rules: [v.isValidEmail], type: 0,
        },
      },
      notes: '',
      v,
      validationText: '',
      processingNonprofit: false,
      isEdited: false,
      isInit: true,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientType() {
      return this.activeClient.client ? this.activeClient.client.client_type : '';
    },
  },
  methods: {
    handleResponses(type, msg) {
      const text = msg !== null ? msg : `${type === 'fail' ? 'Failed to update' : 'Updated'} Non-profit Details`;
      if (!this.modalMode) this.$store.dispatch('setSnackbar', { text, type });
      if (!this.modalMode) this.$store.dispatch('flipSnackbarSwitch');
      if (this.modalMode) this.$emit('done', type === 'fail' ? 'fail' : 'success');
    },
    async save() {
      this.validationText = '';
      if (!this.$refs.client.validate()) return null;

      if (this.validationText) return null;
      this.processingNonprofit = true;
      const data = {
        id: this.activeClient.client.id,
        display_name: this.nonprofit.displayName.value,
        email: this.nonprofit.email.value,
        name: this.nonprofit.name.value,
        quick_notes: this.notes,
        client_type: this.clientType,
      };
      await this.$store.dispatch('updateClient', data)
        .then((res) => this.handleResponses(res.id ? 'success' : 'fail', res.id ? 'Non-Profit details successfully updated.' : 'Error in updating client profile.'))
        .catch((err) => this.handleResponses('fail', err));
      this.processingNonprofit = false;
    },
  },
  mounted() {
    const client = _.cloneDeep(this.activeClient);
    if (client.person) {
      this.nonprofit.displayName.value = client.person.display_name || null;
      this.nonprofit.name.value = client.person.name || null;
      this.nonprofit.email.value = client.person.email || null;
      this.nonprofit.notes.value = client.person.notes || null;
    }

    this.isEdited = false;
  },
  watch: {
    activeClient: {
      handler() {
        if (!this.isEdited) {
          const client = _.cloneDeep(this.activeClient);
          if (client.person) {
            this.nonprofit.displayName.value = client.person.display_name || null;
            this.nonprofit.name.value = client.person.name || null;
            this.nonprofit.email.value = client.person.email || null;
            this.nonprofit.notes.value = client.person.notes || null;
          }
        }
      },
      deep: true,
    },
    nonprofit: {
      handler() {
        if (!this.isInit && !this.isEdited) {
          this.isEdited = true;
          return;
        }
        this.isInit = false;
      },
      deep: true,
    },
  },
};

</script>
