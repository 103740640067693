export const transformTopWorkflows = (response) => {
  const results = {
    totalCount: response.count,
    next: response.next,
    workflows: [],
  };
  // for each workflow, get each step and create a workflow object
  // the length of results.workflows will be the total number of steps we have for all workflows
  response.results.forEach((result) => {
    if (!result.workflow) return;
    results.workflows.push({
      clientName: result.client.display_name,
      clientEmail: result.client.email,
      id: result.workflow.id,
      workflowType: result.workflow.type,
      workflowStatus: result.workflow.status,
      workflowUpdatedAt: result.workflow.updated_at,
      workflowName: result.workflow.name,
      steps: result.workflow.steps.map((step) => ({
        stepName: step.name,
        status: step.status,
        id: step.id,
      })),
    });
  });
  return results;
};
