import _ from 'lodash';
import {
  fetchAllClientInvestmentAccts,
  fetchBankAcctsByClient,
  fetchCurrency,
  fetchFundingFrequencies,
  fetchFundingSubtypes,
  fetchFundingTypes,
  fetchInvestmentAcctsByClient,
  fetchPaymentMethods,
  fetchStagedExternalTransfers,
  fetchRecipientTypes,
  fetchWithdrawalTypes,
  fetchWithdrawalTypesForSubtype,
  fetchWithholdingTaxTypes
} from '../../services/api/FundingVersion2';

import { INVESTMENT_ACCT_BY_TYPE, formatInvestmentAcctsResponse } from '../../services/helpers/funding/index';

const defaultState = {
  clientId: null,
  investmentAccts: [],
  filteredToInvestmentAccts: [],
  marginAccts: [],
  rolloverSavings: [],
  rolloverIncome: [],
  bankAccts: [],
  fundingRequests: [],
  validatedRequest: {},
  activeRequest: {},
  // lists
  fundingFrequencies: [],
  fundingTypes: [],
  fundingSubtypes: [],
  fundingDocuments: [],
  withdrawalTypes: [],
  paymentMethods: [],
  withholdingTaxTypes: [],
  currencyTypes: [],
  recipientTypes: [],
  recurringEvents: [],
  relatedClientAccts: [],
  stagedExternalTransfers: [],
  // basic client info, including display name, contact info...
  client: {},
  // edit flag
  editMode: false,
  selectedRequestType: {},
  selectedRequestSubtype: {},
  warningMessage: {},
};

const mutations = {
  SET_RELATED_CLIENT_ACCTS(state, relatedAccts) {
    state.relatedClientAccts = relatedAccts;
  },
  SET_CLIENT(state, client) {
    state.client = client;
    state.clientId = client.id;
  },
  SET_INVESTMENT_ACCTS(state, investmentAccts) {
    state.investmentAccts = investmentAccts;
  },
  SET_FILTERED_TO_INVESTMENT_ACCTS(state, investmentAccts) {
    state.filteredToInvestmentAccts = investmentAccts;
  },
  SET_MARGIN_ACCTS(state, accounts) {
    state.marginAccts = accounts;
  },
  SET_ROLLOVER_INCOME_ACCTS(state, accounts) {
    state.rolloverIncome = accounts;
  },
  SET_ROLLOVER_SAVINGS_ACCTS(state, accounts) {
    state.rolloverSavings = accounts;
  },
  SET_BANK_ACCTS(state, bankAccts) {
    state.bankAccts = bankAccts;
  },
  SET_FUNDING_FREQUENCIES(state, fundingFrequencies) {
    state.fundingFrequencies = fundingFrequencies;
  },
  SET_FUNDING_TYPES(state, fundingTypes) {
    state.fundingTypes = fundingTypes;
  },
  SET_FUNDING_SUBTYPES(state, fundingSubtypes) {
    state.fundingSubtypes = fundingSubtypes;
  },
  SET_WITHDRAWAL_TYPES(state, withdrawalTypes) {
    state.withdrawalTypes = withdrawalTypes;
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_WITHHOLDING_TAX_TYPES(state, withholdingTaxTypes) {
    state.withholdingTaxTypes = withholdingTaxTypes;
  },
  SET_CURRENCY_TYPES(state, currencyTypes) {
    state.currencyTypes = currencyTypes;
  },
  SET_RECIPIENT_TYPES(state, recipientTypes) {
    state.recipientTypes = recipientTypes;
  },
  SET_RECURRING_EVENTS(state, recurringEvents) {
    state.recurringEvents = recurringEvents;
  },
  SET_VALIDATED_REQUEST(state, validatedRequest) {
    state.validatedRequest = validatedRequest;
  },
  SET_FUNDING_DOCUMENTS(state, fundingDocuments) {
    state.fundingDocuments = fundingDocuments;
  },
  SET_ACTIVE_REQUEST(state, activeRequest) {
    state.activeRequest = activeRequest;
  },
  SET_EDIT(state, bool) {
    state.editMode = bool;
  },
  SET_SELECTED_REQUEST_TYPE(state, type) {
    state.selectedRequestType = type;
  },
  SET_SELECTED_REQUEST_SUBTYPE(state, subtype) {
    state.selectedRequestSubtype = subtype;
  },
  SET_WARNING_MESSAGE(state, warningMessage) {
    state.warningMessage = warningMessage;
  },
  SET_STAGED_EXTERNAL_TRANSFERS(state, stagedExternalTransfers) {
    state.stagedExternalTransfers = stagedExternalTransfers;
  },
};

const actions = {
  setFundingTypes({ commit }) {
    return fetchFundingTypes().then((res) => {
      commit("SET_FUNDING_TYPES", res);
    });
  },
  setFundingSubtypes({ commit }, { clientId, typeId }) {
    return fetchFundingSubtypes(clientId, typeId).then((res) => {
      commit("SET_FUNDING_SUBTYPES", res);
    });
  },
  setFundingFrequencies({ commit }) {
    return fetchFundingFrequencies().then((res) => {
      commit("SET_FUNDING_FREQUENCIES", res);
    });
  },
  setWithdrawalTypes({ commit }, subtypeId = null) {
    if (subtypeId) {
      return fetchWithdrawalTypesForSubtype(subtypeId).then((res) => {
        commit("SET_WITHDRAWAL_TYPES", res);
      });
    }

    return fetchWithdrawalTypes().then((res) => {
      commit("SET_WITHDRAWAL_TYPES", res);
    });
  },
  setPaymentMethods({ commit }, subtypeId = null) {
    return fetchPaymentMethods(subtypeId).then((res) => {
      commit("SET_PAYMENT_METHODS", res);
    });
  },
  setWithholdingTaxTypes({ commit }) {
    return fetchWithholdingTaxTypes().then((res) => {
      commit("SET_WITHHOLDING_TAX_TYPES", res);
    });
  },
  setCurrencyTypes({ commit }) {
    return fetchCurrency().then((res) => {
      commit('SET_CURRENCY_TYPES', res);
    });
  },
  setRecipientTypes({ commit }) {
    return fetchRecipientTypes().then((res) => {
      commit('SET_RECIPIENT_TYPES', res);
    });
  },
  setFilteredToInvestmentAccounts({ commit }, { clientId, types }) {
    return fetchInvestmentAcctsByClient(
      clientId,
      types.join(','),
    ).then((res) => {
      commit('SET_FILTERED_TO_INVESTMENT_ACCTS', formatInvestmentAcctsResponse(res));
    });
  },
  setInvestmentAccountByType({ commit }, { clientId, type }) {
    switch (type) {
      case INVESTMENT_ACCT_BY_TYPE.DIFFERENT_CLIENTS:
        return fetchAllClientInvestmentAccts(clientId).then((res) => {
          commit('SET_RELATED_CLIENT_ACCTS', formatInvestmentAcctsResponse(res, { name: true }));
        });
      case INVESTMENT_ACCT_BY_TYPE.MARGIN_DRAW_DOWN:
        return fetchInvestmentAcctsByClient(clientId, type).then((res) => {
          commit('SET_MARGIN_ACCTS', formatInvestmentAcctsResponse(res));
        });
      case INVESTMENT_ACCT_BY_TYPE.ROLLOVER_SAVINGS:
        return fetchInvestmentAcctsByClient(clientId, type).then((res) => {
          commit('SET_ROLLOVER_SAVINGS_ACCTS', formatInvestmentAcctsResponse(res));
        });
      case INVESTMENT_ACCT_BY_TYPE.ROLLOVER_INCOME:
        return fetchInvestmentAcctsByClient(clientId, type).then((res) => {
          commit('SET_ROLLOVER_INCOME_ACCTS', formatInvestmentAcctsResponse(res));
        });
      case INVESTMENT_ACCT_BY_TYPE.HBP_LLP:
      case INVESTMENT_ACCT_BY_TYPE.RESP:
      case INVESTMENT_ACCT_BY_TYPE.RRIF_SRRIF_LIF:
        return fetchInvestmentAcctsByClient(clientId, type).then((res) => {
          commit('SET_INVESTMENT_ACCTS', formatInvestmentAcctsResponse(res));
        });
      default:
        return fetchInvestmentAcctsByClient(clientId).then((res) => {
          commit('SET_INVESTMENT_ACCTS', formatInvestmentAcctsResponse(res));
        });
    }
  },
  setRecurringEvents({ commit }, recurringEvents) {
    commit('SET_RECURRING_EVENTS', recurringEvents);
  },
  setAccts({ commit }, { clientId }) {
    return Promise.all([fetchBankAcctsByClient(clientId), fetchInvestmentAcctsByClient(clientId)])
      .then((resps) => {
        const bankAccts = resps[0].map((acc) =>
          _.assign(acc, {
            label: `${acc.name} *${acc.account_no}`,
          })
        );
        commit("SET_BANK_ACCTS", bankAccts);
        const investmentAccts = formatInvestmentAcctsResponse(resps[1]);
        commit("SET_INVESTMENT_ACCTS", investmentAccts);
      })
      .catch((err) => console.log("err", err)); // TODO: throw error and route to empty state
  },
  setValidatedRequest({ commit }, request) {
    commit("SET_VALIDATED_REQUEST", request);
  },
  setFundingDocuments({ commit }, documents) {
    commit("SET_FUNDING_DOCUMENTS", documents);
  },
  setActiveRequest({ commit }, request) {
    commit("SET_ACTIVE_REQUEST", request);
  },
  setEditMode({ commit }, bool) {
    commit("SET_EDIT", bool);
  },
  setSelectedRequestType({ commit }, type) {
    commit("SET_SELECTED_REQUEST_TYPE", type);
  },
  setSelectedRequestSubtype({ commit }, subtype) {
    commit("SET_SELECTED_REQUEST_SUBTYPE", subtype);
  },
  setWarningMessage({ commit }, warningMessage) {
    commit("SET_WARNING_MESSAGE", warningMessage);
  },
  setStagedExternalTransfers({ commit }, { clientId }) {
    return fetchStagedExternalTransfers(clientId).then((res) => {
      commit('SET_STAGED_EXTERNAL_TRANSFERS', res);
    });
  },
};

export const getters = {
  relatedClientAccts: (state) => state.relatedClientAccts,
  fundingFrequencies: (state) => state.fundingFrequencies,
  fundingTypes: (state) => state.fundingTypes,
  fundingSubtypes: (state) => state.fundingSubtypes,
  fundingDocuments: (state) => state.fundingDocuments, // additional documents for complex funding
  withdrawalTypes: (state) => state.withdrawalTypes,
  paymentMethods: (state) => state.paymentMethods,
  withholdingTaxTypes: (state) => state.withholdingTaxTypes,
  currencyTypes: (state) => state.currencyTypes,
  recipientTypes: (state) => state.recipientTypes,
  marginAccts: (state) => state.marginAccts,
  rolloverIncome: (state) => state.rolloverIncome,
  rolloverSavings: (state) => state.rolloverSavings,
  recurringEvents: (state) => state.recurringEvents,
  investmentAccts: (state) => state.investmentAccts,
  filteredToInvestmentAccts: (state) => state.filteredToInvestmentAccts,
  bankAccts: (state) => state.bankAccts,
  validatedRequest: (state) => state.validatedRequest, // funding request passed validate call
  activeRequest: (state) => state.activeRequest,
  editMode: (state) => state.editMode,
  selectedRequestType: (state) => state.selectedRequestType,
  selectedRequestSubtype: (state) => state.selectedRequestSubtype,
  complexFundingFeatureFlag: (_state, _rootGetters, rootState) =>
    rootState.app.features.complex_funding_request,
  warningMessage: (state) => state.warningMessage,
  stagedExternalTransfers: (state) => state.stagedExternalTransfers,
};

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
