import { logUserOut } from 'Services/helpers/auth';

const jwtDecode = require('jwt-decode');

const _decodeToken = (token) => {
  if (!token) {
    return {};
  }

  return jwtDecode(token);
};

export const getFirmFromJwt = () => {
  const jwtInfo = _decodeToken(localStorage.getItem('jwt'));
  if (!jwtInfo.firm) return null;
  return jwtInfo.firm;
};

const isPasAdmin = () => {
  const jwt = _decodeToken(localStorage.getItem('jwt'));
  return jwt.pas_admin;
};

const isFirmAdmin = () => {
  const jwt = _decodeToken(localStorage.getItem('jwt'));
  return jwt.firm_admin || jwt.pas_admin;
};

const datacaptureUrl = () => localStorage.getItem('datacaptureUrl');

const axonUrl = () => localStorage.getItem('axonUrl');

const convertObjectToForm = (params) => Object.keys(params).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');

const post = (url, body) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${url}`, {
    method: "POST",
    redirect: "manual",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json; charset=utf-8"
    },
  })
    .then((res) => res.json())
    .then((res) => {
      checkForAuthFail(res);
      return res;
    })
    .catch((err) => {
      console.log("err in api post", err);
      return err;
    });
};

const postFormEncoded = (url, body) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${url}`, {
    method: 'POST',
    redirect: 'manual',
    body: convertObjectToForm(body),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      checkForAuthFail(res);
      return res;
    })
    .catch((err) => {
      console.log('err in api post form encoded', err);
      return err;
    });
};

const upload = (url, body) => {
  const jwt = localStorage.getItem("jwt") || "";
  return fetch(`${url}`, {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      checkForAuthFail(res);
      return res;
    })
    .catch((err) => {
      console.log("err in api post", err);
      return err;
    });
};

const put = (url, body) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${url}`, {
    method: 'PUT',
    redirect: 'manual',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      checkForAuthFail(res);
      return res;
    })
    .catch((err) => {
      console.log('err in api put', err);
      return err;
    });
};

const patch = (url, body) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${url}`, {
    method: 'PATCH',
    redirect: 'manual',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      checkForAuthFail(res);
      return res;
    })
    .catch((err) => {
      console.log('err in api patch', err);
      return err;
    });
};

const get = (url, ...queryParams) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${url}${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`, {
    method: 'GET',
    redirect: 'manual',
    headers: {
      Authorization: `Bearer ${jwt}`,
	   	'Content-Type': 'application/json; charset=utf-8',
      //  "Content-Type": "application/x-www-form-urlencoded"
    },
  })
    .then((res) => res.json())
    .then((res) => {
      checkForAuthFail(res);
      return res;
    });
};

const deleteCall = (url, body) => {
  const jwt = localStorage.getItem('jwt') || '';
  return fetch(`${url}`, {
    method: 'DELETE',
    redirect: 'manual',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((res) => {
      checkForAuthFail(res);
      return res;
    })
    .then((res) => (res.status < 300));
};

function checkForAuthFail(res) {
  if (res.status === 'error' && res.detail === 'auth_fail') logUserOut();
}

export default {
  post,
  put,
  get,
  postFormEncoded,
  upload,
  patch,
  deleteCall,
  isPasAdmin,
  isFirmAdmin,
  datacaptureUrl,
  axonUrl
};
