import api from 'Services/api';

export const fetchClientIPInviteStatus = (clientID) => api.get(`/datahub/get_invitation_status/?client_id=${clientID}`);
export const generateIPLink = (data) => api.post('/advisor_portal/send_ip_invitation_email/', data);
export const unlockInvestorAccount = (clientID) => api.post('/datahub/update_investor_portal_account_status/', { client_id: clientID, suspend_account: false });
export const fetchInvestorAccountStatus = (clientID) => api.get(`/datahub/get_investor_portal_account_status/?client_id=${clientID}`);
export const generateIPLoginEmail = (data) => api.post('/advisor_portal/send_ip_login_email', data);
export const fetchEmailChangeRequest = (clientID) => api.get(`/datahub/get_email_change_request_by_client/?client_id=${clientID}`);
export const requestIPEmailChange = (data) => api.post('/advisor_portal/request_ip_email_change', data);
export const cancelIPEmailChange = (data) => api.post('/advisor_portal/cancel_ip_email_change', data);
export const fetchVerificationStatus = ({ clientId }) => api.get(`/advisor_portal/get_ip_user_verification_status/?client_id=${clientId}`);
export const resetClientIpRegistration = (data) => api.post('/advisor_portal/reset_ip_registration', data);
