<template>
  <div class="mt-5" style="height: 94%; overflow: hidden">
    <h2>Insights</h2>
    <div class="insight-error-warpper">
      <div>
        <img :src="errorSvg" alt="Cannot load data">
        <p class="bold mt-4 mb-1">Something went wrong. Please refresh the page.</p>
        <small style="color:#657381">If this problem continues contact us.</small>
        <div class="mt-2"><PButton variant="primary" @click="reload">Action</PButton></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  components: {
  },
  data() {
  },
  computed: {
    errorSvg() { return this.$store.state.app.themeAssets.virusThreat; },
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.insight-error-warpper{
  width: 100%;
  height: 85%;
  margin: auto;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

</style>
