<template>
  <pas-fullscreen-modal
    @close="close"
    :isOpen="isOpen"
  >
    <v-container class="modal-container">
      <v-layout>
        <v-flex xs12 text-xs-center>
          <pas-page-title>
            Add {{account.nickname}} Details
          </pas-page-title>
        </v-flex>
      </v-layout>
      <v-layout mb-5>
        <v-flex xs12 text-xs-center>
          <span>
            Specify instructions to cover at least the minimum required payment by law.
          </span>
        </v-flex>
      </v-layout>
      <v-form
          ref="form"
          lazy-validation
          @submit.prevent=""
      >
      <v-layout>
      <pas-page-subtitle>Payment Instructions</pas-page-subtitle>
      </v-layout>
      <v-layout align-center row>

        <div class="mr-3">Send payments to </div>
        <pas-button-toggle
          :value="payToType === payToTypeMap['BANK'] ? 0 : 1"
          @input="$event === 0 ? payToType = payToTypeMap['BANK'] : payToType = payToTypeMap['INVESTMENT']"
          :class="{'pas-toggle': true,
                    'flexColumn': true,
                    'btnBottomMargin': true,
                    }"
        >
          <v-btn flat>Bank</v-btn>
          <v-btn flat>Investment</v-btn>
        </pas-button-toggle>
        <div class="mr-3">account</div>
        <pas-autocomplete
          :items="(payToType == payToTypeMap['BANK']) ? bankAccounts : investmentAccounts"
          :value="selectedAccount"
          @input="selectedAccount=$event"
          :placeholder="`Select Account...`"
          :style="{maxWidth}"
          :key="`account`"
          :rules="[v.isRequired()]"
        ></pas-autocomplete>

        <div class="ml-3" v-if="payToType === payToTypeMap['BANK'] && canEditBankAccountSetup">or
          <span class="action-text"
              @click="openBankAccountModal">add a bank account</span>
        </div>
        <!--div class="ml-3" v-else>or
          <span class="action-text"
              @click="$emit('openModal', 'newInvestmentAccount')">add an investment account</span>
        </div-->
      </v-layout>
      <v-layout align-center row mt-3>
        <div class="mr-3">Frequency </div>
        <pas-autocomplete
          :items="frequencyOptions"
          :value="selectedFrequency"
          @input="selectedFrequency = $event"
          :placeholder="`Select frequency...`"
          :style="{maxWidth}"
          :key="`frequency`"
          :rules="[v.isRequired()]"
        ></pas-autocomplete>
      </v-layout>
      <template v-if="custodian === 'fcc'">
        <v-layout align-center row mt-3>
          <v-flex>
            <div class="mr-3">Starting </div>
          </v-flex>
          <v-flex mb-4>
            <pas-autocomplete
              :items="getDates"
              v-model="startDay"
              :placeholder="`Select day...`"
              :style="{maxWidth, position: 'relative', bottom: '-12px'}"
              :key="`start-day`"
              :rules="[v.isRequired()]"
            ></pas-autocomplete>
          </v-flex>
          <v-flex>
            <div class="mx-3">of</div>
          </v-flex>
          <v-flex mb-4>
            <pas-autocomplete
              :items="MONTHS"
              v-model="selectedMonth"
              :placeholder="`Select month...`"
              :style="{maxWidth, position: 'relative', bottom: '-12px'}"
              :key="`month`"
              :rules="[v.isRequired()]"
            ></pas-autocomplete>
          </v-flex>
          <v-flex mb-4>
            <pas-autocomplete
              :items="nextCalendarYear"
              v-model="selectedYear"
              :placeholder="`Select the year...`"
              :style="{maxWidth, position: 'relative', bottom: '-12px'}"
              :rules="[v.isRequired()]"
            ></pas-autocomplete>
          </v-flex>
        </v-layout>
      </template>
      <template v-else>
        <v-layout align-center row mt-3>
          <v-flex>
            <div class="mr-3">Starting </div>
          </v-flex>
          <v-flex>
            <v-radio-group v-model="startDay" row class="language-radio-group" :style="{maxWidth}" :key="`start-day`" :rules="[v.isRequired()]">
              <v-radio :color="primaryColor" :value="15" :label="`15th`"></v-radio>
              <v-radio :color="primaryColor" :value="30" :label="`30th`"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex>
            <div class="mx-3">of</div>
          </v-flex>
          <v-flex mb-4>
            <pas-autocomplete
              :items="MONTHS"
              v-model="selectedMonth"
              :placeholder="`Select month...`"
              :style="{maxWidth, position: 'relative', bottom: '-12px'}"
              :key="`month`"
              :rules="[v.isRequired()]"
            ></pas-autocomplete>
          </v-flex>
          <v-flex mb-4>
            <pas-autocomplete
              :items="nextCalendarYear"
              v-model="selectedYear"
              :placeholder="`Select the year...`"
              :style="{maxWidth, position: 'relative', bottom: '-12px'}"
              :rules="[v.isRequired()]"
            ></pas-autocomplete>
          </v-flex>
        </v-layout>
      </template>
      <v-layout align-center row mt-3>
        <div class="mr-3">Amount </div>
        <pas-input-currency
          v-model="amount"
          label="Enter amount"
          class="mr-3"
          :style="{maxWidth}"
          :disabled="amountDisabled"
          ref="inputCurrency"
          :key="`amount-field`"
          :rules="!amountDisabled ? [v.isRequired(), v.isPositive, v.isGreaterThan(0)] : []"
        ></pas-input-currency>
      </v-layout>
      <v-layout align-center row ml-5>
        <v-radio-group v-model="paymentAmountType" col class="pas-radio-group witholding-radio-group" :rules="[v.isRequired()]">
          <v-layout column>
            <v-radio :color="primaryColor" :value="`custom`" :label="`Custom amount`"/>
            <v-layout :style="{position: 'relative', left: '35px'}">
              <pas-button-toggle
                v-if="paymentAmountType === 'custom'"
                :value="amountType === 'gross' ? 0 : 1"
                @input="$event === 0 ? amountType = 'gross' : amountType = 'net'"
                :class="{'pas-toggle': true,
                        'flexRow': true,
                        'btnBottomMargin': true,
                        }"
              >
                <v-btn flat>Gross</v-btn>
                <v-btn flat>Net</v-btn>
              </pas-button-toggle>
            </v-layout>
          </v-layout>
          <v-radio :color="primaryColor" :value="`min`" :label="`Pay the minimum required`"/>
          <v-radio v-if="isLockedInAccount" :color="primaryColor" :value="`max`" :label="`Pay the maximum required`"/>
        </v-radio-group>
      </v-layout>

      <!-- Minimum witholding tax -->

      <v-layout mt-3>
        How much withholding tax should be held back from payments?
      </v-layout>
      <template v-if="custodian === 'pcs'">
        <v-layout ml-5 row nowrap align-center justify-space-between>
          <v-flex xs2></v-flex> <!-- spacer -->
          <v-flex xs10>
            <v-layout row nowrap align-center mt-4>
              <v-flex xs4></v-flex> <v-flex xs4 :style="{display: 'flex'}" class="space-around witholding-header"><div>Default</div><div>Custom</div></v-flex><v-flex xs4 class="text-align-center">Custom %</v-flex>
            </v-layout>
            <v-layout row nowrap align-center >
              <v-flex xs4 :style="{height: 'fitContent'}">On minimums</v-flex>
              <v-flex xs4 :style="{display: 'flex', justifyContent: 'spaceEvenly'}">
                <v-radio-group v-model="minimumWitholdingTax" row class="pas-radio-group witholding-radio-group" :key="`minimum-rg`" :rules="[v.isRequired()]">
                  <v-radio :color="primaryColor" :value="`default`" :label="``"></v-radio>
                  <v-radio :color="primaryColor" :value="`custom`" :label="``"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs4 v-if="minimumWitholdingTax==='custom'" class="fit-content display-flex justify-center">
                <pas-number-input
                  step="1"
                  min="0"
                  suffix="%"
                  @input="customMinimum=$event ? parseFloat($event).toFixed(0) : '0'"
                  :value="customMinimum.toString()"
                  :style="{maxWidth: '135px'}"
                  @upClick="customMinimum= customMinimum ? (parseFloat(customMinimum) + 1).toFixed(0) : '1'"
                  @downClick="customMinimum=customMinimum ? parseFloat(customMinimum) > 0  ? (parseFloat(customMinimum) - 1).toFixed(0) : '1' : '1'"
                  :key="`custom_minimum`"
                >
                </pas-number-input>
              </v-flex>
            </v-layout>
            <!-- withholding tax on maximums payments -->
            <!-- <v-layout v-if="isLockedInAccount" row nowrap align-center :style="{height: '40px'}">
              <v-flex xs4 :style="{height: 'fitContent'}">On maximums</v-flex><v-flex xs4 :style="{display: 'flex', justifyContent: 'spaceEvenly'}">
              <v-radio-group v-model="maximumWitholdingTax" row class="pas-radio-group witholding-radio-group" :key="`minimum-rg`" :rules="[v.isRequired()]">
                <v-radio :color="primaryColor" :value="`default`" :label="``"></v-radio>
                <v-radio :color="primaryColor" :value="`custom`" :label="``"></v-radio>
              </v-radio-group>
            </v-flex>
              <v-flex xs4 v-if="maximumWitholdingTax==='custom'" class="fit-content display-flex justify-center">
                <pas-number-input
                  step="1"
                  min="0"
                  suffix="%"
                  @input="customMaximum=$event ? parseFloat($event).toFixed(0) : '0'"
                  :value="customMaximum.toString()"
                  :style="{maxWidth: '135px'}"
                  @upClick="customMaximum= customMaximum ? (parseFloat(customMaximum) + 1).toFixed(0) : '1'"
                  @downClick="customMaximum=customMaximum ? parseFloat(customMaximum) > 0  ? (parseFloat(customMaximum) - 1).toFixed(0) : '1' : '1'"
                  :key="`custom_maximum`"
                >
                </pas-number-input>
              </v-flex>
            </v-layout> -->
            <v-layout row nowrap align-center mb-4 >
              <v-flex xs4 :style="{height: 'fitContent'}">On excess payments</v-flex><v-flex xs4 :style="{display: 'flex', justifyContent: 'spaceEvenly'}">
                <v-radio-group v-model="excessWitholdingTax" row class="pas-radio-group witholding-radio-group" :key="`excess-rg`" :rules="[v.isRequired()]">
                  <v-radio :color="primaryColor" :value="`default`" :label="``"></v-radio>
                  <v-radio :color="primaryColor" :value="`custom`" :label="``"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs4 v-if="excessWitholdingTax==='custom'" class="fit-content display-flex justify-center">
                <pas-number-input
                  step="1"
                  min="0"
                  suffix="%"
                  @input="customExcess=$event ? parseFloat($event).toFixed(0) : '0'"
                  :value="customExcess.toString()"
                  :style="{maxWidth: '135px'}"
                  @upClick="customExcess= customExcess ? (parseFloat(customExcess) + 1).toFixed(0) : '1'"
                  @downClick="customExcess=customExcess ? parseFloat(customExcess) > 0  ? (parseFloat(customExcess) - 1).toFixed(0) : '1' : '1'"
                  :key="`custom_excess`"
                >
                </pas-number-input>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
      <template v-else>
        <v-layout ml-5 align-center>
          <v-flex>
            <v-radio-group @change="updateWithholdingTaxSettings()" v-model="withholdingTaxType">
              <v-radio label="None" :value="WITHHOLDING_TAX_TYPES.none"></v-radio>
              <v-radio label="Full Payment" :value="WITHHOLDING_TAX_TYPES.full"></v-radio>
              <v-radio label="Excess Payment" :value="WITHHOLDING_TAX_TYPES.excess"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex>
            <pas-number-input
              :disabled="isWithholdingTaxPercentageDisabled"
              :label="'Amount'"
              :style="{maxWidth: '135px'}"
              :value="isWithholdingTaxPercentageDisabled ? '0' : withholdingTaxPercentage.toString()"
              @input="updateWithholdingTaxSettings($event)"
              @upClick="updateNumberInputPercentage(1); updateWithholdingTaxSettings()"
              @downClick="updateNumberInputPercentage(-1); updateWithholdingTaxSettings()"
              min="0"
              suffix="%"
            />
          </v-flex>
        </v-layout>
      </template>

      <template>
        <v-layout>
          Whose age will the payment calculation be based on?
        </v-layout>
        <v-layout ml-5 mb-3>
          <v-radio-group v-model="paymentAgeElection" row class="pas-radio-group" :key="`payment-age`" :rules="[v.isRequired()]">
            <v-radio :color="primaryColor" :value="0" :label="activeClient.client.display_name"></v-radio>
            <v-radio :color="primaryColor" :value="1" :label="spouse ? spouse.display_name : 'Spouse'"></v-radio>
          </v-radio-group>
        </v-layout>

        <v-layout>
          Has {{spouse ? spouse.display_name: 'a spouse'}} made any contributions to this account as spousal contributions?
        </v-layout>
        <v-layout ml-5>
          <v-radio-group v-model="spousalContributions" row class="pas-radio-group" :key="`spousal-cont`"  :rules="[v.isRequired(), this.spousalRule]">
            <v-radio :color="primaryColor" :value="1" :label="`Yes`"></v-radio>
            <v-radio :color="primaryColor" :value="0" :label="'No'"></v-radio>
          </v-radio-group>
        </v-layout>
    </template>

      <v-layout mt-4 justify-end>
        <pas-error-div :show="errorText ? true : false">{{errorText}}</pas-error-div>
      </v-layout>
      <v-layout mt-1 justify-end>
        <pas-button class="px-5" @click="save">
          Save
        </pas-button>
      </v-layout>
      </v-form>
    </v-container>
  </pas-fullscreen-modal>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { MONTHS, PAY_TO_TYPE, ACCOUNTTYPE } from 'Services/constants';
import { modal } from 'Services/mixins';
import v from 'Services/validation';
import { mapGetters } from 'vuex';
import { WITHHOLDING_TAX_TYPES } from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/constants';

export default {
  name: 'setup-rif-payment',
  mixins: [modal],
  data() {
    return {
      v,
      MONTHS,
      spousalContributions: '',
      paymentAgeElection: '',
      minimumWitholdingTax: '',
      maximumWitholdingTax: '',
      paymentAmountType: '',
      customMinimum: 0,
      customMaximum: 0,
      excessWitholdingTax: '',
      withholdingTaxType: '',
      withholdingTaxPercentage: 0,
      customExcess: 0,
      selectedAccount: '',
      frequencyOptions: [
        'Monthly',
        'Quarterly',
        'Semi-Annually',
        'Annually',
      ],
      payToType: PAY_TO_TYPE.BANK,
      selectedFrequency: '',
      maxWidth: '200px',
      startDay: '',
      selectedMonth: '',
      selectedYear: '',
      amount: '0.00',
      amountType: '', // net, gross, minimum
      amountDisabled: false,
      frequencyMap: {
        M: 'Monthly',
        Q: 'Quarterly',
        S: 'Semi-Annually',
        A: 'Annually',
      },
      errorText: '',
      spousalRule: (v) => !(!(this.account.base_type_string === 'SPOUSAL RRIF') && this.spousalContributions) || 'Only spousal rrifs may have a spousal contribution',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    account: Object,
  },
  created() {
    this.WITHHOLDING_TAX_TYPES = WITHHOLDING_TAX_TYPES;
  },
  computed: {
    ...mapGetters([
      'canEditBankAccountSetup',
      'custodian',
    ]),
    todayDate() {
      return new Date();
    },
    getDates() {
      return Array.from({length: 31}, (_, i) => i + 1)
    },
    nextCalendarYear() {
      return [moment().add(1, 'years').format('YYYY')];
    },
    isLockedInAccount() {
      return ['LIF', 'LRIF'].includes(this.account.base_type_string);
    },
    bankAccounts() {
      return this.$store.state.clients.activeClient.bankAccounts.filter((ba) => (ba.status !== 'I' && ba.status !== 'D')).map((a) => ({
        text: a.description,
        value: a.id,
      }));
    },
    investmentAccounts() {
      return this.$store.state.clients.activeClient.investment_accounts
        .filter((a) => ACCOUNTTYPE[a.account_type_short].toLowerCase().indexOf('cash') != -1)
        .map((a) => ({
          text: a.account_no_dataphile ? `${a.nickname}(${a.account_no_dataphile})` : `${a.nickname}`,
          value: a.id,
        }));
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    spouse() {
      return this.activeClient.relationships
        .some((r) => r.relationship_type === 'SP' || r.relationship_type === 'CL') ? this.activeClient.relationships
          .filter((r) => r.relationship_type === 'SP' || r.relationship_type === 'CL')[0].client_details[0] : null;
    },
    payToTypeMap() {
      return PAY_TO_TYPE;
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    isWithholdingTaxPercentageDisabled() {
      return this.withholdingTaxType === WITHHOLDING_TAX_TYPES.none;
    },
  },
  methods: {
    validate() {
      if (!(this.$refs.form.validate())) return false;
    },
    save() {
      if (!(this.$refs.form.validate())) return false;

      let date = moment().month(this.MONTHS.indexOf(this.selectedMonth));
      date = date.date(this.startDay);
      date = date.year(this.selectedYear);

      if (!date.isValid()) {
        this.errorText = 'Please enter a valid date.';
        return false;
      }
      if (date < this.todayDate) {
        this.errorText = 'Please enter a starting date in the future.';
        return false;
      }

      const rif_details = {
        payment_frequency: _.invert(this.frequencyMap)[this.selectedFrequency],
        account_id: this.selectedAccount,
        payment_amount: this.paymentAmountType === 'custom' && this.amountType === 'net' ? this.amount : 0,
        payment_type: this.paymentAmountType,
        amount_type: this.amountType,
        gross_payment_amt: this.amountType === 'gross' ? this.amount : 0,
        first_payment_date: date.format('YYYY-MM-DD'),
        payment_age_election: this.paymentAgeElection,
        spousal_contribution: this.spousalContributions,
        withholding_tax_type: this.withholdingTaxType,
        min_withholding_tax_rate_selection: this.minimumWitholdingTax,
        max_withholding_tax_rate_selection: this.maximumWitholdingTax,
        custom_minimum: this.minimumWitholdingTax === 'custom' ? this.customMinimum : 0,
        custom_maximum: this.maximumWitholdingTax === 'custom' ? this.customMaximum : 0,
        excess_withholding_tax_rate_selection: this.excessWitholdingTax,
        custom_excess: this.excessWitholdingTax === 'custom' ? this.customExcess : 0,
        payToType: this.payToType,
      };
      this.$emit('save', rif_details);
      const that = this;
      this.$nextTick().then(() => {
        that.close();
      });
    },
    mapAccountToForm() {
      const { rif_details } = this.account;
      if (rif_details) {
        this.selectedAccount = rif_details.account_id;
        this.selectedFrequency = this.frequencyMap[rif_details.payment_frequency];
        this.selectedAccount = rif_details.account_id;
        this.amount = rif_details.payment_type === 'custom' && rif_details.amount_type === 'net' ? rif_details.payment_amount : rif_details.gross_payment_amt;
        this.paymentAmountType = rif_details.payment_type;
        this.amountType = rif_details.amount_type;
        this.selectedYear = moment(rif_details.first_payment_date).year();
        this.selectedMonth = this.MONTHS[moment(rif_details.first_payment_date).month()];
        this.startDay = moment(rif_details.first_payment_date).date();
        this.paymentAgeElection = rif_details.payment_age_election;
        this.spousalContributions = rif_details.spousal_contribution;
        this.withholdingTaxType = rif_details.withholding_tax_type;
        this.minimumWitholdingTax = rif_details.min_withholding_tax_rate_selection;
        this.maximumWitholdingTax = rif_details.max_withholding_tax_rate_selection;
        this.customMinimum = rif_details.custom_minimum;
        this.customMaximum = rif_details.custom_maximum;
        this.excessWitholdingTax = rif_details.excess_withholding_tax_rate_selection;
        this.customExcess = rif_details.custom_excess;
        this.payToType = rif_details.payToType;
      }
    },
    openBankAccountModal() {
      this.$store.commit('OPEN_MODAL', 'add-bank-account');
    },
    updateWithholdingTaxSettings(newPercentage) {
      if (newPercentage) {
        this.withholdingTaxPercentage = parseFloat(newPercentage).toFixed(0);
      }

      const isNone = this.withholdingTaxType === WITHHOLDING_TAX_TYPES.none;
      const isFull = this.withholdingTaxType === WITHHOLDING_TAX_TYPES.full;
      const isExcess = this.withholdingTaxType === WITHHOLDING_TAX_TYPES.excess;

      if (isNone) {
        this.withholdingTaxPercentage = 0;
      }

      this.minimumWitholdingTax = isNone ? 'custom' : 'default';
      this.maximumWitholdingTax = isFull ? 'custom' : 'default';
      this.excessWitholdingTax = isExcess ? 'custom' : 'default';

      this.customMinimum = isNone ? this.withholdingTaxPercentage : 0;
      this.customMaximum = isFull ? this.withholdingTaxPercentage : 0;
      this.customExcess = isExcess ? this.withholdingTaxPercentage : 0;
    },
    updateNumberInputPercentage(value) {
      const newValue = Math.ceil(
        parseFloat(this.withholdingTaxPercentage),
      ) + value;

      this.withholdingTaxPercentage = newValue < 0 ? 0 : newValue;
    },
  },
  watch: {
    paymentAmountType(val) {
      if (val === 'custom') {
        this.amountType = 'gross';
        this.amountDisabled = false;
      } else {
        this.$nextTick().then(() => {
          this.amountType = '';
          this.amount = '0.00';
          this.amountDisabled = true;
        });
      }
    },
    isOpen(val) {
      if (val) {
        this.mapAccountToForm();
      }
    },
    account: {
      deep: true,
      handler(val) {
        this.mapAccountToForm();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-container {
    max-width: 720px !important;
  }

  .witholding-header {
    div {
      left: -10px;
    }
  }
</style>
<style lang="scss">
  .witholding-radio-group {
    .v-input__control {
      width: 100% !important;
    }
    .v-input__slot {
      width: 100% !important;
    }
    .v-input--radio-group__input {
      justify-content: space-around;
    }
  }
</style>
