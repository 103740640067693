<template>
  <div class="px-2">
    <v-form
      ref="commentArea"
      @submit.prevent="">
      <v-layout ref="discussionContainer" class="discussion-container scroll-y">
        <v-flex v-if="comments.length > 0 && !isProcessing" xs12 px-2>
          <template v-for="(comment, index) in comments">
            <PasDiscussionComment
              :commenterName="comment.commenter_details.first_name + ' ' + comment.commenter_details.last_name"
              :content="comment.content"
              :date="comment.created_at"
              :key="`content-${index}`"
            >
            </PasDiscussionComment>
          </template>
        </v-flex>
        <v-flex xs12 px-2 v-else-if="!comments.length > 0 && !isProcessing">
          <p>There aren't any messages here yet. Submit a comment to start a discussion...</p>
        </v-flex>
        <v-flex xs12 px-2 v-else>
            <pas-circular-loader></pas-circular-loader>
        </v-flex>
      </v-layout>
      <v-layout>
          <v-flex xs12>
            <PasTextarea
              :placeholder="`Enter a comment here...`"
              v-model="newCommentContent"
              class="comments-textarea mt-3"
              :noResize="true"
              :rules="[v.isRequired(), v.maxChars(this.maxChars)]"
            ></PasTextarea>
            <PasButton
              class="primary-btn wide-btn"
              @click="submitComment"
              :processing="isProcessing"
            >
            Submit Comment
            </PasButton>
          </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { getDiscussionComments, createDiscussionComment } from 'Services/api/Discussion';
import moment from 'moment';
import api from 'Services/api';
import v from 'Services/validation';
import PasDiscussionComment from './DiscussionComment';

export default {
  name: 'PasDiscussion',
  components: {
    PasDiscussionComment,
  },
  data() {
    return {
      v,
      newCommentContent: '',
      comments: [],
      isProcessing: true,
    };
  },
  props: {
    categoryId: {
      type: Number,
    },
    clientId: {
      type: Number,
    },
    maxChars: {
      type: Number,
      default: 250,
    }
  },
  async mounted() {
    this.isProcessing = true;
    await this.getComments();
    this.isProcessing = false;
  },
  methods: {
    async submitComment() {
      if (!this.$refs.commentArea.validate()) return;
      const commentData = {
        client: this.clientId,
        category: this.categoryId,
        content: this.newCommentContent,
        commenter: this.$store.state.users.currentUser.platform_user_id,
      };
      this.$refs.commentArea.reset();
      this.isProcessing = true;
      await createDiscussionComment(commentData)
      this.newCommentContent = '';
      await this.getComments();
      this.isProcessing = false;
    },
    async getComments() {
      this.isProcessing = true;
      this.comments = await getDiscussionComments(this.categoryId, this.clientId, this.$store.dispatch);
      this.isProcessing = false;
    }
  },
  watch: {
    comments: function() {
      // scroll to bottom of discussion container
      this.$nextTick(function() {
        var container = this.$refs.discussionContainer;
        container.scrollTop = container.scrollHeight;
      });
    }
  },
};
</script>

<style lang="scss">
.discussion-container {
  max-height: 100%;
  height: 300px;
  word-wrap: break-word;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.discussion-container > :first-child {
  margin-top: auto !important;
}

</style>


