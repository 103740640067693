<template>
  <v-app id="app">
    <main>
      <PModal
        :visible="usingPurposeful"
        centered
        no-close-on-backdrop
        :hide-backdrop="false"
      >
        <h3>Sorry, you've been logged out!</h3>
        <p class="mb-2" v-if="isLogoutDueToIdle">
          To make sure client information is safe, you get logged out after
          being inactive for 30 minutes.
        </p>
        <p class="mb-2" v-if="isLogoutDueToUnauthorized">
          You're successfully authenticated, but it looks like you don't have
          access to this system yet. Please contact your administrator to get an
          invitation.
        </p>
        <PButton href="/advisor_portal/login">Login</PButton>
      </PModal>
    </main>
  </v-app>
</template>

<script>
// Services
import { mapGetters } from 'vuex';

export default {
  name: 'sso-logout',
  components: {},
  data() {
    return {
      loading: true,
      hasBeenIdleTooLong: false,
    };
  },
  computed: {
    ...mapGetters(['hasFeatureFlag']),
    onAuthRoute() {
      return this.$route.meta.requiresAuth !== false;
    },
    usingPurposeful() {
      return this.$route.meta.stylesheet === 'beta';
    },
    usingVuetify() {
      return this.$route.meta.stylesheet === 'old';
    },
    isLogoutDueToIdle() {
      const queryParam = this.$route.query;
      return queryParam && queryParam.logoutDueToIdle;
    },
    isLogoutDueToUnauthorized() {
      const queryParam = this.$route.query;
      return queryParam && queryParam.logoutDueToUnauthorized;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "~Styles/fonts.scss";
//global styling
#app {
  height: 100%;
}

.application--wrap {
  background-color: #fafafa;
}
</style>
