<template>
  <div>
    <PModal
      @hidden="closeModal"
      :visible="isOpen"
      :hide-backdrop="false"
      centered
    >
      <template v-if="currentState == STATES.edit">
        <font-awesome-icon :icon="['fal', 'edit']" />
        <div class="subtitle bold mb-4 mt-2">
          Edit the invite details
          <p v-if="inviteAccepted">
            <span class="text-secondary">
              Your client has already accepted the previous invitation.
              Sending a new invite will reset their registration and allow them to re-sign up on Investor Portal.
            </span>
          </p>
        </div>
        <div class="client-detail">
          <PForm ref="form">
            <PInputField
              v-for="(field, key) in clientFields"
              :key="key"
              :form-input-id="key"
              :label="field.label"
              :state="field.state"
              :error-text="field.errorText"
              :helper-text="field.helperText"
              :helper-text-id="field.helperTextId"
              v-model="field.value"
              size="lg"
              type="text"
              class="mb-2"
            />
          </PForm>
        </div>
      </template>
      <template v-else-if="currentState === STATES.done">
        <font-awesome-icon :icon="['fal', 'edit']" />
        <template v-if="processStatus.success">
          <div class="subtitle bold my-1">
            The invite has been sent.
          </div>
          <p class="mb-3">
            Your client has 14 days to set up their password and 2-step
            verification before the invite expires. You can send a new invite at
            any time.
          </p>
        </template>
        <template v-else>
          <div class="subtitle bold my-1">
            There was an error sending this invite. Please try again.
          </div>
          <p>
            {{ processStatus.msg }}
          </p>
        </template>
      </template>
      <PRow
        align-h="end"
        class="mt-3 px-2"
      >
        <PButton
          variant="tertiary"
          class="ml-2"
          size="lg"
          @click="closeModal"
        >
          {{ currentState === STATES.done ? 'Got it' : 'Close' }}
        </PButton>
        <PButton
          v-if="currentState !== STATES.done"
          :disabled="inviteLoading"
          type="submit"
          size="lg"
          variant="success"
          class="ml-2"
          @click="sendIPInvite"
        >
          Send
        </PButton>
      </PRow>
    </PModal>
  </div>
</template>

<script>
import { modal, cursorToEnd, formatPhoneNumber } from 'Services/mixins';
import { mapGetters } from 'vuex';
import { generateIPLink, resetClientIpRegistration } from 'Services/api/InvestorPortal';
import { createOnboardingIPWorkflow, fetchNotCancelledOnboardingForClient } from 'Services/api/Workflows';
import { CLIENT, IP_INVITATION_STATUSES } from 'Services/constants';
import v from 'Services/validation';

const STATES = {
  edit: 'EDIT',
  done: 'DONE',
};

export default {
  name: 'SendInvestorInvite',
  mixins: [modal, cursorToEnd, formatPhoneNumber],
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      STATES,
      IP_INVITATION_STATUSES,
      currentState: null,
      inviteLoading: false,
      isValid: true,
      processStatus: {
        success: false,
        msg: '',
      },
      fields: {
        firstName: {
          label: 'First Name',
          value: null,
          state: true,
          errorText: null,
          clientType: [CLIENT.TYPES.person],
          rules: [v.isRequired()],
        },
        lastName: {
          label: 'Last Name',
          value: null,
          state: true,
          errorText: null,
          clientType: [CLIENT.TYPES.person],
          rules: [v.isRequired()],
        },
        name: {
          label: 'Name',
          value: null,
          state: true,
          errorText: null,
          clientType: [
            CLIENT.TYPES.corporation,
            CLIENT.TYPES.trust,
            CLIENT.TYPES.foundation,
            CLIENT.TYPES.ipp,
            CLIENT.TYPES.nonprofit,
          ],
          rules: [v.isRequired()],
        },
        email: {
          label: 'Email',
          type: 'email',
          value: null,
          state: true,
          errorText: null,
          clientType: ['*'],
          rules: [v.isRequired(), v.isValidEmail],
        },
        phone: {
          label: 'Phone',
          value: null,
          state: true,
          errorText: null,
          clientType: ['*'],
          rules: [v.isRequired(), v.isValidPhone],
        },
      },
    };
  },
  mounted() {
    if (this.person) {
      this.fields.firstName.value = this.person.first_name;
      this.fields.lastName.value = this.person.last_name;
      this.fields.name.value = this.person.name;
    }
    this.fields.email.value = this.client.email || null;
    this.fields.phone.value = this.client[this.preferredNumber];
    this.currentState = STATES.edit;
    this.validateAll();
    // show error as helper text on start to not scare user off
    Object.keys(this.clientFields).forEach((field) => {
      this.clientFields[field].helperText = this.clientFields[field].errorText;
      this.clientFields[field].helperTextId = `${field}-helper-text`;
      this.clientFields[field].errorText = null;
      this.clientFields[field].state = null;
    });
  },
  computed: {
    ...mapGetters(['activeClient', 'clientId']),
    person() {
      return this.activeClient.person;
    },
    client() {
      return this.activeClient.client;
    },
    accounts() {
      return this.activeClient.investment_accounts;
    },
    clientType() {
      return this.activeClient.client.client_type;
    },
    displayName() {
      return this.client.display_name;
    },
    preferredNumber() {
      switch (this.client.preferred_number) {
        case 'O':
          return 'office_number';
        case 'H':
          return 'home_number';
        default:
        case 'M':
          return 'mobile_number';
      }
    },
    clientFields() {
      return Object.keys(this.fields)
        .filter((k) => (this.fields[k].clientType.includes('*') || this.fields[k].clientType.includes(this.clientType)))
        .reduce((acc, key) => {
          acc[key] = this.fields[key];
          return acc;
        }, {});
    },
    inviteAccepted() {
      return this.activeClient
        ?.IPInviteStatus === IP_INVITATION_STATUSES.accepted;
    },
  },
  methods: {
    validate(field) {
      // clear any previous error
      field.state = true;
      field.errorText = null;

      field.rules.forEach((rule) => {
        if (rule(field.value) !== true) {
          field.state = false;
          field.errorText = rule(field.value);
        }
      });
      return field.state;
    },
    validateAll() {
      const valid = Object.keys(this.clientFields).reduce(
        (acc, key) => this.validate(this.clientFields[key]) && acc,
        true,
      );
      this.isValid = valid;
      return valid;
    },
    edit() {
      this.currentState = STATES.edit;
    },
    closeModal(event) {
      if (event) { event.preventDefault(); }
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.$router.push(`/clients/${this.$route.params.clientID}`);
    },
    async triggerOnboarding() {
      // get all client IDs, check the accounts for joint clients
      try {
        const jointClientIds = this.accounts.reduce(
          (acc, r) => ((r.joint_client_id || []).length
            ? [...acc, ...r.joint_client_id]
            : acc),
          [],
        );
        const clientIds = [this.client.id, ...jointClientIds];
        // if client has an onboarding workflow in datahub_new_worlflow_clients, do not start workflow
        const workflowsInfo = await fetchNotCancelledOnboardingForClient(clientIds[0]);
        if (workflowsInfo.totalCount === 0) {
          await createOnboardingIPWorkflow(clientIds);
        }
      } catch (err) {
        throw new TypeError(err);
      }
    },
    async updateClientDetails() {
      const data = {
        id: this.clientId,
        client_type: this.clientType,
        email: this.fields.email.value,
        [this.preferredNumber]: this.fields.phone.value,
      };
      if (this.clientType === CLIENT.TYPES.person) {
        data.first_name = this.fields.firstName.value;
        data.last_name = this.fields.lastName.value;
        data.display_name = `${this.fields.firstName.value} ${this.fields.lastName.value}`;
      } else {
        data.name = this.fields.name.value;
        data.display_name = this.fields.name.value;
      }

      const response = await this.$store.dispatch('updateClient', data);
      if (response?.id) {
        return true;
      }
      this.processStatus.msg = response;
      return false;
    },
    async sendIPInvite() {
      if (this.inviteLoading || !this.validateAll()) return;
      this.inviteLoading = true;
      if (this.inviteAccepted) {
        await resetClientIpRegistration({ client_id: this.clientId });
      }
      const updated = await this.updateClientDetails();
      if (updated) {
        const invitation = await generateIPLink({ client_id: this.clientId });
        if (invitation.message && invitation.message.toLowerCase() === 'success') {
          await this.$store.dispatch('setClientIPInvitationStatus', this.clientId);
          // trigger onboarding after sending invite successfully
          try {
            await this.triggerOnboarding();
          } catch (err) {
            this.processStatus.msg = 'Failed to launch workflow, please try again later.';
            this.currentState = STATES.done;
            return;
          }
          this.$store.dispatch('getWorkflows', this.client.id);
          this.processStatus.success = true;
          this.currentState = STATES.done;
        } else {
          this.processStatus.msg = invitation.message;
          this.currentState = STATES.done;
        }
        this.inviteLoading = false;
      }
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) { this.currentState = STATES.edit; }
    },
  },
};
</script>
