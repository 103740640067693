<template>
  <div>
    <PasPageHeader :path="`/clients/${clientId}`">
      {{clientDisplayName}}
    </PasPageHeader>
    <v-container class="tabs-page-view" v-if="clientId">
      <PasAccordion expanded>
        <template v-slot:panelTitle>
          <PasPageSubtitle>Shareholder Preferences</PasPageSubtitle>
        </template>
        <template v-slot:panelContent>
          <shareholder-pref-modal class="pt-4" noTitle />
        </template>
      </PasAccordion>
    </v-container>
    <v-container v-else>
      <PasCircularLoader />
    </v-container>
  </div>
</template>

<script>
import ShareholderPrefModal from 'Components/Shared/ShareholderPrefModal';
import { mapGetters } from 'vuex';

export default {
  name: 'ShareholderPreferences',
  components: {
    ShareholderPrefModal,
  },
  computed: {
    ...mapGetters(['activeClient']),
    clientId() {
      return this.$store.state.clients.activeClient?.client?.id;
    },
    clientDisplayName() {
      return this.activeClient?.client?.display_name;
    },
  },
};
</script>
