<template>
  <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step">
    <span v-if="step.status === 'needs_attention'">
      <ul>
        <li>
          <span @click.prevent="idModal = true" class="action-text">Upload W-8 Ben</span>
        </li>
      </ul>
    </span>
    <upload-document
      :workflowId="workflow.id"
      :isOpen="idModal"
      @close="idModal = false"
      @done="completeDocumentUpload(); idModal = false"
    />
  </WorkflowStep>
</template>

<script>
import { sendFatcaCheckSuccess } from 'Services/api/Workflows';
import { mapGetters } from 'vuex';
import WorkflowStep from './WorkflowStep';
import UploadDocument from './FatcaCheckStep/UploadDocument';

export default {
  name: 'FatcaCheckStep',
  components: { WorkflowStep, UploadDocument },
  props: {
    workflow: {
      type: Object,
    },
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      idModal: false,
    };
  },
  computed: {
    ...mapGetters(['activeClient']),
  },
  methods: {
    async completeDocumentUpload() {
      await sendFatcaCheckSuccess(this.workflow, this.activeClient.client.id);
      await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
      await this.$store.dispatch('fetchWorkflowV2Details', this.workflow.id);
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
</style>
