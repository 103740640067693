<template>
	<v-container>
		<template v-if="!(workflow) || currentStage === 0">
      <v-layout align-center class="loader-layout">
        <v-flex>
					<pas-circular-loader :loadingMessage="`It'll Just Be A Moment...`"/>
        </v-flex>
      </v-layout>
    </template>
	<template v-else>
	<div id="workflows">
		<v-layout row nowrap mb-3>
			<v-flex>
				<PasPageHeader :path="`/clients/${workflow.pas_client_ids[0]}`">
					{{workflowTitle}}
				</PasPageHeader>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex text-xs-left>
				<assign-to :workflow="workflow" @reassignSuccess="reassignSuccess"></assign-to>
			</v-flex>
		</v-layout>
		<div class="row">
			<v-card flat>
				<v-stepper @input="viewStep" :value="currentStage" non-linear class="pas-stepper">
					<v-stepper-header
						:class="{
							'pas-stepper-header': true,
							'mx-5': ['xs','sm', 'md'].includes(breakpoint),
							'wide-gutter': ['lg','xl'].includes(breakpoint)
						}"
					>
						<template v-for="(stage, index) in (workflow ? workflow.stages : [])">
							<v-hover :key="stage._id + 'step' + viewStage + stage.current_status">
							<div slot-scope="{ hover }">
								<div v-if="hover" class="stepper-tooltip">
									<div class="light-text">{{`Step ${index + 1}`}}</div>
									<div class="strong-text">{{stage.name}}</div>
								</div>
								<v-stepper-step
									editable
									edit-icon="$vuetify.icons.complete"
									:step="index + 1"
									:complete="stage.current_status === 'Completed'"
									:class="{'in-progress-step': stage.current_status === 'In Progress',
													'not-started': stage.current_status.toLowerCase() === 'Not Started'.toLowerCase() || stage.current_status.toLowerCase() === 'Not Required'.toLowerCase()}"
									:key="stage._id + 'stepper'"
									v-if="workflow.stages.length"
								></v-stepper-step>
							</div>
							</v-hover>
							<v-divider
								:key="stage._id + 'divider'"
								v-if="index + 1 !== workflow.stages.length"
								:class="{ 'divider-complete': stage.current_status === 'Completed'}"
							></v-divider>
						</template>
					</v-stepper-header>

					<v-stepper :key="'stage' + viewStage"
						:value="currentStep"
						class="always-visible-stepper"
						vertical

					>
						<template v-for="(step, index) in (currentSteps ? currentSteps : [])">
							<v-stepper-step
								:rules="[() => step.current_status !== 'Needs Attention']"
								:step="index + 1"
								:key="step._id + 'step' + viewStage"
								:complete="step.current_status === 'Completed'"
								:class="{'clickable': true,
												'blurred': isEnabledStep(step)}"
								@click="selectStep(index + 1, step.current_status)"
							>
								{{step.step_name}}
								<small>
									<v-chip label small :color="primaryColor" text-color="white">
										{{getStepStatusLabel(step)}}
									</v-chip>
								</small>
							</v-stepper-step>

							<v-stepper-content
								:step="index + 1"
								:key="step._id + 'content' + viewStage"
								:class="{'no-gutter-padding': ['xs', 'sm'].includes(breakpoint)}"
							>
								<v-layout row nowrap>
									<v-flex xs5>
										<div class="form-label strong-text mb-3">
											{{workflow.stages[viewStage - 1].steps[index].step_name}}
										</div>
										<div>
											{{workflow.stages[viewStage - 1].steps[index].step_description}}
										</div>
									</v-flex>
									<v-flex xs2>
									</v-flex>
									<v-flex xs5>
										<v-layout fill-height align-end>
											<v-flex>
												<v-layout child-flex>
													<div>
														<pas-status-bullet
															v-if="statusBulletMap[`${step.current_status}`.toLowerCase().replace(' ', '_')]"
															:color="statusBulletMap[`${step.current_status}`.toLowerCase().replace(' ', '_')].color"
															:value="100"
														>
														</pas-status-bullet>
														<span class="form-label strong-text mb-3">{{getStepStatusLabel(step)}}</span>
														<span v-if="step.current_status === 'Completed' || step.current_status === 'Not Required'" class="p-text">
															<span class="mini vertical-align">&bull;</span>&nbsp;{{moment(step.date_completed).format('D MMMM YYYY, hA').toLocaleString()}}
														</span>
														<br>
														<span v-if="step.step_name === 'Receive Account Number' && step.current_status === 'Completed'">
															<span
																@click.prevent="confirmAccountDialog = true"
																class="action-text"
																:style="{margin: '0px 28px'}"
															>Edit account number</span>
														</span>
														<span v-if="step.step_name === 'Equifax Validation' && step.current_status === 'Completed'">
															<span @click.prevent="idModal = true; idModalCompleteStep = false" class="action-text" :style="{margin: '0px 28px'}"
															>upload ID</span>
														</span>
													</div>
												</v-layout>
												<v-layout>
													<v-flex v-if="shouldShowStepDetails(step)">
														<p class="workflow-step-info">
															<span v-if="step.step_name === 'Receive Account Number'">
																	Please
																<span @click.prevent="confirmAccountDialog = true" class="action-text">
																	confirm account number
																</span>
															</span>
															<span v-else-if="step.step_name === 'Generate Documents'">
																<span v-if="(!workflow.advisor_approved && !workflow.compliance_approved) && complianceFeatureEnabled">
																	<span v-if="(workflow.compliance_trail.length)">
																		Your documents have been rejected by the compliance officers, please review and re-approve
																	</span>
																	<span v-if="(!workflow.compliance_trail.length)">Review docs then submit to compliance</span>
																	<br>
																	<span @click.prevent="previewDocs" class="action-text">review docs</span>
																	<span> or </span>
																	<span @click.prevent="updateAdvisorApproved" class="action-text">Approve</span>
																</span>
																<span v-if="(workflow.advisor_approved && !workflow.compliance_approved) && complianceFeatureEnabled">
																	<span>FOR COMPLIANCE ONLY:</span><br>
																	<span @click.prevent="previewDocs" class="action-text">review docs</span>
																	<span> or </span>
																	<span @click.prevent="updateComplianceApproved(true)" class="action-text">Approve</span>
																	<span> / </span>
																	<span @click.prevent="updateComplianceApproved(false)" class="action-text">Reject</span>
																</span>
																<span v-if="(workflow.advisor_approved && workflow.compliance_approved) || !complianceFeatureEnabled">
																	<span @click.prevent="previewDocs" class="action-text">preview docs</span>
																	<span> or </span>
																	<span @click.prevent="sendToClient" class="action-text">send to client</span>
																</span>
															</span>
															<span v-else-if="step.step_name === 'Document Signature by Client' || step.step_name === 'Document signature by Advisor'
																		|| step.step_name === 'Client to Sign Documents' || step.step_name === 'Advisor to Sign Documents'">
																<span @click.prevent="completeStep()" class="action-text">Mark as complete</span>
																or
																<span @click.prevent="regenerateDocs" class="action-text">Regenerate Docs</span>
															</span>
															<span v-else-if="step.step_name === 'Equifax Validation'">
																<ul>
																	<li v-if="!equifaxDisabled"><span @click.prevent="rerunEquifax" :class="['action-text']">Run Equifax</span></li>
																	<v-tooltip top v-else>
																		<li slot="activator"><span @click.prevent="rerunEquifax" :class="['action-text','no-pointer']">Run Equifax</span></li>
																		<span>Maximum of 2 equifax runs has been hit</span>
																	</v-tooltip>
																	<li><span @click.prevent="completeEquifax" class="action-text">Complete Manually</span>
																	</li>
																	<li><span @click.prevent="idModal = true; idModalCompleteStep = true" class="action-text">upload ID</span></li>
																</ul>
															</span>

															<span v-else-if="step.step_name === 'AML Check' && step.current_status === 'Needs Attention'">
																<span>FOR COMPLIANCE ONLY:</span><br>
																<span @click.prevent="completeAMLCheck" class="action-text">Complete Manually</span>
															</span>
															<span v-else-if="step.step_name === 'AML Check'">
																<span>We are currently performing World-Check Customer Risk Screening</span>
															</span>

															<span v-else-if="step.step_name === 'Complete Account Opening Questionnaire' && (workflow.type === WORKFLOW.TYPES.supplemental || workflow.type === WORKFLOW.TYPES.supplemental_corporate_onboarding)">
																<span @click.prevent="!missingPersonalInfo.length ? completeStep({ stage: 1, step: 2 }) : missingPersonInfoDialog = true" class="action-text">Mark as complete</span>
																or
																<span @click.prevent="launchSurvey" class="action-text">Launch Survey</span>
															</span>
															<span v-else-if="step.step_name === 'Gather Financial Details'
																			&& (workflow.type === WORKFLOW.TYPES.supplemental
																			|| workflow.type === WORKFLOW.TYPES.corporate_onboarding)">
																<span @click.prevent="completeStep({ stage: 1, step: workflow.type === WORKFLOW.TYPES.corporate_onboarding ? 3 : 1 })" class="action-text">Mark as complete</span>
																or
																<span @click.prevent="financialDetailsModal = true" class="action-text">Provide Financial Details</span>
															</span>
															<span v-else-if="step.step_name === 'Gather Corporation Details'
																			&& workflow.type === WORKFLOW.TYPES.corporate_onboarding">
																<span @click.prevent="completeStep({ stage: 1, step: 2 })" class="action-text">Mark as complete</span>
																or
																<span @click.prevent="clientDetailsModal = true" class="action-text">Provide Corporation Details</span>
															</span>
															<span v-else-if="step.step_name === 'Gather Bank Account Details'
																			&& workflow.type === WORKFLOW.TYPES.corporate_onboarding">
																<span @click.prevent="completeStep({ stage: 1, step: 4 })" class="action-text">Mark as complete</span>
																or
																<span @click.prevent="openBankAccount" class="action-text">Provide Bank Account Details</span>
															</span>
                              <span v-else-if="step.step_name === 'Risk Assessment Questionnaire' && !(step.current_status==='Completed')">This step is in progress -
																<span @click.prevent="!missingRiskScores.length ? completeStep() : missingRiskDialog = true" class="action-text">mark as complete.</span>
															</span>
                              <span v-else-if="step.step_name === 'Account Opening Questionnaire' && !(step.current_status==='Completed')">This step is in progress -
																<span @click.prevent="!missingPersonalInfo.length ? completeStep() : missingPersonInfoDialog = true" class="action-text">mark as complete.</span>
															</span>
															<span v-else-if="!(step.current_status==='Completed')">This step is in progress -
																<span @click.prevent="completeStep()" class="action-text">mark as complete.</span>
															</span>
														</p>
														<div
															v-if="workflow.type === WORKFLOW.TYPES.corporate_onboarding && step.step_name === 'Gather Supplemental Information'"
															class="workflow-step-info"
														>
															<div v-for="(supplementalWorkflow, i) in (supplementalWorkflowDetails)" :key="`supp_workflow-${i}`">
																<router-link
																	:to="`/workflow/${supplementalWorkflow._id}`"
																	target="_blank"
																	:style="{ color: 'var(--primary-color) !important' }"
																>
																	Supplemental workflow for {{supplementalWorkflow.display_name}} {{getStatus(supplementalWorkflow.stages)}}
																</router-link>
															</div>
														</div>
														<div
															v-if="workflow.type === WORKFLOW.TYPES.client_onboarding && step.step_name === 'Gather Supplemental Information'"
															class="workflow-step-info"
														>
															<div v-for="(supplementalWorkflow, i) in (supplementalWorkflowDetails)" :key="`supp_workflow-${i}`">
																<router-link
																	:to="`/workflow/${supplementalWorkflow._id}`"
																	target="_blank"
																	:style="{ color: 'var(--primary-color) !important' }"
																>
																	Supplemental workflow for {{supplementalWorkflow.display_name}} {{getStatus(supplementalWorkflow.stages)}}
																</router-link>
															</div>
														</div>
													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
							</v-stepper-content>
						</template>
					</v-stepper>
				</v-stepper>
				<v-layout mb-5 pb-5 row :wrap="['xs','sm','md'].includes(breakpoint)">
					<v-flex xs12 md10 lg6>
						<workflow-view-discussion-feed :workflow="workflow"></workflow-view-discussion-feed>
					</v-flex>
					<v-flex xs12 md10 lg6 :mt-5="['xs','sm','md'].includes(breakpoint)">
						<workflow-view-documents :workflow="workflow"></workflow-view-documents>
					</v-flex>
				</v-layout>
			</v-card>
		</div>

	</div>
	</template>
	<pas-fullscreen-modal
		:isOpen="launchSurveyScreen"
		@close="closeSurveyScreen"
	>
	 	<v-container class="accounts-container">
		<trigger-onboarding
			:surveyOptionsLoaded="surveyOptionsLoaded"
			:onboardingSetupLoading="onboardingSetupLoading"
			@triggerInPerson="triggerInPerson"
      		@triggerByEmail="triggerByEmail"
		></trigger-onboarding>
		</v-container>
	</pas-fullscreen-modal>
		<confirm-account-modal
			:isOpen="confirmAccountDialog"
			@done="completeStep()"
			@close="confirmAccountDialog = false"
			:workflow="workflow"
		></confirm-account-modal>
		<upload-id
			:workflowId="workflowId"
			:isOpen="idModal"
			@close="idModal = false"
			@done="completeStep(); idModal = false"
		></upload-id>
		<!-- Financial Details modal -->
		<v-dialog
			max-width="1200px"
			v-model="financialDetailsModal"
			persistent
		>
			<v-card class="pa-5">
				<pas-fab class="close-btn">
					<v-icon @click="financialDetailsModal = false" class="close-icon">close</v-icon>
				</pas-fab>
				<financial-details-modal
					ref="financialDetails"
					@done="financialDetailsModalResponses.financials = $event"
					:modalMode="true"
				></financial-details-modal>
				<shareholder-pref-modal v-if="workflow.type === WORKFLOW.TYPES.corporate_onboarding"
					ref="shareholderPref"
					@done="financialDetailsModalResponses.shareholder_pref = $event"
					:modalMode="true"
				></shareholder-pref-modal>
				<pas-button
					class="primary-btn wide-btn"
					@click="saveFinancialDetails"
				>Save</pas-button>
			</v-card>
		</v-dialog>
		<!-- Client Details modal -->
		<v-dialog
			max-width="1200px"
			v-model="clientDetailsModal"
			persistent
		>
			<v-card class="pa-5">
				<pas-fab class="close-btn">
					<v-icon @click="clientDetailsModal = false" class="close-icon">close</v-icon>
				</pas-fab>
				<pas-page-subtitle class="mb-1">Corporation Details</pas-page-subtitle>
				<client-details-modal
					ref="clientDetails"
					@done="clientDetailsModalReponses.details = $event"
					:modalMode="true"
				></client-details-modal>
				<addresses
					ref="clientAddresses"
				 	:client="activeClient.client"
          :_addresses="activeClient.addresses"
					:modalMode="true"
					@done="clientDetailsModalReponses.addresses = $event"
				></addresses>
				<pas-button
					class="primary-btn wide-btn"
					@click="saveClientDetails"
				>Save</pas-button>
			</v-card>
		</v-dialog>

		<!-- Compliance dialog: documents-->
		<v-dialog max-width="450px" v-model="complianceDialog" persistent>
			<v-card class="pa-5">
				<v-layout column>
					<pas-page-subtitle>Compliance Approval</pas-page-subtitle>
					<div class="my-3">Only members of the compliance team can approve</div>
					<v-layout>
						<v-spacer></v-spacer>
						<pas-button
							class="primary-btn mt-3"
							@click="complianceDialog = false"
						>OK</pas-button>
					</v-layout>
				</v-layout>
			</v-card>
		</v-dialog>
    <v-dialog max-width="40%" v-model="missingRiskDialog" persistent>
			<v-card class="pa-5">
				<v-layout column>
					<pas-page-subtitle>Missing {{activeClient.person.first_name}}'s Risk Scores</pas-page-subtitle>
					<div class="my-3">Please ensure {{activeClient.person.first_name}} has completed the Risk Assessment Questionnaire before proceeding.
            At the moment, the following required information is missing:</div>
          <li
            v-for="missingScore in missingRiskScores"
            :key="missingScore"
          >{{missingScore}}</li>
					<v-layout>
						<v-spacer></v-spacer>
						<pas-button
							class="primary-btn mt-3"
							@click="missingRiskDialog = false"
						>OK</pas-button>
					</v-layout>
				</v-layout>
			</v-card>
		</v-dialog>
    <v-dialog max-width="40%" v-model="missingPersonInfoDialog" persistent>
			<v-card class="pa-5">
				<v-layout column>
					<pas-page-subtitle>Missing {{activeClient.person.first_name}}'s Personal Information</pas-page-subtitle>
					<div class="my-3">Please ensure {{activeClient.person.first_name}} has completed the Account Opening Questionnaire before proceeding.
            At the moment, the following required information is missing:</div>
          <li
            v-for="missingInfo in missingPersonalInfo"
            :key="missingInfo"
          >{{missingInfo}}</li>
					<v-layout>
						<v-spacer></v-spacer>
						<pas-button
							class="primary-btn mt-3"
							@click="missingPersonInfoDialog = false"
						>OK</pas-button>
					</v-layout>
				</v-layout>
			</v-card>
		</v-dialog>

		<!-- Compliance dialog: AML Check-->
		<CompleteAMLCheck
			:isOpen="completeAMLCheckDialog"
			@close="completeAMLCheckDialog = false"
			@submit="manualAMLCheck($event); completeAMLCheckDialog = false"
		/>

	</v-container>
</template>

<script>

import moment from 'moment';
import api from 'Services/api';
import { WORKFLOW, EQUIFAX } from 'Services/constants';
import Addresses from 'Components/Shared/Addresses';
import { launchOnboarding } from 'Services/helpers/onboarding';
import FinancialDetailsModal from 'Components/Shared/FinancialDetailsModal';
import ShareholderPrefModal from 'Components/Shared/ShareholderPrefModal';
import ClientDetailsModal from 'Components/Shared/EditDetailsModal';
import TriggerOnboarding from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu/AddInvestmentAccounts/TriggerOnboarding';
import babelPolyfill from '@babel/polyfill';
import _ from 'lodash';
import WorkflowViewDiscussionFeed from './WorkflowViewDiscussionFeed';
import WorkflowViewDocuments from './WorkflowViewDocuments';
import UploadId from './UploadId';
import ConfirmAccountModal from './ConfirmAccountModal';
import GenerateDocuments from './GenerateDocuments';
import confirmAccount from './ConfirmAccount';
import idVerification from './IdVerification';
import AssignTo from './AssignTo';
import CompleteAMLCheck from './WorkflowView/CompleteAMLCheck';

export default {
  name: 'WorkflowView',
  components: {
    WorkflowViewDiscussionFeed,
    WorkflowViewDocuments,
    FinancialDetailsModal,
    ShareholderPrefModal,
    ConfirmAccountModal,
    UploadId,
    GenerateDocuments,
    TriggerOnboarding,
    ClientDetailsModal,
    idVerification,
    confirmAccount,
    Addresses,
    CompleteAMLCheck,
    AssignTo,
  },
  async mounted() {
    this.workflowID = this.$route.params.workflowID;
    await this.$store.dispatch('fetchWorkflow', this.workflowID);
    const lastStage = _.last(this.workflow.stages);
    const lastStep = _.last(lastStage.steps);
    if (this.workflow.status === 'Completed' || lastStep.current_status === 'Completed') {
      this.currentStage = 1;
      this.currentStep = 1;
      this.viewStage = 1;
    } else {
      // Set stage based on Workflow progress
      const stage = this.workflow.stages.findIndex((stage) => stage.current_status !== 'Completed');
      const step = this.workflow.stages[stage].steps.findIndex((step) => step.current_status !== 'Completed');
      this.currentStage = stage + 1;
      this.currentStep = step + 1;
      this.viewStage = this.currentStage;
    }
    if (this.$socket) {
      this.$socket.on(`equifax-update-${this.workflowID}`, (data) => {
        if (data.status === 'success') {
          this.onEquifaxSuccess();
        } else if (data.status === 'fail') {
          this.onEquifaxFail();
        }
      });
      // keep the code for now
      // this.$socket.on(`aml-update-${this.workflowID}`, (data) => {
      //   const text = `AML check ${data.status ? 'passed' : 'failed'}`;
      //   const type = data.status ? 'success' : 'failed';
      //   this.$store.dispatch('setSnackbar', { text, type });
      //   this.$store.dispatch('flipSnackbarSwitch');
      //   await this.$store.dispatch('fetchWorkflow', this.workflowID);
      // });
    }
    await this.$store.dispatch('fetchClientDetails', this.workflow.pas_client_ids[0]);
    this.fetchWorkflowConditionalData();
  },
  data() {
    const that = this;
    return {
      WORKFLOW,
      show: false,
      statusBulletMap: {
        completed: {
          color: 'green',
          value: 100,
        },
        needs_attention: {
          color: 'red',
          value: 100,
        },
        not_started: {
          color: 'red',
          value: 100,
        },
        in_progress: {
          color: 'yellow',
          value: 100,
        },
        cancelled: {
          color: 'green',
          value: 100,
        },
        not_required: {
          color: 'red',
          value: 100,
        },
        pre_update_approval: {
          color: 'green',
          value: 100,
        },
      },
      statusLabelMap: {
        completed: 'Completed',
        needs_attention: 'Needs Attention',
        not_started: 'Not Started',
        in_progress: 'In Progress',
        cancelled: 'Cancelled',
        not_required: 'Not Required',
        pre_update_approval: 'Completed',
      },
      equifaxResults: ['loading'],
      confirmAccountDialog: false,
      idModal: false,
      currentStage: 0,
      currentStep: 0,
      clientCheck: false,
      viewStage: 0,
      newNote: '',
      idModalCompleteStep: true,
      launchSurveyScreen: false,
      surveyOptionsLoaded: false,
      onboardingSetupLoading: false,
      supplementalWorkflowDetails: [],
      financialDetailsModal: false,
      financialDetailsModalResponses: { financials: null, shareholder_pref: null },
      clientDetailsModal: false,
      clientDetailsModalReponses: { details: null, addresses: null },
      equifaxDisabled: false,
      complianceDialog: false,
      missingRiskDialog: false,
      missingPersonInfoDialog: false,
      completeAMLCheckDialog: false,
    };
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    complianceFeatureEnabled() {
      return this.$store.state.app.features.compliance_check;
    },
    router() {
      return this.$router;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    workflow() {
      return this.$store.state.workflow.selectedWorkflow;
    },
    workflowId() {
      return (this.workflow ? this.workflow._id : null);
    },
    currentSteps() {
      if (this.viewStage === 0 || this.currentStage === 0) return []; // haven't been set yet
      const stage = this.isBrowsing ? this.viewStage : this.currentStage;
      return this.workflow ? this.workflow.stages[stage - 1].steps : [];
    },
    completedCurrentStage() {
      if (this.isBrowsing) { return false; }
      return this.workflow.stages[this.currentStage - 1].current_status === 'Completed';
    },
    completedCurrentStep() {
      if (this.isBrowsing) { return false; }
      return this.currentSteps[this.currentStep - 1].current_status === 'Completed';
    },
    completedLastStep() {
      return this.completedCurrentStep && this.onLastStep;
    },
    onLastStage() {
      return this.currentStage === this.workflow.stages.length;
    },
    onLastStep() {
      return this.currentStep === this.currentSteps.length;
    },
    isBrowsing() {
      if (this.workflow && this.workflow.status === 'Completed') {
        return true;
      }
      return this.viewStage !== this.currentStage;
    },
    documents() {
      const workflow = this.$store.state.workflow.selectedWorkflow;
      return workflow ? workflow.docs.map((x) => ({
        name: x.display_name,
        key: x.aws_info.Key,
        doc_type: x.doc_type,
      })) : [];
    },
    workflowTitle() {
      return this.validateClient ? `${WORKFLOW.DISPLAY_NAMES[this.$store.state.workflow.selectedWorkflow.type]} for ${this.activeClient.client.display_name}` : '';
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    validateClient() { // returns false when page is first loaded, true once activeClient is fully updated
      const activeClient = this.activeClient.client;
      if (this.clientCheck && activeClient) return true;
      this.clientCheck = true;
      return false;
    },
    activeClientID() {
      return this.activeClient && this.activeClient.client ? this.activeClient.client.id : '';
    },
    notes() {
      const workflow = this.$store.state.workflow.selectedWorkflow;
      return workflow ? workflow.notes.reverse() : [];
    },
    tempAccounts() {
      if (!this.activeClient.investment_accounts.length) return [];
      		return this.activeClient.investment_accounts.filter((a) => a.account_status === 'T');
    },
    equifaxFail() {
      return [EQUIFAX.VERIFICATION_METHODS.NF, EQUIFAX.VERIFICATION_METHODS.NV].includes(this.equifaxResults[0].verification_method);
    },
    currentUserDatahub() {
      return this.$store.state.users.currentUserDatahub;
    },
    missingRiskScores() {
      const missingRiskInfo = [];
      if (!this.activeClient.riskScores.perception) missingRiskInfo.push('Risk Perception');
      if (!this.activeClient.riskScores.tolerance) missingRiskInfo.push('Risk Tolerance');
      if (!this.activeClient.riskScores.capacity) missingRiskInfo.push('Risk Capacity');
      if (!this.activeClient.riskScores.knowledge) missingRiskInfo.push('Risk Knowledge');
      if (!this.activeClient.riskScores.required_return) missingRiskInfo.push('Required Return');
      if (!this.activeClient.riskScores.overall) missingRiskInfo.push('Overall Risk Score');
      return missingRiskInfo;
    },
    missingPersonalInfo() {
      const missingPersonalInfo = [];
      if (!this.activeClient.person.SIN) missingPersonalInfo.push('Social Insurance Number');
      if (!this.activeClient.person.country_of_birth) missingPersonalInfo.push('Country of Birth');
      if (this.activeClient.person.dependents == null) missingPersonalInfo.push('Number of Dependents');
      if (this.activeClient.person.marital_status === 'unknown') missingPersonalInfo.push('Marital Status');
      if (!this.activeClient.person.date_of_birth) missingPersonalInfo.push('Date of Birth');
      if (!this.activeClient.addresses.length) missingPersonalInfo.push('Address Information');
      if (!this.activeClient.employment.id) missingPersonalInfo.push('Employment Information');
      if (!this.activeClient.fundsSources.id) missingPersonalInfo.push('Source of Funds');
      if (!(this.activeClient.person.is_canadian || this.activeClient.person.is_us_citizen)) {
        missingPersonalInfo.push('Citizenship');
      }
      return missingPersonalInfo;
    },
  },
  watch: {
    currentStep(val) {
      const workflowTypeList = ['account_opening', 'bank_account_setup', 'systematic_setup', 'ips_signing', 'eft_setup'];
      if (
        (val === 4 && this.currentStage === 2 && this.workflow.type === 'client_onboarding')
				|| (val === 4 && this.currentStage === 1 && workflowTypeList.includes(this.workflow.type))
				|| (val === 5 && this.currentStage === 1 && this.workflow.type === 'corporate_onboarding')
      ) {
        const updateWorkflowData = {
          workflow: this.workflowID,
          action: 'complete_step',
          stage: this.currentStage,
          step: this.currentStep,
        };
        this.completeStepStore(updateWorkflowData);

        Promise.all(
          this.tempAccounts.map((account) => this.$store.dispatch('updateAccount', {
            id: account.id,
            primary_client_id: this.activeClient.client.id,
            account_status: 'P',
          })),
        ).then((responses) => responses)
          .catch((err) => err);
      }
    },
    financialDetailsModalResponses: {
      deep: true,
      handler() {
        const corporate_onboarding = this.workflow.type === this.WORKFLOW.TYPES.corporate_onboarding;
        if (this.financialDetailsModalResponses.financials === null || (corporate_onboarding && this.financialDetailsModalResponses.shareholder_pref === null)) {
          return false;
        }
        let type = 'fail';
        if (this.financialDetailsModalResponses.financials === 'success' && (!corporate_onboarding || this.financialDetailsModalResponses.shareholder_pref === 'success')) {
          type = 'success';
          this.completeStep({ stage: 1, step: corporate_onboarding ? 3 : 1 });
        }
        const text = `${type === 'fail' ? 'Failed to save' : 'Saved'} Financial Details`;
        this.$store.dispatch('setSnackbar', { text, type });
        this.$store.dispatch('flipSnackbarSwitch');
        this.financialDetailsModal = false;
        this.financialDetailsModalResponses = { financials: null, shareholder_pref: null };
      },
    },
    clientDetailsModalReponses: {
      deep: true,
      handler() {
        if (this.clientDetailsModalReponses.details === null || this.clientDetailsModalReponses.addresses === null) {
          return false;
        }
        let type = 'fail';
        if (this.clientDetailsModalReponses.details === 'success' && this.clientDetailsModalReponses.addresses === 'success') {
          type = 'success';
          this.completeStep({ stage: 1, step: 2 });
        }
        const text = `${type === 'fail' ? 'Failed to save' : 'Saved'} Corporation Details`;
        this.$store.dispatch('setSnackbar', { text, type });
        this.$store.dispatch('flipSnackbarSwitch');
        this.clientDetailsModal = false;
        this.clientDetailsModalReponses = { details: null, addresses: null };
      },
    },
  },
  methods: {
    async onEquifaxFail() {
      this.$store.dispatch('setSnackbar', { text: 'Error running equifax', type: 'error' });
      this.$store.dispatch('flipSnackbarSwitch');
      await this.$store.dispatch('fetchWorkflow', this.workflowID);
      this.fetchWorkflowConditionalData();
    },
    async onEquifaxSuccess() {
      this.$store.dispatch('setSnackbar', { text: 'Equifax passed', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      await this.$store.dispatch('fetchWorkflow', this.workflowID);
      this.fetchWorkflowConditionalData();
    },
    async fetchWorkflowConditionalData() {
      if ([WORKFLOW.TYPES.supplemental, WORKFLOW.TYPES.client_onboarding].includes(this.workflow.type)) {
        const is_allowed_response = await api.get(`/advisor_portal/equifax/is_run_allowed/?client_id=${this.activeClient.client.id}`);
        if (is_allowed_response.is_allowed) {
          this.equifaxDisabled = false;
        } else this.equifaxDisabled = true;
        await api.get(`/datahub/equifax_results/?client_id=${this.activeClient.client.id}`)
          .then((res) => {
            if (!res.results) {
              this.$store.dispatch('setSnackbar', { text: 'Error retrieving equifax results', type: 'error' });
              this.$store.dispatch('flipSnackbarSwitch');
            } else {
              this.equifaxResults = res.results.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
            }
          })
          .catch((err) => {
            this.$store.dispatch('setSnackbar', { text: 'Error retrieving equifax results', type: 'error' });
            this.$store.dispatch('flipSnackbarSwitch');
          });
      }
      if ([WORKFLOW.TYPES.corporate_onboarding, WORKFLOW.TYPES.client_onboarding].includes(this.workflow.type)) {
        try {
          if (!this.workflow.workflow_specific_data.child_workflows) return null;
          const query = this.workflow.workflow_specific_data.child_workflows.join(',');
          const res = await api.get(`/advisor_portal/api/workflows?ids=${query}`);
          if (!res.workflows) throw Error;
          this.supplementalWorkflowDetails = res.workflows;
        } catch (err) {
          this.$store.dispatch('setSnackbar', { text: 'Error retrieving supplemental workflows', type: 'error' });
          this.$store.dispatch('flipSnackbarSwitch');
        }
      }
    },
    getStepStatusLabel(step) {
      if (step.step_name === 'Equifax Validation' && step.current_status === 'Needs Attention') return 'Failed';
      return this.statusLabelMap[step.current_status.toLowerCase().replace(' ', '_')];
    },
    async saveFinancialDetails() {
      await this.$refs.financialDetails.saveFinancialProfile();
      if (this.workflow.type === this.WORKFLOW.TYPES.corporate_onboarding) await this.$refs.shareholderPref.savePreferences();
    },
    saveClientDetails() {
      this.$refs.clientDetails.save();
      this.$refs.clientAddresses.saveAddress();
    },
    getStatus(stages) {
      if (stages === 'Complete Documentation') return 'is ready to generate documents';
      if (stages === 'Collect Information') return 'requires additional information';
      if (stages === 'Completed') return 'is completed';
    },
    shouldShowStepDetails(step) {
      if (step.current_status === 'In Progress' || step.current_status === 'Needs Attention') return true;
      if (step.current_status === 'Completed' && this.workflow.type === WORKFLOW.TYPES.corporate_onboarding && step.step_name === 'Gather Supplemental Information') return true;
    },
    async triggerInPerson() {
      this.onboardingSetupLoading = true;
      await launchOnboarding([], this.activeClient.client, WORKFLOW.TYPES.supplemental, 'inPerson', this.workflowId);
      this.onboardingSetupLoading = false;
      this.closeSurveyScreen();
    },
    async triggerByEmail() {
      this.onboardingSetupLoading = true;
      await launchOnboarding([], this.activeClient.client, WORKFLOW.TYPES.supplemental, 'byEmail', this.workflowId);
      this.onboardingSetupLoading = false;
      this.closeSurveyScreen();
    },
    launchSurvey() {
      this.launchSurveyScreen = true;
      setTimeout(() => {
        this.surveyOptionsLoaded = true;
      }, 250);
    },
    closeSurveyScreen() {
      this.launchSurveyScreen = false;
      this.surveyOptionsLoaded = false;
    },
    isEnabledStep(step) {
      return step.current_status !== 'In Progress' && step.current_status !== 'Needs Attention';
    },
    decrementStage() {
      this.currentStage--;
      this.currentStep = this.currentSteps.length;
    },
    incrementStage() {
      if (this.onLastStage) {
        this.$router.back();
      }
      this.currentStage++;
      this.currentStep = 1;
    },
    decrementStep() {
      this.currentStep--;
    },
    incrementStep() {
      this.currentStep++;
    },
    selectStep(step, status) {
      this.currentStep = step;
    },
    viewStep(stage) {
      this.viewStage = stage;
      this.currentStep = 1;
      if (this.viewStage === this.currentStage) {
        this.currentStep = 1 + this.workflow.stages[stage - 1].steps.findIndex((step) => step.current_status !== 'Completed');
      }
    },
    addNote(e) {
      if (this.newNote) {
        api.post('/advisor_portal/workflow/add_workflow_note', {
          content: this.newNote,
          workflow: this.workflow._id,
        }).then((res) => {
          const oldWorkflowId = this.workflow._id;
          this.$store.dispatch('fetchWorkflow', this.workflow._id);
        });
        this.newNote = '';
      }
    },
    reassignSuccess() {
      this.$store.dispatch('setSnackbar', { text: 'Workflow successfully reassigned', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      this.$router.push('/');
    },
    openBankAccount() {
      this.$store.commit('OPEN_MODAL', 'add-bank-account');
    },
    async completeStep(location = null, docusign = true) {
      if (!docusign) {
        this.$store.dispatch('setSnackbar', { text: 'Generating documents...', type: 'success' });
        this.$store.dispatch('flipSnackbarSwitch');
        await this.updateDocusign(docusign);
        await this.redoStep();
      }

      const updateWorkflowData = {
        workflow: this.workflowID,
        action: 'complete_step',
        // location - allows for passing custom { stage, step } #s rather than using this.stage, this.step
        stage: location ? location.stage : this.currentStage,
        step: location ? location.step : this.currentStep,
      };

      this.completeStepStore(updateWorkflowData);
    },
    async completeEquifax() {
      const data = {
        client_id: this.activeClient.client.id,
        status: 'M',
      };
      const equifaxRes = await this.$store.dispatch('verifyEquifaxManually', data);
      if (equifaxRes.status !== 'success') return this.snackBar('Error updating workflow', 'fail');
      const updateWorkflowData = {
        workflow: this.workflowID,
        action: 'complete_step',
        stage: this.currentStage,
        step: this.currentStep,
      };
      this.completeStepStore(updateWorkflowData);
    },
    completeStepStore(data) {
      this.$store.dispatch('updateWorkflow', data)
        .then((res) => {
          if (res.error) return this.snackBar('Error updating workflow', 'fail');
          this.$store.dispatch('fetchWorkflow', this.workflowID).then((res) => { this.$forceUpdate(); });
          if (this.onLastStep) this.incrementStage();
          else this.incrementStep();
          this.snackBar('Successfully updated workflow', 'success');
        })
        .catch((err) => this.snackBar('Error updating workflow', 'fail'));
    },
    snackBar(text, type) {
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async previewDocs() {
      if (this.workflow.type === WORKFLOW.TYPES.client_onboarding) {
        const assignedAdvisor = this.$store.getters.teamMembers.filter((tm) => tm.ac_mongo_id === this.workflow.assigned_advisor);
     		const u = await api.get(`/datahub/platform_users/${assignedAdvisor[0].id}/`);
        if (!u.address_line_1 || !u.city || !u.province || !u.postal_code || !u.country) {
          const text = 'Unable to generate documents: advisor assigned to this workflow is missing address';
          this.$store.dispatch('setSnackbar', { text, type: 'fail' });
          this.$store.dispatch('flipSnackbarSwitch');
          return;
        }
      }
      this.$store.dispatch('setSnackbar', { text: 'Generating document preview...', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      this.$store.dispatch('updateWorkflow', {
        workflow: this.workflowID,
        action: 'preview_documents',
      });
    },
    async sendToClient() {
      this.$store.dispatch('setSnackbar', { text: 'Generating documents...', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      this.$store.dispatch('updateWorkflow', {
        workflow: this.workflowID,
        action: 'send_to_client',
      });
      await this.$store.dispatch('fetchWorkflow', this.workflowID)
        .then((res) => this.$forceUpdate());
    },
    async completeAMLCheck() {
      const { compliance_check } = await api.get('/advisor_portal/compliance_check/');
      if (!compliance_check) return this.complianceDialog = true;
      this.completeAMLCheckDialog = true;
    },
    async manualAMLCheck(approvalDetails) {
      const data = {
        workflow: this.workflowID,
        approval_details: approvalDetails,
        action: 'complete_aml_manually',
      };
      await this.$store.dispatch('updateWorkflow', data);
      this.$store.dispatch('setSnackbar', { text: 'Updating the workflow', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      this.$store.dispatch('fetchWorkflow', this.workflowID);
    },
    async updateComplianceApproved(passed_compliance) {
      if (this.loadingComplianceCheck) return;
      const { compliance_check } = await api.get('/advisor_portal/compliance_check/');
      if (!compliance_check) return this.complianceDialog = true;
      const data = {
        workflow: this.workflowID,
        compliance_trail: {
          compliance_officer: this.currentUserDatahub.id,
          passed_compliance,
          review_date: moment(),
        },
        action: 'switch_compliance_approved',
      };
      this.loadingComplianceCheck = true;
      await this.$store.dispatch('updateWorkflow', data);
      this.$store.dispatch('fetchWorkflow', this.workflowID);
      this.loadingComplianceCheck = false;
    },
    async updateAdvisorApproved() {
      if (this.loadingComplianceCheck) return;
      const data = {
        workflow: this.workflowID,
        advisor_approved: true,
        action: 'switch_advisor_approved',
      };
      this.loadingComplianceCheck = true;
      await this.$store.dispatch('updateWorkflow', data);
      this.$store.dispatch('fetchWorkflow', this.workflowID);
      this.loadingComplianceCheck = false;
    },
    redoStep({ stage, step } = {}) {
      const data = {
        workflow: this.workflow._id,
        action: 'redo_step',
        stage: stage ? stage - 1 : this.currentStage - 1,
        step: step ? step - 1 : this.currentStep - 1,
      };
      return this.$store.dispatch('updateWorkflow', data);
    },
    async rerunEquifax() {
      this.$store.dispatch('setSnackbar', { text: 'Rerunning equifax...', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      const is_allowed_response = await api.get(`/advisor_portal/equifax/is_run_allowed/?client_id=${this.activeClient.client.id}`);
      if (is_allowed_response.is_allowed) {
        await this.redoStep();
        this.$store.dispatch('fetchWorkflow', this.workflowID);
      } else {
        this.$store.dispatch('setSnackbar', { text: `Unable to rerun equifax: ${is_allowed_response.reason || 'Internal error processing request'}`, type: 'error' });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async rerunEquifax() {
      this.$store.dispatch('setSnackbar', { text: 'Rerunning equifax...', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      const is_allowed_response = await api.get(`/advisor_portal/equifax/is_run_allowed?client_id=${this.activeClient.client.id}`);
      if (is_allowed_response.is_allowed) {
        await this.redoStep();
        this.$store.dispatch('fetchWorkflow', this.workflowID);
      } else {
        this.$store.dispatch('setSnackbar', { text: `Unable to rerun equifax: ${is_allowed_response.reason}`, type: 'error' });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async regenerateDocs() {
      this.$store.dispatch('setSnackbar', { text: 'Updating workflow...', type: 'success' });
      this.$store.dispatch('flipSnackbarSwitch');
      const data = {
        workflow: this.workflowID,
        action: 'reset_document_steps',
      };
      await this.$store.dispatch('updateWorkflow', data);
      await this.$store.dispatch('fetchWorkflow', this.workflowID)
        .then((res) => this.$forceUpdate());
      this.currentStep = 1;
    },
    downloadFile(path, filename) {
      const url = `${window.location.protocol}//${window.location.host}${path}`;
      fetch(url).then((t) => t.blob().then((b) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();
      }));
    },
  },
};
</script>

<style lang="scss">
@import '~Styles/variables';
	.workflow-step-info {
		margin: 10px 0px 0px 25px !important;
	}
	.no-gutter-padding {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.wide-gutter {
		margin-left: 120px;
		margin-right: 120px;
	}

	.clickable {
		cursor: pointer;
	}

	.pas-stepper {
		overflow: visible !important;
		box-shadow: none !important;
	}
	.always-visible-stepper .v-stepper__content .v-stepper__wrapper {
		height: auto !important;
	}
	.always-visible-stepper .v-stepper__step {
		display: none !important;
	}
	.always-visible-stepper .v-stepper__content {

		margin: 2em 4em !important;
		border-left-style: none !important;
	}
	.blurred + .v-stepper__content {
		opacity: 0.4 !important;
	}
	.always-visible-stepper .v-stepper__step:not(.blurred) + .v-stepper__content {
		opacity: 1 !important;
	}
	.always-visible-stepper {
		box-shadow: none !important;
	}
	.vertical-align {
		vertical-align: middle;
	}
	.mini {
		font-size: 6px;
	}
	.stepper-tooltip {
		position: absolute;
		top: -65px;
		left: -38px;
		background-color: white !important;
		border: 1px solid #eeeeee !important;
		padding: 5px 15px !important;
		z-index: 99999 !important;
		box-shadow: $medium-box-shadow !important;
		text-align: center;
		min-width: 150px;
		min-height: 5.3em !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.stepper-tooltip::after {
		content: " ";
		position: absolute;
		top: 100%;
		left: 45%;
		margin-left: 5px;
		border-width: 5px;
		border-style: solid;
		border-color: white transparent transparent transparent;
		box-shadow: $medium-box-shadow !important;
	}
	.pas-stepper {
		.not-started {
			.v-stepper__step__step {
				background-color: #dddddd !important;
				&::before {
					display: inline-block;
					content: '';
					-webkit-border-radius: 0.375rem;
					border-radius: 0.375rem;
					height: 1rem;
					width: 1rem;
					background-color: #dddddd !important;
					position: relative;
					right: -8px;
				}
			}
		}
		.in-progress-step {
			.v-stepper__step__step {
				background-color: white !important;
				border: 1px solid rgba(0,0,0,0.05) !important;
				&::before {
					display: inline-block;
					content: '';
					-webkit-border-radius: 0.375rem;
					border-radius: 0.375rem;
					height: 0.75rem;
					width: 0.75rem;
					background-color: var(--primary-color) !important;
					position: relative;
					right: -3px;
				}
			}
		}

		.v-stepper__step__step {
			background-color: var(--primary-color) !important;
			box-shadow: $soft-box-shadow !important;
			height: 32px !important;
			width: 32px !important;
			i {
				font-size: 12px;
			}
			z-index: 1 !important;
		}
		hr.v-divider {
			margin-left: -32px !important;
			margin-right: -25px !important;
			border-width: 1px !important;
			z-index: 0 !important;
		}
		.divider-complete {
			border-color: var(--primary-color) !important;
		}
		.v-stepper__step--editable:hover {
			background: transparent !important;
		}
	}
</style>

<style lang="scss" scoped>
	#fileDrop {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dropzone-custom-content {
		text-align: center;
	}
	.dropzone-custom-title {
		margin-top: 0;
	}
	.subtitle {
		font-weight: bold;
	}
	.pas-stepper-header {
		box-shadow: none !important;
	}

</style>
