<template>
  <PRow class="d-flex justify-content-between">
    <PInputField
      class="w-75 client-list-search-bar ml-6"
      v-model="searchBarValue"
      placeholder="Search Clients"
      prependIcon="search"
      type="text"
      :clearable="true"
      @input="$emit('input', searchForClients(searchBarValue))"
    />
    <PDropdown :text="'Advisor team: ' + this.selectedTeam.name" class="pr-6" size="lg">
      <PDropdownItem
        v-for="item in this.teamList"
        :key="item.id"
        :value="item.id"
        @click="refineClientList(item)"
      >
        {{ item.name }}
      </PDropdownItem>
    </PDropdown>
  </PRow>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';

const clientsListVuex = createNamespacedHelpers('clients-list');

export default {
  name: 'ClientsSearch',
  data() {
    return {
      placeholder: [],
      searchBarValue: '',
    };
  },
  computed: {
    ...clientsListVuex.mapState(['clientsList', 'loading', 'teamList', 'selectedTeam']),
  },
  methods: {
    ...clientsListVuex.mapActions(['fetchClientsList', 'removeClient']),
    ...clientsListVuex.mapMutations(['SET_SELECTED_TEAM', 'SET_SEARCH_VAL']),
    dropdownShouldOpen(VueSelect) {
      return false;
    },
    refineClientList(team) {
      this.SET_SELECTED_TEAM(team);
      this.fetchClientsList({ resetClientList: true });
    },
    searchForClients: _.debounce(async function (searchValue) {
      this.SET_SEARCH_VAL(searchValue);
      this.fetchClientsList({ resetClientList: true });
    }, 300),
  },
};

</script>
