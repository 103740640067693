<!-- eslint-disable max-len -->
<template>
  <v-dialog
    :value="isOpen"
    max-height="50rem"
    max-width="50rem"
    @input="closeModal"
  >
    <v-card class="pa-5" max-height="50rem" max-width="50rem">
      <PasFab class="close-btn">
        <v-icon @click="closeModal" class="close-icon">close</v-icon>
      </PasFab>
      <PasPageTitle> This workflow cannot be cancelled </PasPageTitle>
      <div class="my-4">
        <p>
          Unfortunately, we are unable to cancel the workflow at this time.
          If you are looking to make an additional change or correct a previously changed fields, please wait until the workflow has been completed.
          <br><br>For additional questions, please contact Purpose Advisor Solutions at <a :href="`mailto:${opsEmail}`" @click.stop="">{{ opsEmail }}</a>
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getMetadata } from 'Services/api/metadata';
import { modal } from 'Services/mixins';

export default {
  name: 'ContactOpsCancelWorkflow',
  mixins: [modal],
  data() {
    return {
      value: this.isOpen,
      opsEmail: null,
    };
  },
  props: {
    isOpen: { type: Boolean },
  },
  watch: {
    async isOpen(value) {
      if (value) {
        // closeModal resets opsEmail, re-assign opsEmail when modal opens
        this.opsEmail = await getMetadata('pas_operations_email');
      }
    },
  },
};
</script>
