<template>
  <div>
    <pas-list
      :columns="columns"
      :items="corporateRoles"
      :itemName="'relationships'"
      :loading="!clientLoaded"
      class="mt-5"
    >
      <template slot-scope="{ item }">
        <v-layout fill-height row>
          <v-flex xs3>
            <v-layout fill-height row align-center justify-start>
              <router-link :to="`/clients/${getDetails(item).id}`">
                <pas-avatar
                  class="ml-2 mr-4"
                  :isCorporate="getDetails(item).client_type !== 'person'"
                  :avatar="getAvatar('clients', getDetails(item).id)"
                />
              </router-link>
              <div class="strong-text underline-hover">
                <router-link :to="`/clients/${getDetails(item).id}`">{{ getDetails(item).display_name }}</router-link>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout fill-height row align-center justify-start>
              <div class="p-text">{{ getRoleSummary(item) }}</div>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout fill-height row align-center justify-start>
              <v-chip v-if="item.authorized_signatory === true">Yes</v-chip>
              <v-chip v-else-if="item.authorized_signatory === false">No</v-chip>
              <div v-else>-</div>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout fill-height row align-center justify-start>
              <v-chip v-if="item.preferred_or_mandatory_signor === true">Yes</v-chip>
              <v-chip v-else-if="item.preferred_or_mandatory_signor === false">No</v-chip>
              <div v-else>-</div>
              <v-tooltip v-if="item.mandatory_signor_additional_info" bottom color="white" max-width="40%">
                <v-icon
                  slot="activator"
                  size="15px"
                  :style="{ 'margin-right': '10px' }"
                  color="grey"
                >mdi-information</v-icon>
                <span :style="{ color: 'black' }">
                  {{item.mandatory_signor_additional_info}}
                </span>
              </v-tooltip>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout fill-height row align-center justify-start>
              <div class="p-text">{{ item.percentage_ownership | percentage }}</div>
            </v-layout>
          </v-flex>
          <v-flex xs1>
            <v-layout fill-height justify-center align-center @click.stop="">
              <pas-more-menu @click="$event(getDetails(item).id)" :editOptions="editOptions" />
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </pas-list>
    <define-roles
      :isOpen="isDefineRolesModalOpen"
      :legalEntity="editRole ? editRole.corporate_client : null"
      :editRole=editRole
      @close="isDefineRolesModalOpen = false"
      @save="saveRoles"
    />
  </div>
</template>
<script>

import { getAvatar } from 'Services/helpers/files';
import DefineRoles from 'Components/Shared/DefineRoles';
import { percentage } from 'Services/filters';
import { getRoleSummary } from 'Services/helpers/corporateRoles'

export default {
  name: 'CorporateRolesTable',
  components: {
    DefineRoles,
  },
  filters: {
    percentage,
  },
  props: {
    clientLoaded: Boolean,
  },
  data() {
    return {
      columns: [
        { header: 'Name', size: 'xs3' },
        { header: 'Role', size: 'xs3' },
        { header: 'Authorized Signatory', size: 'xs2' },
        { header: 'Preferred/Mandatory Signor', size: 'xs2' },
        { header: 'Ownership', size: 'xs2' },
      ],
      editOptions: [
        {
          title: 'View',
          id: 'view-role',
          onClick: (clientID) => this.$router.push(`/clients/${clientID}/`),
        },
        {
          title: 'Edit',
          id: 'edit-role',
          onClick: (clientID) => this.edit(clientID),
        },
        {
          title: 'Delete',
          id: 'delete-role',
          onClick: (clientID) => this.delete(clientID),
        },
      ],
      isDefineRolesModalOpen: false,
      editRole: null,
      getAvatar,
      getRoleSummary,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clientId() {
      return this.activeClient?.client?.id;
    },
    clientType() {
      return this.activeClient?.client?.client_type;
    },
    corporateRoles() {
      return this.activeClient.corporate_roles.filter((r) => {
        const client = this.clientType === 'person' ? r.client : r.corporate_client;
        return client === this.clientId;
      });
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },
  methods: {
    getDetails(item) {
      if (this.clientType === 'person') {
        return item.corporate_client_details;
      }
      return item.client_details;
    },
    edit(clientID) {
      this.isDefineRolesModalOpen = true;
      const role = this.corporateRoles.find((r) => this.getDetails(r).id === clientID);
      this.editRole = role;
    },
    fetchCorporateRoles() {
      if (this.clientType === 'person') {
        this.$store.dispatch('fetchCorporateRolesForClient', this.activeClient.client.id);
      } else {
        this.$store.dispatch('fetchCorporateRoles', this.activeClient.client.id);
      }
    },
    delete(clientID) {
      this.errorText = '';
      const role = this.corporateRoles.find((r) => this.getDetails(r).id === clientID);

      this.$store.dispatch('deleteCorporateRole', [
        role.id,
      ])
        .then(() => {
          this.$store.dispatch('setSnackbar', { text: 'The Legal Entity role has been deleted', type: 'success', topic: '' });
          this.$store.dispatch('flipSnackbarSwitch');
          this.fetchCorporateRoles();
        })
        .catch(() => {
          this.$store.dispatch('setSnackbar', { text: 'Error in deleting Legal Entity role', type: 'error', topic: '' });
          this.$store.dispatch('flipSnackbarSwitch');
        });
    },
    saveRoles() {
      this.isDefineRolesModalOpen = false;
      this.fetchCorporateRoles();
    },
  },
};
</script>

<style lang="scss">
</style>
