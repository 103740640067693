
export const getExtension = (fileName) => {
  const extension = fileName.substr((fileName.lastIndexOf('.') + 1)).toLowerCase();
  return extension;
};

export const getAvatar = (type, id) => {
  let link = `${window.location.protocol}//${window.location.hostname}`;
  link += window.location.port ? `:${window.location.port}` : '';
  link += `/advisor_portal/view_avatar/${type}/${id}`;
  return link;
};
