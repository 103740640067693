<template>
  <pas-list
    :columns="columns"
    :minHeight="minHeight"
    :items="workflows"
    :itemName="'workflows'"
    :loading="loading"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height row @click="viewWorkflow(item.id)">
        <!-- Workflow Type -->
        <v-flex xs4 pl-3>
          <v-layout fill-height row align-center justify-start>
            <span class="pas-list-item-text">{{workflowDisplayName(item.workflowType)}}</span>
          </v-layout>
        </v-flex>
        <!-- Client name -->
        <v-flex xs4>
          <v-layout fill-height justify-start row align-center>
            <span class="pas-list-item-text">{{item.clientName}}</span>
          </v-layout>
        </v-flex>
        <!-- Current Step -->
        <v-flex xs4>
          <v-layout fill-height row align-center justify-start>
            <span class="pas-list-item-text">{{getCurrentStep(item)}}</span>
          </v-layout>
        </v-flex>
        <!-- Status -->
        <v-flex xs2>
          <v-layout fill-height row align-center justify-start>
            <span class="pas-list-item-text">{{stepStatusName(item.workflowStatus)}}</span>
          </v-layout>
        </v-flex>
        <!-- Updated at -->
        <v-flex xs2>
          <v-layout fill-height row align-center justify-start>
            <span class="pas-list-item-text">
              {{moment(item.workflowUpdatedAt).format('MMMM D, YYYY')}}
            </span>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </pas-list>
</template>

<script>

import { WORKFLOW } from 'Services/constants';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'WorkflowList',
  props: {
    workflows: Array,
    dataObj: Object,
    loading: Boolean,
    minHeight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        { header: 'Type', size: 'xs4' },
        { header: 'Client', size: 'xs4' },
        { header: 'Step', size: 'xs4' },
        { header: 'Status', size: 'xs2' },
        { header: 'Updated', size: 'xs2' },
      ],
      editOptions: [{
        title: 'View',
        onClick: 'view',
      }],
      statusMap: {
        not_started: {
          color: 'yellow',
          value: 100,
        },
        in_progress: {
          color: 'green',
          value: 50,
        },
        needs_attention: {
          color: 'red',
          value: 100,
        },
        completed: {
          color: 'green',
          value: 100,
        },
        unknown: {
          color: 'grey',
          value: 100,
        },
        cancelled: {
          color: 'green',
          value: 100,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'canViewWorkflowDetails',
    ]),
  },
  methods: {
    viewWorkflow(id) {
      if (this.canViewWorkflowDetails) {
        this.$router.push(`/workflow/${id}`);
      }
    },
    workflowDisplayName(type) {
      return _.get(WORKFLOW.DISPLAY_NAMES, type, type);
    },
    stepStatusName(name) {
      return _.get(WORKFLOW.STATUS_NAMES, name, name);
    },
    getCurrentStep(workflow) {
      if (!workflow.steps) return '';
      const currentStep = _.head(workflow.steps);
      if (!currentStep) return '';
      return _.get(WORKFLOW.STEP_DISPLAY_NAMES, currentStep.stepName, '');
    },
  },
};

</script>

<style lang="scss">
.workflow-list {
  .v-input.drop-down.text-xs-right.v-text-field.v-text-field--placeholder.v-select.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light {
    margin-top: 8px;
  }
  .v-input__slot{
    margin-bottom: 0px;
  }
}
.status-chip-flex {
  position: absolute;
  right: 50px;
}
</style>
