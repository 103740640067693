<template>
  <v-layout fill-height :style="{height: '100vh'}" justify-content align-center>
    <pas-circular-loader
      :loadingMessage="`Loading IPS Preview...`"
      :style="{margin: 'auto', position: 'relative', top: '-100px'}"
    >
     
    </pas-circular-loader>
  </v-layout>
</template>

<script>

export default {
  name: 'page-loader'
}
</script>

<style>

</style>
