<template>
  <PContainer v-if="clientId">
    <PRow class="m-1">
      <DataCaptureClientChanges :clientId="clientId" :navigation="navigation" />
    </PRow>
  </PContainer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ClientChangesPage',
  computed: {
    ...mapGetters(['activeClient']),
    clientId() {
      return this.activeClient.client && this.activeClient.client.id;
    },
    navigation() {
      return {
        back: {
          routeName: 'ClientMaintenancePage',
          text: this.$t('cm_common:action.navigate.client_maintenance'),
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
