<template>
  <v-switch
      :value="value"
      class="pas-switch"
      :color="primaryColor"
      @change="$emit('change', $event)"
      :disabled="disabled"
  ></v-switch>
</template>

<script>
export default {
  name: 'pas-switch',
  data() {
    return {};
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style>
  .pas-switch {
    width: 50px !important;
    max-width: 50px !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
</style>
