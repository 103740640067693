/*
 * JavaScript implementation of the Luhn algorithm, with calculation and validation functions
 */

/* luhnChecksum
 * Implement the Luhn algorithm to calculate the Luhn check digit.
 * Return the check digit.
 */
function luhnChecksum(code) {
  const len = code.length;
  const parity = len % 2;
  let sum = 0;
  for (let i = len - 1; i >= 0; i--) {
    let d = parseInt(code.charAt(i));
    if (i % 2 === parity) { d *= 2; }
    if (d > 9) { d -= 9; }
    sum += d;
  }
  return sum % 10;
}

/* luhn_caclulate
 * Return a full code (including check digit), from the specified partial code (without check digit).
 */
function luhnCalculate(partcode) {
  const checksum = luhnChecksum(`${partcode}0`);
  return checksum === 0 ? 0 : 10 - checksum;
}

/* luhn_validate
 * Return true if specified code (with check digit) is valid.
 */
function luhnValidate(fullcode) {
  return luhnChecksum(fullcode) === 0;
}

export {
  luhnCalculate,
  luhnValidate,
};
