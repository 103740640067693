<template>
  <SubAdvisorConsentDisagree
    :investorInfo="investorInfo"
    @exit="onExit"
    :getCalendlyLink="getCalendlyLink"
  />
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'

export default {
  name: 'SubAdvisorConsentDisagreePage',
  computed: {
    ...mapGetters(['activeClient']),
    investorInfo () {
      return {
        firstName: this.activeClient?.person?.first_name,
        lastName: this.activeClient?.person?.last_name,
        email: this.activeClient?.person?.email
      }
    }
  },
  methods: {
    async getCalendlyLink() {
      return this.activeClient?.pmInfo?.calendar?.link
    },
    onExit() {
      this.$router.push({ path: `/clients/${this.activeClient.client.id}` });
    }
  }
}
</script>
