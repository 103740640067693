<template>
  <div>
    <div>
      <v-card class="pa-4">
        <v-layout mb-4>
          <v-flex xs12 text-xs-center>
            <PasPageTitle>Tax Confirmation Page</PasPageTitle>
          </v-flex>
        </v-layout>
        <v-layout mb-4>
          <v-flex xs12 text-xs-left>
            <p class="body-text">
              In order to open your accounts, we need to provide information to
              the Canadian Revenue Agency and the Internal Revenue Service which
              collects this information under international tax treaties. By
              providing the information below, we are able to provide accurate
              information to the CRA and ensure that you are not paying extra
              tax on foreign investments.
            </p>
          </v-flex>
        </v-layout>
        <div
          v-for="(section, sectionIndex) in filteredSections"
          :key="`${sectionIndex} ${section.questions.length}`"
        >
          <PasPageSubtitle class="section-header">{{
            section.title
          }}</PasPageSubtitle>
          <div v-for="question in section.questions" :key="question.id">
            <v-layout row wrap class="form-row" mb-3>
              <v-flex xs7 mt-3 pt-1>
                {{ question.title }}
                <v-tooltip
                  color="white"
                  min-width="300px"
                  bottom
                  v-if="question.description && question.description.length > 0"
                >
                  <v-icon
                    slot="activator"
                    size="15px"
                    :style="{ margin: '0px' }"
                    color="grey"
                    >mdi-alert-circle</v-icon
                  >
                  <span min-width="300px" class="tooltip-text">
                    <pre>
                      {{ question.description }}
                    </pre>
                  </span>
                </v-tooltip>
              </v-flex>
              <v-flex xs4>
                <v-layout column>
                  <v-layout
                    align-self-center
                    child-flex
                    row
                    v-if="question.type === 'checkbox'"
                  >
                    <PasCheckbox
                      @change="setAnswer(question.id, $event)"
                      :value="answers[question.id] === true"
                      :key="`crs-checkbox-${question.question_id}`"
                      class="shrink mr-3"
                    />
                  </v-layout>
                  <v-radio-group
                    v-model="answers[question.id]"
                    @change="setAnswer(question.id, $event)"
                    :rules="[v.isRequired()]"
                    v-else-if="question.type === 'radio'"
                  >
                    <v-layout align-start child-flex row>
                      <v-radio
                        v-for="(item, i) in question.possible_answers"
                        :key="i"
                        color="#c48f3e"
                        :label="item"
                        :value="item === 'yes'"
                        class="mr-4 option"
                      ></v-radio>
                    </v-layout>
                  </v-radio-group>
                  <pas-input-field-list
                    v-else-if="question.type === 'select'"
                    :items="question.possible_answers"
                    v-model="answers[question.id]"
                    @input="setAnswer(question.id, $event)"
                    :rules="question.rules"
                    :placeholder="question.placeholder"
                  ></pas-input-field-list>
                  <pas-input-field
                    v-model="answers[question.id]"
                    @input="
                      setAnswer(
                        question.id,
                        question.type == 'number' ? parseFloat($event) : $event
                      )
                    "
                    v-else-if="
                      question.type === 'text' || question.type == 'number'
                    "
                    :rules="question.rules"
                    :maxLength="question.maxLength"
                  ></pas-input-field>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-card>
      <v-layout mt-5 justify-end>
        <PasButton
          theme="primary"
          class="confirm-btn px-4"
          @click="$emit('submit', formAnswers)"
          >Submit</PasButton
        >
      </v-layout>
    </div>
  </div>
</template>

<script>
import countriesCities from 'full-countries-cities';

import v from 'Services/validation';

export default {
  data() {
    return {
      v,
      backdoor: 0,
      answers: {
        tax_resident_of_canada: null,
        claim_tax_treaty_benefits: null,
        claim_special_rates_and_conditions: null,
        special_rates_and_conditions_article: null,
        special_rates_and_conditions_paragraph: null,
        withholding_percentage_rate: null,
        income_withheld_type: null,
        tax_resident_of_us: null,
        ssn: null,
        applied_for_ssn: false,
        tax_resident_of_other_jurisdiction: null,
        other_jurisdiction_iso_country_code: null,
        tin: null,
        tin_not_available: false,
        tin_not_available_reason: null,
      },
      sections: [
        {
          title: 'Canada Tax',
          questions: [
            {
              id: 'tax_resident_of_canada',
              title: 'Are you a Tax Resident of Canada?',
              type: 'radio',
              possible_answers: ['yes', 'no'],
              description: '',
            },
            {
              id: 'claim_tax_treaty_benefits',
              title:
                'Do you wish to make a claim for Tax Treaty Benefits? (Most Canadians select yes)',
              type: 'radio',
              possible_answers: ['yes', 'no'],
              description:
                'By claiming Tax Treaty Benefits, you will be taxed at a reduced rate on US-sourced income',
              condition: {
                questionId: 'tax_resident_of_canada',
                value: true,
              },
            },
            {
              id: 'claim_special_rates_and_conditions',
              title:
                'Do you wish to claim special rates and conditions? (Most Canadians select no)',
              type: 'radio',
              possible_answers: ['yes', 'no'],
              description:
                "Special rates and conditions are only available in limited circumstances and most Canadians don't qualify",
              condition: {
                questionId: 'claim_tax_treaty_benefits',
                value: true,
              },
            },
            {
              id: 'special_rates_and_conditions_article',
              title:
                'Section Article of treaty under which benefical owner is claiming provisions',
              type: 'text',
              description: '',
              condition: {
                questionId: 'claim_special_rates_and_conditions',
                value: true,
              },
            },
            {
              id: 'special_rates_and_conditions_paragraph',
              title:
                'Section Paragraph of treaty under which benefical owner is claiming provisions',
              type: 'text',
              description: '',
              condition: {
                questionId: 'claim_special_rates_and_conditions',
                value: true,
              },
            },
            {
              id: 'withholding_percentage_rate',
              title: 'Percentage rate of withholding claimed',
              type: 'number',
              description: '',
              rules: [v.isNumeric(), v.isLessThanOrEqual(100)],
              maxLength: 5,
              condition: {
                questionId: 'claim_special_rates_and_conditions',
                value: true,
              },
            },
            {
              id: 'income_withheld_type',
              title: 'Type of income withheld',
              type: 'text',
              description: '',
              condition: {
                questionId: 'claim_special_rates_and_conditions',
                value: true,
              },
            },
          ],
        },
        {
          title: 'US Tax',
          questions: [
            {
              id: 'tax_resident_of_us',
              title: 'Are you a tax resident of the United States?',
              type: 'radio',
              possible_answers: ['yes', 'no'],
              description:
                'A U.S. person is: 1. a U.S. Citizen 2. anyone born in the U.S. 3. a Green Card holder 4. anyone who spends more than 183 days in the U.S. annually',
            },
            {
              id: 'ssn',
              title: 'SSN',
              type: 'text',
              rules: [v.emptyOrSpecifiedChars(9)],
              description: '',
              condition: {
                questionId: 'tax_resident_of_us',
                value: true,
              },
            },
            {
              id: 'applied_for_ssn',
              title: 'I have applied for my SSN',
              type: 'checkbox',
              answer: 'no',
              possible_answers: ['yes', 'no'],
              description: '',
              condition: {
                questionId: 'tax_resident_of_us',
                value: true,
              },
            },
          ],
        },
        {
          title: 'International Tax',
          questions: [
            {
              id: 'tax_resident_of_other_jurisdiction',
              title:
                'Are you a tax resident of a jurisdiction other than Canada and the United States.',
              type: 'radio',
              possible_answers: ['yes', 'no'],
              description: '',
            },
            {
              id: 'other_jurisdiction_iso_country_code',
              title: 'Country',
              placeholder: 'Select a country',
              type: 'select',
              possible_answers: this.countries,
              description: '',
              condition: {
                questionId: 'tax_resident_of_other_jurisdiction',
                value: true,
              },
            },
            {
              id: 'tin',
              title: 'TIN',
              type: 'text',
              maxLength: 25,
              description: '',
              condition: {
                questionId: 'tax_resident_of_other_jurisdiction',
                value: true,
              },
            },
            {
              id: 'tin_not_available',
              title: "I don't have a TIN",
              type: 'checkbox',
              answer: 'no',
              possible_answers: ['yes', 'no'],
              description: '',
              condition: {
                questionId: 'tax_resident_of_other_jurisdiction',
                value: true,
              },
            },
            {
              id: 'tin_not_available_reason',
              title: 'Reason',
              placeholder: 'Select a reason',
              type: 'select',
              possible_answers: [
                'I will apply or have applied for a TIN but have not yet received it',
                'My jurisdiction of tax residence does not issues TINs to its residents',
                'Other reason',
              ],
              description: '',
              condition: {
                questionId: 'tin_not_available',
                value: true,
              },
            },
          ],
        },
      ],
    };
  },
  watch: {
    value() {
      this.backdoor++;
    },
  },
  mounted() {
    this.sections[2].questions.find(
      (q) => q.id === 'other_jurisdiction_iso_country_code',
    ).possible_answers = this.countries;
  },
  computed: {
    countries() {
      return countriesCities.getCountries().map((c) => ({
        text: c.name.official,
        value: c.cca3,
      }));
    },
    loading() {
      return this.loadingFATCA || this.loadingCRS;
    },
    sessionToken() {
      return this.$route.query.sessionToken;
    },
    firmId() {
      return this.$route.query.firm;
    },
    filteredSections() {
      // eslint-disable-next-line no-unused-expressions
      this.backdoor;
      const sectionsCopy = [...this.sections];
      const answersCopy = JSON.parse(JSON.stringify(this.answers));
      const filtered = sectionsCopy.map((section) => {
        const sectionCopy = JSON.parse(JSON.stringify(section));

        const newSectionQuestions = section.questions.filter((question) => {
          if (question.condition) {
            const conditionalQuestionAnswer = answersCopy[question.condition.questionId];
            if (conditionalQuestionAnswer === question.condition.value) {
              return true;
            }
            this.setAnswer(question.id, null);
            answersCopy[question.id] = null;
            return false;
          }
          return true;
        });
        sectionCopy.questions = newSectionQuestions;
        return sectionCopy;
      });
      return filtered;
    },
    formAnswers() {
      return this.answers;
    },
  },
  methods: {
    setAnswer(questionId, value) {
      if (questionId === 'tin_not_available' && value === true) {
        this.$set(this.answers, 'tin', null);
      }
      if (questionId === 'applied_for_ssn' && value === true) {
        this.$set(this.answers, 'ssn', null);
      }

      this.$set(this.answers, questionId, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.body-text {
  font-size: 14px;
}
.section-header {
  margin: 24px 0;
}
.confirm-btn {
  margin-left: 16px;
}
.option {
  text-transform: capitalize;
}
.tooltip-text {
  color: black;
  pre {
    max-width: 500px;
    font: inherit;
    white-space: pre-line;
  }
}
</style>
