import moment from 'moment';
import business from 'moment-business-days';

export const getTimeDiffString = (time) => {
  const diffDays = moment(new Date()).diff(moment(time), 'days');
  const diffHours = moment(new Date()).diff(moment(time), 'hours');
  const diffMins = moment(new Date()).diff(moment(time), 'minutes');
  const diffSecs = moment(new Date()).diff(moment(time), 'seconds');

  if (diffDays === 0) {
    if (diffHours === 0) {
      if (diffMins === 0) {
        if (diffSecs === 0) {
          return '0 seconds';
        }
        return `${diffSecs} second${diffSecs !== 1 ? 's' : ''}`;
      }
      return `${diffMins} minute${diffMins !== 1 ? 's' : ''}`;
    }
    return `${diffHours} hour${diffHours !== 1 ? 's' : ''}`;
  }
  return `${diffDays} day${diffDays !== 1 ? 's' : ''}`;
};

export const getAge = (dateString) => {
  if (!dateString) return null;
  return moment().diff(dateString, 'years');
};

export const getNthBusinessDay = (date, businessDaysToAddOrSubtract) => {
  if (businessDaysToAddOrSubtract > 0) {
    return business(date).businessAdd(businessDaysToAddOrSubtract);
  }
  return business(date).businessSubtract(Math.abs(businessDaysToAddOrSubtract));
};
