<template>
  <v-stepper @input="$emit('input', $event)" :value="selectedStep">
    <v-stepper-header>
      <template v-for="(step, index) in steps">
        <v-stepper-step
          :key="index + 'step'"
          :complete="step.completed"
          :step="index + 1"
          edit-icon="$vuetify.icons.complete"
          editable
        >
          {{step.name}}
        </v-stepper-step>
        <v-divider :key="index + 'divider'" v-if="index + 1 !== steps.length"></v-divider>
      </template>
    </v-stepper-header>
    <slot name="steps-content">
    </slot>
  </v-stepper>
</template>

<script>
export default {
  name: 'pas-stepper',
  data() {
    return {};
  },
  props: {
    steps: {
      type: Array,
      default() {
        return [{ completed: false, name: 'Sample Step' }];
      },
    },
    selectedStep: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style>

</style>
