<template>
  <v-dialog v-model="isOpen" persistent max-width="650px">
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <pas-page-title class="mb-4">Client ID Verification</pas-page-title>
      <pas-dropzone
        ref="pasDropzone"
        :id="'docDrapzone'"
        :options="dropzoneOptions"
      ></pas-dropzone>
      <div class="error--text mt-2 ml-3" v-if="errorText">
        <span class="v-messages__message">{{errorText}}</span>
      </div>
      <v-layout mt-4 align-center justify-end row fill-height>
        <v-flex shrink>
          <pas-button @click="close">Done</pas-button>
        </v-flex>
        <v-flex shrink>
          <pas-button class="wide-btn ml-2" @click="complete">Complete Step</pas-button>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

import { modal } from "Services/mixins"
import  { DOCUMENT_SOURCE } from 'Services/constants';

export default {
  name: "upload-id",
  mixins: [modal],
  props: {
    isOpen: Boolean,
    workflowId: String
  },
  data() {
    let that = this;
    return {
      dropzoneOptions: {
        url: "/advisor_portal/workflow/upload_workflow_document",
        init: function() {
          this.on("sending", (file, xhr, formData) => {
            const documentName = that.activeClient.client.display_name || that.activeClient.client.id;
            formData.append("document_type", 19); //Photo ID
            formData.append("workflow", that.workflowId);
            formData.append("document_source", that.documentSource.Manual);
            formData.append("document_name",`Photo ID for ${documentName}`);
          });
          this.on("success", (file, res) => {
            if (res.message === "Success") {
              that.$store.dispatch("fetchWorkflow", that.workflowId);
              that.$store.dispatch("setDocuments", that.activeClient.client.id);
              that.snackBar('success');
            } else {
              that.snackBar('fail');
              this.removeFile(file);
            }
          });
          this.on("error", (file, err) => {
            that.snackBar('fail')
            this.removeFile(file);            
          });
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        maxFilezie: 3, // MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 3000000, // Bytes
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles:'.png,.jpeg,.jpg,.pdf'
      },
      errorText: ""
    };
  },
  computed: {
    activeClient(){
      return this.$store.state.clients.activeClient
    },
    documents() {
      return this.$store.state.clients.activeClient.documents;
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
  },
  methods: {
    complete() {
      this.errorText = "";
      if (!this.documents.some(d => d.document_type == 19)) {
        this.errorText = "In order to complete this step, please upload a form of ID for the client";
        return;
      }
      this.$emit("done");
    },
    snackBar(type){
      let text = `Oops... Something went wrong, please try upoading the file again`;
      if (type === 'success') text = `The ID was successfully uploaded`;
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
    }
  }
};
</script>

<style>
</style>
