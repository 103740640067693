<template>
  <PasFullscreenModal @close="$emit('close')" :isOpen="isOpen">
    <v-container class="modal-container">
      <v-layout>
        <v-flex xs12 text-xs-center mb-3>
          <PasPageTitle> Add RESP Beneficiaries </PasPageTitle>
        </v-flex>
      </v-layout>
      <template v-if="currentStep === 0">
        <v-layout row wrap>
          <v-flex xs12 text-xs-center> </v-flex>
          <v-flex xs12 text-xs-center mt-3>
            <div>
              Let’s finish setting up this account by adding the beneficiaries.
              If the beneficiary is not yet in the Advisor Center, you can
              <span
                @click="$emit('addNewClient')"
                class="action-text underline"
                :style="{ textTransform: 'none !important' }"
                >add a new client.</span
              >
            </div>
          </v-flex>
        </v-layout>
        <v-layout mt-5>
          <v-flex>
            <v-form ref="form" lazy-validation @submit.prevent="">
              <v-layout
                row
                v-for="(item, i) in beneficiaries"
                :key="`br-${i}`"
                mt-3
              >
                <v-flex xs4>
                  <v-layout row child-flex nowrap>
                    <PasInputFieldList
                      :items="relationships"
                      :value="item"
                      @input="changeBeneficiaries(i, $event)"
                      :rules="[v.isRequired('Please select a beneficiary')]"
                    />
                    <PasIconCircle
                      @click="removeBeneficiary(i)"
                      :style="{ marginTop: '12px !important' }"
                      :theme="`pas`"
                      class="ml-3"
                      v-if="isFamilyRESP"
                    >
                      <v-icon> mdi-close </v-icon>
                    </PasIconCircle>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout mt-3 v-if="isFamilyRESP">
                <v-flex>
                  <PasAddItem
                    :text="'Add Another Beneficiary'"
                    @click="addAnotherBeneficiary"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
        <v-layout mt-5 justify-end>
          <PasButton class="px-5" @click="next()" :disabled="hasEmptySlot">
            Continue
          </PasButton>
        </v-layout>
      </template>
      <template v-else>
        <ConfirmBeneficiaryInfo
          :beneficiary="beneficiaries[currentStep - 1]"
          @back="back()"
          @save="saveBeneficiary($event)"
        />
      </template>
    </v-container>
  </PasFullscreenModal>
</template>

<script>
import v from 'Services/validation';
import ConfirmBeneficiaryInfo from './RESPBeneficiaries/ConfirmBeneficiaryInfo';

export default {
  name: 'specify-beneficiaries',
  components: {
    ConfirmBeneficiaryInfo,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    account: Object,
  },
  data() {
    return {
      v,
      beneficiaries: [{}],
      currentStep: 0,
    };
  },
  computed: {
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    relationships() {
      return this.activeClient.relationships.map((r) => ({
        text: r.client_details[0].display_name,
        value: r,
      }));
    },
    isFamilyRESP() {
      return this.account.resp_type === 1;
    },
    hasEmptySlot() {
      return this.beneficiaries.some((bene) => _.isEmpty(bene));
    },
  },
  methods: {
    back() {
      this.currentStep--;
    },
    next() {
      if (this.currentStep < this.beneficiaries.length) {
        this.currentStep++;
      } else {
        this.saveAndExit();
      }
    },
    addAnotherBeneficiary() {
      this.beneficiaries.push({});
    },
    removeBeneficiary(index) {
      this.beneficiaries.splice(index, 1);
    },
    changeBeneficiaries(i, beneficiary) {
      beneficiary.isPrimaryCaregiver = true;
      this.beneficiaries.splice(i, 1, beneficiary);
    },
    saveAndExit() {
      this.$emit('save', {
        beneficiaries: this.beneficiaries,
      });
      this.currentStep = 0;
      this.$emit('close');
    },
    saveBeneficiary(beneficiary) {
      this.beneficiaries[this.currentStep - 1] = beneficiary;
      const data = {
        SIN: beneficiary.client_details[1].person_details[0].SIN,
        sex: beneficiary.client_details[1].person_details[0].sex.toLowerCase(),
        date_of_birth:
          beneficiary.client_details[1].person_details[0].date_of_birth,
      };
      this.$store
        .dispatch('updatePersonClient', {
          id: beneficiary.client_details[0].id,
          data,
          refetch: false,
        })
        .then((res) => {
          if (res.id) this.next();
        })
        .catch((err) => {
          this.$store.dispatch('setSnackbar', { text: err, type: 'fail' });
          this.$store.dispatch('flipSnackbarSwitch');
        });
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.currentStep = 0;
        if (
          this.account.beneficiaries_info
          && this.account.beneficiaries_info.beneficiaries.length
        ) {
          this.beneficiaries = this.account.beneficiaries_info.beneficiaries;
        } else {
          this.beneficiaries = [{}];
        }
      }
    },
  },
};
</script>
