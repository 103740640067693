<template>
  <div class="px-3">
    <template v-if="canViewPlanningSchedules">
      <PasPageSubtitle class="mt-4">Planning Fees Schedule</PasPageSubtitle>
      <FeeScheduleDetails
        :schedules="planningSchedules"
        v-if="planningSchedules.length"
      />
      <div v-if="!planningSchedules.length">No Planning Fees Schedules to display</div>
    </template>
    <template v-if="canViewManagementSchedules">
      <PasPageSubtitle class="mt-4">Management Fees Schedule</PasPageSubtitle>
      <FeeScheduleDetails
        :schedules="managementSchedules"
        v-if="managementSchedules.length"
      />
      <div v-if="!managementSchedules.length">No Planning Fees Schedules to display</div>
    </template>
    <template v-if="canViewSubadvisorSchedules">
      <PasPageSubtitle class="mt-4">Subadvisor Fees Schedule</PasPageSubtitle>
      <FeeScheduleDetails
        :schedules="subadvisorSchedules"
        v-if="subadvisorSchedules.length"
      />
      <div v-if="!subadvisorSchedules.length">No Planning Fees Schedules to display</div>
    </template>
  </div>
</template>

<script>

import { commaNoDecimalFormatted, commaDecimalFormatted } from 'Services/helpers/currency';
import { mapGetters } from 'vuex';
import {
  fetchPlanningSchedule, fetchManagementSchedule, fetchSubadvisorSchedule,
} from 'Services/api/FeeManagement';
import _ from 'lodash';
import FeeScheduleDetails from './ClientFeeSchedules/FeeScheduleDetails';

export default {
  name: 'ClientFeeSchedules',
  components: {
    FeeScheduleDetails,
  },
  data() {
    return {
      planningSchedules: [],
      managementSchedules: [],
      subadvisorSchedules: [],
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'clientId',
      'feeGroup',
      'canViewPlanningSchedules',
      'canViewManagementSchedules',
      'canViewSubadvisorSchedules',
    ]),
    investmentAccounts() {
      return this.$store.state.clients.activeClient.investment_accounts;
    },
  },
  mounted() {
    this.fetchSchedules();
  },
  watch: {
    investmentAccounts: {
      deep: true,
      handler() {
        this.fetchSchedules();
      },
    },
  },
  methods: {
    fetchSchedules() {
      Promise.all(
        this.investmentAccounts.filter((ia) => ia.planning_schedule)
          .map((ia) => fetchPlanningSchedule(ia.planning_schedule)),
      ).then((results) => this.planningSchedules = _.uniqBy(results, 'id'));
      Promise.all(
        this.investmentAccounts.filter((ia) => ia.management_schedule)
          .map((ia) => fetchManagementSchedule(ia.management_schedule)),
      ).then((results) => this.managementSchedules = _.uniqBy(results, 'id'));
      Promise.all(
        this.investmentAccounts.filter((ia) => ia.subadvisor_schedule)
          .map((ia) => fetchSubadvisorSchedule(ia.subadvisor_schedule)),
      ).then((results) => this.subadvisorSchedules = _.uniqBy(results, 'id'));
    },
  },
};
</script>
