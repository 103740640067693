<template>
  <transition name='slide'>
    <div class="error--text"
      v-if="show">
      <span class="v-messages__message">
        <slot></slot>
      </span>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'pas-error-div',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style>
  .slide-leave-active, .slide-enter-active{
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-enter, .slide-leave-to{
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
