<template >
  <div v-show="displayed">
  <v-progress-linear :value="progress" :color="primaryColor"
    class="wizard-progress-bar"
  ></v-progress-linear>
  <v-layout
    v-if="!isFirstStage"
  >
    <div :class="{'no-pointer': navigationDisabled}">
    <div class="strong-text back-doodle"
      @click="$parent.$emit('back')"
      >&larr; Back</div></div>
  </v-layout>
  <v-container
      class="wizard-container"
  >
    <v-layout mb-3>
      <v-flex xs12 text-xs-center>
        <pas-page-title>
          {{title}}
        </pas-page-title>
      </v-flex>
    </v-layout>
    <v-layout mb-4>
      <v-flex xs12 text-xs-center>
        {{subtitle}}
      </v-flex>
    </v-layout>
    <transition name="slide-fade">
      <div v-show="displayed">
        <v-container
          :style="{maxWidth: contentMaxWidth}"
        >
          <slot></slot>
        </v-container>
        <v-layout justify-end v-if="showNextButton">
          <pas-button
            @click="$emit('next')"
            class="primary-btn px-5"
            :disabled="navigationDisabled"
          >
            Continue
          </pas-button>
        </v-layout>
      </div>
    </transition>

  </v-container>
  </div>
</template>

<script>

export default {
  name: 'pas-wizard-stage',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    title: String,
    subtitle: {
      type: String,
      default: '',
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    contentMaxWidth: {
      type: String,
      default: '1200px',
    },
    showNextButton: {
      type: Boolean,
      default: true,
    },
    isFirstStage: Boolean,
    isLastStage: Boolean,
    navigationDisabled: {
      type: Boolean,
      default: false,
    },
    displayed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stageLoaded: false,
    };
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
};
</script>

<style lang="scss">
  .wizard-container {
    padding-top: 18vh !important;
    max-width: 1200px !important;
    width: 80vw !important;
  }
  .wizard-progress-bar.v-progress-linear {
    margin: 0px !important;
    .v-progress-linear__bar {
      background-color: #eeeeee !important;
    }
  }
  .slide-fade-enter-active {
    transition: all 2s ease;
  }
  // .slide-fade-leave-active {
  //   transition: all 3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  // }
  .slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(80px);
    opacity: 0;

  }
</style>
