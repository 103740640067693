<template>
  <v-dialog v-model="isOpen" persistent max-width="650px">
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="close" class="close-icon">close</v-icon>
      </PasFab>
      <v-form @submit.prevent ref="form" lazy-validation>
        <!-- <v-flex xs12 text-xs-center> -->
        <pas-page-title>Client IDs and Account Numbers</pas-page-title>
        <!-- </v-flex>	 -->
        <v-layout column>
          <div v-for="currentAccount of allAccounts">
            <div class="strong-text my-3">Account ID for {{currentAccount.nickname}}</div>
            <pas-input-field v-model="accountId[currentAccount.id]" :maxLength=8 :rules="[v.maxChars(8)]"></pas-input-field>
          </div>
        </v-layout>
        <v-layout mt-2 justify-end>
          <pas-button class="px-5 mt-3" :disabled="saving" @click="complete">Save</pas-button>
        </v-layout>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "Services/api";
import v from "Services/validation";
import { modal } from "Services/mixins";
import { WORKFLOW } from "Services/constants";
import babelPolyfill from "@babel/polyfill";

export default {
  name: "confirmAccount",
  mixins: [modal],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    workflow: Object
  },
  data() {
    return {
      v: v,
      saving: false,
      accountId: {}
    };
  },
  computed: {
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    allAccounts() {
      if (!this.activeClient.investment_accounts) return [];
      return this.activeClient.investment_accounts.filter(
        a => a.account_status !== "C"
      );
    },
    stepCompleted() {
      const steps = this.workflow.stages.filter(
        s => s.name === "Back Office"
      )[0].steps;
      const stepStatus = steps.filter(
        s => s.step_name === "Receive Account Number"
      )[0].current_status;
      return stepStatus === "Completed" || stepStatus === "completed";
    }
  },
  methods: {
    async complete() {
      this.saving = true;
      try {
        const dataphileNoReponse = await Promise.all(
          Object.keys(this.accountId).map(id => {
            return api.post(`/datahub/accounts/${id}/`, {
              account_no_dataphile: this.accountId[id],
              primary_client_id: this.activeClient.client.id
            });
          })
        );
        if (
          dataphileNoReponse.filter(r => r.id).length !==
          dataphileNoReponse.length
        )
          throw Error;
        this.snackBar(
          `Account Numbers have been successfully updated`,
          "success"
        );
        await this.$store.dispatch(
          "getInvestmentAccounts",
          this.activeClient.client.id
        );
        const missingDataphileNo = Object.values(this.accountId).filter(
          id => !id
        );
        if (!missingDataphileNo.length && !this.stepCompleted)
          this.$emit("done");
        this.saving = false;
        this.$emit("close");
      } catch (err) {
        this.snackBar(`Failed to update Account Numbers`, "fail");
      }
    },
    snackBar(text, type) {
      this.$store.dispatch("setSnackbar", { text, type });
      this.$store.dispatch("flipSnackbarSwitch");
    }
  },
  watch: {
    isOpen(val) {
      if (val)
        this.allAccounts.forEach(
          a => (this.accountId[a.id] = a.account_no_dataphile)
        );
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
