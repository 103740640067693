<template>
  <PBadge :variant="statusToVariant" class="row-badge">
    {{ statusToLabel }}
  </PBadge>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    status: String,
    statusLabelMap: Object,
    statusVariantMap: Object,
  },
  computed: {
    statusToLabel() {
      return this.statusLabelMap[this.status] || '';
    },
    statusToVariant() {
      return this.statusVariantMap[this.status] || '';
    },
  },
};
</script>

<style scoped>
.row-badge {
  font-size: 12px !important;
  padding: 4px 8px !important;
}

.small-badge {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 0.875rem !important;
  padding: 0.25rem 0.5rem !important;
}
</style>
