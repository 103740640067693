<template>
  <v-avatar :size="size">
    <img :src="image" class="avatar-img" @error="onImgError()">
  </v-avatar>
</template>

<script>

import defaultAvatar from 'Assets/img/anonymous.png';
import defaultCorporateAvatar from 'Assets/img/anonymous_corporate.png';

export default {
  name: 'pas-avatar',
  props: {
    avatar: {
      type: String,
      default: defaultAvatar,
    },
    size: {
      type: [Number, String],
      default: '50px',
    },
    isCorporate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useDefault: false,
      defaultAvatar,
      defaultCorporateAvatar,
      refresh: true,
    };
  },
  computed: {
    image() {
      return this.useDefault ? (this.isCorporate ? this.defaultCorporateAvatar : this.defaultAvatar) : this.avatar;
    },
  },
  methods: {
    onImgError() {
      this.useDefault = true;
    },
  },
  watch: {
    avatar() {
      this.useDefault = false;
    },
  },
};
</script>

<style lang="scss">
	.avatar-img {
    clip-path: circle(50%);
	}
  .v-avatar {
    filter: drop-shadow(0px 0px 13px 0px rgba(82,63,105,0.2));
  }
</style>
