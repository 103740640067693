<template>
<v-layout>
	<v-flex xs8>
	<v-layout row align-center pt-1 class="list-filters-row">
		<pas-search-box
			placeholder="Search by name ..."
			@input="$emit('searchChange', $event)"
			:style="{maxWidth: '320px'}"
			class="mr-5"
		></pas-search-box>
		<v-flex shrink pt-1 mr-4>
		<span class="filter-label">Date Range:</span>
		</v-flex>
		<v-flex shrink pt-2>
			<pas-drop-down
				:items="dateRanges"
				:value="dateRange"
				@change="onDateRangeChanged"
				id="day-range"
			></pas-drop-down>
		</v-flex>
<!-- 
		<v-subheader class="filter-header-text ml-5">Filter by: </v-subheader>
		<v-btn-toggle
			v-model="notificationState"
			class="notification-btn-select-group elevation-0"
		>
			<v-btn flat value="Missed" class="notification-state-btn">
				<span class="state-toggle-btn-text">
					Missed
				</span>
			</v-btn>
			<v-btn flat value="Successful" class="notification-state-btn">
				<span class="state-toggle-btn-text">
					Successful
				</span>
			</v-btn>
		</v-btn-toggle> -->
		
	</v-layout>
	</v-flex>
</v-layout>
</template>

<script>

export default {
	name: 'notification-filter',
	data () {
		return {
			dateRanges: ['3 days', '7 days', '2 weeks','All'],
			dateRange: 'All',
			notificationState: '',
			clientOnboardingNewAccount : false,
		}
	},
	computed: {
		primaryColor() {
			return this.$store.state.app.themeVars.primaryColor
		},
	},
	methods: {
		onDateRangeChanged(eventValue) {
			this.dateRange = eventValue;
			this.$emit('on-date-range', this.dateRange);
		},
		onFilterChanged(eventValue) {
			this.clientOnboardingNewAccount = eventValue
			let filters = [];
			if (this.clientOnboardingNewAccount) {
				filters.push('client_onboarding_new_account')
			}
			this.$emit('on-filter', filters)
		},
	}
}
</script>

<style lang="scss" scoped>
	#notification-filter {
		display: flex;
		align-items: center;
	}
	.notifications-drop-down .v-select-list .drop-down .text-xs-right {
		width: 120px !important;
	}
	.filter-header-text {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 7px;
		min-width: fit-content !important;
		padding-left: 0px;
	}
	.notification-btn-select-group {
		background: none !important;
	}
	.notification-state-btn {
		background-color: #F2F2F2 !important;
		width: 200px !important;
		height: 40px;
		border-radius: 56px !important;
		text-transform: capitalize !important;
		margin-left: 15px !important;
	}
	.state-toggle-btn-text {
		font-size: 18px;
	}
	.v-btn-toggle--selected .v-btn--active {
		background-color: var(--primary-color) !important;
		color: #fff !important;
	}
	.date-range-select .v-input__control .v-input__slot:before {
		border: none;	
		background-color: black !important;

	}
	.date-range-select .v-input__control .v-input__slot:after {
		border: none;	
		background-color: black !important;
	}

</style>

