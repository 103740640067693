<template>
  <pas-list
    :columns="columns"
    :minHeight="minHeight"
    :items="workflows"
    :itemName="'workflows'"
    :loading="loading"
  >
    <template slot-scope="{ item }">
      <v-layout fill-height row @click="viewWorkflow(item._id)">
        <v-flex xs3>
          <v-layout fill-height row align-center justify-start>
            <pas-avatar
              class="ml-2 mr-4"
              :isCorporate="item.client_type === 'corporation'"
              :avatar="getAvatar('clients',item.client_id)"
            ></pas-avatar>
            <div class="strong-text">{{item.display_name}}</div>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <v-layout fill-height row align-center justify-start>
            <span class="pas-list-item-text">{{item.type}}</span>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout fill-height justify-space-around row align-center>
            <v-flex shrink>
              <pas-avatar
                class="mr-4 ml-2"
                :avatar="getAvatar('platform_users',teamMembers[item.assigned_user])"
              ></pas-avatar>
            </v-flex>
            <v-flex shrink @click.stop="">
              <pas-reassign-workflow
                :workflow="item"

                @reassignSuccess="$emit('reassignSuccess')"
                class="workflow-list"
              ></pas-reassign-workflow>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex class="status-chip-flex" srink>
              <pas-display-chip
                class="client-status-chip"
                textColor="rgba(196,143,62,0.7)"
                color="rgba(196,143,62,0.2)"
              >{{currentUser === item.assigned_user ? 'You' : 'Team'}}
              </pas-display-chip>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <v-layout fill-height row align-center justify-start>
            <span class="pas-list-item-text">{{item.stages}}</span>
          </v-layout>
        </v-flex>
        <v-flex xs1>
          <v-layout fill-height row align-center justify-center>
            <v-flex shrink>
              <pas-status-bullet
                :color="statusMap[item.status_code] ? statusMap[item.status_code].color : 'grey'"
                :value="statusMap[item.status_code] ? statusMap[item.status_code].value : 0"
              ></pas-status-bullet>
            </v-flex>
            <!-- <v-spacer></v-spacer> -->
            <!-- disable for now since provides no value -->
            <!-- <pas-more-menu
						  :editOptions="editOptions"
              @click="viewWorkflow(item._id)"
            ></pas-more-menu> -->
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </pas-list>
</template>

<script>

import defaultAvatar from 'Assets/img/anonymous.png';
import { getAvatar } from 'Services/helpers/files';


export default {
  name: 'WorkflowList',
  props: {
    workflows: Array,
    dataObj: Object,
    loading: Boolean,
    minHeight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        { header: 'Client Name', size: 'xs3' },
        { header: 'Type', size: 'xs2' },
        { header: 'Assigned to', size: 'xs4' },
        { header: 'Stage', size: 'xs2' },
        { header: 'Status', size: 'xs1' },
      ],
      editOptions: [{
        title: 'View',
        onClick: 'view',
      }],
      statusMap: {
        not_started: {
          color: 'yellow',
          value: 100,
        },
        in_progress: {
          color: 'green',
          value: 50,
        },
        needs_attention: {
          color: 'red',
          value: 100,
        },
        completed: {
          color: 'green',
          value: 100,
        },
        unknown: {
          color: 'grey',
          value: 100,
        },
        cancelled: {
          color: 'green',
          value: 100,
        },
      },
      getAvatar,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.currentUser._id;
    },
    teamMembers() {
      const teamMembers = {};
      this.$store.getters.teamMembers.forEach((m) => teamMembers[m.ac_mongo_id] = m.id);
      return teamMembers;
    },
  },
  methods: {
    viewWorkflow(id) {
      this.$router.push(`/workflow/${id}`);
    },
  },
};

</script>

<style lang="scss">
.workflow-list {
  .v-input.drop-down.text-xs-right.v-text-field.v-text-field--placeholder.v-select.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light {
    margin-top: 8px;
  }
  .v-input__slot{
    margin-bottom: 0px;
  }
}
.status-chip-flex {
  position: absolute;
  right: 50px;
}
</style>
