<template>
  <pas-fullscreen-modal @close="closeModal" :isOpen="isOpen">
    <v-container class="modal-container">
      <v-layout column>
        <v-flex xs12 text-xs-center mb-3>
          <PasPageTitle>Set up a deposit or withdrawal</PasPageTitle>
        </v-flex>
      </v-layout>
      <v-layout column>
          <v-flex xs12 text-xs-center mb-5>
            <span>
              Complete the required fields below to set up a deposit or withdrawal for your client.<br>
              A form will be sent to your client for signature via DocuSign before processing. The form will not be sent for one-time<br>
              withdrawals from non-registered accounts, TFSAs and RRSPs as a client signature is not required.<br>
              Please allow 5 business days to process your request.
            </span>
          </v-flex>
      </v-layout>
      <PasSections
        @select="selectSystematic($event)"
        :selected="selectedSection"
      >
        <template v-slot:1>&nbsp; Deposits</template>
        <template v-slot:2>&nbsp; Withdrawals</template>
      </PasSections>
  <v-form
    lazy-validation
    ref="systematicsForm"
    @submit.prevent=""
  >
    <v-layout mb-3>
      <v-flex xs6 pr-1>
        <div class="strong-text my-3">Frequency</div>
        <PasAutocomplete
          :items="frequencies"
          v-model="frequency"
          placeholder="Select a frequency"
          :rules="[v.isRequired()]"
          :key="`pac-fcc-frequency`"
          @input="assignDate"
        />
      </v-flex>
    </v-layout>
    <v-layout mt-2 mb-3 row :reverse="isWithdrawal">
      <v-flex xs6 :ml-3="isWithdrawal">
        <div class="strong-text my-3">{{ isDeposit ? 'From ' : 'To ' }} Bank Account</div>
        <PasAutocomplete
          placeholder="Select an account"
          v-model="bankAccount"
          return-object
          :items="filteredBankAccounts"
          @change="updateInvestmentAccounts"
          :rules="[v.isObjectEmpty()]"
          :key="`pac-fcc-from`"
        />
      </v-flex>
      <v-flex xs6 :ml-3="isDeposit">
        <div class="strong-text my-3">{{ isDeposit ? 'To ' : 'From ' }} Investment Account</div>
        <PasAutocomplete
          placeholder="Select an account"
          v-model="investmentAccount"
          return-object
          :items="filteredInvestmentAccounts"
          @change="updateBankAccounts"
          :rules="[v.isObjectEmpty()]"
          :key="`pac-fcc-to`"
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="isWithdrawal && isOneTimeFunding" mt-2 mb-3>
      <v-flex xs6 pr-1>
        <div class="strong-text my-3">Reason</div>
        <PasAutocomplete
          :items="reasons"
          v-model="reason"
          placeholder="Select a Reason"
          :rules="[v.isRequired()]"
          :key="`pac-fcc-reason`"
        />
      </v-flex>
    </v-layout>
    <v-layout mt-2 mb-3>
      <v-flex xs3>
        <div class="strong-text my-3">{{ txtAmountLabel }}</div>
        <PasInputCurrency
          v-model="amount"
          ref="inputCurrency"
          :rules="[v.isRequired(), v.isGreaterThan(0)]"
          :key="`pac-fcc-amount`"
        />
      </v-flex>
    </v-layout>
    <v-layout mb-3>
      <v-flex xs2 mr-4>
        <div class="strong-text my-3">Start Date</div>
        <PasDatepicker
          v-model="startDate"
          iconLocation="before"
          :key="`pac-fcc-start-date`"
          :rules="[v.isRequired('Please enter a start date')]"
          :minDate="isOneTimeFunding ? moment().format('YYYY-MM-DD') : moment().businessAdd(5).format('YYYY-MM-DD')"
          :readonly="isOneTimeFunding"
        />
      </v-flex>
      <v-flex xs2 v-if="!isOneTimeFunding">
        <div class="strong-text my-3">End Date</div>
        <PasDatepicker
          v-model="endDate"
          iconLocation="before"
          :key="`pac-fcc-end-date`"
          :rules="[v.isRequired('Please enter an end date')]"
          :minDate="moment().businessAdd(5).format('YYYY-MM-DD')"
        />
      </v-flex>
    </v-layout>
    <v-layout mt-5>
      <PasButton
        @click="submitSystematic"
        class="primary-btn px-5"
        :processing="isProcessing"
      >Submit</PasButton>
    </v-layout>
  </v-form>
    </v-container>
  </pas-fullscreen-modal>
</template>

<script>
import { modal, cursorToEnd } from 'Services/mixins';
import { mapGetters } from 'vuex';
import {
  FCC_FREQUENCIES, FCC_FORBIDDEN_WITHDRAWALS_ONE_TIME,
  FCC_FORBIDDEN_WITHDRAWALS_RECURRING,
  FCC_FORBIDDEN_DEPOSIT_ONE_TIME,
  FCC_FORBIDDEN_DEPOSIT_RECURRING,
} from 'Services/constants';
import { addOneTimeSystematic, addRecurringSystematic, getActiveAccounts } from 'Services/api/MoneyMovement';
import v from 'Services/validation';

export default {
  name: 'add-systematic-fcc',
  mixins: [modal, cursorToEnd],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v,
      type: '1',
      investmentAccount: {},
      bankAccount: {},
      reason: null,
      frequency: '',
      startDate: null,
      endDate: null,
      amount: '0.00',
      selectedSection: true,
      isDeposit: true,
      isWithdrawal: false,
      isProcessing: false,
      grossAmountAccounts: ['13', '14'],
      activeBankAccounts: [],
      activeInvestmentAccounts: [],
      accounts: {},
    };
  },
  async created() {
    await this.$store.dispatch('fetchAllMoneyMovementReasons');
  },
  methods: {
    selectSystematic(event) {
      this.$refs.systematicsForm.resetValidation();
      this.$refs.inputCurrency.$el.getElementsByTagName('input')[0].value = 0;
      this.investmentAccount = {};
      this.bankAccount = {};
      this.reason = null;
      this.frequency = '';
      this.startDate = null;
      this.endDate = null;
      this.amount = '0.00';
      if (event) {
        this.selectedSection = true;
        this.isDeposit = true;
        this.isWithdrawal = false;
        this.type = '1';
      } else {
        this.selectedSection = false;
        this.isWithdrawal = true;
        this.isDeposit = false;
        this.type = '2';
      }
    },
    assignDate() {
      if (this.isOneTimeFunding) this.startDate = this.moment().format('YYYY-MM-DD');
      else {
        this.startDate = this.moment().businessAdd(5).format('YYYY-MM-DD');
        this.endDate = null;
      }
    },
    submitSystematic() {
      if (!this.$refs.systematicsForm.validate()) return;
      this.isProcessing = true;
      const systematicData = {
        from: this.isDeposit ? this.bankAccount.value : this.investmentAccount.value,
        to: this.isDeposit ? this.investmentAccount.value : this.bankAccount.value,
        type: this.type,
        reason: this.reason,
        amount: this.amount,
        client_ids: [this.activeClient.client.id],
        start_date: this.moment(this.startDate).format('YYYYMMDD'),
        ...(!this.isOneTimeFunding) ? {
          frequency: this.frequency,
          stop_date: this.moment(this.endDate).format('YYYYMMDD'),
        } : { },
      };
      if (this.isOneTimeFunding) {
        addOneTimeSystematic(systematicData)
          .then((res) => {
            if (res.status === 'SUCCESS' || res.status === 'success') {
              this.$store.dispatch('setSnackbar', { text: 'Successfully generated one-time transfer.', type: 'success' });
              this.closeModal();
            } else if (res.fcc_api_response) {
              this.isProcessing = false;
              this.$store.dispatch('setSnackbar', { text: 'An error has occurred. Please contact support.', type: 'fail' });
            } else {
              this.isProcessing = false;
              this.$store.dispatch('setSnackbar', { text: 'An internal error has occurred. Please try again.', type: 'fail' });
            }
            this.$store.dispatch('flipSnackbarSwitch');
          });
      } else {
        addRecurringSystematic(systematicData)
          .then((res) => {
            if (res.status === 'SUCCESS' || res.status === 'success') {
              this.$store.dispatch('setSnackbar', { text: 'Successfully generated recurring transfer.', type: 'success' });
              this.closeModal();
            } else if (res.fcc_api_response) {
              this.isProcessing = false;
              this.$store.dispatch('setSnackbar', { text: 'An error has occurred. Please contact inquires@harnessinvest.ca', type: 'fail' });
            } else {
              this.isProcessing = false;
              this.$store.dispatch('setSnackbar', { text: 'An internal error has occurred. Please try again.', type: 'fail' });
            }
            this.$store.dispatch('flipSnackbarSwitch');
          });
      }
    },
    closeModal() {
      this.$refs.systematicsForm.resetValidation();
      this.$refs.inputCurrency.$el.getElementsByTagName('input')[0].value = 0;
      this.$store.commit('CLOSE_CURRENT_MODAL');
      this.close();
    },
    updateBankAccounts() {
      if (!this.isDeposit) {
        const acct = this.accounts.find((acc) => acc.account_id === this.investmentAccount.value);
        const bankAccountIds = acct.bank_accounts.map((bkacct) => bkacct.bank_account_id);
        this.activeBankAccounts = this.bankAccounts
          .filter((account) => account.status === 'V')
          .map((account) => ({
            text: `${account.description}, (${account.account_no})`,
            value: account.id,
          })).filter((acc) => bankAccountIds.includes(acc.value));
      }
    },
    updateInvestmentAccounts() {
      if (this.isDeposit) {
        let bkAcctId = this.bankAccount.value;
        let account_bkacct_mapping = [];
        for (const acc of this.accounts) {
          account_bkacct_mapping[acc.account_id] = acc.bank_accounts.map(bk_acc => bk_acc.bank_account_id);
        }
        this.activeInvestmentAccounts = this.investmentAccounts
          .filter((account) => !this.forbiddenAccountTypes.includes(account.account_type_short))
          .filter((account) => account.account_status === 'O')
          .map((account) => ({
            text: `${account.nickname ? account.nickname : ''} (${account.currency}${account.account_no_dataphile ? `, ${account.account_no_dataphile}` : ''})`,
            value: account.id,
            account_type: account.account_type_short,
          })).filter((acc) => this.accounts.map((res) => res.account_id).includes(acc.value)).filter(acc => account_bkacct_mapping[acc.value].includes(bkAcctId));
      }
    },
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'allMoneyMovementReasons',
      'clientId',
    ]),
    bankAccounts() {
      return this.activeClient.bankAccounts;
    },
    investmentAccounts() {
      return this.activeClient.investment_accounts;
    },
    frequencies() {
      return Object.keys(FCC_FREQUENCIES).map((key) => ({ value: key, text: FCC_FREQUENCIES[key] }));
    },
    reasons() {
      return this.allMoneyMovementReasons
        .filter((reason) => !this.forbiddenReasons.includes(reason.id))
        .map((reason) => ({ value: reason.id, text: reason.reason }));
    },
    isOneTimeFunding() {
      return this.frequency === 'O';
    },
    txtAmountLabel() {
      if (!this.isOneTimeFunding && this.isWithdrawal && this.grossAmountAccounts.includes(this.investmentAccount.account_type)) {
        return 'Gross Amount';
      }
      return 'Net Amount';
    },
    forbiddenReasons() {
      return this.grossAmountAccounts.includes(this.investmentAccount.account_type) ? [] : [1, 6];
    },
    forbiddenAccountTypes() {
      if (this.isDeposit) {
        if (this.isOneTimeFunding) {
          return FCC_FORBIDDEN_DEPOSIT_ONE_TIME;
        }
        return FCC_FORBIDDEN_DEPOSIT_RECURRING;
      }
      if (this.isWithdrawal) {
        if (this.isOneTimeFunding) {
          return FCC_FORBIDDEN_WITHDRAWALS_ONE_TIME;
        }
        return FCC_FORBIDDEN_WITHDRAWALS_RECURRING;
      }
      return [];
    },
    filteredBankAccounts() {
      return this.activeBankAccounts;
    },
    filteredInvestmentAccounts() {
      return this.activeInvestmentAccounts;
    },
  },
  watch: {
    isOpen: {
      async handler() {
        if (this.isOpen) {
          const data = {
            client_id: this.activeClient.client.id,
            active_only: true,
          };
          this.accounts = await getActiveAccounts(data);
          this.activeInvestmentAccounts = this.investmentAccounts
            .filter((account) => !this.forbiddenAccountTypes.includes(account.account_type_short))
            .filter((account) => account.account_status === 'O')
            .map((account) => ({
              text: `${account.nickname ? account.nickname : ''} (${account.currency}${account.account_no_dataphile ? `, ${account.account_no_dataphile}` : ''})`,
              value: account.id,
              account_type: account.account_type_short,
            })).filter((acc) => this.accounts.map((res) => res.account_id).includes(acc.value));
          this.activeBankAccounts = this.bankAccounts
            .filter((account) => account.status === 'V')
            .map((account) => ({
              text: `${account.description}, (${account.account_no})`,
              value: account.id,
            }));
        }
      }
    },
    isDeposit: {
      async handler() {
        this.activeInvestmentAccounts = this.investmentAccounts
            .filter((account) => !this.forbiddenAccountTypes.includes(account.account_type_short))
            .filter((account) => account.account_status === 'O')
            .map((account) => ({
              text: `${account.nickname ? account.nickname : ''} (${account.currency}${account.account_no_dataphile ? `, ${account.account_no_dataphile}` : ''})`,
              value: account.id,
              account_type: account.account_type_short,
            })).filter((acc) => this.accounts.map((res) => res.account_id).includes(acc.value));
          this.activeBankAccounts = this.bankAccounts
            .filter((account) => account.status === 'V')
            .map((account) => ({
              text: `${account.description}, (${account.account_no})`,
              value: account.id,
            }));
      }
    },
    frequency: {
      async handler() {
        this.activeInvestmentAccounts = this.investmentAccounts
            .filter((account) => !this.forbiddenAccountTypes.includes(account.account_type_short))
            .filter((account) => account.account_status === 'O')
            .map((account) => ({
              text: `${account.nickname ? account.nickname : ''} (${account.currency}${account.account_no_dataphile ? `, ${account.account_no_dataphile}` : ''})`,
              value: account.id,
              account_type: account.account_type_short,
            })).filter((acc) => this.accounts.map((res) => res.account_id).includes(acc.value));
      }
    }
  },
};
</script>
