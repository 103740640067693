<template>
  <v-dialog
    :value="isOpen"
    persistent
    max-height="80rem"
    max-width="70rem"
  >
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="$emit('close')">close</v-icon>
      </PasFab>
      <h1 class="mb-3">Upload a file</h1>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent=""
      >
        <v-layout row>
          <v-flex xs6 mr-4>
            <v-layout column>
              <v-flex text-xs-left>
                <div class="strong-text">Submission title</div>
              </v-flex>
              <v-flex>
                <pas-input-field
                  v-model="documentName"
                  :placeholder="`Enter a name for your file`"
                  :rules="[v.isRequired()]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout column>
              <v-flex text-xs-left>
                <div class="strong-text">Type of document</div>
              </v-flex>
              <v-flex mr-2>
                <pas-input-field-list
                  v-model="documentType"
                  :items="documentTypes"
                  :placeholder="`Select document type`"
                  :rules="[v.isRequired()]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <pas-button
          px-5
          class="primary-btn px-5"
          @click="okClick"
        >
          OK
        </pas-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import v from 'Services/validation';
import { DOCUMENT_TYPES } from 'Services/constants';

export default {
  name: 'select-document-type',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    docName: {
      type: String,
      default: '',
    },
    docType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      v,
      documentName: '',
      documentType: null,
    };
  },
  computed: {
    documentTypes() {
      return DOCUMENT_TYPES.map((e, i) => ({ value: i, text: e }));
    },
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
  },
  methods: {
    okClick() {
      if (!(this.$refs.form.validate())) return false;
      this.$emit('fileOptionsSelected', {
        documentName: this.documentName,
        documentType: this.documentType,
      });
      this.$refs.form.reset();
      return true;
    },
  },
  watch: {
    docName() {
      this.documentName = this.docName;
    },
    docType() {
      this.documentType = this.docType;
    },
  },
};
</script>

<style lang="scss">
.is-client-doc{
  .v-input__slot{
    margin: 0px !important;
  }
}
</style>
